import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { notify } from "react-notify-toast";
import * as userActions from "../../../../../../actions/index";
import ReactPaginate from "react-paginate";
import Table from "../../../../../../common/element/Table";
import Tooltip from "../../../../../../common/element/Tooltip";
import { Link } from "react-router-dom";
import Badge from "../../../../../../common/element/Badge";
import { ReinviteProvider } from "../../../../../../actions/client-portal/AllContractorApi";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";

const InviteContrators = (props) => {
  const dispatch = useDispatch();
  const { Tr, Th, Td, THead, TBody } = Table;
  const [countData, setCountData] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  let trading_name;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    trading_name = storedData.trading_name;
  }

  // Function to open the "Remove Invitation" dialog
  const openDialog = () => {
    setIsOpen(true);
  };
  // Function to close the "Remove Invitation" confirmation dialog
  const onDialogClose = () => {
    setIsOpen(false);
  };
  
  // to reInvite user
  const ReInviteUser = (e) => {
    const payload = {
      invite_provider_id: e.inviteContractorId,
      trading_name: trading_name,
    };
    setButtonDisabled(true);
    dispatch(ReinviteProvider(payload))
      .then((response) => {
        if (response?.success) {
          openDialog();
          setButtonDisabled(false);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {})
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>Provider Name</Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("provider_type_name")}
                >
                  Provider type
                  <svg
                    className={`transform ${
                      props?.getAllinvitedData.sort === "provider_type_name" &&
                      props?.getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">Services</span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">ABN/NZBN</span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">State</span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">Invite Status</span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">Invited Date</span>
              </Th>

              <Th className="w-28"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.loadData &&
              props?.contractorDataInvited?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      {/* <Link to="specific-provider"> */}
                      {item?.first_name + " " + item?.last_name}
                      {/* </Link> */}
                    </Td>
                    <Td>
                      {item?.provider_type_name
                        ? item?.provider_type_name
                        : "-"}
                    </Td>
                    <Td>{item?.serviceName ? item?.serviceName : "-"}</Td>
                    <Td>
                      {item?.provider_abn_nzbn ? item?.provider_abn_nzbn : "-"}
                    </Td>
                    <Td>{item?.state_name ? item?.state_name : "-"}</Td>
                    <Td>
                      <Badge
                        className="py-0.5 px-2 rounded-lg"
                        content={
                          item?.invite_status ? item?.invite_status : "-"
                        }
                        innerClass={`${
                          item?.invite_status == "Rejected"
                            ? "bg-error-100 text-error-700"
                            : "bg-gray-100 text-gray-700"
                        }`}
                      />
                    </Td>
                    <Td>{item?.inviteDate ? item?.inviteDate : "-"}</Td>
                    <Td>
                      <div className="flex items-center justify-end gap-4">
                        <Tooltip title="Reinvite " placement="top">
                          <button
                            disabled={buttonDisabled}
                            onClick={() => ReInviteUser(item)}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                                stroke="#475467"
                                stroke-width="1.667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </button>
                        </Tooltip>
                      </div>
                    </Td>
                  </Tr>
                );
              })}
          </TBody>
        </Table>
      </div>
              
      {!props?.loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={props?.countData}
            pageNumber={props?.getAllinvitedData.page}
            displayPage={props?.contractorDataInvited?.length}
            UpdatePageLimit={props?.SetgetAllinvitedData}
            allfilters={props?.getAllinvitedData}
          />

          {props?.countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={props?.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={props?.handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={props?.getAllinvitedData.page - 1}
            />
          )}
        </div>
      }
      {/*--------- Invitaion Sent Confirmation -------- */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <SmallButton onClick={() => onDialogClose()}>close</SmallButton>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default InviteContrators;
