import React from "react";
import Table from "../../../../../../common/element/Table";
const { Tr, Th, Td, THead, TBody } = Table;
const AuditLogList = () => {
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span className="flex items-center gap-1">
                  Timestamp
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>User</Th>
              <Th className="w-7/12">
                <span className="flex items-center gap-1">
                  Action
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>IP address</Th>
            </Tr>
          </THead>
          <TBody>
            <Tr>
              <Td>10:30 28-01-2023</Td>
              <Td>John Smith</Td>
              <Td>
                John Smith updated the contact information for the Client "Acme
                Corporation
              </Td>
              <Td>110.142.17.87</Td>
            </Tr>
            <Tr>
              <Td>mm:ss DD-MM-YYYY</Td>
              <Td>[First name, Last Name]</Td>
              <Td>[Action message here]</Td>
              <Td>[IP address here]</Td>
            </Tr>
            <Tr>
              <Td>mm:ss DD-MM-YYYY</Td>
              <Td>[First name, Last Name]</Td>
              <Td>[Action message here]</Td>
              <Td>[IP address here]</Td>
            </Tr>
            <Tr>
              <Td>mm:ss DD-MM-YYYY</Td>
              <Td>[First name, Last Name]</Td>
              <Td>[Action message here]</Td>
              <Td>[IP address here]</Td>
            </Tr>
          </TBody>
        </Table>
      </div>
    </React.Fragment>
  );
};
export default AuditLogList;
