import axios from "axios";
import * as actionType from "../../constant/actionType";
export const CreateProviderNote = (form_data) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "note_attach") {
        // Check if it's an array of files
        if (Array.isArray(form_data[key].files)) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`note_attach`, file);
          });
        } else {
          // It's a single file
          formdata.append(`note_attach`, form_data[key].files[0]);
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }
    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/CreateOrgNote`,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};
