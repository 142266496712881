import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllIndividualForEmail = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetAllIndividualForEmail`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetAllSpecificEmailTemplates = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetAllSpecificEmailTemplates`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const EmailToIndividual = (form_data) => async (dispatch) => {
  // Final CSV Data
  let formdata = new FormData();
  for (let key in form_data) {
    if (key === "email_doc") {
      if (form_data[key]) {
        form_data[key].files.forEach((file, i) => {
          formdata.append(`email_doc`, file);
        });
      }
    } else if (key === "email_attach_docs") {
      if (form_data[key]) {
        if (form_data[key].length === 1) {
          formdata.append(`email_attach_docs[]`, [form_data[key][0]]);
        } else {
          if (form_data[key].length != 0) {
            form_data[key].forEach((file, i) => {
              formdata.append(`email_attach_docs`, file);
            });
          } else {
            formdata.append(`email_attach_docs`, [[][0]]);
          }
        }
      }
    } else {
      formdata.append(key, form_data[key]);
    }
  }
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/EmailToIndividual`,
    });
    return response.data;
  } catch (error) {}
};

export const GetSpecificOrgNotes = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetSpecificOrgNotes`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const CreateOrgNote = (form_data, ipAddress) => async (dispatch) => {
  // Final CSV Data
  let formdata = new FormData();
  for (let key in form_data) {
    if (key === "note_attach") {
      // Check if it's an array of files
      if (Array.isArray(form_data[key].files)) {
        form_data[key].files.forEach((file, i) => {
          formdata.append(`note_attach`, file);
        });
      } else {
        // It's a single file
        formdata.append(`note_attach`, form_data[key].files[0]);
      }
    } else {
      formdata.append(key, form_data[key]);
    }
  }
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/client/CreateOrgNote`,
    });
    return response.data;
  } catch (error) {}
};
