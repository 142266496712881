import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetClientContactsForSiteList = (userData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetClientContactsForSiteList`,
      params: userData,
    });
    return response.data;
  } catch (error) {}
};
