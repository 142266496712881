import React, { useState, useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Heading from "../../../../../../common/element/Heading";
import Dialog from "../../../../../../common/element/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SearchImg from "../../../../../../assets/icons-svg/search-lg.svg";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Label from "../../../../../../common/element/Label";
import Segment from "../../../../../../common/element/Segment";
import Select from "../../../../../../common/element/Select";
import { CreateProviderType } from "../../../../../../actions/index";
import { GetAllProviderTypes } from "../../../../../../actions/index";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import AccountList from "./AccountList";
import * as userActions from "../../../../../../actions/index";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import Validation from "../../../../../../common/404/Validations";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import { Pagination } from "../../../../../../common/pagination";
import ReactPaginate from "react-paginate";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
const ProviderType = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  //state for open and close pannel
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [apiCalls, setApiCalls] = useState(true);
  const [dialogDeleteOpen, setDeleteOpen] = useState(false);
  // state for filter data
  const [filterpage, setfilterpage] = useState(1);
  const [loadData, setLoadData] = useState(false);
  const [filterlimit, setfilterlimit] = useState(10);
  const [permissionRole, setPermissionRole] = useState("");
  const [filtersort, setFiltersort] = useState("provider_type_name");
  const [filterorder, setFilterOrder] = useState("desc");
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [countData, setCountData] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [allProviderData, setAllProviderData] = useState();
  //Set State for Segment
  const [singleSegmentCheck, setSingleSegmentCheck] = useState([""]);
  const [singleSegmentInviteWorker, setSingleSegmentInviteWorker] = useState([
    "",
  ]);
  const loginData = JSON.parse(localStorage?.getItem("LoginUserData"));
  let role_assignment_id;
  let role_name;

  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
    role_name = loginData?.data?.role?.role_name;
  }
  //state for set value in form
  const [formData, setFormData] = useState({
    provider_type_name: "",
    client_pay_default: false,
    can_invite_workers: false,
    licence_fee: "",
    assignment_fee: "",
    worker_fee: "",
    validation_criteria_limit: "",
  });

  //state for validation
  const [errors, setErrors] = useState({
    nameError: "",
    defaultPayError: "",
    inviteWorkerError: "",
    licenseFeeError: "",
    assignmentFeeError: "",
    workerFeeError: "",
    criteriaLimitError: "",
  });

  //select option for Criteria Limit
  const selectOptions = [
    { value: "Unlimited", label: "Unlimited" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  // open and close pannel functions
  const openDeleteDialog = () => {
    setDeleteOpen(true);
  };
  const onDeleteDialogClose = () => {
    setDeleteOpen(false);
  };
  const openDialog = () => {
    setIsOpen(true);
    setFormData((prevStates) => ({
      ...prevStates,
      provider_type_name: "",
      client_pay_default: "",
      can_invite_workers: "",
      licence_fee: "",
      assignment_fee: "",
      worker_fee: "",
      validation_criteria_limit: "",
    }));
    setSingleSegmentCheck([""]);
    setSingleSegmentInviteWorker([""]);
  };

  const onDialogClose = () => {
    if (!buttonDisabled) {
      setIsOpen(false);
      setTimeout(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: "",
          defaultPayError: "",
          inviteWorkerError: "",
          licenseFeeError: "",
          assignmentFeeError: "",
          workerFeeError: "",
          criteriaLimitError: "",
        }));
      }, 900);
    }
  };
  // Set breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Provider Types",
          url: "/admin/provider-types",
          home: "/admin/dashboard",
        },
      ])
    );
  }, []);
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_id) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_id))
        .then((response) => {
          if (response?.success === true) {
            const data = response?.data?.role_name;
            setPermissionRole(data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetSpecificUserRole();
  }, []);
  //set provider data in formData state
  const handleInputChange = (e) => {
    const { name, value } = e?.target;

    // Define a mapping between input field names and error state keys
    const errorStateKeys = {
      provider_type_name: "nameError",
      licence_fee: "licenseFeeError",
      assignment_fee: "assignmentFeeError",
      worker_fee: "workerFeeError",
    };

    // Update the form data
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the corresponding error if the updated field is found in the mapping
    if (errorStateKeys[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [errorStateKeys[name]]: "",
      }));
    }
  };

  //to select Invite Worker
  const onSingleSelectionSegmentInviteWorker = useCallback(
    (val) => {
      setSingleSegmentInviteWorker(val);
      setFormData((prevFormData) => ({
        ...prevFormData,
        can_invite_workers: val[0] == "yes" ? true : false,
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        inviteWorkerError: "",
      }));
    },
    [setSingleSegmentInviteWorker, setFormData, setErrors]
  );

  //to select Does Client Pay By Default
  const onSingleSelectionSegmentCheck = useCallback(
    (val) => {
      setSingleSegmentCheck(val);
      setFormData((prevFormData) => ({
        ...prevFormData,
        client_pay_default: val[0] == "yes" ? true : false,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        defaultPayError: "",
      }));
    },
    [setSingleSegmentCheck, setFormData, setErrors]
  );

  // set Criteria Limit
  const onChangeCriteriaLimit = (selectedOption) => {
    setFormData({
      ...formData,
      validation_criteria_limit: selectedOption?.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      criteriaLimitError: "",
    }));
  };

  useEffect(() => {
    // Check if can_invite_workers is true and worker_fee does not exist
    if (formData?.can_invite_workers === true && !formData?.worker_fee) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        worker_fee: "", // Add worker_fee with empty value to formData
      }));
    }
    // Check if worker_fee is empty and can_invite_workers is false
    else if (
      formData?.worker_fee === "" &&
      formData?.can_invite_workers === false
    ) {
      const { worker_fee, ...updatedFormData } = formData;
      setFormData(updatedFormData); // Remove worker_fee from formData
    }
  }, [formData?.worker_fee, formData?.can_invite_workers]);

  //Submit all form value
  const submitProviderType = async (e) => {
    e.preventDefault();

    // Validation
    const nameValidation = Validation({
      value: formData?.provider_type_name,
      Validations: { required: true },
    });
    const typeDefaultWorker = Validation({
      value: formData?.client_pay_default,
      Validations: { required: true },
    });
    const typeWorkerInvite = Validation({
      value: formData?.can_invite_workers,
      Validations: { required: true },
    });
    const licenseFeeValidation = Validation({
      value: formData?.licence_fee,
      Validations: { required: true },
    });
    const assignmentFeeValidation = Validation({
      value: formData?.assignment_fee,
      Validations: { required: true },
    });
    let workerFeeValidation;
    if (formData.can_invite_workers) {
      workerFeeValidation = Validation({
        value: formData?.worker_fee,
        Validations: { required: true },
      });
    } else {
      workerFeeValidation = Validation({
        value: formData?.worker_fee,
        Validations: { required: false },
      });
    }
    const criteriaLimitValidation = Validation({
      value: formData?.validation_criteria_limit,
      Validations: { required: true },
    });

    if (!nameValidation.valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameError: nameValidation.error_msg,
      }));
    }
    if (!typeDefaultWorker.valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        defaultPayError: typeDefaultWorker.error_msg,
      }));
    }
    if (!typeWorkerInvite.valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        inviteWorkerError: typeWorkerInvite.error_msg,
      }));
    }
    if (!licenseFeeValidation.valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        licenseFeeError: licenseFeeValidation.error_msg,
      }));
    }
    if (!assignmentFeeValidation.valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        assignmentFeeError: assignmentFeeValidation.error_msg,
      }));
    }
    if (formData.worker_fee == "" && formData.can_invite_workers != false) {
      if (!workerFeeValidation.valid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          workerFeeError: workerFeeValidation.error_msg,
        }));
      }
    }
    if (!criteriaLimitValidation.valid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        criteriaLimitError: criteriaLimitValidation.error_msg,
      }));
    }
    if (
      nameValidation.valid &&
      typeDefaultWorker.valid &&
      typeWorkerInvite.valid &&
      licenseFeeValidation.valid &&
      assignmentFeeValidation.valid &&
      criteriaLimitValidation.valid &&
      workerFeeValidation.valid
    ) {
      try {
        setButtonDisabled(true);
        const response = await dispatch(CreateProviderType(formData,ipAddress));
        if (response?.success) {
          openDeleteDialog();
          fetchProviderData();
          onDialogClose();
          setButtonDisabled(false);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
          setButtonDisabled(false);
        } else {
          setButtonDisabled(false);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        setButtonDisabled(false);
        // Handle error if needed
      }
    }
  };

  // FilTER DATA SHOW
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    page: totalPages,
    limit: filterlimit,
    sort: filtersort,
    order: filterorder,
    search: searchTerm,
  });
  //Get All Provider Data
  const fetchProviderData = useCallback(() => {
    dispatch(GetAllProviderTypes(getAllinvitedData))
      .then((res) => {
        setAllProviderData(res?.data?.rows);
        setLoadData(true);
        setCountData(res?.data?.count);
        setTotalPages(
          Math.ceil((res?.data?.count || 0) / getAllinvitedData?.limit)
        );
      })
      .catch((error) => {
        setLoadData(true);
        console.error("Error fetching provider data:", error);
      });
  }, [dispatch, getAllinvitedData]);

  useEffect(() => {
    fetchProviderData();
  }, [getAllinvitedData]);

  // Handle sorting of the Provider list
  const handleSort = (column, order) => {
    if (column === filtersort) {
      setFilterOrder(order === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(order === "asc" ? "desc" : "asc");
    }
  };

  // useEffect for sorting
  useEffect(() => {
    SetgetAllinvitedData((prevData) => ({
      ...prevData,
      sort: filtersort,
      order: filterorder,
    }));
  }, [filtersort, filterorder]);

  //Handling Pagination
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
  };
  //Handling Search with provider name
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e?.target?.name]: e?.target?.value.trim(),
      };
    });
  };
  const inputchangehandler = useCallback(debounce(handleSearch), []);

  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
      }));
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <Heading>Provider Types</Heading>
        <div className="flex gap-5">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={SearchImg} alt="" className="h-5" />
            </div>
            <ClientInput
              type="text"
              className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              inputchangehandler={inputchangehandler}
              name={"search"}
            />
          </div>
          {permissionRole !== "support team" && (
            <BtnPrimary
              onClick={() => openDialog()}
              className="flex px-3 items-center justify-center gap-2 text-12"
            >
              Add Provider Types
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.712 4.167v11.666M4.878 10h11.667"
                  stroke="#fff"
                  stroke-width="1.667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </BtnPrimary>
          )}
        </div>
      </div>

      <AccountList
        fetchProviderData={fetchProviderData}
        handleSort={handleSort}
        allProviderData={allProviderData}
        filtersort={filtersort}
        filterorder={filterorder}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={allProviderData?.length}
            UpdatePageLimit={updatePageLimit}
            UpdatePage={updatePage}
            // UpdatePageLimit={SetgetAllinvitedData}
            // allfilters={getAllinvitedData}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={getAllinvitedData.page - 1}
            />
          )}
        </div>
      )}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        width={900}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Provider
            </h5>
          </div>
        </div>

        <div className="px-8">
          <div className="flex mb-5 flex-wrap">
            <Label className="font-semibold mb-1.5 text-xs w-4/12">
              Provider name*
            </Label>
            <div className="w-8/12">
              <ClientInput
                placeholder="Enter provider type name here"
                className="h-9 w-full "
                value={formData?.provider_type_name}
                name="provider_type_name"
                inputchangehandler={handleInputChange}
              />
              {errors.nameError && (
                <p className="text-xs text-red-600 mt-2">{errors.nameError}</p>
              )}
            </div>
          </div>

          <div className="flex mb-5 flex-wrap">
            <Label className="font-semibold mb-1.5 text-xs w-4/12">
              Does Client pay by default?*
            </Label>
            <div className="w-8/12">
              <Segment
                className="docType-btn "
                value={singleSegmentCheck}
                onChange={(val) => onSingleSelectionSegmentCheck(val)}
              >
                <Segment.Item value="yes">Yes</Segment.Item>
                <Segment.Item value="no">No</Segment.Item>
              </Segment>
              {errors.defaultPayError && (
                <p className="text-xs text-red-600 mt-2">
                  {errors.defaultPayError}
                </p>
              )}
            </div>
          </div>
          <div className="flex mb-5 flex-wrap ">
            <Label className="font-semibold mb-1.5 text-xs w-4/12">
              Can account invite Workers?*
            </Label>
            <div className="w-8/12">
              <Segment
                className="docType-btn "
                value={singleSegmentInviteWorker}
                onChange={(val) => onSingleSelectionSegmentInviteWorker(val)}
              >
                <Segment.Item value="yes">Yes</Segment.Item>
                <Segment.Item value="no">No</Segment.Item>
              </Segment>
              {errors.inviteWorkerError && (
                <p className="text-xs text-red-600 mt-2">
                  {errors.inviteWorkerError}
                </p>
              )}
            </div>
          </div>
          <div className="flex mb-5 flex-wrap">
            <Label className="font-semibold mb-1.5 text-xs w-4/12">
              License Fee*
            </Label>
            <div className=" w-8/12">
              <div className="relative">
                <div className="flex">
                  <span className="absolute mt-2 ml-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337"
                        stroke="#667085"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <ClientInput
                    placeholder="Enter fee here"
                    className="h-9 flex-1 pl-8"
                    name="licence_fee"
                    type="number"
                    value={formData?.licence_fee}
                    inputchangehandler={handleInputChange}
                  />
                </div>
                <span className="text-xs text-gray-60 block mt-1">
                  + 10% GST will be applied
                </span>
              </div>
              {errors.licenseFeeError && (
                <p className="text-xs text-red-600 mt-2">
                  {errors.licenseFeeError}
                </p>
              )}
            </div>
          </div>
          <div className="flex mb-5 flex-wrap">
            <Label className="font-semibold mb-1.5 text-xs w-4/12">
              Assignment Fee*
            </Label>
            <div className="w-8/12">
              <div className="relative">
                <div className="flex">
                  <span className="absolute mt-2 ml-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337"
                        stroke="#667085"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <ClientInput
                    placeholder="Enter fee here"
                    className="h-9 flex-1 pl-8"
                    type="number"
                    value={formData?.assignment_fee}
                    name="assignment_fee"
                    inputchangehandler={handleInputChange}
                  />
                </div>
                <span className="text-xs text-gray-60 block mt-1">
                  + 10% GST will be applied
                </span>
              </div>
              {errors.assignmentFeeError && (
                <p className="text-xs text-red-600 mt-2">
                  {errors.assignmentFeeError}
                </p>
              )}
            </div>
          </div>
          {formData.can_invite_workers && (
            <div className="flex mb-5 flex-wrap">
              <Label className="font-semibold mb-1.5 text-xs w-4/12">
                Worker Fee*
              </Label>
              <div className="w-8/12">
                <div className="relative">
                  <div className="flex">
                    <span className="absolute mt-2 ml-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <ClientInput
                      placeholder="Enter fee here"
                      className="h-9 flex-1 pl-8"
                      type="number"
                      value={formData?.worker_fee}
                      name="worker_fee"
                      inputchangehandler={handleInputChange}
                    />
                  </div>
                  <span className="text-xs text-gray-60 block mt-1">
                    + 10% GST will be applied
                  </span>
                </div>
                {errors.workerFeeError && (
                  <p className="text-xs text-red-600 mt-2">
                    {errors.workerFeeError}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="flex mb-5 flex-wrap">
            <Label className="font-semibold mb-1.5 text-xs w-4/12">
              Validation criteria limit?*
            </Label>
            <div className="w-8/12 h-9">
              <Select
                className="select-options hide-search-icon"
                options={selectOptions}
                placeholder="Select amount"
                onChange={onChangeCriteriaLimit}
              ></Select>
              {errors.criteriaLimitError && (
                <p className="text-xs text-red-600 mt-2">
                  {errors.criteriaLimitError}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex dailog-footer justify-end gap-2">
          <BtnLight
            disabled={buttonDisabled}
            className="px-4"
            variant="plain"
            onClick={onDialogClose}
          >
            Close
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            className="px-4"
            variant="solid"
            onClick={(e) => {
              submitProviderType(e);
            }}
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Add"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogDeleteOpen}
        bodyOpenClassName="overflow-hidden"
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              onClick={onDeleteDialogClose}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Provider Type added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onDeleteDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default ProviderType;
