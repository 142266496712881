import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import { notify } from "react-notify-toast";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Input from "../../../../../../common/element/Input";
import mailIcon from "../../../../../../assets/icons-svg/mail-02.svg";
import PlusIcon from "../../../../../../assets/icons-svg/image-plus.svg";
import uploadIcon from "../../../../../../assets/icons-svg/upload.svg";
import * as userActions from "../../../../../../actions/index";
import Validations from "../../../../../../common/404/Validations";
import { updateAvtarfunc } from "../../../../../../redux/classes/common/updateAvtar";
import Dialog from "../../../../../../common/element/Dialog";
import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";

const YourProfile = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  // const [profileData, setProfileData] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [jobTitle, setJob] = useState("");
  const [malId, setMailId] = useState();
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [photo, setPhoto] = useState();
  const [urlImage, setUrlImage] = useState();
  const [roleAssignment, setRoleAssignment] = useState();
  const [firstNameError, setfirstError] = useState();
  const [documentError, setdocumentError] = useState("");
  const [lastNameError, setLastError] = useState();
  const [mailError, setMailError] = useState();
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  let individualId;
  if (loginUserData !== null) {
    individualId = loginUserData?.userName?.individual_id;
  }

  const GetAdminProfileById = useCallback(async () => {
    dispatch(userActions.GetAdminProfileById(individualId)).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        setFirstName(arrayData.first_name);
        setLastName(arrayData.last_name);
        setJob(arrayData.admin_job_title);
        setMailId(arrayData.email);
        setRoleAssignment(arrayData.role_assignment_id);
        setUrlImage(arrayData.avatar);
        setPhoto(arrayData.avatar);
        const updatedData = {
          ...storedData,
          userName: {
            ...storedData.userName,
            avatar: arrayData.avatar,
            first_name: arrayData.first_name,
            last_name: arrayData.last_name,
          },
        };
        dispatch(updateAvtarfunc(true));
        localStorage.setItem("LoginUserData", JSON.stringify(updatedData));
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAdminProfileById();
  }, [individualId]);

  const inputchangehandler = (e, identifier) => {
    if (identifier === "firstName") {
      setFirstName(e);
    }
    if (identifier === "lastName") {
      setLastName(e);
    }
    if (identifier === "email") {
      setMailId(e);
    }
    if (identifier === "jobTitle") {
      setJob(e);
    }
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setPhoto(file);
            setUrlImage(URL.createObjectURL(file));
            setdocumentError("");
          } else {
            // Handle size validation error
            setdocumentError("Image dimensions must be within 800x400 pixels.");
          }
        };
      } else {
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed."
        );
        // Handle file type validation error
      }
    }
  };

  const submithandler = (e) => {
    e.preventDefault();

    //Validate Email
    const emailValidation = Validations({
      value: malId,
      Validations: { required: true, email: true },
    });
    if (!emailValidation.valid) {
      setMailError(emailValidation.error_msg);
    }
    const firstnameValidation = Validations({
      value: firstName,
      Validations: { required: true },
    });
    const lastnameValidation = Validations({
      value: lastName,
      Validations: { required: true },
    });
    if (!firstnameValidation.valid) {
      setfirstError(firstnameValidation.error_msg);
    }
    if (!lastnameValidation.valid) {
      setLastError(lastnameValidation.error_msg);
    }

    if (
      emailValidation.valid === true &&
      firstnameValidation.valid === true &&
      lastnameValidation.valid === true
    ) {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email: malId,
        individual_id: individualId,
        role_assignment_id: roleAssignment,
        avatar: photo,
        admin_job_title: jobTitle ? jobTitle : "",
      };
      setButtonDisabled(true);
      props
        .onUpdateUser(payload, ipAddress)
        .then((response) => {
          if (response?.success === true) {
            //    onEditDialogOk()
            setdialogAddOpen(true);
            setButtonDisabled(false);
            GetAdminProfileById();
            setMailError("");
            setfirstError("");
            setLastError("");
            setPhoto("");
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.error("Error :", error);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  };
  const onAddDialogClose = () => {
    setdialogAddOpen(false);
  };
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Personal info
          </h5>
          <p className="text-gray-600">
            Update your photo and personal details here.
          </p>
        </div>
        <div className="flex justify-end items-center">
          <BtnLight
            disabled={buttonDisabled}
            className="w-auto px-4"
            onClick={() => GetAdminProfileById()}
          >
            Cancel
          </BtnLight>
          <SmallButton
            disabled={buttonDisabled}
            className="w-auto px-4 ml-3"
            onClick={(e) => submithandler(e)}
          >
            Save
          </SmallButton>
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Full name</label>
        <div className="flex items-center w-7/12 flex-wrap">
          <div className="flex gap-3 w-full">
            <div className="flex-1">
              <label>First name*</label>
              <Input
                className="h-9 w-full"
                placeholder="Enter first name"
                value={firstName}
                inputchangehandler={(e) => inputchangehandler(e, "firstName")}
              />
              {firstNameError && (
                <div className="text-red-500 text-sm mt-1">
                  {firstNameError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <label>Last name*</label>
              <Input
                className="h-9 w-full"
                placeholder="Enter second name"
                value={lastName}
                inputchangehandler={(e) => inputchangehandler(e, "lastName")}
              />
              {lastNameError && (
                <div className="text-red-500 text-sm mt-1">{lastNameError}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Job title</label>
        <div className="flex items-center w-7/12 flex-wrap">
          <Input
            className="h-9 w-full"
            placeholder="Job title here"
            value={jobTitle}
            inputchangehandler={(e) => inputchangehandler(e, "jobTitle")}
          />
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Email address
        </label>
        <div className="flex items-center w-7/12 flex-wrap relative">
          <span className="absolute left-3 top-2">
            <img src={mailIcon} alt="" />
          </span>
          <Input
            className="h-9 w-full pl-10"
            placeholder="name@emailaddress.com"
            value={malId}
            readOnly={true}
            inputchangehandler={(e) => inputchangehandler(e, "email")}
          />
          {mailError && (
            <div className="text-red-500 text-sm mt-1">{mailError}</div>
          )}
        </div>
      </div>
      <div className="flex items-start  border-gray-200">
        <label className="text-gray-700 font-semibold w-2/12">Your Photo</label>
        <div className="flex items-start w-7/12">
          <span className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center avtar_img_round">
            {/* <img src={PlusIcon} /> */}
            {urlImage != "null" ? (
              <img className="w-16 h-16 rounded-full " src={urlImage} />
            ) : (
              <img
                className="w-16 h-16 rounded-full p-2"
                src={PlusIcon}
                alt=""
              />
            )}
          </span>

          <div class="flex items-center justify-center flex-1 upload-img ml-8 ">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full border py-3 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
            >
              <div
                className="flex items-center justify-center  flex-1 upload-img ml-8"
                onDragOver={(e) => e.preventDefault()}
                onDrop={onDropHandler}
              >
                <div class="flex flex-col items-center justify-center">
                  <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
                    <img src={uploadIcon} alt="" className="w-4" />
                  </div>

                  <p class="mb-1  text-gray-600 darks:text-gray-400">
                    <span class="font-semibold text-blue-700">
                      Click to upload
                    </span>
                    <span className="font-medium"> or drag and drop</span>
                  </p>
                  <p class=" text-gray-600 darks:text-gray-400 font-inter text-center">
                    SVG, PNG, JPG or GIF (max. 800x400px){" "}
                  </p>
                  {documentError && (
                    <div className="text-red-500 text-sm mt-1">
                      {documentError}
                    </div>
                  )}
                </div>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                onChange={(e) => inputChangeHandlerFile(e.target.files[0])}
              />
            </label>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Your profile has been saved successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    // loader: userActions.UserResetPasswordEmail.loader,
  };
}
const mapDispatchToProps = {
  onUpdateUser: userActions.UpdateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourProfile);
