import React, { useEffect, useState, useCallback } from "react";
import Heading from "../../../../../common/element/Heading";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import ClientInput from "../../../../../common/element/Input/clientInput";
import { useDispatch } from "react-redux";
import Table from "../../../../../common/element/Table";
import moment from "moment";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Checkbox from "../../../../../common/element/Checkbox";
import {
  GetGlobelPermissions,
  UpdateGlobelNotifications,
} from "../../../../../actions/Admin";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { debounce } from "../../../../../common/snippet/Debouncing";
import { GetComplianceChecklist } from "../../../../../actions/Admin-Client/Admin-Client";
import * as userActions from "../../../../../actions/index";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
import { useSelector } from "react-redux";
const Notifications = () => {
  const { Tr, Th, Td, THead, TBody } = Table;
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [expandedRows, setExpandedRows] = useState({});
  const [permissionRole, setPermissionRole] = useState("");
  const [searchTerm, setsearchTerm] = useState("");
  const [loadData, setLoadData] = useState(false);
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  const [allGlobalNoti, setAllGlobalNoti] = useState([]);
  let role_assignment_id;
  let role_name;
  if (loginData != null) {
    role_assignment_id = loginData?.data?.role_assignment_id;
    role_name = loginData?.data?.role?.role_name;
  }

  // Set breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Notifications",
          url: "/admin/notifications",
          home: "/admin/dashboard",
        },
      ])
    );
  }, []);

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getGlobalPermissions = useCallback(async () => {
    try {
      const response = await dispatch(
        GetGlobelPermissions({ search: searchTerm })
      );

      if (response?.success) {
        setAllGlobalNoti(response?.data);
        setLoadData(true);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess(); // Function to handle 401 errors
        setLoadData(true);
      }
    } catch (error) {
      setLoadData(true);
      console.error("Error fetching global permissions:", error);
    }
  }, [dispatch, searchTerm]);

  useEffect(() => {
    getGlobalPermissions();
  }, [searchTerm]);

  const handleSeachChange = (e) => {
    setsearchTerm(e?.target?.value.trim());
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_id) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_id))
        .then((response) => {
          if (response?.success === true) {
            const data = response?.data?.role_name;
            setPermissionRole(data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.error("Error :", error);
        });
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole();
  }, []);

  // const onCheckboxChange = (e, item, key, whichArray) => {
  //   const isChecked = e; // true or false

  //   const updatedArray = allGlobalNoti[whichArray].map((currentItem) =>
  //     currentItem === item
  //       ? {
  //       ...currentItem,
  //       permissions: {
  //         ...currentItem.permissions,
  //         [key]: isChecked, // Update the specific permission key inside permissions
  //       }
  //     }
  //       : currentItem
  //   );

  //   // Create a new state object with the updated array
  //   const updatedGlobalNoti = { ...allGlobalNoti, [whichArray]: updatedArray };
  //   setAllGlobalNoti(updatedGlobalNoti);

  //   const changedItem = updatedArray.find(
  //     (currentItem) => currentItem?.notifi_global_setting_id === item?.notifi_global_setting_id
  //   );
  //   const payload = {
  //     ...item, // Spread the item first
  //     notifi_global_setting_id: changedItem?.notifi_global_setting_id,
  //     [key]: isChecked,
  //   };

  //   dispatch(UpdateGlobelNotifications(payload))
  //     .then((response) => {
  //       if (response?.success) {
  //         // Handle success logic here
  //       } else {
  //         console.error("Failed to update notification");
  //         // Handle failure logic here
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error while updating notification:", error);
  //       // Handle error case here
  //     });
  // };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 2nd
  // const onCheckboxChange = (e, item, key, whichArray) => {
  //   const isChecked = e; // true or false

  //   // Map through the specific array to update the permissions
  //   const updatedArray = allGlobalNoti[whichArray].map((currentItem) =>
  //     currentItem === item
  //       ? {
  //         ...currentItem,
  //         permissions: {
  //           ...currentItem.permissions,
  //           [key]: isChecked, // Ensure the update is scoped to the permissions object
  //         },
  //       }
  //       : currentItem
  //   );

  //   // Create a new state object with the updated array
  //   const updatedGlobalNoti = { ...allGlobalNoti, [whichArray]: updatedArray };
  //   setAllGlobalNoti(updatedGlobalNoti);

  //   // Find the updated item
  //   const changedItem = updatedArray.find(
  //     (currentItem) =>
  //       currentItem?.notifi_global_setting_id === item?.notifi_global_setting_id
  //   );

  //   // Prepare the payload
  //   const payload = {
  //     ...item, // Spread the original item properties
  //     permissions: {
  //       ...item.permissions,
  //       [key]: isChecked, // Update only the permissions key in the payload
  //     },
  //   };

  //   // Dispatch the update action
  //   dispatch(UpdateGlobelNotifications(payload))
  //     .then((response) => {
  //       if (response?.success) {
  //         // Handle success logic here
  //       } else {
  //         console.error("Failed to update notification");
  //         // Handle failure logic here
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error while updating notification:", error);
  //       // Handle error case here
  //     });
  // };

  const onCheckboxChange = (e, item, key, whichArray) => {
    const isChecked = e; // true or false

    // Map through the specific array to update the permissions
    const updatedArray = allGlobalNoti[whichArray].map((currentItem) =>
      currentItem === item
        ? {
            ...currentItem,
            permissions: {
              ...Object.keys(currentItem.permissions).reduce(
                (acc, permissionKey) => {
                  acc[permissionKey] = {
                    enabled:
                      permissionKey === key
                        ? isChecked // Update the specific key's enabled value
                        : currentItem.permissions[permissionKey]?.enabled ??
                          currentItem.permissions[permissionKey],
                  };
                  return acc;
                },
                {}
              ),
            },
          }
        : currentItem
    );

    // Create a new state object with the updated array
    const updatedGlobalNoti = { ...allGlobalNoti, [whichArray]: updatedArray };
    setAllGlobalNoti(updatedGlobalNoti);

    // Find the updated item
    const changedItem = updatedArray.find(
      (currentItem) =>
        currentItem?.notifi_global_setting_id === item?.notifi_global_setting_id
    );

    // Prepare the payload
    const payload = {
      ...item,
      permissions: {
        ...Object.keys(item.permissions).reduce((acc, permissionKey) => {
          acc[permissionKey] = {
            enabled:
              permissionKey === key
                ? isChecked // Update the specific key's enabled value
                : item.permissions[permissionKey]?.enabled ??
                  item.permissions[permissionKey],
          };
          return acc;
        }, {}),
      },
    };

    // Dispatch the update action
    dispatch(UpdateGlobelNotifications(payload, ipAddress))
      .then((response) => {
        if (response?.success) {
          // Handle success logic here
        } else {
          console.error("Failed to update notification");
        }
      })
      .catch((error) => {
        console.error("Error while updating notification:", error);
      });
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <Heading>Notifications</Heading>
        <div className="flex gap-4">
          <div className="w-80 h-10 ">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img
                  src="/assets/icons-svg/search-lg.svg"
                  alt=""
                  className="h-5"
                />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
          {/* <div className='w-32'>
            <BtnPrimary onClick={submitNotification}>Save</BtnPrimary>
          </div> */}
        </div>
      </div>
      <div className="border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="flex justify-between gap-1">
                <span className="flex items-center gap-1">
                  Notifications
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </Th>
              <Th>Directors</Th>
              <Th>Client Services</Th>
              <Th>Support Team</Th>
            </Tr>
          </THead>

          <TBody>
            {Object.keys(allGlobalNoti).map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                {/* Main row */}
                <Tr
                  onClick={() => toggleRow(section)}
                  className={`cursor-pointer ${
                    expandedRows[section] === true && "bg-blue-50"
                  }`}
                >
                  <Td className="flex gap-2 items-center">
                    {expandedRows[section] === false ||
                    expandedRows[section] === undefined ? (
                      <svg
                        className="cursor-pointer"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="24.5"
                          width="24"
                          height="24"
                          rx="12"
                          transform="rotate(-90 0 24.5)"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M9 18.5L15 12.5L9 6.5"
                          stroke="#5786CC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="cursor-pointer"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="0.5"
                          width="24"
                          height="24"
                          rx="12"
                          fill="#D2EBFF"
                        />
                        <path
                          d="M6 9.5L12 15.5L18 9.5"
                          stroke="#5786CC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    <span>
                      {section?.charAt(0)?.toUpperCase() + section?.slice(1)}
                    </span>
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>

                {/* Expanded content */}
                {expandedRows[section] && (
                  <>
                    {allGlobalNoti[section]?.map((item, idx) => (
                      <Tr key={idx}>
                        <Td>{item?.permission_name}</Td>
                        <Td>
                          <Checkbox
                            disabled={permissionRole === "support team"}
                            checked={item?.permissions?.superadmin?.enabled}
                            onChange={(e) =>
                              onCheckboxChange(e, item, "superadmin", section)
                            }
                          />
                        </Td>
                        <Td>
                          <Checkbox
                            disabled={permissionRole === "support team"}
                            checked={
                              item?.permissions?.client_service_team?.enabled
                            }
                            onChange={(e) =>
                              onCheckboxChange(
                                e,
                                item,
                                "client_service_team",
                                section
                              )
                            }
                          />
                        </Td>
                        <Td>
                          <Checkbox
                            disabled={permissionRole === "support team"}
                            checked={item?.permissions?.support_team?.enabled}
                            onChange={(e) =>
                              onCheckboxChange(e, item, "support_team", section)
                            }
                          />
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </TBody>
        </Table>
      </div>
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
    </React.Fragment>
  );
};

export default Notifications;
