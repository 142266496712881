import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAdminSpecificContractorSlice";
export const GetWorkerOverviewDetailsById =
  (apiData, workerPortal) => async (dispatch) => {
    /*** API Call Action ***/
    let pathApi;
    if (workerPortal == "contractPortal") {
      pathApi = "provider";
    } else if (workerPortal == "workerPortal") {
      pathApi = "worker";
    } else {
      pathApi = "admin/worker";
    }
    try {
      //   dispatch(fetchData())
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          organisationid:
            workerPortal == "contractPortal" && storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/${pathApi}/${
          workerPortal == "contractPortal"
            ? "GetProviderUserDetailsById"
            : "GetWorkerProfileById"
        }`,
        params: apiData,
      });
      return response.data;
      //   dispatch(fetchDataSuccess(response.data))
    } catch (error) {
      return error.message;
      //   dispatch(fetchDataError(error.message))
    }
  };
