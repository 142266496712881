import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Heading from "../../../../../common/element/Heading";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import UserList from "./UserList";
import { debounce } from "../../../../../common/snippet/Debouncing";
import Label from "../../../../../common/element/Label";
import Input from "../../../../../common/element/Input";
import Checkbox from "../../../../../common/element/Checkbox/Checkbox";
import * as userActions from "../../../../../actions/index";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
// import { notify } from "react-notify-toast";
import Segment from "../../../../../common/element/Segment";
import ReactPaginate from "react-paginate";
import Validations from "../../../../../common/404/Validations";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import userPlusIcon from "../../../../../assets/icons-svg/user-plus-01.svg";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import AlertPage from "../../../../../common/snippet/AlertPage";
import moment from "moment";
import pagination, { Pagination } from "../../../../../common/pagination";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";

const Users = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  let permission_info;
  if (loginData != null) {
    role_assignment_id = loginData?.data?.role_assignment_id;
  }
  if (loginData.data != null) {
    permission_info = JSON.parse(localStorage.getItem("permissionData"));
  }

  // State variables
  const [filterpage, setfilterpage] = useState(1);
  const [permissionRole, setPermissionRole] = useState("");
  const [filterlimit, setfilterlimit] = useState(10);
  const [pageLoader, setPageLoader] = useState(false)
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("first_name");
  const [searchTerm, setsearchTerm] = useState("");
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogIsOpenReInvite, setIsOpenReInvite] = useState(false);
  const [emailAddress, setEmailAddress] = useState();
  const [countData, setCountData] = useState(0);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userRole, setUserRole] = useState();
  // New state to keep track of the selected role
  const [selectedRole, setSelectedRole] = useState(null);
  const [userData, setUserData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [viewType, setViewType] = useState("all")
  const [emailError, setEmailError] = useState("");
  const [firstnameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [userType, setUserType] = useState("");
  const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
  const [getApiObjectData, setGetApiObjectData] = useState({})

  // const [showAlert, setShowAlert] = useState("");
  // Select all user roles from Redux state
  const allRoles = useSelector((state) => state.allRole.allRole);
  // const [showAlert, setShowAlert] = useState(false);
  // const setAlert = (e) => {
  //   setShowAlert({
  //     type: e.type,
  //     title: e.title,
  //     showIcon: e.showIcon,
  //     closable: e.true
  //   });
  // }

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(setBreadcrumbs([{ label: "All Users", url: "/admin/users" }]));
  }, []);

  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = () => {
    if (!isSaving) {
      // Close the dialog and reset form values
      setIsOpen(false);
      setLastName("");
      setFirstName("");
      setEmailAddress("");
      setEmailError("");
      setFirstNameError("");
      setLastNameError("");
      setRoleError("");
      setIsSaving(false);
    }
  };
  const onDialogOk = () => {
    // Close the dialog
    setIsOpen(false);
  };
  const onDialogCloseReInvite = () => {
    setIsOpenReInvite(false);
    onDialogOk();
  };
  // Fetch user data from the server
  const GetAllConserveTeamAndInvites = useCallback(async () => {
    setPageLoader(true)
    dispatch(
      userActions.GetAllConserveTeamAndInvites(
        userType,
        searchTerm,
        filterpage,
        filterorder,
        filtersort,
        filterlimit,
        viewType,
      )
    ).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data?.rows;
        setCountData(response?.data?.count)
        let user_data = [];
        // Process and format the user data
        //This is for All and Invited User Data
        for (let key in arrayData) {
          const currentTime = moment();
          let loginData =
            arrayData[key]?.invite_individual?.user_data?.last_login;
          let lastLogin = moment.duration(currentTime.diff(loginData));
          if ((arrayData?.length && arrayData?.[0]?.invite_individual?.first_name))
            user_data.push({
              first_name: arrayData[key]?.invite_individual?.first_name,
              last_name: arrayData[key]?.invite_individual?.last_name,
              invite_status: arrayData[key]?.invite_status,
              role_name:
                arrayData[key]?.invite_individual?.user_data?.role_assign
                  ?.role?.role_name === "support team"
                  ? "Customer Support"
                  : arrayData[key]?.invite_individual?.user_data?.role_assign
                    ?.role?.role_name === "client service team"
                    ? "Client Service Team"
                    : arrayData[key]?.invite_individual?.user_data?.role_assign
                      ?.role?.role_name === "super admin"
                      ? "Super Admin"
                      : arrayData[key]?.invite_role?.role_name === "support team"
                        ? "Customer Support"
                        : arrayData[key]?.invite_role?.role_name === "client service team"
                          ? "Client Service Team"
                          : arrayData[key]?.invite_role?.role_name === "super admin"
                            ? "Super Admin"
                            : "",
              last_login: arrayData[key]?.invite_individual?.user_data?.last_login
                ? lastLogin.asSeconds() < 60
                  ? "Just now"
                  : lastLogin.asMinutes() < 60
                    ? `${Math.floor(lastLogin.asMinutes())} ${lastLogin.asMinutes() === 1 ? "minute" : "minutes"
                    } ago`
                    : lastLogin.asHours() < 24
                      ? `${Math.floor(lastLogin.asHours())} ${lastLogin.asHours() === 1 ? "hour" : "hours"
                      } ago`
                      : lastLogin.asDays() < 2
                        ? "Yesterday"
                        : `${Math.floor(lastLogin.asDays())} ${lastLogin.asDays() === 1 ? "day" : "days"
                        } ago`
                : "",
              invited_by:
                (arrayData[key]?.invited_by?.individual?.first_name || "")
                  .charAt(0)
                  .toUpperCase() +
                (arrayData[key]?.invited_by?.individual?.first_name || "").slice(
                  1
                ) +
                " " +
                (arrayData[key]?.invited_by?.individual?.last_name || "")
                  .charAt(0)
                  .toUpperCase() +
                (arrayData[key]?.invited_by?.individual?.last_name || "").slice(
                  1
                ),
              // arrayData[key]?.invited_by?.individual?.first_name +
              // " " +
              // arrayData[key]?.invited_by?.individual?.last_name,
              individual_id: arrayData[key]?.individual_id,
              invite_id: arrayData[key]?.invite_id,

            });
        }

        // this is filtered data Super Admin/Customer Support/Client Services/Invited
        for (let key in arrayData) {
          const currentTime = moment();
          let loginData = arrayData[key]?.user_data?.last_login;
          let lastLogin = moment.duration(currentTime.diff(loginData));
          if (arrayData?.length && arrayData?.[0]?.first_name) {
            user_data.push({
              first_name: arrayData[key]?.first_name,
              last_name: arrayData[key]?.last_name,
              invite_status: arrayData[key]?.invite_status,
              role_name:
                arrayData[key]?.user_data?.role_assign
                  ?.role?.role_name === "support team"
                  ? "Customer Support"
                  : arrayData[key]?.user_data?.role_assign
                    ?.role?.role_name === "client service team"
                    ? "Client Service Team"
                    : arrayData[key]?.user_data?.role_assign
                      ?.role?.role_name === "super admin"
                      ? "Super Admin"
                      : "",
              last_login: arrayData[key]?.user_data?.last_login
                ? lastLogin.asSeconds() < 60
                  ? "Just now"
                  : lastLogin.asMinutes() < 60
                    ? `${Math.floor(lastLogin.asMinutes())} ${lastLogin.asMinutes() === 1 ? "minute" : "minutes"
                    } ago`
                    : lastLogin.asHours() < 24
                      ? `${Math.floor(lastLogin.asHours())} ${lastLogin.asHours() === 1 ? "hour" : "hours"
                      } ago`
                      : lastLogin.asDays() < 2
                        ? "Yesterday"
                        : `${Math.floor(lastLogin.asDays())} ${lastLogin.asDays() === 1 ? "day" : "days"
                        } ago`
                : "",
              invited_by:
                (arrayData[key]?.indCreatedBy?.individual?.first_name || "")
                  .charAt(0)
                  .toUpperCase() +
                (arrayData[key]?.indCreatedBy?.individual?.first_name || "").slice(
                  1
                ) +
                " " +
                (arrayData[key]?.indCreatedBy?.individual?.last_name || "")
                  .charAt(0)
                  .toUpperCase() +
                (arrayData[key]?.indCreatedBy?.individual?.last_name || "").slice(
                  1
                ),

              individual_id: arrayData[key]?.individual_id,
              edit_user: true
              // invite_id: arrayData[key]?.invite_id,
            });
          }

        }
        setUserData(user_data);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {

      }
    }).catch((error) => {
      console.error('Handle error:', error);

    }).finally(() => {
      setPageLoader(false);
    });
    // eslint-disable-next-line
  }, [dispatch, userType, viewType, searchTerm, filterpage, filterorder, filtersort, filterlimit,pageLoader]);

  //call permission api
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_id) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_id))
        .then(
          (response) => {
            if (response?.success === true) {
              const data = response?.data?.role_name;
              setPermissionRole(data)
            } else if (response?.status === 401) {
              handleUnauthorizedAccess()
            }
          }
        )
        .catch((error) => {
          console.error('Error :', error);

        })
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole();
  }, []);

  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    GetAllConserveTeamAndInvites(selected + 1, filterlimit);
  };

  useEffect(() => {
    GetAllConserveTeamAndInvites();
    setGetApiObjectData({
      role_name: userType,
      search: searchTerm,
      page: filterpage,
      limit: filterlimit,
      sort: filtersort,
      order: filterorder,
      viewType: viewType
    })
  }, [userType, searchTerm, filterpage, filterorder, viewType, filtersort, filterlimit]);

  const handleSeachChange = (e) => {
    // Handle search input change
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  // const handlecheckbox = (e, option) => {
  //   // Handle role checkbox selection
  //   if (selectedRole === option) {
  //     // If the same option is clicked again, deselect it
  //     setSelectedRole(null);
  //     setUserRole("");
  //   } else {
  //     setSelectedRole(option);
  //     const filteredData = allRoles.filter((item) => item.role_name === option);
  //     setUserRole(filteredData);
  //   }
  // };
  const handleCheckbox = (option) => {
    // Determine if the option is currently selected
    const isSelected = selectedRole === option;

    // Toggle the selection
    if (isSelected) {
      setSelectedRole(null);
      setUserRole("");
    } else {
      setSelectedRole(option);
      const filteredData = allRoles.filter((item) => item?.role_name === option);
      setUserRole(filteredData);
    }
  };

  const inputchangehandler = (e, identifier) => {
    // Handle input change for various fields
    if (identifier === "emailAddress") {
      setEmailAddress(e);
      setEmailError("");
    }
    if (identifier === "firstName") {
      setFirstName(e);
    }
    if (identifier === "lastName") {
      setLastName(e);
    }
  };

  const submitDocumetType = (e) => {
    e.preventDefault();

    //Validate Email
    const emailValidation = Validations({
      value: emailAddress,
      Validations: { required: true, email: true },
    });
    if (!emailValidation.valid) {
      setEmailError(emailValidation.error_msg);
    }
    const firstnameValidation = Validations({
      value: firstName,
      Validations: { required: true },
    });
    const lastnameValidation = Validations({
      value: lastName,
      Validations: { required: true },
    });
    if (!firstnameValidation.valid) {
      setFirstNameError(firstnameValidation.error_msg);
    }
    if (!lastnameValidation.valid) {
      setLastNameError(lastnameValidation.error_msg);
    }
    let roleID = userRole && userRole[0]?.role_id;
    const roleValidation = Validations({
      value: roleID,
      Validations: { required: true },
    });
    if (!roleValidation.valid) {
      setRoleError(roleValidation.error_msg);
    }

    if (
      emailValidation.valid === true &&
      firstnameValidation.valid === true &&
      lastnameValidation.valid === true &&
      roleValidation.valid === true
    ) {
      const payload = {
        email: emailAddress,
        first_name: firstName,
        last_name: lastName,
        // individual_type_id: "e39375dc-b9fc-4b5c-a676-9545a2fd3520",
        individual_type: "conserve user",
        role_id: roleID,

      };
      setIsSaving(true);
      props?.onInviteUser(payload,ipAddress).then((response) => {
        if (response?.success === true) {
          GetAllConserveTeamAndInvites();
          setIsOpenReInvite(true)
          setSelectedRole("");
          setEmailError("");
          setFirstNameError("");
          setLastNameError("");
          setRoleError("");
          setIsSaving(false);
        } else if (response?.status === 409) {
          setEmailError(response?.message);
          // setSelectedRole("");
          setIsSaving(false)
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
          setIsSaving(false);
        } else {
        }
      });
    }
  };

  // to select different tab in user type list
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
      if (val[0] === "view all") {
        setUserType("");
        setfilterpage(1);
        setViewType("all")
      } else {
        setUserType(val[0]);
        // setfilterpage(1);
        if (val[0] == "super admin") {
          setViewType("superAdmin")
        }
        else if (val[0] == "support team") {
          setViewType("supportTeam")
        }
        else if (val[0] == "client service team") {
          setViewType("clientService")
        }
        else if (val[0] == "invited") {
          setViewType("invited")
        }
      }
    },
    [setSingleSegmentValue]
  );

  return (
    <React.Fragment>
      {/* {showAlert && 
      <AlertPage 
      showIcon={showAlert.showIcon} 
      type={showAlert.type} 
      title={showAlert.title} 
      triggerByToast={showAlert.triggerByToast}
      // closable={showAlert.closable}
      >
      </AlertPage> 
      } */}
      <div className="flex justify-between mb-5  flex-wrap gap-y-2.5">
        <Heading>All Users</Heading>
        <div className="flex gap-3">

          {permission_info ? (
            permission_info.role_name !== "support team" ? (
              <BtnPrimary
                onClick={() => openDialog()}
                className="flex w-32 items-center justify-center gap-2 text-12"
              >
                Invite user
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.712 4.167v11.666M4.878 10h11.667"
                    stroke="#fff"
                    strokeWidth="1.667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </BtnPrimary>
            ) : (
              ""
            )
          ) : (
            <BtnPrimary
              onClick={() => openDialog()}
              className="flex w-32 items-center justify-center gap-2 text-12"
            >
              Invite user
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.712 4.167v11.666M4.878 10h11.667"
                  stroke="#fff"
                  strokeWidth="1.667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </BtnPrimary>
          )}


        </div>
      </div>
      <div className="flex justify-between mb-5  flex-wrap gap-y-2.5">
        <Segment
          className="docType-btn"
          value={singleSegmentValue}
          onChange={(val) => onSingleSelectionSegmentChange(val)}
        >
          <Segment.Item value="view all">View All</Segment.Item>
          <Segment.Item value="super admin">Super Admin</Segment.Item>
          <Segment.Item value="support team">Customer Support</Segment.Item>
          <Segment.Item value="client service team">Client Services</Segment.Item>
          <Segment.Item value="invited">Invited</Segment.Item>
        </Segment>
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={searchIcon} alt="" className="h-5" />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
            />
          </div>
        </div>
      </div>
      <UserList
        pageLoader={pageLoader}
        // setUserData={setUserData}
        userData={userData}
        handleSort={handleSort}
        permissionRole={permissionRole}
        // setTotalPages={setTotalPages}
        // setCountData={setCountData}
        filtersort={filtersort}
        filterorder={filterorder}
        // getApiObjectData={getApiObjectData}
        GetAllConserveTeamAndInvites={GetAllConserveTeamAndInvites}
      // setShowAlert={setShowAlert}
      // setAlert={(e) => setAlert(e)}
      />
      {pageLoader === false ? (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={userData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      ) : (
        <div>
          <ThreeDotBouncing />
        </div>
      )}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        width={900}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={userPlusIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Invite user to join Conserve team
            </h5>
          </div>
        </div>

        <div className="px-8">
          <h4 className="text-base text-gray-900 font-semibold mb-5">
            User details
          </h4>
          <div className="flex mb-5 flex-wrap">
            <Label className="font-semibold w-full mb-1.5 text-xs">
              Email address*
            </Label>
            <Input
              placeholder="Enter user’s email address"
              className="h-9 flex-1"
              inputchangehandler={(e) => inputchangehandler(e, "emailAddress")}
            />
            {emailError && (
              <div className="text-red-500 text-sm mt-1">{emailError}</div>
            )}
          </div>

          <div className="flex gap-2 mb-5">
            <div className="flex flex-1 flex-wrap">
              <Label className="w-full font-semibold text-xs mb-1.5 ">
                First name*
              </Label>
              <Input
                placeholder="Enter user’s first name"
                className="h-9 w-full border"
                inputchangehandler={(e) => inputchangehandler(e, "firstName")}
              />
              {firstnameError && (
                <div className="text-red-500 text-sm mt-1">
                  {firstnameError}
                </div>
              )}
            </div>
            <div className="flex flex-1 flex-wrap">
              <Label className="w-full text-xs font-semibold mb-1.5 ">
                Last name*
              </Label>
              <Input
                placeholder="Enter user’s last name"
                className="h-9 w-full"
                inputchangehandler={(e) => inputchangehandler(e, "lastName")}
              />
              {lastnameError && (
                <div className="text-red-500 text-sm mt-1">{lastnameError}</div>
              )}
            </div>
          </div>

          <hr className="mt-5 mb-5" />
          <h4 className="text-base text-gray-900 font-semibold mb-5">
            User access*
          </h4>
          <div className="flex item-start mb-6">
            <Checkbox
              onChange={() => handleCheckbox("super admin")}
              checked={selectedRole === "super admin"}
              value="super admin"
            />
            <div className="flex flex-col">
              <span className="font-medium text-gray-700">Super Admin</span>
              <span className="text-xs text-gray-600">
                Description for super admin In aliquet suscipit gravida molestie in fermentum vitae quis. Leo donec mattis congue.
              </span>
            </div>
          </div>

          <div className="flex item-start mb-6">
            <Checkbox
              onChange={() => handleCheckbox("client service team")}
              checked={selectedRole === "client service team"}
              value="client service team"
            />
            <div className="flex flex-col">
              <span className="font-medium text-gray-700">Client Services Team</span>
              <span className="text-xs text-gray-600">
                Description for admin In aliquet suscipit gravida molestie in fermentum vitae quis. Leo donec mattis congue.
              </span>
            </div>
          </div>

          <div className="flex item-start mb-6">
            <Checkbox
              onChange={() => handleCheckbox("support team")}
              checked={selectedRole === "support team"}
              value="support team"
            />
            <div className="flex flex-col">
              <span className="font-medium text-gray-700">Support Team</span>
              <span className="text-xs text-gray-600">
                Description for admin In aliquet suscipit gravida molestie in fermentum vitae quis. Leo donec mattis congue.
              </span>
            </div>
          </div>

          {!selectedRole && roleError && (
            <div className="text-red-500 text-sm mt-1">{roleError}</div>
          )}

        </div>

        <div className="flex dailog-footer justify-end gap-2">
          <BtnLight disabled={isSaving} className="px-4" variant="plain" onClick={onDialogClose}>
            Close
          </BtnLight>
          <BtnPrimary
            className="px-4"
            variant="solid"
            onClick={submitDocumetType}
            disabled={isSaving}
          >
            {isSaving ? <ThreeDotLoader /> : "Invite"}

          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogIsOpenReInvite}
        onClose={onDialogCloseReInvite}
        onRequestClose={onDialogCloseReInvite}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onDialogCloseReInvite()}>Close</BtnPrimary>
        </div>
      </Dialog>

    </React.Fragment>
  );
};
function mapStateToProps(state) {
  return {
    loader: userActions.InviteUser.loader,
  };
}
const mapDispatchToProps = {
  onInviteUser: userActions.InviteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
