import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const UpdateCompanyInduction =
  (ApiData, ipAddress) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      let formdata = new FormData();
      for (let key in ApiData) {
        if (
          key !== "company_ind_publish_date" &&
          key !== "function_assignment_id"
        ) {
          // For other properties, append the value directly
          formdata.append(key, ApiData[key]);
        }
      }
      const response = await axios({
        method: "put",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/client/UpdateCompanyInduction`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
