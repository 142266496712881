import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
// import { notify } from "react-notify-toast";
import Heading from "../../../../../common/element/Heading";
import Segment from "../../../../../common/element/Segment";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../common/element/Table";
import * as userActions from "../../../../../actions/index";
import ReactPaginate from "react-paginate";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import fileCheck from "../../../../../assets/icons-svg/file-check.svg";
import plusIcon from "../../../../../assets/icons-svg/plus.svg";
import Validations from "../../../../../common/404/Validations";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { debounce } from "../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";
import Dropdown from "../../../../../common/dropdown";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
import { useSelector } from "react-redux";

const DocType = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
  }
  let permission_info;
  if (loginData.data != null) {
    permission_info = JSON.parse(localStorage.getItem("permissionData"));
  }
  // state for filter
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [permissionRole, setPermissionRole] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [filtersort, setFiltersort] = useState("created_date");
  const [filterorder, setFilterOrder] = useState("desc");
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [countData, setCountData] = useState(0);
  const [refetchCustomfield, setRefetchCustomfield] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [showDoc, setShowDoc] = useState(false);

  const [showOther, setShowOtherDoc] = useState(false);
  //state for form data
  // const [document_type, setdocument_type] = useState("");
  // const [singleSegmentValue, setSingleSegmentValue] = useState(["View All"]);
  const [typeDoc, setTypeDoc] = useState("");
  const [typeRecipient, setTypeRecipient] = useState("");
  const [typeExpiry, setTypeExpiry] = useState("");
  const [docName, setDocName] = useState("");
  const [docCustomData, setDocCustomData] = useState({
    req_issuer: false,
    req_policy_no: false,
    req_amount_insured: false,
    req_expiry_date_notes: false,
    custom_fields: [],
  });

  // state for All document data
  const [docData, setDocData] = useState("");
  // state to manager form errors
  const [formErrors, setFormErrors] = useState({
    doc_type_name: "",
    recipient_type: "",
    allow_expiry: "",
    special_char: "",
  });
  const { Tr, Th, Td, THead, TBody } = Table;
  // Set breadcrumbs for the page
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Doc Types",
          url: "/admin/doc-type",
          home: "/admin/dashboard",
        },
      ])
    );
  }, []);

  // Clear form errors
  const clearFormErrors = () => {
    setFormErrors({
      doc_type_name: "",
      recipient_type: "",
      allow_expiry: "",
      special_char: "",
    });
  };
  const clearFormData = () => {
    setDocName("");
    setTypeDoc("");
    setTypeRecipient("");
    setTypeExpiry("");
  };
  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
      clearFormData();
      clearFormErrors();
      setDocCustomData({
        req_issuer: false,
        req_policy_no: false,
        req_amount_insured: false,
        req_expiry_date_notes: false,
        custom_fields: [],
      });
    }
  };
  // Open the dialog for adding documents
  const openAddDialog = () => {
    if (!buttonDisabled) {
      setAddOpen(true);
      setIsOpen(false);
    }
  };

  // Close the dialog for adding documents
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    // clearFormData()
    AdminGetAllDocumentType();
  };

  // Display the insurance input
  const showInsurance = () => {
    setShowDoc(true);
    setShowOtherDoc(false);
  };

  // Display other input
  const showOtherDoc = () => {
    setShowOtherDoc(true);
    setShowDoc(false);
  };

  // Handle changes in the different tab of document list
  // const onSingleSelectionSegmentChange = useCallback(
  //   (val) => {
  //     setSingleSegmentValue(val);
  //     if (val[0] === "View All") {
  //       setdocument_type("");
  //       setfilterpage(1);
  //     } else {
  //       setdocument_type(val[0]);
  //       setfilterpage(1);
  //     }
  //   },
  //   [setSingleSegmentValue]
  // );

  // Handle changes in the document type selection
  // const onaddDoctypeSelectionChange = useCallback(
  //   (val) => {
  //     setTypeDoc(val);
  //   },
  //   [setTypeDoc]
  // );
  // Handle changes in the recipient selection
  const onaddDocRecipientChange = useCallback(
    (val) => {
      setTypeRecipient(val);
    },
    [setTypeRecipient]
  );
  // Handle changes in the expiry selection
  const onaddDocExpiryChange = useCallback(
    (val) => {
      setTypeExpiry(val);
    },
    [setTypeExpiry]
  );

  const handleSort = (column) => {
    // Handle sorting of the service list
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  // Handle page change in the document list
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    // AdminGetAllDocumentType(selected + 1, filterlimit);
  };

  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  // Get all document
  const AdminGetAllDocumentType = useCallback(async () => {
    dispatch(
      userActions.AdminGetAllDocumentType(
        searchTerm,
        filterpage,
        filterorder,
        filtersort,
        filterlimit
      )
    )
      .then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data?.rows;
          setCountData(response?.data?.count);
          setDocData(arrayData);
          setTotalPages(Math.ceil(response?.data?.count / filterlimit));
          setLoadData(true);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
          setLoadData(true);
        } else {
          setLoadData(true);
        }
      })
      .catch((error) => {
        setLoadData(true);
        console.error("Error:", error);
      });
  }, [
    dispatch,
    // document_type,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
  ]);

  useEffect(() => {
    AdminGetAllDocumentType();
  }, [
    // document_type,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
  ]);

  //call permission api
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_id) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_id))
        .then((response) => {
          if (response?.success === true) {
            const data = response?.data?.role_name;
            setPermissionRole(data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole();
  }, []);

  // Set form error messages
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  // Submit the document
  const submitDocumetType = (e) => {
    e.preventDefault();

    const nameValidation = Validations({
      value: docName,
      Validations: { required: true },
    });
    const recipientValidation = Validations({
      value: typeRecipient,
      Validations: { required: true },
    });
    const expiryValidation = Validations({
      value: typeExpiry,
      Validations: { required: true },
    });

    if (!nameValidation.valid) {
      setFormError("doc_type_name", nameValidation.error_msg);
    }
    if (!nameValidation.valid) {
      setFormError("recipient_type", recipientValidation.error_msg);
    }
    if (!expiryValidation.valid) {
      setFormError("allow_expiry", expiryValidation.error_msg);
    }

    if (
      nameValidation.valid === true &&
      recipientValidation.valid === true &&
      expiryValidation.valid === true
    ) {
      let customFields = JSON.stringify(docCustomData.custom_fields);
      const payload = {
        doc_type_name: docName,
        recipient_type: typeRecipient[0],
        allow_expiry: typeExpiry[0],
        ...docCustomData,
        custom_fields: customFields,
      };
      setButtonDisabled(true);
      props
        ?.onAddDocumentType(payload, ipAddress)
        .then((response) => {
          if (response?.success === true) {
            clearFormErrors();
            clearFormData();
            setButtonDisabled(false);
            // AdminGetAllDocumentType();
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setButtonDisabled(false);
          openAddDialog();
        });
    }
  };

  const onCheckboxChange = (name, type) => {
    if (name == "addCustom") {
      docCustomData.custom_fields.push("");
      setRefetchCustomfield(!refetchCustomfield);
    } else {
      setDocCustomData((prevalue) => {
        return {
          ...prevalue,
          [name]: type,
        };
      });
    }
  };

  const deleteCustomField = (index) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [`special_char_${index}`]: "",
    }));
    docCustomData.custom_fields.splice(index, 1);
    setRefetchCustomfield(!refetchCustomfield);
  };

  const updateCustomFiled = (e, index) => {
    // Regular expression pattern to allow only numbers, alphabets, spaces, hyphen
    var pattern = /^[a-zA-Z0-9\- ]+$/;

    if (e.target.value === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`special_char_${index}`]: "",
      }));
    } else {
      if (!pattern.test(e.target.value)) {
        const nameValidation = Validations({
          value: "",
          Validations: { required: true },
        });

        if (!nameValidation.valid) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`special_char_${index}`]: "Special characters not valid",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`special_char_${index}`]: "",
          }));
        }
        return;
      }
    }

    // Update the custom field if input is valid
    docCustomData.custom_fields[index] = e.target.value;
    setRefetchCustomfield(!refetchCustomfield);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [`special_char_${index}`]: "",
    }));
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      <div className="flex items-center justify-between mb-7 gap-3">
        <Heading>Document Types</Heading>
        {permission_info && permission_info?.role_name !== "support team" && (
          <BtnPrimary
            onClick={() => openDialog()}
            className="w-36 flex items-center justify-center text-12"
            variant="solid"
          >
            Add document
            <span className="ml-3">
              <img src={plusIcon} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        )}
      </div>
      <div className="flex justify-between mb-5 flex-wrap gap-y-2.5">
        {/* <Segment
          className="docType-btn"
          value={singleSegmentValue}
          onChange={(val) => onSingleSelectionSegmentChange(val)}
        >
          <Segment.Item value="View All">View All</Segment.Item>
          <Segment.Item value="insurance">Insurance</Segment.Item>
          <Segment.Item value="other">Other</Segment.Item>
        </Segment> */}
        <div className="flex gap-2">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className=" border rounded-lg border-gray-200">
          <Table>
            <THead>
              <Tr>
                <Th>
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("doc_type_name")}
                  >
                    Document Name
                    <svg
                      className={`transform ${
                        filtersort === "doc_type_name" && filterorder === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th className="w-44">Provider type</Th>
              </Tr>
            </THead>
            <TBody>
              {docData
                ? docData.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item?.doc_type_name}</Td>
                      <Td>
                        {(item?.recipient_type || "").charAt(0).toUpperCase() +
                          (item?.recipient_type || "").slice(1)}
                      </Td>
                    </Tr>
                  ))
                : null}
            </TBody>
          </Table>
        </div>
      </div>

      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}

      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={docData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}
      {/* </Layout> */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          {/* {showDoc && ( */}
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/5">
              Document name*
            </label>
            <div className="w-4/5 pl-2">
              <input
                type="text"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter document name here"
                value={docName}
                onChange={(e) => setDocName(e.target.value)}
              />
              {!docName && formErrors.doc_type_name && (
                <p className="text-xs text-red-600 mt-2">
                  {formErrors.doc_type_name}
                </p>
              )}
            </div>
          </div>
          {/* )} */}
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/5">
              Recipient*
            </label>
            <div className="w-4/5 pl-2">
              <Segment
                className="docType-btn"
                value={typeRecipient}
                onChange={(val) => onaddDocRecipientChange(val)}
              >
                <Segment.Item value="provider">
                  <span>Provider</span>
                </Segment.Item>
                <Segment.Item value="worker">
                  <span>Worker</span>
                </Segment.Item>
              </Segment>
              {!typeRecipient && formErrors.recipient_type && (
                <p className="text-xs text-red-600 mt-2">
                  {formErrors.recipient_type}
                </p>
              )}
            </div>
          </div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/5">
              Allow expiry date*
            </label>
            <div className="w-4/5 pl-2">
              <Segment
                className="docType-btn"
                value={typeExpiry}
                onChange={(val) => onaddDocExpiryChange(val)}
              >
                <Segment.Item value="true">
                  <span>Yes</span>
                </Segment.Item>
                <Segment.Item value="false">
                  <span>No</span>
                </Segment.Item>
              </Segment>
              {!typeExpiry && formErrors.allow_expiry && (
                <p className="text-xs text-red-600 mt-2">
                  {formErrors.allow_expiry}
                </p>
              )}
            </div>
          </div>

          {/* const [docCustomData, setDocCustomData] = useState({
    req_issuer:false,
    req_policy_no:false,
    req_amount_insured:false,
    req_expiry_date_notes:true,
    custom_fields:[]
}); */}
          {docCustomData.req_issuer && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/5">Issuer</label>
              <div className="w-4/5 pl-2 flex gap-2.5">
                <input
                  type="text"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Field will appear for users"
                  disabled
                />
                <button onClick={() => onCheckboxChange("req_issuer", false)}>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                      stroke="#667085"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          {docCustomData.req_policy_no && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/5">
                Policy Number
              </label>
              <div className="w-4/5 pl-2 flex gap-2.5">
                <input
                  type="text"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Field will appear for users"
                  disabled
                />
                <button
                  onClick={() => onCheckboxChange("req_policy_no", false)}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                      stroke="#667085"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          {docCustomData.req_amount_insured && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/5">
                Amount Insured
              </label>
              <div className="w-4/5 pl-2 flex gap-2.5">
                <input
                  type="text"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Field will appear for users"
                  disabled
                />
                <button
                  onClick={() => onCheckboxChange("req_amount_insured", false)}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                      stroke="#667085"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          {docCustomData.req_expiry_date_notes && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/5">
                Expiry Date Notes
              </label>
              <div className="w-4/5 pl-2 flex gap-2.5">
                <input
                  type="text"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Field will appear for users"
                  disabled
                />
                <button
                  onClick={() =>
                    onCheckboxChange("req_expiry_date_notes", false)
                  }
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                      stroke="#667085"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          {docCustomData?.custom_fields.map((item, index) => {
            return (
              <>
                <div className="flex mb-5 gap-2.5">
                  <div className="w-1/5 flex flex-wrap">
                    <input
                      type="text"
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Enter Field name"
                      value={item}
                      onChange={(e) => updateCustomFiled(e, index)}
                    />
                    {formErrors[`special_char_${index}`] && (
                      <p className="text-xs text-red-600 mt-2">
                        {formErrors[`special_char_${index}`]}
                      </p>
                    )}
                  </div>
                  <div className="w-4/5 flex gap-2.5">
                    <input
                      type="text"
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Field will appear for users"
                      disabled
                    />
                    <button onClick={() => deleteCustomField(index)}>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                          stroke="#667085"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </>
            );
          })}

          <Dropdown
            onCheckboxChange={onCheckboxChange}
            docCustomData={docCustomData}
          />
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            disabled={buttonDisabled}
            className="mr-1.5"
            variant="plain"
            onClick={onDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            onClick={(e) => submitDocumetType(e)}
            className="ml-1.5"
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Add"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Document added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.AddDocumentType.loader,
  };
}
const mapDispatchToProps = {
  onAddDocumentType: userActions.AddDocumentType,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocType);
