// import React, { useEffect, useState, useCallback } from "react";
// import { connect, useDispatch, useSelector } from "react-redux";
// import {
//   setInvoiceData,
//   clearInvoiceData,
// } from "../../../../../../redux/classes/Admin-Section/invoiceSlice";
// import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
// import Heading from "../../../../../../common/element/Heading";
// import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
// import BtnPrimaryLight from "../../../../../../common/element/Buttons/primaryLightBtn";
// import Select from "../../../../../../common/element/Select";
// import DatePicker from "../../../../../../common/element/DatePicker/DatePicker";
// import Input from "../../../../../../common/element/Input";
// import { debounce } from "../../../../../../common/snippet/Debouncing";
// import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
// import Label from "../../../../../../common/element/Label";
// import { useNavigate } from "react-router-dom";
// import Validations from "../../../../../../common/404/Validations";
// import EmailImg from "../../../../../../assets/icons-svg/email.svg";
// import * as userActions from "../../../../../../actions/index";
// import Checkbox from "../../../../../../common/element/Checkbox";
// import Dialog from "../../../../../../common/element/Dialog";
// import Table from "../../../../../../common/element/Table";
// import BtnLight from "../../../../../../common/element/Buttons/btnLight";
// import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
// import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
// import { GetAllClientList } from "../../../../../../actions/admin-provider/AllInviteContractorApi";
// import { GetAllUsersOfOrgList } from "../../../../../../actions/admin-provider/invoices/GetAllUsersOfOrgList";
// import moment from "moment/moment";
// import { AdminRaiseClientInvoice } from "../../../../../../actions/Admin-Client/invoices/AdminRaiseClientInvoice";
// import PreviewInvoice from "../InvoicePreview/PreviewInvoice";
// import { UpdateDraftInvoice } from "../../../../../../actions/Admin-Client/invoices/UpdateDraftInvoice";
// import { GetInvoiceDetailsById } from "../../../../../../actions/Admin-Client/invoices/GetInvoiceDetailsById";
// const { Tr, Th, Td, THead, TBody } = Table;

// const selectItemOption = [
//   { value: "Tiers", label: "Tiers" },
//   { value: "Service", label: "Service" },
// ];

// const RaiseInvoice = () => {
//   const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
//   const { allFunctions } = useSelector((state) => state);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { invoiceDetails } = useSelector((state) => state);
//   const handleUnauthorizedAccess = useUnauthorizedHandler();
//   const [searchOptions, setSearchOptions] = useState([]);
//   const [columnNames, setColumnNames] = useState([]);
//   const [deleteLineItemsIds, setDeleteLineItemsIds] = useState([]);
//   const [recipientOptions, setRecipientOptions] = useState([]);
//   const [edit, setEdit] = useState(false);
//   const [formError, setFormError] = useState("");

//   const [selectedClint, setSelectedClint] = useState("");

//   const [previewDialog, setPreviewDialog] = useState(false);

//   const [successdialogOpen, setSuccessDialogOpen] = useState(false);
//   const [buttonDisabled, setButtonDisabled] = useState(false);
//   const [selectedCopy, setSelectedCopy] = useState(false);
//   const [emailToError, setEmailError] = useState("");
//   const [orgAddress, setOrgaddress] = useState("");

//   const [search, setSearch] = useState("");
//   const [emailForOpen, setEmailForOpen] = useState(false);
//   const [selectedEmails, setSelectedEmails] = useState([]);
//   const [selectedOptionRecipient, setSelectedOptionRecipient] = useState(null);
//   const [invoiceItems, setInvoiceItems] = useState([
//     { item: "", item_desc: "", quantity: 1, tax: 10, unit_amount: 0 },
//   ]);

//   const [invoiceData, setInvoiceData] = useState({
//     invoiceNo: "",
//     recipient: "",
//     to: "",
//     trading_name: "",
//     user_id: "",
//     start_date: moment(new Date()).format("YYYY-MM-DD"),
//     due_date: "",
//     templateMessage: "",
//     generateAnnualPayment: false,
//     subtotal: 0,
//     totalIncludeGST: 0,
//   });

//   const [invoiceErrors, setInvoiceErrors] = useState({
//     invoiceNo: "",
//     recipient: "",
//     to: "",
//     start_date: "",
//     due_date: "",
//   });
//   const [invoiceItemsErrors, setInvoiceItemsErrors] = useState(
//     invoiceItems?.map(() => ({
//       item: "",
//       item_desc: "",
//       quantity: "",
//       tax: "",
//       unit_amount: "",
//     }))
//   );

//   const [searchClient, setSearchClient] = useState("");

//   let sendMeCopyEmail;
//   const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
//   if (loginData) {
//     sendMeCopyEmail = loginData?.userName?.email
//       ? loginData?.userName?.email
//       : "";
//   }

//   useEffect(() => {
//     if (
//       invoiceDetails?.selectedInvoice &&
//       invoiceDetails?.selectedInvoice?.edited === true
//     ) {
//       dispatch(
//         GetInvoiceDetailsById({
//           account_xero_invoice_id:
//             invoiceDetails?.selectedInvoice?.account_xero_invoice_id,
//         })
//       ).then((response) => {
//         if (response.success) {
//           let data = response.data;
//           setInvoiceItems(data?.xeroLineItems);
//           setSearchOptions([
//             {
//               label: data?.xeroInvHist?.email_to[0],
//               value: data?.user_id,
//             },
//           ]);
//           const payload = {
//             function_id: allFunctions?.allFunction?.filter(
//               (item) => item.function_name == "client"
//             )[0].function_id,
//             search: "",
//             client_org_id: data?.client_org_id,
//           };
//           if (data?.client_org_id) {
//             dispatch(GetAllClientList(payload)).then((response) => {
//               if (response.success) {
//                 let filterData = response.data.map((item) => {
//                   return {
//                     label: item.trading_name,
//                     value: item.organisation_id,
//                   };
//                 });
//                 setRecipientOptions(filterData);
//                 setInvoiceData({
//                   account_xero_invoice_id: data?.account_xero_invoice_id,
//                   invoiceNo: data?.account_xero_invoice_id,
//                   xero_invoice_id: data?.xero_invoice_id,
//                   recipient: filterData[0].value,
//                   to: data?.xeroInvHist?.email_to[0],
//                   trading_name: filterData[0].label,
//                   user_id: data?.user_id,
//                   start_date: moment(new Date()).format("YYYY-MM-DD"),
//                   due_date: data?.end_date,
//                   templateMessage: data?.manual_invoice_note,
//                   generateAnnualPayment: data?.gen_manual_invoice_annually,
//                   subtotal: 0,
//                   totalIncludeGST: 0,
//                 });
//                 setEdit(true);
//               }
//             });
//           }

//           // setInvoiceData({
//           //   invoiceNo: invoiceDetails?.selectedInvoice?.invoiceNumber, // Correct field mapping
//           //   recipient: invoiceDetails?.selectedInvoice?.recipient, // Correct field mapping
//           //   to: invoiceDetails?.selectedInvoice?.payee, // Assuming "to" refers to the payee
//           //   start_date: invoiceDetails?.selectedInvoice?.billingData, // Assuming billingData is the issue date
//           //   due_date: invoiceDetails?.selectedInvoice?.paymentData, // Assuming paymentData is the due date
//           //   templateMessage: "", // Not present in the provided data
//           //   generateAnnualPayment: false, // Not present in the provided data
//           //   subtotal: "", // Not present in the provided data
//           //   totalIncludeGST: "", // Not present in the provided data
//           // });
//         }
//       });

//       //

//       // Handle invoice items if they exist in the Redux data later
//       // setInvoiceItems(invoiceDetails?.selectedInvoice?.invoice_items);
//     }
//     dispatch(clearInvoiceData());
//   }, [invoiceDetails?.selectedInvoice, dispatch]);

//   useEffect(() => {
//     dispatch(
//       setBreadcrumbs([
//         { label: "Invoices", url: "/admin/invoices", home: "/admin/dashboard" },
//         {
//           label: `${edit == true ? "Edit invoice" : "New invoice"}`,
//           url: `/admin/invoices/raise-invoice`,
//         },
//       ])
//     );
//   }, [dispatch, edit]);

//   const onDialogCloseEmail = () => {
//     if (!buttonDisabled) {
//       setEmailForOpen(false);
//       setSelectedEmails([]);
//       setFormError("");
//       setSelectedCopy(false);
//     }
//   };

//   const closeSuccessDialog = () => {
//     setSuccessDialogOpen(false);
//     onDialogCloseEmail();
//     navigate("/admin/client/invoices");
//   };

//   const GetAllIndividualForEmail = useCallback(
//     async (clientId) => {
//       dispatch(GetAllUsersOfOrgList({ client_org_id: clientId })).then(
//         (response) => {
//           if (response.success === true) {
//             const arrayData = response?.data?.client_user;
//             setOrgaddress(response?.data?.org_address);
//             const maildata = [];
//             for (let key in arrayData) {
//               maildata.push({
//                 individual_uuid: arrayData[key].individual_uuid,
//                 label: arrayData[key].email,
//                 value: arrayData[key].user_id,
//               });
//             }
//             setSearchOptions(maildata);
//           } else if (response.status === 401) {
//             handleUnauthorizedAccess();
//           } else {
//           }
//         }
//       );
//       // eslint-disable-next-line
//     },
//     [dispatch, search]
//   );

//   const handleAddItem = () => {
//     if (edit) {
//       setInvoiceItems([
//         ...invoiceItems,
//         {
//           item: "",
//           item_desc: "",
//           quantity: 1,
//           tax: 10,
//           unit_amount: 0,
//           xero_invoice_line_item_id: "",
//         },
//       ]);
//     } else {
//       setInvoiceItems([
//         ...invoiceItems,
//         { item: "", item_desc: "", quantity: 1, tax: 10, unit_amount: 0 },
//       ]);
//     }
//   };

//   const handleItemChange = (index, field, value) => {
//     const updatedItems = invoiceItems?.map((item, i) =>
//       i === index
//         ? { ...item, [field]: field == "item" ? value.value : value }
//         : item
//     );
//     setInvoiceItems(updatedItems);

//     // Validation logic for each field and clear error if valid
//     let errorMessage = "";
//     if (field === "item" && !value) {
//       errorMessage = "Item is required!";
//     } else if (field === "item_desc" && !value) {
//       errorMessage = "Description is required!";
//     } else if (field === "quantity" && (isNaN(value) || value <= 0)) {
//       errorMessage = "quantity must be a positive number!";
//     } else if (field === "unit_amount" && (isNaN(value) || value <= 0)) {
//       errorMessage = "Amount must be a positive number!";
//     }

//     const updatedErrors = invoiceItemsErrors.map((error, i) =>
//       i === index ? { ...error, [field]: errorMessage } : error
//     );
//     setInvoiceItemsErrors(updatedErrors);
//     if (field == "item_desc") {
//       if (!columnNames.includes(field)) {
//         setColumnNames((Preview) => [...Preview, field]);
//       }
//     } else if (field == "unit_amount") {
//       if (!columnNames.includes("amount")) {
//         setColumnNames((Preview) => [...Preview, "amount"]);
//       }
//     }
//   };

//   const handleDeleteItem = (index) => {
//     const updatedItems = invoiceItems?.filter((_, i) => i !== index);
//     setInvoiceItems(updatedItems);
//     if (edit) {
//       if (invoiceItems[index]?.xero_invoice_line_item_id) {
//         setDeleteLineItemsIds((prev) => {
//           // Ensure we append the new ID to the existing array
//           return [...prev, invoiceItems[index]?.xero_invoice_line_item_id];
//         });
//       }

//       //
//     }
//   };

//   function isEmail(value) {
//     if (value !== undefined && value !== "") {
//       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//       return emailRegex.test(value);
//     }
//     return false;
//   }

//   const customOption = { value: search, label: search };
//   const updatedOptions = searchOptions.find((option) => option.value === search)
//     ? searchOptions
//     : search
//     ? [...searchOptions, customOption]
//     : [...searchOptions];

//   const handleSearchChange = debounce((newInputValue, identifier) => {
//     setSearch(newInputValue);
//     if (newInputValue?.length >= 3) {
//       if (identifier === "recipientTo") {
//         setEmailError("");
//       }
//       if (identifier === "sendInvoiceOthers") {
//         // for error message clear
//       }
//     }
//   }, 400);

//   const handleSelectChange = (selectedOption, identifier) => {
//     if (!columnNames.includes(identifier)) {
//       setColumnNames((Preview) => [...Preview, identifier]);
//     }
//     if (identifier === "recipientTo") {
//       let selectedEmail = selectedOption?.label?.trim() || ""; // Get the selected label and trim spaces
//       const isValidEmail = isEmail(selectedEmail); // Check if it's a valid email
//       if (isValidEmail) {
//         setSelectedOptionRecipient({
//           label: selectedEmail,
//           value: selectedOption.value,
//         });
//         setInvoiceData((prevData) => ({
//           ...prevData,
//           to: selectedEmail,
//         }));
//         setInvoiceData((prevData) => ({
//           ...prevData,
//           user_id: selectedOption.value,
//         }));

//         setInvoiceErrors((prevErrors) => ({ ...prevErrors, to: "" }));
//       } else {
//         setEmailError("Please insert a valid email address!");
//         setTimeout(() => {
//           setInvoiceErrors((prevErrors) => ({ ...prevErrors, to: "" }));
//         }, 4000);
//       }
//     }
//     if (identifier === "sendInvoiceOthers") {
//       let selectedEmail = [];
//       selectedOption.map((item) => {
//         selectedEmail.push(item?.label);
//       });
//       setSelectedEmails(selectedOption);
//       setFormError("");
//     }
//   };

//   const calculateSubtotal = () => {
//     return invoiceItems?.reduce((acc, item) => {
//       const unit_amount = item?.unit_amount ? Number(item?.unit_amount) : 0; // Safely handle undefined/null values for unit_amount
//       return acc + (isNaN(unit_amount) ? 0 : unit_amount); // Ensure unit_amount is a valid number
//     }, 0);
//   };

//   const calculateTotalIncludingGST = () => {
//     return invoiceItems?.reduce((acc, item) => {
//       const quantity = 1; // Safely handle undefined/null values for quantity
//       const unit_amount = item?.unit_amount ? Number(item?.unit_amount) : 0; // Safely handle undefined/null values for unit_amount
//       const tax = 10; // Safely handle undefined/null values for tax

//       const validQty = isNaN(quantity) ? 0 : quantity; // Ensure quantity is a valid number
//       const validAmount = isNaN(unit_amount) ? 0 : unit_amount; // Ensure unit_amount is a valid number
//       const validTax = isNaN(tax) ? 0 : tax; // Ensure tax is a valid number

//       // Calculate tax unit_amount and total with GST, handling any potential invalid values
//       const taxAmount = validQty * validAmount * (validTax / 100);
//       const totalWithGST = validAmount + (isNaN(taxAmount) ? 0 : taxAmount);

//       return acc + totalWithGST;
//     }, 0);
//   };

//   useEffect(() => {
//     const subtotal = calculateSubtotal();
//     const totalIncludeGST = calculateTotalIncludingGST();
//     setInvoiceData((prevData) => {
//       if (
//         prevData.subtotal !== subtotal ||
//         prevData.totalIncludeGST !== totalIncludeGST
//       ) {
//         return {
//           ...prevData,
//           subtotal,
//           totalIncludeGST,
//         };
//       }
//       return prevData;
//     });
//   }, [invoiceItems, edit]);

//   const inputchangehandler = (field, value) => {
//     if (!columnNames.includes("end_date")) {
//       setColumnNames((Preview) => [...Preview, "end_date"]);
//     }

//     setInvoiceData((prevData) => ({
//       ...prevData,
//       [field]: value ? moment(value).format("YYYY/MM/DD") : "",
//     }));
//     setInvoiceErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
//   };

//   const handleSendInvocie = (status) => {
//     // const invoiceNoValidation = Validations({
//     //   value: invoiceData.invoiceNo,
//     //   Validations: { required: true },
//     // });
//     // if (!invoiceNoValidation.valid) {
//     //   setInvoiceErrors((prevErrors) => ({
//     //     ...prevErrors,
//     //     invoiceNo: invoiceNoValidation.error_msg,
//     //   }));
//     // }
//     const recipientValidation = Validations({
//       value: invoiceData.recipient,
//       Validations: { required: true },
//     });
//     if (!recipientValidation.valid) {
//       setInvoiceErrors((prevErrors) => ({
//         ...prevErrors,
//         recipient: recipientValidation.error_msg,
//       }));
//     }
//     const toValidation = Validations({
//       value: invoiceData.to,
//       Validations: { required: true },
//     });
//     if (!toValidation.valid) {
//       setInvoiceErrors((prevErrors) => ({
//         ...prevErrors,
//         to: toValidation.error_msg,
//       }));
//     }
//     const issueDateValidationa = Validations({
//       value: invoiceData?.start_date,
//       Validations: { required: true },
//     });
//     if (!issueDateValidationa.valid) {
//       setInvoiceErrors((prevErrors) => ({
//         ...prevErrors,
//         start_date: issueDateValidationa.error_msg,
//       }));
//     }
//     const dueDateValidationa = Validations({
//       value: invoiceData?.due_date,
//       Validations: { required: true },
//     });
//     if (!dueDateValidationa.valid) {
//       setInvoiceErrors((prevErrors) => ({
//         ...prevErrors,
//         due_date: dueDateValidationa.error_msg,
//       }));
//     }

//     // Validate invoice items
//     const itemErrors = invoiceItems?.map((item) => {
//       let errorObj = { item: "", item_desc: "", quantity: "", unit_amount: "" };

//       if (!item.item) {
//         errorObj.item = "Item is required!";
//       }
//       if (!item.item_desc) {
//         errorObj.item_desc = "Description is required!";
//       }
//       if (!1 || isNaN(1) || 1 <= 0) {
//         errorObj.quantity = "quantity must be a positive number!";
//       }
//       if (
//         !item.unit_amount ||
//         isNaN(item.unit_amount) ||
//         item.unit_amount <= 0
//       ) {
//         errorObj.unit_amount = "Amount must be a positive number!";
//       }
//       return errorObj;
//     });

//     // Set errors if found
//     setInvoiceItemsErrors(itemErrors);
//     // Check if there are any errors in invoice items
//     const hasItemErrors = itemErrors.some((error) =>
//       Object.values(error).some((errorMsg) => errorMsg !== "")
//     );

//     // If there are errors, prevent submission
//     if (hasItemErrors) {
//       return;
//     }

//     if (
//       // invoiceNoValidation.valid &&
//       recipientValidation.valid &&
//       toValidation.valid &&
//       issueDateValidationa.valid &&
//       dueDateValidationa.valid &&
//       !hasItemErrors
//     ) {
//       if (status == "draft") {
//         postData("", "DRAFT");
//       } else {
//         setEmailForOpen(true);
//       }

//       // dispatch(invoiceActions.createInvoice(invoiceData, invoiceItems, selectedCopy, selectedEmails, onSendEmail)).then(
//       //     (response) => {
//       //         if (response.success === true) {
//       //             // Clear form and reset error messages
//       //             setInvoiceData({
//       //                 invoiceNo: "",
//       //                 recipient: "",
//       //                 to: "",
//       //                 start_date: "",
//       //                 due_date: "",
//       //                 terms: "",
//       //                 notes: "",
//       //                 subtotal: 0,
//       //                 totalIncludeGST: 0,
//       //             });
//       //             setInvoiceItems([]);
//       //             setEmailError("");
//       //             setInvoiceErrors({});
//       //             setSelectedOptionRecipient(null);
//       //             setSelectedEmails([]);
//       //         } else if (response.status === 401) {
//       //             handleUnauthorizedAccess()
//       //         } else {
//       //         }
//       //     }
//       // );
//     }
//   };

//   const postData = (email, status) => {
//     if (edit) {
//       let payload = {
//         account_xero_invoice_id: invoiceData?.account_xero_invoice_id,
//         xero_invoice_id: invoiceData?.xero_invoice_id,
//         line_items: JSON.stringify(invoiceItems),
//         client_org_id: invoiceData?.recipient,
//         user_id: invoiceData?.user_id,
//         trading_name: invoiceData?.trading_name,
//         email: invoiceData?.to,
//         deleteLineItemsIds: JSON.stringify(deleteLineItemsIds),
//         total_amount: invoiceData?.totalIncludeGST,
//         start_date: invoiceData?.start_date,
//         due_date: invoiceData?.due_date,
//         xero_invoice_status: status,
//         column_names: JSON.stringify(columnNames || []),
//         gen_manual_invoice_annually: invoiceData.generateAnnualPayment,
//         manual_invoice_note: invoiceData?.templateMessage,
//         email_cc: email ? JSON.stringify(email) : "",
//         org_address: orgAddress,
//       };
//       dispatch(UpdateDraftInvoice(payload)).then((response) => {
//         if (response.success) {
//           setButtonDisabled(false);
//           //  selectedCopy==true then sendMeCopyEmail set this in payload other wiese ""
//           setSelectedEmails([]);
//           setSelectedEmails([]);
//           setButtonDisabled(false);
//           onDialogCloseEmail();
//           setSuccessDialogOpen(true);
//         } else {
//           setButtonDisabled(false);
//         }
//       });
//     } else {
//       let payload = {
//         line_items: JSON.stringify(invoiceItems),
//         client_org_id: invoiceData?.recipient,
//         user_id: invoiceData?.user_id,
//         trading_name: invoiceData?.trading_name,
//         email: invoiceData?.to,
//         total_amount: invoiceData?.totalIncludeGST,
//         start_date: invoiceData?.start_date,
//         due_date: invoiceData?.due_date,
//         xero_invoice_status: status,
//         gen_manual_invoice_annually: invoiceData.generateAnnualPayment,
//         manual_invoice_note: invoiceData?.templateMessage,
//         email_cc: email ? JSON.stringify(email) : "",
//         org_address: orgAddress,
//       };
//       dispatch(AdminRaiseClientInvoice(payload)).then((response) => {
//         if (response.status) {
//           setButtonDisabled(false);
//           //  selectedCopy==true then sendMeCopyEmail set this in payload other wiese ""
//           setSelectedEmails([]);
//           setSelectedEmails([]);
//           setButtonDisabled(false);
//           onDialogCloseEmail();
//           setSuccessDialogOpen(true);
//         } else {
//           setButtonDisabled(false);
//         }
//       });
//     }
//   };

//   const handleCheckbox = () => {
//     setSelectedCopy((prevState) => !prevState);
//   };
//   const onSendEmail = () => {
//     let emails = selectedEmails.map((item) => item.label);
//     if (selectedCopy) {
//       emails.push(loginUserData?.userName?.email);
//     }

//     const emaillValidationa = Validations({
//       value: emails.length != 0 ? true : "",
//       Validations: { required: true },
//     });

//     if (!emaillValidationa.valid) {
//       setFormError(emaillValidationa.error_msg);
//     }
//     if (emaillValidationa.valid) {
//       setButtonDisabled(true);
//       postData(emails, "AUTHORISED");
//     }
//   };

//   useEffect(() => {
//     const payload = {
//       function_id: allFunctions?.allFunction?.filter(
//         (item) => item.function_name == "client"
//       )[0].function_id,
//       search: searchClient,
//     };
//     if (searchClient.length >= 3) {
//       dispatch(GetAllClientList(payload)).then((response) => {
//         if (response.success) {
//           let filterData = response.data.map((item) => {
//             return {
//               label: item.trading_name,
//               value: item.organisation_id,
//             };
//           });
//           setRecipientOptions(filterData);
//         }
//       });
//     }
//   }, [searchClient]);

//   const handleRecipientSearchChange = useCallback(
//     debounce((newInputValue) => {
//       setSearchClient(newInputValue);
//     }, 500),
//     []
//   );

//   const handleSelectRecipientChange = (selectedOption) => {
//     if (!columnNames.includes("client_org_id")) {
//       setColumnNames((Preview) => [...Preview, "client_org_id"]);
//     }

//     setSelectedClint(selectedOption);
//     setInvoiceData((prevData) => ({
//       ...prevData,
//       ["recipient"]: selectedOption?.value,
//     }));
//     setInvoiceData((prevData) => ({
//       ...prevData,
//       ["trading_name"]: selectedOption?.label,
//     }));

//     GetAllIndividualForEmail(selectedOption.value);
//     setInvoiceErrors((prevErrors) => ({ ...prevErrors, ["recipient"]: "" }));
//   };

//   const onPreviewDialogClose = () => {
//     setPreviewDialog(false);
//   };

//   return (
//     <React.Fragment>
//       <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
//         <Heading>{`${edit == true ? "Edit invoice" : "New invoice"}`}</Heading>
//       </div>
//       <div className="border border-gray-300 rounded-xl p-5">
//         <div className="flex gap-3">
//           <div className="flex-1">
//             <Label className="font-medium">Invoice no.</Label>
//             <Input
//               type="text"
//               className="h-34 w-full"
//               disabled={true}
//               placeholder="Enter invoice no"
//               value={invoiceData.invoiceNo}
//               // inputchangehandler={(e) => inputchangehandler("invoiceNo", e)}
//             />
//             {invoiceErrors?.invoiceNo && (
//               <div className="text-red-500 text-sm mt-1">
//                 {invoiceErrors?.invoiceNo}
//               </div>
//             )}
//           </div>

//           <div className="flex-1">
//             <Label className="font-medium">Recipient*</Label>
//             {/* <Input
//               type="text"
//               className="h-34 w-full"
//               placeholder="Select a recipient from the list"
//               value={invoiceData.recipient}
//               inputchangehandler={(e) => inputchangehandler("recipient", e)}
//             /> */}
//             <Select
//               className="w-full h-9 select-options"
//               placeholder="Select a recipient from the list"
//               options={recipientOptions}
//               value={
//                 edit
//                   ? recipientOptions[0]
//                   : {
//                       label: invoiceData?.trading_name,
//                       value: invoiceData?.recipient,
//                     }
//               }
//               onChange={(e) => handleSelectRecipientChange(e)}
//               handleSearchChange={(e) => handleRecipientSearchChange(e)}
//             ></Select>
//             {invoiceErrors?.recipient && (
//               <div className="text-red-500 text-sm mt-1">
//                 {invoiceErrors?.recipient}
//               </div>
//             )}
//           </div>

//           <div className="flex-1">
//             <Label className="font-medium">To*</Label>
//             <Select
//               placeholder="Enter email address"
//               className="w-full h-auto select-options"
//               options={updatedOptions}
//               readOnly={edit ? false : invoiceData?.recipient ? false : true}
//               value={
//                 edit
//                   ? recipientOptions[0]
//                   : { label: invoiceData?.to, value: invoiceData?.user_id }
//               }
//               onChange={(e) => handleSelectChange(e, "recipientTo")}
//               // handleSearchChange={(e) => handleSearchChange(e, "recipientTo")}
//             />
//             {invoiceErrors?.to && (
//               <div className="text-red-500 text-sm mt-1">
//                 {invoiceErrors?.to}
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="flex gap-3 mt-5">
//           <div className="flex-1">
//             <Label className="font-medium">Issue Date*</Label>
//             <Input
//               type="text"
//               className="h-34 w-full"
//               value={moment(invoiceData.start_date).format("YYYY-MM-DD")}
//               disabled
//               // disabled={true}
//             />
//             {/* <DatePicker
//               placeholder="Select date"
//               selected={invoiceData.start_date}

//               // onChange={(date) => inputchangehandler("start_date", date)}
//             /> */}
//             {invoiceErrors?.start_date && (
//               <div className="text-red-500 text-sm mt-1">
//                 {invoiceErrors?.start_date}
//               </div>
//             )}
//           </div>

//           <div className="flex-1">
//             <Label className="font-medium">Due Date*</Label>
//             <DatePicker
//               placeholder="Select due date"
//               // selected={invoiceData.due_date}
//               value={
//                 invoiceData.due_date ? new Date(invoiceData.due_date) : null
//               }
//               onChange={(date) => inputchangehandler("due_date", date)}
//               minDate={new Date()}
//             />
//             {invoiceErrors?.due_date && (
//               <div className="text-red-500 text-sm mt-1">
//                 {invoiceErrors?.due_date}
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="border rounded-lg border-gray-200 mt-5 invoice-table">
//           <Table>
//             <THead>
//               <Tr>
//                 <Th className="w-3/12">Items*</Th>
//                 <Th className="w-3/12">Description*</Th>
//                 {/* <Th>Qty</Th> */}
//                 <Th className="w-3/12">GST %</Th>
//                 <Th className="w-3/12">Amount*</Th>
//                 <Th className=""></Th>
//               </Tr>
//             </THead>
//             <TBody>
//               {invoiceItems?.map((item, index) => (
//                 <Tr key={index}>
//                   <Td className="align-top">
//                     <Select
//                       className=""
//                       placeholder="Select Item"
//                       options={selectItemOption}
//                       value={{ label: item.item, value: item.item }}
//                       onChange={(e) => handleItemChange(index, "item", e)}
//                     />
//                     {invoiceItemsErrors[index]?.item && (
//                       <div className="text-red-500 text-sm mt-1">
//                         {invoiceItemsErrors[index].item}
//                       </div>
//                     )}
//                   </Td>
//                   <Td className="align-top">
//                     <Input
//                       className="h-9 w-full"
//                       value={item.item_desc}
//                       inputchangehandler={(e) =>
//                         handleItemChange(index, "item_desc", e)
//                       }
//                     />
//                     {invoiceItemsErrors[index]?.item_desc && (
//                       <div className="text-red-500 text-sm mt-1">
//                         {invoiceItemsErrors[index].item_desc}
//                       </div>
//                     )}
//                   </Td>
//                   {/* Qty field */}
//                   {/* Tax and Amount fields */}
//                   <Td className="align-top">
//                     <Input
//                       className="h-9 w-full"
//                       type="number"
//                       disabled={true}
//                       value={item.tax}
//                       inputchangehandler={(e) =>
//                         handleItemChange(index, "tax", parseFloat(e))
//                       }
//                     />
//                   </Td>
//                   <Td className="align-top">
//                     <Input
//                       className="h-9 w-full"
//                       type="number"
//                       value={item.unit_amount}
//                       inputchangehandler={(e) =>
//                         handleItemChange(index, "unit_amount", parseFloat(e))
//                       }
//                     />
//                     {invoiceItemsErrors[index]?.unit_amount && (
//                       <div className="text-red-500 text-sm mt-1">
//                         {invoiceItemsErrors[index].unit_amount}
//                       </div>
//                     )}
//                   </Td>
//                   {invoiceItems.length > 1 && (
//                     <Td className="align-top">
//                       <svg
//                         width="20"
//                         height="20"
//                         viewBox="0 0 24 25"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                         onClick={() => handleDeleteItem(index)}
//                         className="cursor-pointer"
//                       >
//                         <path
//                           d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
//                           stroke="#475467"
//                           strokeWidth="2"
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                         />
//                       </svg>
//                     </Td>
//                   )}
//                 </Tr>
//               ))}
//             </TBody>
//           </Table>
//         </div>
//         <div className="w-40 flex gap-3 mt-3">
//           <BtnLight
//             className="gap-2 flex items-center justify-center text-xs border-none"
//             onClick={handleAddItem}
//           >
//             <svg
//               width="20"
//               height="20"
//               viewBox="0 0 20 20"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g clipPath="url(#clip0_18999_53644)">
//                 <path
//                   d="M9.99935 6.66602V13.3327M6.66602 9.99935H13.3327M18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935Z"
//                   stroke="#5786CC"
//                   strokeWidth="1.66667"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </g>
//               <defs>
//                 <clipPath id="clip0_18999_53644">
//                   <rect width="20" height="20" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg>
//             <span className="text-blue-700">Add another line</span>
//           </BtnLight>
//         </div>

//         <div className="mt-5 flex gap-5 justify-end align-center">
//           <span className="font-semibold">Subtotal</span>
//           <span className="text-gray-500 ml-2">
//             {Number(invoiceData?.subtotal)?.toFixed(2)}
//           </span>{" "}
//           {/* Show Subtotal */}
//         </div>
//         <div className="mt-5 flex gap-5 justify-end align-center">
//           <span className="font-semibold">Total including GST</span>
//           <span className="text-gray-500 ml-2">
//             {Number(invoiceData?.totalIncludeGST)?.toFixed(2)}
//           </span>{" "}
//           {/* Show Total Including GST */}
//         </div>

//         <div className="mt-10 flex justify-end align-center">
//           <Checkbox
//             checked={invoiceData?.generateAnnualPayment}
//             onChange={(value) => {
//               setInvoiceData((prevData) => ({
//                 ...prevData,
//                 generateAnnualPayment: value,
//               }));
//               if (!columnNames.includes("gen_manual_invoice_annually")) {
//                 setColumnNames((Preview) => [
//                   ...Preview,
//                   "gen_manual_invoice_annually",
//                 ]);
//               }
//             }}
//           />{" "}
//           Generate annual invoice
//         </div>
//         <div className="flex justify-center flex-col w-full mb-4 mt-8 rich-text-area-wrap">
//           <label className="text-xs text-medium text-gray-700 mb-1.5">
//             Additional notes (optional){" "}
//           </label>
//           <RichTextEditor
//             value={
//               invoiceData?.templateMessage ? invoiceData.templateMessage : ""
//             }
//             onChange={(value) => {
//               setInvoiceData((prevData) => ({
//                 ...prevData,
//                 templateMessage: value,
//               }));
//               if (!columnNames.includes("manual_invoice_note")) {
//                 setColumnNames((Preview) => [
//                   ...Preview,
//                   "manual_invoice_note",
//                 ]);
//               }
//             }}
//           />
//         </div>
//       </div>

//       <div className="mt-5 flex gap-3 justify-end align-center">
//         <BtnLight
//           onClick={() => handleSendInvocie("draft")}
//           className="flex w-auto items-center justify-center text-xs px-3"
//         >
//           Save Draft
//         </BtnLight>
//         <BtnPrimaryLight
//           onClick={() => setPreviewDialog(true)}
//           className="w-auto flex items-center justify-center text-xs px-3"
//         >
//           Preview
//         </BtnPrimaryLight>
//         <BtnPrimary
//           onClick={handleSendInvocie}
//           className="w-auto flex items-center justify-center text-xs px-3"
//         >
//           Send Invoice
//         </BtnPrimary>
//       </div>
//       <Dialog
//         isOpen={emailForOpen}
//         onClose={onDialogCloseEmail}
//         onRequestClose={onDialogCloseEmail}
//         width="650px"
//       >
//         <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
//           <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
//             <img src={EmailImg} alt="" />
//           </span>
//           <div>
//             <h5 className="mb-0 text-gray-900 text-base font-semibold">
//               Send Invoice
//             </h5>
//           </div>
//         </div>

//         <div className="p-6 pt-1">
//           <p className="text-base text-gray-900 font-semibold mt-0 mb-0">
//             {`Send invoice for ${
//               invoiceData?.totalIncludeGST ? invoiceData?.totalIncludeGST : "0"
//             } to ${invoiceData?.recipient ? invoiceData?.recipient : "N/A"} ?`}
//           </p>
//           <p className="text-gray-600 mb-5">
//             Invoices can’t be edited after they’ve been sent.{" "}
//           </p>
//           <Label className="block font-medium text-xs leading-4 mb-2">
//             CC:
//           </Label>
//           <div className="w-full m-auto table">
//             <Select
//               type="text"
//               placeholder="Add more emails"
//               className="w-full h-auto select-options"
//               isClearable={true}
//               isSearchable={true}
//               options={updatedOptions ? updatedOptions : selectedEmails}
//               value={selectedEmails}
//               onChange={(e) => handleSelectChange(e, "sendInvoiceOthers")}
//               handleSearchChange={(e) =>
//                 handleSearchChange(e, "sendInvoiceOthers")
//               }
//               isMulti={true}
//             />
//             {formError && (
//               <p className="text-red-500 text-sm mt-1">{formError}</p>
//             )}
//           </div>
//         </div>
//         <div className="p-6 pt-0">
//           <Checkbox
//             checked={selectedCopy}
//             onClick={() => handleCheckbox()}
//             className="font-medium text-sm leading-5"
//           >
//             {" "}
//             Send me a copy
//           </Checkbox>
//         </div>
//         <div className="mt-8 flex justify-between pb-6 px-6">
//           <BtnLight
//             disabled={buttonDisabled}
//             onClick={() => onDialogCloseEmail()}
//             className="mr-1.5"
//           >
//             Cancel
//           </BtnLight>
//           <BtnPrimary
//             className="ml-1.5"
//             disabled={buttonDisabled}
//             onClick={() => onSendEmail()}
//           >
//             {buttonDisabled ? <ThreeDotLoader /> : "Send"}
//           </BtnPrimary>
//         </div>
//       </Dialog>
//       <Dialog
//         isOpen={successdialogOpen}
//         onClose={closeSuccessDialog}
//         onRequestClose={closeSuccessDialog}
//         bodyOpenClassName="overflow-hidden"
//         className="customAlert"
//       >
//         <div className="px-6 pt-6">
//           <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
//             <svg
//               width="49"
//               height="49"
//               viewBox="0 0 49 49"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect
//                 x="0.894531"
//                 y="0.585938"
//                 width="48"
//                 height="48"
//                 rx="24"
//                 fill="#D1FADF"
//               />
//               <path
//                 d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
//                 stroke="black"
//                 stroke-width="2"
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//               />
//             </svg>
//           </span>
//           <p className="text-base text-gray-900 font-semibold text-center mt-4">
//             Invoice sent successfully!
//           </p>
//         </div>
//         <div className="mt-8 flex justify-between pb-6 px-6">
//           <BtnLight onClick={() => closeSuccessDialog()}>Close</BtnLight>
//         </div>
//       </Dialog>

//       <Dialog
//         isOpen={previewDialog}
//         onClose={onPreviewDialogClose}
//         onRequestClose={onPreviewDialogClose}
//         bodyOpenClassName="overflow-hidden"
//         className="documentDialog"
//       >
//         <PreviewInvoice
//           specificInvoiceData={{
//             line_items: invoiceItems,
//             client_org_id: invoiceData?.recipient,
//             user_id: invoiceData?.user_id,
//             trading_name: invoiceData?.trading_name,
//             email: invoiceData?.to,
//             total_amount: invoiceData?.totalIncludeGST,
//             start_date: invoiceData?.start_date,
//             due_date: invoiceData?.due_date,
//             manual_invoice_note: invoiceData?.templateMessage,
//             // email_cc: emails,
//             subtotal: invoiceData.subtotal,
//           }}
//         />
//       </Dialog>
//     </React.Fragment>
//   );
// };

// export default RaiseInvoice;
import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setInvoiceData,
  clearInvoiceData,
} from "../../../../../../redux/classes/Admin-Section/invoiceSlice";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import Heading from "../../../../../../common/element/Heading";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import BtnPrimaryLight from "../../../../../../common/element/Buttons/primaryLightBtn";
import Select from "../../../../../../common/element/Select";
import DatePicker from "../../../../../../common/element/DatePicker/DatePicker";
import Input from "../../../../../../common/element/Input";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Label from "../../../../../../common/element/Label";
import { useNavigate } from "react-router-dom";
import Validations from "../../../../../../common/404/Validations";
import EmailImg from "../../../../../../assets/icons-svg/email.svg";
import * as userActions from "../../../../../../actions/index";
import Checkbox from "../../../../../../common/element/Checkbox";
import Dialog from "../../../../../../common/element/Dialog";
import Table from "../../../../../../common/element/Table";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { GetAllClientList } from "../../../../../../actions/admin-provider/AllInviteContractorApi";
import { GetAllUsersOfOrgList } from "../../../../../../actions/admin-provider/invoices/GetAllUsersOfOrgList";
import moment from "moment/moment";
import { AdminRaiseClientInvoice } from "../../../../../../actions/Admin/invoices/AdminRaiseClientInvoice";
import PreviewInvoice from "../InvoicePreview/PreviewInvoice";
import { UpdateDraftInvoice } from "../../../../../../actions/Admin/invoices/UpdateDraftInvoice";
import { GetInvoiceAndLineItemsById } from "../../../../../../actions/Admin-Client/invoices/GetInvoiceAndLineItemsById";
const { Tr, Th, Td, THead, TBody } = Table;

const selectItemOption = [
  { value: "Tiers", label: "Tiers" },
  { value: "Service", label: "Service" },
];

const RaiseInvoice = () => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const { allFunctions } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoiceDetails } = useSelector((state) => state);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [searchOptions, setSearchOptions] = useState([]);
  const [message, setMessage] = useState("Invoice sent successfully");
  const [columnNames, setColumnNames] = useState([]);
  const [deleteLineItemsIds, setDeleteLineItemsIds] = useState([]);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [edit, setEdit] = useState(false);
  const [formError, setFormError] = useState("");

  const [selectedClint, setSelectedClint] = useState("");

  const [previewDialog, setPreviewDialog] = useState(false);

  const [successdialogOpen, setSuccessDialogOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState(false);
  const [emailToError, setEmailError] = useState("");
  const [orgAddress, setOrgaddress] = useState("");

  const [search, setSearch] = useState("");
  const [emailForOpen, setEmailForOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedOptionRecipient, setSelectedOptionRecipient] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([
    { item: "", item_desc: "", quantity: 1, tax: 10, unit_amount: 0 },
  ]);

  const [invoiceData, setInvoiceData] = useState({
    invoiceNo: "",
    recipient: "",
    to: "",
    trading_name: "",
    user_id: "",
    start_date: moment(new Date()).format("YYYY-MM-DD"),
    due_date: "",
    templateMessage: "",
    generateAnnualPayment: false,
    subtotal: 0,
    totalIncludeGST: 0,
  });

  const [invoiceErrors, setInvoiceErrors] = useState({
    invoiceNo: "",
    recipient: "",
    to: "",
    start_date: "",
    due_date: "",
  });
  const [invoiceItemsErrors, setInvoiceItemsErrors] = useState(
    invoiceItems?.map(() => ({
      item: "",
      item_desc: "",
      quantity: "",
      tax: "",
      unit_amount: "",
    }))
  );

  const [searchClient, setSearchClient] = useState("");

  let sendMeCopyEmail;
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (loginData) {
    sendMeCopyEmail = loginData?.userName?.email
      ? loginData?.userName?.email
      : "";
  }

  useEffect(() => {
    if (
      invoiceDetails?.selectedInvoice &&
      invoiceDetails?.selectedInvoice?.edited === true
    ) {
      dispatch(
        GetInvoiceAndLineItemsById({
          account_xero_invoice_id:
            invoiceDetails?.selectedInvoice?.account_xero_invoice_id,
        })
      ).then((response) => {
        if (response.success) {
          let data = response.data;
          setInvoiceItems(data?.xeroLineItems);
          setSearchOptions([
            {
              label: data?.xeroInvHist?.email_to[0],
              value: data?.user_id,
            },
          ]);
          const payload = {
            function_id: allFunctions?.allFunction?.filter(
              (item) => item.function_name == "client"
            )[0].function_id,
            search: "",
            client_org_id: data?.client_org_id,
          };
          if (data?.client_org_id) {
            dispatch(GetAllClientList(payload)).then((response) => {
              if (response.success) {
                let filterData = response.data.map((item) => {
                  return {
                    label: item.trading_name,
                    value: item.organisation_id,
                  };
                });
                setRecipientOptions(filterData);
                setInvoiceData({
                  account_xero_invoice_id: data?.account_xero_invoice_id,
                  invoiceNo: data?.account_xero_invoice_id,
                  xero_invoice_id: data?.xero_invoice_id,
                  recipient: filterData[0].value,
                  to: data?.xeroInvHist?.email_to[0],
                  trading_name: filterData[0].label,
                  user_id: data?.user_id,
                  start_date: moment(new Date()).format("YYYY-MM-DD"),
                  due_date: data?.end_date,
                  templateMessage: data?.manual_invoice_note,
                  generateAnnualPayment: data?.gen_manual_invoice_annually,
                  subtotal: 0,
                  totalIncludeGST: 0,
                });
                setEdit(true);
              }
            });
          }

          // setInvoiceData({
          //   invoiceNo: invoiceDetails?.selectedInvoice?.invoiceNumber, // Correct field mapping
          //   recipient: invoiceDetails?.selectedInvoice?.recipient, // Correct field mapping
          //   to: invoiceDetails?.selectedInvoice?.payee, // Assuming "to" refers to the payee
          //   start_date: invoiceDetails?.selectedInvoice?.billingData, // Assuming billingData is the issue date
          //   due_date: invoiceDetails?.selectedInvoice?.paymentData, // Assuming paymentData is the due date
          //   templateMessage: "", // Not present in the provided data
          //   generateAnnualPayment: false, // Not present in the provided data
          //   subtotal: "", // Not present in the provided data
          //   totalIncludeGST: "", // Not present in the provided data
          // });
        }
      });

      //

      // Handle invoice items if they exist in the Redux data later
      // setInvoiceItems(invoiceDetails?.selectedInvoice?.invoice_items);
    }
    dispatch(clearInvoiceData());
  }, [invoiceDetails?.selectedInvoice, dispatch]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Invoices", url: "/admin/invoices", home: "/admin/dashboard" },
        {
          label: `${edit == true ? "Edit invoice" : "New invoice"}`,
          url: `/admin/invoices/raise-invoice`,
        },
      ])
    );
  }, [dispatch, edit]);

  const onDialogCloseEmail = () => {
    if (!buttonDisabled) {
      setEmailForOpen(false);
      setSelectedEmails([]);
      setFormError("");
      setSelectedCopy(false);
    }
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    onDialogCloseEmail();
    navigate("/admin/invoices");
  };

  const GetAllIndividualForEmail = useCallback(
    async (clientId) => {
      dispatch(GetAllUsersOfOrgList({ client_org_id: clientId })).then(
        (response) => {
          if (response.success === true) {
            const arrayData = response?.data?.client_user;
            setOrgaddress(response?.data?.org_address);
            const maildata = [];
            for (let key in arrayData) {
              maildata.push({
                individual_uuid: arrayData[key].individual_uuid,
                label: arrayData[key].email,
                value: arrayData[key].user_id,
              });
            }
            setSearchOptions(maildata);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        }
      );
      // eslint-disable-next-line
    },
    [dispatch, search]
  );

  const handleAddItem = () => {
    if (edit) {
      setInvoiceItems([
        ...invoiceItems,
        {
          item: "",
          item_desc: "",
          quantity: 1,
          tax: 10,
          unit_amount: 0,
          xero_invoice_line_item_id: "",
        },
      ]);
    } else {
      setInvoiceItems([
        ...invoiceItems,
        { item: "", item_desc: "", quantity: 1, tax: 10, unit_amount: 0 },
      ]);
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = invoiceItems.map((item, i) =>
      i === index
        ? { ...item, [field]: field == "item" ? value.value : value }
        : item
    );
    setInvoiceItems(updatedItems);

    // Validation logic for each field and clear error if valid
    let errorMessage = "";
    if (field === "item" && !value) {
      errorMessage = "Item is required!";
    } else if (field === "item_desc" && !value) {
      errorMessage = "Description is required!";
    } else if (field === "quantity" && (isNaN(value) || value <= 0)) {
      errorMessage = "quantity must be a positive number!";
    } else if (field === "unit_amount" && (isNaN(value) || value <= 0)) {
      errorMessage = "Amount must be a positive number!";
    }

    const updatedErrors = invoiceItemsErrors.map((error, i) =>
      i === index ? { ...error, [field]: errorMessage } : error
    );
    setInvoiceItemsErrors(updatedErrors);
    if (field == "item_desc") {
      if (!columnNames.includes(field)) {
        setColumnNames((Preview) => [...Preview, field]);
      }
    } else if (field == "unit_amount") {
      if (!columnNames.includes("amount")) {
        setColumnNames((Preview) => [...Preview, "amount"]);
      }
    }
  };

  const handleDeleteItem = (index) => {
    setInvoiceItemsErrors((prevErrors) =>
      prevErrors.filter((_, i) => i !== index)
    );
    const updatedItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(updatedItems);
    if (edit) {
      if (invoiceItems[index]?.xero_invoice_line_item_id) {
        setDeleteLineItemsIds((prev) => {
          // Ensure we append the new ID to the existing array
          return [...prev, invoiceItems[index]?.xero_invoice_line_item_id];
        });
      }

      //
    }
  };

  function isEmail(value) {
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
    return false;
  }

  const customOption = { value: search, label: search };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : search
    ? [...searchOptions, customOption]
    : [...searchOptions];
  const handleSearchChange = debounce((newInputValue, identifier) => {
    setSearch(newInputValue);
    if (newInputValue?.length >= 3) {
      if (identifier === "recipientTo") {
        setEmailError("");
      }
      if (identifier === "sendInvoiceOthers") {
        // for error message clear
      }
    }
  }, 400);

  const handleSelectChange = (selectedOption, identifier) => {
    if (!columnNames.includes(identifier)) {
      setColumnNames((Preview) => [...Preview, identifier]);
    }
    if (identifier === "recipientTo") {
      let selectedEmail = selectedOption?.label?.trim() || ""; // Get the selected label and trim spaces
      const isValidEmail = isEmail(selectedEmail); // Check if it's a valid email
      if (isValidEmail) {
        setSelectedOptionRecipient({
          label: selectedEmail,
          value: selectedOption.value,
        });
        setInvoiceData((prevData) => ({
          ...prevData,
          to: selectedEmail,
        }));
        setInvoiceData((prevData) => ({
          ...prevData,
          user_id: selectedOption.value,
        }));

        setInvoiceErrors((prevErrors) => ({ ...prevErrors, to: "" }));
      } else {
        setEmailError("Please insert a valid email address!");
        setTimeout(() => {
          setInvoiceErrors((prevErrors) => ({ ...prevErrors, to: "" }));
        }, 4000);
      }
    }
    if (identifier === "sendInvoiceOthers") {
      let selectedEmail = [];
      selectedOption.map((item) => {
        selectedEmail.push(item?.label);
      });
      setSelectedEmails(selectedOption);
      setFormError("");
    }
  };

  const calculateSubtotal = () => {
    return invoiceItems.reduce((acc, item) => {
      const unit_amount = item?.unit_amount ? Number(item?.unit_amount) : 0; // Safely handle undefined/null values for unit_amount
      return acc + (isNaN(unit_amount) ? 0 : unit_amount); // Ensure unit_amount is a valid number
    }, 0);
  };

  const calculateTotalIncludingGST = () => {
    return invoiceItems.reduce((acc, item) => {
      const quantity = 1; // Safely handle undefined/null values for quantity
      const unit_amount = item?.unit_amount ? Number(item?.unit_amount) : 0; // Safely handle undefined/null values for unit_amount
      const tax = 10; // Safely handle undefined/null values for tax

      const validQty = isNaN(quantity) ? 0 : quantity; // Ensure quantity is a valid number
      const validAmount = isNaN(unit_amount) ? 0 : unit_amount; // Ensure unit_amount is a valid number
      const validTax = isNaN(tax) ? 0 : tax; // Ensure tax is a valid number

      // Calculate tax unit_amount and total with GST, handling any potential invalid values
      const taxAmount = validQty * validAmount * (validTax / 100);
      const totalWithGST = validAmount + (isNaN(taxAmount) ? 0 : taxAmount);

      return acc + totalWithGST;
    }, 0);
  };

  useEffect(() => {
    const subtotal = calculateSubtotal();
    const totalIncludeGST = calculateTotalIncludingGST();
    setInvoiceData((prevData) => {
      if (
        prevData.subtotal !== subtotal ||
        prevData.totalIncludeGST !== totalIncludeGST
      ) {
        return {
          ...prevData,
          subtotal,
          totalIncludeGST,
        };
      }
      return prevData;
    });
  }, [invoiceItems, edit]);

  const inputchangehandler = (field, value) => {
    if (!columnNames.includes("end_date")) {
      setColumnNames((Preview) => [...Preview, "end_date"]);
    }
    setInvoiceData((prevData) => ({
      ...prevData,
      [field]: value ? moment(value).format("YYYY/MM/DD") : "",
    }));
    setInvoiceErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const handleSendInvocie = (status) => {
    // const invoiceNoValidation = Validations({
    //   value: invoiceData.invoiceNo,
    //   Validations: { required: true },
    // });
    // if (!invoiceNoValidation.valid) {
    //   setInvoiceErrors((prevErrors) => ({
    //     ...prevErrors,
    //     invoiceNo: invoiceNoValidation.error_msg,
    //   }));
    // }
    const recipientValidation = Validations({
      value: invoiceData.recipient,
      Validations: { required: true },
    });
    if (!recipientValidation.valid) {
      setInvoiceErrors((prevErrors) => ({
        ...prevErrors,
        recipient: recipientValidation.error_msg,
      }));
    }
    const toValidation = Validations({
      value: invoiceData.to,
      Validations: { required: true },
    });
    if (!toValidation.valid) {
      setInvoiceErrors((prevErrors) => ({
        ...prevErrors,
        to: toValidation.error_msg,
      }));
    }
    const issueDateValidationa = Validations({
      value: invoiceData?.start_date,
      Validations: { required: true },
    });
    if (!issueDateValidationa.valid) {
      setInvoiceErrors((prevErrors) => ({
        ...prevErrors,
        start_date: issueDateValidationa.error_msg,
      }));
    }
    const dueDateValidationa = Validations({
      value: invoiceData?.due_date,
      Validations: { required: true },
    });
    if (!dueDateValidationa.valid) {
      setInvoiceErrors((prevErrors) => ({
        ...prevErrors,
        due_date: dueDateValidationa.error_msg,
      }));
    }

    // Validate invoice items
    const itemErrors = invoiceItems.map((item) => {
      let errorObj = { item: "", item_desc: "", quantity: "", unit_amount: "" };

      if (!item.item) {
        errorObj.item = "Item is required!";
      }
      if (!item.item_desc) {
        errorObj.item_desc = "Description is required!";
      }
      if (!1 || isNaN(1) || 1 <= 0) {
        errorObj.quantity = "quantity must be a positive number!";
      }
      if (
        !item.unit_amount ||
        isNaN(item.unit_amount) ||
        item.unit_amount <= 0
      ) {
        errorObj.unit_amount = "Amount must be a positive number!";
      }
      return errorObj;
    });

    // Set errors if found
    setInvoiceItemsErrors(itemErrors);
    // Check if there are any errors in invoice items
    const hasItemErrors = itemErrors.some((error) =>
      Object.values(error).some((errorMsg) => errorMsg !== "")
    );

    // If there are errors, prevent submission
    if (hasItemErrors) {
      return;
    }

    if (
      // invoiceNoValidation.valid &&
      recipientValidation.valid &&
      toValidation.valid &&
      issueDateValidationa.valid &&
      dueDateValidationa.valid &&
      !hasItemErrors
    ) {
      if (status == "draft") {
        setMessage("Invoice moved to draft!");
        postData("", "DRAFT");
      } else {
        setEmailForOpen(true);
        setMessage("Invoice sent successfully!");
      }

      // dispatch(invoiceActions.createInvoice(invoiceData, invoiceItems, selectedCopy, selectedEmails, onSendEmail)).then(
      //     (response) => {
      //         if (response.success === true) {
      //             // Clear form and reset error messages
      //             setInvoiceData({
      //                 invoiceNo: "",
      //                 recipient: "",
      //                 to: "",
      //                 start_date: "",
      //                 due_date: "",
      //                 terms: "",
      //                 notes: "",
      //                 subtotal: 0,
      //                 totalIncludeGST: 0,
      //             });
      //             setInvoiceItems([]);
      //             setEmailError("");
      //             setInvoiceErrors({});
      //             setSelectedOptionRecipient(null);
      //             setSelectedEmails([]);
      //         } else if (response.status === 401) {
      //             handleUnauthorizedAccess()
      //         } else {
      //         }
      //     }
      // );
    }
  };

  const postData = (email, status) => {
    if (edit) {
      let payload = {
        account_xero_invoice_id: invoiceData?.account_xero_invoice_id,
        xero_invoice_id: invoiceData?.xero_invoice_id,
        line_items: JSON.stringify(invoiceItems),
        client_org_id: invoiceData?.recipient,
        user_id: invoiceData?.user_id,
        trading_name: invoiceData?.trading_name,
        email: invoiceData?.to,
        deleteLineItemsIds: JSON.stringify(deleteLineItemsIds),
        total_amount: invoiceData?.totalIncludeGST,
        start_date: invoiceData?.start_date,
        due_date: invoiceData?.due_date,
        xero_invoice_status: status,
        column_names: JSON.stringify(columnNames || []),
        gen_manual_invoice_annually: invoiceData.generateAnnualPayment,
        manual_invoice_note: invoiceData?.templateMessage,
        email_cc: email ? JSON.stringify(email) : "",
        org_address: orgAddress,
      };
      dispatch(UpdateDraftInvoice(payload)).then((response) => {
        if (response.success) {
          setButtonDisabled(false);
          //  selectedCopy==true then sendMeCopyEmail set this in payload other wiese ""
          setSelectedEmails([]);
          setSelectedEmails([]);
          setButtonDisabled(false);
          onDialogCloseEmail();
          setSuccessDialogOpen(true);
        } else {
          setButtonDisabled(false);
        }
      });
    } else {
      let payload = {
        line_items: JSON.stringify(invoiceItems),
        client_org_id: invoiceData?.recipient,
        user_id: invoiceData?.user_id,
        trading_name: invoiceData?.trading_name,
        email: invoiceData?.to,
        total_amount: invoiceData?.totalIncludeGST,
        start_date: invoiceData?.start_date,
        due_date: invoiceData?.due_date,
        xero_invoice_status: status,
        gen_manual_invoice_annually: invoiceData.generateAnnualPayment,
        manual_invoice_note: invoiceData?.templateMessage,
        email_cc: email ? JSON.stringify(email) : "",
        org_address: orgAddress,
      };
      dispatch(AdminRaiseClientInvoice(payload)).then((response) => {
        if (response.status) {
          setButtonDisabled(false);
          //  selectedCopy==true then sendMeCopyEmail set this in payload other wiese ""
          setSelectedEmails([]);
          setSelectedEmails([]);
          setButtonDisabled(false);
          onDialogCloseEmail();
          setSuccessDialogOpen(true);
        } else {
          setButtonDisabled(false);
        }
      });
    }
  };

  const handleCheckbox = () => {
    setSelectedCopy((prevState) => !prevState);
  };
  const onSendEmail = () => {
    let emails = selectedEmails.map((item) => item.label);
    if (selectedCopy) {
      emails.push(loginUserData?.userName?.email);
    }

    const emaillValidationa = Validations({
      value: emails.length != 0 ? true : "",
      Validations: { required: true },
    });

    if (!emaillValidationa.valid) {
      setFormError(emaillValidationa.error_msg);
    }
    if (emaillValidationa.valid) {
      setButtonDisabled(true);
      postData(emails, "AUTHORISED");
    }
  };

  useEffect(() => {
    const payload = {
      function_id: allFunctions?.allFunction?.filter(
        (item) => item.function_name == "client"
      )[0].function_id,
      search: searchClient,
    };
    if (searchClient.length >= 3) {
      dispatch(GetAllClientList(payload)).then((response) => {
        if (response.success) {
          let filterData = response.data.map((item) => {
            return {
              label: item.trading_name,
              value: item.organisation_id,
            };
          });
          setRecipientOptions(filterData);
        }
      });
    }
  }, [searchClient]);

  const handleRecipientSearchChange = useCallback(
    debounce((newInputValue) => {
      setSearchClient(newInputValue);
    }, 500),
    []
  );

  const handleSelectRecipientChange = (selectedOption) => {
    if (!columnNames.includes("client_org_id")) {
      setColumnNames((Preview) => [...Preview, "client_org_id"]);
    }

    setSelectedClint(selectedOption);
    setInvoiceData((prevData) => ({
      ...prevData,
      ["recipient"]: selectedOption?.value,
    }));
    setInvoiceData((prevData) => ({
      ...prevData,
      ["trading_name"]: selectedOption?.label,
    }));

    GetAllIndividualForEmail(selectedOption.value);
    setInvoiceErrors((prevErrors) => ({ ...prevErrors, ["recipient"]: "" }));
  };

  const onPreviewDialogClose = () => {
    setPreviewDialog(false);
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
        <Heading>{`${edit == true ? "Edit invoice" : "New invoice"}`}</Heading>
      </div>
      <div className="border border-gray-300 rounded-xl p-5">
        <div className="flex gap-3">
          <div className="flex-1">
            <Label className="font-medium">Invoice no.</Label>
            <Input
              type="text"
              className="h-34 w-full"
              disabled={true}
              placeholder="Enter invoice no"
              value={invoiceData.invoiceNo}
              // inputchangehandler={(e) => inputchangehandler("invoiceNo", e)}
            />
            {invoiceErrors?.invoiceNo && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.invoiceNo}
              </div>
            )}
          </div>

          <div className="flex-1">
            <Label className="font-medium">Recipient*</Label>
            {/* <Input
              type="text"
              className="h-34 w-full"
              placeholder="Select a recipient from the list"
              value={invoiceData.recipient}
              inputchangehandler={(e) => inputchangehandler("recipient", e)}
            /> */}
            <Select
              className="w-full h-9 select-options"
              placeholder="Select a recipient from the list"
              options={recipientOptions}
              value={
                edit
                  ? recipientOptions[0]
                  : {
                      label: invoiceData?.trading_name,
                      value: invoiceData?.recipient,
                    }
              }
              onChange={(e) => handleSelectRecipientChange(e)}
              handleSearchChange={(e) => handleRecipientSearchChange(e)}
            ></Select>
            {invoiceErrors?.recipient && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.recipient}
              </div>
            )}
          </div>

          <div className="flex-1">
            <Label className="font-medium">To*</Label>
            <Select
              placeholder="Enter email address"
              className="w-full h-auto select-options"
              options={updatedOptions}
              readOnly={edit ? false : invoiceData?.recipient ? false : true}
              value={
                edit
                  ? recipientOptions[0]
                  : { label: invoiceData?.to, value: invoiceData?.user_id }
              }
              onChange={(e) => handleSelectChange(e, "recipientTo")}
              // handleSearchChange={(e) => handleSearchChange(e, "recipientTo")}
            />
            {invoiceErrors?.to && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.to}
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-3 mt-5">
          <div className="flex-1">
            <Label className="font-medium">Issue Date*</Label>
            <Input
              type="text"
              className="h-34 w-full"
              value={moment(invoiceData.start_date).format("YYYY-MM-DD")}
              disabled
              // disabled={true}
            />
            {/* <DatePicker
              placeholder="Select date"
              selected={invoiceData.start_date}
              
              // onChange={(date) => inputchangehandler("start_date", date)}
            /> */}
            {invoiceErrors?.start_date && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.start_date}
              </div>
            )}
          </div>
          <div className="flex-1">
            <Label className="font-medium">Due Date*</Label>
            <DatePicker
              placeholder="Select due date"
              // value={new Date(invoiceData.due_date)}
              value={
                invoiceData.due_date ? new Date(invoiceData.due_date) : null
              }
              // selected={invoiceData.due_date}

              onChange={(date) => inputchangehandler("due_date", date)}
              minDate={new Date()}
            />
            {invoiceErrors?.due_date && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.due_date}
              </div>
            )}
          </div>
        </div>

        <div className="border rounded-lg border-gray-200 mt-5 invoice-table">
          <Table>
            <THead>
              <Tr>
                <Th className="w-3/12">Items*</Th>
                <Th className="w-3/12">Description*</Th>
                {/* <Th>Qty</Th> */}
                <Th className="w-3/12">GST %</Th>
                <Th className="w-3/12">Amount*</Th>
                <Th className=""></Th>
              </Tr>
            </THead>
            <TBody>
              {invoiceItems.map((item, index) => (
                <Tr key={index}>
                  <Td className="align-top">
                    <Select
                      className=""
                      placeholder="Select Item"
                      options={selectItemOption}
                      value={{ label: item.item, value: item.item }}
                      onChange={(e) => handleItemChange(index, "item", e)}
                    />
                    {invoiceItemsErrors[index]?.item && (
                      <div className="text-red-500 text-sm mt-1">
                        {invoiceItemsErrors[index].item}
                      </div>
                    )}
                  </Td>
                  <Td className="align-top">
                    <Input
                      className="h-9 w-full"
                      value={item.item_desc}
                      inputchangehandler={(e) =>
                        handleItemChange(index, "item_desc", e)
                      }
                    />
                    {invoiceItemsErrors[index]?.item_desc && (
                      <div className="text-red-500 text-sm mt-1">
                        {invoiceItemsErrors[index].item_desc}
                      </div>
                    )}
                  </Td>
                  {/* Qty field */}
                  {/* Tax and Amount fields */}
                  <Td className="align-top">
                    <Input
                      className="h-9 w-full"
                      type="number"
                      disabled={true}
                      value={item.tax}
                      inputchangehandler={(e) =>
                        handleItemChange(index, "tax", parseFloat(e))
                      }
                    />
                  </Td>
                  <Td className="align-top">
                    <Input
                      className="h-9 w-full"
                      type="number"
                      value={item.unit_amount}
                      inputchangehandler={(e) =>
                        handleItemChange(index, "unit_amount", parseFloat(e))
                      }
                    />
                    {invoiceItemsErrors[index]?.unit_amount && (
                      <div className="text-red-500 text-sm mt-1">
                        {invoiceItemsErrors[index].unit_amount}
                      </div>
                    )}
                  </Td>
                  {invoiceItems.length > 1 && (
                    <Td className="align-top">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleDeleteItem(index)}
                        className="cursor-pointer"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#475467"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Td>
                  )}
                </Tr>
              ))}
            </TBody>
          </Table>
        </div>
        <div className="w-40 flex gap-3 mt-3">
          <BtnLight
            className="gap-2 flex items-center justify-center text-xs border-none"
            onClick={handleAddItem}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_18999_53644)">
                <path
                  d="M9.99935 6.66602V13.3327M6.66602 9.99935H13.3327M18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935Z"
                  stroke="#5786CC"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_18999_53644">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-blue-700">Add another line</span>
          </BtnLight>
        </div>

        <div className="mt-5 flex gap-5 justify-end align-center">
          <span className="font-semibold">Subtotal</span>
          <span className="text-gray-500 ml-2">
            {Number(invoiceData?.subtotal)?.toFixed(2)}
          </span>{" "}
          {/* Show Subtotal */}
        </div>
        <div className="mt-5 flex gap-5 justify-end align-center">
          <span className="font-semibold">Total including GST</span>
          <span className="text-gray-500 ml-2">
            {Number(invoiceData?.totalIncludeGST)?.toFixed(2)}
          </span>{" "}
          {/* Show Total Including GST */}
        </div>

        <div className="mt-10 flex justify-end align-center">
          <Checkbox
            checked={invoiceData?.generateAnnualPayment}
            onChange={(value) => {
              setInvoiceData((prevData) => ({
                ...prevData,
                generateAnnualPayment: value,
              }));
              if (!columnNames.includes("gen_manual_invoice_annually")) {
                setColumnNames((Preview) => [
                  ...Preview,
                  "gen_manual_invoice_annually",
                ]);
              }
            }}
          />{" "}
          Generate annual invoice
        </div>
        <div className="flex justify-center flex-col w-full mb-4 mt-8 rich-text-area-wrap">
          <label className="text-xs text-medium text-gray-700 mb-1.5">
            Additional notes (optional){" "}
          </label>
          <RichTextEditor
            value={
              invoiceData?.templateMessage ? invoiceData.templateMessage : ""
            }
            onChange={(value) => {
              setInvoiceData((prevData) => ({
                ...prevData,
                templateMessage: value,
              }));
              if (!columnNames.includes("manual_invoice_note")) {
                setColumnNames((Preview) => [
                  ...Preview,
                  "manual_invoice_note",
                ]);
              }
            }}
          />
        </div>
      </div>

      <div className="mt-5 flex gap-3 justify-end align-center">
        <BtnLight
          onClick={() => handleSendInvocie("draft")}
          className="flex w-auto items-center justify-center text-xs px-3"
        >
          Save Draft
        </BtnLight>
        <BtnPrimaryLight
          onClick={() => setPreviewDialog(true)}
          className="w-auto flex items-center justify-center text-xs px-3"
        >
          Preview
        </BtnPrimaryLight>
        <BtnPrimary
          onClick={handleSendInvocie}
          className="w-auto flex items-center justify-center text-xs px-3"
        >
          Send Invoice
        </BtnPrimary>
      </div>
      <Dialog
        isOpen={emailForOpen}
        onClose={onDialogCloseEmail}
        onRequestClose={onDialogCloseEmail}
        width="650px"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailImg} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Send Invoice
            </h5>
          </div>
        </div>

        <div className="p-6 pt-1">
          <p className="text-base text-gray-900 font-semibold mt-0 mb-0">
            {`Send invoice for ${
              invoiceData?.totalIncludeGST ? invoiceData?.totalIncludeGST : "0"
            } to ${invoiceData?.recipient ? invoiceData?.recipient : "N/A"} ?`}
          </p>
          <p className="text-gray-600 mb-5">
            Invoices can’t be edited after they’ve been sent.{" "}
          </p>
          <Label className="block font-medium text-xs leading-4 mb-2">
            CC:
          </Label>
          <div className="w-full m-auto table">
            <Select
              type="text"
              placeholder="Add more emails"
              className="w-full h-auto select-options"
              isClearable={true}
              isSearchable={true}
              options={updatedOptions ? updatedOptions : selectedEmails}
              value={selectedEmails}
              onChange={(e) => handleSelectChange(e, "sendInvoiceOthers")}
              handleSearchChange={(e) =>
                handleSearchChange(e, "sendInvoiceOthers")
              }
              isMulti={true}
            />
            {formError && (
              <p className="text-red-500 text-sm mt-1">{formError}</p>
            )}
          </div>
        </div>
        <div className="p-6 pt-0">
          <Checkbox
            checked={selectedCopy}
            onClick={() => handleCheckbox()}
            className="font-medium text-sm leading-5"
          >
            {" "}
            Send me a copy
          </Checkbox>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight
            disabled={buttonDisabled}
            onClick={() => onDialogCloseEmail()}
            className="mr-1.5"
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            disabled={buttonDisabled}
            onClick={() => onSendEmail()}
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Send"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={successdialogOpen}
        onClose={closeSuccessDialog}
        onRequestClose={closeSuccessDialog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            {message}
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeSuccessDialog()}>Close</BtnLight>
        </div>
      </Dialog>

      <Dialog
        isOpen={previewDialog}
        onClose={onPreviewDialogClose}
        onRequestClose={onPreviewDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <PreviewInvoice
          specificInvoiceData={{
            line_items: invoiceItems,
            client_org_id: invoiceData?.recipient,
            user_id: invoiceData?.user_id,
            trading_name: invoiceData?.trading_name,
            email: invoiceData?.to,
            total_amount: invoiceData?.totalIncludeGST,
            start_date: invoiceData?.start_date,
            due_date: invoiceData?.due_date,
            manual_invoice_note: invoiceData?.templateMessage,
            // email_cc: emails,
            subtotal: invoiceData.subtotal,
          }}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default RaiseInvoice;
