import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetAllAdminNotifications = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/GetCommonNotifications`,
      params: getAllData,
    });

    // Dispatch an action if needed, for example:
    // dispatch({ type: actionType.SET_ADMIN_NOTIFICATIONS, payload: response.data });

    return response.data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error fetching notifications:", error);
    return null; // Return null or handle the error as needed
  }
};
