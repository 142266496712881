import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../../../../common/element/Table";
import Button from "../../../../../common/element/Buttons";
import Tag from "../../../../../common/element/Tag";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import Checkbox from "../../../../../common/element/Checkbox";
import moment from "moment";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetSignedUrlByKey } from "../../../../../actions/public/GetSignedUrlByKey";
import { useDispatch } from "react-redux";
const { Tr, Th, Td, THead, TBody } = Table;
const InvoiceList = (props) => {
  const dispatch = useDispatch();
  const [mainCheck, setMainCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [pdfDownloadSuccessDialog, setPdfDownloadSuccessDialog] =
    useState(false);
  const [invoicesData, setInvoicesData] = useState("");
  const [pdfViewDialog, setPdfViewDialog] = useState(false);
  const previousViewTypeRef = useRef();

  useEffect(() => {
    if (props?.viewType !== previousViewTypeRef.current) {
      setCheckedItems([]);
    }
    previousViewTypeRef.current = props?.viewType;
  }, [props?.viewType]);

  const closeDownloadSuccessPdf = () => {
    setPdfDownloadSuccessDialog(false);
  };

  const onCheckboxChange = (e, item) => {
    if (item === "mainCheck") {
      const isChecked = e;
      setMainCheck(isChecked);

      if (isChecked) {
        setCheckedItems(props?.allInvoicesData);
      } else {
        setCheckedItems([]);
      }
    } else {
      const isChecked = e;
      const existingIndex = checkedItems.findIndex(
        (ele) => ele.account_xero_invoice_id === item.account_xero_invoice_id
      );

      if (isChecked && existingIndex === -1) {
        setCheckedItems((prevItems) => [...prevItems, item]);
      } else if (!isChecked && existingIndex !== -1) {
        setCheckedItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems.splice(existingIndex, 1);
          return updatedItems;
        });
      }
    }
  };

  const updateOpenUrl = (file) => {
    if (file?.xero_invoice_pdf) {
      dispatch(
        GetSignedUrlByKey({ url: file?.xero_invoice_pdf }, "client")
      ).then((response) => {
        if (response.success) {
          OpneSingleInvoice(response?.data, file);
        }
      });
    }
  };

  const OpneSingleInvoice = (pdf, item) => {
    return new Promise((resolve, reject) => {
      try {
        if (!pdf || !item?.xero_invoice_number) {
          throw new Error(
            "Invalid invoice data: Missing URL or invoice number"
          );
        }
        // Open the PDF in a new tab
        const newWindow = window.open(pdf, "_blank");
        if (newWindow) {
          resolve(); // Success if window opens
        } else {
          reject(
            new Error(
              "Failed to open new window - likely blocked by popup blocker"
            )
          );
        }
      } catch (error) {
        console.error(
          `Error opening invoice ${item?.xero_invoice_number || "Unknown"}:`,
          error.message
        );
        reject(error);
      }
    });
  };

  const openSelectedInvoicesSequentially = async () => {
    for (let i = 0; i < checkedItems.length; i++) {
      try {
        // Open one invoice and wait before opening the next one
        await updateOpenUrl(checkedItems[i]);
      } catch (error) {
        console.error(`Failed to open invoice ${i + 1}:`, error.message);
      }
      await new Promise((resolve) => setTimeout(resolve, 500)); // Small delay to ensure smooth opening
    }
  };

  const updateUrl = (file) => {
    if (file?.xero_invoice_pdf) {
      dispatch(
        GetSignedUrlByKey({ url: file?.xero_invoice_pdf }, "client")
      ).then((response) => {
        if (response.success) {
          downloadSingleInvoice(response?.data, file);
        }
      });
    }
  };
  const downloadInvoice = (item) => {
    updateUrl(item);
  };

  const downloadSingleInvoice = async (pdf, item) => {
    try {
      if (!pdf || !item?.xero_invoice_number) {
        throw new Error("Invalid invoice data: Missing URL or invoice number");
      }

      const response = await fetch(pdf);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const url = window.URL.createObjectURL(blob); // Create an object URL for the Blob

      const link = document.createElement("a");
      link.href = url;
      link.download = `${item.xero_invoice_number}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error(
        `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
        error.message
      );
    }
  };
  const downloadSelectedInvoices = async () => {
    for (const [index, item] of checkedItems.entries()) {
      await new Promise((resolve) => {
        setTimeout(() => {
          updateUrl(item);
          resolve();
        }, index * 300); // Introducing a delay of 300ms between downloads
      });
    }

    // After all downloads are attempted, show success dialog and reset checked items
    setPdfDownloadSuccessDialog(true);
    setCheckedItems([]);
  };

  const handleRowClick = (item) => {
    setInvoicesData(item);
    setPdfViewDialog(true);
  };
  const onDialogClose = () => {
    setPdfViewDialog(false);
  };

  return (
    <React.Fragment>
      <div className="border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <Checkbox
                  className="cursor-pointer"
                  checked={
                    checkedItems.length === props?.allInvoicesData?.length
                      ? mainCheck
                      : false
                  }
                  onChange={(e) => onCheckboxChange(e, "mainCheck")}
                />
              </Th>
              <Th>
                <div className="flex gap-1">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => props?.handleSort("xero_invoice_number")}
                  >
                    Invoice no.
                    <svg
                      className={`transform ${
                        props?.filtersort === "xero_invoice_number" &&
                        props?.filterorder === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Th>
              <Th>Amount</Th>
              <Th>Payment Sent</Th>
              <Th>Payment Paid</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.allInvoicesData?.map((item) => {
              let status = "";

              if (item.xero_invoice_status === "AUTHORISED") {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Set time to 00:00:00
                const invoiceSendDate = new Date(item.invoice_send_date);
                invoiceSendDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                const upcomingCreatedDate = new Date(
                  item.upcoming_created_date
                );
                upcomingCreatedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                const dueDate = new Date(item.due_date);
                dueDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                // Check if today's date is between the two dates

                if (
                  item.upcoming_created_date &&
                  // today >= upcomingCreatedDate &&
                  today < invoiceSendDate
                ) {
                  status = "UPCOMING";
                } else if (today >= invoiceSendDate && today <= dueDate) {
                  status = "DUE";
                } else if (dueDate < today) {
                  status = "OVERDUE";
                }

                // const today = new Date();
                // const invoiceSendDate = new Date(item.invoice_send_date);
                // const upcomingCreatedDate = new Date(
                //   item.upcoming_created_date
                // );
                // const dueDate = new Date(item.due_date);
                // const today = new Date();
                // today.setHours(0, 0, 0, 0); // Reset time to midnight for accurate comparison

                // const invoiceSendDate = new Date(item.invoice_send_date);
                // invoiceSendDate.setHours(0, 0, 0, 0); // Reset time to midnight

                // const upcomingCreatedDate = new Date(
                //   item.upcoming_created_date
                // );
                // upcomingCreatedDate.setHours(0, 0, 0, 0); // Reset time to midnight
                // const dueDate = new Date(item.due_date);
                // dueDate.setHours(0, 0, 0, 0); // Reset time to midnight
                // // Check if today falls between invoiceSendDate and upcomingCreatedDate (inclusive)
                // if (today >= invoiceSendDate && today <= upcomingCreatedDate) {
                //   status = "UPCOMING";
                // } else if (today >= invoiceSendDate && today <= dueDate) {
                //   status = "DUE";
                // } else if (dueDate < today) {
                //   status = "OVERDUE";
                // }

                // if (invoiceSendDate >= today && invoiceSendDate <= upcomingCreatedDate) {
                //   status = "UPCOMING";
                // } else if (invoiceSendDate >= today && invoiceSendDate <= upcomingCreatedDate) {
                //   status = "DUE";
                // } else if (dueDate < today) {
                //   status = "OVERDUE";
                // }
              }

              // let status;
              // if(item.xero_invoice_status=="AUTHORISED"){
              //   item.end_date
              //   if(){
              //     status="Upcoming"
              //   }
              //   else{
              //     status="Due"
              //   }

              // }
              return (
                <Tr key={item?.account_xero_invoice_id}>
                  <Td onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      className="cursor-pointer"
                      checked={checkedItems?.some(
                        (checkedItem) =>
                          checkedItem?.account_xero_invoice_id ===
                          item?.account_xero_invoice_id
                      )}
                      onChange={(e) => onCheckboxChange(e, item)}
                    />
                  </Td>
                  <Td>{item?.xero_invoice_number}</Td>
                  <Td>{item?.amount}</Td>
                  <Td>
                    {item?.invoice_send_date
                      ? moment(item?.invoice_send_date).format("YYYY/MM/DD")
                      : "-"}
                  </Td>
                  <Td>
                    {item?.payment_date
                      ? moment(item?.payment_date).format("YYYY/MM/DD")
                      : "-"}
                  </Td>
                  <Td>
                    {(props?.viewType
                      ? props?.viewType
                      : item.xero_invoice_status) === "PAID" && (
                      <Tag className="bg-success-50 text-success-700">
                        <span>
                          <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.39355 0.981445L3.89355 6.48145L1.39355 3.98145"
                              stroke="#3DB77E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        Paid
                      </Tag>
                    )}
                    {(props?.viewType ? props?.viewType : status) ===
                      "OVERDUE" && (
                      <Tag className="bg-error-50 text-error-700">
                        <span>
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_7446_51239)">
                              <path
                                d="M6.39355 4.98145V6.98145M6.39355 8.98145H6.39855M1.39355 5.24282V8.72007C1.39355 8.84237 1.39355 8.90352 1.40737 8.96106C1.41962 9.01208 1.43982 9.06085 1.46723 9.10559C1.49816 9.15605 1.54139 9.19928 1.62787 9.28576L4.08924 11.7471C4.17572 11.8336 4.21895 11.8768 4.26941 11.9078C4.31415 11.9352 4.36292 11.9554 4.41394 11.9676C4.47148 11.9814 4.53263 11.9814 4.65493 11.9814H8.13218C8.25448 11.9814 8.31563 11.9814 8.37317 11.9676C8.42419 11.9554 8.47296 11.9352 8.5177 11.9078C8.56816 11.8768 8.61139 11.8336 8.69787 11.7471L11.1592 9.28576C11.2457 9.19928 11.289 9.15605 11.3199 9.10559C11.3473 9.06085 11.3675 9.01208 11.3797 8.96106C11.3936 8.90352 11.3936 8.84237 11.3936 8.72007V5.24282C11.3936 5.12052 11.3936 5.05937 11.3797 5.00183C11.3675 4.95081 11.3473 4.90204 11.3199 4.8573C11.289 4.80684 11.2457 4.76361 11.1592 4.67713L8.69787 2.21576C8.61139 2.12928 8.56816 2.08605 8.5177 2.05513C8.47296 2.02771 8.42419 2.00751 8.37317 1.99526C8.31563 1.98145 8.25448 1.98145 8.13218 1.98145H4.65493C4.53263 1.98145 4.47148 1.98145 4.41394 1.99526C4.36292 2.00751 4.31415 2.02771 4.26941 2.05513C4.21895 2.08605 4.17572 2.12928 4.08924 2.21576L1.62787 4.67713C1.54139 4.76361 1.49816 4.80684 1.46723 4.8573C1.43982 4.90204 1.41962 4.95081 1.40737 5.00183C1.39355 5.05937 1.39355 5.12052 1.39355 5.24282Z"
                                stroke="#F04438"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7446_51239">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0.393555 0.981445)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>{" "}
                        Overdue
                      </Tag>
                    )}
                    {((props?.viewType ? props?.viewType : status) ==
                      "UPCOMING" ||
                      (props?.viewType ? props?.viewType : status) ==
                        "DUE") && (
                      <Tag className="bg-warning-50 text-warning-700">
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_18999_70113)">
                              <path
                                d="M11.35 5.75L10.3503 6.75L9.34998 5.75M10.4725 6.5C10.4907 6.33583 10.5 6.169 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C7.41363 10.5 8.67502 9.84817 9.5 8.82867M6 3.5V6L7.5 7"
                                stroke="#F79009"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18999_70113">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>{" "}
                        {status}
                      </Tag>
                    )}
                    {(props?.viewType
                      ? props?.viewType
                      : item.xero_invoice_status) === "DRAFT" && (
                      <Tag className="bg-blue-100 text-blue-700">
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_18999_37916)">
                              <path
                                d="M1.25 10.7502L4.02463 9.68299C4.2021 9.61473 4.29084 9.5806 4.37386 9.53603C4.4476 9.49645 4.5179 9.45077 4.58402 9.39946C4.65846 9.34169 4.72569 9.27447 4.86014 9.14001L10.5 3.50015C11.0523 2.94787 11.0523 2.05244 10.5 1.50015C9.94773 0.947867 9.0523 0.947866 8.50001 1.50015L2.86014 7.14001C2.72569 7.27446 2.65846 7.34169 2.6007 7.41613C2.54939 7.48225 2.5037 7.55255 2.46412 7.62629C2.41955 7.70931 2.38542 7.79805 2.31717 7.97552L1.25 10.7502ZM1.25 10.7502L2.27906 8.07463C2.3527 7.88317 2.38952 7.78744 2.45267 7.74359C2.50786 7.70527 2.57615 7.69078 2.64215 7.70338C2.71767 7.7178 2.79019 7.79033 2.93524 7.93538L4.06479 9.06493C4.20984 9.20997 4.28236 9.2825 4.29678 9.35802C4.30939 9.42401 4.29489 9.49231 4.25657 9.5475C4.21272 9.61065 4.11699 9.64747 3.92553 9.72111L1.25 10.7502Z"
                                stroke="#0E4E9A"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18999_37916">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        Draft
                      </Tag>
                    )}
                  </Td>
                  <Td onClick={(e) => e.stopPropagation()}>
                    {item.xero_invoice_status !== "DRAFT" ? (
                      <Button onClick={() => downloadInvoice(item)}>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                            stroke="#344054"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    ) : (
                      <div className="flex gap-2 reset-dropdown">
                        {/* <Button onClick={() => handleEditDataforRedux(item)}>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.39668 15.4059C2.43497 15.0613 2.45411 14.889 2.50624 14.728C2.55249 14.5851 2.61784 14.4491 2.70051 14.3238C2.79369 14.1825 2.91627 14.0599 3.16142 13.8147L14.1667 2.80949C15.0871 1.88902 16.5795 1.88902 17.5 2.8095C18.4205 3.72997 18.4205 5.22236 17.5 6.14283L6.49475 17.1481C6.2496 17.3932 6.12702 17.5158 5.98572 17.609C5.86035 17.6916 5.72439 17.757 5.58152 17.8032C5.42048 17.8554 5.24819 17.8745 4.90362 17.9128L2.08331 18.2262L2.39668 15.4059Z"
                              stroke="#475467"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Button> */}
                        -
                      </div>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>

      {checkedItems?.length > 0 && (
        <>
          <div className="font-semibold my-5">
            <ul
              className="flex items-center justify-center w-[400px] h-[56px] rounded-[110px] p-2 mx-auto my-auto"
              style={{ boxShadow: "0px 0px 24px 0px rgb(214, 221, 237)" }}
            >
              <li
                className="p-2"
                style={{ color: "#5786CC", position: "relative" }}
              >
                <Button>{`${checkedItems?.length} selected`}</Button>
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
              </li>
              <li
                onClick={() => {
                  // setDialogResendMultiInvoice(true);
                  openSelectedInvoicesSequentially();
                }}
                className="p-2 relative flex gap-1 cursor-pointer"
              >
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    d="M9.16669 2.78828H5.66669C4.26656 2.78828 3.56649 2.78828 3.03171 3.06076C2.56131 3.30044 2.17885 3.68289 1.93917 4.1533C1.66669 4.68808 1.66669 5.38814 1.66669 6.78828V13.7883C1.66669 15.1884 1.66669 15.8885 1.93917 16.4233C2.17885 16.8937 2.56131 17.2761 3.03171 17.5158C3.56649 17.7883 4.26656 17.7883 5.66669 17.7883H12.6667C14.0668 17.7883 14.7669 17.7883 15.3017 17.5158C15.7721 17.2761 16.1545 16.8937 16.3942 16.4233C16.6667 15.8885 16.6667 15.1884 16.6667 13.7883V10.2883M6.66666 12.7883H8.06212C8.46977 12.7883 8.67359 12.7883 8.86541 12.7422C9.03547 12.7014 9.19804 12.6341 9.34716 12.5427C9.51536 12.4396 9.65948 12.2955 9.94773 12.0072L17.9167 4.03828C18.607 3.34792 18.607 2.22863 17.9167 1.53828C17.2263 0.847919 16.107 0.847919 15.4167 1.53827L7.44772 9.50723C7.15946 9.79548 7.01534 9.93961 6.91227 10.1078C6.82088 10.2569 6.75354 10.4195 6.71271 10.5896C6.66666 10.7814 6.66666 10.9852 6.66666 11.3928V12.7883Z"
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Open</span>
              </li>
              <li
                onClick={() => {
                  if (
                    checkedItems?.length === 1 &&
                    checkedItems[0]?.xero_invoice_status === "DRAFT"
                  ) {
                  } else {
                    downloadSelectedInvoices();
                  }
                }}
                className={`p-2 flex gap-1 cursor-pointer ${
                  checkedItems?.every(
                    (item) => item?.xero_invoice_status === "DRAFT"
                  )
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                    stroke="#344054"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Download</span>
              </li>
            </ul>
          </div>
        </>
      )}

      <Dialog
        isOpen={pdfDownloadSuccessDialog}
        onClose={closeDownloadSuccessPdf}
        onRequestClose={closeDownloadSuccessPdf}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Selected PDFs downloaded successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeDownloadSuccessPdf()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default InvoiceList;
