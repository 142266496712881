import moment from "moment";
import React from "react";

const PreviewInvoice = ({ specificInvoiceData }) => {
  const invoiceData = {
    invoiceNumber: "-",
    date: moment(new Date()).format("YYYY-MM-DD"),
    dueDate: specificInvoiceData?.due_date || "-",
    companyName: "Your Company Name",
    clientName: "Client Name",
    clientAddress: "123 Client Street, City, Country",
    items: specificInvoiceData?.line_items,
    taxRate: 10, // Percentage
    total_amount: specificInvoiceData?.total_amount,
  };

  const calculateSubtotal = () => {
    return invoiceData.items.reduce(
      (total, item) => total + 1 * item.unit_amount,
      0
    );
  };

  const calculateTax = (subtotal) => {
    return (subtotal * 10) / 100;
  };

  const subtotal = calculateSubtotal();
  const tax = calculateTax(subtotal);
  const total = subtotal + tax;

  return (
    <div className="max-w-4xl mx-auto my-10 p-8 bg-white border shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Invoice</h1>

      <div className="mb-6">
        <p className="text-lg font-semibold">{invoiceData.companyName}</p>
        <p>Invoice #: {invoiceData.invoiceNumber}</p>
        <p>Date: {invoiceData.date}</p>
        <p>Due Date: {invoiceData.dueDate}</p>
      </div>

      <div className="mb-6">
        <p className="text-lg font-semibold">Bill To:</p>
        <p>{invoiceData.clientName}</p>
        <p>{invoiceData.clientAddress}</p>
      </div>

      <table className="w-full border-collapse border border-gray-300 mb-6">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">
              Description
            </th>
            <th className="border border-gray-300 px-4 py-2 text-right">
              Quantity
            </th>
            <th className="border border-gray-300 px-4 py-2 text-right">
              Price
            </th>
            <th className="border border-gray-300 px-4 py-2 text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {invoiceData?.items?.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-4 py-2">
                {item?.item_desc}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-right">
                {1}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-right">
                ${Number(item?.unit_amount)?.toFixed(2)}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-right">
                ${(1 * Number(item.unit_amount)).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="text-right">
        <p>Subtotal: ${subtotal.toFixed(2)}</p>
        <p>
          Tax ({invoiceData.taxRate}%): ${tax.toFixed(2)}
        </p>
        <p className="font-bold">Total: ${total.toFixed(2)}</p>
      </div>

      <p className="text-center text-gray-500 mt-6">
        Thank you for your business!
      </p>
    </div>
  );
};

export default PreviewInvoice;
