import React, { useState, useEffect, useCallback } from "react";
import Heading from "../../../../common/element/Heading";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
// import BtnLight from "../../../../common/element/Buttons/btnLight";
import EmailIcon from "../../../../assets/icons-svg/email.svg";
import AddUser from "./Dialog/AddUser";
import Dialog from "../../../../common/element/Dialog/Dialog";
import UserList from "./UserList";
import { debounce } from "../../../../common/snippet/Debouncing";
import ClientInput from "../../../../common/element/Input/clientInput";
import PlusIcon from "../../../../assets/icons-svg/plus.svg";
import UserIcon from "../../../../assets/icons-svg/user.svg";
import SearchImg from "../../../../assets/icons-svg/search-lg.svg";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import {
  GetAllSpecificOrgInvites,
  GetAllSpecificOrgUsers,
} from "../../../../actions/provider-portal/AllUserAPIs";
import { useDispatch, useSelector } from "react-redux";
// import Email from "../Email/Email";
import Email from "../../../clientPortal/pages/ClientPortal/Email/Email";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import ThreeDotBouncing from "../../../../common/element/PageLoader";
const ProviderPortalUsers = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [loadData, setLoadData] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [invitedUser, setInvitedUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allUser, setAllUser] = useState("");
  const [forceRender, setForceRender] = useState(false);
  const permissionProviderData = useSelector(
    (state) => state?.permissionsProvider?.permissionData
  );

  // Permission
  useEffect(() => {
    if (permissionProviderData !== null) {
      localStorage?.setItem(
        "permission_data",
        JSON?.stringify(permissionProviderData)
      );
      setForceRender((prevForceRender) => !prevForceRender);
    }
  }, [permissionProviderData]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }

  let function_assignment_id;
  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    function_assignment_id = storedData.function_assignment_id;
    organisation_id = storedData.organisation_id;
  }

  // state to open/ close email dialog
  const [dialogEmailOpen, setEmailOpen] = useState(false);
  // to open email dialog
  // const openEmailDialog = () => {
  //   setEmailOpen(true);
  // };
  // to close email dialog
  const onEmailClose = (e) => {
    setEmailOpen(false);
  };

  const setInviteData = (newData) => {
    setInvitedUser(newData);
  };
  // const setUserList = (newData) => {
  //   setAllUser(newData);
  // }

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Users", url: "/provider/users", home: "/provider/dashboard" },
      ])
    );
  }, []);

  //Search data
  const handleSearch = (e) => {
    setSearchTerm(e?.target?.value.trim());
  };
  const inputchangehandler = useCallback(debounce(handleSearch), []);

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  // const onDialogOk = (e) => {
  //   setIsOpen(false);
  // };
  useEffect(() => {
    dispatch(GetAllSpecificOrgUsers(organisation_id, searchTerm)).then(
      (response) => {
        if (response.success) {
          setLoadData(true);
          const arrayData = response.data;
          setAllUser(arrayData);
        } else if (response.status === 401) {
          setLoadData(true);
          handleUnauthorizedAccess();
        } else {
          setLoadData(true);
        }
      }
    );
    dispatch(
      GetAllSpecificOrgInvites({
        function_assignment_id: function_assignment_id,
        invited_user_type: "provider_user",
        search: searchTerm,
      })
    ).then((response) => {
      if (response.success) {
        setLoadData(true);
        const arrayData = response.data;
        setInvitedUser(arrayData);
        // props.setInvitedUser
      } else if (response.status === 401) {
        setLoadData(true);
        handleUnauthorizedAccess();
      } else {
        setLoadData(true);
      }
    });
  }, [searchTerm, setSearchTerm]);
  return (
    <React.Fragment>
      {/* <PortalLayout> */}
      <div className="flex justify-between items-start mb-5">
        <div className="mb-2">
          <Heading>Users management</Heading>
          <p className="text-gray-600">
            {" "}
            Manage your team members and their account permissions here.
          </p>
        </div>
        <div className="flex gap-2">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={SearchImg} alt="" className="h-5" />
            </div>
            <ClientInput
              type="text"
              className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              inputchangehandler={inputchangehandler}
              name={"search"}
            />
          </div>
          {/* <BtnLight
            onClick={() => openEmailDialog()}
            className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
          >
            Email <img src={EmailIcon} alt="" className="ml-2" />
          </BtnLight> */}

          {/*permission for Read and Write */}
          <BtnPrimary
            onClick={() => openDialog()}
            variant="solid"
            disabled={permission_data.users_write == true ? false : true}
            className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
            type="button"
          >
            Invite user
            <span className="ml-3">
              <img src={PlusIcon} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>

      <UserList
        permission_data={permission_data}
        allUser={allUser}
        invitedUser={invitedUser}
        onInvitedUser={setInviteData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {/* </PortalLayout> */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={UserIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a user
            </h5>
          </div>
        </div>
        <AddUser onDialogClose={onDialogClose} onInvitedUser={setInviteData} />
      </Dialog>
      <Dialog
        isOpen={dialogEmailOpen}
        onClose={onEmailClose}
        onRequestClose={onEmailClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <Email onEmailClose={onEmailClose} />
      </Dialog>
    </React.Fragment>
  );
};
export default ProviderPortalUsers;
