import React, { useState, useEffect, useCallback } from "react";
import Heading from "../../../../../common/element/Heading";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import EmailIcon from "../../../../../assets/icons-svg/email.svg";
import AddUser from "./Dialog/AddUser";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import UserList from "./UserList";
import PlusIcon from "../../../../../assets/icons-svg/plus.svg";
import UserIcon from "../../../../../assets/icons-svg/user.svg";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import {
  GetAllSpecificOrgInvites,
  GetAllSpecificOrgUsers,
} from "../../../../../actions/client-portal/AllUsersApi";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../../common/snippet/Debouncing";
import Email from "../Email/Email";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
const ClientPortalUsers = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const permissionDataUpdate = useSelector(
    (state) => state?.permissions?.permissionData
  );
  const [forceRender, setForceRender] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [invitedUser, setInvitedUser] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [allUser, setAllUser] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem(
        "permission_data",
        JSON.stringify(permissionDataUpdate)
      );
      setForceRender((prevForceRender) => !prevForceRender);
      if (permissionDataUpdate?.users_view == false) {
        // navigate('/client/dashboard');
        navigate("/client/noaccess");
      }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }
  let usersPermission = permission_data.users_write == true ? false : true;

  let function_assignment_id;
  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    function_assignment_id = storedData?.function_assignment_id;
    organisation_id = storedData?.organisation_id;
  }

  // state to open/ close email dialog
  const [dialogEmailOpen, setEmailOpen] = useState(false);
  // to open email dialog
  const openEmailDialog = () => {
    setEmailOpen(true);
  };
  // to close email dialog
  const onEmailClose = (e) => {
    if (!buttonDisabled) {
      setEmailOpen(false);
    }
  };

  const setInviteData = (newData) => {
    setInvitedUser(newData);
  };
  const setUserList = (newData) => {
    setAllUser(newData);
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Users", url: "/client/users", home: "/client/dashboard" },
      ])
    );
  }, []);

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
    }
  };

  const onDialogOk = (e) => {
    setIsOpen(false);
  };
  const fetchAllSpecificOrgUsers = useCallback(
    async (search) => {
      const response = await dispatch(
        GetAllSpecificOrgUsers(organisation_id, search)
      );
      if (response.success) {
        setLoadData(true);
        const arrayData = response.data;
        setAllUser(arrayData);
      } else if (response.status === 401) {
        setLoadData(true);
        handleUnauthorizedAccess();
      } else {
        setLoadData(true);
      }
    },
    [dispatch]
  );
  
  const fetchAllSpecificOrgInvites = useCallback(
    async (searchT) => {
      const response = await dispatch(
        GetAllSpecificOrgInvites({
          function_assignment_id,
          invited_user_type: "client_user",
          search: searchT,
        })
      );
      if (response.success) {
        setLoadData(true);
        const arrayData = response.data;
        setInvitedUser(arrayData);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
        setLoadData(true);
      } else {
        setLoadData(true);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setLoadData(false);
    fetchAllSpecificOrgUsers(searchTerm);
    fetchAllSpecificOrgInvites(searchTerm);
  }, [searchTerm]);

  const handleSeachChange = (e) => {
    setsearchTerm(e?.target?.value.trim());
  };

  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  return (
    <React.Fragment>
      {/* <PortalLayout> */}
      <div className="flex justify-between mb-5">
        <div className="mb-2">
          <Heading>Users management</Heading>
          <p className="text-gray-600">
            {" "}
            Manage your team members and their account permissions here.
          </p>
        </div>
        {/* <BtnLight
          disabled={usersPermission}
          onClick={() => openEmailDialog()}
          className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
        >
          Email <img src={EmailIcon} alt="" className="ml-2" />
        </BtnLight> */}

        <div className="flex gap-3">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
          <BtnPrimary
            onClick={() => openDialog()}
            variant="solid"
            disabled={usersPermission}
            className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
            type="button"
          >
            Invite user
            <span className="ml-3">
              <img src={PlusIcon} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>

      <UserList
        fetchAllSpecificOrgUsers={fetchAllSpecificOrgUsers}
        fetchAllSpecificOrgInvites={fetchAllSpecificOrgInvites}
        usersPermission={usersPermission}
        allUser={allUser}
        invitedUser={invitedUser}
        onInvitedUser={setInviteData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {/* </PortalLayout> */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={UserIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a user
            </h5>
          </div>
        </div>
        <AddUser
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          fetchAllSpecificOrgInvites={fetchAllSpecificOrgInvites}
          onDialogClose={onDialogClose}
          onInvitedUser={setInviteData}
        />
      </Dialog>
      <Dialog
        isOpen={dialogEmailOpen}
        onClose={onEmailClose}
        onRequestClose={onEmailClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <Email
          onEmailClose={onEmailClose}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default ClientPortalUsers;
