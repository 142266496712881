import axios from "axios";
import * as actionType from "../../../constant/actionType";
export const CreateAssignWorkersToClient = (ApiData) => async (dispatch) => {
  try {
const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

    const response = await axios({
      method: "post",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/CreateAssignWorkersToClient`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
