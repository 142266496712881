import axios from "axios";
import * as actionType from "../../../constant/actionType";
export const CreateProviderDoc = (ApiData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === "doc_file") {
        formdata.append("doc_file", ApiData.doc_file[0]);
      } else if (key === "doctype_fields_data") {
        formdata.append(key, JSON.stringify(ApiData[key]));
      } else {
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/CreateProviderDoc`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
