import React from 'react'

import classNames from 'classnames'

const CloseButton = React.forwardRef((props, ref) => {
    const { absolute, className, defaultStyle, svgClass, ...rest } = props
    const closeButtonAbsoluteClass = 'absolute z-10'

    const closeButtonClass = classNames(
        
        defaultStyle && 'close-btn-default',
        absolute && closeButtonAbsoluteClass,
        className
    )

    return (
        <span className={closeButtonClass} role="button" {...rest} ref={ref}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m18 6.5-12 12m0-12 12 12" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


        </span>
    )
})

CloseButton.defaultProps = {
    defaultStyle: true,
}

export default CloseButton
