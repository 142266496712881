import React, { useState, useEffect, useCallback } from "react";
import InvoiceList from "./InvoiceList";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { useDispatch } from "react-redux";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import Dialog from "../../../../../../common/element/Dialog";
import { useSelector } from "react-redux";
import { GetProviderInvoicesById } from "../../../../../../actions/admin-provider/invoices/GetProviderInvoicesById";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
const SpecificProviderInvoices = () => {
  const { id } = useParams();
  const orgId = decryptId(id);
  const { SpecificProviderData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("created_date");
  const [allInvoicesData, setAllInvoicesData] = useState([]);
  const [loadData, setLoadData] = useState(false);

  // const getData = [
  //   {
  //     client_uuid: 1,
  //     invoiceNumber: "000000001",
  //     recipient: "Recipient A",
  //     payee: "Client",
  //     amount: "$100.00",
  //     billingData: "01/01/2022",
  //     paymentData: "05/01/2022",
  //     status: "Paid",
  //     invoiceUrl:
  //       "https://conservedev.s3.ap-south-1.amazonaws.com/1728018166364-643095962-sample.pdf",
  //     email: "recipientA@example.com",
  //   },
  //   {
  //     client_uuid: 2,
  //     invoiceNumber: "000000002",
  //     recipient: "Recipient B",
  //     payee: "Provider",
  //     amount: "$200.00",
  //     billingData: "02/01/2022",
  //     paymentData: "-",
  //     status: "Overdue",
  //     invoiceUrl:
  //       "https://conservedev.s3.ap-south-1.amazonaws.com/1728017017266-554973309-BillFormat.pdf",
  //     email: "recipientB@example.com",
  //   },
  //   {
  //     client_uuid: 3,
  //     invoiceNumber: "000000003",
  //     recipient: "Recipient C",
  //     payee: "Client",
  //     amount: "$300.00",
  //     billingData: "03/01/2022",
  //     paymentData: "-",
  //     status: "Upcoming",
  //     invoiceUrl:
  //       "https://conservedev.s3.ap-south-1.amazonaws.com/1728017794003-303364362-agreement.pdf",
  //     email: "recipientC@example.com",
  //   },
  //   {
  //     client_uuid: 4,
  //     invoiceNumber: "000000004",
  //     recipient: "Recipient D",
  //     payee: "Client",
  //     amount: "$150.00",
  //     billingData: "04/01/2022",
  //     paymentData: "04/01/2022",
  //     status: "Paid",
  //     invoiceUrl: "https://example.com/invoice-draft.pdf",
  //     email: "recipientD@example.com",
  //   }, // New Draft Invoice
  // ];

  // useEffect(() => {
  //   dispatch(
  //     setBreadcrumbs([
  //       { label: "Invoices", url: "/admin/invoices", home: "/admin/dashboard" },
  //     ])
  //   );
  // }, []);

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Provider",
          url: "/admin/provider/view-all-provider",
          home: "/admin/provider/dashboard",
        },
        {
          label: `${SpecificProviderData?.trading_name}`,
          url: `/admin/provider/view-all-provider/specific-provider/${encodeURIComponent(
            id
          )}`,
        },

        {
          label: "Invoices",
          url: `/admin/provider/view-all-provider/specific-provider/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, []);

  const onGetAllInvoiceData = useCallback(async () => {
    setLoadData(false);
    // dispatch(AdminGetAllProviderInvoices()).then(()=>{

    // })
    // setAllInvoicesData(getData);
    // setCountData(getData.length);
    // setTotalPages(Math.ceil(getData.length / filterlimit));

    try {
      const payload = {
        sort: filtersort,
        order: filterorder,
        provider_org_id: orgId,
      };
      const response = await dispatch(GetProviderInvoicesById(payload));
      if (response?.success) {
        setLoadData(true);
        const arrayData = response?.data;
        setAllInvoicesData(arrayData);
        // setCountData(response?.data?.count);
        // setTotalPages(Math.ceil(response.data.count / filterlimit));
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
        setLoadData(true);
      }
    } catch (error) {
      setLoadData(true);
      console.error("Error fetching global permissions:", error);
    }
  }, [dispatch, filterorder, filtersort]);
  useEffect(() => {
    onGetAllInvoiceData();
  }, [filterorder, filtersort]);

  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  
  return (
    <React.Fragment>
      <InvoiceList
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        allInvoicesData={allInvoicesData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && allInvoicesData?.length == 0 && (
        <div className="h-full w-full text-center mt-2">No Data Available!</div>
      )}
      {/* <div className="flex justify-between mt-3">
        <Pagination
          totalCount={countData || 0}
          pageNumber={filterpage}
          displayPage={allInvoicesData?.length}
          UpdatePageLimit={setfilterlimit}
          UpdatePage={setfilterpage}
        />
        {countData > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={filterpage - 1}
          />
        )}
      </div> */}
    </React.Fragment>
  );
};
export default SpecificProviderInvoices;
