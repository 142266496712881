import React, { useEffect, useState } from "react";
// import Card from "../components/element/Card";
import Card from "../../../../common/element/Card";
import Heading from "../../../../common/element/Heading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../common/element/Select";
import Upload from "../../../../common/element/Upload";
import { useDispatch } from "react-redux";
import Validations from "../../../../common/404/Validations";
import Circle from "../../../../common/element/Iconcircle";
import { GetAllCountries } from "../../../../actions";
import { GetOrgUserInvitationById } from "../../../../actions/public/GetOrgUserInvitationById";
import { GetAllStatesbyCountryId } from "../../../../actions/public/GetAllStatesbyCountryId";
import { SubmitWorkerInvitation } from "../../../../actions/admin-provider/SubmitWorkerInvitation";
import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { decryptId } from "../../../../common/encryptDecrypt/encryptdecrypt";
import preventInvalidChars from "../../../../utils/preventInvalidChars";

const LeftSideWrap = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const queryParams = new URLSearchParams(location.search);
  const tradingNameFromURL = queryParams.get("trading_name");
  const provider_org_id = queryParams.get("provider_org_id");
  const [errorvalidation, seterrorvalidation] = useState({
    password: "",
    confirmPassword: "",
    fistName: "",
    lastName: "",
    country: "",
    state: "",
    jobTitle: "",
  });
  const invite_id = decryptId(queryParams.get("invite_id").replace(/\s/g, "+"));

  const [passwordError, setPasswordError] = useState("");
  const [checkRedirect, setcheckRedirect] = useState(false);
  const [avatarValidation, setavatarValidation] = useState("");
  const [visibleHide, setVisibleHide] = useState({
    password: true,
    confirmPassword: true,
  });
  const [repeatPassword, setRepeatPassword] = useState("");
  const [formStatus, setformStatus] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [avtar, setavtar] = useState(true);
  const [countryData, setcountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [formData, SetFormData] = useState("");

  const showHandle = (e) => {
    e.event.preventDefault();
    setVisibleHide((prevalue) => {
      if (e.name == "password") {
        return {
          ...prevalue,
          [e.name]: !visibleHide.password,
        };
      } else {
        return {
          ...prevalue,
          [e.name]: !visibleHide.confirmPassword,
        };
      }
    });
  };

  // Get Country Function
  const HandleCountryChanges = (e) => {
    SetFormData((prevalue) => {
      return {
        ...prevalue,
        ["country_id"]: e.value,
      };
    });
  };

  // State Data
  const HandleStateChanges = (e) => {
    SetFormData((prevalue) => {
      return { ...prevalue, ["state_id"]: e.value };
    });
  };

  const openSelect = (e) => {
    // State data
    if (e === "state") {
      if (
        formData?.country_id != undefined &&
        formData?.country_id != null &&
        formData?.country_id != ""
      ) {
        dispatch(GetAllStatesbyCountryId(formData?.country_id)).then(
          (response) => {
            if (response.success) {
              const filterData = response?.data?.map((item) => {
                return {
                  value: item.state_id,
                  label: item.state_name,
                };
              });
              setStateData(filterData);
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          }
        );
      }
    }
    // countryData,
    else {
      dispatch(GetAllCountries()).then((response) => {
        if (response.success) {
          const filterData = response?.data?.map((item) => {
            return {
              value: item.country_id,
              label: item.name,
            };
          });
          setcountryData(filterData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  };

  // Get Invite Data
  useEffect(() => {
    dispatch(GetOrgUserInvitationById(invite_id)).then((response) => {
      let data = response.data;
      if (data) {
        SetFormData({
          invite_id: data.invite_id,
          created_by: data.created_by,
          trading_name:
            data?.invite_fun_assign?.org_data?.trading_name == undefined
              ? ""
              : data?.invite_fun_assign?.org_data?.trading_name,
          password: "",
          first_name: data.individuals[0].first_name,
          last_name: data.individuals[0].last_name,
          email: data.individuals[0].email,
          state_id:
            data.individuals[0].state_id == null
              ? ""
              : data.individuals[0].state_id,
          individual_id: data.individual_id,
          organisation_id: data?.invite_fun_assign?.organisation_id,
          country_id:
            data.individuals[0].country_id == null
              ? ""
              : data.individuals[0].country_id,
          phone:
            data.individuals[0].phone == null
              ? ""
              : data.individuals[0].phone == null,
          worker_job_title: "",
          avatar:
            data.individuals[0].avatar == null
              ? ""
              : data?.individuals[0].avatar,
        });

        if (
          response.data.individuals[0].avatar !== null &&
          response.data.individuals[0].avatar !== ""
        ) {
          setavtar(false);
        }
        if (response.data.invite_status == "Invited") {
          setcheckRedirect(true);
          if (
            response.data.individuals[0].user_id == null ||
            response.data.individuals[0].user_id == ""
          ) {
            setformStatus(true);
          } else {
            setformStatus(false);
          }
        } else {
          navigate("/login");
          setcheckRedirect(false);
        }
      } else {
        setcheckRedirect(true);
      }
    });
  }, []);

  // Submit Data function
  const completeRegistration = () => {
    let combPswd = true;
    let validationCheck = true;
    if (formData.password !== repeatPassword) {
      setPasswordError("Passwords do not match");
      validationCheck = false;
      combPswd = false;
    } else {
      setPasswordError("");
      combPswd = true;
    }
    const isStrong = formData.password.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );
    if (!isStrong) {
      setPasswordError(
        "Your password must be at least 8 characters long, contain at least one special character and have a mixture of lowercase, uppercase and numbers."
      );
      // return;
    }

    const passwordValidation = Validations({
      value: formData.password,
      Validations: { required: true },
    });

    const first_name = Validations({
      value: formData.first_name,
      Validations: { required: true },
    });

    const last_name = Validations({
      value: formData.last_name,
      Validations: { required: true },
    });

    const confirmPassword = Validations({
      value: formData.confirmPassword,
      Validations: { required: true },
    });

    const country = Validations({
      value: formData.country_id,
      Validations: { required: true },
    });

    const state = Validations({
      value: formData.state_id,
      Validations: { required: true },
    });

    const jobTitle = Validations({
      value: formData.worker_job_title,
      Validations: { required: true },
    });

    if (!passwordValidation.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["password"]: passwordValidation.error_msg,
        };
      });
    }

    if (!confirmPassword.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["confirmPassword"]: confirmPassword.error_msg,
        };
      });
    }

    if (!country.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["country"]: country.error_msg,
        };
      });
    }

    if (!state.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["state"]: state.error_msg,
        };
      });
    }

    if (!jobTitle.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["jobTitle"]: jobTitle.error_msg,
        };
      });
    }

    if (!first_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["fistName"]: first_name.error_msg,
        };
      });
    }

    if (!last_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["lastName"]: last_name.error_msg,
        };
      });
    }

    let passwordStatus = formStatus ? passwordValidation.valid : true;
    if (
      passwordStatus &&
      first_name.valid &&
      last_name.valid &&
      // confirmPassword.valid &&
      country.valid &&
      state.valid &&
      jobTitle.valid &&
      combPswd
    ) {
      validationCheck = true;
    } else {
      validationCheck = false;
    }
    if (validationCheck) {
      const updatedFormData = {
        ...formData,
        // provider_org_id: provider_org_id,
        // trading_name: tradingNameFromURL,
      };

      dispatch(SubmitWorkerInvitation(updatedFormData))
        .then((response) => {
          if (response.success) {
            navigate("/login");
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          notify.show(error, "error");
        });
    }
  };
  const handlechange = (e) => {
    if (e.target.name == "confirmPassword") {
      setRepeatPassword(e.target.value);
    } else {
      SetFormData((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
  };
  // Avtar Upload function
  const handleFileChange = (e) => {
    setSelectedImage(null);
    setavatarValidation("");
    if (e[0]) {
      if (/\.(jpg|jpeg|png|svg|gif|)$/i.test(e?.[0]?.name)) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(e[0]);

        SetFormData((prevalue) => {
          return {
            ...prevalue,
            ["avatar"]: e,
          };
        });
      } else {
        setavatarValidation("Only SVG, PNG, JPG or GIF files will be accepted");
      }
    } else {
      setavatarValidation("");
    }
  };
  {
    if (checkRedirect) {
      return (
        <div
          style={{ backgroundColor: `#eff8ff` }}
          className="flex justify-between pr-6 pl-6"
        >
          <div className="w-full  justify-center flex flex-wrap">
            <div className="sideBarStepWrap sticky top-0  pt-20 flex justify-between flex-col pb-20">
              <div className="block pr-20">
                <div className="flex items-center mb-5">
                  <img src="/assets/logo.svg" alt="conserve" width="149px" />
                </div>
                <Heading className="font-bold mb-10 text-2xl">
                  Welcome to Conserve
                </Heading>
                <p className="mb-4 font-medium text-gray-600">
                  You’ve been invited to join{" "}
                  {formData.first_name + " " + formData.last_name} on the
                  Conserve app and are signing up using {formData.email}.
                </p>
                <p className="font-medium text-gray-600">
                  Create a secure password and fill your details to complete
                  your registration.
                </p>
              </div>
              <div className="flex pr-20 gap-4">
                <Link
                  className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex back-btn-hide items-center"
                  to="/login"
                >
                  {" "}
                  <img
                    src="/assets/icons-svg/arrow-left.svg"
                    className="w-5 mr-2"
                    alt=""
                  />{" "}
                  Back to log in
                </Link>
              </div>
            </div>
            <form className="pb-20 pt-20 registerBlock">
              {formStatus && (
                <Card className="p-7  border border-gray-200 rounded-xl mb-7">
                  <h5 className="text-blue-800 font-semibold text-2xl mb-7">
                    Create your password to get started.
                  </h5>
                  <div className="block mb-5">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      for="password"
                    >
                      Password*
                    </label>
                    <div className="relative flex">
                      <input
                        className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={visibleHide.password ? "password" : "text"}
                        name="password"
                        onChange={(e) => handlechange(e)}
                        placeholder=""
                        autoComplete="new-password"
                      />
                      <button
                        className="absolute right-4 top-3"
                        onClick={(e) =>
                          showHandle({ event: e, name: "password" })
                        }
                      >
                        {visibleHide.password ? (
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="21"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="grey"
                            stroke-width="2"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            fill="none"
                            viewBox="0 0 20 21"
                            height="21"
                            stroke="grey"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                              stroke="#667085"
                              stroke-width="1.667"
                            ></path>
                            <path
                              d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                              stroke="#667085"
                              stroke-width="1.667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        )}
                      </button>
                    </div>
                    <p className=" text-xs text-red-600 mt-2">
                      {passwordError}
                    </p>
                  </div>
                  <div className="block mb-5">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      for="password"
                    >
                      Confirm Password*
                    </label>
                    <div className="relative flex">
                      <input
                        className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={visibleHide.confirmPassword ? "password" : "text"}
                        placeholder=""
                        name="confirmPassword"
                        onChange={(e) => handlechange(e)}
                      />
                      <button
                        className="absolute right-4 top-3"
                        onClick={(e) =>
                          showHandle({ event: e, name: "confirmPassword" })
                        }
                      >
                        {visibleHide.confirmPassword ? (
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            height="21"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="grey"
                            stroke-width="2"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            fill="none"
                            viewBox="0 0 20 21"
                            height="21"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                              stroke="#667085"
                              stroke-width="1.667"
                            ></path>
                            <path
                              d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                              stroke="#667085"
                              stroke-width="1.667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        )}
                      </button>
                      {/* {errorvalidation.confirmPassword && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {errorvalidation.confirmPassword}
                        </div>
                      )} */}
                    </div>
                    {errorvalidation.password && (
                      <div className="text-red-500 text-sm mt-1 ">
                        {errorvalidation.password}
                      </div>
                    )}
                  </div>
                </Card>
              )}
              <Card className="registerBlock p-7 border border-gray-200 rounded-xl mb-7">
                <h5 className="text-blue-800 font-semibold text-2xl mb-7">
                  Your information
                </h5>

                <div className="contactBlockWrap">
                  <div className="flex gap-2">
                    <div className="flex-1 mb-5">
                      <label
                        className="block text-gray-700  mb-2 text-xs font-medium"
                        for="first_name"
                      >
                        First name*
                      </label>
                      <input
                        className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        id="first_name"
                        type="text"
                        value={formData.first_name}
                        placeholder="Enter your first name"
                      />
                      {errorvalidation.fistName && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {errorvalidation.fistName}
                        </div>
                      )}
                    </div>
                    <div className="flex-1 mb-5">
                      <label
                        className="block text-gray-700  mb-2 text-xs font-medium"
                        for="last_name"
                      >
                        Last name*
                      </label>
                      <input
                        className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        id="last_name"
                        type="text"
                        value={formData.last_name}
                        placeholder="Enter your last name"
                      />
                      {errorvalidation.lastName && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {errorvalidation.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="flex-1 mb-5">
                      <label
                        className="block text-gray-700  mb-2 text-xs font-medium"
                        for="email"
                      >
                        Email address
                      </label>
                      <input
                        className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="text"
                        value={formData.email}
                        placeholder="Enter your email"
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="flex-1 mb-5">
                      <label
                        className="block text-gray-700  mb-2 text-xs font-medium"
                        for="contact"
                      >
                        Contact number
                      </label>
                      <input
                        className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        id="contact"
                        value={formData.phone}
                        name="phone"
                        onChange={(e) => handlechange(e)}
                        type="number"
                        placeholder=""
                        onKeyDown={preventInvalidChars}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 mb-5">
                    <div className="select-options search-none flex-1">
                      <label className="block text-gray-700  mb-2 text-xs font-medium">
                        Country
                      </label>
                      <Select
                        isSearchable={false}
                        placeholder="Country"
                        onChange={HandleCountryChanges}
                        options={countryData}
                        className="h-10"
                        onMenuOpen={() => openSelect("country")}
                      ></Select>
                      {errorvalidation.country && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {errorvalidation.country}
                        </div>
                      )}
                    </div>
                    <div className="select-options search-none  flex-1">
                      <label className="block text-gray-700  mb-2 text-xs font-medium">
                        State
                      </label>
                      <Select
                        isSearchable={false}
                        placeholder="State"
                        onChange={HandleStateChanges}
                        e
                        options={stateData}
                        className="h-10"
                        onMenuOpen={() => openSelect("state")}
                      ></Select>
                      {errorvalidation.state && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {errorvalidation.state}
                        </div>
                      )}
                      {/* formData.country_id */}
                    </div>
                  </div>
                  <div className="block">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      for="address"
                    >
                      Job title
                    </label>
                    <input
                      className=" flex-1 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      id="address"
                      type="text"
                      name="worker_job_title"
                      value={formData.title}
                      onChange={(e) => handlechange(e)}
                      placeholder=""
                    />
                    {errorvalidation.jobTitle && (
                      <div className="text-red-500 text-sm mt-1 ">
                        {errorvalidation.jobTitle}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
              {/* Avtar JSX */}
              {avtar && (
                <Card className="p-7  border border-gray-200 rounded-xl mb-7">
                  <h5 className="text-blue-800 font-semibold text-2xl mb-7">
                    Your avatar
                  </h5>

                  <h4 className="font-bold text-gray-700 text-base mb-4">
                    Upload your picture for improved profile recognition
                  </h4>
                  <div className="flex ">
                    <Circle className="bg-gray-200  mb-5 w-16 h-16 mr-5">
                      {selectedImage !== null ? (
                        <img src={selectedImage} alt="" />
                      ) : (
                        <img src="/assets/icons-svg/image-plus.svg" alt="" />
                      )}
                    </Circle>
                    <Upload
                      accept=".svg, .png, .jpg, .jpeg, .gif"
                      onChange={handleFileChange}
                      showList={true}
                      onFileRemove={handleFileChange}
                      uploadLimit={1}
                      draggable
                      className="flex flex-col items-center justify-center flex-1 border py-4 px-6 rounded-xl  border-gray-200"
                    >
                      <div className="flex flex-col items-center justify-center">
                        <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                          <span className="font-semibold text-blue-700">
                            Click to upload
                          </span>
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                          SVG, PNG, JPG or GIF (max. 800x400px)
                        </p>
                      </div>
                    </Upload>
                  </div>
                  {avatarValidation && (
                    <div className="text-red-500 text-sm mt-1">
                      {avatarValidation}
                    </div>
                  )}
                </Card>
              )}

              <div className="flex mt-7 justify-end form-footer">
                <BtnPrimary
                  className="w-auto flex justify-center items-center px-6"
                  onClick={() => completeRegistration()}
                >
                  Complete registration
                </BtnPrimary>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
};
export default LeftSideWrap;
