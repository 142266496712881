import axios from "axios";
import * as actionType from "../../constant/actionType";
export const UpdateConserveUsersPersonalPermissions =
  (APIData) => async (dispatch) => {
    try {
      // dispatch(fetchData());
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "patch",
        data: APIData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/provider/UpdateConserveUsersPersonalPermissions`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
