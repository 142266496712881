import React, { useState, useCallback, useEffect, useRef } from "react";
import Dialog from "../../../../../../../../common/element/Dialog";
import Table from "../../../../../../../../common/element/Table";
import Badge from "../../../../../../../../common/element/Badge";
import Select from "../../../../../../../../common/element/Select";
import Tooltip from "../../../../../../../../common/element/Tooltip";
import BtnLight from "../../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../../common/element/Buttons/btnPrimary";
import Validations from "../../../../../../../../common/404/Validations";
import ScrollBar from "../../../../../../../../common/element/ScrollBar/ScrollBar";
import Scrollbar from "../../../../../../../../common/element/ScrollBar";
import { AssignCompChecklistToWorker } from "../../../../../../../../actions/client-portal/AllSiteApi";
import useUnauthorizedHandler from "../../../../../../../../common/snippet/useUnauthorizedHandler";
import Documents from "./DocumentView";
import moment from "moment";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
const DocumentListShow = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const { Tr, Th, Td, THead, TBody } = Table;
  const { organisationId } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const dispatch = useDispatch();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [formError, setFormError] = useState({ checklistAssignErr: "" });
  const [formData, setFormData] = useState({ checklistIds: [] });
  const openDialog = (item) => {
    setSelectedItem(item);
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
  };
  const handleChecklistAssign = () => {
    const checklistValidation = Validations({
      value: formData?.checklistIds?.[0],
      Validations: { required: true },
    });
    if (!checklistValidation?.valid) {
      setFormError((prevError) => ({
        ...prevError,
        checklistAssignErr: checklistValidation?.error_msg,
      }));
    }
    if (checklistValidation?.valid) {
      const payload = {
        client_org_id: props?.client_org_id ? props?.client_org_id : "",
        provider_org_id: props?.provider_org_id ? props?.provider_org_id : "",
        worker_id: props?.worker_id ? props?.worker_id : "",
        checklistIds: formData?.checklistIds ? formData?.checklistIds : [],
      };

      dispatch(AssignCompChecklistToWorker(payload, ipAddress)).then(
        (response) => {
          if (response?.success) {
            props?.OnGetSubmissionDocsOrChecklist();
            setAddOpen(true);
            // const arrayData = response.data;
            //   first show documents
            // call home page api
            // hide checklist assign dialog
            // and pending some data for set in document view
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        }
      );
    }
  };
  const handleSelectChange = (selectedOption) => {
    let filterData = selectedOption.map((item) => item?.value);
    setSelectedOption(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      checklistIds: filterData,
    }));
    setFormError((prevError) => ({
      ...prevError,
      checklistAssignErr: "",
    }));
  };
  return (
    <React.Fragment>
      <div className="p-2 mb-6 mh-430">
        {props?.allDocData?.length > 0 &&
        (props?.allDocData?.[0].workerCheckDocs.length ||
          props?.allDocData?.[1]?.workerCheckDocs?.length) ? (
          <>
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <div className="h-64 overflow-auto">
                <Scrollbar>
                  <Table>
                    <THead>
                      <Tr>
                        <Th>
                          <span
                            onClick={() => props?.handleSort("doc_name")}
                            className="flex items-center gap-1 "
                          >
                            Document
                            <svg
                              className={`transform ${
                                props?.filtersort === "doc_name" &&
                                props?.filterorder === "asc"
                                  ? "rotate-180"
                                  : ""
                              }`}
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                stroke="#475467"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </span>
                        </Th>
                        <Th>Document type</Th>
                        <Th>Issuing Authority</Th>
                        <Th>Expiry Date</Th>
                        <Th>
                          <span className="flex items-center gap-1">
                            Status
                            <Tooltip title="Invited" placement="top-start">
                              <img
                                src="/assets/icons-svg/help-circle.svg"
                                alt="Tooltip"
                              />
                            </Tooltip>
                          </span>
                        </Th>
                      </Tr>
                    </THead>

                    <TBody>
                      {props?.allDocData &&
                        props?.allDocData?.length > 0 &&
                        props?.allDocData?.map((dataItem, index) => (
                          <React.Fragment key={index}>
                            {dataItem?.workerCheckDocs &&
                              dataItem?.workerCheckDocs?.map(
                                (doc, docIndex) => (
                                  <Tr
                                    className="cursor-pointer"
                                    onClick={() => openDialog(doc)}
                                    key={docIndex}
                                  >
                                    <Th>
                                      {doc?.workerDocAppr?.workerApprDoc
                                        ?.doc_name
                                        ? doc?.workerDocAppr?.workerApprDoc
                                            ?.doc_name
                                        : "-"}
                                    </Th>
                                    <Td>
                                      {doc?.document_type?.doc_type_name
                                        ? doc?.document_type?.doc_type_name
                                        : "-"}
                                    </Td>
                                    <Td>
                                      {doc?.document_type?.doc_type_name?.issuer
                                        ? doc?.document_type?.doc_type_name
                                            ?.issuer
                                        : "-"}
                                    </Td>
                                    <Td>
                                      {doc?.workerDocAppr?.workerApprDoc
                                        ?.expiry_date
                                        ? moment(
                                            doc?.workerDocAppr?.workerApprDoc
                                              ?.expiry_date
                                          ).format("DD/MM/YYYY")
                                        : "-"}
                                    </Td>
                                    <Td>
                                      {doc?.workerDocAppr?.approval_status ===
                                        "approved" && (
                                        <Badge
                                          onClick={() => openDialog(doc)}
                                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                          content={"Approved"}
                                          innerClass="bg-success-100 text-success-700"
                                        />
                                      )}
                                      {doc?.workerDocAppr?.approval_status ===
                                        "client_approved_action" && (
                                        <div className="flex gap-3">
                                          <Badge
                                            onClick={() => openDialog(doc)}
                                            className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                            content={"Approved"}
                                            innerClass="bg-success-100 text-success-700"
                                          />
                                          <svg
                                            width="16"
                                            height="17"
                                            viewBox="0 0 16 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M2.66675 14.5L2.66675 3.16667M2.66675 9.16667H7.60008C7.97345 9.16667 8.16013 9.16667 8.30274 9.094C8.42818 9.03009 8.53017 8.9281 8.59409 8.80266C8.66675 8.66005 8.66675 8.47337 8.66675 8.1V3.56667C8.66675 3.1933 8.66675 3.00661 8.59409 2.86401C8.53017 2.73856 8.42818 2.63658 8.30274 2.57266C8.16013 2.5 7.97345 2.5 7.60008 2.5H3.73341C3.36005 2.5 3.17336 2.5 3.03075 2.57266C2.90531 2.63658 2.80333 2.73856 2.73941 2.86401C2.66675 3.00661 2.66675 3.1933 2.66675 3.56667V9.16667ZM8.66675 3.83333H12.9334C13.3068 3.83333 13.4935 3.83333 13.6361 3.906C13.7615 3.96991 13.8635 4.0719 13.9274 4.19734C14.0001 4.33995 14.0001 4.52663 14.0001 4.9V9.43333C14.0001 9.8067 14.0001 9.99339 13.9274 10.136C13.8635 10.2614 13.7615 10.3634 13.6361 10.4273C13.4935 10.5 13.3068 10.5 12.9334 10.5H9.73341C9.36005 10.5 9.17336 10.5 9.03075 10.4273C8.90531 10.3634 8.80333 10.2614 8.73941 10.136C8.66675 9.99339 8.66675 9.8067 8.66675 9.43333V3.83333Z"
                                              stroke="#039855"
                                              stroke-width="1.33333"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      )}
                                      {doc?.workerDocAppr?.approval_status ===
                                        "client_approval_req" && (
                                        <Badge
                                          onClick={() => openDialog(doc)}
                                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                          content={"Action required"}
                                          innerClass="bg-blue-100 text-blue-700"
                                        />
                                      )}
                                      {doc?.workerDocAppr?.approval_status ===
                                        "client_reject" && (
                                        <Badge
                                          onClick={() => openDialog(doc)}
                                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                          content={"Reject"}
                                          innerClass="bg-error-100 text-error-700"
                                        />
                                      )}
                                      {doc?.workerDocAppr?.approval_status ===
                                        "admin_reject" && (
                                        <Badge
                                          onClick={() => openDialog(doc)}
                                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                          content={"Reject"}
                                          innerClass="bg-error-100 text-error-700"
                                        />
                                      )}
                                      {doc?.workerDocAppr?.approval_status ===
                                        "pending" && (
                                        <Badge
                                          onClick={() => openDialog(doc)}
                                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                          content={"Awaiting review"}
                                          innerClass="bg-blue-100 text-blue-700"
                                        />
                                      )}
                                      {doc?.workerDocAppr?.approval_status ===
                                        (null || undefined) && (
                                        <Badge
                                          onClick={() => openDialog(doc)}
                                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                          content={"Pending Upload"}
                                          innerClass="bg-success-100 text-success-700"
                                        />
                                      )}
                                    </Td>
                                  </Tr>
                                )
                              )}
                          </React.Fragment>
                        ))}
                    </TBody>
                  </Table>
                </Scrollbar>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-60 ">
              <div className="h-full  flex items-center justify-center">
                <div className="">
                  <h4 className="font-semibold text-sm text-center mb-2 ">
                    Checklist unassigned
                  </h4>
                  <p className="text-xs w-96 text-center text-gray-600">
                    No documents are visible because a compliance checklist
                    hasn't been assigned yet. Please assign a checklist by
                    selecting one from the dropdown menu.
                  </p>
                  <div className="w-3/4 my-5">
                    <Select
                      placeholder="Select documents required"
                      name="comp_doc_id_data"
                      className="w-96 h-auto select-options"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      isMulti
                      options={
                        props?.allChecklistData ? props?.allChecklistData : []
                      }
                    />
                    {formError?.checklistAssignErr && (
                      <div className="text-red-500 text-sm mt-1">
                        {formError?.checklistAssignErr}
                      </div>
                    )}
                  </div>
                  <BtnPrimary
                    onClick={handleChecklistAssign}
                    className="px-3 w-40 text-xs h-9 mx-auto mt-4"
                  >
                    Assign checklist(s)
                  </BtnPrimary>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <Documents
          selectedItem={selectedItem}
          trading_name={props?.trading_name}
          OnGetSubmissionDocsOrChecklist={props?.OnGetSubmissionDocsOrChecklist}
        />
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Checklist assign successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default DocumentListShow;
