import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { GetAllProvidersOfClient } from "../../../../../../actions/Admin-Client/GetAllProvidersOfClient";
import { useDispatch } from "react-redux";
import Table from "../../../../../../common/element/Table";
import THead from "../../../../../../common/element/Table/THead";
import Tr from "../../../../../../common/element/Table/Tr";
import Th from "../../../../../../common/element/Table/Th";
import TBody from "../../../../../../common/element/Table/TBody";
import { encryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import Td from "../../../../../../common/element/Table/Td";
import Tooltip from "../../../../../../common/element/Tooltip";
import { Pagination } from "../../../../../../common/pagination";
import { useNavigate, useParams } from "react-router-dom";
import Tag from "../../../../../../common/element/Tag/Tag";
import { updateRoutefunc } from "../../../../../../redux/classes/common/updateRoute";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";

const ContractorsList = (props) => {
  const [loadData, setLoadData] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [allProviderData, setallProviderData] = useState([]);
  const [countData, setCountData] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  let dispatch = useDispatch();
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    client_fa_id: props?.client_fa_id,
    // contact_type_id: props?.contact_type_id,
    client_org_id: props?.clientData?.organisation_id,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: props?.searchViewAll ? props?.searchViewAll : "",
  });
  const debounceTimeout = useRef(null);
  const previousSearchViewAll = useRef(props.searchViewAll);
  const previousInvitedData = useRef(getAllinvitedData);

  useEffect(() => {
    // Check if values have actually changed
    if (
      previousSearchViewAll.current === props.searchViewAll &&
      previousInvitedData.current === getAllinvitedData
    ) {
      return;
    }

    previousSearchViewAll.current = props.searchViewAll;
    previousInvitedData.current = getAllinvitedData;

    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set new timeout for debouncing
    debounceTimeout.current = setTimeout(() => {
      // let updateFilter = { ...getAllinvitedData };
      // updateFilter["search"] = props.searchViewAll;
      // updateFilter["page"] = props?.searchViewAll ? 1 : getAllinvitedData.page;
      let payload = getAllinvitedData;
      if (props.type == "Compliant" || props.type == "nonCompliant") {
        delete payload.limit;
      } else {
        payload = getAllinvitedData;
      }
      dispatch(GetAllProvidersOfClient(getAllinvitedData)).then((response) => {
        if (response.success) {
          setLoadData(true);
          if (props.type == "Compliant") {
            setallProviderData(
              response?.data?.rows.filter(
                (item) => item.docs_status == "Compliant"
              )
            );
          } else if (props.type == "nonCompliant") {
            setallProviderData(
              response?.data?.rows.filter(
                (item) => item.docs_status == "Non-compliant"
              )
            );
          } else {
            setallProviderData(response?.data?.rows);
          }
          setCountData(response?.data?.count);
          setTotalPages(
            Math.ceil(response?.data?.count / getAllinvitedData.limit)
          );
        } else {
          setLoadData(true);
        }
      });
    }, 100); // Adjust the delay as per your requirement
  }, [getAllinvitedData, props?.searchViewAll, dispatch]);

  useEffect(() => {
    SetgetAllinvitedData((prevData) => ({
      ...prevData,
      page: 1,
      search: props?.searchViewAll ? props?.searchViewAll : "",
    }));
  }, [props?.searchViewAll]);

  const getPageNumber = () => {
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(countData / getAllinvitedData?.limit));
  };

  const handleSort = (column) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
      };
    });
  };

  const handleSpecificData = (item) => {
    navigate(
      `/admin/provider/view-all-provider/specific-provider/${encodeURIComponent(
        encryptId(item?.provider_org_id)
      )}`
    );
    dispatch(updateRoutefunc(true));
  };
  return (
    <React.Fragment>
      <div className="w-full inline-block align-middle">
        <div className="overflow-hidden border rounded-lg border-gray-200">
          <Table className="min-w-full divide-y divide-gray-200">
            <THead>
              <Tr>
                {/* <Th className="w-49">
                  <span
                    className="flex items-center gap-1"
                  >
                    ID
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th> */}
                <Th className="w-49">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("trading_name")}
                  >
                    Provider Name
                    <svg
                      className={`transform ${
                        getAllinvitedData.sort === "trading_name" &&
                        getAllinvitedData.order === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th className="w-49">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("provider_type_name")}
                  >
                    Provider type
                    <svg
                      className={`transform ${
                        getAllinvitedData.sort === "provider_type_name" &&
                        getAllinvitedData.order === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th>

                <Th className="w-49">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("meta_value_one")}
                  >
                    Services
                    <svg
                      className={`transform ${
                        getAllinvitedData.sort === "meta_value_one" &&
                        getAllinvitedData.order === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th>

                <Th className="w-48">
                  {/* <span className="flex items-center gap-1">ABN/NZBN</span> */}
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("abn_nzbn")}
                  >
                    ABN/NZBN
                    <svg
                      className={`transform ${
                        getAllinvitedData.sort === "abn_nzbn" &&
                        getAllinvitedData.order === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th className="w-48">
                  <span className="flex items-center gap-1">State</span>
                </Th>
                <Th
                  scope="col"
                  className="flex gap-2 bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
                >
                  Status
                  <Tooltip
                    title={
                      <>
                        Invited:
                        <br />
                        Compliant:
                        <br />
                        Non-compliant:
                        <br />
                        Non-compliant ($) :{" "}
                      </>
                    }
                  >
                    <button
                      variant="plain"
                      shape="circle"
                      size="sm"
                      className="text-blue-700 font-semibold hover:text-gray-600"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_13733_32643)">
                          <path
                            d="M6.06001 5.99992C6.21675 5.55436 6.52611 5.17866 6.93331 4.93934C7.34052 4.70002 7.81927 4.61254 8.28479 4.69239C8.75032 4.77224 9.17255 5.01427 9.47673 5.3756C9.7809 5.73694 9.94738 6.19427 9.94668 6.66659C9.94668 7.99992 7.94668 8.66659 7.94668 8.66659M8.00001 11.3333H8.00668M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00001 14.6666C4.31811 14.6666 1.33334 11.6818 1.33334 7.99992C1.33334 4.31802 4.31811 1.33325 8.00001 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z"
                            stroke="#667085"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_13733_32643">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </Tooltip>
                </Th>

                <Th className="w-48">
                  {/* <span className="flex items-center gap-1">ABN/NZBN</span> */}
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("renewal_date")}
                  >
                    Renewal Date
                    <svg
                      className={`transform ${
                        getAllinvitedData.sort === "renewal_date" &&
                        getAllinvitedData.order === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
              </Tr>
            </THead>
            <TBody>
              {allProviderData &&
                allProviderData.map((item) => (
                  <Tr
                    key={item.index}
                    onClick={() => handleSpecificData(item)}
                    className={"cursor-pointer"}
                  >
                    {/* <Td>0</Td> */}
                    <Td>{item?.trading_name ? item?.trading_name : "-"}</Td>
                    <Td>
                      {item?.provider_type_name
                        ? item?.provider_type_name
                        : "-"}
                    </Td>
                    <Td>{item?.meta_value_one ? item?.meta_value_one : "-"}</Td>
                    <Td>{item?.abn_nzbn ? item?.abn_nzbn : "-"}</Td>
                    <Td>{item?.state_name ? item?.state_name : "-"}</Td>
                    <Td>
                      {/* <span
                        className={
                          "badge bg-gray-100 text-gray-700 py-0.5 px-2 rounded-lg font-medium"
                        }
                      >
                        {item?.inviteStatus ? item?.inviteStatus : "-"}
                      </span> */}
                      {item?.docs_status == "Checklist pending" && (
                        <Tag className="bg-error-50 text-error-700">
                          Checklist pending
                        </Tag>
                      )}
                      {item?.docs_status == "Non-compliant" && (
                        <Tag className="bg-error-50 text-error-700">
                          Non-compliant
                        </Tag>
                      )}
                      {item?.docs_status == "Compliant" && (
                        <Tag className="bg-success-50 text-success-700">
                          Compliant
                        </Tag>
                      )}
                    </Td>
                    <Td>{item?.renewal_date ? item?.renewal_date : "-"}</Td>
                  </Tr>
                ))}
            </TBody>
          </Table>

          {loadData &&
            props.type != undefined &&
            allProviderData.length == 0 && (
              <div className="flex justify-between mt-3">
                {" "}
                <div className="h-full w-full text-center mt-2">
                  No Data Available!
                </div>
              </div>
            )}
        </div>
      </div>
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {props.type == undefined && (
        <React.Fragment>
          {loadData && (
            <div
              className="flex justify-between mt-3"
              style={{ alignItems: "Right", listStyle: "none", padding: 0 }}
            >
              <Pagination
                totalCount={countData}
                pageNumber={getAllinvitedData.page}
                displayPage={allProviderData?.length}
                UpdatePageLimit={SetgetAllinvitedData}
                // UpdatePage={SetgetAllinvitedData}
                allfilters={getAllinvitedData}
              />
              {allProviderData?.length > 0 && (
                <ReactPaginate
                  className="flex justify-content-end p-2 align-items-center pagination-wrap"
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLinkClassName={"prev-link"}
                  nextLinkClassName={"page-link"}
                  breakLinkClassName={"prev-link"}
                  previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                  nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                  pageLinkClassName={"px-4 rounded-md"}
                  activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                  disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
                  renderOnZeroPageCount={null}
                  forcePage={getAllinvitedData?.page - 1}
                />
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default ContractorsList;
