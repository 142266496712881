import axios from "axios";
import * as actionType from "../../constant/actionType";
/*** GetAllProvidersPrimaryList Action ***/
export const GetAllContractorPrimaryListStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllContractorPrimaryList_START,
  };
};
export const GetAllContractorPrimaryListSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllContractorPrimaryList_SUCCESS,
  };
};
export const GetAllContractorPrimaryListFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllContractorPrimaryList_FAIL,
  };
};

export const GetAllProvidersPrimaryList = (
  functionId,
  contact_type_id,
  function_assignment_id,
  search
) => {
  return (dispatch) => {
    dispatch(GetAllContractorPrimaryListStart());
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/client/GetAllProvidersPrimaryList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
      },
      params: {
        function_id: functionId,
        contact_type_id: contact_type_id,
        function_assignment_id: function_assignment_id,
        search: search,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllContractorPrimaryListSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllContractorPrimaryListFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** InviteSpecificProvider Action ***/
export const InviteSpecificContractorStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.InviteSpecificContractor_START,
  };
};
export const InviteSpecificContractorSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.InviteSpecificContractor_SUCCESS,
  };
};
export const InviteSpecificContractorFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.InviteSpecificContractor_FAIL,
  };
};

export const InviteSpecificProvider = (form_data, ipAddress) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    dispatch(InviteSpecificContractorStart());
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "invite_attach") {
        if (form_data[key] != undefined) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`invite_attach`, file);
          });
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/InviteSpecificProvider`,
    })
      .then(function (response) {
        dispatch(InviteSpecificContractorSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          InviteSpecificContractorFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** ReinviteProvider Action ***/
export const ReinviteContractorStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.ReinviteContractor_START,
  };
};
export const ReinviteContractorSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.ReinviteContractor_SUCCESS,
  };
};
export const ReinviteContractorFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.ReinviteContractor_FAIL,
  };
};

export const ReinviteProvider = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(ReinviteContractorStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/ReinviteProvider`,
    })
      .then(function (response) {
        dispatch(ReinviteContractorSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          ReinviteContractorFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};
