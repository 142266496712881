import React, { useCallback, useEffect, useState } from "react";
import Heading from "../../../../../common/element/Heading";
import Tabs from "../../../../../common/element/Tabs";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import Select from "../../../../../common/element/Select/Select";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import ContractorList from "./YourContractor/ContractorList";
import InviteContractors from "./YourContractor/InviteContractors";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Table from "../../../../../common/element/Table";
import Dropdown from "../../../../../common/element/Dropdown";
import Button from "../../../../../common/element/Buttons";
import Checkbox from "../../../../../common/element/Checkbox";
import GlobalSearch from "./GlobalSearch";
// import { debounce } from "../../../../../common/snippet/Debouncing";
import * as userActions from "../../../../../actions/index";
import AddContractor from "./YourContractor/index";
import { connect, useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { reSetData } from "../../../../../redux/classes/client-portal/clientCsvSlice";
import { GetAllComplianceOfClientList } from "../../../../../actions/client-portal/AllSiteApi";
import { GetAllInvitedProvidersOfClient } from "../../../../../actions/client-portal/AllContractorApi";
import { GetClientUserPermByIdForClient } from "../../../../../actions/client-portal/AllUsersApi";
import { InviteProvidersCsvView } from "../../../../../actions/client-portal/clientCsvApi";
// import ClientInput from "../../../../../common/element/Input/clientInput";
import { useNavigate } from "react-router-dom";
import { ViewClientCsv } from "../../../../../actions/client-portal/clientCsvView";
// import ReactPaginate from "react-paginate";
// import Segment from "../../../../../common/element/Segment";
import EmailIcon from "../../../../../assets/icons-svg/email.svg";
import Email from "../Email/Email";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import Validations from "../../../../../common/404/Validations";
import moment from "moment";
import contractorImg from "../../../../../assets/icons-svg/cons-provider.svg";
import SearchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetAllProviderTypesList } from "../../../../../actions/Admin-Client/Admin-Client";
import { GetAllComplianceListOfClient } from "../../../../../actions/client-portal/AllSiteApi";

import ScrollBar from "../../../../../common/element/ScrollBar/ScrollBar";
import Label from "../../../../../common/element/Label";
import Upload from "../../../../../common/element/Upload";
import RichTextEditor from "../../../../../common/snippet/RichTextEditor";
import { GetAllProvidersPrimaryList } from "../../../../../actions/client-portal/Client";
import Tooltip from "../../../../../common/element/Tooltip";
const { TabNav, TabList, TabContent } = Tabs;
const { Tr, Th, Td, THead, TBody } = Table;
const ClientContractor = (props) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("LoginUserData"));
  const [totalPages, setTotalPages] = useState(1);
  const [documentError, setdocumentError] = useState("");
  const [previousFirstAbn, setPreviousFirstAbn] = useState("");
  const [selectProviderDetails, setSelectProviderDetails] = useState([]);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [refetchData, setRefetchData] = useState(false);
  const [dialogDeleteIsOpen, SetDialogDeleteIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [workerCheckListError, setWorkerChecklistError] = useState("");
  const [workerSelCompChecklist, setWorkerSelCompChecklist] = useState([]);
  const [workerAllCheckListData, setWorkerAllCheckListData] = useState([]);
  const [errorCount, setErrorCount] = useState(0);
  const [indexRow, SetIndexRow] = useState("");
  const permissionDataUpdate = useSelector(
    (state) => state?.permissions?.permissionData
  );
  const dispatch = useDispatch();
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem(
        "permission_data",
        JSON.stringify(permissionDataUpdate)
      );
      setForceRender((prevForceRender) => !prevForceRender);

      permissionDataUpdate?.provider_view == false &&
        navigate("/client/noaccess");
      // navigate("/client/dashboard")
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }

  const providerPermission =
    permission_data?.provider_write == true ? false : true;
  // All ReduxFunction

  const GetFunction = useSelector((state) => state);
  let function_assignment_id;
  let contractor_permissions;
  let user_id;
  let trading_name;
  let organisation_id;
  let current_user;
  // let trading_name;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
    organisation_id = storedData?.organisation_id;
    trading_name = storedData.trading_name;
    current_user = `${storedData?.userName?.first_name || ""} ${
      storedData?.userName?.last_name || ""
    }`;
    // trading_name = storedData.trading_name;
  }

  const CSVData = [
    ["first_name", "last_name", "email", "provider_abn_nzbn"],
    ["brandon", "Hodges", "brandon.hodges33@conserv.com", "00-000-000-000"],
    ["victor", "Carr", "victor.carr33@conserv.com", "00-000-000-000"],
    ["Lillian", "Hodges", "lillian.pullman@conserv.com", "00-000-000-000"],
    ["Edward", "Hodges", "edward.mills@conserv.com", "00-000-000-000"],
    ["Megan", "Hodges", "megan.mcdonald4555@conserv.com", "00-000-000-000"],
  ];
  // const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [search, setSearch] = useState();
  const [singleSegmentValue, setSingleSegmentValue] = useState(["contractors"]);
  const [functionId, setfunctionId] = useState();
  const [finalCsvData, setfinalCsvData] = useState([]);
  const [updateCsvData, setUpdateCsvData] = useState(false);
  const [countData, setCountData] = useState(0);
  const [attachment, setAttachment] = useState({ files: [] });
  const [loadData, setLoadData] = useState(false);
  const [checkListError, setChecklistError] = useState("");
  const [checkBox, setcheckBox] = useState(true);
  const [dialogNextCsv, setDialogNextCsv] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [searchProviderOptions, setSearchOptions] = useState([]);
  const [contactorDataUse, setContactorDataUse] = useState([]);
  const [abnEditOrNot, setAbnEditOrNot] = useState(false);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    client_fa_id: function_assignment_id,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });
  const [searchOptions, setSelectOptions] = useState([]);
  const [GlobalSearchfilter, SetGlobalSearchfilter] = useState([
    {
      value: "No Data",
      label: "No Data",
    },
  ]);

  const [allCheckListData, setAllCheckListData] = useState([]);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [dialogOpenCSV, setCSV] = useState(false);
  const [updateForm, setupdateForm] = useState(false);
  const [dialogInviteSent, setDialogInvite] = useState(false);
  const [dialogEmailOpen, setEmailOpen] = useState(false);
  const [selCompChecklist, setSelCompChecklist] = useState([]);
  const [contractorDataInvited, setContractorDataInvited] = useState();
  const [contractorDataRegisterd, setContractorDataRegisterd] = useState();
  const [defaultTab, SetdefaultTab] = useState("tab1");
  const [totalPagesContractor, setTotalPagesContractor] = useState(0);
  const [contractorData, SetcontractorData] = useState({
    individual_id: "",
    contractor_f_a_id: "",
    contractor_name: "",
    provider_abn_nzbn: "",
    provider_email: "",
    invite_message: "",
    first_name: "",
    last_name: "",
    // function_assignment_id:function_assignment_id,
    trading_name: "",
    invite_attach: { files: [] },
  });
  const [formErrors, setFormErrors] = useState({
    contractor_name: "",
    provider_abn_nzbn: "",
    provider_email: "",
    first_name: "",
    last_name: "",
    selectDoc: "",
    conserveEmailReq: "",
    onlyCheckEmail: "",
    correctFormat: "",
    comCheckListId: "",
  });

  const [formError, setFormCSVErrors] = useState({
    selectDoc: "",
    conserveEmailReq: "",
    correctFormat: "",
    comCheckListId: "",
  });

  const [CsvFileData, SetCsvFileData] = useState({
    provider_type_id: "",
    invite_attach: "",
    invite_message: "",
  });
  const GetFunctionId = GetFunction.allFunctions.allFunction;
  const selectWorkerCompChecklist = (selectedOption) => {
    setWorkerSelCompChecklist(selectedOption);
    setWorkerChecklistError("");
  };
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 bg-blue-50 border-gray-300 rounded-lg border font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
          stroke="#344054"
          strokeWidth="1.667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Import CSV
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m6.394 9 6 6 6-6"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Provider",
          url: "/client/provider",
          home: "/client/dashboard",
        },
      ])
    );
  }, []);
  // // Get ProviderList
  const fetchProviderList = useCallback(() => {
    dispatch(GetAllProviderTypesList()).then((response) => {
      const filterData = response?.data?.map((item) => ({
        value: item?.provider_type_id,
        label: item?.provider_type_name,
      }));
      setSearchOptions(filterData);
    });
  }, [dispatch, setSearchOptions]);
  useEffect(() => {
    if (dialogChoose || dialogNextCsv) {
      fetchProviderList();
    }
  }, [dialogChoose, dialogNextCsv]);

  function isEmail(value) {
    // Regular expression for checking email format
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
  }

  function isElevenDigitNumber(value) {
    // Remove spaces and check if it's 11 digits
    if (value !== undefined && value !== "") {
      const strippedValue = value.replace(/\s/g, "");
      const numberRegex = /^\d{11}$/;
      return numberRegex.test(strippedValue);
    }
  }
  // Function to handle selection of document type
  const selectDocType = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      selectDoc: "",
    }));
  };
  // to open email dialog
  const openEmailDialog = () => {
    setEmailOpen(true);
  };

  // to close email dialog
  const onEmailClose = (e) => {
    if (!buttonDisabled) {
      setEmailOpen(false);
    }
  };

  // api call for permissions
  // useEffect(() => {
  //   const apiData = {
  //     user_id: user_id,
  //     function_assignment_id: function_assignment_id,
  //   };
  //   dispatch(GetClientUserPermByIdForClient(apiData)).then((response) => {
  //     if (response.success === true) {
  //       // const arrayData = response.data;
  //       localStorage.setItem("permission_data", JSON.stringify(response.data));
  //     } else if (response.status === 401) {
  //       handleUnauthorizedAccess();
  //     }
  //   });
  // }, []);

  // Function to clear form errors
  const clearFormErrors = () => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contractor_name: "",
      provider_abn_nzbn: "",
      provider_email: "",
      onlyCheckEmail: "",
      correctFormat: "",
    }));
  };

  // Set a form error
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  // Add Client Provider
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  let prevSearchValue = "";
  const handleSearchChange = debounce((newInputValue) => {
    // Clear the emailError if the search input has changed
    if (
      prevSearchValue !== newInputValue &&
      formErrors.provider_email !== "" &&
      selectedOption != ""
    ) {
      setFormErrors((prevError) => ({
        ...prevError,
        provider_email: "",
      }));
      setSelectedOption("");
    }
    setFormErrors((prevError) => ({
      ...prevError,
      onlyCheckEmail: "",
      correctFormat: "",
    }));
    // Update the previous search value
    prevSearchValue = newInputValue;
    setSearch(newInputValue);
  }, 500);

  // Handle the select option change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    if (isEmail(selectedOption?.label)) {
      SetcontractorData((prevalue) => ({
        ...prevalue,
        provider_email: selectedOption?.label,
      }));

      SetcontractorData((prevState) => ({
        ...prevState,
        provider_abn_nzbn: selectProviderDetails
          ? selectProviderDetails?.abn_nzbn
          : "",
      }));

      if (
        selectProviderDetails?.first_name ||
        selectProviderDetails?.last_name
      ) {
        SetcontractorData((prevError) => ({
          ...prevError,
          provider_abn_nzbn: selectProviderDetails
            ? selectProviderDetails?.abn_nzbn
            : "",
          first_name: selectProviderDetails?.first_name
            ? ""
            : prevError.first_name,
          last_name: selectProviderDetails?.last_name
            ? ""
            : prevError.last_name,
        }));
      }
    } else if (isElevenDigitNumber(selectedOption?.label)) {
      SetcontractorData((prevalue) => ({
        ...prevalue,
        provider_abn_nzbn: selectedOption?.label,
        provider_email: "",
        first_name: "",
        last_name: "",
        individual_id: "",
        contractor_f_a_id: "",
      }));
    } else {
      // show message
      //Please Enter the Value in correct format
    }
    setFormErrors((prevError) => ({
      ...prevError,
      conserveEmailReq: "",
      provider_abn_nzbn: "",
      correctFormat: "",
    }));

    GetIndividualByEmail(selectedOption?.label);
  };

  const GetIndividualByEmail = async (email) => {
    if (email) {
      try {
        const response = await props?.onGetIndividualByEmail(email);

        if (response.success === true) {
          const arrayData = response?.data;

          if (arrayData && arrayData?.is_conserve_team === false) {
            SetcontractorData((prevalue) => ({
              ...prevalue,
              provider_email: arrayData?.email,
              first_name: arrayData?.first_name,
              last_name: arrayData?.last_name,
              individual_id: arrayData?.individual_id,
              contractor_f_a_id: arrayData?.function_assignment_id,
            }));
            if (arrayData?.first_name || arrayData?.last_name) {
              setFormErrors((prevError) => ({
                ...prevError,
                first_name: arrayData?.first_name ? "" : prevError.first_name,
                last_name: arrayData?.last_name ? "" : prevError.last_name,
              }));
            }

            if (contractorData.provider_abn_nzbn === "" && arrayData?.abn) {
              SetcontractorData((prevState) => ({
                ...prevState,
                provider_abn_nzbn: arrayData?.abn ? arrayData?.abn : "",
              }));
            }
          } else if (arrayData && arrayData?.is_conserve_team === true) {
            // setEmailError("This email cannot be selected");
            setFormErrors((prevError) => ({
              ...prevError,
              conserveEmailReq: "This email cannot be selected",
              // Clear the error message initially
            }));
            SetcontractorData((prevState) => ({
              ...prevState,
              first_name: "",
              last_name: "",
            }));
          }
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      } catch (error) {
        console.error(
          "Error occurred while fetching individual by email:",
          error
        );
      }
    }
  };

  // Custom option for the input value
  const customOption = { value: search, label: search };

  // Check if the inputValue exists in options, if not, add it
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];

  function GetAllfunctionId() {
    if (GetFunctionId) {
      for (let key in GetFunctionId) {
        if (GetFunctionId[key].function_name === "provider") {
          let id = GetFunctionId[key].function_id;
          setfunctionId(id);
        }
      }
    }
  }

  useEffect(() => {
    GetAllfunctionId();
  }, [GetAllfunctionId]);

  const GetAllProvidersPrimaryListfunc = async (contact_type_id) => {
    if (functionId && contact_type_id && search) {
      dispatch(
        GetAllProvidersPrimaryList(
          functionId,
          contact_type_id,
          function_assignment_id,
          search
        )
      ).then((response) => {
        if (response.success === true) {
          const arrayData = response.data;
          let contactorData = [];
          for (let key in arrayData) {
            contactorData.push({
              ContractorOrgName: arrayData[key].trading_name,
              ContractorFAId: arrayData[key].function_assignment_id,
              abn: arrayData[key].abn,
              functionId: arrayData[key].function_id,
              individualId: arrayData[key].individual_id,
              email: arrayData[key].email,
              relationId: arrayData[key].f_a_relation_id,
              ContractorFirstName: arrayData[key].first_name,
              ContractorLastName: arrayData[key].last_name,
            });
          }
          setSelectProviderDetails(arrayData?.[0]);

          // if (contractorData.provider_abn_nzbn === "") {
          //   SetcontractorData((prevState) => ({
          //     ...prevState,
          //     provider_abn_nzbn:
          //       contactorData?.[0]?.abn != undefined
          //         ? contactorData?.[0]?.abn
          //         : "",
          //   }));
          // }
          // if (
          //   contactorData?.[0]?.ContractorFirstName ||
          //   contactorData?.[0]?.ContractorLastName
          // ) {
          //   SetcontractorData((prevError) => ({
          //     ...prevError,
          //     first_name: contactorData?.[0]?.ContractorFirstName
          //       ? ""
          //       : prevError.first_name,
          //     last_name: contactorData?.[0]?.ContractorLastName
          //       ? ""
          //       : prevError.last_name,
          //   }));
          // }

          // if provider exist globally then if exist under the same client or not
          const listData = [];
          if (contactorData) {
            contactorData.map((item) => {
              if (!item?.relationId) {
                listData.push(item);
              }
            });
          }

          setSelectOptions(listData);
          // setContactorDataUse(contactorData);

          // if relationId exist then user cannot select that email/abn as that provider exist under the same client
          const checkByEmail = contactorData.find(
            (item) => item?.email === search
            // (item) => item?.email === contactorData.provider_email
          );
          if (checkByEmail?.relationId) {
            setFormErrors((prevErrors) => ({
              ...prevErrors,
              provider_email:
                "This email cannot be selected because it alredy exist as provider for this client",
            }));
            setTimeout(() => {
              SetcontractorData((prevState) => ({
                ...prevState,
                provider_email: "",
              }));
            }, 3000);
            SetcontractorData((prevState) => ({
              ...prevState,
              first_name: "",
              last_name: "",
              individual_id: "",
            }));
          }
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
    }
  };

  let contactTypeId = GetFunction.AllContactTypes.filter(
    (item) => item?.contact_type === "provider primary"
  );

  useEffect(() => {
    if (GetFunction.AllContactTypes) {
      if (search?.length > 3) {
        GetAllProvidersPrimaryListfunc(contactTypeId[0].contact_type_id);
      }
    }
  }, [search]);

  const openChoose = () => {
    setSelectedDocumentType("");
    setupdateForm(false);
    setChoose(true);
    setSelectedOption("");
    SetcontractorData(() => ({
      individual_id: "",
      provider_fa_id: "",
      contractor_name: "",
      provider_abn_nzbn: "",
      provider_email: "",
      invite_message: "",
      trading_name: "",
    }));
  };
  const onDialogAxistingClose = () => {
    setSelectProviderDetails([]);
    setAbnEditOrNot(false);
    setChoose(false);
    setFormErrors((prevError) => ({
      ...prevError,
      contractor_name: "",
      provider_abn_nzbn: "",
      provider_email: "",
      selectDoc: "",
      conserveEmailReq: "",
      onlyCheckEmail: "",
      first_name: "",
      last_name: "",
      correctFormat: "",
    }));
    setSelectedDocumentType("");
    setSelectedOption("");
    SetcontractorData({
      individual_id: "",
      contractor_f_a_id: "",
      contractor_name: "",
      provider_abn_nzbn: "",
      provider_email: "",
      invite_message: "",
      first_name: "",
      last_name: "",
      trading_name: "",
      invite_attach: { files: [] },
    });
  };
  const openDialog = () => {
    let emailValidation;
    let abnValidation;
    let correctFormatVal;
    const inputValue = selectedOption?.value;

    const providerTypeName = Validations({
      value: selectedDocumentType,
      Validations: { required: true },
    });
    if (!providerTypeName?.valid) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        selectDoc: providerTypeName.error_msg,
      }));
    }

    if (formErrors?.provider_email == "") {
      if (isEmail(selectedOption?.label)) {
        emailValidation = Validations({
          value: inputValue,
          Validations: { email: true, required: true },
        });
        if (!emailValidation?.valid)
          setFormError("onlyCheckEmail", emailValidation.error_msg);
        // set ABN error empty
      } else if (isElevenDigitNumber(selectedOption?.label)) {
        abnValidation = Validations({
          value: inputValue,
          Validations: { required: true, ValidateABN: true },
        });
        if (!abnValidation?.valid)
          setFormError("provider_abn_nzbn", abnValidation.error_msg);
        // set email error Empty
      } else {
        // formError.correctFormat
        correctFormatVal = Validations({
          value: inputValue,
          Validations: { email: true, required: true },
        });
        if (!correctFormatVal?.valid) {
          setFormErrors((prevError) => ({
            ...prevError,
            correctFormat:
              inputValue == "" || inputValue == undefined
                ? "This field is required!"
                : "Please enter the value in the correct format",
          }));
        }
      }

      // check either abn or email is valid
      if (
        formErrors?.conserveEmailReq == "" &&
        formErrors?.correctFormat === "" &&
        (emailValidation?.valid === true || abnValidation?.valid === true) &&
        providerTypeName?.valid
      ) {
        setupdateForm(true);
        setChoose(false);

        if (contractorData?.provider_abn_nzbn !== "") {
          setAbnEditOrNot(true);
        }
      }
      if (emailValidation?.valid && providerTypeName?.valid) {
        setFormErrors({
          ...formErrors,
          provider_abn_nzbn: "",
        });
      } else if (abnValidation?.valid) {
        setPreviousFirstAbn(inputValue);
        SetcontractorData((prevalue) => ({
          ...prevalue,
          provider_abn_nzbn: inputValue,
        }));
        setFormErrors({
          ...formErrors,
          provider_email: "",
        });

        setSelectedOption("");
      }
    }
  };

  const onAddDialogClose = (e) => {
    setAddOpen(false);
    setRefetchData(!refetchData);
    // props?.getOnDialogClose(onAddDialogClose);
  };

  const openCSV = () => {
    setCSV(true);
  };

  const dialogCloseCSV = (e) => {
    setCSV(false);
    dispatch(reSetData());
    setCsvData([]);
  };

  const handleFileChange = (e) => {
    dispatch(reSetData());
    setCsvData([]);
    const file = e.target.files[0];
    let payload = {
      client_fa_id: function_assignment_id,
      provider_invite_csv: file,
      contact_type_id: contactTypeId[0].contact_type_id,
      function_id: functionId,
    };
    dispatch(InviteProvidersCsvView(payload));
    openCSV();
    setUpdateCsvData(false);
  };

  // Function to handle checkbox change
  const handlecheckbox = (event, index) => {
    const isChecked = event;
    const item = csvData[index];
    if (isChecked) {
      // Add the checked item to the array
      setfinalCsvData((prevCheckedItems) => [...prevCheckedItems, item]);
    } else {
      // Remove the unchecked item from the array
      setfinalCsvData((prevCheckedItems) =>
        prevCheckedItems.filter((checkedItem) => checkedItem !== item)
      );
    }
  };

  // Submit CSV Data
  const submitCsvData = () => {
    const DocSelectValidation = Validations({
      value: CsvFileData.provider_type_id,
      Validations: { required: true },
    });

    const comCheckListId = Validations({
      value: selCompChecklist?.length == 0 ? "" : true,
      Validations: { required: true },
    });

    // setFormCSVErrors("selectDoc", DocSelectValidation.error_msg);
    setFormCSVErrors((prevalue) => {
      return {
        ...prevalue,
        ["selectDoc"]: DocSelectValidation.error_msg,
      };
    });

    setFormCSVErrors((prevalue) => {
      return {
        ...prevalue,
        ["comCheckListId"]: comCheckListId.error_msg,
      };
    });

    const WorkerCheckListValidation = Validations({
      value: workerSelCompChecklist?.length == 0 ? "" : workerSelCompChecklist,
      Validations: { required: true },
    });
    if (!WorkerCheckListValidation?.valid) {
      setWorkerChecklistError(WorkerCheckListValidation?.error_msg);
    }
    const workerChecllistIds = workerSelCompChecklist?.map(
      (item) => item?.value
    );
    // setFormCSVErrors("comCheckListId", comCheckListId.error_msg);
    if (
      DocSelectValidation.valid &&
      documentError == "" &&
      comCheckListId.valid &&
      WorkerCheckListValidation.valid
    ) {
      let CheckList = JSON.stringify(
        selCompChecklist?.map((item) => item?.value)
      );
      const updatedCsvFileData = {
        ...CsvFileData,
        invite_attach: attachment,
      };

      dispatch(
        ViewClientCsv({
          provider_invite_data: finalCsvData,
          ...updatedCsvFileData,
          workerChecklistIds: JSON.stringify(
            workerChecllistIds.length ? workerChecllistIds : []
          ),
          checklistIds: CheckList,
          client_org_id: organisation_id,
          current_user: current_user,
          trading_name: trading_name,
        })
      )
        .then((response) => {
          if (response?.status) {
            setDialogInvite(true);
            setWorkerChecklistError("");
            setWorkerSelCompChecklist([]);
            setAttachment({ files: [] });
          } else {
            console.error("Unexpected response structure:", response);
            // Optionally, handle unexpected successful response here.
          }
        })
        .catch((error) => {
          console.error(
            "An error occurred while dispatching ViewClientCsv:",
            error
          );
        });

      setUpdateCsvData(true);
    }
  };

  useEffect(() => {
    if (GetFunction.CSVData.data?.data == "Invite sent successfully!") {
      if (updateCsvData) {
        dialogCloseCSV();
        setAddOpen(true);
        dispatch(reSetData());
        setCsvData([]);
      }
    } else {
      if (
        !GetFunction.CSVData.loading &&
        GetFunction.CSVData.data?.data !== undefined
      ) {
        setCsvData(GetFunction.CSVData.data?.data);
        let filterdata = GetFunction?.CSVData?.data?.data?.filter(
          (items) => items?.is_validated == true
        );
        const count = GetFunction.inviteProviderCsvData.data?.data.reduce(
          (total, item) => {
            return (
              total +
              (item.validation_errors ? item.validation_errors.length : 0)
            );
          },
          0
        );
        setErrorCount(count);
        setfinalCsvData(filterdata);
      }
    }
  }, [GetFunction]);

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };

  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    // setTotalPages(
    //   Math.ceil(
    //     GetFunction.GetAllInvitedContractorsOfClientData.data?.data?.count /
    //       getAllinvitedData.limit
    //   )
    // );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        page: 1,
        [e.target.name]: e?.target?.value.trim(),
      };
    });
  };

  const inputchangeSearch = useCallback(debounce(handleSearch), []);
  // page, limit, sort, order, search, function_assignment_id  (client function assignment id)
  // useEffect(() => {
  //   dispatch(GetAllInvitedProvidersOfClient(getAllinvitedData));
  // }, [getAllinvitedData]);
  useEffect(() => {
    setLoadData(false);
    dispatch(GetAllInvitedProvidersOfClient(getAllinvitedData)).then(
      (response) => {
        if (response.success) {
          setLoadData(true);
          setCountData(response.data?.count);
          const arrayData = response.data?.rows;
          let contactorData = [];

          for (let key in arrayData) {
            let invitedDate = moment(arrayData[key]?.invite_date).format(
              "DD/MM/YYYY"
            );
            contactorData.push({
              provider_org_name: arrayData[key]?.provider_org_name,
              provider_abn_nzbn: arrayData[key]?.provider_abn_nzbn,
              inviteDate: invitedDate,
              invite_status: arrayData[key]?.invite_status,
              inviteContractorId: arrayData[key]?.invite_provider_id,
              first_name: arrayData[key]?.first_name,
              last_name: arrayData[key]?.last_name,
              provider_type_name: arrayData[key]?.provider_type_name,
              serviceName: arrayData[key]?.serviceName,
              state_name: arrayData[key]?.state_name,
            });
          }
          setContractorDataInvited(contactorData);
          setTotalPages(
            Math.ceil(response.data?.count / getAllinvitedData.limit)
          );
        } else if (response.status === 401) {
          setLoadData(true);
          handleUnauthorizedAccess();
        } else {
          setLoadData(true);
        }
      }
    );
  }, [getAllinvitedData, refetchData]);

  // const getPageNumber = () => {
  //   setTotalPages(
  //     Math.ceil(
  //       GetFunction.GetAllInvitedContractorsOfClientData?.data?.data?.count /
  //         getAllinvitedData.limit
  //     )
  //   );
  // };
  //  if(GetFunction.GetAllInvitedContractorsOfClientData?.data==null){

  //  }
  // useEffect(() => {
  //   getPageNumber();
  // }, [getPageNumber]);

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
    },
    [setSingleSegmentValue]
  );

  // Submit CSV Data
  const submitCsvDataNext = () => {
    // open new pannel
    dialogCloseCSV();
    setDialogNextCsv(true);
  };

  const onCloseNextCsv = () => {
    setSelCompChecklist([]);
    setDialogNextCsv(false);
    setWorkerChecklistError("");
    setWorkerSelCompChecklist([]);
    setAttachment({ files: [] });
    setfinalCsvData([]);
    SetCsvFileData({
      provider_type_id: "",
      invite_attach: [],
      invite_message: "",
    });

    setFormCSVErrors({
      selectDoc: "",
      conserveEmailReq: "",
      correctFormat: "",
      comCheckListId: "",
    });
  };

  const HandleAtachData = (event, name) => {
    SetCsvFileData((prevalue) => {
      return {
        ...prevalue,
        [name]: event,
      };
    });
  };

  const OnCloseinviteProvider = () => {
    setRefetchData(!refetchData);
    onCloseNextCsv();
    setDialogInvite(false);
    setfinalCsvData([]);
    SetCsvFileData({
      provider_type_id: "",
      invite_attach: [],
      invite_message: "",
    });
  };

  const selectCompChecklist = (selectedOption) => {
    setSelCompChecklist(selectedOption);
    setChecklistError("");
  };

  const OnGetAllComplianceOfClientList = useCallback(async () => {
    if (organisation_id) {
      try {
        const response = await dispatch(
          GetAllComplianceOfClientList({ client_org_id: organisation_id })
        );
        if (response?.success) {
          const arrayData = response?.data;

          // Transform the data to map over required fields
          const transformedData = arrayData.map((item) => {
            return {
              label: item?.checklist_name,
              value: item?.checklist_id,
              recipient_type: item?.recipient_type,
            };
          });

          // Filter the data by recipient_type
          const providerData = transformedData.filter(
            (item) => item.recipient_type === "provider"
          );
          const workerData = transformedData.filter(
            (item) => item.recipient_type === "worker"
          );

          // Set the filtered data to their respective states
          setAllCheckListData(providerData); // Provider data
          setWorkerAllCheckListData(workerData); // Worker data
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
          console.error("Failed to fetch data:", response);
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  }, [organisation_id, dispatch]);

  useEffect(() => {
    OnGetAllComplianceOfClientList();
  }, [organisation_id, OnGetAllComplianceOfClientList]);

  const handleFileChangeCsv = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true;
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        } else {
          totalSize += fileSize;
          return true;
        }
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });
      // setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      } else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError(
            "Total file size exceeds the maximum limit of 25MB."
          );
          return;
        } else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };

  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      // setFilesUploaded(false);
    }

    if (files.length === 0) {
      // setFilesUploaded(false)
    }
  };

  const RemoveRow = (index) => {
    SetDialogDeleteIsOpen(true);
    SetIndexRow(index);
  };

  const onDeleteDialogClose = () => {
    SetDialogDeleteIsOpen(false);
  };

  const deleteRow = () => {
    // indexRow
    setCsvData((prevData) => prevData.filter((_, index) => index !== indexRow));
    onDeleteDialogClose();
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div>
          <Heading>All Providers</Heading>
          <p className="text-gray-600">
            Manage contractors for seamless collaboration.
          </p>
        </div>
        <BtnLight
          disabled={providerPermission}
          onClick={() => openEmailDialog()}
          className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
        >
          Email <img src={EmailIcon} alt="" className="ml-2" />
        </BtnLight>
      </div>

      <Tabs defaultValue="tab1" variant="pill">
        <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 mt-6 client-portal-tab">
          <TabList>
            <TabNav value="tab1">Your Providers</TabNav>
            <TabNav value="tab2">Global search</TabNav>
          </TabList>
        </div>
        <TabContent value="tab1">
          <Tabs defaultValue="contractors" variant="pill">
            <div className="flex justify-between flex-wrap mb-5 px-2">
              <div className="client-provider-tab ">
                <TabList>
                  <TabNav value="contractors">
                    <button onClick={() => SetdefaultTab("contractors")}>
                      Providers
                    </button>
                  </TabNav>
                  <TabNav value="Invited">
                    <button onClick={() => SetdefaultTab("Invited")}>
                      Invited Providers
                    </button>
                  </TabNav>
                </TabList>
              </div>
              <div className="w-80 h-10">
                <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5 ">
                    <img src={SearchIcon} alt="" className="h-5" />
                  </div>
                  {defaultTab === "contractors" ? (
                    <input
                      type="text"
                      name="search"
                      className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight mr-2 focus:outline-none focus:shadow-outline"
                      placeholder="Search"
                      onChange={(e) => inputchangeSearch(e, "ContractorList")}
                    />
                  ) : (
                    <input
                      type="text"
                      // name="hs-table-search"
                      name="search"
                      className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight mr-2 focus:outline-none focus:shadow-outline"
                      placeholder="Search"
                      onChange={(e) => inputchangeSearch(e, "invitedList")}
                    />
                  )}
                  {/* <div className="absolute inset-y-0 right-3.5 flex items-center pl-4 pointer-events-none">
                  <img src={SettingIcon} alt="" />
                </div> */}
                </div>
              </div>

              <div className="flex gap-2 reset-dropdown">
                <Dropdown
                  renderTitle={Toggle}
                  disabled={providerPermission}
                  isOpen={true}
                >
                  <Dropdown.Item eventKey="a">
                    <CSVLink
                      className="client_portal_csv"
                      data={CSVData.map((row) =>
                        row.filter((column) => column !== "action")
                      )}
                      filename={"Sample file for invite conserve provider.csv"}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 6.66667L8 10M8 10L4.66667 6.66667M8 10V2"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Download template</span>
                    </CSVLink>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="b">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.33317 1.51318V4.26688C9.33317 4.64025 9.33317 4.82693 9.40583 4.96954C9.46975 5.09498 9.57173 5.19697 9.69718 5.26088C9.83978 5.33354 10.0265 5.33354 10.3998 5.33354H13.1535M5.99984 10.0002L7.99984 12.0002M7.99984 12.0002L9.99984 10.0002M7.99984 12.0002L7.99984 8.00016M9.33317 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H10.1332C11.2533 14.6668 11.8133 14.6668 12.2412 14.4488C12.6175 14.2571 12.9234 13.9511 13.1152 13.5748C13.3332 13.147 13.3332 12.5869 13.3332 11.4668V5.3335L9.33317 1.3335Z"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {/* Import file */}
                    <span>Import file</span>
                    <input
                      disabled={providerPermission}
                      className="upload_csv"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                  </Dropdown.Item>
                </Dropdown>
                <BtnPrimary
                  disabled={providerPermission}
                  variant="solid"
                  onClick={() => openChoose()}
                  className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
                  type="button"
                >
                  Add provider
                  <span className="ml-3">
                    <img
                      src="/assets/icons-svg/plus.svg"
                      alt=""
                      className="h-5"
                    />
                  </span>
                </BtnPrimary>
              </div>
            </div>
            <TabContent value="contractors">
              <ContractorList
                handleSort={handleSort}
                contractorData={contractorDataRegisterd}
                totalPages={totalPagesContractor}
                handlePageChange={handlePageChange}
                getAllinvitedData={getAllinvitedData}
                providerPermission={providerPermission}
              />
            </TabContent>

            <TabContent value="Invited">
              <InviteContractors
                countData={countData}
                loadData={loadData}
                handleSort={handleSort}
                contractorDataInvited={contractorDataInvited}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                getAllinvitedData={getAllinvitedData}
                SetgetAllinvitedData={SetgetAllinvitedData}
                providerPermission={providerPermission}
              />
            </TabContent>
          </Tabs>
        </TabContent>
        <TabContent value="tab2">
          <GlobalSearch providerPermission={providerPermission} />
        </TabContent>
      </Tabs>
      <Dialog
        isOpen={dialogChoose}
        onClose={onDialogAxistingClose}
        onRequestClose={onDialogAxistingClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={contractorImg} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one.</p>
          </div>
        </div>
        <div className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4 mb-1 block">
              Provider type*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                options={searchProviderOptions}
                value={selectedDocumentType}
                onChange={(selectedOption) => selectDocType(selectedOption)}
              ></Select>
              {formErrors.selectDoc && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formErrors.selectDoc}
                </p>
              )}
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 mb-1 block">
              Provider name, ABN, or email*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-10 select-options"
                placeholder="Search for a Provider"
                isClearable={true}
                isSearchable={true}
                options={updatedOptions ? updatedOptions : selectedOption}
                value={selectedOption || customOption}
                onChange={handleSelectChange}
                handleSearchChange={handleSearchChange}
              ></Select>
              {formErrors.correctFormat && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {formErrors.correctFormat}
                </p>
              )}
              {formErrors.conserveEmailReq && (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.conserveEmailReq}
                </p>
              )}

              {formErrors.onlyCheckEmail && !formErrors.conserveEmailReq && (
                <p className="text-xs text-red-600 mb-4 mt-0">
                  {formErrors.onlyCheckEmail}
                </p>
              )}

              {formErrors.provider_email &&
                !formErrors.conserveEmailReq &&
                !formErrors.onlyCheckEmail && (
                  <p className="text-xs text-red-600 mb-4 mt-0">
                    {formErrors.provider_email}
                  </p>
                )}

              {formErrors.contractor_name &&
                !formErrors.conserveEmailReq &&
                !formErrors.onlyCheckEmail &&
                !formErrors.provider_email && (
                  <p className="text-xs text-red-600 mb-4 mt-0">
                    Please enter the value in the correct format.
                  </p>
                )}

              {formErrors.provider_abn_nzbn &&
                !formErrors.conserveEmailReq &&
                !formErrors.onlyCheckEmail &&
                !formErrors.provider_email &&
                !formErrors.contractor_name && (
                  <p className="text-xs text-red-600 mb-4 mt-0">
                    Please enter the value in the correct format.
                  </p>
                )}

              {!formErrors.conserveEmailReq &&
                !formErrors.onlyCheckEmail &&
                !formErrors.provider_email &&
                !formErrors.contractor_name &&
                !formErrors.provider_abn_nzbn &&
                ""}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            className="mr-1.5"
            variant="plain"
            onClick={onDialogAxistingClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => openDialog()} className="ml-1.5">
            Add Provider
          </BtnPrimary>
        </div>
      </Dialog>

      {/* Invite Provider import csv */}
      <Dialog
        isOpen={dialogNextCsv}
        onClose={onCloseNextCsv}
        onRequestClose={onCloseNextCsv}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Providers
            </h5>
            <p>
              Ensure Providers are categorised correctly and send an optional
              invitation message.
            </p>
          </div>
        </div>
        <div className="pl-4">
          <div className="h-500">
            <ScrollBar>
              <div className="pr-6 pl-2">
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Provider type*
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      options={searchProviderOptions}
                      // value={selectedDocumentType}
                      onChange={(e) =>
                        HandleAtachData(e.value, "provider_type_id")
                      }
                    ></Select>
                    {!CsvFileData.provider_type_id && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.selectDoc}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Compliance Checklist
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      isMulti={true}
                      options={allCheckListData}
                      value={selCompChecklist}
                      onChange={(selectedOption) => {
                        selectCompChecklist(selectedOption);
                        setFormCSVErrors((prevError) => ({
                          ...prevError,
                          comCheckListId: "",
                        }));
                      }}
                    ></Select>
                    {formError.comCheckListId && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.comCheckListId}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Worker Compliance Checklist(s)
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select Compliance Checklist"
                      isMulti={true}
                      options={workerAllCheckListData}
                      value={workerSelCompChecklist}
                      onChange={(selectedOption) =>
                        selectWorkerCompChecklist(selectedOption)
                      }
                    ></Select>
                    {workerSelCompChecklist?.length === 0 &&
                      workerCheckListError && (
                        <p className="text-xs text-red-600 mb-2 mt-0">
                          {workerCheckListError}
                        </p>
                      )}
                  </div>
                </div>
                <div className="mb-5">
                  <Label className="text-xs font-medium block mb-1">
                    Attachment (optional)
                  </Label>
                  <Upload
                    draggable
                    className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                    // onChange={(e) => HandleAtachData(e, "invite_attach")}
                    onChange={handleFileChangeCsv}
                    onFileRemove={handleFileRemove}
                    multiple={true}
                    maxFileSize={25 * 1024 * 1024}
                  >
                    <span className="text-blue-700 font-semibold">
                      Click to upload
                    </span>{" "}
                    <span className="font-medium text-gray-600">
                      or drag and drop
                    </span>
                    <p className="text-gray-600">
                      (Max 25mb. Only pdf, heic, png, and jpg files will be
                      accepted)
                    </p>
                  </Upload>
                  {documentError && (
                    <p className="text-xs text-red-600 mt-1">{documentError}</p>
                  )}
                </div>
                <div className="mb-5 message-box-height">
                  <Label className="text-xs font-medium block mb-1">
                    Message (optional)
                  </Label>
                  <RichTextEditor
                    value={CsvFileData.invite_message}
                    // onChange={(value) => setMessage(value)}
                    onChange={(e) => HandleAtachData(e, "invite_message")}
                  />
                </div>
              </div>
            </ScrollBar>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onCloseNextCsv}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => submitCsvData()} className="ml-1.5">
            Invite Provider
          </BtnPrimary>
        </div>
      </Dialog>

      {updateForm && (
        <AddContractor
          contactorDataUse={contactorDataUse}
          setSelectedDocumentType={setSelectedDocumentType}
          searchProviderOptions={searchProviderOptions}
          selectedDocumentType={selectedDocumentType}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          contractorData={contractorData}
          setPreviousFirstAbn={setPreviousFirstAbn}
          previousFirstAbn={previousFirstAbn}
          abnEditOrNot={abnEditOrNot}
          setAbnEditOrNot={setAbnEditOrNot}
          SetcontractorData={SetcontractorData}
          clearFormErrors={clearFormErrors}
          setFormError={setFormError}
        />
      )}

      {/* Succesfull Dilog Box */}
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogInviteSent}
        bodyOpenClassName="overflow-hidden"
        onClose={OnCloseinviteProvider}
        onRequestClose={OnCloseinviteProvider}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="48" height="48" rx="24" fill="#D1FADF" />
              <path
                d="M22.4995 26.0002L32.9995 15.5002M22.6271 26.3282L25.2552 33.0862C25.4867 33.6816 25.6025 33.9793 25.7693 34.0662C25.9139 34.1415 26.0862 34.1416 26.2308 34.0664C26.3977 33.9797 26.5139 33.6822 26.7461 33.0871L33.3364 16.1994C33.5461 15.6622 33.6509 15.3936 33.5935 15.222C33.5437 15.0729 33.4268 14.956 33.2777 14.9062C33.1061 14.8488 32.8375 14.9536 32.3003 15.1633L15.4126 21.7536C14.8175 21.9858 14.52 22.102 14.4333 22.2689C14.3581 22.4135 14.3582 22.5858 14.4335 22.7304C14.5204 22.8972 14.8181 23.013 15.4135 23.2445L22.1715 25.8726C22.2923 25.9196 22.3527 25.9431 22.4036 25.9794C22.4487 26.0115 22.4881 26.051 22.5203 26.0961C22.5566 26.147 22.5801 26.2074 22.6271 26.3282Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invites sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 ">
          <BtnPrimary onClick={OnCloseinviteProvider}>Close</BtnPrimary>
        </div>
      </Dialog>
      {/* CSV Dilog Box */}
      <Dialog
        isOpen={dialogOpenCSV}
        onClose={dialogCloseCSV}
        onRequestClose={dialogCloseCSV}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
                stroke="#344054"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              CSV Upload
            </h5>
            <p>Providers</p>
          </div>
        </div>
        <div className="h-96 pr-2">
          {errorCount >= 1 && (
            <div className="text-red-500 text-sm mt-1 ml-5 mb-3">
              {errorCount} fields contain errors. Hover over the red icons for
              more details and correct the highlighted fields before proceeding.
            </div>
          )}
          <Scrollbar>
            <div className="px-4">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                {/* <Table>
                  <THead>
                    <Tr>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Provider Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Provider ABN/NZBN
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Provider Email
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Invite Message
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">Action</span>
                      </Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {csvData?.map((item, index) => {
                      if (item?.provider_org_name == null) return;
                      return (
                        <React.Fragment>
                          <Tr key={index}>
                            <Td>
                              {item?.provider_org_name
                                ? item?.provider_org_name
                                : "-"}
                            </Td>
                            <Td>
                              {item?.provider_abn_nzbn
                                ? item?.provider_abn_nzbn
                                : "-"}
                            </Td>
                            <Td>
                              {item?.provider_email
                                ? item?.provider_email
                                : "-"}
                            </Td>
                            <Td>
                              {item?.invite_message
                                ? item?.invite_message
                                : "-"}
                            </Td>
                            <Td>
                              {item?.is_validated ? (
                                <Checkbox
                                  checked={true}
                                  onChange={(event) => {
                                    handlecheckbox(event, index);
                                  }}
                                />
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m18 6.5-12 12m0-12 12 12"
                                    stroke="red"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table> */}
                <Table>
                  <THead>
                    <Tr>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          First Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Last Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Email address
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          ABN/NZBN
                        </span>
                      </Th>
                      <Th className="w-1/3"></Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {/* {csvData?.map((item, index) => {
                      return (
                        <React.Fragment>
                          <Tr key={index}>
                            <Td>{item?.first_name}</Td>
                            <Td>{item?.last_name}</Td>
                            <Td>{item?.email}</Td>
                            <Td>{item?.provider_abn_nzbn}</Td>
                            <Td>
                              {item?.is_validated ? (
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    handlecheckbox(event, index);
                                  }}
                                />
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m18 6.5-12 12m0-12 12 12"
                                    stroke="red"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })} */}
                    {csvData?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Tr>
                          <Td
                            className={
                              item?.validation_errors?.some(
                                (error) => error.field === "first_name"
                              )
                                ? "border-red-400 border border-solid"
                                : ""
                            }
                          >
                            <span className="flex items-center justify-between">
                              {item?.first_name}
                              {item?.validation_errors?.some(
                                (error) => error.field === "first_name"
                              ) && (
                                <Tooltip
                                  title={item?.validation_errors
                                    ?.filter(
                                      (error) => error.field === "first_name"
                                    )
                                    ?.map((error) => error.message)}
                                  placement="top-start"
                                >
                                  <span className="pl-1">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#bcclap1iaa)">
                                        <path
                                          d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                          stroke="rgb(239 68 68 / var(--tw-text-opacity))"
                                          strokeWidth="1.333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="bcclap1iaa">
                                          <path fill="#fff" d="M0 0h16v16H0z" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </Td>
                          {/* Repeat similar logic for other columns */}
                          <Td
                            className={
                              item?.validation_errors?.some(
                                (error) => error.field === "last_name"
                              )
                                ? "border-red-400 border border-solid"
                                : ""
                            }
                          >
                            <span className="flex items-center justify-between">
                              {item?.last_name}
                              {item?.validation_errors?.some(
                                (error) => error.field === "last_name"
                              ) && (
                                <Tooltip
                                  title={item?.validation_errors
                                    ?.filter(
                                      (error) => error.field === "last_name"
                                    )
                                    ?.map((error) => error.message)}
                                  placement="top-start"
                                >
                                  <span className="pl-1">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#bcclap1iaa)">
                                        <path
                                          d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                          stroke="rgb(239 68 68 / var(--tw-text-opacity))"
                                          strokeWidth="1.333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="bcclap1iaa">
                                          <path fill="#fff" d="M0 0h16v16H0z" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </Td>
                          <Td
                            className={
                              item?.validation_errors?.some(
                                (error) => error.field === "email"
                              )
                                ? "border-red-400 border border-solid"
                                : ""
                            }
                          >
                            <span className="flex items-center justify-between">
                              {item?.email}
                              {item?.validation_errors?.some(
                                (error) => error.field === "email"
                              ) && (
                                <Tooltip
                                  title={item?.validation_errors
                                    ?.filter((error) => error.field === "email")
                                    ?.map((error) => error.message)}
                                  placement="top-start"
                                >
                                  <span className="pl-1">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#bcclap1iaa)">
                                        <path
                                          d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                          stroke="rgb(239 68 68 / var(--tw-text-opacity))"
                                          strokeWidth="1.333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="bcclap1iaa">
                                          <path fill="#fff" d="M0 0h16v16H0z" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </Td>
                          <Td
                            className={
                              item?.validation_errors?.some(
                                (error) => error.field === "provider_abn_nzbn"
                              )
                                ? "border-red-400 border border-solid"
                                : ""
                            }
                          >
                            <span className="flex items-center justify-between">
                              {item?.provider_abn_nzbn}
                              {item?.validation_errors?.some(
                                (error) => error.field === "provider_abn_nzbn"
                              ) && (
                                <Tooltip
                                  title={item?.validation_errors
                                    ?.filter(
                                      (error) =>
                                        error.field === "provider_abn_nzbn"
                                    )
                                    ?.map((error) => error.message)}
                                  placement="top-start"
                                >
                                  <span className="pl-1">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#bcclap1iaa)">
                                        <path
                                          d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                          stroke="rgb(239 68 68 / var(--tw-text-opacity))"
                                          strokeWidth="1.333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="bcclap1iaa">
                                          <path fill="#fff" d="M0 0h16v16H0z" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          </Td>
                          <Td>
                            <Button onClick={() => RemoveRow(index)}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#475467"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </Button>
                          </Td>
                          {/* Repeat for email and provider_abn_nzbn */}
                        </Tr>
                      </React.Fragment>
                    ))}
                  </TBody>
                </Table>
              </div>
            </div>
          </Scrollbar>
        </div>
        {/* invite provider from csv */}
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => dialogCloseCSV()} className="mr-1.5">
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={!finalCsvData.length}
            onClick={() => submitCsvDataNext()}
          >
            Next
          </BtnPrimary>
        </div>
      </Dialog>
      {/* email Dilog Box */}
      <Dialog
        isOpen={dialogDeleteIsOpen}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to remove this Row?
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6  gap-3">
          <BtnLight
            className="customAlert"
            variant="plain"
            onClick={onDeleteDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary variant="solid" onClick={deleteRow}>
            Confirm
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogEmailOpen}
        onClose={onEmailClose}
        onRequestClose={onEmailClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <Email
          onEmailClose={onEmailClose}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
        />
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  onGetIndividualByEmail: userActions.GetIndividualByEmail,
  // onGetAllContractorPrimaryList: userActions.GetAllProvidersPrimaryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContractor);
