import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Segment from "../../../../../common/element/Segment";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select/Select";
import { debounce } from "../../../../../common/snippet/Debouncing";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import FullscreenLoader from "../../../../../common/element/ButtonLoader/FullScrrenLoader";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Upload from "../../../../../common/element/Upload";
import Validations from "../../../../../common/404/Validations";
import Heading from "../../../../../common/element/Heading";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";
import DatePicker from "../../../../../common/element/DatePicker";
import ComplianceList from "./ComplianceList";
import * as userActions from "../../../../../actions/index";
import dayjs from "dayjs";
import Table from "../../../../../common/element/Table";
import Tabs from "../../../../../common/element/Tabs";
import Button from "../../../../../common/element/Buttons";
import ReactPaginate from "react-paginate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import plusIcon from "../../../../../assets/icons-svg/plus.svg";
import plusIconBlue from "../../../../../assets/icons-svg/plus-blue.svg";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import fileCheck from "../../../../../assets/icons-svg/file-check.svg";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import {
  CreateComplianceChecklist,
  GetComplianceChecklist,
  GetAllComplianceList,
  GetAllProviderTypesList,
} from "../../../../../actions/client-portal/AllContactsApi";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
const { TabNav, TabList, TabContent } = Tabs;
const Compliance = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const navigate = useNavigate();
  let organisation_id;
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    organisation_id = storedData.organisation_id;
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }

  const { Tr, Th, Td, THead, TBody } = Table;
  const dateGap = 0;
  const { id } = useParams();
  const permissionDataUpdate = useSelector(
    (state) => state?.permissions?.permissionData
  );
  const [forceRender, setForceRender] = useState(false);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
  }
  let otherDocumentData = [];
  const [openAddComDoc, setOpenAddComDoc] = useState(false);
  const [selectedDocumentDataF, setSelectDocumentDataF] = useState([]);
  const [allDocuments, setAllDocument] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [tab, setTap] = useState("tab1");
  const [addComplianceDoc, setAddComplianceDoc] = useState({
    OtherMandatoryDoc: "",
    doc_type_name: "",
    document_type_id: "",
  });
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [documentError, setdocumentError] = useState("");
  const [filtersort, setFiltersort] = useState("created_date");
  const [filterorder, setFilterOrder] = useState("desc");
  const [otherDocId, setOtherDocId] = useState("");
  const [otherDocumentName, setOtherDocumentName] = useState("");
  const [active, setActive] = useState("active");
  const [selectedOption, setSelectedOption] = useState(null);
  const [countData, setCountData] = useState(0);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [dialogArch, setDialogArch] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedRecipientType, setSelectedRecipientType] = useState("");
  const [selectedOtherDocType, setSelectedOtherDocType] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [otherDocuments, setOtherDocuments] = useState([
    {
      document_type_id: "",
      documentName: "",
      instructions: "",
      isMandatory: "yes",
      file: null,
    },
  ]);
  const [docTypeData, setDocTypeData] = useState("");
  const [industryData, setIndustryData] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [selectDocumentData, setSelectDocumentData] = useState([]);
  const [industryId, setIndustryId] = useState("");
  const [DocData, setDocData] = useState("");
  const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
  const [recipientType, setRecipientType] = useState("");
  const [recipientDocType, setRecipientDocType] = useState("");
  const [expiryDate, setexpiry_date] = useState("");
  const [documentData, SetDocumentData] = useState({
    client_org_id: organisation_id,
    document_type_id: [],
    comp_checklist_id: "",
    is_mandatory: "",
  });
  // state for form error
  const [formError, setFormErrors] = useState({
    selectDoc: "",
    docType: "",
    isMandatoryErr: "",
    docName: "",
    industry: "",
    recipient: "",
    otherDocName: "",
    expiry_date: "",
    selectDocOne: "",
  });

  const [formDocError, setFormDocErrors] = useState({
    document_type_id: "",
    comp_checklist_id: "",
    is_mandatory: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Compliance Checklist",
          url: "/client/compliance",
          home: "/client/dashboard",
        },
      ])
    );
  }, [props.clientData]);

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem(
        "permission_data",
        JSON.stringify(permissionDataUpdate)
      );
      setForceRender((prevForceRender) => !prevForceRender);
      if (permissionDataUpdate?.compliance_view == false) {
        // navigate('/client/dashboard');
        navigate("/client/noaccess");
      }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }
  const compliancePermission =
    permission_data?.compliance_write == true ? false : true;
  // based on this value we set enable and disable buttons

  const validateOtherDocNames = (documents) => {
    return documents.map((doc) => {
      if (!doc.documentName) {
        return { error_msg: "This field is required!" };
      }
      return null;
    });
  };
  const removeDuplicatesUsingReduce = (arr) => {
    return arr.reduce((acc, current) => {
      const x = acc.find((item) => item.value === current.value);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  const openDialogAddDoc = () => {
    setOpenAddComDoc(true);

    const filteredData = selectDocumentData.filter(
      (doc) =>
        !allDocuments?.some(
          (usedDoc) => usedDoc?.document_type_id === doc?.value
        )
    );
    const filteredDataOther = selectDocumentData?.filter((doc) =>
      allDocuments?.some(
        (docName) =>
          docName?.document_type_id == doc?.value &&
          docName?.doc_type_name == "Other"
      )
    );
    const allData = [...filteredDataOther, ...filteredData];
    const uniqueArray = removeDuplicatesUsingReduce(allData);

    setSelectDocumentDataF(uniqueArray);
  };
  const addCompDocument = () => {
    const selectOneDocumnet = Validations({
      value: addComplianceDoc?.doc_type_name,
      Validations: { required: true },
    });
    setFormError("selectDocOne", selectOneDocumnet.error_msg);

    const Is_it_mandatory = Validations({
      value: addComplianceDoc?.OtherMandatoryDoc,
      Validations: { required: true },
    });
    setFormErrors((prev) => ({
      ...prev,
      isMandatoryErr: Is_it_mandatory.error_msg,
    }));

    let OtherDocName;
    if (addComplianceDoc?.doc_type_name == "Other") {
      OtherDocName = Validations({
        value: addComplianceDoc?.documentName,
        Validations: { required: true },
      });
      setFormErrors((prev) => ({
        ...prev,
        otherDocName: OtherDocName.error_msg,
      }));
    }
    if (
      selectOneDocumnet?.valid &&
      documentError == "" &&
      Is_it_mandatory?.valid &&
      (addComplianceDoc?.doc_type_name !== "Other" || OtherDocName?.valid)
    ) {
      if (OtherDocName?.valid) {
        setAllDocument([
          ...allDocuments,
          {
            doc_type_name: addComplianceDoc?.doc_type_name,
            document_type_id: addComplianceDoc?.document_type_id,
            is_doc_mandatory:
              addComplianceDoc?.OtherMandatoryDoc?.[0] == "yes" ? true : false,
            otherDocFile: addComplianceDoc?.file ? addComplianceDoc?.file : [],
            other_doc_instruction: addComplianceDoc?.instructions
              ? addComplianceDoc?.instructions
              : "",
            other_doc_name: addComplianceDoc?.documentName,
            is_other_doc: true,
          },
        ]);
      } else if (selectOneDocumnet?.valid && Is_it_mandatory?.valid) {
        setAllDocument([
          ...allDocuments,
          {
            doc_type_name: addComplianceDoc?.doc_type_name,
            document_type_id: addComplianceDoc?.document_type_id,
            is_doc_mandatory:
              addComplianceDoc?.OtherMandatoryDoc?.[0] == "yes" ? true : false,
            is_other_doc: false,
          },
        ]);
      }

      // setButtonDisabled(true);
      // dispatch(userActions.CreateComplianceDoc(documentData)).then(
      //   (response) => {
      //     if (response?.success === true) {
      setButtonDisabled(false);
      closeDialogAddDoc();
      //     } else {
      //       setButtonDisabled(false);
      //     }
      //   }
      // );
    }
  };
  const handleDeleteDocument = (index) => {
    const newDocuments = allDocuments?.filter(
      (_, docIndex) => docIndex !== index
    );
    setAllDocument(newDocuments);
  };

  const closeDialogAddDoc = () => {
    setOpenAddComDoc(false);
    setAddComplianceDoc({
      OtherMandatoryDoc: "",
      doc_type_name: "",
      document_type_id: "",
      file: null,
      instructions: "",
      documentName: "",
    });
    setdocumentError("");
    setFormErrors((prevErr) => ({
      ...prevErr,
      isMandatoryErr: "",
      selectDocOne: "",
    }));
    setSelectedOption("");
    setSelectedOptions([]);
  };
  const handleMandatoryChange = (value) => {
    setAddComplianceDoc((prevData) => ({
      ...prevData,
      OtherMandatoryDoc: value,
    }));
    // i think this is extra
    SetDocumentData((prevData) => ({
      ...prevData,
      is_mandatory: value,
    }));
    setFormErrors((prev) => ({ ...prev, isMandatoryErr: "" }));
  };
  // Function to open the document type selection dialog
  const openChoose = () => {
    setChoose(true);
    clearFormErrors();
  };
  const openChooseDoc = () => {
    setIsOpen(true);
  };
  // Archived checklist
  const OpenArchCheckList = () => {
    setActive("archived");
    // GetAllComplianceChecklistOfClient()

    setDialogArch(true);
  };
  const onBackListData = () => {
    setDialogArch(false);
    if (active == "archived") {
      setsearchTerm("");
    }
    setActive("active");
  };
  const handleOtherDocChange = (field, value) => {
    // setAddComplianceDoc((prevData) => ({
    //   ...prevData,
    //   [field]: value
    // }))
    // setFormErrors(prev => ({ ...prev, otherDocName: "" }));

    if (field == "file") {
      const fileType = value?.[0]?.type;
      if (fileType == null) {
        setdocumentError("");
        return;
      }
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif") ||
        fileType.startsWith("application/pdf")
      ) {
        if (value[0]?.size <= 2 * 1024 * 1024) {
          setAddComplianceDoc((prevData) => ({
            ...prevData,
            [field]: value,
          }));
          setdocumentError("");
        } else {
          setdocumentError("File size exceeds the maximum limit of 2MB.");
        }
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, PDF or GIF files are allowed."
        );
      }
    } else {
      setAddComplianceDoc((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const addOtherDocument = () => {
    setOtherDocuments([
      ...otherDocuments,
      {
        documentName: "",
        instructions: "",
        isMandatory: "yes",
        file: null,
      },
    ]);
  };
  const handleRemoveDocument = (index) => {
    const updatedDocs = [...otherDocuments];
    updatedDocs.splice(index, 1);
    setOtherDocuments(updatedDocs);
    setFormErrors((prev) => ({ ...prev, otherDocName: "" }));
  };

  const onChooseClose = (e) => {
    if (!buttonDisabled) {
      setChoose(false);
      clearFormErrors();
      clearFormData();
      setTap("tab1");
    }
  };
  // Function to open the main add document dialog
  const clearFormErrors = () => {
    setFormErrors(() => ({
      selectDoc: "",
      docType: "",
      docName: "",
      industry: "",
      isMandatoryErr: "",
      otherDocName: "",
      recipient: "",
      selectDocOne: "",
    }));
  };
  const clearFormData = () => {
    setSelectedDocumentType("");
    setDocTypeData("");
    setIndustryId("");
    setDocumentName("");
    setSelectedOption("");
    setSelectedOptions([]);
    setSelectDocumentData([]);
    setSelectedRecipientType("");
    setexpiry_date("");
    setOtherDocId("");
    SetDocumentData({
      client_org_id: organisation_id,
      document_type_id: [],
      comp_checklist_id: "",
      is_mandatory: "",
    });
    otherDocumentData = [];
    setOtherDocuments([
      {
        document_type_id: "",
        documentName: "",
        instructions: "",
        isMandatory: "yes",
        file: null,
      },
    ]);
  };
  // Function to close the main add document dialog
  const onDialogClose = (e) => {
    SetDocumentData({
      client_org_id: "",
      document_type_id: [],
      comp_checklist_id: "",
      is_mandatory: "",
    });
    if (!isSaving) {
      setIsOpen(false);
      clearFormData();
      setIsSaving(false);
    }
  };

  // Function to close the add document alert dialog
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    onChooseClose();
    onDialogClose();
    // props.getOnDialogClose(onAddDialogClose);
  };
  // Function to handle selection of document type
  const selectDocType = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
  };

  // function for handling changes in the document Type selection
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setLoadData(false);
      setSingleSegmentValue(val);
      if (val[0] === "view all") {
        setRecipientType("");
        // setSelectSWMS(false);
        setfilterpage(1);
      } else {
        setRecipientType(val[0]);
        // setSelectSWMS(false);
        setfilterpage(1);
      }
    },
    [setSingleSegmentValue]
  );
  const handleSeachChange = (e) => {
    setsearchTerm(e?.target?.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    // GetAllComplianceChecklistOfClient(selected + 1, filterlimit);
  };

  const GetAllOrgIndustryTypeList = useCallback(async () => {
    dispatch(userActions.GetAllOrgIndustryTypeList())
      .then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          let service_data = [];
          for (let key in arrayData) {
            service_data.push({
              label: arrayData[key].meta_value_one,
              value: arrayData[key].master_setting_id,
            });
          }
          setIndustryData(service_data);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // eslint-disable-next-line
  }, [dispatch]);

  const GetAllComplianceChecklistOfClient = useCallback(async () => {
    try {
      const response = await dispatch(
        GetComplianceChecklist(
          organisation_id,
          filtersort,
          filterorder,
          recipientType,
          active == "archived" ? "" : searchTerm,
          filterlimit,
          filterpage,
          active
        )
      );

      const { success, data, status } = response;

      if (success) {
        setLoadData(true);
        const { rows } = data;
        setCountData(response?.data?.count);
        setDocData(rows);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (status === 401) {
        setLoadData(true);
        handleUnauthorizedAccess();
      } else {
        setLoadData(true);
        console.error("Failed to fetch compliance checklist:", response);
        // Handle other types of errors or unsuccessful responses
      }
    } catch (error) {
      setLoadData(true);
      console.error("Failed to fetch compliance checklist:", error);
      // Handle unexpected errors
    }
  }, [
    dispatch,
    organisation_id,
    filtersort,
    filterorder,
    recipientType,
    searchTerm,
    filterlimit,
    filterpage,
    active,
  ]);

  useEffect(() => {
    setLoadData(false);
    GetAllComplianceChecklistOfClient();
  }, [
    GetAllComplianceChecklistOfClient,
    organisation_id,
    filtersort,
    filterorder,
    recipientType,
    searchTerm,
    filterlimit,
    filterpage,
    active,
  ]);

  const handleSort = (sortType) => {
    setFiltersort(sortType);
    setFilterOrder(filterorder === "asc" ? "desc" : "asc");
  };
  // Set a form error
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const setDocFormErrorfunc = (field, message) => {
    setFormDocErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  // const transformDocumentData = (documentTypeIds) => {
  //   return documentTypeIds?.map(id => ({
  //     document_type_id: id,
  //     is_other_doc: false,
  //     is_doc_mandatory: true,
  //   }));
  // };
  // const transformedDocumentData = transformDocumentData(documentData?.document_type_id ? documentData?.document_type_id : []);

  // const transformObject = (documentTypeIds) => {
  //   return documentTypeIds?.map(ele => ({
  //     document_type_id: otherDocId ? otherDocId : "",
  //     is_doc_mandatory: ele?.isMandatory == "yes" ? true : false,
  //     is_other_doc: true,
  //     other_doc_instruction: ele.instructions ? ele.instructions : "",
  //     other_doc_name: ele.documentName ? ele.documentName : "",
  //     otherDocFile: ele?.file?.[0] ? ele?.file?.[0] : ""
  //   }));
  // };
  // otherDocumentData = transformObject(otherDocuments ? otherDocuments : [])

  const submitClient = (e) => {
    // const removeOtherDocId = transformedDocumentData.filter((ele) => ele.document_type_id != otherDocId)
    // let otherDocNameErrors;
    // let documentFiles = []
    // if (otherDocumentData[0]?.document_type_id) {
    //   documentFiles = [...removeOtherDocId, ...otherDocumentData]
    //   otherDocNameErrors = validateOtherDocNames(otherDocuments);
    //   setFormErrors(prev => ({ ...prev, otherDocName: otherDocNameErrors }));

    // }
    // else {
    //   documentFiles = transformedDocumentData;
    // }
    const DocSelectValidation = Validations({
      value: selectedDocumentType,
      Validations: {
        required: selectedRecipientType == "provider" ? true : false,
      },
    });

    // const fdata = otherDocNameErrors?.filter((ele) => ele?.error_msg === "This field is required!");
    const expiry_date = Validations({
      value: expiryDate,
      Validations: { required: true },
    });
    const docNameValidation = Validations({
      value: documentName,
      Validations: { required: true },
    });
    const recipient = Validations({
      value: selectedRecipientType,
      Validations: { required: true },
    });

    setFormError("docName", docNameValidation.error_msg);
    setFormError("recipient", recipient.error_msg);

    setFormError("expiry_date", expiry_date.error_msg);
    if (selectedRecipientType == "provider") {
      setFormError("selectDoc", DocSelectValidation.error_msg);
    } else {
      setFormError("selectDoc", "");
    }
    // let validOrNot = fdata?.[0]?.error_msg === undefined ? "" : fdata[0].error_msg !== "This field is required!";
    if (
      docNameValidation.valid &&
      recipient.valid &&
      expiry_date.valid &&
      DocSelectValidation.valid &&
      tab == "tab1"
    ) {
      setTap("tab2");
    }
    if (
      tab == "tab2" &&
      docNameValidation.valid &&
      recipient.valid &&
      DocSelectValidation.valid
      // (validOrNot === undefined || validOrNot !== "This field is required!") &&
    ) {
      const payload = {
        client_org_id: organisation_id,
        provider_type_id:
          selectedDocumentType?.value != undefined
            ? selectedDocumentType?.value
            : "",
        checklist_name: documentName,
        checklist_renewal_date: expiryDate,
        recipient_type: selectedRecipientType,
        docsData: allDocuments ? allDocuments : [],
        abn_nzbn: storedData?.abn_nzbn,
        trading_name: storedData?.trading_name,
      };
      setButtonDisabled(true);

      setIsSaving(true);
      dispatch(CreateComplianceChecklist(payload, ipAddress))
        .then((response) => {
          if (response?.success === true) {
            setIsSaving(false);
            setAddOpen(true);
            setButtonDisabled(false);
            setAllDocument([]);
            setTap("tab1");
            // Fetch new data
            GetAllComplianceChecklistOfClient();
          }
        })
        .catch((error) => {
          console.error("Error :", error);
        })
        .finally(() => {
          setIsSaving(false);
          setButtonDisabled(false);
        });
    }
  };

  // Submit Client Doc
  const submitClientDoc = (e) => {
    const select_list = Validations({
      value: documentData.document_type_id,
      Validations: { required: true },
    });
    const select_Document = Validations({
      value: documentData.comp_checklist_id,
      Validations: { required: true },
    });
    const Is_it_mandatory = Validations({
      value: documentData.is_mandatory,
      Validations: { required: true },
    });
    setDocFormErrorfunc("document_type_id", select_list.error_msg);
    setDocFormErrorfunc("comp_checklist_id", select_Document.error_msg);
    setDocFormErrorfunc("is_mandatory", Is_it_mandatory.error_msg);
    if (select_list.valid && select_Document.valid && Is_it_mandatory.valid) {
      setIsSaving(true);
      dispatch(userActions.CreateComplianceDoc(documentData))
        .then((response) => {
          if (response?.success === true) {
            setIsSaving(false);
            setAddOpen(true);
          } else {
            setIsSaving(false);
          }
        })
        .catch((error) => {
          console.error("Error removing user:", error);
        });
    }
  };
  //call permission api
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_id) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_id))
        .then((response) => {
          if (response?.success === true) {
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole();
    GetAllOrgIndustryTypeList();
  }, []);

  // // Get ProviderList
  const fetchProviderList = useCallback(() => {
    dispatch(GetAllProviderTypesList())
      .then((response) => {
        const filterData = response?.data?.map((item) => ({
          value: item.provider_type_id,
          label: item.provider_type_name,
        }));
        setSearchOptions(filterData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [dispatch, setSearchOptions]);

  // Get ProviderList
  useEffect(() => {
    if (selectedRecipientType === "provider" && searchOptions.length === 0) {
      fetchProviderList();
    }
  }, [selectedRecipientType]);

  // Get GetAllComplianceList
  useEffect(() => {
    // recipientDocType
    if (selectedRecipientType) {
      dispatch(
        userActions.GetAllDocumentTypeList({
          recipient_type: selectedRecipientType,
        })
      )
        .then((response) => {
          let filterData = response?.data?.map((item) => {
            return {
              value: item.document_type_id,
              label: item.doc_type_name,
            };
          });

          setSelectDocumentData(filterData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedRecipientType]);

  const handleForm = (event, expireDate) => {
    setexpiry_date(event);
  };

  const minDate = dayjs(new Date())
    .subtract(dateGap - 1, "day")
    .startOf("day")
    .toDate();

  // Handle DocData function
  const HandleDocData = (data, nanme) => {
    setSelectedOptions(data);
    setSelectedOption(data);
    if (data?.label == "Other") {
      const filterOtherId = data?.value;
      setOtherDocId(filterOtherId?.[0] == undefined ? "" : filterOtherId[0]);
      setAddComplianceDoc((...prevData) => ({
        ...prevData,
        doc_type_name: data?.label ? data?.label : "",
        document_type_id: data?.value ? data?.value : "",
      }));
    } else {
      setAddComplianceDoc((...prevData) => ({
        ...prevData,
        doc_type_name: data?.label ? data?.label : "",
        document_type_id: data?.value ? data?.value : "",
      }));
    }
    // SetDocumentData((prevalue) => {
    //   if (nanme == "comp_checklist_id") {
    //     setRecipientDocType(data?.recipient_type);
    //     return {
    //       ...prevalue,
    //       [nanme]: data.value,
    //     };
    //   } else if (nanme == "document_type_id") {
    //     return {
    //       ...prevalue,
    //       [nanme]: data.value,
    //     };
    //   } else {
    //     return {
    //       ...prevalue,
    //       [nanme]: data,
    //     };
    //   }
    // });
  };

  // const isOtherSelected = selectedOptions?.some(option => option?.label === 'Other');
  // const heightClass = isOtherSelected ? 'h-64' : "";
  return (
    <React.Fragment>
      {dialogArch == false && (
        <>
          <div className="flex justify-between mb-5">
            <div>
              <Heading>Compliance Checklist</Heading>
              <p className="text-gray-600">
                Configure and manage criteria for contractors and workers to be
                fulfilled before initiating collaboration with your organisation
              </p>
            </div>
          </div>
          <div className="flex justify-between mb-5 flex-wrap gap-2 px-2">
            <div className="flex gap-2 flex-wrap">
              <Segment
                className="docType-btn"
                value={singleSegmentValue}
                onChange={(val) => onSingleSelectionSegmentChange(val)}
              >
                <Segment.Item value="view all">View All</Segment.Item>
                <Segment.Item value="worker">Worker</Segment.Item>
                <Segment.Item value="provider">Provider</Segment.Item>
              </Segment>

              <div className="w-80 h-9">
                <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5">
                    <img src={searchIcon} alt="" className="h-5" />
                  </div>
                  <input
                    type="text"
                    name="hs-table-search"
                    className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    onChange={(e) => inputchangeSearch(e)}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-x-2.5 w-80 pl-7">
                <BtnLight
                  onClick={() => OpenArchCheckList()}
                  className="px-3 text-xs h-9 flex gap-2 items-center"
                >
                  Archived checklists
                </BtnLight>
                <BtnPrimary
                  disabled={compliancePermission}
                  onClick={() => openChoose()}
                  className="px-3 text-xs h-9 flex gap-2 items-center"
                >
                  Add Checklist
                  <span>
                    <img src={plusIcon} alt="" />
                  </span>
                </BtnPrimary>
              </div>
            </div>
          </div>
        </>
      )}
      {dialogArch == true && (
        <div className="mb-5 flex-wrap gap-2 px-2">
          <div className="flex justify-between mb-0 flex-wrap gap-2">
            <Heading>Archived checklists</Heading>
            <BtnLight
              className="w-60 h-9 flex items-center justify-center"
              onClick={onBackListData}
            >
              {" "}
              Back to active checklists
              <span>
                <svg
                  className="ml-4"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 7.49935H13.75C15.8211 7.49935 17.5 9.17828 17.5 11.2493C17.5 13.3204 15.8211 14.9993 13.75 14.9993H10M2.5 7.49935L5.83333 4.16602M2.5 7.49935L5.83333 10.8327"
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </BtnLight>
          </div>

          <p className="text-gray-600">
            An overview of all archived checklists. They cannot be restored, but
            you can still view them.
          </p>
        </div>
      )}
      <ComplianceList
        DocData={DocData}
        compliancePermission={compliancePermission}
        // for delete data show in list  (dialogArch)
        GetAllComplianceChecklistOfClient={GetAllComplianceChecklistOfClient}
        dialogArch={dialogArch}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        openChooseDoc={openChooseDoc}
        searchOptions={searchOptions}
        industryData={industryData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={DocData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}
      {/* CheckList Dialog */}
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Checklist
            </h5>
          </div>
        </div>

        <Tabs value={tab} variant="pill">
          <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 mt-6 client-portal-tab">
            <TabList>
              <TabNav value="tab1" onClick={() => setTap("tab1")}>
                Details
              </TabNav>
              <TabNav value="tab2" onClick={() => setTap("tab2")}>
                Documents
              </TabNav>
            </TabList>
          </div>

          <TabContent value="tab1">
            <div className="px-6">
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Checklist name
                </label>
                <div className="w-3/4 pl-2">
                  <input
                    className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={documentName}
                    placeholder="Enter checklist name"
                    onChange={(e) => setDocumentName(e.target.value)}
                  />
                  {!documentName && formError.docName && (
                    <p className="text-xs text-red-600 mb-2 mt-0">
                      {formError.docName}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Recipient type
                </label>
                <div className="w-3/4 pl-2">
                  <Segment
                    className="docType-btn"
                    value={selectedRecipientType}
                    onChange={(val) => {
                      setSelectedRecipientType(val[0]);
                      if (val[0] === "worker") {
                        setSelectedDocumentType("");
                      }
                    }}
                  >
                    <Segment.Item value="provider">Provider</Segment.Item>
                    <Segment.Item value="worker">Worker</Segment.Item>
                  </Segment>
                  {!selectedRecipientType && formError.recipient && (
                    <p className="text-xs text-red-600 mb-2 mt-0">
                      {formError.recipient}
                    </p>
                  )}
                </div>
              </div>
              {selectedRecipientType == "provider" && (
                <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Provider type
                  </label>
                  <div className="w-3/4 pl-2">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      options={searchOptions}
                      value={selectedDocumentType}
                      onChange={(selectedOption) =>
                        selectDocType(selectedOption)
                      }
                    ></Select>
                    {!selectedDocumentType && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.selectDoc}
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Expiry date*
                </label>
                <div className="w-3/4 pl-2 flex">
                  <div className="w-full relative">
                    <span className="adon_icon absolute left-4 top-3">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#0ckeddmh9a)">
                          <path
                            d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z"
                            stroke="#344054"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="0ckeddmh9a">
                            <path
                              fill="#fff"
                              transform="translate(0 .958)"
                              d="M0 0h20v20H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <DatePicker
                      onChange={(e) => handleForm(e, "expiry_date")}
                      placeholder="Select expire date"
                      minDate={minDate}
                      value={expiryDate}
                    />
                    {!expiryDate && formError.expiry_date && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.expiry_date}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabContent>

          <TabContent value="tab2">
            <div className="px-6">
              {allDocuments?.length === 0 && (
                <>
                  <div className="h-60">
                    <div className="h-full flex items-center justify-center">
                      <div className="text-center">
                        <h4 className="font-semibold text-sm">
                          No document selected
                        </h4>
                        <p className="text-xs">
                          Please add at least one to build the checklist
                        </p>
                        <BtnPrimary
                          onClick={openDialogAddDoc}
                          className="px-3 w-40 text-xs h-9 flex gap-2 items-center mx-auto mt-4"
                        >
                          Add Document(s)
                          <span>
                            <img src={plusIcon} alt="Plus Icon" />
                          </span>
                        </BtnPrimary>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {allDocuments?.length > 0 && (
                <>
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <THead>
                        <Tr>
                          <Th>
                            <span
                              className="flex items-center gap-1"
                              onClick={() => handleSort("doc_type_name")}
                            >
                              Document
                              <svg
                                className={`transform ${
                                  filtersort === "doc_type_name" &&
                                  filterorder === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </Th>
                          <Th>Mandatory?</Th>
                          <Th></Th>
                        </Tr>
                      </THead>
                      <TBody>
                        {allDocuments.length > 0 &&
                          allDocuments.map((doc, index) => (
                            <Tr key={index}>
                              <Td>{doc?.doc_type_name}</Td>
                              <Td>
                                {doc?.is_doc_mandatory === true ? "Yes" : "No"}
                              </Td>
                              <Td>
                                <Button
                                  onClick={() => handleDeleteDocument(index)}
                                >
                                  <svg
                                    width="19"
                                    height="20"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                      stroke="#667085"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                      </TBody>
                    </Table>
                  </div>
                  <div className="mb-5 mt-5 mr-5 flex justify-end">
                    <BtnLight
                      onClick={openDialogAddDoc}
                      className="w-36  flex items-center text-blue-700 justify-center gap-2"
                    >
                      Add more
                      <span>
                        <img src={plusIconBlue} alt="Add icon" />
                      </span>
                    </BtnLight>
                  </div>
                </>
              )}
            </div>
          </TabContent>
        </Tabs>

        {/* <div className={heightClass}>
          <Scrollbar>
            <div className={`${isOtherSelected === "Other" ? heightClass : ""} px-6`}> */}

        {/* </Scrollbar>
        </div> */}

        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onChooseClose}>
            Cancel
          </BtnLight>
          {tab == "tab1" && (
            <>
              <BtnPrimary onClick={submitClient} className="ml-1.5">
                Next
              </BtnPrimary>
            </>
          )}
          {tab == "tab2" && (
            <>
              <BtnPrimary
                disabled={
                  buttonDisabled || allDocuments?.length == 0
                    ? true
                    : buttonDisabled
                }
                onClick={submitClient}
                className="ml-1.5"
              >
                {buttonDisabled ? <ThreeDotLoader /> : "Save"}
              </BtnPrimary>
            </>
          )}
        </div>
      </Dialog>
      {/* Add Document */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Compliance Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Select list
            </label>
            <div className="w-3/4 pl-2">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select Validation Criteria"
                options={docTypeData}
                value={
                  docTypeData &&
                  docTypeData?.filter(
                    (item) => item.value == documentData.comp_checklist_id
                  )
                }
                onChange={(e) => HandleDocData(e, "comp_checklist_id")}
              ></Select>
              {!documentData.comp_checklist_id &&
                formDocError.comp_checklist_id && (
                  <p className="text-xs text-red-600 mb-2 mt-0">
                    {formDocError.comp_checklist_id}
                  </p>
                )}
            </div>
          </div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Select document
            </label>

            <div className="w-3/4 pl-2">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select Industry"
                options={selectDocumentData}
                value={
                  selectDocumentData &&
                  selectDocumentData?.filter(
                    (item) => item.value == documentData.document_type_id
                  )
                }
                onChange={(e) => HandleDocData(e, "document_type_id")}
                // sMulti
              ></Select>
              {!documentData.document_type_id &&
                formDocError.document_type_id && (
                  <p className="text-xs text-red-600 mb-2 mt-0">
                    {formDocError.document_type_id}
                  </p>
                )}
            </div>
          </div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Is it mandatory?
            </label>
            <div className="w-3/4 pl-2">
              <Segment
                className="docType-btn-md"
                value={documentData.is_mandatory}
                onChange={(val) => HandleDocData(val[0], "is_mandatory")}
              >
                <Segment.Item value="true">Yes</Segment.Item>
                <Segment.Item value="false">No</Segment.Item>
              </Segment>
              {!documentData.is_mandatory && formDocError.is_mandatory && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formDocError.is_mandatory}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary
            type="submit"
            onClick={(e) => submitClientDoc(e)}
            className="ml-1.5"
            disabled={isSaving}
          >
            {isSaving ? "Adding..." : "Add"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Checklist added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={openAddComDoc}
        onClose={closeDialogAddDoc}
        onRequestClose={closeDialogAddDoc}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Compliance Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Select document(s)*
            </label>
            <div className="w-3/4 pl-2">
              <Select
                placeholder="Select documents required"
                name="comp_doc_id_data"
                className="w-full h-auto select-options"
                value={selectedOption}
                onChange={(e) => HandleDocData(e, "document_type_id")}
                options={
                  Array.isArray(selectedDocumentDataF)
                    ? selectedDocumentDataF
                    : Array.isArray(selectDocumentData)
                    ? selectDocumentData
                    : []
                }
              />
              {!selectedOption && formError?.selectDocOne && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formError?.selectDocOne}
                </p>
              )}
            </div>
          </div>

          <div className="flex">
            <label className="font-medium text-gray-700 w-1/4">
              Is it mandatory?
            </label>
            <div className="w-3/4 pl-2">
              <Segment
                className="docType-btn"
                value={addComplianceDoc?.OtherMandatoryDoc}
                onChange={handleMandatoryChange}
              >
                <Segment.Item value="yes">Yes</Segment.Item>
                <Segment.Item value="no">No</Segment.Item>
              </Segment>
              {!addComplianceDoc?.OtherMandatoryDoc &&
                formError?.isMandatoryErr && (
                  <p className="text-xs text-red-600 mb-2 mt-0">
                    {formError?.isMandatoryErr}
                  </p>
                )}
            </div>
          </div>
          {selectedOptions?.label === "Other" && (
            <>
              <div className="flex mb-5">
                <label className="font-medium mt-5 text-gray-700 w-1/4">
                  ‘Other’ document name
                </label>
                <div className="w-3/4 pl-2 mt-5">
                  <input
                    className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={addComplianceDoc?.documentName}
                    placeholder="Name this ‘Other’ document"
                    onChange={(e) =>
                      handleOtherDocChange("documentName", e.target.value)
                    }
                  />
                  {formError?.otherDocName?.error_msg && (
                    <p className="text-xs text-red-600 mb-2 mt-0">
                      {formError.otherDocName?.error_msg}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  What would you like the recipient to do?
                </label>
                <div className="w-3/4 pl-2">
                  <input
                    className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={addComplianceDoc?.instructions}
                    placeholder="Provide short instruction here e.g please download file, fill in information and reupload"
                    onChange={(e) =>
                      handleOtherDocChange("instructions", e?.target?.value)
                    }
                  />
                </div>
              </div>
              {/* <div className="flex">
                <label className="font-medium text-gray-700 w-1/4">
                  Is ‘Other’ document mandatory?
                </label>
                <div className="w-3/4 pl-2">
                  <Segment
                    className="docType-btn"
                    value={doc.isMandatory}
                    onChange={(val) => handleOtherDocChange(index, 'isMandatory', val[0])}
                  >
                    <Segment.Item value="yes">Yes</Segment.Item>
                    <Segment.Item value="no">No</Segment.Item>
                  </Segment>
                </div>
              </div> */}
              <div className="flex mb-5 pt-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Upload ‘Other’ document
                </label>
                <div className="w-3/4 pl-2">
                  <Upload
                    uploadLimit={1}
                    onChange={(file) => handleOtherDocChange("file", file)}
                    accept={".jpg, .jpeg, .png, .pdf,"}
                    draggable
                    className="w-full"
                    onFileRemove={() => handleOtherDocChange("file", null)}
                  >
                    <div className="text-center">
                      <div className="flex items-center justify-center flex-col">
                        <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                              stroke="#475467"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>
                        <span className="pt-4">
                          <span className="text-blue-700 font-semibold mr-1">
                            Click to upload
                          </span>
                          <span className="text-xs text-gray-600">
                            or drag and drop
                          </span>
                          <div>
                            <span className="text-xs text-gray-600">
                              (Max 2MB. Only pdf, heic, png, webp, and jpg files
                              will be accepted)
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </Upload>
                  {documentError && (
                    <p className="text-xs text-red-600 mb-2 mt-0">
                      {documentError}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            className="mr-1.5"
            variant="plain"
            onClick={closeDialogAddDoc}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            className="ml-1.5"
            onClick={addCompDocument}
          >
            Add
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.CreateComplianceDoc.loader,
  };
}
const mapDispatchToProps = {
  onCreateComplianceDoc: userActions.CreateComplianceDoc,
};

export default connect(mapStateToProps, mapDispatchToProps)(Compliance);
