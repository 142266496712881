import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllInvitedProvidersOfClient =
  (getAllinvitedData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetAllInvitedProvidersOfClient`,
        params: getAllinvitedData,
      });
      //   dispatch(fetchDataSuccess(response.data));
      return response.data;
    } catch (error) {
      //   dispatch(fetchDataError(error.message));
      return error.message;
    }
  };

export const GetWorkerProfileById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    //   dispatch(fetchData())
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetWorkerProfileById`,
      params: apiData,
    });
    return response.data;
    //   dispatch(fetchDataSuccess(response.data))
  } catch (error) {
    return error.message;
    //   dispatch(fetchDataError(error.message))
  }
};
export const GetAllClientContacts = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetAllClientContacts`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const InviteSpecificProvider = (form_data) => async (dispatch) => {
  // Create a new FormData object to handle file uploads
  let formData = new FormData();

  // Iterate over the keys in the form_data object
  for (let key in form_data) {
    if (key === "invite_attach") {
      // Check if the key is 'invite_attach' for file uploads
      if (
        form_data[key] &&
        form_data[key].files &&
        Array.isArray(form_data[key].files)
      ) {
        // If files are provided, append each file to the FormData object
        form_data[key].files.forEach((file, i) => {
          formData.append(`invite_attach`, file);
        });
      } else {
        console.warn(
          `Expected an array of files for key 'invite_attach', but got`,
          form_data[key]
        );
      }
    } else {
      // For other form fields, simply append the key-value pair to the FormData object
      formData.append(key, form_data[key]);
    }
  }

  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    // Send a POST request using axios
    const response = await axios({
      method: "post",
      url: `${actionType.environment_url}/api/admin/client/InviteSpecificProvider`,
      data: formData, // Pass the FormData object as the request data
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
    });

    // If the request is successful, return the response data
    return response.data;
  } catch (error) {
    // If there's an error, log it and rethrow it to propagate to the caller
    console.error("Error inviting specific provider:", error);
    throw error;
  }
};
export const ReinviteProvider = (form_data) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "put",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/ReinviteProvider`,
    });
    return response.data;
  } catch (error) {}
};

export const GetAllGlobalProvidersForClient =
  (getAllinvitedData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetAllGlobalProvidersForClient`,
        params: getAllinvitedData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetAllProvidersOfClientAndDetails =
  (getAllinvitedData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetAllProvidersOfClientAndDetails`,
        params: getAllinvitedData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
