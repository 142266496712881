import React, { useState, useEffect, useCallback } from "react";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { Link } from "react-router-dom";
import { debounce } from "../../../../../common/snippet/Debouncing";
import Segment from "../../../../../common/element/Segment";
import Heading from "../../../../../common/element/Heading";
import InvoiceList from "./InvoiceList";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../../../../common/pagination";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import { connect, useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { AdminGetAllInvoices } from "../../../../../actions/Admin/invoices/AdminGetAllInvoices";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
const Invoices = ({ dashboardStatus }) => {
  const dispatch = useDispatch();
  const [loadData, setLoadData] = useState(false);
  const [countData, setCountData] = useState(0);
  const [filterpage, setfilterpage] = useState(1);
  const [searchTerm, setsearchTerm] = useState("");
  const [filterlimit, setfilterlimit] = useState(10);
  const [removeCheck, setRemoveCheck] = useState(false);
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("created_date");
  const [viewType, setViewType] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
  const [allInvoicesData, setAllInvoicesData] = useState([]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Invoices", url: "/admin/invoices", home: "/admin/dashboard" },
      ])
    );
  }, []);

  const onGetAllInvoiceData = useCallback(async () => {
    let payload = {
      page: filterpage,
      search: searchTerm,
      statusType: viewType,
      limit: filterlimit,
      order: filterorder,
      sort: filtersort,
    };
    dispatch(AdminGetAllInvoices(payload)).then((response) => {
      if (response.success) {
        setAllInvoicesData(response.data?.rows);
        setCountData(response.data?.count);
        setTotalPages(Math.ceil(response.data?.count / filterlimit));
        setLoadData(true);
      } else {
        setLoadData(true);
      }
    });
  }, [
    dispatch,
    viewType,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
  ]);

  useEffect(() => {
    onGetAllInvoiceData();
  }, [viewType, searchTerm, filterpage, filterorder, filtersort, filterlimit]);

  const handleSeachChange = (e) => {
    setRemoveCheck(true);
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
  };
  
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
      if (val[0] === "view all") {
        setfilterpage(1);
        setViewType("");
      } else {
        if (val[0] == "paid") {
          setViewType("PAID");
        } else if (val[0] == "upcoming") {
          setViewType("UPCOMING");
        } else if (val[0] == "overdue") {
          setViewType("OVERDUE");
        } else if (val[0] == "draft") {
          setViewType("DRAFT");
        } else if (val[0] == "due") {
          setViewType("DUE");
        }
      }
    },
    [setSingleSegmentValue]
  );
  return (
    <React.Fragment>
      {!dashboardStatus && (
        <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
          <Heading>Invoices</Heading>
          <div className="flex flex-wrap gap-3">
            <Link to="raise-invoice">
              <BtnPrimary className="flex w-40 items-center justify-center gap-2 text-12">
                Raise an invoice
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.712 4.167v11.666M4.878 10h11.667"
                    stroke="#fff"
                    strokeWidth="1.667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </BtnPrimary>
            </Link>
          </div>
        </div>
      )}

      <div
        className={`flex justify-between mb-5  flex-wrap gap-y-2.5 ${
          dashboardStatus && "px-4"
        }`}
      >
        <Segment
          className="docType-btn"
          value={singleSegmentValue}
          onChange={(val) => onSingleSelectionSegmentChange(val)}
        >
          <Segment.Item value="view all">View All</Segment.Item>
          <Segment.Item value="paid">Paid</Segment.Item>
          <Segment.Item value="upcoming">Upcoming</Segment.Item>
          <Segment.Item value="overdue">Overdue</Segment.Item>
          <Segment.Item value="draft">Draft</Segment.Item>
          <Segment.Item value="due">Due</Segment.Item>
        </Segment>
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={searchIcon} alt="" className="h-5" />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
            />
          </div>
        </div>
      </div>
      {/* <Filter /> */}
      <InvoiceList
        removeCheck={removeCheck}
        setRemoveCheck={setRemoveCheck}
        handleSort={handleSort}
        filtersort={filtersort}
        setViewType={setViewType}
        viewType={viewType}
        filterorder={filterorder}
        allInvoicesData={allInvoicesData}
        dashboardStatus={dashboardStatus}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={allInvoicesData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default Invoices;
