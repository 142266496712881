import axios from "axios";
import * as actionType from "../../constant/actionType";
export const UpdateProviderUserProfile = (data) => async (dispatch) => {
  // Final CSV Data
  try {
const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/UpdateProviderUserProfile`,
    });
    return response.data;
  } catch (error) {}
};
