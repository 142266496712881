import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/ClientPortal/Dashboard";
import ClientContractor from "../pages/ClientPortal/Provider";
import ClientPortalUsers from "../pages/ClientPortal/Users";
import SpecificContractorName from "../pages/ClientPortal/Provider/SpecificContractor";
import ContractorSpecificWorker from "../pages/ClientPortal/Provider/SpecificContractor/Workers/SingleWorker/ContractorSpecificWorker";
import Compliance from "../pages/ClientPortal/Compliance";
import Inductions from "../pages/ClientPortal/Inductions";
import Sites from "../pages/ClientPortal/Sites";
import SpecificSite from "../pages/ClientPortal/SpecificSite/index";
import Settings from "../pages/ClientPortal/Settings/index";
import Contacts from "../pages/ClientPortal/Contacts/index";
import Notes from "../pages/ClientPortal/Notes/Notes";
import Documents from "../pages/ClientPortal/documents/Documents";
import ClientSupport from "../pages/ClientPortal/Support";
import Invoices from "../pages/ClientPortal/Invoices";
import Reporting from "../pages/ClientPortal/Report";
import Messages from "../pages/ClientPortal/Messages";
import AccessNot from "../pages/ClientPortal/NoAccess";
import WorkerDoc from "../pages/ClientPortal/documents/WorkerDoc";
export const ClientPortalRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="provider" element={<ClientContractor />} />
        <Route path="users" element={<ClientPortalUsers />} />
        {/* <Route path="provider/specific-provider" element={<SpecificContractorName />}/> */}
        <Route
          path="provider/specific-provider/:organisationId"
          element={<SpecificContractorName />}
        />
        <Route
          path="provider/specific-worker"
          element={<ContractorSpecificWorker />}
        />
        <Route path="compliance" element={<Compliance />} />
        <Route path="inductions" element={<Inductions />} />
        <Route path="sites" element={<Sites />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="support" element={<ClientSupport />} />
        <Route path="reports" element={<Reporting />} />
        <Route path="noaccess" element={<AccessNot />} />
        <Route path="messages" element={<Messages />} />
        <Route path="sites/specific-site/:siteId" element={<SpecificSite />} />
        <Route path="provider-doc-approval" element={<Documents />} />
        <Route path="worker-doc-approval" element={<WorkerDoc />} />
        <Route path="settings" element={<Settings />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="notes" element={<Notes />} />
      </Routes>
    </React.Fragment>
  );
};
