import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const ViewClientCsv = (CSVData) => async (dispatch) => {
  // Final CSV Data
  try {
    let formdata = new FormData();
    for (let key in CSVData) {
      if (key === "invite_attach") {
        if (CSVData[key] != undefined && CSVData[key] != "") {
          CSVData[key].files.forEach((file, i) => {
            formdata.append(`invite_attach`, file);
          });
        }
      } else if (key == "provider_invite_data") {
        formdata.append(`provider_invite_data`, JSON.stringify(CSVData[key]));
      } else {
        formdata.append(key, CSVData[key]);
      }
    }
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/InviteProviderFromCsv`,
    });
    return response.data;
    // dispatch(fetchDataSuccess());
  } catch (error) {
    return error.message;
    // dispatch(fetchDataError(error.message));
  }
};
