import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Card from "../../../../../common/element/Card";
import Heading from "../../../../../common/element/Heading";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { useLocation, useNavigate } from "react-router-dom";
import * as userActions from "../../../../../actions/index";
import logoImage from "../../../../../assets/logo.svg";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";

const CreatePassword = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [user_id, setUser_id] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hiddenpassword, sethiddenpassword] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const userId = urlSearchParams.get("user_id");
    setUser_id(userId);
  }, []);

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const togglePassword = () => {
    sethiddenpassword(!hiddenpassword);
  };

  const handleRepeatPassword = (event) => {
    setRepeatPassword(event.target.value);
  };

  const handleErrorMessage = () => {
    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );
    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );

    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
    if (!isStrong) {
      return; // If the password is not strong, don't proceed with the submission
    }

    if (!repeatPassword) {
      setPasswordError("To continue, please enter your confirmed password");
      return;
    } else if (newPassword !== repeatPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    const payload = {
      user_id: user_id,
      password: newPassword,
    };

    setButtonDisabled(true);
    props
      ?.onUserResetPassword(payload)
      .then((response) => {
        if (response?.success === true) {
          setTimeout(() => {
            // Redirects to login page,
            navigate("/login");
            setButtonDisabled(false);
          }, 500);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .fincall(() => {
        setTimeout(() => {
          setButtonDisabled(false);
        }, 500);
      });
  };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen pr-6 pl-6"
    >
      <div className="min-h-screen justify-center flex  py-12 px-4">
        <div className="flex-col items-center justify-center flex">
          <div className="flex items-center mb-6">
            <img className="brand-img" src={logoImage} alt="conserve" />
          </div>
          <Heading className="text-center mb-3">Set new password</Heading>
          <p className="text-sm text-gray-600 text-center mb-6">
            Your new password must be different to previously <br />
            used passwords.
          </p>
          <Card className="w-500 rounded-xl   block-shadow w-100 mt-7 px-10 py-8">
            <div className="w-full">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    className="block text-gray-700  mb-2 text-xs font-medium"
                    htmlFor="Email"
                  >
                    Password*
                  </label>
                  <div className="relative flex flex flex-column">
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type={hiddenpassword ? "password" : "text"}
                      placeholder=""
                      value={newPassword}
                      onChange={(event) => handleNewPassword(event)}
                      onBlur={handleErrorMessage}
                    />
                    <span
                      className="absolute right-4 top-3"
                      onClick={togglePassword}
                    >
                      {hiddenpassword ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="flex flex-column">
                    <p className="text-xs text-red-600 mt-2">{passwordError}</p>
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700  mb-2 text-xs font-medium"
                    htmlFor="password"
                  >
                    Confirm Password*
                  </label>
                  <div className="relative flex">
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type={hiddenpassword ? "password" : "text"}
                      placeholder=""
                      value={repeatPassword}
                      onChange={(event) => handleRepeatPassword(event)}
                    />
                    <span
                      className="absolute right-4 top-3"
                      onClick={togglePassword}
                    >
                      {hiddenpassword ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between w-full">
                  <BtnPrimary disabled={buttonDisabled} type="submit">
                    {buttonDisabled ? (
                      <ThreeDotLoader />
                    ) : (
                      <span className="pr-2"> Submit </span>
                    )}
                  </BtnPrimary>
                </div>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onUserResetPassword: userActions.UserResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
