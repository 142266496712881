import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

function ProctedRoute() {
  const navigate = useNavigate();
  const { LoginUpdate } = useSelector((state) => state);
  const { InputLimit } = useSelector((state) => state);
  // Get tokken from local storage
  const storedData = localStorage.getItem("conserve_authtoken");
  const authenticated = localStorage.getItem("Conserve_http_Authenticated");
  useEffect(() => {
    if (!storedData) {
      if (authenticated) {
        navigate("/login");
      } else {
        navigate("/");
      }
    }
  }, [LoginUpdate]);
  return <Outlet />;
}

export default ProctedRoute;
