import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const CreateCompanyInductionForClient =
  (APIData, ipAddress) => async (dispatch) => {
    // Final CSV Data
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      // dispatch(fetchData());
      const response = await axios({
        method: "post",
        data: APIData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/client/CreateCompanyInductionForClient`,
      });
      return response.data;
      // dispatch(fetchDataSuccess(response.data));
    } catch (error) {
      return error.message;
      // dispatch(fetchDataError(error.message));
    }
  };
