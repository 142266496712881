import axios from "axios";
import * as actionType from "../../constant/actionType";
export const AddClientContact = (data) => async (dispatch) => {
  // Final CSV Data

  try {
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/AddClientContact`,
    });

    return response.data;
  } catch (error) {}
};
export const GetAllIndividualListForContact =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetAllIndividualListForContact`,
        params: getAllData,
      });
      return response.data;
    } catch (error) {}
  };
export const GetAllClientContactss = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetAllClientContacts`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const setCompanyIndcutionModule = (moduleData) => {
  return {
    type: actionType.SET_COMPANY_iNDUCTION_MODULE,
    payload: moduleData,
  };
};

export const setFunctionAssignmentUuid = (functionAssignmentId) => {
  return {
    type: actionType.SET_FUNCTION_ASSIGNMENT_ID,
    payload: functionAssignmentId,
  };
};
export const resetFunctionAssignmentUuid = (functionAssignmentId) => {
  return {
    type: actionType.SET_FUNCTION_ASSIGNMENT_ID,
    payload: functionAssignmentId,
  };
};

/*** AddClient Action ***/
export const AddClientStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.AddClient_START,
  };
};
export const AddClientSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.AddClient_SUCCESS,
  };
};
export const AddClientFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.AddClient_FAIL,
  };
};
export const AddClient = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(AddClientStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        "Content-Type": "multipart/form-data",
        userip: ipAddress,
      },

      url: `${actionType.environment_url}/api/admin/client/AddClient`,
    })
      .then(function (response) {
        dispatch(AddClientSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(AddClientFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** GetAllParentClientList Action ***/
export const GetAllParentClientListStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllParentClientList_START,
  };
};
export const GetAllParentClientListSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllParentClientList_SUCCESS,
  };
};
export const GetAllParentClientListFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllParentClientList_FAIL,
  };
};

export const GetAllParentClientList = (functionId) => {
  return (dispatch) => {
    dispatch(GetAllParentClientListStart());
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllParentClientList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        function_id: functionId,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllParentClientListSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllParentClientListFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllServiceTypes Action ***/
export const AdminGetAllClientsStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.AdminGetAllClients_START,
  };
};
export const AdminGetAllClientsSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.AdminGetAllClients_SUCCESS,
  };
};
export const AdminGetAllClientsFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.AdminGetAllClients_FAIL,
  };
};

export const AdminGetAllClients = (
  functionId,
  searchTerm,
  filterpage,
  filterlimit,
  filtersort,
  filterorder
) => {
  return (dispatch) => {
    dispatch(AdminGetAllClientsStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/AdminGetAllClients`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        // contact_type_id: contactTypeId,
        function_id: functionId,
        search: searchTerm,
        limit: filterlimit,
        page: filterpage,
        sort: filtersort,
        order: filterorder,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(AdminGetAllClientsSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          AdminGetAllClientsFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetClientOverviewProfileById Action ***/
export const GetClientOverviewProfileByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetClientOverviewProfileById_START,
  };
};
export const GetClientOverviewProfileByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetClientOverviewProfileById_SUCCESS,
  };
};
export const GetClientOverviewProfileByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetClientOverviewProfileById_FAIL,
  };
};

export const GetClientOverviewProfileById = (
  organisation_id,
  contactTypeId
) => {
  return (dispatch) => {
    dispatch(GetClientOverviewProfileByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetClientOverviewProfileById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        organisation_id: organisation_id,
        contact_type_id: contactTypeId,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetClientOverviewProfileByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetClientOverviewProfileByIdFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** CreateComplianceDoc Action ***/
export const CreateComplianceDocStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateComplianceDoc_START,
  };
};
export const CreateComplianceDocSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateComplianceDoc_SUCCESS,
  };
};
export const CreateComplianceDocFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateComplianceDoc_FAIL,
  };
};

export const CreateComplianceDoc = (form_data) => {
  return (dispatch) => {
    dispatch(CreateComplianceDocStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        "Content-Type": "application/json",
      },

      url: `${actionType.environment_url}/api/admin/client/CreateComplianceDoc`,
    })
      .then(function (response) {
        dispatch(CreateComplianceDocSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          CreateComplianceDocFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

export const CreateComplianceChecklist = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      let arr = [];

      if (key == "docsData") {
        ApiData["docsData"].forEach((item, index) => {
          let tempId = { tempId: index + 1 };
          let updateObj = { ...item, ...tempId };
          arr.push(updateObj);
          if (index + 1 == ApiData["docsData"].length) {
            formdata.append("docsData", JSON.stringify(arr));
          }
          if (item?.is_other_doc) {
            formdata.append(`file_${index + 1}`, item.otherDocFile?.[0]);
          }
        });
      } else {
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateComplianceChecklist`,
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data;
  } catch (error) {
    return error.message;
    // dispatch(fetchDataError(error.message));
  }
};

// Provider list data
export const GetAllProviderTypesList = () => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllProviderTypesList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

export const GetAllComplianceOfClientList = (ApiData) => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllComplianceOfClientList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: ApiData,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

// Provider list data
export const GetComplianceChecklist = (
  client_org_id,
  filtersort,
  filterorder,
  recipientType,
  searchTerm,
  filterlimit,
  filterpage,
  active
) => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllComplianceChecklistOfClient`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        client_org_id: client_org_id,
        sort: filtersort,
        order: filterorder,
        recipient_type: recipientType,
        search: searchTerm,
        limit: filterlimit,
        page: filterpage,
        DetailType: active,
      },
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

export const GetAllComplianceList = (apiData) => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllComplianceList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: apiData,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

// GetComplianceChecklistById
export const GetComplianceChecklistById = (apiData) => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetComplianceChecklistById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: apiData,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

// DeleteComplianceDoc
export const DeleteComplianceDoc = (form_data) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteComplianceDoc`,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        // dispatch(DeleteClientContactFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** GetAllDocumentTypeList Action ***/
export const GetAllDocumentTypeListStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllDocumentTypeList_START,
  };
};
export const GetAllDocumentTypeListSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllDocumentTypeList_SUCCESS,
  };
};
export const GetAllDocumentTypeListFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllDocumentTypeList_FAIL,
  };
};

export const GetAllDocumentTypeList = (recipient_type) => {
  return (dispatch) => {
    dispatch(GetAllDocumentTypeListStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllDocumentTypeList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: recipient_type,
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllDocumentTypeListSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllDocumentTypeListFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllComplianceDocsOfClient Action ***/
export const GetAllComplianceDocsOfClientStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllComplianceDocsOfClient_START,
  };
};
export const GetAllComplianceDocsOfClientSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllComplianceDocsOfClient_SUCCESS,
  };
};
export const GetAllComplianceDocsOfClientFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllComplianceDocsOfClient_FAIL,
  };
};

export const GetAllComplianceDocsOfClient = (
  functionAssignmentId,
  filtersort,
  filterorder,
  recipientType,
  searchTerm,
  filterlimit,
  filterpage
) => {
  return (dispatch) => {
    dispatch(GetAllComplianceDocsOfClientStart());
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllComplianceChecklistOfClient`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        function_assignment_id: functionAssignmentId,
        // is_doctype_swms: selectSWMS,
        sort: filtersort,
        order: filterorder,
        recipient_type: recipientType,
        search: searchTerm,
        limit: filterlimit,
        page: filterpage,
        // order
        // sort
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllComplianceDocsOfClientSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllComplianceDocsOfClientFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** CreateOrgNote Action ***/
export const CreateOrgNoteStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateOrgNote_START,
  };
};
export const CreateOrgNoteSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateOrgNote_SUCCESS,
  };
};
export const CreateOrgNoteFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateOrgNote_FAIL,
  };
};

export const CreateOrgNote = (form_data) => {
  return (dispatch) => {
    dispatch(CreateOrgNoteStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "note_attach") {
        // Check if it's an array of files
        if (Array.isArray(form_data[key].files)) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`note_attach`, file);
          });
        } else {
          // It's a single file
          formdata.append(`note_attach`, form_data[key].files[0]);
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }
    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateOrgNote`,
    })
      .then(function (response) {
        dispatch(CreateOrgNoteSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(CreateOrgNoteFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** GetSpecificOrgNotes Action ***/
export const GetSpecificOrgNotesStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetSpecificOrgNotes_START,
  };
};
export const GetSpecificOrgNotesSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetSpecificOrgNotes_SUCCESS,
  };
};
export const GetSpecificOrgNotesFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetSpecificOrgNotes_FAIL,
  };
};

export const GetSpecificOrgNotes = (functionAssignmentid, filterData) => {
  return (dispatch) => {
    dispatch(GetSpecificOrgNotesStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetSpecificOrgNotes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        function_assignment_id: functionAssignmentid,
        search: filterData.search,
        limit: filterData.limit,
        page: filterData.page,
        order: filterData.order,
        sort: filterData.sort,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetSpecificOrgNotesSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetSpecificOrgNotesFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllProvidersPrimaryList Action ***/
export const GetAllContractorPrimaryListStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllContractorPrimaryList_START,
  };
};
export const GetAllContractorPrimaryListSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllContractorPrimaryList_SUCCESS,
  };
};
export const GetAllContractorPrimaryListFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllContractorPrimaryList_FAIL,
  };
};

export const GetAllProvidersPrimaryList = (
  functionId,
  contact_type_id,
  function_assignment_id,
  search
) => {
  return (dispatch) => {
    dispatch(GetAllContractorPrimaryListStart());
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllProvidersPrimaryList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      params: {
        function_id: functionId,
        contact_type_id: contact_type_id,
        function_assignment_id: function_assignment_id,
        search: search,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllContractorPrimaryListSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllContractorPrimaryListFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** InviteSpecificProvider Action ***/
export const InviteSpecificContractorStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.InviteSpecificContractor_START,
  };
};
export const InviteSpecificContractorSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.InviteSpecificContractor_SUCCESS,
  };
};
export const InviteSpecificContractorFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.InviteSpecificContractor_FAIL,
  };
};

export const InviteSpecificProvider = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(InviteSpecificContractorStart());
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "invite_attach") {
        if (form_data[key] != undefined) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`invite_attach`, file);
          });
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/client/InviteSpecificProvider`,
    })
      .then(function (response) {
        dispatch(InviteSpecificContractorSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          InviteSpecificContractorFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** ReinviteProvider Action ***/
export const ReinviteContractorStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.ReinviteContractor_START,
  };
};
export const ReinviteContractorSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.ReinviteContractor_SUCCESS,
  };
};
export const ReinviteContractorFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.ReinviteContractor_FAIL,
  };
};

export const ReinviteProvider = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(ReinviteContractorStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/client/ReinviteProvider`,
    })
      .then(function (response) {
        dispatch(ReinviteContractorSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          ReinviteContractorFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** AddClientContact Action ***/
export const AddClientContactStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.AddClientContact_START,
  };
};
export const AddClientContactSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.AddClientContact_SUCCESS,
  };
};
export const AddClientContactFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.AddClientContact_FAIL,
  };
};

/*** GetAllInvitedProvidersOfClient Action ***/
export const GetAllInvitedContractorsOfClientStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllInvitedContractorsOfClient_START,
  };
};
export const GetAllInvitedContractorsOfClientSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllInvitedContractorsOfClient_SUCCESS,
  };
};
export const GetAllInvitedContractorsOfClientFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllInvitedContractorsOfClient_FAIL,
  };
};

export const GetAllInvitedProvidersOfClient = (
  functionAssignmentId,
  searchTerm,
  filterpage,
  filterorder,
  filtersort,
  filterlimit
) => {
  return (dispatch) => {
    dispatch(GetAllInvitedContractorsOfClientStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllInvitedProvidersOfClient`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        client_fa_id: functionAssignmentId,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        search: searchTerm,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllInvitedContractorsOfClientSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllInvitedContractorsOfClientFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetAllClientContacts Action ***/
export const GetAllClientContactsStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllClientContacts_START,
  };
};
export const GetAllClientContactsSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllClientContacts_SUCCESS,
  };
};
export const GetAllClientContactsFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllClientContacts_FAIL,
  };
};

export const GetAllClientContacts = (
  organisation_id,
  searchTerm,
  filterpage,
  filterorder,
  filtersort,
  filterlimit
) => {
  return (dispatch) => {
    dispatch(GetAllClientContactsStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllClientContacts`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        organisation_id: organisation_id,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        search: searchTerm,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllClientContactsSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllClientContactsFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetClientContactById Action ***/
export const GetClientContactByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetClientContactById_START,
  };
};
export const GetClientContactByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetClientContactById_SUCCESS,
  };
};
export const GetClientContactByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetClientContactById_FAIL,
  };
};

export const GetClientContactById = (organisation_id, individual_id) => {
  return (dispatch) => {
    dispatch(GetClientContactByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetClientContactById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        organisation_id: organisation_id,
        individual_id: individual_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetClientContactByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetClientContactByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** DeleteClientContact Action ***/
export const DeleteClientContactStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.DeleteClientContact_START,
  };
};
export const DeleteClientContactSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.DeleteClientContact_SUCCESS,
  };
};
export const DeleteClientContactFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.DeleteClientContact_FAIL,
  };
};
export const DeleteClientContact = (form_data) => {
  return (dispatch) => {
    dispatch(DeleteClientContactStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteClientContact`,
    })
      .then(function (response) {
        dispatch(DeleteClientContactSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          DeleteClientContactFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** UpdateClientContact Action ***/
export const UpdateClientContactStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateClientContact_START,
  };
};
export const UpdateClientContactSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateClientContact_SUCCESS,
  };
};
export const UpdateClientContactFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateClientContact_FAIL,
  };
};

export const UpdateClientContact = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateClientContactStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "individual_data") {
        const individualData = form_data[key];
        // Check if individualData is an object
        if (individualData && typeof individualData === "object") {
          // Iterate over individualData properties
          for (let dataKey in individualData) {
            formdata.append(
              `individual_data[${dataKey}]`,
              individualData[dataKey]
            );
          }
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateClientContact`,
    })
      .then(function (response) {
        dispatch(UpdateClientContactSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateClientContactFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllIndividualListForInvite Action ***/
export const GetAllIndividualListForInviteStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllIndividualListForInvite_START,
  };
};
export const GetAllIndividualListForInviteSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllIndividualListForInvite_SUCCESS,
  };
};
export const GetAllIndividualListForInviteFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllIndividualListForInvite_FAIL,
  };
};

export const GetAllIndividualListForInvite = (search) => {
  return (dispatch) => {
    dispatch(GetAllIndividualListForInviteStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllIndividualListForInvite`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        search: search,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllIndividualListForInviteSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllIndividualListForInviteFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** InviteOrgUser Action ***/
export const InviteOrgUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.InviteOrgUser_START,
  };
};
export const InviteOrgUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.InviteOrgUser_SUCCESS,
  };
};
export const InviteOrgUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.InviteOrgUser_FAIL,
  };
};

export const InviteOrgUser = (form_data) => {
  return (dispatch) => {
    dispatch(InviteOrgUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/InviteOrgUser`,
    })
      .then(function (response) {
        dispatch(InviteOrgUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(InviteOrgUserFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** GetAllSpecificOrgUsers Action ***/
export const GetAllSpecificOrgUsersStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllSpecificOrgUsers_START,
  };
};
export const GetAllSpecificOrgUsersSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllSpecificOrgUsers_SUCCESS,
  };
};
export const GetAllSpecificOrgUsersFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllSpecificOrgUsers_FAIL,
  };
};

export const GetAllSpecificOrgUsers = (organisation_id) => {
  return (dispatch) => {
    dispatch(GetAllSpecificOrgUsersStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllSpecificOrgUsers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        organisation_id: organisation_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllSpecificOrgUsersSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllSpecificOrgUsersFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllSpecificOrgInvites Action ***/
export const GetAllSpecificOrgInvitesStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllSpecificOrgInvites_START,
  };
};
export const GetAllSpecificOrgInvitesSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllSpecificOrgInvites_SUCCESS,
  };
};
export const GetAllSpecificOrgInvitesFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllSpecificOrgInvites_FAIL,
  };
};

export const GetAllSpecificOrgInvites = (
  function_assignment_id,
  invited_user_type
) => {
  return (dispatch) => {
    dispatch(GetAllSpecificOrgInvitesStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllSpecificOrgInvites`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        function_assignment_id: function_assignment_id,
        invited_user_type: invited_user_type,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllSpecificOrgInvitesSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllSpecificOrgInvitesFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** ReinviteOrgUser Action ***/
export const ReinviteOrgUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.ReinviteOrgUser_START,
  };
};
export const ReinviteOrgUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.ReinviteOrgUser_SUCCESS,
  };
};
export const ReinviteOrgUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.ReinviteOrgUser_FAIL,
  };
};
export const ReinviteOrgUser = (form_data) => {
  return (dispatch) => {
    dispatch(ReinviteOrgUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/ReinviteOrgUser`,
    })
      .then(function (response) {
        dispatch(ReinviteOrgUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          ReinviteOrgUserFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetSpecificClientDetailsById Action ***/
export const GetSpecificClientDetailsByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetSpecificClientDetailsById_START,
  };
};
export const GetSpecificClientDetailsByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetSpecificClientDetailsById_SUCCESS,
  };
};
export const GetSpecificClientDetailsByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetSpecificClientDetailsById_FAIL,
  };
};

export const GetSpecificClientDetailsById = (organisation_id) => {
  return (dispatch) => {
    dispatch(GetSpecificClientDetailsByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetSpecificClientDetailsById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        organisation_id: organisation_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetSpecificClientDetailsByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetSpecificClientDetailsByIdFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** UpdateClientDetails Action ***/
export const UpdateClientDetailsStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateClientDetails_START,
  };
};
export const UpdateClientDetailsSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateClientDetails_SUCCESS,
  };
};
export const UpdateClientDetailsFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateClientDetails_FAIL,
  };
};
export const UpdateClientDetails = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateClientDetailsStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateClientDetails`,
    })
      .then(function (response) {
        dispatch(UpdateClientDetailsSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateClientDetailsFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** UpdateOrgStatus Action ***/
export const UpdateOrgStatusStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateOrgStatus_START,
  };
};
export const UpdateOrgStatusSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateOrgStatus_SUCCESS,
  };
};
export const UpdateOrgStatusFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateOrgStatus_FAIL,
  };
};
export const UpdateOrgStatus = (form_data, userip) => {
  return (dispatch) => {
    dispatch(UpdateOrgStatusStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: userip,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateOrgStatus`,
    })
      .then(function (response) {
        dispatch(UpdateOrgStatusSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateOrgStatusFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetClientOtherDetailsById Action ***/
export const GetClientOtherDetailsByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetClientOtherDetailsById_START,
  };
};
export const GetClientOtherDetailsByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetClientOtherDetailsById_SUCCESS,
  };
};
export const GetClientOtherDetailsByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetClientOtherDetailsById_FAIL,
  };
};

export const GetClientOtherDetailsById = (organisation_id) => {
  return (dispatch) => {
    dispatch(GetClientOtherDetailsByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetClientOtherDetailsById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        organisation_id: organisation_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetClientOtherDetailsByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetClientOtherDetailsByIdFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** UpdateClientAccreditations Action ***/
export const UpdateClientAccreditationsStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateClientAccreditations_START,
  };
};
export const UpdateClientAccreditationsSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateClientAccreditations_SUCCESS,
  };
};
export const UpdateClientAccreditationsFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateClientAccreditations_FAIL,
  };
};
export const UpdateClientAccreditations = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateClientAccreditationsStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateClientAccreditations`,
    })
      .then(function (response) {
        dispatch(UpdateClientAccreditationsSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateClientAccreditationsFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};
// Update Compliance Checklist
export const UpdateComplianceChecklist = (paramsValue) => async (dispatch) => {
  try {
    const response = await axios({
      method: "patch",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateComplianceChecklist`,
      data: paramsValue,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const ArchiveChecklist = (form_data) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/ArchiveChecklist`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to archive checklist:", error);
        throw error;
      });
  };
};
export const ArchiveComplianceDoc = (form_data) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/ArchiveComplianceDoc`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to archive checklist:", error);
        throw error;
      });
  };
};
export const AddChecklistDoc = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      formdata.append(key, ApiData[key]);
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/AddChecklistDoc`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

/*** UpdateComplianceChecklistReviewDate Action ***/
export const UpdateComplianceChecklistReviewDateStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateComplianceChecklistReviewDate_START,
  };
};
export const UpdateComplianceChecklistReviewDateSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateComplianceChecklistReviewDate_SUCCESS,
  };
};
export const UpdateComplianceChecklistReviewDateFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateComplianceChecklistReviewDate_FAIL,
  };
};
export const UpdateComplianceChecklistReviewDate = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateComplianceChecklistReviewDateStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateComplianceChecklistReviewDate`,
    })
      .then(function (response) {
        dispatch(UpdateComplianceChecklistReviewDateSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateComplianceChecklistReviewDateFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetOrgUserPermById Action ***/
export const GetOrgUserPermByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetOrgUserPermById_START,
  };
};
export const GetOrgUserPermByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetOrgUserPermById_SUCCESS,
  };
};
export const GetOrgUserPermByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetOrgUserPermById_FAIL,
  };
};

export const GetOrgUserPermById = (user_id, function_assignment_id) => {
  return (dispatch) => {
    dispatch(GetOrgUserPermByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetOrgUserPermById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        user_id: user_id,
        function_assignment_id: function_assignment_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetOrgUserPermByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetOrgUserPermByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** UpdateOrgUserPerm Action ***/
export const UpdateOrgUserPermStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateOrgUserPerm_START,
  };
};
export const UpdateOrgUserPermSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateOrgUserPerm_SUCCESS,
  };
};
export const UpdateOrgUserPermFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateOrgUserPerm_FAIL,
  };
};
export const UpdateOrgUserPerm = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateOrgUserPermStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateOrgUserPerm`,
    })
      .then(function (response) {
        dispatch(UpdateOrgUserPermSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateOrgUserPermFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** CreateCompanyInductionForClient Action ***/
export const CreateCompanyInductionForClientStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateCompanyInductionForClient_START,
  };
};
export const CreateCompanyInductionForClientSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateCompanyInductionForClient_SUCCESS,
  };
};
export const CreateCompanyInductionForClientFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateCompanyInductionForClient_FAIL,
  };
};
export const CreateCompanyInductionForClient = (form_data) => {
  return (dispatch) => {
    dispatch(CreateCompanyInductionForClientStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateCompanyInductionForClient`,
    })
      .then(function (response) {
        dispatch(CreateCompanyInductionForClientSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          CreateCompanyInductionForClientFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** CreateCompanyInductionModule Action ***/
export const CreateCompanyInductionModuleStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateCompanyInductionModule_START,
  };
};
export const CreateCompanyInductionModuleSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateCompanyInductionModule_SUCCESS,
  };
};
export const CreateCompanyInductionModuleFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateCompanyInductionModule_FAIL,
  };
};
export const CreateCompanyInductionModule = (form_data) => {
  return (dispatch) => {
    dispatch(CreateCompanyInductionModuleStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "create",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateCompanyInductionModule`,
    })
      .then(function (response) {
        dispatch(CreateCompanyInductionModuleSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          CreateCompanyInductionModuleFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetAllSpecificEmailTemplates Action ***/
export const GetAllEmailTemplateForClientsEmailStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllEmailTemplateForClientsEmail_START,
  };
};
export const GetAllEmailTemplateForClientsEmailSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllEmailTemplateForClientsEmail_SUCCESS,
  };
};
export const GetAllEmailTemplateForClientsEmailFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllEmailTemplateForClientsEmail_FAIL,
  };
};

export const GetAllSpecificEmailTemplates = (templateType) => {
  return (dispatch) => {
    dispatch(GetAllEmailTemplateForClientsEmailStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllSpecificEmailTemplates`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        template_type: templateType,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllEmailTemplateForClientsEmailSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllEmailTemplateForClientsEmailFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetAllIndividualForEmail Action ***/
export const GetClientIndividualForEmailStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetClientIndividualForEmail_START,
  };
};
export const GetClientIndividualForEmailSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetClientIndividualForEmail_SUCCESS,
  };
};
export const GetClientIndividualForEmailFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetClientIndividualForEmail_FAIL,
  };
};

export const GetAllIndividualForEmail = (search) => {
  return (dispatch) => {
    dispatch(GetClientIndividualForEmailStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllIndividualForEmail`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        search: search,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetClientIndividualForEmailSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetClientIndividualForEmailFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** EmailToIndividual Action ***/
export const EmailToClientIndividualStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.EmailToClientIndividual_START,
  };
};
export const EmailToClientIndividualSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.EmailToClientIndividual_SUCCESS,
  };
};
export const EmailToClientIndividualFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.EmailToClientIndividual_FAIL,
  };
};
export const EmailToIndividual = (form_data) => {
  return (dispatch) => {
    dispatch(EmailToClientIndividualStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "email_doc") {
        if (form_data[key]) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`email_doc`, file);
          });
        }
      } else if (key === "email_attach_docs") {
        if (form_data[key]) {
          if (form_data[key].length === 1) {
            formdata.append(`email_attach_docs[]`, [form_data[key][0]]);
          } else {
            if (form_data[key].length != 0) {
              form_data[key].forEach((file, i) => {
                formdata.append(`email_attach_docs`, file);
              });
            } else {
              formdata.append(`email_attach_docs`, [[][0]]);
            }
          }
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/EmailToIndividual`,
    })
      .then(function (response) {
        dispatch(EmailToClientIndividualSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          EmailToClientIndividualFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** CreateSite Action ***/
export const CreateSiteStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateSite_START,
  };
};
export const CreateSiteSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateSite_SUCCESS,
  };
};
export const CreateSiteFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateSite_FAIL,
  };
};

export const CreateSite = (form_data) => {
  return (dispatch) => {
    dispatch(CreateSiteStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "evacuation_diagram") {
        if (form_data[key]) {
          form_data[key].forEach((file, i) => {
            formdata.append(`evacuation_diagram`, file);
          });
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateSite`,
    })
      .then(function (response) {
        dispatch(CreateSiteSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(CreateSiteFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** GetClientSiteById Action ***/
export const GetClientSiteByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetClientSiteById_START,
  };
};
export const GetClientSiteByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetClientSiteById_SUCCESS,
  };
};
export const GetClientSiteByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetClientSiteById_FAIL,
  };
};

export const GetClientSiteById = (siteId, id) => {
  return (dispatch) => {
    dispatch(GetClientSiteByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetClientSiteById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        site_id: siteId,
        client_org_id: id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetClientSiteByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetClientSiteByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** RemoveClientSiteById Action ***/
export const RemoveClientSiteByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.RemoveClientSiteById_START,
  };
};
export const RemoveClientSiteByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.RemoveClientSiteById_SUCCESS,
  };
};
export const RemoveClientSiteByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.RemoveClientSiteById_FAIL,
  };
};
export const RemoveClientSiteById = (form_data) => {
  return (dispatch) => {
    dispatch(RemoveClientSiteByIdStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/RemoveClientSiteById`,
    })
      .then(function (response) {
        dispatch(RemoveClientSiteByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          RemoveClientSiteByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllSitesOfClient Action ***/
export const GetAllSitesOfClientStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllSitesOfClient_START,
  };
};
export const GetAllSitesOfClientSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllSitesOfClient_SUCCESS,
  };
};
export const GetAllSitesOfClientFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllSitesOfClient_FAIL,
  };
};

export const GetAllSitesOfClient = (functionAssignmentId, filterData) => {
  return (dispatch) => {
    dispatch(GetAllSitesOfClientStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAllSitesOfClient`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        function_assignment_id: functionAssignmentId,
        page: filterData.page,
        limit: filterData.limit,
        sort: filterData.sort,
        order: filterData.order,
        search: filterData.search,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllSitesOfClientSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllSitesOfClientFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** UpdateClientSite Action ***/
export const UpdateClientSiteStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateClientSite_START,
  };
};
export const UpdateClientSiteSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateClientSite_SUCCESS,
  };
};
export const UpdateClientSiteFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateClientSite_FAIL,
  };
};
export const UpdateClientSite = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(UpdateClientSiteStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "evacuation_diagram") {
        if (form_data[key] && Array.isArray(form_data[key].files)) {
          form_data[key].files.forEach((file) => {
            formdata.append("evacuation_diagram", file);
          });
        }
      } else if (key === "evacuation_diagramUrl") {
        formdata.append("evacuation_diagram", JSON.stringify(form_data[key]));
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress, // Add IP address here
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateClientSite`,
    })
      .then((response) => {
        dispatch(UpdateClientSiteSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(
          UpdateClientSiteFail("Something went wrong, Please try again.")
        );
        return error.message;
      });
  };
};

/*** DeleteSiteEvacuationDiagramById Action ***/
export const DeleteSiteEvacuationDiagramByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.DeleteSiteEvacuationDiagramById_START,
  };
};
export const DeleteSiteEvacuationDiagramByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.DeleteSiteEvacuationDiagramById_SUCCESS,
  };
};
export const DeleteSiteEvacuationDiagramByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.DeleteSiteEvacuationDiagramById_FAIL,
  };
};
export const DeleteSiteEvacuationDiagramById = (form_data) => {
  return (dispatch) => {
    dispatch(DeleteSiteEvacuationDiagramByIdStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "evacuation_diagram") {
        if (form_data[key].length !== 0) {
          formdata.append(`evacuation_diagram`, JSON.stringify(form_data[key]));
        } else if (form_data[key].length === 0) {
          formdata.append(`evacuation_diagram`, JSON.stringify(form_data[key]));
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSiteEvacuationDiagramById`,
    })
      .then(function (response) {
        dispatch(DeleteSiteEvacuationDiagramByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          DeleteSiteEvacuationDiagramByIdFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetAlreadyExistingOrgCheck Action ***/
export const GetAlreadyExistingOrgCheckStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAlreadyExistingOrgCheck_START,
  };
};
export const GetAlreadyExistingOrgCheckSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAlreadyExistingOrgCheck_SUCCESS,
  };
};
export const GetAlreadyExistingOrgCheckFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAlreadyExistingOrgCheck_FAIL,
  };
};

export const GetAlreadyExistingOrgCheck = (
  trading_name,
  abn_nzbn,
  organisation_id
) => {
  return (dispatch) => {
    dispatch(GetAlreadyExistingOrgCheckStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetAlreadyExistingOrgCheck`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        trading_name: trading_name,
        abn_nzbn: abn_nzbn,
        organisation_id: organisation_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAlreadyExistingOrgCheckSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAlreadyExistingOrgCheckFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetCompDocsListOfWorkersType Action ***/
export const GetCompDocsListOfWorkerTypeStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetCompDocsListOfWorkerType_START,
  };
};
export const GetCompDocsListOfWorkerTypeSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetCompDocsListOfWorkerType_SUCCESS,
  };
};
export const GetCompDocsListOfWorkerTypeFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetCompDocsListOfWorkerType_FAIL,
  };
};

export const GetCompDocsListOfWorkerType = (function_assignment_id, search) => {
  return (dispatch) => {
    dispatch(GetCompDocsListOfWorkerTypeStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/client/GetCompDocsListOfWorkerType`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        function_assignment_id: function_assignment_id,
        search: search,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetCompDocsListOfWorkerTypeSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetCompDocsListOfWorkerTypeFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** UpdateSiteActiveStatus Action ***/
export const UpdateSiteActiveStatusStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateSiteActiveStatus_START,
  };
};
export const UpdateSiteActiveStatusSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateSiteActiveStatus_SUCCESS,
  };
};
export const UpdateSiteActiveStatusFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateSiteActiveStatus_FAIL,
  };
};
export const UpdateSiteActiveStatus = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateSiteActiveStatusStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateSiteActiveStatus`,
    })
      .then(function (response) {
        dispatch(UpdateSiteActiveStatusSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateSiteActiveStatusFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** RemoveOrgUserInvitation Action ***/
export const RemoveOrgUserInvitationStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.RemoveOrgUserInvitation_START,
  };
};
export const RemoveOrgUserInvitationSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.RemoveOrgUserInvitation_SUCCESS,
  };
};
export const RemoveOrgUserInvitationFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.RemoveOrgUserInvitation_FAIL,
  };
};
export const RemoveOrgUserInvitation = (form_data) => {
  return (dispatch) => {
    dispatch(RemoveOrgUserInvitationStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/RemoveOrgUserInvitation`,
    })
      .then(function (response) {
        dispatch(RemoveOrgUserInvitationSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          RemoveOrgUserInvitationFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** RemoveClientUser Action ***/
export const RemoveClientUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.RemoveClientUser_START,
  };
};
export const RemoveClientUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.RemoveClientUser_SUCCESS,
  };
};
export const RemoveClientUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.RemoveClientUser_FAIL,
  };
};
export const RemoveClientUser = (form_data) => {
  return (dispatch) => {
    dispatch(RemoveClientUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/RemoveClientUser`,
    })
      .then(function (response) {
        dispatch(RemoveClientUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          RemoveClientUserFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};
// RemoveOrgUser
export const RemoveOrgUser = (ApiData) => {
  return (dispatch) => {
    return axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/RemoveOrgUser`,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return;
      });
  };
};
