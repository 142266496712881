import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Heading from "../../../../../../common/element/Heading";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import EmailLogList from "./EmailLogList";
import SearchImg from "../../../../../../assets/icons-svg/search-lg.svg";
import * as userActions from "../../../../../../actions/index";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import ReactPaginate from "react-paginate";
import DatePicker from "../../../../../../common/element/DatePicker";
import Table from "../../../../../../common/element/Table";
import moment from "moment";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
import Dialog from "../../../../../../common/element/Dialog";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Label from "../../../../../../common/element/Label";
import {
  GetAllEmailSenderList,
  GetAllEmailRecipientList,
  GetAllUsedEmailTemplatesList,
} from "../../../../../../actions/Admin";
import Dropdown from "../../../../../../common/element/Dropdown";
import Validation from "../../../../../../common/404/Validations";
import Select from "../../../../../../common/element/Select";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import { isSameDay, locale, format } from "date-fns";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "active" : ""} h-5 w-5 transition-transform`}
    >
      <path
        d="M14.5992 6V22.8M6.19922 14.4H22.9992"
        stroke="#5786CC"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.19922 14.4004H22.9992"
        stroke="#5786CC"
        stroke-width="2.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
const EmailLog = () => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [filtersort, setFiltersort] = useState("created_date");
  const [filterorder, setFilterOrder] = useState("desc");
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [countData, setCountData] = useState(0);
  const [loadData, setLoadData] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [key, setKey] = useState(0);
  const [sectionOption, setSectionOption] = useState();
  const [emailRecipOption, setEmailRecipOption] = useState();
  const [templateOption, setTemplateOption] = useState();
  const [filterData, setFilterData] = useState({
    recipientEmail: "",
    created_by: "",
  });
  const [templateFilter, setTemplateFilter] = useState({
    recipientEmail: "",
    template_name: "",
    email_template_id: "",
  });
  const [error, setError] = useState({ start_Date: "", end_Date: "" });
  const [iconSvg, setIconSvg] = useState(false);
  const [advanceData, setAdvanceData] = useState({
    created_by: "",
    recipientEmail: "",
    email_template_id: "",
    start_Date: "",
    end_Date: "",
  });

  // Start React-Date-Range
  const [customRangeActive, setCustomRangeActive] = useState(false);
  const [disabledDate, setDisabedDate] = useState(false);
  const [date, setDate] = useState([
    { startDate: null, endDate: null, key: "custom" },
  ]);
  const [dateMinMax, setDateMinMax] = useState({
    lowestDate: null,
    greatestDate: null,
  });

  const handleDateRangeChange = (ranges) => {
    const selectedRange = ranges[date[0]?.key];
    setDate([selectedRange]);
    if (selectedRange.key === "custom") {
      const { startDate, endDate } = selectedRange;
      if (startDate == null && endDate == null) {
        setCustomRangeActive(true);
      } else {
        setCustomRangeActive(false);
      }
      if (startDate && endDate) {
        setError({ ...error, start_Date: "", end_Date: "" });
      }
    } else {
      setCustomRangeActive(false);
      setError({ ...error, start_Date: "", end_Date: "" });
    }
  };

  const staticRanges = [
    {
      label: "Today",
      range: () => {
        const startDate = new Date();
        const endDate = new Date();
        return { startDate, endDate, key: "today" };
      },
      isSelected: (range) =>
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, new Date()) &&
        range.key == "today",
    },
    {
      label: "Yesterday",
      range: () => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date(endDate);
        return { startDate, endDate, key: "yesterday" };
      },
      isSelected: (range) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return (
          isSameDay(range.startDate, yesterday) &&
          isSameDay(range.endDate, yesterday) &&
          range.key == "yesterday"
        );
      },
    },
    {
      label: "Last 7 days",
      range: () => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 6); // Subtract 6 days from endDate
        return { startDate, endDate, key: "last7Days" };
      },
      isSelected: (range) => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const today = new Date(endDate);
        const last7Days = new Date(today.setDate(today.getDate() - 6));
        return (
          isSameDay(range.startDate, last7Days) &&
          isSameDay(range.endDate, endDate) &&
          range.key == "last7Days"
        );
      },
    },
    {
      label: "Last 30 days",
      range: () => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 29); // Subtract 29 days from endDate
        return { startDate, endDate, key: "last30Days" };
      },
      isSelected: (range) => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const today = new Date(endDate);
        const last30Days = new Date(today.setDate(today.getDate() - 29));
        return (
          isSameDay(range.startDate, last30Days) &&
          isSameDay(range.endDate, endDate) &&
          range.key == "last30Days"
        );
      },
    },
    {
      label: "Last 12 months",
      range: () => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1); // Subtract 1 year from endDate
        return { startDate, endDate, key: "last12Months" };
      },
      isSelected: (range) => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const today = new Date(endDate);
        const last12Months = new Date();
        last12Months.setFullYear(last12Months.getFullYear() - 1);
        return (
          isSameDay(range.startDate, last12Months) &&
          isSameDay(range.endDate, endDate) &&
          range.key == "last12Months"
        );
      },
    },
    {
      label: "All time",
      range: () => {
        const startDate = dateMinMax.lowestDate;
        const endDate = new Date();
        return { startDate, endDate, key: "allTime" };
      },
      isSelected: (range) =>
        isSameDay(range.startDate, dateMinMax.lowestDate) &&
        isSameDay(range.endDate, new Date()) &&
        range.key === "allTime",
    },

    // Custom Range
    {
      label: "Custom range",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
        key: "custom",
      }),
      isSelected: (range) => {
        return (
          range.key === "custom" &&
          (range.startDate !== null || range.endDate !== null)
        );
      },
      className:
        customRangeActive &&
        (date[0]?.startDate !== null || date[0]?.endDate !== null)
          ? "isActiveDate"
          : "",
    },
  ];

  // Define the isDayDisabled function
  function isDayDisabled(day) {
    if (date[0]?.key !== "custom") {
      setDisabedDate(true);
      return day.getDay() || day.getDay() === 0;
    } else {
      setDisabedDate(false);
    }
  }
  // End React-Date-Range

  // useRef for Empty Date and search input
  // const inputRef = useRef(null);
  // const clearInput = () => {
  //   inputRef.current.value = '';
  // };
  //set dropdown on active
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      iconSvg
    ) {
      setIconSvg(false);
      setDate([{ startDate: null, endDate: null, key: "custom" }]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  // set breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Email Logs",
          url: "/admin/email-log",
          home: "/admin/dashboard",
        },
      ])
    );
  }, []);

  // handle search filter
  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
  };

  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  //Api call for GetallEmailLogs
  const GetallEmailLogs = useCallback(async () => {
    dispatch(
      userActions.GetallEmailLogs(
        filtersort,
        filterorder,
        filterpage,
        filterlimit,
        searchTerm,
        advanceData?.created_by,
        advanceData?.recipientEmail,
        advanceData?.email_template_id,
        advanceData?.start_Date,
        advanceData?.end_Date
      )
    ).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data?.rows;
        const timestamps = arrayData.map((product) =>
          new Date(product.created_date).getTime()
        );
        const lowestDate = new Date(Math.min(...timestamps));
        const greatestDate = new Date(Math.max(...timestamps));
        setDateMinMax({ lowestDate, greatestDate });
        setCountData(response?.data?.count);
        setLoadData(true);
        let emailLog = [];
        for (let key in arrayData) {
          const emailDate = moment(arrayData[key]?.created_date).format(
            "HH:mm DD/MM/YY"
          );
          emailLog.push({
            createdDate: emailDate,
            emailSubject: arrayData[key]?.email_subject,
            recipient: arrayData[key]?.email_to,
            sender:
              arrayData[key]?.first_name + " " + arrayData[key]?.last_name,
            templateName: arrayData[key]?.template_name,
            emailId: arrayData[key]?.email_id,
          });
        }
        setEmailData(emailLog);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (response?.status === 401) {
        setLoadData(true);
        handleUnauthorizedAccess();
      } else {
        setLoadData(true);
        // Handle other cases if needed
      }
    });
  }, [
    dispatch,
    filtersort,
    filterorder,
    filterpage,
    filterlimit,
    searchTerm,
    advanceData?.created_by,
    advanceData?.recipientEmail,
    advanceData?.email_template_id,
    advanceData?.start_Date,
    advanceData?.end_Date,
  ]);
  let newSearchTerm = "";
  let hasAdvanceData;
  useEffect(() => {
    GetallEmailLogs(
      filtersort,
      filterorder,
      filterpage,
      filterlimit,
      searchTerm,
      advanceData?.created_by,
      advanceData?.recipientEmail,
      advanceData?.email_template_id,
      advanceData?.start_Date,
      advanceData?.end_Date
    );
  }, [
    filtersort,
    filterorder,
    filterpage,
    filterlimit,
    searchTerm,
    advanceData,
  ]);

  // Handle advance filter toggle
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
    setIconSvg((prevState) => !prevState);
  };
  const handleOpenDate = (value) => {
    setOpen(open === value ? 0 : value);

    if (open !== 0 && open !== 1) {
      setDate([{ startDate: new Date(), endDate: new Date(), key: "today" }]);
    } else {
      if (open == 0) {
        setDate([{ startDate: new Date(), endDate: new Date(), key: "today" }]);
      }
      if (open == 1) {
        setDate([{ startDate: null, endDate: null, key: "custom" }]);
      }
    }
  };

  const Toggle = (
    <BtnLight
      onClick={handleOpen}
      className="h-10 w-40 flex items-center justify-center gap-2 text-12"
    >
      Advanced search
      {iconSvg ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.82125 4.72239C2.19097 4.01796 1.87583 3.66574 1.86394 3.3664C1.85361 3.10636 1.96536 2.85643 2.16603 2.69074C2.39704 2.5 2.86966 2.5 3.81491 2.5H16.184C17.1293 2.5 17.6019 2.5 17.8329 2.69074C18.0336 2.85643 18.1453 3.10636 18.135 3.3664C18.1231 3.66574 17.808 4.01796 17.1777 4.72239L12.4225 10.037C12.2968 10.1774 12.234 10.2477 12.1892 10.3276C12.1495 10.3984 12.1203 10.4747 12.1027 10.554C12.0828 10.6435 12.0828 10.7377 12.0828 10.9261V15.382C12.0828 15.5449 12.0828 15.6264 12.0565 15.6969C12.0333 15.7591 11.9955 15.8149 11.9463 15.8596C11.8907 15.9102 11.815 15.9404 11.6637 16.001L8.83039 17.1343C8.5241 17.2568 8.37096 17.3181 8.24802 17.2925C8.14052 17.2702 8.04617 17.2063 7.98551 17.1148C7.91613 17.0101 7.91613 16.8452 7.91613 16.5153V10.9261C7.91613 10.7377 7.91613 10.6435 7.89623 10.554C7.87858 10.4747 7.84943 10.3984 7.8097 10.3276C7.76491 10.2477 7.70209 10.1774 7.57645 10.037L2.82125 4.72239Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 3.66602L11.5 3.66602M11.5 3.66602C11.5 5.04673 12.6193 6.16602 14 6.16602C15.3807 6.16602 16.5 5.04673 16.5 3.66602C16.5 2.2853 15.3807 1.16602 14 1.16602C12.6193 1.16602 11.5 2.2853 11.5 3.66602ZM6.5 10.3327L16.5 10.3327M6.5 10.3327C6.5 11.7134 5.38071 12.8327 4 12.8327C2.61929 12.8327 1.5 11.7134 1.5 10.3327C1.5 8.95197 2.61929 7.83268 4 7.83268C5.38071 7.83268 6.5 8.95197 6.5 10.3327Z"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </BtnLight>
  );

  // useEffect for GetAllEmailSeapplyFilterHandlernderList api for set data in advance filter
  useEffect(() => {
    if (open && (sectionOption == undefined || sectionOption.length == 0)) {
      dispatch(GetAllEmailSenderList(filterData?.recipientEmail)).then(
        (res) => {
          if (res?.success === true) {
            const senderOptions = res?.data?.map((sender) => ({
              value: sender?.sender_email,
              label: sender?.sender_email,
              created_by: sender?.created_by,
            }));
            setSectionOption(senderOptions);
          } else if (res?.status === 401) {
            handleUnauthorizedAccess();
          } else {
            // Handle other cases if needed
          }
        }
      );
      dispatch(GetAllEmailRecipientList(filterData?.created_by)).then((res) => {
        if (res?.success === true) {
          const recipientOptions = res?.data?.map((recipient) => ({
            value: recipient,
            label: recipient,
          }));
          setEmailRecipOption(recipientOptions);
        } else if (res?.status === 401) {
          handleUnauthorizedAccess();
        } else {
          // Handle other cases if needed
        }
      });
      dispatch(
        GetAllUsedEmailTemplatesList({
          created_by: filterData?.created_by,
          recipientEmail: templateFilter.recipientEmail,
        })
      ).then((res) => {
        if (res?.success === true) {
          const templateOptions = res?.data?.map((template) => ({
            value: template?.template_name,
            label: template?.template_name,
            template_id: template?.email_template_id,
          }));
          setTemplateOption(templateOptions);
        } else if (res?.status === 401) {
          handleUnauthorizedAccess();
        } else {
          // Handle other cases if needed
        }
      });
    }
  }, [open]);

  // useEffect for GetAllEmailSenderList
  useEffect(() => {
    if (filterData?.recipientEmail != "" || filterData?.created_by != "") {
      dispatch(GetAllEmailSenderList(filterData?.recipientEmail)).then(
        (res) => {
          if (res?.success === true) {
          } else if (res?.status === 401) {
            handleUnauthorizedAccess();
          } else {
            // Handle other cases if needed
          }
        }
      );
    }
  }, [filterData?.recipientEmail]);

  // useEffect for GetAllEmailRecipientList
  useEffect(() => {
    setTemplateFilter({
      recipientEmail: "",
      template_name: "",
      email_template_id: "",
    });
    dispatch(GetAllEmailRecipientList(filterData?.created_by)).then((res) => {
      if (res?.success === true) {
        const recipientOptions = res?.data?.map((recipient) => ({
          value: recipient,
          label: recipient,
        }));
        setEmailRecipOption(recipientOptions);
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other cases if needed
      }
    });
  }, [filterData?.created_by, filterData?.recipientEmail]);

  // useEffect for GetAllEmailRecipientList and GetAllUsedEmailTemplatesList
  useEffect(() => {
    dispatch(
      GetAllUsedEmailTemplatesList({
        created_by: filterData?.created_by,
        recipientEmail: templateFilter.recipientEmail,
      })
    ).then((res) => {
      if (res?.success === true) {
        const templateOptions = res?.data?.map((template) => ({
          value: template?.template_name,
          label: template?.template_name,
          template_id: template?.email_template_id,
        }));
        setTemplateOption(templateOptions);
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other cases if needed
      }
    });
  }, [filterData?.created_by, templateFilter.recipientEmail]);

  // Select Sender for filter
  const handleSelection = useCallback(
    (selectedOption, property) => {
      setFilterData((prevState) => {
        // Only update the state if the selected value is different from the current value
        if (prevState[property] !== selectedOption.value) {
          // Find the sender object corresponding to the selected sender email
          const selectedSender = sectionOption.find(
            (sender) => sender.value === selectedOption.value
          );
          // Retrieve the created_by value from the selected sender object
          const created_by = selectedSender ? selectedSender.created_by : "";

          return {
            ...prevState,
            [property]: selectedOption.value,
            created_by: created_by,
          };
        }
        setTemplateFilter({
          ...prevState,
          recipientEmail: "",
          email_template_id: "",
        });
        setFilterData({ ...filterData, recipientEmail: "" });
        return prevState;
      });
    },
    [sectionOption]
  );

  // Selection Recipient for filter
  const handleSelectionRecipt = useCallback(
    (selectedOption, property) => {
      setTemplateFilter((prevState) => {
        // Only update the state if the selected value is different from the current value
        if (prevState[property] !== selectedOption.value) {
          return { ...prevState, [property]: selectedOption.value };
        }
        return prevState;
      });
    },
    [emailRecipOption]
  );

  // Select Templates for filter
  const handleSelectionTemplate = useCallback(
    (selectedOption, property) => {
      setTemplateFilter((prevState) => {
        // Only update the state if the selected value is different from the current value
        if (prevState[property] !== selectedOption.value) {
          const selectedTemplate = templateOption.filter(
            (option) => option.template_id === selectedOption.template_id
          )[0];
          // Retrieve the email_template_id value from the selected template object
          const email_template_id = selectedTemplate
            ? selectedTemplate.template_id
            : "";

          return {
            ...prevState,
            [property]: selectedOption.value,
            email_template_id: email_template_id,
          };
        }
        return prevState;
      });
    },
    [templateOption]
  );

  //Submit applyFilterHandler
  const applyFilterHandler = (e) => {
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
    e.preventDefault();
    let startDate = date[0]?.startDate;
    startDate = startDate == null ? "" : date[0]?.startDate;
    let endDate = date[0]?.endDate;
    if (endDate == null) {
      endDate = "";
    } else {
      if (isNaN(endDate.getTime())) {
        endDate = "";
      }
    }
    // Validation for date

    if (startDate !== "" || endDate !== "") {
      const stDateValidation = Validation({
        value: startDate,
        Validations: { required: true },
      });
      const edDateValidation = Validation({
        value: endDate,
        Validations: { required: true },
      });
      if (!stDateValidation.valid) {
        setError((prevErrors) => ({
          ...prevErrors,
          start_Date: "Start date must be required!",
        }));
      }
      if (!edDateValidation.valid) {
        setError((prevErrors) => ({
          ...prevErrors,
          end_Date: "End date must be required!",
        }));
      }
      
      if (stDateValidation.valid && edDateValidation.valid) {
        setError({ ...error, start_Date: "", end_Date: "" });
        const timestamp = date[0]?.startDate;
        const formattedDateSD = moment(timestamp)?.format("YYYY-MM-DD");
        const timestampED = date[0]?.endDate;
        const formattedDateED = moment(timestampED)?.format("YYYY-MM-DD");
        setAdvanceData({
          created_by: filterData?.created_by,
          recipientEmail: templateFilter?.recipientEmail,
          email_template_id: templateFilter?.email_template_id,
          start_Date: date[0]?.startDate ? formattedDateSD : "",
          end_Date: date[0]?.endDate ? formattedDateED : "",
        });
      }
    } else {
      setError({ ...error, start_Date: "", end_Date: "" });
      setAdvanceData({
        created_by: filterData?.created_by,
        recipientEmail: templateFilter.recipientEmail,
        email_template_id: templateFilter.email_template_id,
        start_Date: date[0]?.startDate ? date[0]?.startDate : "",
        end_Date: date[0]?.endDate ? date[0]?.endDate : "",
      });
    }
  };

  //Clear advance filter inputs
  const clearFilter = () => {
    // clearInput()
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
    setAdvanceData({
      created_by: "",
      recipientEmail: "",
      template_name: "",
      start_Date: "",
      end_Date: "",
    });
    setFilterData({ recipientEmail: "", created_by: "" });
    setTemplateFilter({
      recipientEmail: "",
      template_name: "",
      email_template_id: "",
    });
    if (open === 1) {
      setDate([{ startDate: new Date(), endDate: new Date(), key: "today" }]);
    } else {
      setDate([{ startDate: null, endDate: null, key: "custom" }]);
    }

    // setDate([{ startDate: new Date(), endDate: new Date(), key: "today" }]);
    // setError({ ...error, start_Date: "", end_Date: "" });
    // setKey(prevKey => prevKey + 1);
    setsearchTerm("");
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5  flex-wrap gap-y-2.5">
        <Heading>Email Log</Heading>
        <div className="flex gap-3 flex gap-3 flex-wrap gap-y-2.5">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={SearchImg} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
          <div
            className={`filter-block ${iconSvg === true ? "active" : ""}`}
            ref={dropdownRef}
          >
            <Dropdown renderTitle={Toggle}>
              <div className="p-4">
                {/* <div className="p-2">
                  <Label className="font-semibold mb-3 block">Filter name (search)</Label>
                  <div className="input-wrapper">
                    <div className="input-suffix-start left-3.5">
                      <img
                        src="/assets/icons-svg/search-lg.svg"
                        alt=""
                        className="h-5"
                      />
                    </div>
                    <input
                      type="text"
                      name="hs-table-search"
                      className="input pl-11 w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Type keyword"
                      ref={inputRef}
                      onChange={(e) => inputchangeSearch(e)}
                    />
                  </div>
                </div> */}
                <div className=" p-2">
                  <Label className="font-semibold mb-3 block">
                    Filter name (dropdown)
                  </Label>
                  <Select
                    placeholder="Sender"
                    className="select-option hide-seacrh-icon mb-3"
                    options={sectionOption}
                    value={sectionOption?.filter(function (option) {
                      return option?.value === filterData?.recipientEmail;
                    })}
                    onChange={(selectedOption) =>
                      handleSelection(selectedOption, "recipientEmail")
                    }
                  />

                  <Select
                    placeholder="Recipient"
                    className="select-option hide-seacrh-icon mb-3"
                    options={emailRecipOption}
                    value={emailRecipOption?.filter(function (option) {
                      return option?.value === templateFilter?.recipientEmail;
                    })}
                    onChange={(selectedOption) =>
                      handleSelectionRecipt(selectedOption, "recipientEmail")
                    }
                  />

                  <Select
                    placeholder="Template"
                    className="select-option hide-seacrh-icon "
                    options={templateOption}
                    value={templateOption?.filter(function (option) {
                      return option?.value === templateFilter?.template_name;
                    })}
                    onChange={(selectedOption) =>
                      handleSelectionTemplate(selectedOption, "template_name")
                    }
                  />
                </div>
                <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                  <AccordionHeader onClick={() => handleOpenDate(1)}>
                    Date range
                  </AccordionHeader>
                  <AccordionBody>
                    <div>
                      <DateRangePicker
                        className="hide-input-range calander_wrapper"
                        ranges={date}
                        onChange={handleDateRangeChange}
                        staticRanges={staticRanges}
                        moveRangeOnFirstSelection={true}
                        retainEndDateOnFirstSelection={false}
                        startDatePlaceholder={"Start date"}
                        showMonthAndYearPickers={false}
                        rangeColors={["#D0D5DD"]}
                        endDatePlaceholder={"End date"}
                        showSelectionPreview={true}
                        weekStartsOn={1}
                        weekdayDisplayFormat="EEEEEE"
                        disabledDay={isDayDisabled}
                      />
                    </div>
                    {error.end_Date && (
                      <p className="text-xs text-red-600 mt-2">
                        {error.end_Date}
                      </p>
                    )}
                    {error.start_Date && (
                      <p className="text-xs text-red-600 mt-2">
                        {error.start_Date}
                      </p>
                    )}
                  </AccordionBody>
                </Accordion>
                <div className="flex gap-2 border-t border-gray-200 pt-6 pb-3 mt-4">
                  <BtnLight disabled={buttonDisabled} onClick={clearFilter}>
                    Clear all filter
                  </BtnLight>
                  <BtnPrimary
                    disabled={buttonDisabled}
                    onClick={applyFilterHandler}
                  >
                    Apply filter
                  </BtnPrimary>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <EmailLogList
        emailData={emailData}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={emailData.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default EmailLog;
