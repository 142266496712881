import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ImageUploader = React.forwardRef((props, ref) => {
  const {
    accept,
    beforeUpload,
    disabled,
    multiple,
    onChange,
    className,
    defaultImage,
    height,
    width,
    uploadImage, // New prop for enabling/disabling image upload
    ...rest
  } = props;

  const fileInputField = useRef(null);
  const [image, setImage] = useState(defaultImage || null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageUpload = (event) => {
    if (uploadImage) {
      // Changed condition to allow upload when uploadImage is true
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setImageFile(file);
          onChange?.(file);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleImageClick = () => {
    if (!disabled && uploadImage) {
      // Changed condition to allow click when uploadImage is true
      fileInputField.current?.click();
    }
  };

  const uploadClass = classNames("image-uploader", className, {
    "cursor-pointer": uploadImage,
    "cursor-not-allowed": !uploadImage,
  });

  useEffect(() => {
    if (beforeUpload && imageFile) {
      const result = beforeUpload(imageFile);
      if (result === false) {
        setImage(defaultImage || null);
        setImageFile(null);
      }
    }
  }, [beforeUpload, imageFile, defaultImage]);

  return (
    <div ref={ref} className={uploadClass} {...rest} onClick={handleImageClick}>
      {uploadImage && (
        <input
          type="file"
          accept={accept}
          ref={fileInputField}
          onChange={handleImageUpload}
          className="image-input"
          multiple={multiple}
          disabled={disabled}
        />
      )}
      {image && (
        <img
          src={image}
          alt="Uploaded Preview"
          className="uploaded-image"
          style={{
            width: `${width}px`,
            height: `${height}px`,
            borderRadius: "12px",
          }}
        />
      )}
      {!image && (
        <div
          className="flex flex-col items-center justify-center w-full border py-4 px-3 rounded-xl border-gray-200 bg-white text-center"
          style={{ height: `${height}px`, width: `${width}px` }}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
              <img src="/assets/icons-svg/upload.svg" alt="" className="w-4" />
            </div>
            <p className="mb-2 text-gray-600 dark:text-gray-400">
              <span className="font-semibold text-blue-700">
                Click to upload
              </span>
              <span className="text-xs"> or drag and drop</span>
            </p>
            <p className="text-gray-600 dark:text-gray-400 font-inter text-xs">
              Example SVG, PNG, JPG
            </p>
          </div>
        </div>
      )}
    </div>
  );
});

ImageUploader.propTypes = {
  accept: PropTypes.string,
  beforeUpload: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  defaultImage: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  uploadImage: PropTypes.bool, // Prop type for enabling/disabling image upload
};

ImageUploader.defaultProps = {
  accept: ".png,.jpg,.jpeg,.svg",
  disabled: false,
  multiple: false,
  height: 300,
  width: 225,
  uploadImage: true, // Default value for enabling image upload
};

export default ImageUploader;
