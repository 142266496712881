import React, { useState, useEffect, useCallback } from "react";
import Heading from "../../../../common/element/Heading";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import InvoiceList from "./InvoiceList";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../../../common/pagination";
import Dialog from "../../../../common/element/Dialog/Dialog";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { GetProviderInvoicesById } from "../../../../actions/provider-portal/invoices/GetProviderInvoicesById";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ThreeDotBouncing from "../../../../common/element/PageLoader";
const Invoices = () => {
  const dispatch = useDispatch();
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const { allFunctions } = useSelector((state) => state);
  const [countData, setCountData] = useState(0);
  const [loadData, setLoadData] = useState(false);
  const [filterpage, setfilterpage] = useState(1);
  const [pdfDownloadSuccessDialog, setPdfDownloadSuccessDialog] =
    useState(false);
  const [filterlimit, setfilterlimit] = useState(10);
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("created_date");

  const [totalPages, setTotalPages] = useState(1);
  const [allInvoicesData, setAllInvoicesData] = useState([]);
  const { specificClientDetails } = useSelector((state) => state);
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Invoices",
          url: `/provider/invoices`,
          home: "/provider/dashboard",
        },
      ])
    );
  }, [specificClientDetails]);

  const onGetAllInvoiceData = useCallback(async () => {
    let payload = {
      page: filterpage,
      limit: filterlimit,
      provider_org_id: loginUserData?.organisation_id,
      order: filterorder,
      sort: filtersort,
    };

    dispatch(GetProviderInvoicesById(payload)).then((response) => {
      if (response.success) {
        setLoadData(true);
        setAllInvoicesData(response?.data);
        setCountData(response.data?.count);
        setTotalPages(Math.ceil(response.data?.length / filterlimit));
      } else {
        setLoadData(true);
      }
    });
  }, [dispatch, filterpage, filterorder, filtersort, filterlimit]);

  useEffect(() => {
    onGetAllInvoiceData();
  }, [filterpage, filterorder, filtersort, filterlimit]);

  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
  };

  const closeDownloadSuccessPdf = () => {
    setPdfDownloadSuccessDialog(false);
  };

  return (
    <React.Fragment>
      <div>
        <Heading>Invoice</Heading>
        <p className="text-gray-600 my-3">
          Non morbi vitae turpis leo scelerisque nisl phasellus nisi. Commodo.
        </p>
      </div>
      {/* <Filter /> */}
      <InvoiceList
        loadData={loadData}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        allInvoicesData={allInvoicesData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}

      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={allInvoicesData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}
      <Dialog
        isOpen={pdfDownloadSuccessDialog}
        onClose={closeDownloadSuccessPdf}
        onRequestClose={closeDownloadSuccessPdf}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            All PDFs downloaded successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeDownloadSuccessPdf()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Invoices;
