import React, { useState } from "react";
import Heading from "../../../../common/element/Heading";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ResendEmailVerification } from "../../../../actions/public/ResendEmailVerification";
import { useSelector } from "react-redux";
const InboxVerification = () => {
  const { SignUpData } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, SetLoader] = useState(false);
  const SubmitData = () => {
    SetLoader(true);
    //Validate Email
    if (true) {
      dispatch(
        ResendEmailVerification({
          email: SignUpData.email,
          registrationType: SignUpData?.registrationType,
        })
      ).then((response) => {
        if (response.success) {
          // navigate("/sign-up/inbox-verification");
          SetLoader(false);
        } else {
          SetLoader(false);
        }
      });
    } else {
      SetLoader(false);
    }
  };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen pr-6 pl-6"
    >
      <div className="min-h-screen justify-center flex  py-12 px-4">
        <div className="flex-col items-center justify-center flex">
          <div className="flex items-center mb-6">
            <img className="brand-img" src="/assets/logo.svg" alt="conserve" />
          </div>
          <Heading className="text-center mb-3">Check your email</Heading>
          <p className="text-base text-gray-600 text-center font-semibold max-w-lg">
            We’ve sent a verification link to{" "}
            <span className="text-gray-600 font-bold">{SignUpData.email}</span>{" "}
            . Click the link in the email to confirm your email address.
          </p>

          <div className="border-t border-gray-200 w-full max-w-md mb-9 mt-7"></div>

          <p className="text-center text-gray-600 text-base font-medium">
            Did not receive an email?
          </p>
          <div className="flex mt-2 mb-9 justify-between">
            <BtnLight
              className="h-9 text-xs px-3 flex justify-center items-center"
              onClick={SubmitData}
              disabled={loader}
            >
              <div to="password-creation">Resend email</div>
            </BtnLight>
          </div>
          <p className="text-xs text-gray-400 text-center font-semibold">
            Resend email{" "}
            <Link
              to={""}
              className="text-blue-700 hover:text-gray-900 focus:text-gray-600"
            >
              Re-enter your email address
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default InboxVerification;
