import axios from "axios";
import * as actionType from "../constant/actionType";
import { useNavigate } from "react-router-dom";

export const ConserveUserPersonalNotifPermission =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/ConserveUserPersonalNotifPermission`,
        params: getAllData,
      });
      return response?.data;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching notifications:", error);
      return null; // Return null or handle the error as needed
    }
  };

export const GetClientPersonalNotifypermissions =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/GetClientPersonalNotifypermissions`,
        params: getAllData,
      });
      return response?.data;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching notifications:", error);
      return null; // Return null or handle the error as needed
    }
  };
export const GetAdminPersonalNotifypermissions =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/GetAdminPersonalNotifypermissions`,
        params: getAllData,
      });
      return response?.data;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching notifications:", error);
      return null; // Return null or handle the error as needed
    }
  };

export const GetGlobelPermissions = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/GetGlobelPermissions`,
      params: getAllData,
    });
    return response?.data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error fetching notifications:", error);
    return null; // Return null or handle the error as needed
  }
};

export const UpdateAdminPersonalNotifypermissions =
  (APIData, ipAddress) => async (dispatch) => {
    try {
      // dispatch(fetchData());
      const response = await axios({
        method: "patch",
        data: APIData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/admin/UpdateAdminPersonalNotifypermissions`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
export const UpdateConserveUsersPersonalPermissions =
  (APIData) => async (dispatch) => {
    try {
      // dispatch(fetchData());
      const response = await axios({
        method: "patch",
        data: APIData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/UpdateConserveUsersPersonalPermissions`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
export const UpdateGlobelNotifications =
  (APIData, ipAddress) => async (dispatch) => {
    try {
      // dispatch(fetchData());
      const response = await axios({
        method: "patch",
        data: APIData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/admin/UpdateGlobelNotifications`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetAllAdminNotifications = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/GetCommonNotifications`,
      params: getAllData,
    });

    // Dispatch an action if needed, for example:
    // dispatch({ type: actionType.SET_ADMIN_NOTIFICATIONS, payload: response.data });

    return response.data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error fetching notifications:", error);
    return null; // Return null or handle the error as needed
  }
};

export const GetAllDeshboardTasks = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/GetAllDeshboardTasks`,
      params: getAllData,
    });

    // Dispatch an action if needed, for example:
    // dispatch({ type: actionType.SET_ADMIN_NOTIFICATIONS, payload: response.data });

    return response.data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error fetching notifications:", error);
    return null; // Return null or handle the error as needed
  }
};

export const GetNotificationTasksForAdminPortal =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/GetNotificationTasksForAdminPortal`,
        params: getAllData,
      });

      // Dispatch an action if needed, for example:
      // dispatch({ type: actionType.SET_ADMIN_NOTIFICATIONS, payload: response.data });

      return response.data;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching notifications:", error);
      return null; // Return null or handle the error as needed
    }
  };
export const UpdateTaskArchiveStatus = (APIData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "patch",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/UpdateTaskArchiveStatus`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const MarkTaskAsRead = (APIData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "patch",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/MarkTaskAsRead`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const setAllFunction = (allFunction) => {
  return {
    type: actionType.SET_ALL_FUNCTION,
    payload: allFunction,
  };
};

/*** AddDocumentType Action ***/
export const AddDocumentTypeStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.AddDocumentType_START,
  };
};
export const AddDocumentTypeSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.AddDocumentType_SUCCESS,
  };
};
export const AddDocumentTypeFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.AddDocumentType_FAIL,
  };
};
export const AddDocumentType = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(AddDocumentTypeStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
        "Content-Type": "application/json",
      },

      url: `${actionType.environment_url}/api/admin/AddDocumentType`,
    })
      .then(function (response) {
        dispatch(AddDocumentTypeSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          // Handle unauthorized access here, for example, redirect to login page
          dispatch(AddDocumentTypeFail("Unauthorized access. Please log in."));
          // HandleUnauthorizedAccess()
        } else {
          dispatch(
            AddDocumentTypeFail("Something went wrong, Please try again.")
          );
        }
        return error;
      });
  };
};

/*** AddDocumentType Action ***/
export const AdminGetAllDocumentTypeStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.AdminGetAllDocumentType_START,
  };
};
export const AdminGetAllDocumentTypeSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.AdminGetAllDocumentType_SUCCESS,
  };
};
export const AdminGetAllDocumentTypeFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.AdminGetAllDocumentType_FAIL,
  };
};
export const AdminGetAllDocumentType = (
  searchTerm,
  filterpage,
  filterorder,
  filtersort,
  filterlimit
) => {
  return (dispatch) => {
    dispatch(AdminGetAllDocumentTypeStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/AdminGetAllDocumentType`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        // document_type: document_type,
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(AdminGetAllDocumentTypeSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          // Handle unauthorized access here, for example, redirect to login page
          dispatch(
            AdminGetAllDocumentTypeFail("Unauthorized access. Please log in.")
          );
          // HandleUnauthorizedAccess()
        } else {
          dispatch(
            AdminGetAllDocumentTypeFail(
              "Something went wrong, Please try again."
            )
          );
        }
        return error;
      });
  };
};

/*** CreateEmailTemplate Action ***/
export const CreateEmailTemplateStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateEmailTemplate_START,
  };
};
export const CreateEmailTemplateSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateEmailTemplate_SUCCESS,
  };
};
export const CreateEmailTemplateFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateEmailTemplate_FAIL,
  };
};
export const CreateEmailTemplate = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(CreateEmailTemplateStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "email_temp_doc") {
        if (form_data[key] != undefined) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`email_temp_doc`, file);
          });
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/CreateEmailTemplate`,
    })
      .then(function (response) {
        dispatch(CreateEmailTemplateSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          CreateEmailTemplateFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetEmailTemplateById Action ***/
export const GetEmailTemplateByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetEmailTemplateById_START,
  };
};
export const GetEmailTemplateByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetEmailTemplateById_SUCCESS,
  };
};
export const GetEmailTemplateByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetEmailTemplateById_FAIL,
  };
};

export const GetEmailTemplateById = (email_id) => {
  return (dispatch) => {
    dispatch(GetEmailTemplateByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetEmailTemplateById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        email_template_id: email_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetEmailTemplateByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetEmailTemplateByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllEmailTemplatesByType Action ***/
export const GetAllEmailTemplatesByTypeStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllEmailTemplatesByType_START,
  };
};
export const GetAllEmailTemplatesByTypeSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllEmailTemplatesByType_SUCCESS,
  };
};
export const GetAllEmailTemplatesByTypeFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllEmailTemplatesByType_FAIL,
  };
};

export const GetAllEmailTemplatesByType = (
  template_type,
  searchTerm,
  filterpage,
  filterorder,
  filtersort,
  filterlimit,
  DetailType
) => {
  return (dispatch) => {
    dispatch(GetAllEmailTemplatesByTypeStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetAllEmailTemplatesByType`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        template_type: template_type,
        DetailType: DetailType,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllEmailTemplatesByTypeSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllEmailTemplatesByTypeFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** UpdateEmailTemplate Action ***/
export const UpdateEmailTemplateStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateEmailTemplate_START,
  };
};
export const UpdateEmailTemplateSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateEmailTemplate_SUCCESS,
  };
};
export const UpdateEmailTemplateFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateEmailTemplate_FAIL,
  };
};
export const UpdateEmailTemplate = (form_data) => {
  return (dispatch) => {
    dispatch(UpdateEmailTemplateStart());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "email_temp_doc") {
        form_data[key].forEach((file, i) => {
          formdata.append(`email_temp_doc`, file);
        });
      } else {
        formdata.append(key, form_data[key]);
      }
    }
    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/UpdateEmailTemplate`,
    })
      .then(function (response) {
        dispatch(UpdateEmailTemplateSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UpdateEmailTemplateFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** DeleteEmailTemplate Action ***/
export const DeleteEmailTemplateStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.DeleteEmailTemplate_START,
  };
};
export const DeleteEmailTemplateSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.DeleteEmailTemplate_SUCCESS,
  };
};
export const DeleteEmailTemplateFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.DeleteEmailTemplate_FAIL,
  };
};
export const DeleteEmailTemplate = (form_data) => {
  return (dispatch) => {
    dispatch(DeleteEmailTemplateStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/DeleteEmailTemplate`,
    })
      .then(function (response) {
        dispatch(DeleteEmailTemplateSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          DeleteEmailTemplateFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** DeleteEmailTempAttachmentById Action ***/
export const DeleteEmailTempAttachmentByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.DeleteEmailTempAttachmentById_START,
  };
};
export const DeleteEmailTempAttachmentByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.DeleteEmailTempAttachmentById_SUCCESS,
  };
};
export const DeleteEmailTempAttachmentByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.DeleteEmailTempAttachmentById_FAIL,
  };
};
export const DeleteEmailTempAttachmentById = (form_data) => {
  return (dispatch) => {
    dispatch(DeleteEmailTempAttachmentByIdStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/DeleteEmailTempAttachmentById`,
    })
      .then(function (response) {
        dispatch(DeleteEmailTempAttachmentByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          DeleteEmailTempAttachmentByIdFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** CreateServiceType Action ***/
export const CreateServiceTypeStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateServiceType_START,
  };
};
export const CreateServiceTypeSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateServiceType_SUCCESS,
  };
};
export const CreateServiceTypeFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.CreateServiceType_FAIL,
  };
};
export const CreateServiceType = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(CreateServiceTypeStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/CreateServiceType`,
    })
      .then(function (response) {
        dispatch(CreateServiceTypeSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          CreateServiceTypeFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** GetAllServiceTypes Action ***/
export const GetAllServiceTypesStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllServiceTypes_START,
  };
};
export const GetAllServiceTypesSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllServiceTypes_SUCCESS,
  };
};
export const GetAllServiceTypesFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllServiceTypes_FAIL,
  };
};

export const GetAllServiceTypes = (
  serviceType,
  searchTerm,
  filterpage,
  filterlimit,
  filtersort,
  filterorder
) => {
  return (dispatch) => {
    dispatch(GetAllServiceTypesStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetAllServiceTypes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        meta_key: serviceType,
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllServiceTypesSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllServiceTypesFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** CreateProviderTypes Action ***/
export const CreateProviderTypeStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.CreateProviderType_START,
  };
};

export const CreateProviderTypeSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.CreateProviderType_SUCCESS,
  };
};

export const CreateProviderTypeFail = (error) => {
  let errorMessage = "Something went wrong, Please try again.";
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message; // Use the specific error message from the server if available
  }
  return {
    error: errorMessage,
    loader: false,
    isAuthenticated:
      error.response && error.response.status === 401 ? false : true,
    type: actionType.CreateProviderType_FAIL,
  };
};

export const CreateProviderType = (data, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: "post",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/AddProviderType`,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// Get All Provider Data
export const GetAllProviderTypes = (paramsValue) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/GetAllProviderTypes`,
      params: paramsValue,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// Update Provider Type Data
export const UpdateProviderType =
  (paramsValue, ipAddress) => async (dispatch) => {
    try {
      const response = await axios({
        method: "put",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/admin/UpdateProviderType`,
        data: paramsValue,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  };

// Get GetSpecificProviderTypeLogs Provider Data
export const GetSpecificProviderTypeLogs =
  (paramsValue) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/GetSpecificProviderTypeLogs`,
        params: paramsValue, // Corrected: passing paramsValue directly
      });
      return response.data;
    } catch (error) {
      return error;
    }
  };
/*** GetAllProviderTypes Action ***/
export const GetAllProviderTypesStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllProviderTypes_START,
  };
};
export const GetAllProviderTypesSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllProviderTypes_SUCCESS,
  };
};
export const GetAllProviderTypesFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllProviderTypes_FAIL,
  };
};

/*** InviteUser Action ***/
export const InviteUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.InviteUser_START,
  };
};
export const InviteUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.InviteUser_SUCCESS,
  };
};
export const InviteUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.InviteUser_FAIL,
  };
};
export const InviteUser = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(InviteUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/InviteUser`,
    })
      .then(function (response) {
        dispatch(InviteUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(InviteUserFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** GetAllConserveTeamAndInvites Action ***/
export const GetAllConserveTeamAndInvitesStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAllConserveTeamAndInvites_START,
  };
};
export const GetAllConserveTeamAndInvitesSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAllConserveTeamAndInvites_SUCCESS,
  };
};
export const GetAllConserveTeamAndInvitesFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAllConserveTeamAndInvites_FAIL,
  };
};

export const GetAllConserveTeamAndInvites = (
  userType,
  searchTerm,
  filterpage,
  filterorder,
  filtersort,
  filterlimit,
  viewType
) => {
  return (dispatch) => {
    dispatch(GetAllConserveTeamAndInvitesStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetAllConserveTeamAndInvites`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        role_name: userType,
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        viewType: viewType,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAllConserveTeamAndInvitesSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAllConserveTeamAndInvitesFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** GetInvitationById Action ***/
export const GetInvitationByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetInvitationById_START,
  };
};
export const GetInvitationByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetInvitationById_SUCCESS,
  };
};
export const GetInvitationByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetInvitationById_FAIL,
  };
};

export const GetInvitationById = (invite_id) => {
  return (dispatch) => {
    dispatch(GetInvitationByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetInvitationById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        invite_id: invite_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetInvitationByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetInvitationByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** SubmitAdminUserInvitation Action ***/
export const SubmitAdminUserInvitationStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.SubmitAdminUserInvitation_START,
  };
};
export const SubmitAdminUserInvitationSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.SubmitAdminUserInvitation_SUCCESS,
  };
};
export const SubmitAdminUserInvitationFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.SubmitAdminUserInvitation_FAIL,
  };
};
export const SubmitAdminUserInvitation = (form_data) => {
  return (dispatch) => {
    dispatch(SubmitAdminUserInvitationStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/SubmitAdminUserInvitation`,
    })
      .then(function (response) {
        dispatch(SubmitAdminUserInvitationSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          SubmitAdminUserInvitationFail(
            "Something went wrong, Please try again."
          )
        );
        return error;
      });
  };
};

/*** ReinviteAdminUser Action ***/
export const ReinviteAdminUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.ReinviteAdminUser_START,
  };
};
export const ReinviteAdminUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.ReinviteAdminUser_SUCCESS,
  };
};
export const ReinviteAdminUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.ReinviteAdminUser_FAIL,
  };
};
export const ReinviteAdminUser = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(ReinviteAdminUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/ReinviteAdminUser`,
    })
      .then(function (response) {
        dispatch(ReinviteAdminUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          ReinviteAdminUserFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

// /*** GetUserDetailsById Action ***/
export const GetUserDetailsByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetUserDetailsById_START,
  };
};
export const GetUserDetailsByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetUserDetailsById_SUCCESS,
  };
};
export const GetUserDetailsByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetUserDetailsById_FAIL,
  };
};

export const GetUserDetailsById = (individual_id) => {
  return (dispatch) => {
    dispatch(GetUserDetailsByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetUserDetailsById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        individual_id: individual_id,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetUserDetailsByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetUserDetailsByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** UserResetPasswordEmail Action ***/
export const UserResetPasswordEmailStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UserResetPasswordEmail_START,
  };
};
export const UserResetPasswordEmailSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UserResetPasswordEmail_SUCCESS,
  };
};
export const UserResetPasswordEmailFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UserResetPasswordEmail_FAIL,
  };
};
export const UserResetPasswordEmail = (form_data) => {
  return (dispatch) => {
    dispatch(UserResetPasswordEmailStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/UserResetPasswordEmail`,
    })
      .then(function (response) {
        dispatch(UserResetPasswordEmailSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UserResetPasswordEmailFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** UpdateUser Action ***/
export const UpdateUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UpdateUser_START,
  };
};
export const UpdateUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UpdateUser_SUCCESS,
  };
};
export const UpdateUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UpdateUser_FAIL,
  };
};
export const UpdateUser = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(UpdateUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/UpdateUser`,
    })
      .then(function (response) {
        dispatch(UpdateUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(UpdateUserFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** RemoveUser Action ***/
export const RemoveUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.RemoveUser_START,
  };
};
export const RemoveUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.RemoveUser_SUCCESS,
  };
};
export const RemoveUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.RemoveUser_FAIL,
  };
};
export const RemoveUser = (form_data) => {
  return (dispatch) => {
    dispatch(RemoveUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/RemoveUser`,
    })
      .then(function (response) {
        dispatch(RemoveUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(RemoveUserFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** UserResetPassword Action ***/
export const UserResetPasswordStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.UserResetPassword_START,
  };
};
export const UserResetPasswordSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.UserResetPassword_SUCCESS,
  };
};
export const UserResetPasswordFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.UserResetPassword_FAIL,
  };
};
export const UserResetPassword = (form_data) => {
  return (dispatch) => {
    dispatch(UserResetPasswordStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/UserResetPassword`,
    })
      .then(function (response) {
        dispatch(UserResetPasswordSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          UserResetPasswordFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

// /*** GetallEmailLogs Action ***/
export const GetallEmailLogsStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetallEmailLogs_START,
  };
};
export const GetallEmailLogsSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetallEmailLogs_SUCCESS,
  };
};
export const GetallEmailLogsFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetallEmailLogs_FAIL,
  };
};

export const GetallEmailLogs = (
  filtersort,
  filterorder,
  filterpage,
  filterlimit,
  searchTerm,
  created_by,
  recipientEmail,
  email_template_id,
  start_Date,
  end_Date
) => {
  return (dispatch) => {
    dispatch(GetallEmailLogsStart());
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetallEmailLogs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        created_by: created_by,
        recipientEmail: recipientEmail,
        email_template_id: email_template_id,
        from_date: start_Date,
        to_date: end_Date,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetallEmailLogsSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetallEmailLogsFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

// /*** GetEmailLogById Action ***/
export const GetEmailLogByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetEmailLogById_START,
  };
};
export const GetEmailLogByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetEmailLogById_SUCCESS,
  };
};
export const GetEmailLogByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetEmailLogById_FAIL,
  };
};

export const GetEmailLogById = (emailid) => {
  return (dispatch) => {
    dispatch(GetEmailLogByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetEmailLogById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        email_id: emailid,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetEmailLogByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetEmailLogByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

// GetAllEmailSenderList Action
export const GetAllEmailSenderList = (paramsValue) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/GetAllEmailSenderList`,
      params: { recipientEmail: paramsValue },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
// GetAllEmailRecipientList Action
export const GetAllEmailRecipientList = (paramsValue) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/GetAllEmailRecipientList`,
      params: { created_by: paramsValue },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// GetAllUsedEmailTemplatesList Action
export const GetAllUsedEmailTemplatesList =
  (paramsValue) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/GetAllUsedEmailTemplatesList`,
        params: paramsValue,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }; // /*** GetAdminProfileById Action ***/
export const GetAdminProfileByIdStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetAdminProfileById_START,
  };
};
export const GetAdminProfileByIdSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetAdminProfileById_SUCCESS,
  };
};
export const GetAdminProfileByIdFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetAdminProfileById_FAIL,
  };
};

export const GetAdminProfileById = (individualid) => {
  return (dispatch) => {
    dispatch(GetAdminProfileByIdStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetAdminProfileById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        individual_id: individualid,
      },
    };

    return axios(config)
      .then(function (response) {
        dispatch(GetAdminProfileByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetAdminProfileByIdFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

// /*** GetSpecificUserRole Action ***/
export const GetSpecificUserRoleStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.GetSpecificUserRole_START,
  };
};
export const GetSpecificUserRoleSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.GetSpecificUserRole_SUCCESS,
  };
};
export const GetSpecificUserRoleFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.GetSpecificUserRole_FAIL,
  };
};

export const GetSpecificUserRole = (role_assignment_id) => {
  return (dispatch) => {
    dispatch(GetSpecificUserRoleStart());

    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/GetSpecificUserRole`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        role_assignment_id: role_assignment_id,
      },
    };

    return axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          localStorage.setItem(
            "permissionData",
            JSON.stringify(response.data.data)
          );
        }
        dispatch(GetSpecificUserRoleSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          GetSpecificUserRoleFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** RemoveAdminInviteUser Action ***/
export const RemoveAdminInviteUserStart = () => {
  return {
    error: null,
    loader: true,
    isAuthenticated: true,
    type: actionType.RemoveAdminInviteUser_START,
  };
};
export const RemoveAdminInviteUserSuccess = (response) => {
  return {
    error: false,
    loader: false,
    isAuthenticated: response.status === 401 ? false : true,
    type: actionType.RemoveAdminInviteUser_SUCCESS,
  };
};
export const RemoveAdminInviteUserFail = (error) => {
  return {
    error: error,
    loader: false,
    isAuthenticated: error.status === 401 ? false : true,
    type: actionType.RemoveAdminInviteUser_FAIL,
  };
};
export const RemoveAdminInviteUser = (form_data, ipAddress) => {
  return (dispatch) => {
    dispatch(RemoveAdminInviteUserStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/admin/RemoveAdminInviteUser`,
    })
      .then(function (response) {
        dispatch(RemoveAdminInviteUserSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          RemoveAdminInviteUserFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

export const ForwardEmail = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "post",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/ForwardEmail`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
