import axios from "axios";
import * as actionType from "../../../constant/actionType";
import {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
} from "../../../redux/classes/AdminContractor/GetAdminproviderClientSlice";
export const GetAllClientsOfWorkerByProvider =
  (apiData) => async (dispatch) => {
    try {
const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

      // Dispatch the fetch action to indicate loading
      dispatch(fetchData());

      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/provider/worker/GetAllClientsOfWorkerByProvider`,
        params: apiData,
      });

      // Dispatch the success action with the response data
      dispatch(fetchDataSuccess(response.data));

      return response.data;
    } catch (error) {
      // Dispatch the error action with the error message
      dispatch(fetchDataError(error));

      console.error("Error fetching clients of provider:", error);
      throw error; // Throw the error to be handled by the caller if necessary
    }
  };
