import React, { useCallback, useEffect, useState } from "react";
import Card from "../../../../common/element/Card";
import Heading from "../../../../common/element/Heading";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Radio from "../../../../common/element/Radio";
import Tooltip from "../../../../common/element/Tooltip";
import Segment from "../../../../common/element/Segment";
import Select from "../../../../common/element/Select";
import Upload from "../../../../common/element/Upload";
import Circle from "../../../../common/element/Iconcircle";
import { CreateOrUpdateProviderRegisTempData } from "../../../../actions/public/CreateOrUpdateProviderRegisTempData";
import * as userActions from "../../../../actions/index";
import { useDispatch } from "react-redux";
import Label from "../../../../common/element/Label";
import Input from "../../../../common/element/Input";
import WorkHealth from "./WorkHealth";
import PdfFile from "../../../../assets/icons-svg/upload-pdf.svg";
import YourBusiness from "./YourBusiness";
import { setAllFunction } from "../../../../actions";
import { useSelector } from "react-redux";
import { GetAllClientList } from "../../../../actions/admin-provider/AllInviteContractorApi";
import PaymentMethod from "./StepTwo";
import { GetProviderRegisSaveAndContinueById } from "../../../../actions/public/GetProviderRegisSaveAndContinueById";
import Button from "../../../../common/element/Buttons";
import { RegistrationValidationCheck } from "../../../../redux/classes/common/RegistrationValidation";
import Validations from "../../../../common/404/Validations";
import { UpdateProviderRegisTempData } from "../../../../actions/public/UpdateProviderRegisTempData";
import { SignupProviderForClient } from "../../../../actions/public/SignupProviderForClient";
import ThreeDotLoader from "../../../../common/element/ButtonLoader/ThreeDotLoader";
import {
  GettData,
  ResetData,
} from "../../../../redux/classes/common/ProviderRegisterData";
import ConnectClient from "../../../providerPortal/pages/Registration/ConnectClient";
import Dialog from "../../../../common/element/Dialog";
import { ResetProviderSignUpData } from "../../../../redux/classes/provider-portal/SignUp";
import { decryptId } from "../../../../common/encryptDecrypt/encryptdecrypt";
import { AddProviderRegisterFinalData } from "../../../../redux/classes/common/ProviderRegisterFinalData";
const ContractorRegistrationClient = () => {
  const { ProviderRegisterFinalData } = useSelector((state) => state);
  const { ProviderContactData } = useSelector((state) => state);
  const { SignUpData } = useSelector((state) => state);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let providerTempRegistID;
  try {
    providerTempRegistID = searchParams?.get("provider_temp_regist_id")?.trim(); // Get and trim the value
    if (!providerTempRegistID) {
      throw new Error("provider_temp_regist_id is missing or invalid.");
    }

    providerTempRegistID = decryptId(providerTempRegistID.replace(/\s/g, "+"));

    if (providerTempRegistID) {
    } else {
      // console.error("Failed to decrypt providerTempRegistID");
    }
  } catch (error) {
    // console.error("Error retrieving provider_temp_regist_id:", error.message);
  }

  const dispatch = useDispatch();
  // const [visibleHide, setVisibleHide] = useState(false);
  // const [highRiskShow, setHighRiskShow] = useState(false);
  // const [docAdd, setDocAdd] = useState(false);
  // const [value, setValue] = useState("Primary");
  const [uploadedFiles, setUploadedFiles] = useState();
  const [clientData, SetClientData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [step, Setstep] = useState(false);
  const [tempEngagementDocError, setTempEngagementDocError] = useState("");
  const [tempMsaDocError, setTempMsaDocError] = useState("");

  const [clientError, setClientError] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [GetProviderChecklistByClient, setGetProviderChecklistByClient] =
    useState({ client_org_id: "", provider_org_id: "", token: "" });

  const [msaInfo, setMsaInfo] = useState({
    msa_ques_one:
      "Is your organisation required to report under the Modern Slavery Act (Cth) 2022?*",
    ans_ques_one: "",
    msa_ques_two:
      "Does your organisation have policies to deal with modern slavery?*",
    ans_ques_two: "",
    msa_ques_three:
      "Does your organisation have a person(s) overseeing modern slavery risks?*",
    ans_ques_three: "",
    msa_ques_four:
      "Are staff in your organisation trained to manage modern slavery risks?*",
    ans_ques_four: "",
    msa_ques_five:
      "Does your organisation engage low skilled migrant workers?*",
    ans_ques_five: "",
    msa_ques_six:
      "Does your organisation screen prospective suppliers to assess modern slavery risks?*",
    ans_ques_six: "",
  });

  const [FormData, SetFormData] = useState({
    registrationType:
      SignUpData?.registrationType == "forClient"
        ? "forClient"
        : "inviteByClient",
    invite_provider_id: "", //only require when create request first time
    provider_temp_regist_id: "",
    client_org_id: "",
    client_fa_id: "",
    user_id: "",
    tempMsaDoc: {},
    tempEngagementDoc: {},
    provider_regist_data: {
      address_data: [
        {
          country_id: "",
          state_id: "",
          address_one: "",
          address_two: "",
          suburb: "",
          zipcode: "",
          address_type: "",
        },
      ],
      individual_data: [],
      client_contact_data: {
        contact_title: "Mr",
        contact_first_name: "",
        contact_last_name: "",
        contact_phone: "",
        contact_phone_optional: "",
        contact_email: "",
      },
      whs_contact_info: {
        title: "mr",
        first_name: "",
        last_name: "",
        phone: "",
        phone_optional: "",
        email: "",
      },
      org_type: "other",
      abn_nzbn: "",
      trading_name: "",
      high_risk_master_setting_id: "",
      ind_master_setting_id: "",
      provider_type_id: ProviderContactData?.provider_type_id || "",
      no_of_emp: "",
      high_risk_activity: true,
      whs_system_available: false,
      whs_system_certified: false,
      msa_info: {},
    },
  });

  const [contactError, SetContactError] = useState({
    contact_title: "",
    contact_first_name: "",
    contact_last_name: "",
    contact_phone: "",
    contact_email: "",
    ans_ques_one: "",
    ans_ques_two: "",
    ans_ques_three: "",
    ans_ques_four: "",
    ans_ques_five: "",
    ans_ques_six: "",
  });

  const TitleOptions = [
    { value: "Mr", label: "Mr." },
    { value: "miss", label: "Miss" },
    { value: "mrs", label: "Mrs." },
  ];

  // const preventDefault = (e) => {
  //   e.preventDefault();
  // };
  // const onChange = (val) => {
  //   setValue(val);
  // };
  // const showHandle = (e) => {
  //   e.preventDefault();
  //   setVisibleHide(true);
  // };
  // const hideHandle = (e) => {
  //   e.preventDefault();
  //   setVisibleHide(false);
  // };
  // const highRiskShowHandle = (e) => {
  //   e.preventDefault();
  //   setHighRiskShow(true);
  // };
  // const hideHighRiskShow = (e) => {
  //   setHighRiskShow(false);
  //   e.preventDefault();
  // };

  // const uploadDocHandler = (e) => {
  //   e.preventDefault();
  //   setDocAdd(true);
  // };

  // const hideBlock = (e) => {
  //   setDocAdd(false);
  //   e.preventDefault();
  // };
  // const countryOptions = [
  //   { value: "Australia", label: "Australia" },
  //   { value: "Belgium", label: "Belgium" },
  //   { value: "Brazil", label: "Brazil" },
  // ];
  // const IndustryOptions = [
  //   { value: "Education", label: "Education" },
  //   { value: "Training", label: "Training" },
  // ];

  const handleTitleChange = (e, mainName, name) => {
    SetContactError((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: "",
    }));

    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        [mainName]: {
          ...prevFormData.provider_regist_data.client_contact_data,
          [name]: name == "contact_title" ? e.value : e,
          // replace "newTitle" with the desired title
        },
      },
    }));

    // SetFormData(()=>{

    // })
  };
  const msaInfoHandle = (val, field) => {
    SetContactError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: "",
    }));
    setMsaInfo((prevFormData) => ({
      ...prevFormData,
      [field]: val[0],
    }));
  };

  // const handleFileChange = (files, name) => {
  //   SetFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: files[0],
  //   }));
  // };
  const handleFileChange = (files, name) => {
    let totalSize = 0;
    const validFileTypes = ["application/pdf"];
    let fileTypeError = false;

    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }
        totalSize += fileSize;
        return true;
      }
      return false;
    });

    const errorMessage =
      totalSize > 25 * 1024 * 1024
        ? "Total file size exceeds the maximum limit of 25MB."
        : fileTypeError
        ? "Invalid file type. Only PDF files are allowed."
        : "";

    // Set specific error messages for each file input
    if (name === "tempEngagementDoc") {
      setTempEngagementDocError(errorMessage);
    } else if (name === "tempMsaDoc") {
      setTempMsaDocError(errorMessage);
    }
    if (!errorMessage) {
      SetFormData((prevFormData) => ({
        ...prevFormData,
        [name]: validatedFiles[0],
      }));
    }
  };

  // const handleFileRemove = (files, name) => {
  // SetFormData((prevFormData) => ({
  //   ...prevFormData,
  //   [name]: "",
  // }));
  // };
  const handleFileRemove = (files, name) => {
    const validFileTypes = ["application/pdf"];
    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Determine the error message
    const errorMessage =
      totalSize > 25 * 1024 * 1024
        ? "Total file size exceeds the maximum limit of 25MB."
        : fileTypeError
        ? "Invalid file type detected. Only PDF files are allowed."
        : "";

    // Set specific error message based on the upload field
    if (name === "tempEngagementDoc") {
      setTempEngagementDocError(errorMessage);
    } else if (name === "tempMsaDoc") {
      setTempMsaDocError(errorMessage);
    }

    // Update form data with validated files or clear it if no files are left
    SetFormData((prevFormData) => ({
      ...prevFormData,
      [name]: validatedFiles.length > 0 ? validatedFiles[0] : "",
    }));

    // Optional: Handle edge case when all files are removed
    if (validatedFiles.length === 0) {
      // Uncomment if you need to set additional states when all files are removed
      // setFilesUploaded(false);
    }
  };

  const GetAllFunctions = useCallback(async () => {
    dispatch(userActions.GetAllFunctions()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        dispatch(setAllFunction(arrayData));
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllFunctions();
    if (ProviderContactData?.invite_provider_id) {
      SetFormData((prevalue) => {
        return {
          ...prevalue,
          invite_provider_id: ProviderContactData?.invite_provider_id,
          client_fa_id: ProviderContactData?.client_fa_id,
          client_org_id:
            ProviderContactData?.provInviteClient?.org_data?.organisation_id,
        };
      });
    }
    // ProviderContactData.is_invited_by_admin !== undefined
    //     ? !ProviderContactData.is_invited_by_admin
    //     : false
    if (true) {
      let clientContactData = {
        contact_title: ProviderContactData?.ProvInvitedBy?.title,
        contact_first_name: ProviderContactData?.ProvInvitedBy?.first_name,
        contact_last_name: ProviderContactData?.ProvInvitedBy?.last_name,
        contact_phone: ProviderContactData?.ProvInvitedBy?.phone,
        contact_phone_optional:
          ProviderContactData?.ProvInvitedBy?.phone_optional,
        contact_email: ProviderContactData?.ProvInvitedBy?.email,
      };

      SetFormData((prevFormData) => ({
        ...prevFormData,
        provider_regist_data: {
          ...prevFormData.provider_regist_data,
          client_contact_data: clientContactData, // Update the contact information
        },
      }));
    }
  }, []);

  const getAllfunctions = useSelector((state) => state);
  useEffect(() => {
    if (
      getAllfunctions?.allFunctions?.allFunction?.filter?.(
        (item) => item.function_name == "client"
      )?.[0]?.function_id
    ) {
      dispatch(
        GetAllClientList({
          function_id: getAllfunctions?.allFunctions?.allFunction?.filter(
            (item) => item.function_name == "client"
          )[0].function_id,
          search: "",
        })
      ).then((response) => {
        const ClientData = [];
        for (let key in response?.data) {
          ClientData.push({
            label: response?.data[key]?.trading_name,
            value: response?.data[key]?.function_assignment_id,
            organisation_id: response?.data[key]?.organisation_id,
            // name:"ind_master_setting_ids",
          });
        }
        SetClientData(ClientData);

        SetFormData((prevalue) => {
          return {
            ...prevalue,
            client_trading_name: ClientData?.filter?.(
              (options) => options.value === ProviderContactData?.client_fa_id
            )?.[0]?.label,
          };
        });
        if (FormData.client_fa_id) {
          SetFormData((prevalue) => {
            return {
              ...prevalue,
              client_trading_name: ClientData?.filter?.(
                (options) => options.value === FormData.client_fa_id
              )?.[0]?.label,
            };
          });
        }
      });
    }
  }, [getAllfunctions]);
  useEffect(() => {
    if (FormData.client_fa_id) {
      if (clientData.length) {
        SetFormData((prevalue) => {
          return {
            ...prevalue,
            client_org_id: clientData?.filter?.(
              (options) => options.value === FormData.client_fa_id
            )?.[0]?.value,
            client_trading_name: clientData?.filter?.(
              (options) => options.value === FormData.client_fa_id
            )?.[0]?.label,
          };
        });
      }
    }
  }, [FormData.client_fa_id]);
  const formHandle = (e, name) => {
    SetFormData((prevalue) => {
      if (name == "clientId") {
        setClientError("");
        return {
          ...prevalue,
          client_org_id: e.organisation_id,
          client_fa_id: e.value,
          client_trading_name: e.label,
        };
      }
    });
  };

  const UpdateData = (data, contact) => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        individual_data: contact, // Update the contact information
        address_data: data.provider_address_data,
        ...data.detailsData,
      },
    }));
  };

  const UpdateWHSData = (data, WHSData) => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        whs_contact_info: data, // Update the contact information
        ...WHSData,
      },
    }));
  };

  useEffect(() => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        msa_info: msaInfo, // Update the contact information
      },
    }));
  }, [msaInfo]);

  const setFormError = (field, message) => {
    SetContactError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };
  const SubmitData = () => {
    if (ProviderRegisterData?.data == null) {
      const client_fa_id = Validations({
        value: FormData.client_fa_id,
        Validations: { required: true },
      });
      if (!client_fa_id.valid) {
        setClientError(client_fa_id.error_msg);
      }
      if (client_fa_id.valid) {
        setButtonDisabled2(true);
        dispatch(
          CreateOrUpdateProviderRegisTempData(
            FormData,
            SignUpData?.registrationType
          )
        )
          .then((response) => {
            if (response.success) {
              navigate("/login");
              setButtonDisabled2(false);
            } else {
              console.error(
                "Operation failed:",
                response.errorMessage || "Unknown error"
              );
            }
          })
          .catch((error) => {
            console.error("An error occurred during registration:", error);
          })
          .finally(() => {
            setButtonDisabled2(false);
          });
      }
    } else {
      setButtonDisabled2(true);
      dispatch(UpdateProviderRegisTempData(FormData))
        .then((response) => {
          if (response.success) {
            navigate("/login");
            setButtonDisabled2(false);
          } else {
            console.error(
              "Operation failed: ",
              response.errorMessage || "Unknown error"
            );
          }
        })
        .catch((error) => {
          console.error("An error occurred during registration:", error);
        })
        .finally(() => {
          setButtonDisabled2(false);
        });
    }
  };

  const finalSaveData = () => {
    dispatch(RegistrationValidationCheck(true));

    // Contact Validation
    const allFieldsNotEmpty = Object.keys(
      FormData?.provider_regist_data?.client_contact_data
    ).every(
      (key) =>
        key === "contact_phone_optional" ||
        FormData?.provider_regist_data?.client_contact_data?.[key]?.trim() !==
          ""
    );

    // MSA Validation
    // Function to check if all answers are not empty
    function checkAllAnswersNotEmpty(data) {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (data[key] === "") {
            return false;
          }
        }
      }
      return true;
    }
    const allAnswersNotEmpty = checkAllAnswersNotEmpty(
      FormData?.provider_regist_data?.msa_info
    );

    // address_data Validation
    const isValidAddress = (address) => {
      return Object.keys(address).every(
        (key) => key === "address_two" || address[key] !== ""
      );
    };

    const results =
      FormData?.provider_regist_data?.address_data.map(isValidAddress);

    // Details Validation
    let detailsData = {
      abn_nzbn: FormData?.abn_nzbn,
      no_of_emp: FormData?.no_of_emp,
      ind_master_setting_id: FormData?.ind_master_setting_id,
      trading_name: FormData?.trading_name,
    };
    const allDetailsDataNotEmpty = Object.values(detailsData).every(
      (value) => value !== ""
    );

    // address_data Validation
    const PrimaryContact = (address) => {
      return Object.keys(address).every(
        (key) => key === "address_two" || address[key] !== ""
      );
    };

    const PrimaryContactResult =
      FormData?.provider_regist_data?.individual_data?.map != undefined
        ? FormData?.provider_regist_data?.individual_data?.map?.(PrimaryContact)
        : true;

    let contactData = FormData?.provider_regist_data?.client_contact_data;
    const contact_first_name = Validations({
      value: contactData.contact_first_name,
      Validations: { required: true },
    });
    const contact_last_name = Validations({
      value: contactData.contact_last_name,
      Validations: { required: true },
    });

    const contact_phone = Validations({
      value: contactData.contact_phone,
      Validations: { required: true },
    });
    const contact_email = Validations({
      value: contactData.contact_email,
      Validations: { required: true, email: true },
    });

    const ans_ques_one = Validations({
      value: msaInfo.ans_ques_one,
      Validations: { required: true },
    });

    const ans_ques_two = Validations({
      value: msaInfo.ans_ques_two,
      Validations: { required: true },
    });

    const ans_ques_three = Validations({
      value: msaInfo.ans_ques_three,
      Validations: { required: true },
    });

    const ans_ques_four = Validations({
      value: msaInfo.ans_ques_four,
      Validations: { required: true },
    });

    const ans_ques_five = Validations({
      value: msaInfo.ans_ques_five,
      Validations: { required: true },
    });

    const ans_ques_six = Validations({
      value: msaInfo.ans_ques_six,
      Validations: { required: true },
    });

    if (!contact_first_name.valid) {
      setFormError("contact_first_name", contact_first_name.error_msg);
    }
    if (!contact_last_name.valid) {
      setFormError("contact_last_name", contact_last_name.error_msg);
    }
    if (!contact_phone.valid) {
      setFormError("contact_phone", contact_phone.error_msg);
    }
    if (!contact_email.valid) {
      setFormError("contact_email", contact_email.error_msg);
    }

    if (!ans_ques_one.valid) {
      setFormError("ans_ques_one", ans_ques_one.error_msg);
    }

    if (!ans_ques_two.valid) {
      setFormError("ans_ques_two", ans_ques_two.error_msg);
    }

    if (!ans_ques_three.valid) {
      setFormError("ans_ques_three", ans_ques_three.error_msg);
    }

    if (!ans_ques_four.valid) {
      setFormError("ans_ques_four", ans_ques_four.error_msg);
    }

    if (!ans_ques_five.valid) {
      setFormError("ans_ques_five", ans_ques_five.error_msg);
    }

    if (!ans_ques_six.valid) {
      setFormError("ans_ques_six", ans_ques_six.error_msg);
    }
    const abnValidation = Validations({
      value: FormData?.provider_regist_data?.abn_nzbn,
      Validations: { required: true, ValidateABN: true },
    });

    if (
      // FormData?.high_risk_master_setting_id &&
      abnValidation.valid &&
      ans_ques_six.valid &&
      ans_ques_five.valid &&
      ans_ques_four.valid &&
      ans_ques_three.valid &&
      ans_ques_two.valid &&
      ans_ques_one.valid &&
      contact_email.valid &&
      contact_phone.valid &&
      allFieldsNotEmpty &&
      allAnswersNotEmpty &&
      results &&
      allDetailsDataNotEmpty &&
      PrimaryContactResult &&
      contact_first_name.valid &&
      contact_last_name.valid &&
      tempEngagementDocError == "" &&
      tempMsaDocError == ""
    ) {
      if (ProviderContactData?.inviteProType?.client_pay_default) {
        setButtonDisabled(true);
        dispatch(SignupProviderForClient(FormData))
          .then((response) => {
            if (response.success) {
              if (SignUpData?.registrationType === "forClient") {
                navigate("/login");
                setButtonDisabled(false);
                dispatch(ResetProviderSignUpData());
              } else {
                setDialogIsOpen(true);
                setButtonDisabled(false);
                setGetProviderChecklistByClient({
                  client_org_id: response?.data?.client_org_id,
                  provider_org_id: response?.data?.provider_org_id,
                  token: response?.data?.token,
                  abn_nzbn: response?.data?.abn_nzbn,
                  provider_trading_name: response?.data?.provider_trading_name,
                });
                localStorage.setItem(
                  "conserve_authtoken",
                  response?.data?.token
                );
              }
            } else {
              console.error(
                "Operation failed:",
                response.errorMessage || "Unknown error"
              );
            }
          })
          .catch((error) => {
            console.error("An error occurred during signup:", error);
          })
          .finally(() => {
            setButtonDisabled(false);
          });
      } else {
        if (SignUpData?.registrationType === "forClient") {
          Setstep(true);
        } else {
          setDialogIsOpen(true);
        }
        dispatch(AddProviderRegisterFinalData(FormData));
      }
    }
  };

  const StepTwo = () => {
    Setstep(true);
  };

  // Get Registration Data
  const { ProviderRegisterData } = useSelector((state) => state);

  useEffect(() => {
    if (providerTempRegistID != null) {
      dispatch(
        GetProviderRegisSaveAndContinueById({
          provider_temp_regist_id: providerTempRegistID,
        })
      ).then((response) => {
        if (response?.success) {
          if (response?.data == null) {
            navigate("/expire-link");
            dispatch(ResetData());
          } else {
            if (response?.data?.is_regis_completed) {
              dispatch(ResetData());
              navigate("/login");
            } else {
              dispatch(GettData(response));
              SetFormData((prevalue) => {
                return {
                  ...prevalue,
                  ...response?.data,

                  ["tempMsaDoc"]: response?.data?.temp_msa_doc,
                  ["tempEngagementDoc"]: response?.data?.temp_engagement_doc,
                };
              });
              setMsaInfo((prevalue) => {
                return {
                  ...prevalue,
                  ...response?.data?.provider_regist_data?.msa_info,
                };
              });
            }
          }
        } else {
          dispatch(ResetData());
        }
      });
    } else {
      dispatch(ResetData());
    }
  }, [ProviderRegisterData?.success]);

  const onDialogClose = () => {
    setdialogAddOpen(true);
  };
  const onAddDialogClose = () => {
    setdialogAddOpen(false);
    setDialogIsOpen(false);
    navigate("/login");
  };

  useEffect(() => {
    SetFormData((prevFormData) => ({
      ...prevFormData,
      provider_regist_data: {
        ...prevFormData.provider_regist_data,
        individual_data: SignUpData, // Update the contact information
      },
    }));
  }, [SignUpData]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const session_id = params.get("session_id");
    const paymentId = params.get("subscription_id");
    if (session_id || paymentId) {
      setDialogIsOpen(true);
    }
  }, []);

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-between pr-6 pl-6"
    >
      <div className="w-full  justify-center flex flex-wrap">
        <div className="sideBarStepWrap sticky top-0  pt-20 flex justify-between flex-col pb-20">
          <div className="block">
            <div className="flex items-center mb-6">
              <img src="/assets/logo.svg" alt="conserve" width="149px" />
            </div>
            <Heading className="font-bold mb-10 text-2xl">
              {SignUpData?.registrationType == "forClient"
                ? "Registering for a Client"
                : "Registering for a Client"}
            </Heading>
            <ul className="stepListWrap mb-20">
              {step ? (
                <li className="mb-6 step-cmpt">
                  <div className="flex">
                    <span className="flex h-8 w-8 bg-blue-100 rounded-full items-center justify-center mr-4">
                      <img src="assets/icons-svg/checkMark.svg" alt="" />
                    </span>
                    <div>
                      <h6 className="flex text-gray-700 font-semibold mb-0.5">
                        <span className="mr-0.5">1.</span> Business Information{" "}
                      </h6>
                      <p className=" text-gray-600">
                        Tell us about your business
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                <li className="mb-6 active">
                  <div className="flex">
                    <span className="flex h-8 w-8 bg-white rounded-full outline-4 outline-blue-200 items-center justify-center mr-4 outline">
                      <span className="inline-block h-2.5 w-2.5 bg-blue-800 rounded-full"></span>
                    </span>
                    <div>
                      <h6 className="flex text-blue-800 font-semibold mb-0.5">
                        <span className="mr-0.5">1.</span> Business Information{" "}
                      </h6>
                      <p className="text-blue-700">
                        Tell us about your business
                      </p>
                    </div>
                  </div>
                </li>
              )}
              <li className="mb-6">
                <div className="flex">
                  {step ? (
                    <span className="flex h-8 w-8 bg-white rounded-full outline-4 outline-blue-200 items-center justify-center mr-4 outline">
                      <span className="inline-block h-2.5 w-2.5 bg-blue-800 rounded-full"></span>
                    </span>
                  ) : (
                    <span
                      className={`flex items-center justify-center h-8 w-8 bg-gray-300 rounded-full mr-4`}
                    >
                      <span
                        className={`inline-block h-2.5 w-2.5 bg-gray-400 rounded-full`}
                      ></span>
                    </span>
                  )}
                  <div>
                    <h6 className={`flex text-gray-700 font-semibold mb-0.5`}>
                      <span className="mr-0.5">2.</span>Payment
                    </h6>
                    <p className={`text-gray-600`}>
                      Securely complete your registration by <br />
                      making a payment.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex pr-20 gap-4">
            <Link
              to="/login"
              className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex back-btn-hide items-center"
            >
              {" "}
              <img
                src="assets/icons-svg/arrow-left.svg"
                className="w-5 mr-2"
                alt=""
              />{" "}
              Back to log in
            </Link>
            <BtnLight className="w-auto px-3 text-xs">
              Save and continue another time
            </BtnLight>
          </div>
        </div>
        {step && <PaymentMethod Setstep={Setstep} />}
        {!step && (
          <form className="pb-20 pt-20">
            <Card className="p-7  border border-gray-200 rounded-xl mb-7">
              <h5 className="text-blue-800 font-semibold text-2xl mb-7">
                Client you are registering for
              </h5>
              <div className="mb-7">
                <label
                  className="block text-gray-700  mb-2 text-xs font-medium"
                  for="password"
                >
                  Search for Client
                </label>
                {/* clientData */}
                <div className="select-options w-full">
                  <Select
                    isSearchable={false}
                    readOnly={
                      SignUpData?.registrationType == "forClient" ? false : true
                    }
                    placeholder="Search for Client"
                    options={clientData}
                    className="h-10"
                    value={
                      clientData &&
                      clientData.filter(
                        (options) => options.value === FormData.client_fa_id
                      )
                    }
                    onChange={(e) => formHandle(e, "clientId")}
                  />
                </div>
                {clientError && (
                  <p className="text-xs text-red-600 ">{clientError}</p>
                )}
              </div>
              <h4 className="font-bold text-gray-700 text-base mb-4">
                Upload evidence of engagement
              </h4>

              {!FormData?.tempEngagementDoc?.endsWith?.(".pdf") ? (
                <>
                  <Upload
                    className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl mb-7 border-gray-200"
                    draggable
                    uploadLimit={1}
                    accept=".pdf"
                    onChange={(e) => handleFileChange(e, "tempEngagementDoc")}
                    onFileRemove={(e) =>
                      handleFileRemove(e, "tempEngagementDoc")
                    }
                    maxFileSize={25 * 1024 * 1024}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <Circle className="bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
                        <img
                          src="assets/icons-svg/upload.svg"
                          alt=""
                          className="w-4"
                        />
                      </Circle>
                      <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                        <span className="font-semibold text-blue-700">
                          Click to upload
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                        PDF only. Max 25mb.
                      </p>
                    </div>
                    {tempEngagementDocError && (
                      <p className="text-xs text-red-600 mt-1">
                        {tempEngagementDocError}
                      </p>
                    )}
                  </Upload>
                </>
              ) : (
                <div className="p-4 rounded-xl border border-gray-300 w-full flex items-center justify-between">
                  <div className="flex  gap-4  items-center">
                    <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                      <img src="/assets/icons-svg/upload-pdf.svg" alt="" />
                    </span>
                    <div className="flex flex-col  items-center">
                      <span className="text-gray-700 text-xs font-medium">
                        {FormData.tempEngagementDoc}
                        {/* {checkPdf?.name} */}
                      </span>
                      {/* <span className="text-gray-600 text-xs">
                  {checkPdf?.size
                      ? `${(checkPdf?.size / 1024 / 1024).toFixed(2)} MB`
                      : `${(props.size.size / 1024).toFixed(2)} MB`}
                  </span> */}
                    </div>
                  </div>
                  <Button
                    onClick={(e) => handleFileRemove(e, "tempEngagementDoc")}
                  >
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </div>
              )}

              <h4 className="font-bold text-gray-700 text-base mb-5 mt-7">
                Client contact*
              </h4>
              <div className="items-start  mb-5">
                {/* <Label className="font-semibold w-72 font-sm">WHS Contact</Label> */}
                <div className="Pflex items-center  flex-1 flex-wrap">
                  <div className="flex w-full gap-2">
                    <Select
                      isSearchable={false}
                      readOnly={
                        SignUpData?.registrationType == "forClient"
                          ? false
                          : true
                      }
                      // ProviderContactData?.is_invited_by_admin !== undefined
                      //     ? !ProviderContactData.is_invited_by_admin
                      //     : false
                      placeholder=""
                      options={TitleOptions}
                      className="select-option w-2/12 mb-5 h-9"
                      value={
                        TitleOptions &&
                        TitleOptions.filter(
                          (options) =>
                            options.value ===
                            FormData.provider_regist_data?.client_contact_data
                              ?.contact_title
                        )
                      }
                      onChange={(e) =>
                        handleTitleChange(
                          e,
                          "client_contact_data",
                          "contact_title"
                        )
                      }
                    />
                    <div className="w-5/12 h-9 mb-5">
                      <Input
                        type="text"
                        name="First Name"
                        placeholder="First Name*"
                        className=" w-full"
                        disabled={
                          SignUpData?.registrationType == "forClient"
                            ? false
                            : true
                        }
                        // ProviderContactData.is_invited_by_admin !== undefined
                        //     ? !ProviderContactData.is_invited_by_admin
                        //     : false

                        value={
                          FormData?.provider_regist_data?.client_contact_data
                            ?.contact_first_name
                        }
                        inputchangehandler={(e) =>
                          handleTitleChange(
                            e,
                            "client_contact_data",
                            "contact_first_name"
                          )
                        }
                      />
                      {contactError.contact_first_name && (
                        <p className="text-xs text-red-600 ">
                          {contactError.contact_first_name}
                        </p>
                      )}
                    </div>
                    <div className="w-5/12 mb-5">
                      <Input
                        type="text"
                        name="Last Name"
                        placeholder="Last Name*"
                        className=" h-9 w-full"
                        disabled={
                          SignUpData?.registrationType == "forClient"
                            ? false
                            : true
                        }
                        // ProviderContactData.is_invited_by_admin !== undefined
                        //     ? !ProviderContactData.is_invited_by_admin
                        //     : false
                        value={
                          FormData?.provider_regist_data?.client_contact_data
                            ?.contact_last_name
                        }
                        inputchangehandler={(e) =>
                          handleTitleChange(
                            e,
                            "client_contact_data",
                            "contact_last_name"
                          )
                        }
                      />
                      {contactError.contact_last_name && (
                        <p className="text-xs text-red-600 ">
                          {contactError.contact_last_name}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full gap-2">
                    <div className="w-2/4 w-full mb-5">
                      <Input
                        type="number"
                        name="Work"
                        placeholder="Mobile*"
                        // disabled={
                        //   SignUpData?.registrationType == "forClient"
                        //     ? false
                        //     : true
                        // }
                        // ProviderContactData.is_invited_by_admin !== undefined
                        //   ? !ProviderContactData.is_invited_by_admin
                        //   : false
                        className=" h-9 w-full"
                        value={
                          FormData?.provider_regist_data?.client_contact_data
                            ?.contact_phone
                        }
                        inputchangehandler={(e) =>
                          handleTitleChange(
                            e,
                            "client_contact_data",
                            "contact_phone"
                          )
                        }
                      />
                      {contactError.contact_phone && (
                        <p className="text-xs text-red-600 ">
                          {contactError.contact_phone}
                        </p>
                      )}
                    </div>
                    <Input
                      type="number"
                      name="Mobile"
                      placeholder="Phone(Optional)"
                      className="w-2/4 h-9 mb-5"
                      value={
                        FormData?.provider_regist_data?.client_contact_data
                          ?.contact_phone_optional
                      }
                      inputchangehandler={(e) =>
                        handleTitleChange(
                          e,
                          "client_contact_data",
                          "contact_phone_optional"
                        )
                      }
                    />
                  </div>
                  <div className="w-full h-9 mb-5">
                    <Input
                      type="text"
                      name="Email"
                      placeholder="Email"
                      disabled={
                        SignUpData?.registrationType == "forClient"
                          ? false
                          : true
                      }
                      // ProviderContactData.is_invited_by_admin !== undefined
                      //     ? !ProviderContactData.is_invited_by_admin
                      //     : false
                      className="w-full h-9"
                      value={
                        FormData?.provider_regist_data?.client_contact_data
                          ?.contact_email
                      }
                      inputchangehandler={(e) =>
                        handleTitleChange(
                          e,
                          "client_contact_data",
                          "contact_email"
                        )
                      }
                    />
                    {contactError.contact_email && (
                      <p className="text-xs text-red-600 ">
                        {contactError.contact_email}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <Card className="registerBlock p-7 border border-gray-200 rounded-xl">
              <YourBusiness UpdateData={UpdateData} />
            </Card>
            <Card className="registerBlock p-7 mt-7 border border-gray-200 rounded-xl">
              <WorkHealth UpdateData={UpdateWHSData} />
            </Card>
            <Card className="registerBlock p-7 border border-gray-200 rounded-xl mt-7 ">
              <div className="flex justify-between flex-col mb-5 pt-5">
                <h5 className="text-blue-800 font-semibold text-2xl">
                  Modern Slavery Act (Cth) 2022{" "}
                  <Tooltip
                    title="Modern Slavery Act (Cth) 2022"
                    placement="top-start"
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.06 6.5a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.833h.007m6.66-3.333a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0z"
                        stroke="#98A2B3"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Tooltip>
                </h5>
              </div>
              <div className="items-start  mb-5">
                <p className="font-semibold w-full font-sm mb-5">
                  Is your organisation required to report under the Modern
                  Slavery Act (Cth) 2022*?
                </p>
                <div className="flex items-center  validation-filter ">
                  <Segment
                    value={`${FormData?.provider_regist_data?.msa_info.ans_ques_one}`}
                    onChange={(val) => msaInfoHandle(val, "ans_ques_one")}
                  >
                    <Segment.Item value="true">
                      <span>Yes</span>
                    </Segment.Item>
                    <Segment.Item value="false">
                      <span>No</span>
                    </Segment.Item>
                  </Segment>
                </div>
                {contactError.ans_ques_one && (
                  <p className="text-xs text-red-600 ">
                    {contactError.ans_ques_one}
                  </p>
                )}
              </div>
              {/* {hideCheckList && ( */}
              <div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation have policies to deal with modern
                    slavery?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_two}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_two")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_two && (
                    <p className="text-xs text-red-600 ">
                      {contactError.ans_ques_two}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation have a person(s) overseeing modern
                    slavery risks?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_three}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_three")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_three && (
                    <p className="text-xs text-red-600 ">
                      {contactError.ans_ques_three}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Are staff in your organisation trained to manage modern
                    slavery risks?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_four}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_four")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_four && (
                    <p className="text-xs text-red-600 ">
                      {contactError.ans_ques_four}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation engage low skilled migrant workers?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_five}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_five")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_five && (
                    <p className="text-xs text-red-600 ">
                      {contactError.ans_ques_five}
                    </p>
                  )}
                </div>
                <div className="items-start  mb-5">
                  <p className="font-semibold w-full font-sm mb-5">
                    Does your organisation screen prospective suppliers to
                    assess modern slavery risks?*
                  </p>
                  <div className="flex items-center  validation-filter ">
                    <Segment
                      value={`${FormData?.provider_regist_data?.msa_info.ans_ques_six}`}
                      onChange={(val) => msaInfoHandle(val, "ans_ques_six")}
                    >
                      <Segment.Item value="true">
                        <span>Yes</span>
                      </Segment.Item>
                      <Segment.Item value="false">
                        <span>No</span>
                      </Segment.Item>
                    </Segment>
                  </div>
                  {contactError.ans_ques_six && (
                    <p className="text-xs text-red-600 ">
                      {contactError.ans_ques_six}
                    </p>
                  )}
                </div>
                <div className="items-start mb-5">
                  <h4 className="font-bold text-gray-700 text-base mb-5">
                    Attach The Modern Safety Statement
                  </h4>
                  <div className="Pflex items-center  flex-1 validation-filter ">
                    <div className="columns-input">
                      <div className="hideIcons wrap-doc">
                        {!FormData?.tempMsaDoc?.endsWith?.(".pdf") ? (
                          <Upload
                            draggable
                            accept=".pdf"
                            uploadLimit={1}
                            className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                            onChange={(e) => handleFileChange(e, "tempMsaDoc")}
                            onFileRemove={(e) =>
                              handleFileRemove(e, "tempMsaDoc")
                            }
                            maxFileSize={25 * 1024 * 1024}
                          >
                            <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
                              <img
                                src="/assets/icons-svg/upload.svg"
                                alt=""
                                className="w-4"
                              />
                            </div>
                            <span className="text-blue-700 font-semibold">
                              Click to upload
                            </span>
                            <span className="text-xs"> or drag and drop</span>
                            <p className="text-gray-600 darks:text-gray-400 font-inter">
                              PDF only. Max 25mb.
                            </p>
                            {tempMsaDocError && (
                              <p className="text-xs text-red-600 mt-1">
                                {tempMsaDocError}
                              </p>
                            )}
                          </Upload>
                        ) : (
                          <div className="p-4 rounded-xl border border-gray-300 w-full flex items-center justify-between">
                            <div className="flex  gap-4  items-center">
                              <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                                <img
                                  src="/assets/icons-svg/upload-pdf.svg"
                                  alt=""
                                />
                              </span>
                              <div className="flex flex-col  items-center">
                                <span className="text-gray-700 text-xs font-medium">
                                  {FormData.tempMsaDoc}
                                  {/* {checkPdf?.name} */}
                                </span>
                                {/* <span className="text-gray-600 text-xs">
                  {checkPdf?.size
                      ? `${(checkPdf?.size / 1024 / 1024).toFixed(2)} MB`
                      : `${(props.size.size / 1024).toFixed(2)} MB`}
                  </span> */}
                              </div>
                            </div>
                            <Button
                              onClick={(e) => handleFileRemove(e, "tempMsaDoc")}
                            >
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#344054"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </div>
                        )}

                        {uploadedFiles && uploadedFiles.files && (
                          <div className="upload-file flex items-center justify-between mt-2">
                            <div className="upload-file-left flex items-center">
                              <div className="upload-file-thumbnail">
                                <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                                  <img src={PdfFile} alt="" />
                                </span>
                              </div>
                              <a
                                href={uploadedFiles.files[0].file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="upload-file-name font-medium text-xs text-gray-700">
                                  {uploadedFiles.files[0].fileName}
                                </span>
                              </a>
                            </div>
                            {/* <span className="upload-file-remove w-5" onClick={() => handleRemoveFile(item)}> */}
                            {/* <span className=""><img src={trashIcon} alt="" /></span>
                          </span> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className="flex mt-7 justify-end form-footer">
              <BtnLight
                disabled={buttonDisabled || buttonDisabled2}
                className="w-auto px-6 mr-2"
                onClick={SubmitData}
              >
                {/* {(buttonDisabled2) ? <ThreeDotLoader /> : " Save and continue another time"} */}
                Save and continue another time
              </BtnLight>

              <BtnPrimary
                className="w-auto"
                disabled={buttonDisabled || buttonDisabled2}
                onClick={finalSaveData}
              >
                <div
                  className="flex justify-center items-center px-6"
                  to="step-two"
                >
                  {buttonDisabled ? <ThreeDotLoader /> : "Next"}

                  <img
                    src="assets/icons-svg/arrow-right.svg"
                    className="ml-2"
                    alt=""
                  />
                </div>
              </BtnPrimary>
            </div>
            <div className="flex justify-center mt-6 show-on-mobile">
              <Link
                to="/login"
                className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex  items-center"
              >
                {" "}
                Back to log in
              </Link>
            </div>
          </form>
        )}
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/building.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Connect with &nbsp;
              <span>
                {clientData.length > 0 && FormData?.client_trading_name
                  ? clientData
                      .filter(
                        (option) =>
                          option?.label === FormData.client_trading_name
                      )
                      .map((option) => option.label)
                      .join(", ")
                  : "No clients found"}
              </span>
            </h5>

            <p className="text-gray-600 ">
              Ensure a successful connection with the client by completing these
              steps
            </p>
          </div>
        </div>
        <ConnectClient
          GetProviderChecklistByClient={GetProviderChecklistByClient}
          onDialogClose={onDialogClose}
          // openInvoice={openInvoice}
          setGetProviderChecklistByClient={setGetProviderChecklistByClient}
        />
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Your registration completed successfully! Now, please log in.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </div>
  );
};
export default ContractorRegistrationClient;
