import React, { useState, useEffect } from "react";
import Heading from "../../../common/element/Heading";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../common/element/Buttons/btnLight";

import { RejectProviderInvitation } from "../../../actions/public/RejectContractorInvitation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Validation from "../../../common/404/Validations";
import { notify } from "react-notify-toast";
import { GetProviderInvitationById } from "../../../actions/public/GetContractorInvitationById";

import useUnauthorizedHandler from "../../../common/snippet/useUnauthorizedHandler";
import { GetInvitedProviderChecklist } from "../../../actions/provider-portal/registration/GetInvitedProviderChecklist";
import Login from "../../auth/Login/Login";
import { UpdateCheckExistUser } from "../../../redux/classes/provider-portal/checkExistUser";
import { decryptId } from "../../../common/encryptDecrypt/encryptdecrypt";
const InvitationRejected = () => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const { checkExistUser } = useSelector((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [isExpired, SetisExpired] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [error, Seterror] = useState("");
  const contractor_invite_id = decryptId(
    searchParams.get("invite_provider_id").replace(/\s/g, "+")
  );

  const dispatch = useDispatch();
  const [email, SetEmail] = useState("");
  const [formData, SetformData] = useState({
    invite_provider_id: contractor_invite_id,
    reject_invite_message: "",
    providerName: "",
    // trading_name: "",
    // client_org_id: ""
  });

  const [checkLogin, SetCheckLogin] = useState(false);
  // Submit Rejection function
  const RejectContractorInvitationBtn = () => {
    const wordCount = formData.reject_invite_message.split(/\s+/).length;
    const InductionName = Validation({
      value: formData.reject_invite_message,
      Validations: { required: true },
    });
    if (!InductionName.valid) {
      Seterror(InductionName.error_msg);
    } else {
      if (wordCount <= 250) {
        dispatch(RejectProviderInvitation(formData, ipAddress)).then(
          (response) => {
            if (response.success) {
              navigate("/invitationFeedback");
              dispatch(
                UpdateCheckExistUser({
                  checkUser: false,
                  path: "",
                  login: false,
                })
              );
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          }
        );
      } else {
        notify.show("value too long for type character varying(255)", "error");
      }
    }
  };

  // Form Handle
  const handleChange = (e) => {
    Seterror("");
    SetformData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const GetData = () => {
    if (AllContactType) {
      const contactTypeId = AllContactType.find(
        (item) => item.contact_type === "provider primary"
      )?.contact_type_id;
      dispatch(
        GetProviderInvitationById({
          invite_provider_id: contractor_invite_id,
          contact_type_id: contactTypeId,
        })
      ).then((response) => {
        if (!response.success) {
          navigate("/expire-link");
          SetisExpired(false);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
          SetformData((prevalue) => {
            return {
              ...prevalue,
              ["providerName"]:
                response.data.invite_data.invitedProvider.providerName,
            };
          });
          SetisExpired(true);
        }
      });
    }
  };

  useEffect(() => {
    const tradingNameFromURL = searchParams.get("trading_name");
    const client_org_id = searchParams.get("client_org_id");
    // setTradingName(tradingNameFromURL); // Update state with trading_name

    // SetformData((previousData) => ({
    //   ...previousData,
    //   trading_name: tradingNameFromURL,
    //   client_org_id: client_org_id,
    // }))

    dispatch(
      GetInvitedProviderChecklist({
        invite_provider_id: contractor_invite_id,
        isRejectAttempt: true,
      })
    ).then((response) => {
      if (response.success) {
        SetEmail(response?.data?.inviteData?.email);
        if (response?.data?.inviteData?.invitedProvider?.user_id == null) {
          GetData();
        } else {
          localStorage.setItem("Conserve_http_Authenticated", true);
          if (
            response?.data?.userValidate?.is_worker ||
            response?.data?.userValidate?.fa_user_permission_id
          ) {
            if (checkExistUser.login) {
              SetCheckLogin(false);
              SetisExpired(true);
              GetData();
            } else {
              SetCheckLogin(true);
              dispatch(
                UpdateCheckExistUser({
                  checkUser: true,
                  path: window.location.pathname + window.location.search,
                  login: false,
                })
              );
              GetData();
            }
          } else {
            SetCheckLogin(false);
            SetisExpired(true);
            GetData();
          }

          // navigate("/login");
        }
      } else {
        navigate("/expire-link");
      }
    });
  }, [checkExistUser.login]);

  // GetContacttype id
  // useEffect(() => {
  //   if (AllContactType) {
  //     const contactTypeId = AllContactType.find(
  //       (item) => item.contact_type === "provider primary"
  //     )?.contact_type_id;
  //     dispatch(
  //       GetProviderInvitationById({
  //         invite_provider_id: contractor_invite_id,
  //         contact_type_id: contactTypeId,
  //       })
  //     ).then((response) => {
  //       if(!response.success){
  //         navigate("/expire-link")
  //         SetisExpired(false)
  //       } else if (response.status === 401) {
  //         handleUnauthorizedAccess()
  //       }
  //       else{
  //         SetformData((prevalue)=>{
  //           return{
  //             ...prevalue,["providerName"]:response.data.invite_data.invitedProvider.providerName
  //           }
  //         })
  //         SetisExpired(true)
  //       }
  //     });
  //   }
  // }, []);
  const updateStatus = () => {
    SetCheckLogin(false);
  };
  if (checkLogin) {
    return <Login email={email} reject={true} updateStatus={updateStatus} />;
  } else if (isExpired) {
    return (
      <div
        style={{ backgroundColor: `#eff8ff` }}
        className="flex justify-center min-h-screen"
      >
        <div className="min-h-screen justify-center flex  py-12 px-6">
          <div className="flex-col items-center justify-center flex">
            <div className="flex items-center mb-6">
              <img
                className="brand-img"
                src="/assets/logo.svg"
                alt="conserve"
              />
            </div>
            <Heading className="text-center mb-3">
              Please provide a reason why you've <br /> rejected the Client's
              invitation
            </Heading>
            <p className="text-base text-gray-600 text-center mb-6 font-medium">
              Your feedback will help us understand your reasoning and enable us
              to improve <br />
              our services accordingly.
            </p>
            <textarea
              onChange={handleChange}
              name={"reject_invite_message"}
              className="w-full border-gray-300 rounded-lg h-44"
              placeholder="Enter a message..."
            ></textarea>
            {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
            <div className=" mt-6 px-10 w-full flex justify-end gap-2">
              <BtnLight className="w-auto px-5">
                It was an accident, I want to accept invite
              </BtnLight>
              <BtnPrimary
                onClick={() => RejectContractorInvitationBtn()}
                className="w-auto px-5"
              >
                Send reason
              </BtnPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default InvitationRejected;
