import axios from "axios";
import * as actionType from "../../constant/actionType";
export const ConserveUserPersonalNotifPermission =
  (getAllData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/ConserveUserPersonalNotifPermission`,
        params: getAllData,
      });
      return response?.data;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching notifications:", error);
      return null; // Return null or handle the error as needed
    }
  };
