import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import SitesList from "./SitesList";
import AddSite from "../../Dialog/AddSite";
import PlusImg from "../../../../../../assets/icons-svg/plus.svg";
import HomeImg from "../../../../../../assets/icons-svg/home-smile.svg";
import * as userActions from "../../../../../../actions/index";
import ReactPaginate from "react-paginate";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
import SearchImg from "../../../../../../assets/icons-svg/search-lg.svg";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import { setDefaultTab } from "../../../../../../redux/classes/Admin-Client/shiftTabSlice";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
const Sites = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const activeTab = useSelector((state) => state.tabChange);
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
  }
  const [totalPages, setTotalPages] = useState(1);
  const [allSiteData, setAllSiteData] = useState("");
  const [countData, setCountData] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [filterState, setFilterState] = useState({
    sort: "site_name",
    order: "desc",
    page: 1,
    limit: 10,
    search: "",
  });
  const [loadData, setLoadData] = useState(false);
  const [getSitesCalled, setGetSitesCalled] = useState(true);
  const functionAssignmentId = useSelector(
    (state) => state.functionAssignmentId.functionAssignmentId
  );

  useEffect(() => {
    if (activeTab == "tab5Click") {
      setTimeout(() => {
        dispatch(setDefaultTab(""));
      }, 500);
    }
  }, [activeTab]);
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },
        {
          label: props?.clientData?.trading_name,
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(
            id
          )}`,
        },
        {
          label: "Sites",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, [props?.clientData]);

  // const handleSeachChange = (e) => {
  //   setsearchTerm(e.target.value);
  //   setfilterpage(1);
  // };
  const handleSearchChange = (e) => {
    setGetSitesCalled(true);
    setFilterState({
      ...filterState,
      search: e.target.value.trim(),
      page: 1, // Reset page when searching
    });
  };

  const inputchangeSearch = useCallback(debounce(handleSearchChange), []);
  // const handlePageChange = ({ selected }) => {
  //   setfilterpage(selected + 1);
  //   //  GetAllComplianceDocsOfClient(selected + 1, filterlimit)
  // };
  const handlePageChange = ({ selected }) => {
    setGetSitesCalled(true);
    setFilterState({
      ...filterState,
      page: selected + 1,
    });
  };

  // const handleSort = (column) => {
  //   if (column === filtersort) {
  //     setFilterOrder(filterorder === "asc" ? "desc" : "asc");
  //   } else {
  //     setFiltersort(column);
  //     setFilterOrder(filterorder === "asc" ? "desc" : "asc");
  //   }
  // };
  const handleSort = (column) => {
    setGetSitesCalled(true);
    if (column === filterState.sort) {
      // Toggle the sorting order if the same column is clicked
      setFilterState({
        ...filterState,
        order: filterState.order === "asc" ? "desc" : "asc",
      });
    } else {
      setFilterState({
        ...filterState,
        sort: column,
        order: "asc", // Set the default order when changing the sorting column
        page: 1, // Reset page when changing the sorting column
      });
    }
  };

  const updateDataFromApi = (arrayData) => {
    let siteData = [];
    for (let key in arrayData) {
      siteData.push({
        siteName: arrayData[key].site_name,
        siteId: arrayData[key].site_id,
        siteState: arrayData[key].state_name,
        stateId: arrayData[key].state_id,
        siteAddress: arrayData[key].site_address,
        siteStatus: arrayData[key].is_site_active,
      });
    }
    setAllSiteData(siteData);
  };

  const GetAllSitesOfClient = useCallback(async () => {
    dispatch(
      userActions.GetAllSitesOfClient(functionAssignmentId, filterState)
    ).then((response) => {
      if (response.success === true) {
        setLoadData(true);
        const arrayData = response.data.rows;
        updateDataFromApi(arrayData);
        setCountData(response.data.count);
        setTotalPages(Math.ceil(response.data.count / filterState.limit));
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
        setLoadData(true);
      } else {
        setLoadData(true);
      }
    });
    // setGetSitesCalled(true);
    // eslint-disable-next-line
  }, [dispatch, functionAssignmentId, filterState]);

  useEffect(() => {
    // if (getSitesCalled) { // Check if the function has already been called
    GetAllSitesOfClient();
    // setGetSitesCalled(false);
    // }
    // GetAllSitesOfClient();
  }, [
    filterState.limit,
    filterState.page,
    filterState.sort,
    filterState.order,
    filterState.search,
  ]);

  const [dialogIsOpen, setIsOpen] = useState(false);
  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    !isSaving ? setIsOpen(false) : setIsOpen(true);
  };
  
  //call permission api
  // const GetSpecificUserRole = useCallback(async () => {
  //   if (role_assignment_id) {
  //     dispatch(userActions.GetSpecificUserRole(role_assignment_id)).then(
  //       (response) => {
  //         if (response.success === true) { }
  //         else if (response.status === 401) {
  //           handleUnauthorizedAccess()
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch]);
  // useEffect(() => {
  //   GetSpecificUserRole()
  // }, [])

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 px-2">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={SearchImg} alt="" className="h-5" />
            </div>
            <input
              type="text"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
              name={"search"}
            />
          </div>
        </div>
        <div className="flex">
          <BtnPrimary
            onClick={() => openDialog()}
            variant="solid"
            className="w-auto h-9 flex items-center justify-center text-xs px-3.5"
            type="button"
          >
            Add Site{" "}
            <span className="ml-3">
              <img src={PlusImg} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>
      <SitesList
        allSiteData={allSiteData}
        handleSort={handleSort}
        filtersort={filterState.sort}
        filterorder={filterState.order}
        onDialogClose={onDialogClose}
        setIsOpen={setIsOpen}
        GetAllSitesOfClient={GetAllSitesOfClient}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterState.page}
            displayPage={allSiteData?.length}
            UpdatePageLimit={setFilterState}
            allfilters={filterState}
          />

          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              // pageLinkClassName={'page-link'}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterState.page - 1}
            />
          )}
        </div>
      )}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={HomeImg} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a Site
            </h5>
            <p className="text-gray-600 ">
              Create a new Site under the Client's profile to manage Inductions,
              Inductees, Visitor Log and more.
            </p>
          </div>
        </div>
        <AddSite
          onDialogClose={onDialogClose}
          GetAllSitesOfClient={GetAllSitesOfClient}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default Sites;
