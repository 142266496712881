import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const UpdateProviderClientContact = (ApiData, path, ipAddress) => async (dispatch) => {
  let apiPath = path === "client" ? "UpdateProviderClientContact" : "UpdateProviderContact";
  
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        userip: ipAddress, // Added IP address header
      },
      url: `${actionType.environment_url}/api/admin/provider/${apiPath}`,
    });

    return response.data;
  } catch (error) {
    console.error("Error updating contact:", error);
    return error.message;
  }
};
