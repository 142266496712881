import React, { useCallback, useEffect, useState } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import { notify } from "react-notify-toast";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../../common/snippet/Debouncing";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import ClientInput from "../../../../../common/element/Input/clientInput";
import ReactPaginate from "react-paginate";
import Segment from "../../../../../common/element/Segment";
import Validations from "../../../../../common/404/Validations";
import Dropdown from "../../../../../common/element/Dropdown";
import { CSVLink } from "react-csv";
import Button from "../../../../../common/element/Buttons";
import { inviteWorkerCsvView } from "../../../../../actions/admin-worker/InviteWorkerCSV";
import MultiSelect from "@mui/material/Select";
import Td from "../../../../../common/element/Table/Td";
import TBody from "../../../../../common/element/Table/TBody";
import Th from "../../../../../common/element/Table/Th";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tr from "../../../../../common/element/Table/Tr";
import Table from "../../../../../common/element/Table";
import Scrollbar from "../../../../../common/element/ScrollBar";
import THead from "../../../../../common/element/Table/THead";
import Checkbox from "../../../../../common/element/Checkbox";
import { reSetData } from "../../../../../redux/classes/AdminContractor/inviteContractorWorkerCsvViewSlice";
import { InviteWorkerFromCsvDetails } from "../../../../../actions/admin-provider/InviteWorkerFromCsvDetails";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../../../common/element/Select";
import Heading from "../../../../../common/element/Heading";
import ViewAllList from "./ViewAllList";
import ScrollBar from "../../../../../common/element/ScrollBar/ScrollBar";
import { AdminGetAllWorkers } from "../../../../../actions/admin-worker/AdminWorkerAPI";
import { GetIndividualByEmail } from "../../../../../actions/public/GetIndividualByEmail";
import { GetAllIndividualListForWorkerInvites } from "../../../../../actions/admin-worker/GetAllIndividualListForWorkerInvites";
import { InviteWorker } from "../../../../../actions/admin-worker/InviteWorker";
import {
  GetAllProviderList,
  GetAllClientsOfProviderList,
} from "../../../../../actions/admin-worker/GetAllProviders";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Workers = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Worker",
          url: "/admin/worker/view-all-worker",
          home: "/admin/worker/dashboard",
        },
      ])
    );
  }, []);
  const GetFunction = useSelector((state) => state);
  const GetFunctionAll = useSelector(
    (state) => state?.allFunctions?.allFunction
  );
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const [addWorkerData, setAddWorkerData] = useState({
    // trading_name: "provider_trading" /* provider trading name */,
    // provider_fa_id: "488cbc2e-35a2-4ecd-91ae-a10af4155849",
    workersInviteData: [
      {
        individual_id: "",
        email: "",
        first_name: "",
        last_name: "",
      },
    ],
    // workerClientsIds: "",
  });
  const [loadData, setLoadData] = useState(false);
  const [contactTypeId, setContactTypeId] = useState("");
  const [apiCalls, setApiCalls] = useState(true);
  const [allWorkersDetails, setAllWorkersDetails] = useState([]);
  const [countData, setCountData] = useState(0);
  const [selectedworker, setSelectedworker] = useState(null);
  const [providerOrgId, setProviderOrgId] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [providerFunId, setProviderFunId] = useState("");
  const [providerFaId, setProviderFaId] = useState("");
  const [selectedProvType, setSelectedProvType] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientOrgIds, setSelectedClientOrgIds] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [selectedWorkerOpt, setOptionWorkerOpt] = useState("");
  const [searchWorker, setSearchWorker] = useState("");
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [singleSegmentValue, setSingleSegmentValue] = useState("workers");
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [checkExistingWorker, setcheckExistingWorker] = useState(false);
  const [search, setSearch] = useState("");
  const [customOptions, setcustomOptions] = useState([]);
  const [inviteData, setinviteData] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const [validateData, setvalidateData] = useState(false);
  const [dialogNextCsv, setDialogNextCsv] = useState(false);
  const [refetch, setrefetch] = useState(false);
  const [finalCsvData, setfinalCsvData] = useState([]);
  const [checkRequired, SetcheckRequired] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dialogOpenCSV, setCSV] = useState(false);
  const [allClient, setAllClient] = useState([]);
  const [updateCsvData, setUpdateCsvData] = useState(false);
  const [selectedClientType, setSelectedClientType] = useState("");
  const [personName, setPersonName] = React.useState([]);
  const [dialogUnsaveOpen, setUnsaveOpen] = useState(false);
  const [checkError, setCheckError] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [optionsWorker, setOptionWorker] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    // function_assignment_id: "488cbc2e-35a2-4ecd-91ae-a10af4155849",
    page: 1,
    limit: 10,
    sort: "first_name",
    order: "desc",
    search: "",
  });
  const [errorValidation, setErrorValidation] = useState({
    email: "",
    fistName: "",
    lastName: "",
    workerClientsIds: "",
    providerNameError: "",
    clientNameError: "",
    // Initialize worker-specific errors
    ...addWorkerData?.workersInviteData?.reduce((acc, _, index) => {
      acc[`firstNameError_${index}`] = "";
      acc[`lastNameError_${index}`] = "";
      acc[`emailError_${index}`] = "";
      acc[`conserveTeamError_${index}`] = "";
      acc[`alreadyWorkerError_${index}`] = "";
      return acc;
    }, {}),
  });

  const hasConserveTeamError = Object.keys(errorValidation).some(
    (key) => key.startsWith("conserveTeamError_") && errorValidation[key]
  );
  const hasAlreadyWorkerError = Object.keys(errorValidation).some(
    (key) => key.startsWith("alreadyWorkerError_") && errorValidation[key]
  );
  const getAllWorkersData = useCallback(() => {
    dispatch(AdminGetAllWorkers(getAllinvitedData)).then((response) => {
      if (response?.success) {
        const arrayData = response?.data;
        setAllWorkersDetails(arrayData?.rows);
        setLoadData(true);
        setCountData(arrayData?.count);
        setTotalPages(Math.ceil(arrayData?.count / getAllinvitedData?.limit));
      } else if (response?.status === 401) {
        setLoadData(true);
        handleUnauthorizedAccess();
      }
    });
  }, [dispatch, getAllinvitedData]);

  useEffect(() => {
    getAllWorkersData();
  }, [getAllinvitedData]);

  const handleSort = (column) => {
    if (column === getAllinvitedData?.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    }
  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    console.log(
      "Total pages handlePageChange ",
      Math.ceil(countData / getAllinvitedData?.limit)
    );

    setTotalPages(Math.ceil(countData / getAllinvitedData?.limit));
  };
  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const getPageNumber = () => {
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1,
      }));
      setTotalPages(
        Math.ceil(
          countData / newLimit?.limit
            ? newLimit?.limit
            : newLimit
            ? newLimit
            : 10
        )
      );
      console.log(
        "Total pages updatePageLimit ",
        countData / newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10
      );
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };

  useEffect(() => {
    if (AllContactType) {
      const contactTypeId = AllContactType.find(
        (item) => item?.contact_type === "provider primary"
      )?.contact_type_id;
      setContactTypeId(contactTypeId);
    }
    if (GetFunctionAll) {
      const providerFunId = GetFunctionAll?.find(
        (item) => item?.function_name === "provider"
      )?.function_id;
      setProviderFunId(providerFunId);
    }
  }, []);
  const GetProviderListData = useCallback(() => {
    if (search?.trim().length >= 3) {
      setSelectedProvType(null);
      dispatch(GetAllProviderList(search, providerFunId, contactTypeId)).then(
        (response) => {
          if (response?.success) {
            // setProviderFaId(response?.data?.[0]?.function_assignment_id);
            // setProviderOrgId(response?.data?.[0]?.organisation_id);
            const FilterData = response?.data?.map((item) => {
              return {
                label: item?.trading_name,
                organisation_id: item?.organisation_id,
                trading_name: item?.trading_name,
                function_assignment_id: item?.function_assignment_id,
              };
            });
            if (FilterData) {
              setSearchOptions(FilterData);
            }
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        }
      );
    }
  }, [dispatch, search]);

  useEffect(() => {
    GetProviderListData();
  }, [search]);

  const GetAllClientsOfProviderLists = useCallback(() => {
    if (providerFaId && selectedProvType?.organisation_id) {
      dispatch(GetAllClientsOfProviderList(providerFaId)).then((response) => {
        if (response?.success) {
          if (response?.data.length > 0) {
            const filteredData = response?.data.map((client) => ({
              label: client?.trading_name,
              value: client?.organisation_id,
              organisation_id: client?.organisation_id,
              function_assignment_id: client?.function_assignment_id,
              trading_name: client?.trading_name,
            }));
            setClientOptions(filteredData);
          }
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  }, [dispatch, providerFaId, selectedProvType?.organisation_id]);
  useEffect(() => {
    GetAllClientsOfProviderLists();
  }, [providerFaId, selectedProvType?.organisation_id]);

  const GetAllWorkerData = useCallback(() => {
    if (searchWorker && providerOrgId) {
      dispatch(
        GetAllIndividualListForWorkerInvites(searchWorker, providerOrgId)
      ).then((response) => {
        if (response?.success) {
          const FilterData = response?.data?.map((item) => {
            return {
              // label: `${item?.first_name} ${item?.last_name}`,
              label: item?.email,
              email: item?.email,
              first_name: item?.first_name,
              individual_id: item?.individual_id,
              last_name: item?.last_name,
              user_id: item?.user_id,
              is_conserve_team: item?.is_conserve_team,
              worker_id: item?.worker_id,
            };
          });
          setOptionWorker(FilterData);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  }, [dispatch, searchWorker]);

  useEffect(() => {
    GetAllWorkerData();
  }, [searchWorker]);

  //  To check Correct Email or not
  function isEmail(value) {
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
    return false;
  }
  const openDialog = () => {
    setIsOpen(true);
    setinviteData([]);
    setcheckExistingWorker(false);
  };
  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
      setErrorValidation({
        email: "",
        fistName: "",
        lastName: "",
        workerClientsIds: "",
        providerNameError: "",
        clientNameError: "",
        ...addWorkerData?.workersInviteData?.reduce((acc, _, index) => {
          acc[`firstNameError_${index}`] = "";
          acc[`lastNameError_${index}`] = "";
          acc[`emailError_${index}`] = "";
          acc[`conserveTeamError_${index}`] = "";
          acc[`alreadyWorkerError_${index}`] = "";
          return acc;
        }, {}),
      });
      setSelectedClientOrgIds([]);
      setSearchOptions([]);
      setSelectedProvType(null);
      setSelectedClient(null);
      setOptionWorker([]);
      setClientOptions([]);
      setProviderFaId("");
      setProviderOrgId("");
      setCheckError(false);

      setAddWorkerData({
        // trading_name: "provider_trading" /* provider trading name */,
        // provider_fa_id: "488cbc2e-35a2-4ecd-91ae-a10af4155849",
        workersInviteData: [
          {
            individual_id: "",
            email: "",
            first_name: "",
            last_name: "",
          },
        ],
        // workerClientsIds: "",
      });
      setPersonName([]);
    }
  };
  const handleInviteWorkers = () => {
    let validationCheck = true;
    // Validate worker client IDs
    // const workerClientsIds = Validations({ value: selectedClientOrgIds?.[0], Validations: { required: true } });
    // if (!workerClientsIds.valid) {
    //     setErrorValidation((prevValue) => ({ ...prevValue, workerClientsIds: workerClientsIds.error_msg }));
    //     validationCheck = false;
    // }

    // Validate provider
    const providerValidation = Validations({
      value: selectedProvType?.label,
      Validations: { required: true },
    });
    if (!providerValidation.valid) {
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        providerNameError: providerValidation.error_msg,
      }));
      validationCheck = false;
    }

    // Validate client  selectedClient
    const clientValidation = Validations({
      value: selectedClientOrgIds?.[0],
      Validations: { required: true },
    });
    if (!clientValidation.valid) {
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        clientNameError: clientValidation.error_msg,
      }));
      validationCheck = false;
    }

    // Validate workers' fields
    let workersValidation = true;

    setErrorValidation((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      addWorkerData?.workersInviteData.forEach((item, index) => {
        if (item?.worker_id) {
          updatedErrors[`alreadyWorkerError_${index}`] =
            "This worker is already associated with this provider. Please select a different worker.";
          workersValidation = false;
        }
        if (item?.is_conserve_team == true) {
          updatedErrors[`conserveTeamError_${index}`] =
            "Conserve team members cannot be added via this form.";
          workersValidation = false;
        }
        if (!item?.first_name) {
          updatedErrors[`firstNameError_${index}`] = "This field is required!";
          workersValidation = false;
        }
        if (!item?.last_name) {
          updatedErrors[`lastNameError_${index}`] = "This field is required!";
          workersValidation = false;
        }
        if (!item?.email) {
          updatedErrors[`emailError_${index}`] = "This field is required!";
          workersValidation = false;
        }
        if (item?.email) {
          updatedErrors[`emailError_${index}`] = "";
        }
      });
      return updatedErrors;
    });

    if (!workersValidation) {
      validationCheck = false;
    }

    // Proceed with submission if all validations pas
    if (validationCheck) {
      if (!inviteData?.is_conserve_team) {
        setButtonDisabled(true);
        const payload = {
          provider_org_id: selectedProvType?.organisation_id
            ? selectedProvType?.organisation_id
            : "",
          trading_name: selectedProvType?.trading_name
            ? selectedProvType?.trading_name
            : "" /* provider trading name */,
          provider_fa_id: providerFaId ? providerFaId : "", //"488cbc2e-35a2-4ecd-91ae-a10af4155849",
          workersInviteData: addWorkerData?.workersInviteData
            ? addWorkerData?.workersInviteData
            : [],
          workerClientsIds:
            selectedClientOrgIds.length > 0
              ? selectedClientOrgIds
              : [] /* client organisation id */,
        };
        dispatch(InviteWorker(payload))
          .then((response) => {
            if (response?.success) {
              setAddOpen(true);
              setButtonDisabled(false);
              setIsOpen(false);
              setinviteData([]);
              setrefetch(!refetch);
            } else if (response?.status === 409) {
              setButtonDisabled(false);
              notify.show(response?.message, "error");
            } else if (response?.status === 401) {
              handleUnauthorizedAccess();
              setButtonDisabled(false);
            }
          })
          .catch((error) => {
            // Handle error
            setButtonDisabled(false);
          })
          .finally(() => {
            setButtonDisabled(false);
          });
      } else {
        notify.show("This worker is not invited", "error");
      }
    } else {
      const emailValidation = Validations({
        value: inviteData.email,
        Validations: { required: true, email: true },
      });
      if (!emailValidation.valid) {
        setvalidateData(true);
        SetcheckRequired(emailValidation.error_msg);
      } else {
        setcheckExistingWorker(true);
      }
    }
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    onDialogClose();
    setrefetch(!refetch);
  };

  const openUnsaveDialog = () => {
    setUnsaveOpen(true);
    setIsOpen(false);
  };

  // Check Existing Email
  const HandleChange = (e, identifier) => {
    const value = e?.target
      ? e?.target?.value?.replace(/\s/g, "")
      : e.replace(/\s/g, "");

    if (identifier === "searchWorker") {
      // Logic for the Select component (worker search)
      setSearchWorker(value);
      setvalidateData(false);
      setcustomOptions({ value, label: value });
    } else if (identifier === "search") {
      // Logic for the ClientInput component (general search)
      handleSearch({ target: { name: "search", value } });
    }
  };
  const inputchangehandler = useCallback(debounce(HandleChange), []);

  // Form data Handle
  const formHandleData = (e, index, fieldName) => {
    const { value } = e.target;

    setAddWorkerData((prevState) => {
      const updatedWorkersInviteData = [...prevState.workersInviteData];
      updatedWorkersInviteData[index][fieldName] = value;

      return {
        ...prevState,
        workersInviteData: updatedWorkersInviteData,
      };
    });

    setErrorValidation((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (fieldName === "first_name") {
        updatedErrors[`firstNameError_${index}`] = "";
      } else if (fieldName === "last_name") {
        updatedErrors[`lastNameError_${index}`] = "";
      }
      return updatedErrors;
    });
  };

  // FilTER DATA SHOW

  // Check Tab
  // Update Main Tabs Function
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val[0]);
      SetgetAllinvitedData((prevalue) => {
        return { ...prevalue, ["sort"]: "created_date" };
      });
    },
    [setSingleSegmentValue]
  );

  // Import CSV Function
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 bg-blue-50 border-gray-300 rounded-lg border font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
          stroke="#344054"
          strokeWidth="1.667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Import CSV
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m6.394 9 6 6 6-6"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
  useEffect(() => {
    if (GetFunction.inviteContractorWorkerCsvData?.data?.data?.success) {
      if (updateCsvData) {
        dialogCloseCSV();
        setAddOpen(true);
        dispatch(reSetData());
        setCsvData([]);
      }
    } else {
      if (
        !GetFunction.inviteContractorWorkerCsvData.loading &&
        GetFunction.inviteContractorWorkerCsvData.data?.data !== undefined
      ) {
        setCsvData(GetFunction.inviteContractorWorkerCsvData.data?.data);
        let filterdata =
          GetFunction?.inviteContractorWorkerCsvData?.data?.data.filter(
            (items) => items.is_validated == true
          );
        // Now, create a new filtered array that excludes the 'action' and 'invite_id' properties
        if (filterdata) {
          const filteredData = filterdata.map((item) => {
            // Create a new object with only the desired properties
            const { action, is_validated, invite_id, ...filteredItem } = item;
            return filteredItem;
          });

          setfinalCsvData(filteredData);
        } else {
          setfinalCsvData([]);
        }
      }
    }
  }, [GetFunction]);

  const openCSV = () => {
    setCSV(true);
  };

  const dialogCloseCSV = (e) => {
    setCSV(false);
    onCloseNextCsv();
    dispatch(reSetData());
    setCsvData([]);
  };

  // Upload CSV File
  const handleFileChange = (e) => {
    dispatch(reSetData());
    setCsvData([]);
    const file = e.target.files[0];
    let payload = {
      provider_org_id: selectedProvType?.organisation_id
        ? selectedProvType?.organisation_id
        : "",
      // function_assignment_id: "488cbc2e-35a2-4ecd-91ae-a10af4155849",
      // provider_org_id: selectedProvType?.organisation_id ? selectedProvType?.organisation_id : "",
      // trading_name: selectedProvType?.trading_name ? selectedProvType?.trading_name : "", /* provider trading name */
      function_assignment_id: providerFaId ? providerFaId : "", //"488cbc2e-35a2-4ecd-91ae-a10af4155849",
      invite_worker_csv: file,
    };
    dispatch(inviteWorkerCsvView(payload));
    openCSV();
    // setUpdateCsvData(false);
  };

  // CSV Default Data Array
  const CSVData = [
    ["first_name", "last_name", "email"],
    ["brandon", "Hodges", "brandon.hodges33@conserv.com"],
    ["victor", "Carr", "victor.carr33@conserv.com"],
    ["Lillian", "Hodges", "lillian.pullman@conserv.com"],
    ["Edward", "Hodges", "edward.mills@conserv.com"],
    ["Megan", "Hodges", "megan.mcdonald4555@conserv.com"],
  ];

  // Function to handle checkbox change
  const handlecheckbox = (event, index) => {
    const isChecked = event;
    const item = csvData[index];
    if (isChecked) {
      // Add the checked item to the array
      setfinalCsvData((prevCheckedItems) => [...prevCheckedItems, item]);
    } else {
      // Remove the unchecked item from the array
      setfinalCsvData((prevCheckedItems) =>
        prevCheckedItems.filter(
          (checkedItem) => checkedItem.email !== item?.email
        )
      );
    }
  };
  // Submit CSV Data
  const submitCsvData = () => {
    const providerValidation = Validations({
      value: selectedProvType?.label,
      Validations: { required: true },
    });
    if (!providerValidation.valid) {
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        providerNameError: providerValidation.error_msg,
      }));
    }

    // Validate client  selectedClient
    const clientValidation = Validations({
      value: selectedClientOrgIds?.[0],
      Validations: { required: true },
    });
    if (!clientValidation.valid) {
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        clientNameError: clientValidation.error_msg,
      }));
    }
    // Validate clientOrgIds
    if (providerValidation?.valid && clientValidation?.valid) {
      setButtonDisabled(true);
      dispatch(
        InviteWorker({
          workersInviteData: finalCsvData, // Worker data from CSV
          trading_name: selectedProvType?.trading_name
            ? selectedProvType?.trading_name
            : "" /* provider trading name */,
          provider_fa_id: providerFaId ? providerFaId : "", //"488cbc2e-35a2-4ecd-91ae-a10af4155849",
          workerClientsIds:
            selectedClientOrgIds.length > 0
              ? selectedClientOrgIds
              : [] /* client organisation Id's */,
        })
      )
        .then((response) => {
          if (response?.success) {
            setCSV(false);
            setButtonDisabled(false);
            dispatch(reSetData());
            setCsvData([]);
            setAddOpen(true);
            onCloseNextCsv();
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
            setButtonDisabled(false);
          }
        })
        .catch((error) => {
          setButtonDisabled(false);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
      setUpdateCsvData(true);
    }
  };

  const onCloseNextCsv = () => {
    if (!buttonDisabled) {
      setPersonName([]);
      setDialogNextCsv(false);
      setfinalCsvData([]);
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        providerNameError: "",
        clientNameError: "",
      }));
      setSelectedClientOrgIds([]); //
      setSearchOptions([]);
      setSelectedProvType(null); //
      setSelectedClient(null);
      setClientOptions([]);
      setProviderFaId("");
      setProviderOrgId("");
    }
  };

  const selectionClient = (selectedOption) => {
    setSelectedClientType(selectedOption);
  };

  const addWorker = () => {
    setAddWorkerData((prevState) => ({
      ...prevState,
      workersInviteData: [
        ...prevState.workersInviteData,
        { individual_id: "", email: "", first_name: "", last_name: "" },
      ],
    }));
  };

  const DeleteWorker = (indexToRemove) => {
    setAddWorkerData((prevState) => {
      const updatedWorkersInviteData = prevState.workersInviteData.filter(
        (_, index) => index !== indexToRemove
      );

      // Clear and reindex errors
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };

        // Remove errors for the worker being deleted
        delete updatedErrors[`firstNameError_${indexToRemove}`];
        delete updatedErrors[`lastNameError_${indexToRemove}`];
        delete updatedErrors[`emailError_${indexToRemove}`];
        delete updatedErrors[`conserveTeamError_${indexToRemove}`];
        delete updatedErrors[`alreadyWorkerError_${indexToRemove}`];
        // Reindex errors for the remaining workers
        const reindexedErrors = Object.keys(updatedErrors).reduce(
          (acc, key) => {
            const match = key.match(
              /^(firstNameError|lastNameError|emailError|conserveTeamError|alreadyWorkerError)_(\d+)$/
            );
            if (match) {
              const currentIndex = parseInt(match[2], 10);
              if (currentIndex > indexToRemove) {
                // Shift the index down for workers after the deleted one
                const newIndex = currentIndex - 1;
                const newKey = `${match[1]}_${newIndex}`;
                acc[newKey] = updatedErrors[key];
              } else if (currentIndex !== indexToRemove) {
                // Keep the same for workers before the deleted one
                acc[key] = updatedErrors[key];
              }
            } else {
              // Copy any unrelated errors
              acc[key] = updatedErrors[key];
            }
            return acc;
          },
          {}
        );

        return reindexedErrors;
      });

      return {
        ...prevState,
        workersInviteData: updatedWorkersInviteData,
      };
    });
  };

  const handleChange = (event) => {
    setErrorValidation((prevalue) => {
      return {
        ...prevalue,
        ["workerClientsIds"]: "",
      };
    });
    const EventValue = event.target.value;
    const filterdata = EventValue.map((item, index) => {
      return item;
    });
    setPersonName(filterdata.flat());

    setAddWorkerData((prevalue) => {
      return {
        ...prevalue,
        ["workerClientsIds"]: filterdata.flat().map((item) => item?.value),
      };
    });
  };

  const openMultiSelect = () => {
    dispatch(
      GetAllClientsOfProviderList({
        provider_fa_id: "488cbc2e-35a2-4ecd-91ae-a10af4155849",
      })
    )
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data?.rows;
          let client_data = [];
          for (let key in arrayData) {
            client_data.push({
              label: arrayData[key].trading_name,
              value: arrayData[key].organisation_id,
              organisation_id: arrayData[key].organisation_id,
              function_assignment_id: arrayData[key].function_assignment_id,
            });
          }
          const listData = [];
          if (client_data) {
            client_data.map((item) => {
              if (item?.function_assignment_id) {
                listData.push(item);
              }
            });
          }
          setAllClient(listData);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {});
  };

  // Custom option for the input value for email/abn for 1st page & email for 2nd page
  const customOption = { value: searchWorker, label: searchWorker };
  const updatedOptions = optionsWorker?.find(
    (option) => option.value === search
  )
    ? optionsWorker
    : [...optionsWorker, customOption];

  // function for Select tag for email/abn for 1st page & email for 2nd page
  // const handleSearchChange = (newInputValue) => {
  //     setSearch(newInputValue);
  // };
  const handleSearchChange = useCallback(
    debounce((newInputValue, identifier) => {
      // const trimmedInput = newInputValue.replace(/\s/g, '');
      let trimmedInput = newInputValue.trim().replace(/\s+/g, " "); // Remove leading, trailing, and extra spaces
      if (identifier === "provider") {
        setSearch(trimmedInput);
      }
      if (identifier === "client") {
        setSearchClient(trimmedInput);
      }
      if (identifier === "worker") {
        setSearchWorker(trimmedInput);
      }
    }, 500),
    []
  );
  const handleSelectChange = (selectedOption, identifier, index) => {
    if (identifier === "provider") {
      setSelectedProvType(selectedOption);
      setProviderFaId(selectedOption?.function_assignment_id);
      setProviderOrgId(selectedOption?.organisation_id);
      setSelectedClientOrgIds([]);
      setSelectedClient(null);
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        providerNameError: "",
      }));
    }
    if (identifier === "client") {
      setSelectedClient(selectedOption);
      const FilterOrgIds = selectedOption?.map((item) => item.organisation_id);
      setSelectedClientOrgIds(FilterOrgIds ? FilterOrgIds : []);
      setErrorValidation((prevErrors) => ({
        ...prevErrors,
        clientNameError: "",
      }));
    }
    if (identifier === "worker") {
      if (selectedOption?.is_conserve_team) {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`conserveTeamError_${index}`] =
            "Conserve team members cannot be added via this form.";
          return updatedErrors;
        });
      } else {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`conserveTeamError_${index}`] = "";
          return updatedErrors;
        });
      }
      // Already Worker associated with this provider Error
      if (selectedOption?.worker_id) {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`alreadyWorkerError_${index}`] =
            "This worker is already associated with this provider. Please select a different worker.";
          return updatedErrors;
        });
      } else {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`alreadyWorkerError_${index}`] = "";
          return updatedErrors;
        });
      }

      let emailAlreadyExist = false;
      setAddWorkerData((prevValue) => {
        const emailToValidate = selectedOption?.label || selectedOption?.email;
        // Check for duplicate email
        const emailMatch = prevValue?.workersInviteData?.some(
          (ele) => ele?.email === emailToValidate
        );
        if (emailMatch) {
          setErrorValidation((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            updatedErrors[`emailError_${index}`] =
              "This email address has already been entered.";
            // updatedErrors[`conserveTeamError_${index}`] = ""
            emailAlreadyExist = true;
            return updatedErrors;
          });
          // setTimeout(() => {
          //     setErrorValidation((prevErrors) => {
          //         const updatedErrors = { ...prevErrors };
          //         updatedErrors[`emailError_${index}`] = "";
          //         emailAlreadyExist=false;
          //         return updatedErrors;
          //     });
          // }, 4000);
          return prevValue; // Return the previous state to avoid changes
        }

        // Validate email format
        if (emailToValidate && !isEmail(emailToValidate)) {
          setErrorValidation((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            updatedErrors[`emailError_${index}`] =
              "Please insert a valid email address";
            emailAlreadyExist = true;
            return updatedErrors;
          });

          // setTimeout(() => {
          //     setErrorValidation((prevErrors) => {
          //         const updatedErrors = { ...prevErrors };
          //         updatedErrors[`emailError_${index}`] = "";
          //         emailAlreadyExist=false;
          //         return updatedErrors;
          //     });
          // }, 4000);
          return prevValue; // Return the previous state to avoid changes
        }

        const updatedWorkersInviteData = prevValue.workersInviteData.map(
          (item, i) => {
            if (i === index) {
              return {
                ...item,
                ...selectedOption,
                email:
                  selectedOption == null
                    ? ""
                    : selectedOption.value !== undefined
                    ? selectedOption.value
                    : selectedOption.email,
              };
            }
            return item;
          }
        );

        return {
          ...prevValue,
          workersInviteData: updatedWorkersInviteData,
        };
      });

      // Clear worker-specific errors
      if (
        selectedOption?.email &&
        selectedOption?.first_name &&
        selectedOption?.last_name
      ) {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`firstNameError_${index}`] = "";
          updatedErrors[`lastNameError_${index}`] = "";
          return updatedErrors;
        });
      }

      if (selectedOption?.label && emailAlreadyExist == false) {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`emailError_${index}`] = "";
          return updatedErrors;
        });
      }
    }
  };

  // Submit CSV Data
  const submitCsvDataNext = () => {
    // open new pannel
    dialogCloseCSV();
    setDialogNextCsv(true);
  };
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <Heading>All Workers</Heading>
        <div className="flex">
          <div className="flex gap-2 reset-dropdown">
            {/* <Select 
                        placeholder="Search"
                        className="select-options w-80" /> */}

            <div className="w-80 h-10">
              <div className="input-wrapper">
                <div className="input-suffix-start left-3.5">
                  <img
                    src="/assets/icons-svg/search-lg.svg"
                    alt=""
                    className="h-5"
                  />
                </div>
                <ClientInput
                  type="text"
                  className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Search"
                  inputchangehandler={(e) => inputchangehandler(e, "search")} // Pass identifier for general search
                  name={"search"}
                />
              </div>
            </div>
            <Dropdown renderTitle={Toggle} isOpen={true}>
              <Dropdown.Item eventKey="a">
                <CSVLink
                  className="client_portal_csv"
                  data={CSVData.map((row) =>
                    row.filter((column) => column !== "action")
                  )}
                  filename={"Sample file for worker invite.csv"}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 6.66667L8 10M8 10L4.66667 6.66667M8 10V2"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Download template</span>
                </CSVLink>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="b"
                className="cursor-pointer"
                onClick={() => setDialogNextCsv(true)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33317 1.51318V4.26688C9.33317 4.64025 9.33317 4.82693 9.40583 4.96954C9.46975 5.09498 9.57173 5.19697 9.69718 5.26088C9.83978 5.33354 10.0265 5.33354 10.3998 5.33354H13.1535M5.99984 10.0002L7.99984 12.0002M7.99984 12.0002L9.99984 10.0002M7.99984 12.0002L7.99984 8.00016M9.33317 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H10.1332C11.2533 14.6668 11.8133 14.6668 12.2412 14.4488C12.6175 14.2571 12.9234 13.9511 13.1152 13.5748C13.3332 13.147 13.3332 12.5869 13.3332 11.4668V5.3335L9.33317 1.3335Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Import file</span>
                <input />
              </Dropdown.Item>
            </Dropdown>
            <BtnPrimary
              onClick={() => openDialog()}
              className="px-2 w-32 text-12 h-9 justify-center flex gap-2 items-center"
            >
              Add Worker{" "}
              <span>
                <img src="/assets/icons-svg/plus.svg" alt="" />
              </span>
            </BtnPrimary>
          </div>
        </div>
      </div>
      <ViewAllList
        getAllinvitedData={getAllinvitedData}
        handleSort={handleSort}
        // getAllWorkersData={getAllWorkersData}
        allWorkersDetails={allWorkersDetails}
      />
      {!loadData && (
        // <div className="h-full w-full text-center mt-5"></div>
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div
          className="flex justify-between mt-3"
          style={{
            alignItems: "Right",
            listStyle: "none",
            padding: 0,
          }}
        >
          <Pagination
            totalCount={countData || 0}
            pageNumber={getAllinvitedData?.page}
            displayPage={allWorkersDetails?.length || 0}
            UpdatePageLimit={updatePageLimit}
            UpdatePage={updatePage}
            // UpdatePageLimit={SetgetAllinvitedData}
            // allfilters={getAllinvitedData}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={getAllinvitedData?.page - 1}
            />
          )}
        </div>
      )}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9979 15.5C8.82782 15.5 6.0087 17.0306 4.21389 19.406C3.8276 19.9172 3.63446 20.1728 3.64078 20.5183C3.64566 20.7852 3.81326 21.1219 4.02326 21.2867C4.29508 21.5 4.67177 21.5 5.42513 21.5H18.5707C19.324 21.5 19.7007 21.5 19.9725 21.2867C20.1825 21.1219 20.3501 20.7852 20.355 20.5183C20.3613 20.1728 20.1682 19.9172 19.7819 19.406C17.9871 17.0306 15.168 15.5 11.9979 15.5Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9979 12.5C14.4832 12.5 16.4979 10.4853 16.4979 8C16.4979 5.51472 14.4832 3.5 11.9979 3.5C9.51262 3.5 7.4979 5.51472 7.4979 8C7.4979 10.4853 9.51262 12.5 11.9979 12.5Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a worker
            </h5>
          </div>
        </div>
        <div className="">
          <div className="px-6">
            <label className="font-semibold text-base text-gray-700 block mb-1">
              Provider*
            </label>
            <div className="chooseEmail">
              <Select
                className="w-full h-9 select-options"
                placeholder="Search & select a provider"
                options={searchOptions}
                value={selectedProvType}
                onChange={(e) => handleSelectChange(e, "provider")}
                handleSearchChange={(e) => handleSearchChange(e, "provider")}
              ></Select>
              {errorValidation?.providerNameError && (
                <div className="text-red-500 text-sm mt-1 ">
                  {errorValidation?.providerNameError}
                </div>
              )}
            </div>
          </div>
          <div className="px-6 mt-5 ">
            <label className="font-semibold text-base text-gray-700 block mb-1">
              Client*
            </label>
            <div className="chooseEmail">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select a client"
                options={clientOptions}
                value={selectedClient}
                isMulti={true}
                onChange={(e) => handleSelectChange(e, "client")}
                handleSearchChange={(e) => handleSearchChange(e, "client")}
              ></Select>
              {errorValidation?.clientNameError && (
                <div className="text-red-500 text-sm mt-1 ">
                  {errorValidation?.clientNameError}
                </div>
              )}
            </div>
          </div>
          {addWorkerData?.workersInviteData?.length > 1 && (
            <div className="mt-5 px-6">
              <label className="font-semibold text-base text-gray-700 block mb-1">
                Workers*
              </label>
            </div>
          )}
          {addWorkerData?.workersInviteData?.map((item, index) => (
            <React.Fragment key={index}>
              <div className="mt-5 px-6">
                {addWorkerData?.workersInviteData.length <= 1 && (
                  <label className="font-semibold text-base text-gray-700 block mb-1">
                    Workers*
                  </label>
                )}
                <div className="chooseEmail flex gap-2 items-start">
                  <div className="w-full">
                    <Select
                      className="w-full h-10 select-options"
                      placeholder="Search a worker"
                      isClearable={
                        item?.first_name && item?.last_name ? true : false
                      }
                      isSearchable={true}
                      options={
                        updatedOptions ? updatedOptions : selectedWorkerOpt
                      }
                      value={{
                        label: `${item.email}`,
                        email: item?.email,
                        individual_id: item?.individual_id,
                        is_conserve_team: item?.is_conserve_team,
                        worker_id: item?.worker_id,
                      }}
                      onChange={(e) => handleSelectChange(e, "worker", index)}
                      handleSearchChange={(e) =>
                        handleSearchChange(e, "worker")
                      }
                    />
                    <div className="flex flex-row gap-1 mt-1">
                      {/* First priority: alreadyWorkerError */}
                      {errorValidation[`alreadyWorkerError_${index}`] && (
                        <div className="text-red-500 text-sm">
                          {errorValidation[`alreadyWorkerError_${index}`]}
                        </div>
                      )}

                      {/* Second priority: conserveTeamError, only if alreadyWorkerError doesn't exist */}
                      {!errorValidation[`alreadyWorkerError_${index}`] &&
                        errorValidation[`conserveTeamError_${index}`] && (
                          <div className="text-red-500 text-sm">
                            {errorValidation[`conserveTeamError_${index}`]}
                          </div>
                        )}

                      {/* Third priority: emailError, only if the previous two errors don't exist */}
                      {!errorValidation[`alreadyWorkerError_${index}`] &&
                        !errorValidation[`conserveTeamError_${index}`] &&
                        errorValidation[`emailError_${index}`] && (
                          <div className="text-red-500 text-sm">
                            {errorValidation[`emailError_${index}`]}
                          </div>
                        )}
                    </div>
                  </div>

                  {addWorkerData?.workersInviteData.length > 1 && (
                    <Button onClick={() => DeleteWorker(index)}>
                      <svg
                        className="my-2"
                        width="19"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#667085"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </Button>
                  )}
                </div>
                {!item?.individual_id && (
                  <div className="flex gap-2">
                    <div className="w-3/6 mt-5">
                      <label className="font-medium text-gray-700 block mb-1">
                        First Name*
                      </label>
                      <ClientInput
                        name="first_name"
                        readOnly={item?.individual_id ? true : false}
                        inputchangehandler={(e) =>
                          formHandleData(e, index, "first_name")
                        }
                        value={item?.first_name}
                        className="h-9 w-full"
                        placeholder="Enter Contact's first name"
                      />
                      {errorValidation[`firstNameError_${index}`] && (
                        <div className="text-red-500 text-sm mt-1">
                          {errorValidation[`firstNameError_${index}`]}
                        </div>
                      )}
                    </div>
                    <div className="w-3/6 mt-5">
                      <label className="font-medium text-gray-700 block mb-1">
                        Last Name*
                      </label>
                      <ClientInput
                        name="last_name"
                        readOnly={item?.individual_id ? true : false}
                        inputchangehandler={(e) =>
                          formHandleData(e, index, "last_name")
                        }
                        value={item?.last_name}
                        className="h-9 w-full"
                        placeholder="Enter Contact's last name"
                      />
                      {errorValidation[`lastNameError_${index}`] && (
                        <div className="text-red-500 text-sm mt-1">
                          {errorValidation[`lastNameError_${index}`]}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}

          <button
            class=" px-6 text-blue-700 text-xs font-semibold flex items-center gap-2 mt-5"
            onClick={addWorker}
          >
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 4.167v11.666M4.167 10h11.666"
                  stroke="#5786CC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </span>
            Add another Worker
          </button>
        </div>

        <div className="flex dailog-footer">
          <BtnLight
            className="mr-1.5 px-4"
            variant="plain"
            disabled={buttonDisabled}
            onClick={() => onDialogClose()}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={
              buttonDisabled || hasConserveTeamError || hasAlreadyWorkerError
            }
            onClick={() => handleInviteWorkers()}
            className="ml-1.5 px-4 flex gap-2 items-center justify-center"
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Invite user"}
          </BtnPrimary>
        </div>
      </Dialog>
      {/* CSV Dilog Box */}

      {/* Succesfull Dilog Box */}
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      {/* Invite Worker import csv */}
      <Dialog
        isOpen={dialogNextCsv}
        onClose={onCloseNextCsv}
        onRequestClose={onCloseNextCsv}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Provider and Clients
            </h5>
            {/* <p>
                            Ensure Providers are categorised correctly and send an optional
                            invitation message.
                        </p> */}
          </div>
        </div>
        <div className="pl-4">
          <div className="pr-6 pl-2">
            <div className="">
              <label className="font-semibold text-base text-gray-700 block mb-1">
                Provider*
              </label>
              <div className="chooseEmail">
                <Select
                  className="w-full h-9 select-options"
                  placeholder="Search & select a provider"
                  options={searchOptions}
                  value={selectedProvType}
                  onChange={(e) => handleSelectChange(e, "provider")}
                  handleSearchChange={(e) => handleSearchChange(e, "provider")}
                ></Select>
                {errorValidation?.providerNameError && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorValidation?.providerNameError}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5">
              <label className="font-semibold text-base text-gray-700 block mb-1">
                Client*
              </label>
              <div className="chooseEmail">
                <Select
                  className="w-full h-9 select-options"
                  placeholder="Select a client"
                  options={clientOptions}
                  value={selectedClient}
                  isMulti={true}
                  onChange={(e) => handleSelectChange(e, "client")}
                  handleSearchChange={(e) => handleSearchChange(e, "client")}
                ></Select>
                {errorValidation?.clientNameError && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorValidation?.clientNameError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            clas
            disabled={buttonDisabled}
            className="mr-1.5"
            variant="plain"
            onClick={onCloseNextCsv}
          >
            Cancel
          </BtnLight>
          <div className="w-full">
            <input
              type="file"
              id="csvUpload"
              accept=".csv"
              style={{ display: "none" }} // Hide the input
              onChange={handleFileChange}
            />

            <BtnPrimary
              disabled={
                !(
                  selectedProvType?.organisation_id &&
                  selectedClientOrgIds?.length > 0
                )
              }
              onClick={() => document.getElementById("csvUpload").click()} // Trigger file input click
              className="ml-1.5"
            >
              {buttonDisabled ? <ThreeDotLoader /> : "Next"}
            </BtnPrimary>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogOpenCSV}
        onClose={dialogCloseCSV}
        onRequestClose={dialogCloseCSV}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
                stroke="#344054"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              CSV Upload
            </h5>
            <p>Workers</p>
          </div>
        </div>
        <div className="h-96 pr-2">
          <Scrollbar>
            <div className="px-4">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          First Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Last Name
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">Email</span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">Action</span>
                      </Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {csvData?.map((item, index) => {
                      return (
                        <React.Fragment>
                          <Tr key={index}>
                            <Td>{item?.first_name}</Td>
                            <Td>{item?.last_name}</Td>
                            <Td>{item?.email}</Td>
                            <Td>
                              {item?.is_validated ? (
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    handlecheckbox(event, index);
                                  }}
                                />
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m18 6.5-12 12m0-12 12 12"
                                    stroke="red"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table>
              </div>
            </div>
          </Scrollbar>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight
            disabled={buttonDisabled}
            onClick={() => dialogCloseCSV()}
            className="mr-1.5"
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={!finalCsvData.length || buttonDisabled}
            onClick={() => submitCsvData()}
          >
            Invite user
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Workers;
