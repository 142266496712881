// import axios from "axios";
import axios from "axios";
import * as actionType from "../../constant/actionType";

export const DeleteCompanyIndModuleQuesAnsByid =
  (ApiData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "delete",
        data: { module_question_id: ApiData },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/DeleteCompanyIndModuleQuesAnsByid`,
      });
      return response.data;
    } catch (error) {}
  };
