import React, { useState } from "react";
import Table from "../../../../../../../../../../common/element/Table";
import Tag from "../../../../../../../../../../common/element/Tag";
import BtnLight from "../../../../../../../../../../common/element/Buttons/btnLight";
import Dialog from "../../../../../../../../../../common/element/Dialog/Dialog";
const InductionsList = () => {
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [waitDialogIsOpen, setWaitIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;
  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };
  const openWaitDialog = () => {
    setWaitIsOpen(true);
  };
  const onWaitDialogClose = (e) => {
    setWaitIsOpen(false);
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-2/12">
                <span className="flex items-center gap-1 ">
                  Induction Name
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-2/12">
                <span className="flex items-center gap-1 ">
                  Client
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-4/12">Site name</Th>

              <Th>
                <span className="flex items-center gap-1 ">
                  No. of Modules
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>
                <span className="flex items-center gap-1 ">
                  Date completed
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>
                <span className="flex items-center gap-1">Status</span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            <Tr>
              <Td>Lorem ipsum</Td>
              <Td>Client name</Td>
              <Td>[Site name here]</Td>
              <Td>XX</Td>
              <Td>DD/MM/YYYY</Td>
              <Td>
                <span onClick={() => openWaitDialog()}>
                  <Tag className="text-blue-800 bg-blue-50">
                    Awaiting action
                  </Tag>
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>Lorem ipsum</Td>
              <Td>Client name</Td>
              <Td>[Site name here]</Td>
              <Td>XX</Td>
              <Td>DD/MM/YYYY</Td>
              <Td>
                <span onClick={() => openWaitDialog()}>
                  <Tag className="text-blue-800 bg-blue-50">
                    Awaiting action
                  </Tag>
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>Lorem ipsum</Td>
              <Td>Client name</Td>
              <Td>[Site name here]</Td>
              <Td>XX</Td>
              <Td>DD/MM/YYYY</Td>
              <Td>
                <span onClick={() => openDialog()}>
                  <Tag className="text-success-700 bg-success-50">
                    Completed
                  </Tag>
                </span>
              </Td>
            </Tr>
          </TBody>
        </Table>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/book-open.svg" alt="" />
          </span>
          <div>
            <h5 className="text-gray-900 text-base font-semibold">
              [Induction name here]
            </h5>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={waitDialogIsOpen}
        onClose={onWaitDialogClose}
        onRequestClose={onWaitDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            The Worker has not yet finished the induction process.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 ">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default InductionsList;
