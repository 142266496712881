import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllClientContacts = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetAllClientContacts`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const GetOrgEmailLogs =
  (filterpage, filterlimit, filtersort, filterorder, organisation_id) =>
  async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetOrgEmailLogs`,
        params: {
          page: filterpage,
          limit: filterlimit,
          sort: filtersort,
          order: filterorder,
          organisation_id: organisation_id,
        },
      });
      return response.data;
    } catch (error) {}
  };

export const GetEmailLogById = (emailId) => {
  return (dispatch) => {
    // dispatch(GetEmailLogByIdStart());
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/client/GetEmailLogById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      params: {
        email_id: emailId,
      },
    };

    return axios(config)
      .then(function (response) {
        // dispatch(GetEmailLogByIdSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        // dispatch(GetEmailLogByIdFail("Something went wrong, Please try again."));
        return error;
      });
  };
};
export const ForwardEmail = (ApiData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "post",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/ForwardEmail`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
export const GetProviderInternalOrClientContacts =
  (getAllData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetProviderInternalOrClientContacts`,
        params: getAllData,
      });
      return response.data;
    } catch (error) {}
  };
// Provider list data
export const GetAllProviderTypesList = () => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/client/GetAllProviderTypesList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

// GetComplianceChecklistById
export const GetComplianceChecklistById = (apiData) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/client/GetComplianceChecklistById`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      params: apiData,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

// Update Compliance Checklist
export const UpdateComplianceChecklist =
  (paramsValue, ipAddress) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "patch",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/client/UpdateComplianceChecklist`,
        data: paramsValue,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  };

export const ArchiveChecklist = (form_data, ipAddress) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    return axios({
      method: "delete",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/client/ArchiveChecklist`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to archive checklist:", error);
        throw error;
      });
  };
};
export const ArchiveComplianceDoc = (form_data) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    return axios({
      method: "delete",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/ArchiveComplianceDoc`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to archive checklist:", error);
        throw error;
      });
  };
};
export const AddChecklistDoc = (ApiData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in ApiData) {
      formdata.append(key, ApiData[key]);
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/AddChecklistDoc`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

// DeleteComplianceDoc
export const DeleteComplianceDoc = (form_data) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    return axios({
      method: "delete",
      data: form_data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/DeleteComplianceDoc`,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        // dispatch(DeleteClientContactFail("Something went wrong, Please try again."));
        return error;
      });
  };
};
export const CreateComplianceChecklist =
  (ApiData, ipAddress) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      let formdata = new FormData();
      for (let key in ApiData) {
        let arr = [];
        if (key == "docsData") {
          ApiData["docsData"].forEach((item, index) => {
            let tempId = { tempId: index + 1 };
            let updateObj = { ...item, ...tempId };
            arr.push(updateObj);
            if (index + 1 == ApiData["docsData"].length) {
              formdata.append("docsData", JSON.stringify(arr));
            }
            if (item?.is_other_doc) {
              formdata.append(`file_${index + 1}`, item?.otherDocFile?.[0]);
            }
          });
        } else {
          formdata.append(key, ApiData[key]);
        }
      }
      const response = await axios({
        method: "post",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/client/CreateComplianceChecklist`,
      });
      // dispatch(fetchDataSuccess(response.data));
      return response.data;
    } catch (error) {
      return error.message;
      // dispatch(fetchDataError(error.message));
    }
  };
export const DashboardClientCompanyInductions =
  (apiData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        params: { function_assignment_id: apiData },
        url: `${actionType.environment_url}/api/client/DashboardClientCompanyInductions`,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return { success: false, error: error.message };
    }
  };
export const GetComplianceChecklist = (
  client_org_id,
  filtersort,
  filterorder,
  recipientType,
  searchTerm,
  filterlimit,
  filterpage,
  active
) => {
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/client/GetAllComplianceChecklistOfClient`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      params: {
        client_org_id: client_org_id,
        sort: filtersort,
        order: filterorder,
        recipient_type: recipientType,
        search: searchTerm,
        limit: filterlimit,
        page: filterpage,
        DetailType: active,
      },
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

export const GetAllComplianceList = (apiData) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/client/GetAllComplianceList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      params: apiData,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

export const GetAllIndividualListForContact =
  (getAllData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetAllIndividualListForContact`,
        params: getAllData,
      });
      return response.data;
    } catch (error) {}
  };

export const AddClientContact = (data, userip) => async (dispatch) => {
  // Final CSV Data

  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
        userip: userip,
      },
      url: `${actionType.environment_url}/api/client/AddClientContact`,
    });

    return response.data;
  } catch (error) {}
};

export const GetClientContactById = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetClientContactById`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateClientContact = (data, ipAddress) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in data) {
      if (key === "individual_data") {
        // Set 'individual_data' to an empty JSON string
        formdata.append(`individual_data`, "{}");
      } else {
        // For other keys, append the value as usual
        formdata.append(key, data[key]);
      }
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/client/UpdateClientContact`,
    });
    return response.data;
  } catch (error) {}
};

export const DeleteClientContact = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/DeleteClientContact`,
    });
    return response.data;
  } catch (error) {}
};
