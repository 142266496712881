import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notify } from "react-notify-toast";
import { TailSpin } from "react-loader-spinner";
import ScrollBar from "../../../../../../../../../common/element/ScrollBar/ScrollBar";
import Tabs from "../../../../../../../../../common/element/Tabs";
import Select from "../../../../../../../../../common/element/Select/Select";
import Tooltip from "../../../../../../../../../common/element/Tooltip";
import Multiselect from "multiselect-react-dropdown";
import Segment from "../../../../../../../../../common/element/Segment";
import * as userActions from "../../../../../../../../../actions/index";
import Validation from "../../../../../../../../../common/404/Validations";
import ClientInput from "../../../../../../../../../common/element/Input/clientInput";
import ChooseModule from "../../ChooseModules/ChooseModule";
import PdfFile from "../../ChooseModules/ChoosePdf";
import URLink from "../../ChooseModules/URLLink";
import ChooseQus from "../../ChooseQues";
import AddedText from "../../ChooseModules/AddedText";
import ImgFile from "../../ChooseModules/ImgFile";
import VideoUpload from "../../ChooseModules/VideoUpload";
import HelpCircleImg from "../../../../../../../../../assets/icons-svg/help-circle.svg";

import { siteInductionDetailForm } from "../../../../../../../../../redux/classes/Admin-Client/SiteInductionDetailForm";
import { AddSiteInductionModuleData } from "../../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { UpdateSiteInductionModuleData } from "../../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { ResetEditSiteInduction } from "../../../../../../../../../redux/classes/Admin-Client/EditSiteInductionSlice";
import { refetchAllInductionsDataSite } from "../../../../../../../../../redux/classes/Admin-Client/refetchAllInductionsSite";
import { UpdateInductionStatusSite } from "../../../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";
import { ResetSiteInductionModuleData } from "../../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { EditSiteInduction } from "../../../../../../../../../redux/classes/Admin-Client/EditSiteInductionSlice";
import { closeDocumentfuncSite } from "../../../../../../../../../redux/classes/Admin-Client/closeDocumentSite";
import { DeletedocumentDataSite } from "../../../../../../../../../redux/classes/Admin-Client/deleteDocumentSite";

import {
  CreateSiteInductionModuleForClient,
  UpdateSiteInductionModuleForClient,
  GetIndividualSiteIndDataForClient,
  UpdateSiteInductionDataForClient,
  GetCompDocsListOfWorkerType,
  UpdateModuleSortingOrder,
  GetSiteInductionAndModulesbyIdForClient,
} from "../../../../../../../../../actions/client-portal/AllSiteApi";

import useUnauthorizedHandler from "../../../../../../../../../common/snippet/useUnauthorizedHandler";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { decryptId } from "../../../../../../../../../common/encryptDecrypt/encryptdecrypt";

let updateCheck = false;
const AddInductionDialog = (props) => {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const siteOrgId = decryptId(siteId);

  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { TabNav, TabList, TabContent } = Tabs;
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const { SiteInductionModuleData } = useSelector((state) => state);
  // get all redux state
  const GettAllState = useSelector((state) => state);
  // get induction detail form from redux
  const moduleGetData = GettAllState?.SiteInductionModuleData;

  const [fileSize, SetfileSize] = useState({ size: "", name: "" });
  const [moduleDics, SetModuleDics] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("https://example.com/path/to/your.pdf");

  let function_assignment_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData !== null) {
    function_assignment_id = storedData.function_assignment_id;
  }

  const statusOptions = [
    {
      value: `Active Publish for all inductees to access.`,
      label: `Active Publish for all inductees to access.`,
    },
    {
      value: "Draft Visible to Admins and Clients only, until published.",
      label: "Draft Visible to Admins and Clients only, until published.",
    },
    {
      value:
        "Archive Deactivate Induction for Inductees and Clients, visible to Admins only.",
      label:
        "Archive Deactivate Induction for Inductees and Clients, visible to Admins only.",
    },
  ];
  // const statusOptionsDocument = [
  //     { value: `Active Publish for all inductees to access.` , label:  `Item input text` },
  //     ]

  const [isHighRisk, setIsHighRisk] = useState("");

  const [defaultTab, SetdefaultTab] = useState("tab1");
  const [formSatus, SetformSatus] = useState("inductionDetails");
  const [hasRun, setHasRun] = useState(false);
  const [allDocType, setAllDocType] = useState(null);
  const [riskData, setRiskData] = useState("");
  const [isFetchData, setisFetchData] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [inductionNameError, SetInductionNameError] = useState("");
  const [moduleError, SetmoduleError] = useState("");
  const [checkEdit, SetcheckEdit] = useState(true);
  const [choosePDF, uploadPDF] = useState(false);
  const [chooseURLink, setURLink] = useState(false);
  const [editText, setEditText] = useState(false);
  const [chooseImg, UploadImg] = useState(false);
  const [checkContent, SetcheckContent] = useState(false);
  const [indexData, SetindexData] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [chooseVideo, UploadVideo] = useState(false);
  const [viewModuleCheck, setviewModuleCheck] = useState(false);
  const [moduleId, SetmoduleId] = useState([]);
  const [checkLoader, SetCheckLoader] = useState(false);
  const [checkValue, setcheckValue] = useState(false);
  const [defaultTabChekc, SetdefaultTabChekc] = useState(true);

  const [previousSiteIndDocTypesIds, setPreviousSiteIndDocTypesIds] =
    useState("");
  const [previousSiteSiteIndIndustryIds, setPreviousSiteIndIndustryIds] =
    useState("");

  const [inductionData, setIndcutionData] = useState({
    site_ind_name: "",
    site_ind_desc: "",
    // document_type_id_data: "",
    doctType_id_data: "",
    master_setting_id_data: "",
    site_id: siteOrgId,
    // site_induction_id: "",
    // site_ind_status: "",
    // ...props?.siteInductionDetailData
  });

  const { updateInductionStatusSite } = useSelector((state) => state);
  const [dialogDeleteOpen, setDeleteOpen] = useState(false);

  const openDeleteDialog = () => {
    setDeleteOpen(true);
  };

  const onDeleteDialogClose = () => {
    setDeleteOpen(false);
  };

  const ViewModule = (e) => {
    const InductionName = Validation({
      value: inductionData?.site_ind_name,
      Validations: { required: true },
    });
    const ModuleName = Validation({
      value: moduleGetData[indexData]?.module_name,
      Validations: { required: true },
    });
    if (!ModuleName.valid) {
      SetmoduleError(ModuleName.error_msg);
    }
    if (!InductionName.valid) {
      SetInductionNameError(InductionName.error_msg);
    }

    if (ModuleName.valid && InductionName.valid) {
      SetCheckLoader(true);
      let checktabisinduction = false;

      // After Edit Induction
      if (GettAllState?.editSiteInduction && defaultTab == "tab1") {
        const removedDocTypes =
          Array.isArray(previousSiteIndDocTypesIds) &&
          Array.isArray(inductionData?.site_ind_doctypes)
            ? previousSiteIndDocTypesIds?.filter(
                (docTypeId) =>
                  !inductionData?.doctType_id_data?.includes(
                    docTypeId?.document_type_id
                  )
              )
            : [];

        let removedDocIds = removedDocTypes.map(
          (item) => item?.site_ind_doctype_id
        );
        // Filter out removed industry IDs
        const removedIndustries =
          Array.isArray(previousSiteSiteIndIndustryIds) &&
          Array.isArray(inductionData?.master_setting_id_data)
            ? previousSiteSiteIndIndustryIds?.filter(
                (industryId) =>
                  !inductionData?.master_setting_id_data?.includes(
                    industryId?.master_setting_id
                  )
              )
            : [];

        const removedIndustryIds = removedIndustries.map(
          (item) => item?.site_ind_industry_id
        );

        const addedDocTypes =
          Array.isArray(previousSiteIndDocTypesIds) &&
          Array.isArray(inductionData?.site_ind_doctypes)
            ? previousSiteIndDocTypesIds.filter((docTypeId) =>
                inductionData?.site_ind_doctypes.includes(
                  docTypeId.document_type_id
                )
              )
            : [];
        const existedDocs = addedDocTypes.map((item) => item?.document_type_id);
        let AddedDocIds;
        if (previousSiteIndDocTypesIds[0]) {
          AddedDocIds = inductionData?.doctType_id_data.filter(
            (docTypeId) => !existedDocs.includes(docTypeId)
          );
        } else {
          AddedDocIds = inductionData?.doctType_id_data;
        }
        const addedIndustriesTypes =
          Array.isArray(previousSiteSiteIndIndustryIds) &&
          Array.isArray(inductionData?.master_setting_id_data)
            ? previousSiteSiteIndIndustryIds.filter((industryId) =>
                inductionData?.master_setting_id_data?.includes(
                  industryId?.master_setting_id
                )
              )
            : [];

        const existedIndustry = addedIndustriesTypes.map(
          (item) => item?.master_setting_id
        );

        let AddedIndustryIds;
        if (previousSiteSiteIndIndustryIds[0]) {
          AddedIndustryIds = inductionData?.master_setting_id_data?.filter(
            (industryId) => !existedIndustry.includes(industryId)
          );
        } else {
          AddedIndustryIds = inductionData?.master_setting_id_data;
        }
        AddedDocIds = Array.from(new Set(AddedDocIds));
        removedDocIds = Array.from(new Set(removedDocIds));
        let updateInductionDetail = {
          site_id: siteOrgId,
          site_induction_id: inductionData?.site_induction_id,
          site_ind_name: inductionData?.site_ind_name,
          site_ind_desc: inductionData?.site_ind_desc,
          site_name: props?.siteData?.site_name,
          SiteIndDocTypeIds: JSON.stringify(removedDocIds), //docType id remove
          // SiteIndDocTypesIds: removedDocIds, //docType id remove
          SiteIndIndustryIds: removedIndustryIds, //site_ind_industry_id removed
          newDocTypeIds: JSON.stringify(AddedDocIds), // added doctype id
          newMasterSettingIds: JSON.stringify(AddedIndustryIds), // added site industry id
        };
        dispatch(
          UpdateSiteInductionDataForClient(updateInductionDetail, ipAddress)
        )
          .then((response) => {
            if (response?.success) {
            } else if (response?.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {});
      } else {
        viewModuleCheck == false &&
          dispatch(
            CreateSiteInductionModuleForClient(
              siteModuleData[siteModuleData.length - 1]
            )
          )
            .then((response) => {
              if (response?.success) {
                let indexData = moduleGetData?.length - 1;
                let moduleDataId = { module_id: response?.data?.module_id };
                const moduleData = {
                  ...moduleGetData[moduleGetData?.length - 1],
                  ...moduleDataId,
                };
                dispatch(
                  UpdateSiteInductionModuleData({ indexData, moduleData })
                );
                moduleId.push({
                  module_id: response?.data?.module_id,
                  index: indexData,
                });
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {});
      }

      SetcheckEdit(false);
      updateCheck = true;
      let indexData = e.index;
      let oldIndex = indexData;
      let moduleData;

      let updateIndex = { module_id: moduleId[e.index]?.module_id };
      setPreviousIndex(oldIndex);

      if (GettAllState?.editSiteInduction) {
        updateIndex = { module_id: moduleGetData[e.index].module_id };
      }

      updateIndex.module_id &&
        dispatch(GetIndividualSiteIndDataForClient(updateIndex)).then(
          (response) => {
            if (response?.success) {
              moduleData = response?.data;
              SetModuleData(moduleData);

              dispatch(
                UpdateSiteInductionModuleData({ indexData, moduleData })
              );
              UpdateData();
            } else if (response?.status === 401) {
              handleUnauthorizedAccess();
            }
          }
        );

      setviewModuleCheck(true);
      uploadPDF(false);
      UploadImg(false);
      UploadVideo(false);
      setURLink(false);
      setEditText(false);

      SetModuleData((prevalue) => {
        const newData = moduleGetData[e.index];
        return {
          ...prevalue,
          ...newData,
        };
      });
      SetdefaultTab(e.module_name);
      SetindexData(e.index);

      if (SiteInductionModuleData[e.index]?.content_info_type == "file") {
        // const fileName = SiteInductionModuleData[e.index].site_induction_file.name;
        let fileName;

        if (
          // SiteInductionModuleData?.[e.index]?.site_ind_file?.name !==
          //   undefined ||
          SiteInductionModuleData?.[e.index]?.site_ind_file?.name !== undefined
        ) {
          fileName = SiteInductionModuleData[e.index]?.site_ind_file.name;
        } else {
          fileName = SiteInductionModuleData[e.index]?.content_info;
        }

        // Get the file extension
        let fileExtension;
        // = fileName.split(".").pop().toLowerCase();
        // Check if it's a PDF
        if (fileName) {
          fileExtension = fileName?.split(".")?.pop()?.toLowerCase();
        }
        if (fileExtension === "pdf") {
          uploadPDF(true);
          // fetch(moduleGetData[indexData].content_info)
          //   .then((response) => response?.blob())
          //   .then((blob) => {
          // const sizeKB = Math.round(blob.size / 1024);
          //       const parts = moduleGetData[indexData].content_info.split("-");
          //       let FileName;
          //       if (parts.length >= 3) {
          //         FileName = parts[parts.length - 1];
          //       }
          //       SetfileSize({
          //         size: sizeKB,
          //         name: FileName,
          //       });
          //     });
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          UploadImg(true);
          const parts = moduleGetData[indexData].content_info.split("-");
          let FileName;
          if (parts.length >= 3) {
            FileName = parts[parts.length - 1];
            FileName = FileName.replace(/%/g, " ");
          }
          SetfileSize({
            size: "",
            name: FileName,
          });
        }
      }
      const contentInfoType =
        SiteInductionModuleData[e.index]?.content_info_type;
      switch (contentInfoType) {
        case "text":
          setEditText(true);
          break;
        case "file_video":
          UploadVideo(true);
          const parts = moduleGetData[indexData]?.content_info?.split("-");
          let FileName;
          if (parts.length >= 3) {
            FileName = parts[parts.length - 1];
            FileName = FileName.replace(/%/g, " ");
          }
          SetfileSize({
            size: "",
            name: FileName,
          });

          break;
        case "video_url":
          UploadVideo(true);
          break;
        case "url":
          setURLink(true);
          break;
        case "":
          uploadPDF(false);
          UploadImg(false);
          UploadVideo(false);
          setURLink(false);
          setEditText(false);
          break;
      }
    }
  };

  useEffect(() => {
    if (GettAllState?.editSiteInduction) {
      setIndcutionData(props?.siteInductionDetailData);
    }
  }, [props?.siteInductionDetailData]);

  const [moduleData, SetModuleData] = useState({
    site_induction_id: GettAllState?.editSiteInduction
      ? GettAllState?.siteInductionDetailData.site_induction_id
      : props?.siteInductionId,
    module_name: "Module",
    content_info: "",
    module_desc: "",
    content_link_title: "",
    content_info_type: "",
    module_type: "site induction",
    pdf_page_range: {},
    module_ques_data: [
      {
        question: "",
        ques_ans_data: [
          {
            answer: "",
            is_correct_answer: false,
          },
        ],
      },
    ],
    // is_module_mandatory: true,
    site_induction_file: [],
    // file_content: []
  });

  useEffect(() => {
    setisFetchData(true);
  }, [SiteInductionModuleData[indexData]]);

  const fetchDataCallback = useCallback(() => {
    if (SiteInductionModuleData?.length && isFetchData) {
      setisFetchData(false);
      SetModuleData(SiteInductionModuleData[indexData]);
    }
  }, [
    SiteInductionModuleData,
    indexData,
    isFetchData,
    SetModuleData,
    setisFetchData,
  ]);

  useEffect(() => {
    fetchDataCallback();
  }, [fetchDataCallback]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // This is required for some browsers
      dispatch(ResetEditSiteInduction());
      return "Are you sure you want to leave this page?"; // This message will be displayed in the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [moduleGetData]);

  useEffect(() => {
    let checkValidation = true;

    if (props?.validateFrom) {
      const InductionName = Validation({
        value: inductionData?.site_ind_name,
        Validations: { required: true },
      });
      if (!InductionName.valid) {
        SetInductionNameError(InductionName.error_msg);
      }
    }
    if (GettAllState?.editSiteInduction) {
      const InductionName = Validation({
        value: inductionData?.site_ind_name,
        Validations: { required: true },
      });
      if (!InductionName.valid) {
        SetInductionNameError(InductionName.error_msg);
      }
      checkValidation = InductionName.valid;
    }
    const InductionName = Validation({
      value: moduleGetData[indexData]?.module_name,
      Validations: { required: true },
    });
    if (moduleGetData?.length == 0) {
      InductionName.valid = true;
    }
    if (!InductionName.valid) {
      SetmoduleError(InductionName.error_msg);
    }
    if (checkValidation && InductionName.valid) {
      SetformSatus(props?.formSatus);
      SetdefaultTab(
        props?.formSatus != "inductionDetails" ? "Module0" : "tab1"
      );

      let isCorrectAnswerPresent = false;
      // Check if the target question is found and has the required is_correct_answer
      if (
        moduleGetData?.length &&
        moduleGetData?.[indexData]?.content_info_type
      ) {
        if (
          moduleGetData?.[indexData]?.module_ques_data?.every(
            (item) =>
              item?.ques_ans_data.every((ans) => ans.answer !== "") &&
              item?.ques_ans_data.some((ans) => ans.is_correct_answer)
          )
        ) {
          isCorrectAnswerPresent = true;
        } else {
          isCorrectAnswerPresent = false;
        }
      } else {
        isCorrectAnswerPresent = true;
      }
      if (props?.saveData) {
        if (
          ((updateCheck ||
            props?.exitCheck ||
            GettAllState?.editSiteInduction ||
            defaultTab == "tab1") &&
            moduleGetData?.[indexData]?.module_id) ||
          moduleGetData?.length == 0
        ) {
          if (
            checkLoader ||
            props?.exitCheck ||
            GettAllState?.editSiteInduction ||
            defaultTab == "tab1"
          ) {
            const moduleDesc = moduleGetData?.[indexData]?.module_desc;
            const words = moduleDesc?.split(/\s+/);
            if (isCorrectAnswerPresent || moduleGetData?.length == 0) {
              setIsSaving(true);
              if (words?.length <= 255 || moduleGetData?.length == 0) {
                if (updateInductionStatusSite || props?.exitCheck) {
                  if (GettAllState?.editSiteInduction && defaultTab == "tab1") {
                    const removedDocTypes =
                      Array.isArray(previousSiteIndDocTypesIds) &&
                      Array.isArray(inductionData?.site_ind_doctypes)
                        ? previousSiteIndDocTypesIds.filter(
                            (docTypeId) =>
                              !inductionData?.doctType_id_data.includes(
                                docTypeId?.document_type_id
                              )
                          )
                        : [];

                    let removedDocIds = removedDocTypes?.map(
                      (item) => item?.site_ind_doctype_id
                    );

                    // Filter out removed industry IDs
                    const removedIndustries =
                      Array.isArray(previousSiteSiteIndIndustryIds) &&
                      Array.isArray(inductionData?.master_setting_id_data)
                        ? previousSiteSiteIndIndustryIds?.filter(
                            (industryId) =>
                              !inductionData?.master_setting_id_data?.includes(
                                industryId?.master_setting_id
                              )
                          )
                        : [];

                    const removedIndustryIds = removedIndustries.map(
                      (item) => item?.site_ind_industry_id
                    );

                    const addedDocTypes =
                      Array.isArray(previousSiteIndDocTypesIds) &&
                      Array.isArray(inductionData?.site_ind_doctypes)
                        ? previousSiteIndDocTypesIds.filter((docTypeId) =>
                            inductionData?.site_ind_doctypes.includes(
                              docTypeId.document_type_id
                            )
                          )
                        : [];

                    const existedDocs = addedDocTypes.map(
                      (item) => item?.document_type_id
                    );

                    let AddedDocIds;
                    if (previousSiteIndDocTypesIds[0]) {
                      AddedDocIds = inductionData?.doctType_id_data.filter(
                        (docTypeId) => !existedDocs.includes(docTypeId)
                      );
                    } else {
                      AddedDocIds = inductionData?.doctType_id_data;
                    }

                    const addedIndustriesTypes =
                      Array.isArray(previousSiteSiteIndIndustryIds) &&
                      Array.isArray(inductionData?.master_setting_id_data)
                        ? previousSiteSiteIndIndustryIds.filter((industryId) =>
                            inductionData?.master_setting_id_data?.includes(
                              industryId?.master_setting_id
                            )
                          )
                        : [];

                    const existedIndustry = addedIndustriesTypes.map(
                      (item) => item?.master_setting_id
                    );

                    let AddedIndustryIds;
                    if (previousSiteSiteIndIndustryIds[0]) {
                      AddedIndustryIds =
                        inductionData?.master_setting_id_data?.filter(
                          (industryId) => !existedIndustry.includes(industryId)
                        );
                    } else {
                      AddedIndustryIds = inductionData?.master_setting_id_data;
                    }
                    AddedDocIds = Array.from(new Set(AddedDocIds));
                    removedDocIds = Array.from(new Set(removedDocIds));
                    let updateInductionDetail = {
                      site_id: siteOrgId,
                      site_induction_id: inductionData?.site_induction_id,
                      site_ind_name: inductionData?.site_ind_name,
                      site_ind_desc: inductionData?.site_ind_desc,
                      SiteIndDocTypeIds: JSON.stringify(removedDocIds), //docType id remove
                      SiteIndIndustryIds: removedIndustryIds, //site_ind_industry_id removed
                      newDocTypeIds: JSON.stringify(AddedDocIds), // added doctype id
                      newMasterSettingIds: JSON.stringify(AddedIndustryIds), // added site industry id
                      site_name: props?.siteData?.site_name,
                    };

                    dispatch(
                      UpdateSiteInductionDataForClient(
                        updateInductionDetail,
                        ipAddress
                      )
                    )
                      .then((response) => {
                        if (response?.success) {
                          // need to check weather (siteInductionDetailForm(inductionData)); condition
                          //  is good or updateInductionDetail needs to paas instead of inductionData
                          dispatch(
                            siteInductionDetailForm(updateInductionDetail)
                          );
                          dispatch(UpdateInductionStatusSite(false));
                          props?.closePannel();
                        } else if (response?.status === 401) {
                          handleUnauthorizedAccess();
                        } else {
                          props?.closePannelDisable();
                        }
                      })
                      .catch((error) => {});
                  } else {
                    if (moduleGetData?.length > 0) {
                      dispatch(
                        UpdateSiteInductionModuleForClient(
                          moduleGetData[indexData]
                        )
                      )
                        .then((response) => {
                          if (response?.success) {
                            updateCheck = false;
                            setIsSaving(false);
                            dispatch(UpdateInductionStatusSite(false));
                            props?.closePannel();
                          } else if (response?.status === 401) {
                            handleUnauthorizedAccess();
                          } else {
                            props?.closePannelDisable();
                          }
                        })
                        .catch((error) => {});
                    } else {
                      // props?.closePannelDisable();
                      props?.closePannel();
                    }
                  }
                }
              } else {
                props?.closePannelDisable();
                setIsSaving(false);
                notify.show(
                  "value too long for type character varying(255)",
                  "error"
                );
              }
            } else {
              props?.closePannelDisable();
              notify.show("Each question one answer is required", "error");
            }
          } else {
            props?.closePannel();
          }
        } else {
          const moduleDesc = moduleGetData?.[indexData]?.module_desc;
          const words = moduleDesc?.split(/\s+/);
          // Check if the length of module_desc is greater than or equal to 250 characters
          if (isCorrectAnswerPresent) {
            setIsSaving(true);
            if (words?.length <= 255) {
              // Your code here if the condition is true
              // if(moduleGetData?.[indexData]?.module_id){
              //   delete moduleGetData[indexData].module_id;
              // }
              dispatch(
                CreateSiteInductionModuleForClient(moduleGetData?.[indexData])
              )
                .then((response) => {
                  if (response?.success) {
                    let moduleDataId = {
                      module_id: response?.data?.module_id,
                    };
                    // Merge response?.data into moduleGetData[indexData]
                    const moduleData = {
                      ...moduleGetData[indexData],
                      ...moduleDataId,
                    };
                    dispatch(
                      UpdateSiteInductionModuleData({ indexData, moduleData })
                    );
                    props?.closePannel();
                    setIsSaving(false);
                  } else if (response?.status === 401) {
                    handleUnauthorizedAccess();
                  }
                })
                .catch((error) => {});
            } else {
              // Your code here if the condition is false
              props?.closePannelDisable();
              setIsSaving(false);
              notify.show(
                "value too long for type character varying(255)",
                "error"
              );
            }
          } else {
            props?.closePannelDisable();
            notify.show("Each question one answer is required", "error");
          }
        }
      }
    } else {
      props?.closePannel("disableBTN");
    }
  }, [
    props?.formSatus,
    props?.validateFrom,
    props?.saveData,
    props?.upDateState,
    props?.siteInductionDetailData,
  ]);

  const handleAddContent = (e) => {
    dispatch(DeletedocumentDataSite(true));
    SetfileSize({ size: "", name: "" });
    dispatch(UpdateInductionStatusSite(true));
    e.target.id == "pdf" && uploadPDF(true);
    e.target.id == "image" && UploadImg(true);
    e.target.id == "video" && UploadVideo(true);
    e.target.id == "urlLink" && setURLink(true);
    e.target.id == "text" && setEditText(true);
  };

  const handleClose = () => {
    // uploadPDF(false);
    // UploadImg(false);
    // UploadVideo(false);
    // setURLink(false);
    // setEditText(false);
  };
  useEffect(() => {
    if (GettAllState?.closeDocumentSite) {
      uploadPDF(false);
      UploadImg(false);
      UploadVideo(false);
      setURLink(false);
      setEditText(false);
      dispatch(closeDocumentfuncSite(false));
    }
  }, [GettAllState?.closeDocumentSite]);

  const handleInduction = (e) => {
    SetcheckContent(true);
    if (e.target.name == "site_ind_name") {
      SetInductionNameError("");
    }
    setIndcutionData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (!GettAllState?.editSiteInduction) {
      dispatch(siteInductionDetailForm(inductionData));
    }
  }, [inductionData]);

  const inputModulechangehandler = (event) => {
    dispatch(UpdateInductionStatusSite(true));
    SetcheckContent(true);
    if (event.target.name === "module_name") {
      SetdefaultTab(event.target.value + indexData);
      SetmoduleError("");
    }
    const moduleDesc = event.target.value;
    const words = moduleDesc.split(/\s+/);
    if (words.length <= 256) {
      SetModuleDics(false);
      SetModuleData((prevalue) => {
        return {
          ...prevalue,
          [event.target.name]: event.target.value,
        };
      });
    } else {
      SetModuleDics(true);
    }
  };

  useEffect(() => {
    if (siteModuleData.length !== 0) {
      if (!GettAllState?.editSiteInduction || checkContent) {
        dispatch(UpdateSiteInductionModuleData({ indexData, moduleData }));
      }
    }
  }, [moduleData]);

  useEffect(() => {
    if (GettAllState?.editSiteInduction && defaultTab == "tab1") {
      const siteDocId =
        Array.isArray(props?.siteInductionDetailData?.site_ind_doctypes) &&
        props?.siteInductionDetailData?.site_ind_doctypes.map(
          (id) => id.document_type_id
        );
      const siteIndustryId =
        Array.isArray(props?.siteInductionDetailData?.master_setting_id_data) &&
        props?.siteInductionDetailData?.master_setting_id_data?.map(
          (id) => id.master_setting_id
        );
      // setIndcutionData({
      //   document_type_id_data: siteDocId,
      //   master_setting_id_data: siteIndustryId,
      // })

      if (props?.siteInductionDetailData) {
        setIndcutionData((prevalue) => {
          return {
            ...prevalue,
            ...props?.siteInductionDetailData,
          };
        });
      }

      setIndcutionData((prevalue) => {
        return {
          ...prevalue,
          doctType_id_data: siteDocId,
          master_setting_id_data: siteIndustryId,
        };
      });
    }
    // const siteDocId =
    // props?.siteInductionDetailData.site_ind_doc_types.map((id) => id.site_ind_doc_type_id)
    // const siteIndustryId =
    // props?.siteInductionDetailData.site_ind_industries.map((id) => id.site_ind_industry_id)

    if (GettAllState?.editSiteInduction && defaultTab == "tab1") {
      // if(GettAllState?.editSiteInduction){
      setPreviousSiteIndDocTypesIds(
        props?.siteInductionDetailData.site_ind_doctypes
      );
      setPreviousSiteIndIndustryIds(
        props?.siteInductionDetailData.master_setting_id_data
      );
    }
  }, [props?.siteInductionDetailData]);

  // useEffect(() => {
  //   if (props?.siteInductionDetailData) {
  //     setIndcutionData((prevalue) => {
  //       return {
  //         ...prevalue,
  //         ...props?.siteInductionDetailData,
  //       };
  //     });
  //   }
  // }, []);

  const siteModuleData = useSelector((state) => state.SiteInductionModuleData);

  if (props?.formSatus === "module") {
    if (siteModuleData.length == 0) {
      if (props?.siteInductionId && !GettAllState?.editSiteInduction) {
        moduleData.site_induction_id = props?.siteInductionId;

        dispatch(AddSiteInductionModuleData(moduleData));
      }
      if (GettAllState?.editSiteInduction) {
        moduleData.site_induction_id =
          GettAllState?.siteInductionDetailData.site_induction_id;
        // dispatch(AddInductionModuleData(moduleData));
      }
    }
  }

  // Add New Module
  const handleAddNewSection = () => {
    let isCorrectAnswerPresent = true;
    // Check if the target question is found and has the required is_correct_answer
    if (
      moduleGetData?.length &&
      moduleGetData?.[indexData]?.content_info_type
    ) {
      if (
        moduleGetData?.[indexData]?.module_ques_data?.every(
          (item) =>
            item?.ques_ans_data.every((ans) => ans.answer !== "") &&
            item?.ques_ans_data.some((ans) => ans.is_correct_answer)
        )
      ) {
        isCorrectAnswerPresent = true;
      } else {
        isCorrectAnswerPresent = false;
      }
    } else {
      isCorrectAnswerPresent = true;
    }

    if (isCorrectAnswerPresent) {
      SetModuleDics(false);
      SetCheckLoader(true);
      SetdefaultTabChekc(false);
      if (GettAllState?.editSiteInduction && defaultTab == "tab1") {
        const removedDocTypes =
          Array.isArray(previousSiteIndDocTypesIds) &&
          Array.isArray(inductionData?.site_ind_doctypes)
            ? previousSiteIndDocTypesIds.filter(
                (docTypeId) =>
                  !inductionData?.doctType_id_data.includes(
                    docTypeId.document_type_id
                  )
              )
            : [];

        let removedDocIds = removedDocTypes.map(
          (item) => item?.site_ind_doctype_id
        );

        // Filter out removed industry IDs
        const removedIndustries =
          Array.isArray(previousSiteSiteIndIndustryIds) &&
          Array.isArray(inductionData?.master_setting_id_data)
            ? previousSiteSiteIndIndustryIds?.filter(
                (industryId) =>
                  !inductionData?.master_setting_id_data?.includes(
                    industryId?.master_setting_id
                  )
              )
            : [];

        const removedIndustryIds = removedIndustries.map(
          (item) => item?.site_ind_industry_id
        );

        const addedDocTypes =
          Array.isArray(previousSiteIndDocTypesIds) &&
          Array.isArray(inductionData?.site_ind_doctypes)
            ? previousSiteIndDocTypesIds.filter((docTypeId) =>
                inductionData?.site_ind_doctypes.includes(
                  docTypeId.document_type_id
                )
              )
            : [];

        const existedDocs = addedDocTypes.map((item) => item?.document_type_id);

        let AddedDocIds;

        if (previousSiteIndDocTypesIds[0]) {
          AddedDocIds = inductionData?.doctType_id_data.filter(
            (docTypeId) => !existedDocs.includes(docTypeId)
          );
        } else {
          AddedDocIds = inductionData?.doctType_id_data;
        }

        const addedIndustriesTypes =
          Array.isArray(previousSiteSiteIndIndustryIds) &&
          Array.isArray(inductionData?.master_setting_id_data)
            ? previousSiteSiteIndIndustryIds.filter((industryId) =>
                inductionData?.master_setting_id_data?.includes(
                  industryId?.master_setting_id
                )
              )
            : [];

        const existedIndustry = addedIndustriesTypes.map(
          (item) => item?.master_setting_id
        );

        let AddedIndustryIds;
        if (previousSiteSiteIndIndustryIds[0]) {
          AddedIndustryIds = inductionData?.master_setting_id_data?.filter(
            (industryId) => !existedIndustry.includes(industryId)
          );
        } else {
          AddedIndustryIds = inductionData?.master_setting_id_data;
        }
        AddedDocIds = Array.from(new Set(AddedDocIds));
        removedDocIds = Array.from(new Set(removedDocIds));
        let updateInductionDetail = {
          site_id: siteOrgId,
          site_induction_id: inductionData?.site_induction_id,
          site_ind_name: inductionData?.site_ind_name,
          site_ind_desc: inductionData?.site_ind_desc,
          SiteIndDocTypeIds: JSON.stringify(removedDocIds), //docType id remove
          SiteIndIndustryIds: removedIndustryIds, //site_ind_industry_id removed
          newDocTypeIds: JSON.stringify(AddedDocIds), // added doctype id
          newMasterSettingIds: JSON.stringify(AddedIndustryIds), // added site industry id
          site_name: props?.siteData?.site_name,
        };
        dispatch(
          UpdateSiteInductionDataForClient(updateInductionDetail, ipAddress)
        )
          .then((response) => {
            if (response?.success) {
              dispatch(siteInductionDetailForm(updateInductionDetail));
            } else if (response?.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {});
      }

      if (updateCheck) {
        UpdateData();
        updateCheck = false;
      }

      const InductionName = Validation({
        value: moduleGetData[indexData]?.module_name,
        Validations: { required: true },
      });
      if (moduleGetData?.length == 0) {
        InductionName.valid = true;
      }
      if (!InductionName.valid) {
        SetmoduleError(InductionName.error_msg);
      } else {
        SetModuleData({
          site_induction_id: GettAllState?.editSiteInduction
            ? GettAllState?.siteInductionDetailData.site_induction_id
            : props?.siteInductionId,
          module_name: "Module",
          content_info: "",
          module_desc: "",
          content_link_title: "",
          content_info_type: "",
          module_type: "site induction",
          pdf_page_range: {},
          module_ques_data: [
            {
              question: "",
              ques_ans_data: [
                {
                  answer: "",
                  is_correct_answer: false,
                },
              ],
            },
          ],
          is_module_mandatory: true,
          site_induction_file: [],
        });
        dispatch(
          AddSiteInductionModuleData({
            site_induction_id: GettAllState?.editSiteInduction
              ? GettAllState?.siteInductionDetailData.site_induction_id
              : props?.siteInductionId,
            module_name: "Module",
            content_info: "",
            module_desc: "",
            content_link_title: "",
            content_info_type: "",
            module_type: "site induction",
            // pdf_page_range: {},
            module_ques_data: [
              {
                question: "",
                ques_ans_data: [
                  {
                    answer: "",
                    is_correct_answer: false,
                  },
                ],
              },
            ],
            is_module_mandatory: true,
            site_induction_file: [],
          })
        );
        uploadPDF(false);
        UploadImg(false);
        UploadVideo(false);
        setURLink(false);
        setEditText(false);
        SetdefaultTab("Module" + moduleGetData?.length);
        !viewModuleCheck &&
          defaultTab !== "tab1" &&
          dispatch(CreateSiteInductionModuleForClient(moduleGetData[indexData]))
            .then((response) => {
              if (response?.success) {
                let moduleDataId = { module_id: response?.data?.module_id };
                // Merge response?.data into moduleGetData[indexData]
                const moduleData = {
                  ...moduleGetData[indexData],
                  ...moduleDataId,
                };
                dispatch(
                  UpdateSiteInductionModuleData({ indexData, moduleData })
                );
                moduleId.push({
                  module_id: response?.data?.module_id,
                  index: indexData,
                });
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {});
        SetindexData(moduleGetData?.length);
      }
      setviewModuleCheck(false);
    } else {
    }
  };

  useEffect(() => {
    if (
      GettAllState?.editSiteInduction == true &&
      moduleGetData?.length > 0 &&
      checkEdit
    ) {
      uploadPDF(false);
      UploadImg(false);
      UploadVideo(false);
      setURLink(false);
      setEditText(false);

      if (moduleGetData[indexData]?.content_info_type == "file") {
        // const fileName = InductionModuleData?.[e.index]?.site_induction_file?.name;
        let fileName;
        if (
          moduleGetData?.[indexData]?.site_induction_file?.name !== undefined
        ) {
          fileName = moduleGetData[indexData]?.site_induction_file.name;
        } else {
          fileName = moduleGetData[indexData]?.content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName?.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {
          uploadPDF(true);

          // Condition need to implement for moduleGetData[indexData]
          // fetch(moduleGetData[indexData].content_info)
          //   .then((response) => response?.blob())
          //   .then((blob) => {
          //     const sizeKB = Math.round(blob.size / 1024);
          //     const parts = moduleGetData[indexData].content_info.split("-");
          //     let FileName;
          //     if (parts.length >= 3) {
          //       FileName = parts[parts.length - 1];
          //     }
          //     SetfileSize({
          //       size: sizeKB,
          //       name: parts,
          //     });
          //   });
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          UploadImg(true);
          // fetch(moduleGetData[indexData].content_info)
          //   .then((response) => response?.blob())
          //   .then((blob) => {
          //     const sizeKB = Math.round(blob.size / 1024);
          const parts = moduleGetData[indexData].content_info.split("-");
          let FileName;
          if (parts.length >= 3) {
            FileName = parts[parts.length - 1];
          }
          SetfileSize({
            size: "",
            name: FileName,
          });
          // });
        }
        // if (fileExtension === "video") {
        //   UploadVideo(true);
        // }
      }

      if (moduleGetData?.[indexData]?.content_info_type == "text") {
        setEditText(true);
      }
      if (moduleGetData?.[indexData]?.content_info_type == "file_video") {
        UploadVideo(true);
      }
      if (moduleGetData?.[indexData]?.content_info_type == "video_url") {
        UploadVideo(true);
      }
      if (moduleGetData?.[indexData]?.content_info_type == "url") {
        setURLink(true);
      }
    }
  }, [moduleGetData]);

  useEffect(() => {
    if (SiteInductionModuleData?.length > 0) {
      if (SiteInductionModuleData[indexData]?.content_info_type == "file") {
        let fileName;
        if (
          SiteInductionModuleData?.[indexData]?.site_induction_file?.name !==
          undefined
        ) {
          fileName =
            SiteInductionModuleData[indexData]?.site_induction_file.name;
        } else {
          fileName = SiteInductionModuleData?.[indexData]?.content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName?.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {
          uploadPDF(true);
          try {
            // const url = new URL(pdfUrl);
            // const newBaseUrl = `${url.origin}${url.pathname}`;
          } catch (error) {
            console.error("Invalid PDF URL:", error);
          }
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          UploadImg(true);
        }
      }
      if (SiteInductionModuleData[indexData]?.content_info_type == "text") {
        setEditText(true);
      }
      if (moduleGetData[indexData].content_info_type == "file_video") {
        UploadVideo(true);
      }
      if (
        SiteInductionModuleData[indexData]?.content_info_type == "video_url"
      ) {
        UploadVideo(true);
      }
      if (SiteInductionModuleData[indexData]?.content_info_type == "url") {
        setURLink(true);
      }
      if (SiteInductionModuleData[indexData]?.content_info_type == "") {
        uploadPDF(false);
        UploadImg(false);
        UploadVideo(false);
        setURLink(false);
        setEditText(false);
      }
    }
  }, []);

  function UpdateData() {
    if (updateCheck && previousIndex != null) {
      if (updateInductionStatusSite) {
        let isCorrectAnswerPresent = false;
        // Check if the target question is found and has the required is_correct_answer
        if (
          moduleGetData?.length &&
          (moduleGetData?.[previousIndex]?.content_info !== "" ||
            moduleGetData?.[previousIndex]?.content_info_type)
        ) {
          if (
            moduleGetData?.[previousIndex]?.module_ques_data?.every(
              (item) =>
                item?.ques_ans_data.every((ans) => ans.answer !== "") &&
                item?.ques_ans_data.some((ans) => ans.is_correct_answer)
            )
          ) {
            isCorrectAnswerPresent = true;
          } else {
            isCorrectAnswerPresent = false;
          }
        } else {
          isCorrectAnswerPresent = true;
        }
        if (isCorrectAnswerPresent) {
          // edit induction

          if (moduleGetData?.length > 0) {
            dispatch(
              UpdateSiteInductionModuleForClient(siteModuleData[previousIndex])
            )
              .then((response) => {
                if (response?.success) {
                  dispatch(UpdateInductionStatusSite(false));
                } else if (response?.status === 401) {
                  handleUnauthorizedAccess();
                }
              })
              .catch((error) => {});
          } else {
            // props?.closePannelDisable();
            props?.closePannel();
          }
        } else {
          dispatch(UpdateInductionStatusSite(false));
        }
      }
    }
  }

  let closeBtn;
  useEffect(() => {
    if (
      choosePDF ||
      chooseImg ||
      chooseVideo ||
      editText ||
      chooseURLink == true
    ) {
      setcheckValue(true);
      // closeBtn = (
      //   <Tooltip placement={"right-start"} title={"Remove module content"}>
      //     <button
      //       onClick={() => handleClose()}
      //       className="modue_creation_close_icon"
      //       style={{ position: "absolute", right: "20px" }}
      //     >
      //       <svg
      //         width="24"
      //         height="25"
      //         viewBox="0 0 24 25"
      //         fill="none"
      //         xmlns="http://www.w3.org/2000/svg"
      //       >
      //         <path
      //           d="m18 6.5-12 12m0-12 12 12"
      //           stroke="#667085"
      //           strokeWidth="2"
      //           strokeLinecap="round"
      //           strokeLinejoin="round"
      //         />
      //       </svg>
      //     </button>
      //   </Tooltip>
      // );
    } else {
      setcheckValue(false);
      // closeBtn = "";
    }
  }, [choosePDF, chooseImg, chooseVideo, editText, chooseURLink]);

  // if (
  //   choosePDF ||
  //   chooseImg ||
  //   chooseVideo ||
  //   editText ||
  //   chooseURLink == true
  // ) {
  //   closeBtn = (
  //     <Tooltip placement={"right-start"} title={"Remove module content"}>
  //       <button
  //         onClick={() => handleClose()}
  //         className="modue_creation_close_icon"
  //         style={{ position: "absolute", right: "20px" }}
  //       >
  //         <svg
  //           width="24"
  //           height="25"
  //           viewBox="0 0 24 25"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="m18 6.5-12 12m0-12 12 12"
  //             stroke="#667085"
  //             strokeWidth="2"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           />
  //         </svg>
  //       </button>
  //     </Tooltip>
  //   );
  // } else {
  //   closeBtn = "";
  // }

  // Adjust Module Discription Height
  useEffect(() => {
    // Calculate the scroll height and set the textarea height accordingly
    const textarea = document.querySelector(".moduleDescTextarea");
    if (textarea) {
      textarea.style.minHeight = "88px";
      textarea.style.height = `${Math.max(textarea.scrollHeight, 88)}px`;
      textarea.style.maxHeight = "400px";
      if (textarea.textLength === 0) {
        textarea.style.height = "88px";
      }
    }
  }, [moduleData, inductionData]);

  // Onclick Edit Button open Default module
  if (moduleGetData?.length > 0 && !hasRun && defaultTabChekc) {
    setHasRun(true);
  }
  useEffect(() => {
    if (
      GettAllState?.editSiteInduction &&
      moduleGetData?.length > 0 &&
      hasRun
    ) {
      SetdefaultTab("tab1");
      // SetdefaultTab(moduleGetData?.[0]?.module_name + 0);
      setHasRun(true);
    }
    if (GettAllState?.editSiteInduction && moduleGetData?.length == 0) {
      SetdefaultTab("tab1");
    }
  }, [hasRun]);

  let checkTab = GettAllState?.editSiteInduction ? true : defaultTab !== "tab1";

  useEffect(() => {
    const moduleDesc = moduleGetData[indexData]?.module_desc;
    const words = moduleDesc?.split(/\s+/);
    if (words !== undefined) {
      if (words?.length <= 255) {
        SetModuleDics(false);
      } else {
        SetModuleDics(true);
      }
    }
  }, []);

  const deleteModule = (item, index) => {
    let ModuleData = {
      module_id: item?.module_id,
      content_info: item?.content_info,
      content_info_type: item?.content_info_type,
      moduleQuesIds: item?.module_ques_data?.map(
        (item) => item?.module_question_id
      ),
    };
    let indexData = index;
    SetindexData(0);
    props?.openDeletePopup(ModuleData, indexData);
  };

  // const GetCompDocsListOfWorkerType = useCallback(async () => {
  //   // for both insurance and others
  //   // const docType = ["insurance", "other"];
  //   //changes in api needed to accept both insurance and others
  //   // const docType = "insurance";
  //   // const recipient_type = "worker"
  //   dispatch(
  //     userActions.GetCompDocsListOfWorkerType(function_assignment_id)
  //   ).then((response) => {
  //     if (response?.success === true) {
  //       const arrayData = response?.data;
  //       let doc_data = [];
  //       for (let key in arrayData) {
  //         doc_data.push({
  //           label:
  //             arrayData[key]?.compliance_doc_name +
  //             " - (" +
  //             arrayData[key]?.document_type.document_type +
  //             ")",
  //           value: arrayData[key]?.compliance_doc_id,
  //           // recipient_type: arrayData[key]?.document_type.document_type,
  //         });
  //       }
  //       setAllDocType(doc_data);
  //     } else {
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [dispatch]);

  const GetHighRiskActivityList = useCallback(async () => {
    dispatch(userActions.GetHighRiskActivityList()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        let service_data = [];
        for (let key in arrayData) {
          service_data.push({
            label: arrayData[key]?.meta_value_one,
            value: arrayData[key]?.master_setting_id,
          });
        }
        setRiskData(service_data);
        // } else if (response?.status === 401) {
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetHighRiskActivityList();
    // GetCompDocsListOfWorkerType();
  }, []);

  useEffect(() => {
    // Get Document type
    dispatch(
      userActions.GetAllDocumentTypeList({
        recipient_type: "worker",
      })
    ).then((response) => {
      let filterData = response?.data?.map((item) => {
        return {
          value: item?.document_type_id,
          label: item?.doc_type_name,
        };
      });
      filterData = filterData?.filter((ele) => ele?.label != "Other");
      setAllDocType(filterData);
    });

    // const function_assignment_id1 = {
    //   function_assignment_id: function_assignment_id,
    // };

    // dispatch(GetCompDocsListOfWorkerType(function_assignment_id1)).then(
    //   (response) => {
    //     if (response?.success) {
    //       const arrayData = response?.data;
    //       let doc_data = [];
    //       for (let key in arrayData) {
    //         doc_data.push({
    //           label:
    //             arrayData[key]?.compliance_doc_name +
    //             " - (" +
    //             arrayData[key]?.document_type.document_type +
    //             ")",
    //           value: arrayData[key]?.compliance_doc_id,
    //           // recipient_type: arrayData[key]?.document_type.document_type,
    //         });
    //       }
    //       setAllDocType(doc_data);
    //     } else if (response?.status === 401) {
    //       handleUnauthorizedAccess();
    //     }
    //   }
    // );
  }, []);

  const handleInductionDoc = (e) => {
    setIndcutionData((prevalue) => {
      return {
        ...prevalue,
        doctType_id_data: e,
        // comp_doc_id_data: e,
      };
    });
  };

  const handleInductionRisk = (e) => {
    setIndcutionData((prevalue) => {
      return {
        ...prevalue,
        master_setting_id_data: e,
      };
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    // Ensure the dragged item is moving to a different position
    if (source.index !== destination?.index) {
      // Calculate the new sort order based on the destination index
      // const newSortOrder = destination?.index;
      const newSortOrder =
        destination?.index > source.index
          ? destination?.index
          : destination?.index;
      // + 1;
      // Update the state or dispatch an action to update the order
      // const reorderedModules = Array.from(moduleGetData);
      // const [removed] = reorderedModules.splice(source.index, 1);
      // reorderedModules.splice(destination?.index, 0, removed);
      const reorderedModules = Array.from(moduleGetData);
      const movedModule = reorderedModules.splice(source.index, 1)[0];
      // reorderedModules.splice(destination?.index, 0, movedModule);
      reorderedModules.splice(newSortOrder, 0, movedModule);

      // Update the sort_order property in the reorderedModules array
      const updatedModules = reorderedModules.map((item, index) => ({
        ...item,
        sort_order: index,
      }));

      let moduleArray = updatedModules?.map((items, index) => {
        return {
          module_id: items?.module_id,
          sort_order: items?.sort_order,
        };
      });

      dispatch(UpdateModuleSortingOrder(moduleArray))
        .then((response) => {
          if (response?.success) {
            dispatch(
              GetSiteInductionAndModulesbyIdForClient(
                inductionData?.site_induction_id
              )
            ).then((response) => {
              if (response?.success) {
                dispatch(ResetSiteInductionModuleData());
                response?.data?.site_ind_modules.map((item) => {
                  dispatch(
                    AddSiteInductionModuleData({
                      site_induction_id: item?.site_ind_module_id,
                      module_name: item?.module_data?.module_name,
                      content_info: item?.module_data?.content_info,
                      module_desc: item?.module_data?.module_desc,
                      content_link_title: item?.module_data?.content_link_title,
                      content_info_type: item?.module_data?.content_info_type,
                      module_type: item?.module_data?.module_type,
                      module_ques_data: item?.module_data?.module_ques_data,
                      module_id: item?.module_id,
                      pdf_page_range: item?.module_data?.pdf_page_range,
                      site_ind_module_id: item?.site_ind_module_id,
                      sort_order: item?.sort_order,
                    })
                  );
                });
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            });
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Tabs value={defaultTab} variant="pill">
          <div className="flex flex-auto w-full">
            <div className="flex flex-col  bg-blue-50 border-r border-gray-300 w-1/4">
              <div className="overflow-y-auto h-595">
                <ScrollBar>
                  {!isSaving && (
                    <div className=" py-6 px-3">
                      <TabList className="flex-col left-side-block inductionList">
                        <TabNav
                          disabled={formSatus !== "inductionDetails"}
                          value={"tab1"}
                          onClick={() => {
                            if (GettAllState?.editSiteInduction) {
                              SetdefaultTab("tab1");
                            }
                          }}
                        >
                          Induction Details
                        </TabNav>
                        <hr className="border-t border-gray-300 mt-4 mb-3 w-full" />

                        {checkTab && (
                          <React.Fragment>
                            <Droppable
                              droppableId="tabNavList"
                              // direction="horizontal"
                            >
                              {(provided) => (
                                <TabList
                                  className="flex-col left-side-block inductionList w-full"
                                  ref={provided?.innerRef}
                                  {...provided?.droppableProps}
                                >
                                  {moduleGetData?.map((items, index) => {
                                    return (
                                      <React.Fragment>
                                        <Draggable
                                          className="w-full"
                                          key={items?.module_id}
                                          draggableId={items?.module_id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className="w-full"
                                              ref={provided?.innerRef}
                                              {...provided?.draggableProps}
                                              {...provided?.dragHandleProps}
                                              style={{
                                                ...provided?.draggableProps
                                                  .style,
                                                transition: " 0.2s ease",
                                                // backgroundColor: snapshot.isDragging ? '#d3ffd3' : 'white',
                                              }}
                                            >
                                              <TabNav
                                                value={
                                                  items?.module_name + index
                                                }
                                                key={index}
                                              >
                                                <span
                                                  className="items-center w-full text-left break-all whitespace-normal pr-1"
                                                  onClick={() =>
                                                    ViewModule({
                                                      index: index,
                                                      module_name:
                                                        items?.module_name +
                                                        index,
                                                    })
                                                  }
                                                >
                                                  {index + 1}.
                                                  {items?.module_name}
                                                </span>
                                                <div className="flex items-center gap-2">
                                                  <span
                                                    onClick={() =>
                                                      ViewModule({
                                                        index: index,
                                                        module_name:
                                                          items?.module_name +
                                                          index,
                                                      })
                                                    }
                                                    className="flex justify-center items-center "
                                                  >
                                                    <svg
                                                      width="20"
                                                      height="21"
                                                      viewBox="0 0 20 21"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M10 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM10 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM10 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM15.834 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM4.167 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667z"
                                                        stroke="#D0D5DD"
                                                        strokeWidth="1.667"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </span>
                                                  {moduleGetData?.length >
                                                    1 && (
                                                    <button
                                                      onClick={() =>
                                                        deleteModule(
                                                          items,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <svg
                                                        width="20"
                                                        height="21"
                                                        viewBox="0 0 20 21"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                                          stroke="#667085"
                                                          strokeWidth="1.667"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    </button>
                                                  )}
                                                </div>
                                              </TabNav>
                                            </div>
                                          )}
                                        </Draggable>
                                      </React.Fragment>
                                    );
                                  })}
                                  <button
                                    className="text-blue-700 text-xs font-semibold flex items-center gap-2 mt-5"
                                    onClick={handleAddNewSection}
                                  >
                                    <span>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10 4.167v11.666M4.167 10h11.666"
                                          stroke="#5786CC"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Add another module
                                  </button>
                                </TabList>
                              )}
                            </Droppable>
                          </React.Fragment>
                        )}
                      </TabList>
                    </div>
                  )}
                  {isSaving && (
                    <div>
                      <div className="blur">
                        <div className="flex">
                          <label className="font-medium text-gray-700 w-1/5">
                            Site Address*
                          </label>
                          <div className="w-4/5 pl-2">
                            <input
                              type="text"
                              placeholder="Street Address*"
                              className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <input
                              type="text"
                              placeholder="Street Address*"
                              className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center mb-5 mt-5 pt-5">
                        {/* <TailSpin color="blue" radius={"8px"} /> */}
                      </div>
                      <div className="blur">
                        <div className="flex mb-5 border-t mt-5 pt-5">
                          <label className="font-medium text-gray-700 w-1/5">
                            Evacuation Diagram
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </ScrollBar>
              </div>
            </div>
            <div className="w-3/4">
              <TabContent value="tab1" className="pr-1">
                <div className="overflow-y-auto h-595">
                  <ScrollBar>
                    <div className="p-6">
                      <div className="flex mb-5 ">
                        <label className="text-gray-700 font-Medium w-1/4">
                          Induction name*
                        </label>
                        <div className="w-3/4">
                          <ClientInput
                            type="text"
                            name="site_ind_name"
                            placeholder="Induction name"
                            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            value={inductionData?.site_ind_name}
                            inputchangehandler={handleInduction}
                            // onChange={(e) => handleInduction(e)}
                          />
                          {!inductionData?.site_ind_name &&
                            inductionNameError && (
                              <div className="text-red-500 text-sm mt-1">
                                {inductionNameError}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="flex mb-5">
                        <label className="text-gray-700 font-Medium w-1/4">
                          Induction description
                        </label>
                        <div className="w-3/4">
                          <textarea
                            // maxLength={250}
                            name="site_ind_desc"
                            rows={4}
                            cols={40}
                            placeholder="Provide a brief description to help Inductees..."
                            className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            value={inductionData?.site_ind_desc}
                            onChange={handleInduction}
                            // onChange={(e) => handleInduction(e, "site_ind_desc")}
                          />
                        </div>
                      </div>

                      {/* <div className="flex mb-5">
                           <label className="text-gray-700 font-Medium w-1/4 flex items-start">Status* <Tooltip title="Status Information" placement="top-start"><img src="/assets/icons-svg/help-circle.svg" className="pl-2.5" alt="#"/></Tooltip></label>
                           <div className="w-3/4">
                              <Select
                                 isSearchable={false}
                                 placeholder="Select Status"
                                 options={statusOptions}
                                 className="select-option h-10"
                                 ></Select>
                           </div>
                        </div> */}
                      <hr className="mb-5 border-gray-200" />
                      <div className="flex mb-5">
                        <label className="text-gray-700 font-Medium w-1/4 flex  items-start">
                          Documents{" "}
                          <Tooltip
                            title="Status Information"
                            placement="top-start"
                          >
                            <img
                              src={HelpCircleImg}
                              className="pl-2.5"
                              alt="#"
                            />
                          </Tooltip>
                        </label>
                        <div className="w-3/4">
                          <Select
                            name="doctType_id_data"
                            className="w-full h-auto select-options"
                            value={
                              Array.isArray(allDocType) &&
                              Array.isArray(inductionData?.doctType_id_data) &&
                              allDocType?.filter((option) =>
                                inductionData?.doctType_id_data?.includes(
                                  option?.value
                                )
                              )
                            } // Filter the selected options
                            onChange={(selectedOptions) =>
                              handleInductionDoc(
                                selectedOptions?.map((option) => option?.value)
                              )
                            }
                            placeholder="Select documents required"
                            isMulti
                            options={allDocType}
                          />
                        </div>
                      </div>
                      <div className="flex mb-5">
                        <label className="text-gray-700 font-Medium w-1/4">
                          Is it high risk specific?
                        </label>
                        <div className="w-auto mr-3">
                          <Segment
                            className="docType-btn-md"
                            value={isHighRisk}
                            onChange={(val) => setIsHighRisk(val)}
                          >
                            <Segment.Item value="true">
                              <span>Yes</span>
                            </Segment.Item>
                            <Segment.Item value="false">
                              <span>No</span>
                            </Segment.Item>
                          </Segment>
                        </div>
                        <div className="flex-1 set-search-icon">
                          <Select
                            name="master_setting_id_data"
                            className="w-full h-auto select-options"
                            value={
                              Array.isArray(riskData) &&
                              Array.isArray(
                                inductionData?.master_setting_id_data
                              ) &&
                              riskData.filter((option) =>
                                inductionData?.master_setting_id_data?.includes(
                                  option.value
                                )
                              )
                            } // Filter the selected options
                            onChange={(selectedOptions) =>
                              handleInductionRisk(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="Search industry"
                            isMulti
                            isDisabled={
                              isHighRisk == "" || isHighRisk[0] === "false"
                                ? true
                                : false
                            }
                            options={riskData}
                          />
                        </div>
                      </div>
                    </div>
                  </ScrollBar>
                </div>
              </TabContent>
              <TabContent
                value={defaultTab == "tab1" ? "tab2" : defaultTab}
                className="pr-1"
              >
                <div className="overflow-y-auto h-595">
                  <ScrollBar>
                    <div className="p-6">
                      {!isSaving && (
                        <React.Fragment>
                          <div className="flex mb-5 ">
                            <label className="text-gray-700 font-Medium w-1/4">
                              Module name*
                            </label>
                            <div className="w-3/4">
                              <ClientInput
                                inputchangehandler={inputModulechangehandler}
                                type="text"
                                value={moduleGetData[indexData]?.module_name}
                                name={"module_name"}
                                placeholder="Module name"
                                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                              />
                              {moduleError && (
                                <div className="text-red-500 text-sm mt-1">
                                  {moduleError}
                                </div>
                              )}
                            </div>
                            {/* <div className="w-3/4">
                        <input
                          onChange={inputModulechangehandler}
                          type="text"
                          value={siteModuleData[indexData]?.module_name}
                          name={"module_name"}
                          placeholder="Induction name"
                          className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {moduleError && ( <div className="text-red-500 text-sm mt-1"> {moduleError}</div>)}
                      </div> */}
                          </div>
                          <div className="flex mb-5">
                            <label className="text-gray-700 font-Medium w-1/4">
                              Module description
                            </label>
                            <div className="w-3/4">
                              <textarea
                                // maxLength={250}
                                name="module_desc"
                                onChange={inputModulechangehandler}
                                rows={4}
                                cols={40}
                                placeholder="Provide a brief description to help Inductees..."
                                value={moduleGetData[indexData]?.module_desc}
                                className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                              />
                              {moduleDics && (
                                <div className="text-red-500 text-sm mt-1">
                                  value too long for type character varying(255)
                                </div>
                              )}
                            </div>
                          </div>
                          <hr className="mb-5 border-gray-200" />
                        </React.Fragment>
                      )}
                      {isSaving && (
                        <div>
                          <div className="blur">
                            <div className="flex">
                              <label className="font-medium text-gray-700 w-1/5">
                                Site Address*
                              </label>
                              <div className="w-4/5 pl-2">
                                <input
                                  type="text"
                                  placeholder="Street Address*"
                                  className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                <input
                                  type="text"
                                  placeholder="Street Address*"
                                  className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center mb-5 mt-5 pt-5">
                            <TailSpin color="blue" radius={"8px"} />
                          </div>
                          <div className="blur">
                            <div className="flex mb-5 border-t mt-5 pt-5">
                              <label className="font-medium text-gray-700 w-1/5">
                                Evacuation Diagram
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isSaving && (
                        <React.Fragment>
                          <div className="flex mb-5 flex-col gap-3">
                            <label className="text-gray-900 font-semibold text-xl">
                              Module Content
                            </label>
                            {closeBtn}
                            {!checkValue && (
                              <div className="text-center w-full py-12">
                                <span className="text-base text-gray-600 font-semibold block mb-6">
                                  This Module has no content yet. Start by
                                  uploading content to get started.
                                </span>
                                <ChooseModule
                                  handleAddContent={handleAddContent}
                                />
                              </div>
                            )}
                            {choosePDF && (
                              <PdfFile
                                indexData={indexData}
                                moduleData={moduleData}
                                size={fileSize}
                              />
                            )}
                            {chooseImg && (
                              <ImgFile
                                indexData={indexData}
                                moduleData={moduleData}
                                size={fileSize}
                              />
                            )}
                            {chooseVideo && (
                              <VideoUpload
                                indexData={indexData}
                                moduleData={moduleData}
                                size={fileSize}
                              />
                            )}
                            {editText && (
                              <AddedText
                                indexData={indexData}
                                moduleData={moduleData}
                              />
                            )}
                            {chooseURLink && (
                              <URLink
                                indexData={indexData}
                                moduleData={moduleData}
                              />
                            )}
                          </div>
                          <div className="flex flex-col gap-3">
                            <label className="text-gray-900 font-semibold text-xl">
                              Assessment
                            </label>
                            {!checkValue && (
                              <div className="text-center w-full py-12">
                                <span className="text-base text-gray-600 font-semibold block">
                                  Content is needed to start filling out the
                                  assessment section. Please upload content to
                                  this module.
                                </span>
                              </div>
                            )}
                            {checkValue && (
                              <ChooseQus
                                indexData={indexData}
                                moduleData={moduleData}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </ScrollBar>
                </div>
              </TabContent>
            </div>
          </div>
        </Tabs>
      </DragDropContext>
    </React.Fragment>
  );
};
export default AddInductionDialog;
