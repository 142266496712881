import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../common/element/Dialog";
import fileCheck from "../../../../../../assets/icons-svg/file-check.svg";
import Segment from "../../../../../../common/element/Segment";
import Upload from "../../../../../../common/element/Upload";
import DatePicker from "../../../../../../common/element/DatePicker";
import plusIcon from "../../../../../../assets/icons-svg/plus.svg";
import Select from "../../../../../../common/element/Select";
import * as userActions from "../../../../../../actions/index";
import dayjs from "dayjs";
import Table from "../../../../../../common/element/Table";
import { useDispatch } from "react-redux";
import {
  GetAllProviderTypesList,
  GetComplianceChecklistById,
  UpdateComplianceChecklist,
  ArchiveChecklist,
  ArchiveComplianceDoc,
  AddChecklistDoc,
  providerType,
} from "../../../../../../actions/Admin-Client/Admin-Client";
import Tabs from "../../../../../../common/element/Tabs";
import Validations from "../../../../../../common/404/Validations";
import BtnPrimaryLight from "../../../../../../common/element/Buttons/primaryLightBtn";
import plusIconBlue from "../../../../../../assets/icons-svg/plus-blue.svg";
import { DeleteComplianceDoc } from "../../../../../../actions/Admin-Client/Admin-Client";
import { filter } from "lodash";
import Button from "../../../../../../common/element/Buttons";
const ValidationList = (props) => {
  const dispatch = useDispatch();
  const { TabNav, TabList, TabContent } = Tabs;
  const { Tr, Th, Td, THead, TBody } = Table;
  const [providerListData, setProviderListData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [openCheckListView, setOpenCheckListView] = useState(false);
  const [openUpdatedDialog, setOpenUpdatedDialog] = useState(false);
  const [openCompDeatils, setOpenCompDeatils] = useState(false);
  const [openDialogArch, setOpenDialogArch] = useState(false);
  const [openDialogArch2, setOpenDialogArch2] = useState(false);
  const [documentError, setdocumentError] = useState("");
  const [openAddComDoc, setOpenAddComDoc] = useState(false);
  const [selectedDocumentData, setSelectDocumentData] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [filtersort, setFiltersort] = useState("doc_type_name");
  const [filterorder, setFilterOrder] = useState("desc");
  const [isMandatory, setIsMandatory] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);
  const [compareValue, setCompareValue] = useState({
    checklist_Name: "",
    renewal_date: "",
    showAndHide: false,
  });
  const [addComplianceDoc, setAddComplianceDoc] = useState({
    selectedOneDoc: "",
    OtherMandatoryDoc: "",
    document_type_id: "",
    alreadyUsedDoc: [],
  });

  const [otherDocuments, setOtherDocuments] = useState({
    documentName: "",
    instructions: "",
    file: null,
  });
  const [viewOnly, setViewOnly] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);
  const [storeDelDocId, setStoreDelDocId] = useState([]);
  const [Compliancedata, SetCompliancedata] = useState({
    client_org_id: "",
    provider_type_id: "",
    checklist_name: "",
    checklist_renewal_date: "",
    ind_master_setting_ids: "",
    recipient_type: "",
    checklist_id: "",
    compDocs: [],
  });
  const [selectedRecipientType, setSelectedRecipientType] = useState(
    Compliancedata?.recipient_type ? Compliancedata.recipient_type : ""
  );
  const [seletedRecipTypeNew, setSeletedRecipTypeNew] = useState(
    Compliancedata?.recipient_type ? Compliancedata?.recipient_type : ""
  );

  const [formError, setFormErrors] = useState({
    checklistErr: "",
    recipient: "",
    expiry_date: "",
    otherDocName: "",
    providerTypeErr: "",
    selectOneDocType: "",
    isMandatoryErr: "",
  });

  const handleOnlyView = (item, type) => {
    setOpenCheckListView(true);
    setViewOnly(true);
    dispatch(
      GetComplianceChecklistById({
        checklist_id: item,
        DetailType: type,
      })
    ).then((response) => {
      if (response.success == true) {
        SetCompliancedata(response?.data);
      }
    });

    // but true one state for readOnly
  };
  const onCloseDetails = () => {
    setOpenCompDeatils(false);
    onCloseCheckList();
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    closeDialogAddDoc();
  };
  // Compare Previous value and next updated value
  useEffect(() => {
    let isoDateStr;
    if (Compliancedata?.checklist_renewal_date !== "") {
      const dateObj = new Date(Compliancedata?.checklist_renewal_date);
      isoDateStr = dateObj?.toISOString();
    }
    if (
      Compliancedata?.checklist_name !== compareValue?.checklist_Name ||
      isoDateStr !== compareValue?.renewal_date
    ) {
      setCompareValue((prevData) => ({
        ...prevData,
        showAndHide: true,
      }));
    } else {
      setCompareValue((prevData) => ({
        ...prevData,
        showAndHide: false,
      }));
    }
  }, [Compliancedata?.checklist_name, Compliancedata?.checklist_renewal_date]);

  const dateGap = 0;
  const minDate = dayjs(new Date())
    .subtract(dateGap - 1, "day")
    .startOf("day")
    .toDate();

  const selectDocType = (selectedOption) => {
    setSelectedDocumentType(selectedOption.label);
    SetCompliancedata((prevState) => ({
      ...prevState,
      provider_type_id: selectedOption?.value,
    }));
  };
  const setIndustryId = (selectedOpt) => {
    setSelectIndustry(selectedOpt);
  };
  const [updateCheckList, setUpdateCheckList] = useState({
    comp_checklist_id: "",
    checklist_name: "",
    recipient_type: "",
    provider_type_id: "",
  });
  const [dialogChoose, setChoose] = useState(false);

  const DeleteHandle = (data) => {
    dispatch(
      GetComplianceChecklistById({
        comp_checklist_id: data.comp_checklist_id,
      })
    ).then((response) => {
      if (response.success == true) {
        SetCompliancedata(response?.data);
      }
    });
    setChoose(true);
  };
  // const addOtherDocument = () => {
  //   setOtherDocuments({
  //     documentName: "",
  //     instructions: "",
  //     isMandatory: "yes",
  //     file: null,
  //   });
  // };
  const handleOtherDocChange = (field, value) => {
    if (field == "file") {
      const fileType = value?.[0]?.type;
      if (fileType == null) {
        setdocumentError("");
        return;
      }
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif") ||
        fileType.startsWith("application/pdf")
      ) {
        if (value[0]?.size <= 2 * 1024 * 1024) {
          setOtherDocuments((prevData) => ({
            ...prevData,
            [field]: value,
          }));
          setdocumentError("");
        } else {
          setdocumentError("File size exceeds the maximum limit of 2MB.");
        }
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, PDF or GIF files are allowed."
        );
      }
    } else {
      setOtherDocuments((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      if (field == "documentName") {
        setFormErrors((prev) => ({ ...prev, otherDocName: "" }));
      }
    }
  };

  const removeDuplicatesUsingReduce = (arr) => {
    return arr.reduce((acc, current) => {
      const x = acc.find((item) => item.value === current.value);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  const openDialogAddDoc = () => {
    setOpenAddComDoc(true);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      isMandatoryErr: "",
      selectOneDocType: "",
      otherDocName: "",
    }));
    // Filter Document List Data
    let recipientType = Compliancedata?.provider_type_id
      ? "provider"
      : "worker";
    dispatch(
      userActions.GetAllDocumentTypeList({
        recipient_type: recipientType,
      })
    ).then((response) => {
      let arrayData = response?.data?.map((item) => {
        return {
          value: item.document_type_id,
          label: item.doc_type_name,
        };
      });
      const filteredData = arrayData.filter(
        (doc) =>
          !addComplianceDoc.alreadyUsedDoc?.some(
            (usedDoc) => usedDoc?.checklist_doc_id === doc?.value
          )
      );
      const filteredDataOther = arrayData?.filter(
        (doc) => doc?.label === "Other"
      );

      const allData = [...filteredDataOther, ...filteredData];
      const uniqueArray = removeDuplicatesUsingReduce(allData);
      setSelectDocumentData(uniqueArray);
    });
  };
  const closeDialogAddDoc = () => {
    setOpenAddComDoc(false);
    setSelectedDoc(null);
    setdocumentError("");
    setIsMandatory("no");
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      isMandatoryErr: "",
      selectOneDocType: "",
      otherDocName: "",
    }));
    setAddComplianceDoc((prevData) => ({
      ...prevData,
      selectedOneDoc: "",
      OtherMandatoryDoc: "",
      document_type_id: "",
    }));
    setOtherDocuments({
      documentName: "",
      instructions: "",
      file: null,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setAddComplianceDoc((prevData) => ({
      ...prevData,
      selectedOneDoc: selectedOption,
      document_type_id: selectedOption.value,
    }));
  };

  const handleMandatoryChange = (value) => {
    setAddComplianceDoc((prevData) => ({
      ...prevData,
      OtherMandatoryDoc: value,
    }));
  };

  const addCompDocument = () => {
    if (buttonDisabled == false) {
      const SelectOneDocVal = Validations({
        value: addComplianceDoc?.selectedOneDoc,
        Validations: { required: true },
      });
      if (!SelectOneDocVal.valid) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          selectOneDocType: SelectOneDocVal.error_msg,
        }));
      }

      const OtherMandatoryDocVal = Validations({
        value: addComplianceDoc?.OtherMandatoryDoc?.[0],
        Validations: { required: true },
      });
      if (!OtherMandatoryDocVal.valid) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          isMandatoryErr: OtherMandatoryDocVal.error_msg,
        }));
      }

      let OtherDocUploadValiation;

      if (addComplianceDoc?.selectedOneDoc?.label === "Other") {
        OtherDocUploadValiation = Validations({
          value: otherDocuments?.documentName,
          Validations: { required: true },
        });
        if (!OtherDocUploadValiation.valid) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            otherDocName: OtherDocUploadValiation.error_msg,
          }));
        }
      }

      if (
        SelectOneDocVal.valid &&
        OtherMandatoryDocVal.valid &&
        documentError == "" &&
        addComplianceDoc?.selectedOneDoc?.label === "Other"
          ? OtherDocUploadValiation?.valid
          : OtherDocUploadValiation == undefined
      ) {
        let payload;
        if (
          otherDocuments?.documentName &&
          addComplianceDoc?.selectedOneDoc?.label === "Other" &&
          OtherDocUploadValiation?.valid
        ) {
          payload = {
            document_type_id: addComplianceDoc?.document_type_id,
            checklist_id: Compliancedata?.checklist_id,
            is_doc_mandatory:
              addComplianceDoc?.OtherMandatoryDoc?.[0] == "yes" ? true : false,
            checklistDoc: otherDocuments?.file ? otherDocuments.file?.[0] : [],
            other_doc_name: otherDocuments?.documentName,
            is_other_doc: true,
            other_doc_instruction: otherDocuments?.instructions
              ? otherDocuments?.instructions
              : "",
          };
        } else {
          payload = {
            is_other_doc: false,
            document_type_id: addComplianceDoc?.document_type_id,
            checklist_id: Compliancedata?.checklist_id,
            is_doc_mandatory:
              addComplianceDoc?.OtherMandatoryDoc?.[0] == "yes" ? true : false,
          };
        }
        setButtonDisabled(true);
        dispatch(AddChecklistDoc(payload)).then((response) => {
          if (response.success === true) {
            setButtonDisabled(false);
            setAddOpen(true);
            dispatch(
              GetComplianceChecklistById({
                checklist_id: Compliancedata?.checklist_id,
                DetailType: "active",
              })
            ).then((response) => {
              if (response.success == true) {
                let arrayData = [];
                SetCompliancedata(response?.data);
                arrayData = response?.data?.compDocs;
                const result = arrayData.map((doc) => ({
                  checklist_doc_id: doc?.document_type?.document_type_id,
                  doc_type_name: doc?.document_type?.doc_type_name,
                }));

                setAddComplianceDoc((prevData) => ({
                  ...prevData,
                  alreadyUsedDoc: result,
                }));
              }
            });
            //  specifics id call get api
          } else {
            setButtonDisabled(false);
          }
        });
      }
    }
  };

  const handleDeleteDocument = useCallback(
    (docId) => {
      const newDocuments = Compliancedata?.compDocs.filter(
        (doc) => doc?.checklist_doc_id !== docId
      );
      SetCompliancedata((prevData) => ({
        ...prevData,
        compDocs: newDocuments,
      }));
      setStoreDelDocId((prevIds) => [...prevIds, docId]);
    },
    [Compliancedata?.compDocs]
  );

  // const closeDialogAddDoc = () => {
  //   setOpenAddComDoc(false)
  // }

  const onViewCheckList = (item, type) => {
    setOpenCheckListView(true);
    SetCompliancedata((prevData) => ({
      ...prevData,
      checklist_id: item,
    }));
    // set item id herer
    dispatch(
      GetComplianceChecklistById({
        checklist_id: item,
        DetailType: type,
      })
    ).then((response) => {
      if (response.success == true) {
        let arrayData = [];
        SetCompliancedata(response?.data);
        arrayData = response?.data?.compDocs;
        const result = arrayData?.map((doc) => ({
          checklist_doc_id: doc?.document_type?.document_type_id,
          doc_type_name: doc?.document_type?.doc_type_name,
        }));
        setCompareValue((prevState) => ({
          ...prevState,
          checklist_Name: response?.data?.checklist_name
            ? response?.data?.checklist_name
            : "",
          renewal_date: response?.data?.checklist_renewal_date
            ? response?.data?.checklist_renewal_date
            : "",
        }));

        setAddComplianceDoc((prevData) => ({
          ...prevData,
          alreadyUsedDoc: result,
        }));
      }
    });
  };
  const onCloseCheckList = () => {
    if (!buttonDisabled || !buttonDisabled2) {
      setOpenCheckListView(false);
      setViewOnly(false);
      setStoreDelDocId([]);
      clearError();
      setCompareValue({
        checklist_Name: "",
        renewal_date: "",
        showAndHide: false,
      });
    }
  };
  const handleArchive = () => {
    setOpenDialogArch(true);
  };
  const closeDialogArch = () => {
    if (buttonDisabled == false) {
      setOpenDialogArch(false);
      setViewOnly(false);
    }
  };
  const onConfirm = () => {
    setButtonDisabled(true);
    dispatch(ArchiveChecklist({ checklist_id: Compliancedata?.checklist_id }))
      .then((response) => {
        if (response.success === true) {
          setOpenDialogArch2(true);
          props?.GetAllComplianceChecklistOfClient();
          setTimeout(() => {
            setButtonDisabled(false);
          }, 100);
        } else {
          setButtonDisabled(false);
        }
      })
      .catch((error) => {
        setButtonDisabled(false);
      });
  };
  const closeDialogArch2 = () => {
    setOpenDialogArch2(false);
    setOpenDialogArch(false);
    setOpenCheckListView(false);
    setViewOnly(false);
  };

  // Get ProviderList
  const fetchProviderTypes = useCallback(() => {
    dispatch(GetAllProviderTypesList()).then((response) => {
      const filterData = response?.data?.map((item) => ({
        value: item.provider_type_id,
        label: item.provider_type_name,
      }));

      setProviderListData(filterData);
    });
  }, [dispatch, setProviderListData]);

  // useEffect to fetch provider types || filter Industry

  useEffect(() => {
    setSelectedRecipientType(Compliancedata?.recipient_type);
    setSeletedRecipTypeNew(
      Compliancedata?.recipient_type ? Compliancedata?.recipient_type : ""
    );
    if (industryData.length === 0) {
      setIndustryData(props.industryData);
    }
    if (selectedRecipientType === "provider" && providerListData.length === 0) {
      fetchProviderTypes();
    }
    if (providerListData?.length > 0) {
      const filteredProviders = providerListData?.filter((ele) => {
        return ele.value === Compliancedata?.provider_type_id;
      });
      setSelectedDocumentType(filteredProviders);
    }
    if (industryData?.length && Compliancedata?.compCheckInd?.length) {
      const filteredIndustry = industryData?.filter((ele) => {
        return ele.value === Compliancedata?.compCheckInd[0]?.master_setting_id;
      });
      setSelectIndustry(filteredIndustry);
    }
  }, [
    Compliancedata?.recipient_type,
    Compliancedata,
    Compliancedata?.provider_type_id,
    selectedRecipientType,
    providerListData?.length,
    fetchProviderTypes,
  ]);

  const clearError = () => {
    SetCompliancedata({
      client_org_id: "",
      provider_type_id: "",
      checklist_name: "",
      checklist_renewal_date: "",
      ind_master_setting_ids: "",
      recipient_type: "",
    });

    setFormErrors({
      checklistErr: "",
      recipient: "",
      expiry_date: "",
      otherDocName: "",
      providerTypeErr: "",
      selectOneDocType: "",
      isMandatoryErr: "",
    });
  };
  // Close Model
  const onChooseClose = (e) => {
    setChoose(false);
    clearError();
  };

  // Open Add document pannel
  const AddDocument = () => {
    props.openChooseDoc();
  };

  // Delete Specific document
  const DeleteDocHandle = (item, specificIndex) => {
    dispatch(DeleteComplianceDoc({ compliance_doc_id: item })).then(
      (response) => {
        if (response.success) {
          let filteredData = Compliancedata?.compDocs.filter(
            (item, index) => index !== specificIndex
          );
          SetCompliancedata((prev) => {
            return {
              ...prev,
              ["compDocs"]: filteredData,
            };
          });
        }
      }
    );
  };

  const updatedCompCheckList = () => {
    if (compareValue?.showAndHide) {
      const checklistNameVal = Validations({
        value: Compliancedata?.checklist_name,
        Validations: { required: true },
      });
      if (!checklistNameVal.valid) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          checklistErr: checklistNameVal.error_msg,
        }));
      }

      const recipient = Validations({
        value: seletedRecipTypeNew,
        Validations: { required: true },
      });
      if (!recipient.valid) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          recipient: recipient.error_msg,
        }));
      }

      const providerTypeVal = Validations({
        value: Compliancedata?.provider_type_id,
        Validations: { required: true },
      });
      if (!providerTypeVal.valid) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          providerTypeErr: providerTypeVal.error_msg,
        }));
      }

      const expiry_date = Validations({
        value: Compliancedata?.checklist_renewal_date
          ? Compliancedata?.checklist_renewal_date
          : "",
        Validations: { required: true },
      });
      if (!expiry_date.valid) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          expiry_date: expiry_date.error_msg,
        }));
      }

      if (
        checklistNameVal.valid &&
        recipient.valid &&
        providerTypeVal.valid &&
        expiry_date.valid
      ) {
        let payload = {
          checklist_id: Compliancedata?.checklist_id,
          checklist_name: Compliancedata?.checklist_name,
          checklist_renewal_date: Compliancedata?.checklist_renewal_date,
        };
        setButtonDisabled2(true);

        dispatch(UpdateComplianceChecklist(payload))
          .then((response) => {
            if (response.success === true) {
              // setOpenUpdatedDialog(true)
              setOpenCompDeatils(true);
              // onCloseCheckList()
              setCompareValue({
                checklist_Name: "",
                renewal_date: "",
                showAndHide: false,
              });
              setButtonDisabled2(false);
              props?.GetAllComplianceChecklistOfClient();
              setOpenCheckListView(false);
            } else {
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          })
          .finally(() => {
            setButtonDisabled2(false);
          });
      }
    } else {
      setOpenCompDeatils(true);
    }
  };
  const archiveCompDocList = async () => {
    if (storeDelDocId?.length > 0) {
      setButtonDisabled(true);
      dispatch(ArchiveComplianceDoc({ checklistDocIds: storeDelDocId }))
        .then((response) => {
          if (response?.success) {
            // onCloseCheckList()
            setOpenCheckListView(false);
            setOpenUpdatedDialog(true);
            setStoreDelDocId([]);
          } else {
            console.error("Failed to archive documents:", response);
          }
        })
        .catch((error) => {
          console.error("Error archiving documents:", error);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    } else {
      setOpenUpdatedDialog(true);
    }
  };

  const onCloseUpdateDialog = () => {
    setOpenUpdatedDialog(false);
    clearError();
    setOpenCheckListView(false);
  };
  const handleSort = (sortType) => {
    setFiltersort(sortType);

    setFilterOrder(filterorder === "asc" ? "desc" : "asc");
  };
  const OnComplianceCheckListById = useCallback(() => {
    if (Compliancedata?.checklist_id) {
      dispatch(
        GetComplianceChecklistById({
          checklist_id: Compliancedata?.checklist_id,
          DetailType: "active",
          sort: filtersort,
          order: filterorder,
        })
      ).then((response) => {
        if (response.success == true) {
          let arrayData = [];
          SetCompliancedata(response?.data);

          arrayData = response?.data?.compDocs;
          const result = arrayData.map((doc) => ({
            checklist_doc_id: doc?.document_type?.document_type_id,
            doc_type_name: doc?.document_type?.doc_type_name,
          }));

          setAddComplianceDoc((prevData) => ({
            ...prevData,
            alreadyUsedDoc: result,
          }));
        }
      });
    }
  }, [filterorder, filtersort]);

  useEffect(() => {
    OnComplianceCheckListById();
  }, [filterorder, filtersort]);
  return (
    <React.Fragment>
      <div className="overflow-x-auto">
        <div className="w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg border-gray-200">
            <Table className="min-w-full divide-y divide-gray-200">
              <THead className="bg-gray-100">
                <Tr>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gpy-3.5ray-600 w-3/4"
                  >
                    Checklist
                  </Th>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
                  >
                    Recipient
                  </Th>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
                  >
                    <span
                      className="flex items-center gap-1"
                      onClick={() => props?.handleSort("created_date")}
                    >
                      Renewal date
                      <svg
                        className={`transform ${
                          props.filtersort === "created_date" &&
                          props.filterorder === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          stroke-width="1.333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </Th>
                  <Th className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "></Th>
                </Tr>
              </THead>
              <TBody className="divide-y divide-gray-200">
                {props.loadData &&
                  props?.DocData &&
                  props?.dialogArch == false &&
                  props.DocData.map((item) => (
                    <Tr
                      className="cursor-pointer"
                      onClick={() =>
                        onViewCheckList(item?.checklist_id, "active")
                      }
                      key={item?.compliance_doc_id}
                    >
                      <Td className=" px-4 py-3.5 font-medium text-gray-900">
                        {item?.checklist_name}
                      </Td>
                      <Td className="px-4">{item?.recipient_type} </Td>
                      <Td className=" px-4 py-3.5 font-medium text-gray-900">
                        <Button
                          onClick={() =>
                            onViewCheckList(item?.checklist_id, "active")
                          }
                        >
                          {moment(item?.checklist_renewal_date).format(
                            "DD-MM-YYYY"
                          )}
                        </Button>
                      </Td>
                      {/* <Td>
                        <button onClick={() => DeleteHandle(item)}>
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                              stroke="#667085"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </Td> */}
                    </Tr>
                  ))}
                {props?.dialogArch == true &&
                  props?.DocData &&
                  props.DocData.map((item) => (
                    <Tr
                      className="cursor-pointer"
                      onClick={() =>
                        handleOnlyView(item?.checklist_id, "archived")
                      }
                      key={item?.compliance_doc_id}
                    >
                      <Td className=" px-4 py-3.5 font-medium text-gray-900">
                        {item?.checklist_name}
                      </Td>
                      <Td className="px-4">{item?.recipient_type} </Td>
                      <Td className=" px-4 py-3.5 font-medium text-gray-900">
                        <Button
                          onClick={() =>
                            handleOnlyView(item?.checklist_id, "archived")
                          }
                        >
                          {moment(item?.checklist_renewal_date).format(
                            "DD-MM-YYYY"
                          )}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </div>
        </div>
      </div>
      {/* Delete Dialog */}
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              {Compliancedata?.checklist_name}
            </h5>
            <p class="text-gray-600">Compliance Checklist</p>
          </div>
        </div>
        <Tabs defaultValue="tab1" variant="pill" className="p-3">
          <div className=" bg-gray-25 border border-gray-100 p-1 rounded-lg mb-5">
            <TabList className={"bg-blue-25"}>
              <TabNav value="tab1">Details</TabNav>
              <TabNav value="tab2">Documents</TabNav>
            </TabList>
          </div>
          <div className="">
            <TabContent value="tab1">
              <div className="px-6">
                <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Checklist name
                  </label>
                  <div className="w-3/4 pl-2">
                    <input
                      className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={Compliancedata?.checklist_name}
                      placeholder="Enter checklist name"
                      // onChange={(e) => setDocumentName(e.target.value)}
                    />
                    {/* {!documentName && formError.docName && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formError.docName}
                </p>
              )} */}
                  </div>
                </div>
                <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Recipient type
                  </label>
                  <div className="w-3/4 pl-2">
                    <Segment
                      className="docType-btn"
                      value={selectedRecipientType}
                      // onChange={(val) => setSelectedRecipientType(val[0])}
                    >
                      <Segment.Item value="provider">Provider</Segment.Item>
                      <Segment.Item value="worker">Worker</Segment.Item>
                    </Segment>
                  </div>
                </div>

                {selectedRecipientType == "provider" && (
                  <div className="flex mb-5">
                    <label className="font-medium text-gray-700 w-1/4">
                      Provider type
                    </label>
                    <div className="w-3/4 pl-2">
                      <Select
                        className="w-full h-9 select-options"
                        placeholder="Select document"
                        readOnly={true}
                        options={providerListData}
                        value={selectedDocumentType ? selectedDocumentType : ""}
                      ></Select>
                    </div>
                  </div>
                )}

                <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Industry
                  </label>
                  <div className="w-3/4 pl-2">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select Industry"
                      options={industryData}
                      readOnly={true}
                      value={selectIndustry?.filter((option) => option.label)}
                      onChange={(selectedOptions) =>
                        setIndustryId(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      isMulti
                    ></Select>
                    {/* {!industryId && formError.industry && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formError.industry}
                </p>
              )} */}
                  </div>
                </div>
                <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Checklist renewal date*
                  </label>
                  <div className="w-3/4 pl-2 flex">
                    <div className="w-full relative">
                      <span className="adon_icon absolute left-4 top-3">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#0ckeddmh9a)">
                            <path
                              d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z"
                              stroke="#344054"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="0ckeddmh9a">
                              <path
                                fill="#fff"
                                transform="translate(0 .958)"
                                d="M0 0h20v20H0z"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <DatePicker
                        // onChange={(e) => handleForm(e, "expiry_date")}
                        disabled={true}
                        placeholder="Checklist renewal date"
                        // minDate={minDate}
                        value={
                          Compliancedata?.checklist_renewal_date
                            ? new Date(Compliancedata.checklist_renewal_date)
                            : null
                        }
                      />
                      {/* {!expiryDate && formError.expiry_date && (
                  <p className="text-xs text-red-600 mb-2 mt-0">
                    {formError.expiry_date}
                  </p>
                )} */}
                    </div>
                  </div>
                </div>
              </div>
            </TabContent>
            <TabContent value="tab2">
              <div className="overflow-x-auto">
                <div className="w-full inline-block align-middle">
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table className="min-w-full divide-y divide-gray-200">
                      <THead className="bg-gray-100">
                        <Tr>
                          <Th
                            scope="col"
                            className="bg-gray-100 px-4 py-3 font-medium text-left text-gpy-3.5ray-600 w-3/4"
                          >
                            Checklist
                          </Th>
                          <Th
                            scope="col"
                            className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
                          >
                            Mandatory?
                          </Th>
                          <Th className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "></Th>
                        </Tr>
                      </THead>
                      <TBody className="divide-y divide-gray-200">
                        {Compliancedata?.compDocs &&
                          Compliancedata?.compDocs.map((item, index) => (
                            <Tr key={item?.compliance_doc_id}>
                              <Td className=" px-4 py-3.5 font-medium text-gray-900">
                                {item?.document_type?.doc_type_name}
                              </Td>
                              <Td className="px-4">
                                {item?.is_mandatory ? "Yes" : "False"}{" "}
                              </Td>
                              <Td>
                                <button
                                  onClick={() =>
                                    DeleteDocHandle(
                                      item?.compliance_doc_id,
                                      index
                                    )
                                  }
                                >
                                  <svg
                                    width="19"
                                    height="20"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                      stroke="#667085"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </Td>
                            </Tr>
                          ))}
                      </TBody>
                    </Table>
                  </div>
                  <BtnLight
                    className="px-3 color-blue-700 text-xs h-9 flex gap-2 items-center mt-2 w-auto m-auto mr-0"
                    onClick={AddDocument}
                  >
                    Add Document
                    <span>
                      <img src={plusIconBlue} alt="" />
                    </span>
                  </BtnLight>
                </div>
              </div>
            </TabContent>
          </div>
        </Tabs>

        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onChooseClose}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={true} className="ml-1.5">
            Save
          </BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={openCheckListView}
        onClose={onCloseCheckList}
        onRequestClose={onCloseCheckList}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              {Compliancedata?.checklist_name}
            </h5>
          </div>
        </div>

        <Tabs className="px-2" defaultValue="tab1" variant="pill">
          <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 mt-6 client-portal-tab">
            <TabList>
              <TabNav value="tab1">Details</TabNav>
              <TabNav value="tab2">Documents</TabNav>
            </TabList>
          </div>

          <TabContent value="tab1">
            <div className="px-6">
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Checklist name test*
                </label>
                <div className="w-3/4 pl-2">
                  <input
                    disabled={viewOnly}
                    maxlength={25}
                    className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={Compliancedata?.checklist_name}
                    placeholder="Enter checklist name"
                    onChange={(e) =>
                      SetCompliancedata((prevState) => ({
                        ...prevState,
                        checklist_name: e.target.value,
                      }))
                    }
                  />
                  {!Compliancedata?.checklist_name &&
                    formError.checklistErr && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.checklistErr}
                      </p>
                    )}
                </div>
              </div>
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Recipient type*
                </label>
                <div className="w-3/4 pl-2">
                  <Segment
                    className="docType-btn"
                    value={seletedRecipTypeNew}
                    readOnly={true}
                    onChange={(val) => setSeletedRecipTypeNew(val[0])}
                  >
                    <Segment.Item value="provider">Provider</Segment.Item>
                    <Segment.Item value="worker">Worker</Segment.Item>
                  </Segment>
                  {/*  */}
                </div>
              </div>
              {Compliancedata?.provider_type_id && (
                <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Provider type*
                  </label>
                  <div className="w-3/4 pl-2">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      // readOnly={viewOnly}
                      readOnly={true}
                      options={providerListData}
                      value={selectedDocumentType}
                      onChange={(selectedOption) =>
                        selectDocType(selectedOption)
                      }
                    ></Select>
                    {!selectedDocumentType && formError.providerTypeErr && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.providerTypeErr}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Checklist renewal date*
                </label>
                <div className="w-3/4 pl-2 flex">
                  <div className="w-full relative">
                    <span className="adon_icon absolute left-4 top-3">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#0ckeddmh9a)">
                          <path
                            d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z"
                            stroke="#344054"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="0ckeddmh9a">
                            <path
                              fill="#fff"
                              transform="translate(0 .958)"
                              d="M0 0h20v20H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <DatePicker
                      disabled={viewOnly}
                      // value={new Date(Compliancedata?.checklist_renewal_date)}
                      value={
                        Compliancedata?.checklist_renewal_date
                          ? new Date(Compliancedata.checklist_renewal_date)
                          : null
                      }
                      onChange={(e) => {
                        SetCompliancedata((prevState) => ({
                          ...prevState,
                          checklist_renewal_date: e,
                        }));
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          expiry_date: "",
                        }));
                      }}
                      placeholder="Select renewal date"
                      minDate={minDate}
                    />
                    {formError.expiry_date && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.expiry_date}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-5 flex justify-end">
                {viewOnly == false && (
                  <BtnLight
                    onClick={handleArchive}
                    className="w-44  flex items-center justify-center gap-2"
                  >
                    Archive Checklist
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.13029 6.68629C3.99362 6.68276 3.89437 6.67485 3.80514 6.6571C3.14399 6.52559 2.62716 6.00876 2.49565 5.34761C2.46362 5.18661 2.46362 4.99301 2.46362 4.60579C2.46362 4.21858 2.46362 4.02498 2.49565 3.86398C2.62716 3.20283 3.14399 2.686 3.80514 2.55449C3.96614 2.52246 4.15974 2.52246 4.54696 2.52246H17.047C17.4342 2.52246 17.6278 2.52246 17.7888 2.55449C18.4499 2.686 18.9668 3.20283 19.0983 3.86398C19.1303 4.02498 19.1303 4.21858 19.1303 4.60579C19.1303 4.99301 19.1303 5.18661 19.0983 5.34761C18.9668 6.00876 18.4499 6.52559 17.7888 6.6571C17.6995 6.67485 17.6003 6.68276 17.4636 6.68629M9.13029 10.8558H12.4636M4.13029 6.68913H17.4636V13.5225C17.4636 14.9226 17.4636 15.6227 17.1911 16.1574C16.9515 16.6278 16.569 17.0103 16.0986 17.25C15.5638 17.5225 14.8638 17.5225 13.4636 17.5225H8.13029C6.73016 17.5225 6.03009 17.5225 5.49531 17.25C5.02491 17.0103 4.64246 16.6278 4.40277 16.1574C4.13029 15.6227 4.13029 14.9226 4.13029 13.5225V6.68913Z"
                        stroke="#344054"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </BtnLight>
                )}
              </div>
            </div>
            {viewOnly == false && (
              <div className="flex dailog-footer ">
                <BtnLight
                  disabled={buttonDisabled2}
                  className="mr-1.5"
                  variant="plain"
                  onClick={onCloseCheckList}
                >
                  Cancel
                </BtnLight>
                <BtnPrimary
                  disabled={buttonDisabled2}
                  onClick={() => updatedCompCheckList()}
                  className="ml-1.5"
                >
                  Save
                </BtnPrimary>
              </div>
            )}
          </TabContent>

          <TabContent value="tab2">
            {Compliancedata?.compDocs?.length === 0 && (
              <>
                <>
                  <div className="h-60">
                    <div className="h-full flex items-center justify-center">
                      <div className="text-center">
                        <h4 className="font-semibold text-sm">
                          No document selected
                        </h4>
                        <p className="text-xs">
                          Please add at least one to build the checklist
                        </p>
                        <BtnPrimary
                          onClick={openDialogAddDoc}
                          className="px-3 w-40 text-xs h-9 flex gap-2 items-center mx-auto mt-4"
                        >
                          Add Document(s)
                          <span>
                            <img src={plusIcon} alt="Plus Icon" />
                          </span>
                        </BtnPrimary>
                      </div>
                    </div>
                  </div>
                </>
              </>
            )}
            {Compliancedata?.compDocs?.length > 0 && (
              <>
                <div className="overflow-hidden border rounded-lg border-gray-200">
                  <Table>
                    <THead>
                      <Tr>
                        <Th>
                          <span
                            className="flex items-center gap-1"
                            onClick={() => handleSort("doc_type_name")}
                          >
                            Document
                            <svg
                              className={`transform ${
                                filtersort === "doc_type_name" &&
                                filterorder === "asc"
                                  ? "rotate-180"
                                  : ""
                              }`}
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                stroke="#475467"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </Th>
                        <Th>Mandatory?</Th>

                        {viewOnly == false && <Th></Th>}
                      </Tr>
                    </THead>
                    <TBody>
                      {Compliancedata?.compDocs &&
                        (Compliancedata?.compDocs).map((doc, index) => (
                          <Tr key={index}>
                            <Td>{doc.document_type?.doc_type_name}</Td>
                            <Td>
                              {doc.is_doc_mandatory === true ? "Yes" : "No"}
                            </Td>
                            {viewOnly == false && (
                              <Td>
                                <Button
                                  onClick={() =>
                                    handleDeleteDocument(doc?.checklist_doc_id)
                                  }
                                >
                                  <svg
                                    width="19"
                                    height="20"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                      stroke="#667085"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </Button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                      {/* { } */}
                    </TBody>
                  </Table>
                </div>
                {viewOnly == false && (
                  <div className="mb-5 mt-5 mr-5 flex justify-end">
                    <BtnLight
                      onClick={openDialogAddDoc}
                      className="w-36  flex items-center text-blue-700 justify-center gap-2"
                    >
                      Add more
                      <span>
                        <img src={plusIconBlue} alt="Add icon" />
                      </span>
                    </BtnLight>
                  </div>
                )}
              </>
            )}
            {viewOnly == false && (
              <div className="flex dailog-footer ">
                <BtnLight
                  disabled={buttonDisabled}
                  className="mr-1.5"
                  variant="plain"
                  onClick={onCloseCheckList}
                >
                  Cancel
                </BtnLight>
                <BtnPrimary
                  disabled={buttonDisabled}
                  onClick={archiveCompDocList}
                  className="ml-1.5"
                >
                  Save
                </BtnPrimary>
              </div>
            )}
          </TabContent>
        </Tabs>

        {/* {viewOnly == false && (
          <div className="flex dailog-footer ">
            <BtnLight disabled={buttonDisabled} className="mr-1.5" variant="plain" onClick={onCloseCheckList}>
              Cancel
            </BtnLight>
            <BtnPrimary disabled={(buttonDisabled ? buttonDisabled : storeDelDocId?.length > 0 ? false : compareValue?.showAndHide == false ? true : false)}
              onClick={() => updatedCompCheckList()}
              className="ml-1.5">
              Save
            </BtnPrimary>
          </div>
        )} */}
        {viewOnly == true && (
          <div className="flex dailog-footer ">
            <BtnPrimary
              className="mr-1.5"
              variant="plain"
              onClick={onCloseCheckList}
            >
              Close
            </BtnPrimary>
          </div>
        )}
      </Dialog>
      {/* */}
      <Dialog
        isOpen={openUpdatedDialog}
        onClose={onCloseUpdateDialog}
        onRequestClose={onCloseUpdateDialog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Document updated successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onCloseUpdateDialog}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={openDialogArch}
        onClose={closeDialogArch}
        onRequestClose={closeDialogArch}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="56"
              height="57"
              viewBox="0 0 56 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="4.47656"
                width="48"
                height="48"
                rx="24"
                fill="#D2EBFF"
              />
              <rect
                x="4"
                y="4.47656"
                width="48"
                height="48"
                rx="24"
                stroke="#E5F3FE"
                stroke-width="8"
              />
              <path
                d="M20 24.4732C19.836 24.4689 19.7169 24.4594 19.6098 24.4381C18.8164 24.2803 18.1962 23.6601 18.0384 22.8667C18 22.6735 18 22.4412 18 21.9766C18 21.5119 18 21.2796 18.0384 21.0864C18.1962 20.293 18.8164 19.6728 19.6098 19.515C19.803 19.4766 20.0353 19.4766 20.5 19.4766H35.5C35.9647 19.4766 36.197 19.4766 36.3902 19.515C37.1836 19.6728 37.8038 20.293 37.9616 21.0864C38 21.2796 38 21.5119 38 21.9766C38 22.4412 38 22.6735 37.9616 22.8667C37.8038 23.6601 37.1836 24.2803 36.3902 24.4381C36.2831 24.4594 36.164 24.4689 36 24.4732M26 29.4766H30M20 24.4766H36V32.6766C36 34.3567 36 35.1968 35.673 35.8385C35.3854 36.403 34.9265 36.862 34.362 37.1496C33.7202 37.4766 32.8802 37.4766 31.2 37.4766H24.8C23.1198 37.4766 22.2798 37.4766 21.638 37.1496C21.0735 36.862 20.6146 36.403 20.327 35.8385C20 35.1968 20 34.3567 20 32.6766V24.4766Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to archive this Compliance Checklist?
          </p>
          <p className="text-base text-gray-400  text-center mt-4">
            Archiving this checklist will impact all associated Providers who
            have submitted documents against it. Please confirm if you want to
            proceed with deleting the entire checklist.
          </p>
        </div>
        <div className="mt-8 gap-5 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} onClick={closeDialogArch}>
            Close
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} onClick={onConfirm}>
            Confirm
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={openDialogArch2}
        onClose={closeDialogArch2}
        onRequestClose={closeDialogArch2}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="56"
              height="57"
              viewBox="0 0 56 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="4.47656"
                width="48"
                height="48"
                rx="24"
                fill="#D2EBFF"
              />
              <rect
                x="4"
                y="4.47656"
                width="48"
                height="48"
                rx="24"
                stroke="#E5F3FE"
                stroke-width="8"
              />
              <path
                d="M20 24.4732C19.836 24.4689 19.7169 24.4594 19.6098 24.4381C18.8164 24.2803 18.1962 23.6601 18.0384 22.8667C18 22.6735 18 22.4412 18 21.9766C18 21.5119 18 21.2796 18.0384 21.0864C18.1962 20.293 18.8164 19.6728 19.6098 19.515C19.803 19.4766 20.0353 19.4766 20.5 19.4766H35.5C35.9647 19.4766 36.197 19.4766 36.3902 19.515C37.1836 19.6728 37.8038 20.293 37.9616 21.0864C38 21.2796 38 21.5119 38 21.9766C38 22.4412 38 22.6735 37.9616 22.8667C37.8038 23.6601 37.1836 24.2803 36.3902 24.4381C36.2831 24.4594 36.164 24.4689 36 24.4732M26 29.4766H30M20 24.4766H36V32.6766C36 34.3567 36 35.1968 35.673 35.8385C35.3854 36.403 34.9265 36.862 34.362 37.1496C33.7202 37.4766 32.8802 37.4766 31.2 37.4766H24.8C23.1198 37.4766 22.2798 37.4766 21.638 37.1496C21.0735 36.862 20.6146 36.403 20.327 35.8385C20 35.1968 20 34.3567 20 32.6766V24.4766Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Compliance Checklist successfully archived
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={closeDialogArch2}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={openAddComDoc}
        onClose={closeDialogAddDoc}
        onRequestClose={closeDialogAddDoc}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Compliance Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Select document(s)*
            </label>
            <div className="w-3/4 pl-2">
              <Select
                placeholder="Select documents required"
                name="comp_doc_id_data"
                className="w-full h-auto select-options"
                value={addComplianceDoc?.selectedOneDoc}
                options={selectedDocumentData}
                onChange={handleSelectChange}
              />
              {!addComplianceDoc?.selectedOneDoc &&
                formError?.selectOneDocType && (
                  <p className="text-xs text-red-600 mb-2 mt-0">
                    {formError?.selectOneDocType}
                  </p>
                )}
            </div>
          </div>
          <div className="flex">
            <label className="font-medium text-gray-700 w-1/4">
              Is it mandatory?*
            </label>
            <div className="w-3/4 pl-2">
              <Segment
                className="docType-btn"
                value={addComplianceDoc?.OtherMandatoryDoc}
                onChange={handleMandatoryChange}
              >
                <Segment.Item value="yes">Yes</Segment.Item>
                <Segment.Item value="no">No</Segment.Item>
              </Segment>
              {!addComplianceDoc?.OtherMandatoryDoc &&
                formError?.isMandatoryErr && (
                  <p className="text-xs text-red-600 mb-2 mt-0">
                    {formError?.isMandatoryErr}
                  </p>
                )}
            </div>
          </div>
          {addComplianceDoc?.selectedOneDoc?.label === "Other" && (
            <div>
              <div className="flex mb-5">
                <label className="font-medium mt-5 text-gray-700 w-1/4">
                  ‘Other’ document name*
                </label>
                <div className="w-3/4 pl-2 mt-5">
                  <input
                    className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={
                      otherDocuments?.documentName
                        ? otherDocuments?.documentName
                        : ""
                    }
                    placeholder="Name this ‘Other’ document"
                    onChange={(e) =>
                      handleOtherDocChange("documentName", e.target.value)
                    }
                  />
                  {formError?.otherDocName && (
                    <p className="text-xs text-red-600 mb-2 mt-0">
                      {formError?.otherDocName}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex mb-5">
                <label className="font-medium text-gray-700 w-1/4">
                  What would you like the recipient to do?
                </label>
                <div className="w-3/4 pl-2">
                  <input
                    className="h-9 pl-3 w-full input input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={otherDocuments?.instructions}
                    placeholder="Provide short instruction here e.g please download file, fill in information and reupload"
                    onChange={(e) =>
                      handleOtherDocChange("instructions", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="flex mb-5 pt-5">
                <label className="font-medium text-gray-700 w-1/4">
                  Upload ‘Other’ document
                </label>
                <div className="w-3/4 pl-2">
                  <Upload
                    uploadLimit={1}
                    onChange={(file) => handleOtherDocChange("file", file)}
                    accept={".jpg, .jpeg, .png, .pdf,"}
                    draggable
                    className="w-full"
                    onFileRemove={() => handleOtherDocChange("file", null)}
                  >
                    <div className="text-center">
                      <div className="flex items-center justify-center flex-col">
                        <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                              stroke="#475467"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>
                        <span className="pt-4">
                          <span className="text-blue-700 font-semibold mr-1">
                            Click to upload
                          </span>
                          <span className="text-xs text-gray-600">
                            or drag and drop
                          </span>
                          <div>
                            <span className="text-xs text-gray-600">
                              (Max 2MB. Only pdf, heic, png, webp, and jpg files
                              will be accepted)
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </Upload>
                  {documentError && (
                    <p className="text-xs text-red-600 mb-2 mt-0">
                      {documentError}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* {addComplianceDoc?.selectedOneDoc?.label === 'Other' && (
            <div className="flex">
              <label
                className="cursor-pointer flex gap-2 font-medium text-blue-700 w-1/4"
                onClick={addOtherDocument}
              >
                <span>
                  <img src={plusIconBlue} alt="" />
                </span>
                Add another ‘Other’ document
              </label>
            </div>
          )} */}
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            className="mr-1.5"
            variant="plain"
            onClick={closeDialogAddDoc}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            className="ml-1.5"
            onClick={addCompDocument}
          >
            Add
          </BtnPrimary>
        </div>
        <Dialog
          isOpen={dialogAddOpen}
          onClose={onAddDialogClose}
          onRequestClose={onAddDialogClose}
          bodyOpenClassName="overflow-hidden"
          className="customAlert"
        >
          <div className="px-6 pt-6">
            <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p className="text-base text-gray-900 font-semibold text-center mt-4">
              Document added successfully!
            </p>
          </div>
          <div className="mt-8 flex justify-between pb-6 px-6">
            <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
          </div>
        </Dialog>
      </Dialog>
      <Dialog
        isOpen={openCompDeatils}
        onClose={onCloseDetails}
        onRequestClose={onCloseDetails}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Details updated successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onCloseDetails}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ValidationList;
