import React, { useState, useEffect, useCallback } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";
import Validations from "../../../../../common/404/Validations";
import { useDispatch } from "react-redux";
import imagePlus from "../../../../../assets/icons-svg/image-plus.svg";
import { updateAvtarfunc } from "../../../../../redux/classes/common/updateAvtar";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetWorkerOverviewDetailsById } from "../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";
import { UpdateWorkerProfileSetting } from "../../../../../actions/worker-portal/UpdateWorkerProfileSetting";
import * as userActions from "../../../../../actions/index";
import Select from "../../../../../common/element/Select";
import preventInvalidChars from "../../../../../utils/preventInvalidChars";
const YourProfile = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [statesOne, setStatesOne] = useState();
  const [countries, setCountries] = useState();
  const [statesTwo, setStatesTwo] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    occupation: "",
    email: "",
    photo: "",
    phone: "",
    state_id: "",
    country_id: "",
  });
  const [formErrors, setFormErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    // occupation: "",
    contactError: "",
    emailError: "",
    photoError: "",
    stateError: "",
    countryError: "",
  });

  const [attachemnt, setAttachment] = useState("");
  const [urlImage, setUrlImage] = useState();
  const [documentError, setdocumentError] = useState("");
  let getApiData;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData) {
    getApiData = {
      individual_id: storedData.userName.individual_id,
    };
  }

  const updateStateFromApi = (arrayData) => {
    setFormData({
      ...formData,
      first_name: arrayData?.first_name ? arrayData?.first_name : "",
      last_name: arrayData?.last_name ? arrayData?.last_name : "",
      occupation: arrayData?.occupation ? arrayData?.occupation : "",
      email: arrayData?.email,
      individual_id: arrayData?.individual_id,
      phone: arrayData?.phone ? arrayData?.phone : "",
      country_id: arrayData?.country_id ? arrayData?.country_id : "",
      state_id: arrayData?.state_id ? arrayData?.state_id : "",
      // photo: arrayData?.avatar
    });
    clearErroMessages();
    setUrlImage(arrayData?.avatar);
    setAttachment(arrayData?.avatar);
  };

  const clearErroMessages = () => {
    setFormErrors({
      firstNameError: "",
      lastNameError: "",
      // occupation: "",
      contactError: "",
      emailError: "",
      photoError: "",
      stateError: "",
      countryError: "",
    });
  };
  useEffect(() => {
    dispatch(GetWorkerOverviewDetailsById(getApiData, "workerPortal")).then(
      (response) => {
        if (response?.success) {
          const arrayData = response?.data;
          updateStateFromApi(arrayData);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      }
    );
  }, []);

  const onDropHandler = (e) => {
    e?.preventDefault();
    const files = e?.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension

    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setAttachment(file);
            setUrlImage(URL.createObjectURL(file));
            setdocumentError("");
          } else {
            // Handle size validation error
            setdocumentError("Image dimensions must be within 800x400 pixels.");
          }
        };
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed."
        );
      }
    }
  };

  const handleInputChange = (e, identifier) => {
    let value;
    if (identifier == "country_id") {
      value = e?.value;
    } else if (identifier == "state_id") {
      value = e?.value;
    } else {
      value = e?.target?.value;
    }
    setFormData({ ...formData, [identifier]: value });

    const errorMapping = {
      first_name: "firstNameError",
      last_name: "lastNameError",
      phone: "contactError",
      state_id: "stateError",
      country_id: "countryError",
    };

    // Clear the specific error if the identifier matches the field
    const errorKey = errorMapping[identifier];

    setFormErrors((prevError) => ({
      ...prevError,
      [errorKey]: "",
    }));
  };

  const handleSubmit = (e) => {
    // e?.preventDefault()
    const phoneValidation = Validations({
      value:
        formData?.phone?.length == "" || formData?.phone?.length == undefined
          ? "test"
          : formData?.phone?.length >= 7
          ? formData?.phone
          : "",
      Validations: { required: true },
    });
    if (!phoneValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        contactError: "Phone number must be at least 7 digits if provided",
      }));
    }
    const fnameValidation = Validations({
      value: formData?.first_name,
      Validations: { required: true },
    });
    const lnameValidation = Validations({
      value: formData?.last_name,
      Validations: { required: true },
    });
    const countryValidation = Validations({
      value: formData?.country_id,
      Validations: { required: true },
    });
    const stateValidation = Validations({
      value: formData?.state_id,
      Validations: { required: true },
    });
    if (!fnameValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        firstNameError: fnameValidation.error_msg,
      }));
    }
    if (!lnameValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        lastNameError: fnameValidation.error_msg,
      }));
    }
    if (!countryValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        countryError: countryValidation.error_msg,
      }));
    }
    if (!stateValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        stateError: stateValidation.error_msg,
      }));
    }

    if (
      fnameValidation?.valid === true &&
      lnameValidation?.valid === true &&
      phoneValidation?.valid === true &&
      countryValidation?.valid === true &&
      stateValidation?.valid === true
    ) {
      const payload = {
        individual_id: getApiData.individual_id,
        first_name: formData?.first_name ? formData?.first_name : "",
        last_name: formData?.last_name ? formData?.last_name : "",
        occupation: formData?.occupation ? formData?.occupation : "",
        avatar: attachemnt,
        phone: formData?.phone ? formData?.phone : "",
        country_id: formData?.country_id ? formData?.country_id : "",
        state_id: formData?.state_id ? formData?.state_id : "",
      };

      dispatch(UpdateWorkerProfileSetting(payload))
        .then((response) => {
          if (response?.success) {
            // props.onDialogClose();
            dispatch(
              GetWorkerOverviewDetailsById(getApiData, "workerPortal")
            ).then((response) => {
              if (response?.success) {
                const arrayData = response?.data;
                updateStateFromApi(arrayData);
                // Update the avatar key for the specific user
                const updatedData = {
                  ...storedData,
                  userName: {
                    ...storedData.userName,
                    avatar: arrayData?.avatar,
                    first_name: arrayData?.first_name,
                    last_name: arrayData?.last_name,
                  },
                };
                setdialogAddOpen(true);
                dispatch(updateAvtarfunc(true));
                localStorage.setItem(
                  "LoginUserData",
                  JSON.stringify(updatedData)
                );
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            });
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
    }
  };

  const onAddDialogClose = () => {
    setdialogAddOpen(false);
  };

  const GetAllStatesbyCountryId = useCallback(
    async (formData) => {
      if (formData?.country_id) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData?.country_id)
        ).then((response) => {
          if (response?.success === true) {
            const arrayData = response?.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setStatesOne(state_data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
      }

      // eslint-disable-next-line
    },
    [dispatch, formData?.country_id]
  );

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            country_id: arrayData[key].country_id,
          });
        }
        setCountries(country_data);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllStatesbyCountryId(formData);
  }, [formData]);

  useEffect(() => {
    GetAllCountries();
  }, [formData]);

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Personal info
          </h5>
          <p className="text-gray-600">
            Update your photo and personal details here.
          </p>
        </div>
        <div className="flex justify-end items-center">
          <BtnLight className="w-auto px-4">Cancel</BtnLight>
          <SmallButton
            className="w-auto px-4 ml-3"
            onClick={() => handleSubmit()}
          >
            Save
          </SmallButton>
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Full name*</label>
        <div className="flex items-center w-7/12 flex-wrap">
          <div className="flex gap-3 w-full">
            <div className="flex-1">
              <input
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter first name"
                value={formData?.first_name}
                onChange={(e) => handleInputChange(e, "first_name")}
              />
              {formErrors?.firstNameError && (
                <div className="text-red-500 text-sm mb-6 mt-0  ">
                  {formErrors?.firstNameError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <input
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter second name"
                value={formData?.last_name}
                onChange={(e) => handleInputChange(e, "last_name")}
              />
              {formErrors?.lastNameError && (
                <div className="text-red-500 text-sm mb-6 mt-0  ">
                  {formErrors?.lastNameError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Occupation</label>
        <div className="flex items-center w-7/12 flex-wrap">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Job title here"
            value={formData?.occupation}
            onChange={(e) => handleInputChange(e, "occupation")}
          />
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Email address
        </label>
        <div className="flex items-center w-7/12 flex-wrap relative">
          <span className="absolute left-3 top-2">
            <img src="/assets/icons-svg/mail-01.svg" alt="" />
          </span>
          <input
            className="input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline' h-9 w-full pl-10"
            placeholder="name@emailaddress.com"
            value={formData?.email}
          />
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Contact number
        </label>
        <div className="flex items-center w-7/12 flex-wrap relative">
          <span className="absolute left-3 top-2">
            <img src="/assets/icons-svg/phone.svg" alt="" />
          </span>
          <input
            type="number"
            className="input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline' h-9 w-full pl-10"
            placeholder="Contact number"
            value={formData?.phone}
            onChange={(e) => {
              const value = e?.target?.value;
              if (value?.length <= 15) {
                handleInputChange(e, "phone");
              }
            }}
            min="1000000" // Min value for 7 digits
            max="999999999999999" // Max value for 15 digits
            onKeyDown={preventInvalidChars}
          />
          {formErrors?.contactError && (
            <div className="text-red-500 text-sm mb-6 mt-0  ">
              {formErrors?.contactError}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Location*</label>
        <div className="flex items-center w-7/12 flex-wrap">
          <div className="flex gap-3 w-full">
            <div className="flex-1">
              <Select
                isSearchable={false}
                placeholder="Country"
                options={countries}
                className="select-option w-full h-10 flex-1"
                value={countries?.filter(function (option) {
                  return option.value === formData?.country_id;
                })}
                onChange={(e) => handleInputChange(e, "country_id")}
              />
              {formErrors?.countryError && (
                <div className="text-red-500 text-sm mb-6 mt-0  ">
                  {formErrors?.countryError}
                </div>
              )}
            </div>
            <div className="flex-1">
              <Select
                isSearchable={false}
                placeholder="State"
                options={statesOne}
                className="select-option w-full h-10 flex-1"
                value={statesOne?.filter(function (option) {
                  return option.value === formData?.state_id;
                })}
                onChange={(e) => handleInputChange(e, "state_id")}
              />
              {formErrors?.stateError && (
                <div className="text-red-500 text-sm mb-6 mt-0  ">
                  {formErrors?.stateError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start  border-gray-200">
        <label className="text-gray-700 font-semibold w-2/12">Your photo</label>
        <div className="columns-input">
          <div className="flex flex-row">
            <span className="h-16 w-16 bg-gray-100 rounded-full flex justify-center items-center mr-5 avtar_img_round">
              {urlImage ? (
                <img className="w-16 h-16 rounded-full" src={urlImage} alt="" />
              ) : (
                <img src={imagePlus} alt="" />
              )}
            </span>
            <div className="flex items-center justify-center  flex-1 upload-img cursor-pointer">
              <div className="flex items-center justify-center  flex-1 upload-img ">
                <label
                  htmlFor="dropzone-file"
                  className="cursor-pointer flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                >
                  <div
                    className="flex items-center justify-center  flex-1 upload-img ml-8"
                    onDragOver={(e) => e?.preventDefault()}
                    onDrop={onDropHandler}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                        <span className="font-semibold text-blue-700">
                          Click to upload
                        </span>
                        <span className="text-xs"> or drag and drop</span>
                      </p>
                      <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                      {documentError && (
                        <div className="text-red-500 text-sm mt-1">
                          {documentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) =>
                      inputChangeHandlerFile(e?.target?.files[0])
                    }
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Your profile has been saved successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default YourProfile;
