import React, { useCallback, useEffect, useState } from "react";
import Tabs from "../../../../../../../../common/element/Tabs";
import BtnPrimary from "../../../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../../../../common/element/Table";
import Badge from "../../../../../../../../common/element/Badge";
import Tooltip from "../../../../../../../../common/element/Tooltip";
import Tag from "../../../../../../../../common/element/Tag";
import Scrollbar from "../../../../../../../../common/element/ScrollBar";
import Dialog from "../../../../../../../../common/element/Dialog";
// import DocumentListShow from "../SingleWorker/WorkerTabs/Documents/DocumentListShow";
import ImageUploader from "../../../../../../../../common/element/ImageUploder";
import Documents from "../SingleWorker/WorkerTabs/Documents";
import Button from "../../../../../../../../common/element/Buttons";
import SiteInductionView from "./SiteInductionView";
// import ViewInduction from "./InductionView";
import ViewIndSpecificData from "./ViewIndSpecificData";
import {
  GetWorkerSiteLogsByClient,
  GetIndStatusOfWorkerByClient,
  GetWorkerTrainingInduction,
} from "../../../../../../../../actions/client-portal/AllSiteApi";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router";
import ThreeDotBouncing from "../../../../../../../../common/element/PageLoader";
const { TabNav, TabList, TabContent } = Tabs;
const InducteesInfo = ({
  DetailData,
  individual_id,
  documnetSection,
  organisation_id,
  workerId,
  trading_name,
}) => {
  const defaultImageUrl =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVYhkJiYFb8n7qEEchgHlIoZzgalsuUrfkXQ&s";
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(0);
  const [siteLogDataCP, setSiteLogDataCP] = useState([]);
  const [indName, setIndName] = useState("");
  const [inductionData, setinductionData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [tabValue, setTabValue] = useState("tab1");
  const [dataIndSpec, setDataIndSpec] = useState(null);
  const [imageUrl, setImageUrl] = useState(DetailData?.avatar);
  let client_org_id;
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (loginData) {
    client_org_id = loginData?.organisation_id;
  }

  const [logSitefilter, setLogSiteFilter] = useState({
    individual_id: individual_id?.individual_id
      ? individual_id?.individual_id
      : "",
    client_org_id: client_org_id ? client_org_id : "",
    sort: "site_name",
    order: "",
  });
  const [siteIndFilter, setSiteIndFilter] = useState({
    client_org_id: client_org_id ? client_org_id : "",
    worker_id: workerId ? workerId : "",
    sort: "company_ind_name",
    order: "desc",
    inductionType: "company",
  });
  const [siteIndFilterSite, setSiteIndFilterSite] = useState({
    client_org_id: client_org_id ? client_org_id : "",
    worker_id: workerId ? workerId : "",
    sort: "site_ind_name",
    order: "desc",
    inductionType: "site",
  });
  const [inDialogIsOpen, setInIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const openInDialog = async (item, type) => {
    let payload = {
      worker_id: workerId || "",
    };
    if (type === "company") {
      payload = {
        ...payload,
        company_induction_id: item?.company_induction_id,
        inductionType: "company",
      };
      setIndName(item?.company_ind_name);
      setInIsOpen(true);
    } else if (type === "site") {
      payload = {
        ...payload,
        site_induction_id: item?.site_induction_id || "",
        inductionType: "site",
      };
      setIndName(item?.site_ind_name);
      setInIsOpen(true);
    }
    setDataIndSpec(payload);
  };

  const onInDialogClose = (e) => {
    setInIsOpen(false);
  };

  useEffect(() => {
    if (DetailData?.avatar) {
      setImageUrl(DetailData.avatar);
    } else {
      setImageUrl(defaultImageUrl);
    }
    setKey((prevKey) => prevKey + 1); // Force a re-render by changing the key
  }, [DetailData]);

  const HandleLog = async (tab) => {
    setLoadData(false);
    setTabValue(tab);
    if (tab == "tab4") {
      try {
        const response = await dispatch(
          GetWorkerSiteLogsByClient(logSitefilter)
        );
        if (response.success) {
          setLoadData(true);
          const arrayData = response.data;
          setSiteLogDataCP(arrayData);
        } else if (response.status === 401) {
          // handle unauthorized access
          console.warn("Unauthorized access");
        } else {
          // handle other errors
          console.error(
            "Failed to fetch site logs:",
            response.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    if (tab == "tab3") {
      try {
        const response = await dispatch(
          GetIndStatusOfWorkerByClient(siteIndFilter)
        );
        if (response.success) {
          setLoadData(true);
          const arrayData = response.data;
          setinductionData(arrayData);
        } else if (response.status === 401) {
          // handle unauthorized access
          setLoadData(true);
          console.warn("Unauthorized access");
        } else {
          // handle other errors
          setLoadData(true);
          console.error(
            "Failed to fetch site logs:",
            response.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    if (tab == "tab5") {
      try {
        const response = await dispatch(
          GetIndStatusOfWorkerByClient(siteIndFilterSite)
        );
        if (response.success) {
          setLoadData(true);
          const arrayData = response.data;
          setinductionData(arrayData);
        } else if (response.status === 401) {
          // handle unauthorized access
          setLoadData(true);
          console.warn("Unauthorized access");
        } else {
          // handle other errors
          setLoadData(true);
          console.error(
            "Failed to fetch site logs:",
            response.message || "Unknown error"
          );
        }
      } catch (error) {
        setLoadData(true);
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSort = (column) => {
    setLogSiteFilter((prevalue) => {
      return {
        ...prevalue,
        ["order"]: logSitefilter.order === "asc" ? "desc" : "asc",
      };
    });
    setLogSiteFilter((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
  };
  //This code for client-portal provider-worker specific
  useEffect(() => {
    if (logSitefilter.sort && tabValue == "tab4") {
      dispatch(GetWorkerSiteLogsByClient(logSitefilter)).then((response) => {
        setLoadData(true);
        setSiteLogDataCP(response?.data);
      });
    }
  }, [dispatch, logSitefilter, logSitefilter.sort]);

  // site and company Induction data sort
  const handleSortInd = (column) => {
    setSiteIndFilter((prevalue) => {
      return {
        ...prevalue,
        ["order"]: siteIndFilter.order === "asc" ? "desc" : "asc",
      };
    });
    setSiteIndFilter((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
  };
  useEffect(() => {
    if (siteIndFilter.sort && tabValue == "tab3") {
      dispatch(GetIndStatusOfWorkerByClient(siteIndFilter)).then((response) => {
        setLoadData(true);
        setinductionData(response?.data);
      });
    }
  }, [dispatch, siteIndFilter, siteIndFilter.sort]);

  // Site Filter
  const handleSortIndSite = (column) => {
    setSiteIndFilterSite((prevalue) => {
      return {
        ...prevalue,
        ["order"]: siteIndFilterSite.order === "asc" ? "desc" : "asc",
      };
    });
    setSiteIndFilterSite((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
  };
  useEffect(() => {
    if (siteIndFilterSite.sort && tabValue == "tab5") {
      dispatch(GetIndStatusOfWorkerByClient(siteIndFilterSite)).then(
        (response) => {
          setLoadData(true);
          setinductionData(response?.data);
        }
      );
    }
  }, [dispatch, siteIndFilterSite, siteIndFilterSite.sort]);
  return (
    <React.Fragment>
      <Tabs value={tabValue} variant="pill">
        <div className="mx-2 bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
          <TabList>
            <TabNav
              className="w-1/4"
              value="tab1"
              onClick={() => HandleLog("tab1")}
            >
              Details
            </TabNav>

            <TabNav
              className="w-1/4"
              value="tab2"
              onClick={() => HandleLog("tab2")}
            >
              Documentation
            </TabNav>

            <TabNav
              className="w-1/4"
              value="tab3"
              onClick={() => HandleLog("tab3")}
            >
              Company Inductions
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab5"
              onClick={() => HandleLog("tab5")}
            >
              Site Inductions
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab4"
              onClick={() => HandleLog("tab4")}
            >
              Site access log
            </TabNav>
          </TabList>
        </div>
        <div className="mh-430">
          <TabContent value="tab1">
            <div className="flex w-full p-5 details-modal">
              <div className="col-1">
                <div className="flex w-full upload-img h-full">
                  <ImageUploader
                    key={key}
                    uploadImage={false}
                    defaultImage={imageUrl}
                    height={233}
                    width={193}
                  />
                </div>
              </div>
              <div className=" col-2">
                <form>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        First name*
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.first_name ? DetailData?.first_name : "-"}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Last name*
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.last_name ? DetailData?.last_name : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Email address*
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.email ? DetailData?.email : "-"}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Contact number
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.phone ? DetailData?.phone : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Job title
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.occupation ? DetailData?.occupation : "-"}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Company name
                      </label>
                      <span className="font-semibold text-gray-900">
                        {trading_name ? trading_name : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Country
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.name ? DetailData?.name : "-"}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        State
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.state_name ? DetailData?.state_name : "-"}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </TabContent>
          <TabContent value="tab2">
            <Documents
              DetailData={DetailData}
              workerId={workerId}
              individual_id={individual_id}
              trading_name={trading_name ? trading_name : ""}
            />
          </TabContent>
          <TabContent value="tab3">
            <div className="p-2 mb-6 mh-430">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <div className="h-64 overflow-auto">
                  <Scrollbar>
                    <Table>
                      <THead>
                        <Tr>
                          <Th>
                            <span
                              className="flex items-center gap-1"
                              onClick={() => handleSortInd("company_ind_name")}
                            >
                              Induction
                              <svg
                                className={`transform ${
                                  siteIndFilter?.sort === "company_ind_name" &&
                                  siteIndFilter?.order === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                          </Th>

                          <Th>Site name</Th>
                          <Th>Induction Completed</Th>
                          <Th className="w-44">
                            <span className="flex items-center">
                              Status
                              <Tooltip title="Top start" placement="top-start">
                                <span className="pl-1">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#bcclap1iaa)">
                                      <path
                                        d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                        stroke="#98A2B3"
                                        strokeWidth="1.333"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="bcclap1iaa">
                                        <path fill="#fff" d="M0 0h16v16H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </Tooltip>
                            </span>
                          </Th>
                        </Tr>
                      </THead>
                      <TBody>
                        {loadData &&
                          inductionData?.clientCompInd &&
                          inductionData?.clientCompInd?.map((item, index) => {
                            let status;
                            if (item?.workerCompInd?.is_comp_ind_completed) {
                              status = "Completed";
                            } else if (
                              item?.workerCompInd?.is_comp_ind_completed ==
                              false
                            ) {
                              status = "Pending";
                            } else {
                              status = "Awaiting action";
                            }
                            return (
                              <Tr
                                className="cursor-pointer"
                                onClick={() => openInDialog(item, "company")}
                                key={index}
                              >
                                <Td>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      openInDialog(item, "company")
                                    }
                                  >
                                    {item?.company_ind_name}
                                  </span>
                                </Td>

                                <Td>-</Td>
                                <Td>-</Td>
                                <Td>
                                  {status == "Awaiting action" && (
                                    <Tag className=" bg-gray-100 text-gray-700">
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                          stroke="#475467"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      {status}
                                    </Tag>
                                  )}
                                  {status == "Completed" && (
                                    <Tag className="bg-success-100 text-success-700">
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="m10 3.691-5.5 5.5-2.5-2.5"
                                          stroke="#039855"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Completed
                                    </Tag>
                                  )}
                                  {status == "Pending" && (
                                    <Tag className="bg-blue-100 text-blue-800">
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_1592_20677)">
                                          <path
                                            d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                            stroke="#5786CC"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1592_20677">
                                            <rect
                                              width="12"
                                              height="12"
                                              fill="white"
                                              transform="translate(0 0.691406)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      Pending
                                    </Tag>
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        {!loadData && (
                          <Td colspan={"4"}>
                            <div>
                              <ThreeDotBouncing />
                            </div>
                          </Td>
                        )}
                        {loadData &&
                          inductionData?.clientCompInd?.length == 0 && (
                            <Td colspan={"4"} className="text-center">
                              <div>No data available!</div>
                            </Td>
                          )}
                      </TBody>
                    </Table>
                  </Scrollbar>
                </div>
              </div>
            </div>
          </TabContent>
          <TabContent value="tab5">
            <div className="p-2 mb-6 mh-430">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <div className="h-64 overflow-auto">
                  <Scrollbar>
                    <Table>
                      <THead>
                        <Tr>
                          <Th>
                            <span
                              className="flex items-center gap-1"
                              onClick={() => handleSortIndSite("site_ind_name")}
                            >
                              Induction
                              <svg
                                className={`transform ${
                                  siteIndFilterSite?.sort === "site_ind_name" &&
                                  siteIndFilterSite?.order === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                          </Th>

                          <Th>Site name</Th>
                          <Th>Induction Completed</Th>
                          <Th className="w-44">
                            <span className="flex items-center">
                              Status
                              <Tooltip title="Top start" placement="top-start">
                                <span className="pl-1">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#bcclap1iaa)">
                                      <path
                                        d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                        stroke="#98A2B3"
                                        strokeWidth="1.333"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="bcclap1iaa">
                                        <path fill="#fff" d="M0 0h16v16H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </Tooltip>
                            </span>
                          </Th>
                        </Tr>
                      </THead>
                      <TBody>
                        {loadData &&
                          inductionData?.clientOrgSites?.map((siteInd, index) =>
                            siteInd?.siteInd?.map((item, innerIndex) => {
                              let status;
                              if (item?.siteIndWorker?.is_induction_completed) {
                                status = "Completed";
                              } else if (
                                item?.siteIndWorker?.is_induction_completed ==
                                false
                              ) {
                                status = "Pending";
                              } else {
                                status = "Awaiting action";
                              }
                              return (
                                <Tr
                                  className="cursor-pointer"
                                  onClick={() => openInDialog(item, "site")}
                                  key={index + innerIndex}
                                >
                                  {/* <Td><span className="cursor-pointer" onClick={openInDialog(item, "site")}> {item?.site_ind_name}</span> </Td> */}
                                  <Td>
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => openInDialog(item, "site")}
                                    >
                                      {item?.site_ind_name}
                                    </span>
                                  </Td>
                                  <Td>{siteInd?.site_name}</Td>
                                  <Td>
                                    {item?.siteIndWorker
                                      ? item?.siteIndWorker?.updated_date ==
                                        "null"
                                        ? moment(
                                            item?.siteIndWorker?.updated_date
                                          ).format("YYYY-MM-DD")
                                        : "-"
                                      : "-"}
                                  </Td>
                                  <Td>
                                    {status == "Awaiting action" && (
                                      <Tag className=" bg-gray-100 text-gray-700">
                                        <svg
                                          width="12"
                                          height="13"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                            stroke="#475467"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        {status}
                                      </Tag>
                                    )}
                                    {status == "Completed" && (
                                      <Tag className="bg-success-100 text-success-700">
                                        <svg
                                          width="12"
                                          height="13"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="m10 3.691-5.5 5.5-2.5-2.5"
                                            stroke="#039855"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        Completed
                                      </Tag>
                                    )}
                                    {status == "Pending" && (
                                      <Tag className="bg-blue-100 text-blue-800">
                                        <svg
                                          width="12"
                                          height="13"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g clip-path="url(#clip0_1592_20677)">
                                            <path
                                              d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                              stroke="#5786CC"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_1592_20677">
                                              <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                                transform="translate(0 0.691406)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                        Pending
                                      </Tag>
                                    )}
                                  </Td>
                                </Tr>
                              );
                            })
                          )}
                        {!loadData && (
                          <Td colspan={"4"}>
                            <div>
                              <ThreeDotBouncing />
                            </div>
                          </Td>
                        )}
                        {loadData &&
                          inductionData?.clientOrgSites?.length == 0 && (
                            <Td colspan={"4"} className="text-center">
                              <div>No data available!</div>
                            </Td>
                          )}
                      </TBody>
                    </Table>
                  </Scrollbar>
                </div>
              </div>
            </div>
          </TabContent>
          <TabContent value="tab4">
            <div className="p-2 mb-6 h-430 pr-1">
              <div className="h-64 overflow-auto">
                <Scrollbar>
                  <div className="pr-2">
                    <div className="overflow-hidden border rounded-lg border-gray-200">
                      <Table>
                        <THead>
                          <Tr>
                            <Th>
                              <Button
                                className="flex items-center gap-1"
                                onClick={() => handleSort("site_name")}
                              >
                                Site
                                <svg
                                  className={`transform ${
                                    logSitefilter.sort !== "" &&
                                    logSitefilter.sort === "site_name" &&
                                    logSitefilter.order === "asc"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                    stroke="#475467"
                                    strokeWidth="1.333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Button>
                            </Th>
                            <Th>Sign-In</Th>
                            <Th>Sign-Out</Th>
                          </Tr>
                        </THead>
                        <TBody>
                          {/* This is for client-portal provider site-log */}
                          {siteLogDataCP?.map((item, index) => {
                            return (
                              <Tr key={index}>
                                <Td>{item?.WorkerSite?.site_name}</Td>
                                <Td>
                                  <span className="text-gray-900 font-medium block">
                                    {moment(item.sign_in_date).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </span>
                                  <span className="text-gray-600">
                                    {moment(item.sign_in_date).format(
                                      "HH:mm:ss"
                                    )}
                                  </span>
                                </Td>
                                <Td>
                                  {item.clock_in_out_status ? (
                                    <span className="text-success-500">
                                      On Site
                                    </span>
                                  ) : (
                                    <>
                                      <span className="text-gray-900 block w-full font-medium">
                                        {item?.sign_out_date !== null
                                          ? moment(item.sign_out_date).format(
                                              "DD/MM/YYYY"
                                            )
                                          : "-"}
                                      </span>
                                      <span className="text-gray-600">
                                        {item?.sign_in_date !== null
                                          ? moment(item.sign_out_date).format(
                                              "HH:mm"
                                            )
                                          : "-"}
                                      </span>
                                    </>
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                          {!loadData && (
                            <Td colspan={"4"}>
                              <div>
                                <ThreeDotBouncing />
                              </div>
                            </Td>
                          )}
                          {loadData && siteLogDataCP?.length == 0 && (
                            <Td colspan={"4"} className="text-center">
                              <div>No data available!</div>
                            </Td>
                          )}
                        </TBody>
                      </Table>
                    </div>
                  </div>
                </Scrollbar>
              </div>
            </div>
          </TabContent>
        </div>
      </Tabs>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/5">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList className="bg-gray-500 rounded-lg p-1">
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="p-4">
                <TabContent value="tab1">
                  <div className="mb-4">
                    <div className="font-bold text-white text-xl  flex justify-between mb-4">
                      <span>Status</span>
                    </div>
                    <Tag className="text-success-700 bg-success-50">
                      Approved
                    </Tag>
                    <label className="text-gray-100 block mt-4 mb-1">
                      Message
                    </label>
                    <p className="font-semibold text-white">
                      Duis massa leo elementum quam facilisis enim quis. Aliquam
                      adipiscing enim non laoreet tempus purus habitasse. In
                      commodo dignissim sodales eget habitasse urna accumsan
                      suspendisse.
                    </p>
                    <hr className="my-4 border-t border-gray-500" />
                    <div className="font-bold text-white text-xl mb-1 flex justify-between">
                      <span>Status</span>
                      <Button className="ml-auto">
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                    <label className="text-gray-100">
                      [DD Month YYYY] at [00:00]
                    </label>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Policy number
                    </label>
                    <p className="text-white font-semibold">[00000000000000]</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Amount insured
                    </label>
                    <p className="text-white font-semibold">$00000000</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      No. of workers
                    </label>
                    <p className="text-white font-semibold">0000000</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Expiry date
                    </label>
                    <p className="text-white font-semibold">DD/MM/YYYY</p>
                  </div>
                </TabContent>

                <TabContent value="tab2">
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      27 April 2023 at 13:34
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2">
                      Hi. The document was rejected because the insurance amount
                      does not meet Client’s requirements. Please re-upload.
                    </div>
                  </div>
                  <div className="mb-4  pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                      <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                            stroke="#5786CC"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <div className="ml-3">
                        <span className="font-medium text-gray-700 text-xs">
                          document name.[format]
                        </span>
                        <p className="text-gray-600 text-xs">200 KB</p>
                      </div>
                      <Button className="ml-auto">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                            stroke="#475467"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </TabContent>
              </div>
            </Tabs>
          </div>
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-header flex items-center justify-center">
              <div className="flex justify-content-center">
                <Button className="bg-white h-10 w-11 text-xl bl-rds">-</Button>
                <span className="font-semibold text-12 text-gray-700 bg-white w-16 flex items-center justify-center text-center h-10 border-r border-l">
                  100%
                </span>
                <Button className="bg-white h-10 w-11 text-xl br-rds">+</Button>
              </div>
              <div className="flex gap-6 rightSidebtn">
                <Button className="flex text-white gap-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#zvmr0yey3a)">
                      <path
                        d="m10.835 18.333-2.5-2.5m0 0 2.5-2.5m-2.5 2.5H12.5a5.833 5.833 0 0 0 2.5-11.105m-10 10.544a5.834 5.834 0 0 1 2.5-11.105h4.167m0 0-2.5-2.5m2.5 2.5-2.5 2.5"
                        stroke="#fff"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="zvmr0yey3a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.588 15.303 9.41 16.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L9.41 4.697m-2.328 8.22 5.834-5.834"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="doc-view"></div>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={inDialogIsOpen}
        onClose={onInDialogClose}
        onRequestClose={onInDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="text-gray-900 text-base font-semibold">
              {indName ? indName : ""}
            </h5>
          </div>
        </div>
        {dataIndSpec?.inductionType == "company" && (
          <ViewIndSpecificData dataIndSpec={dataIndSpec} />
        )}
        {dataIndSpec?.inductionType == "site" && (
          <SiteInductionView dataIndSpec={dataIndSpec} />
        )}
      </Dialog>
    </React.Fragment>
  );
};
export default InducteesInfo;
