import React, { useState, useCallback, useEffect } from "react";
import Heading from "../../../../../common/element/Heading";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select/Select";
import Dialog from "../../../../../common/element/Dialog";
import ClientInput from "../../../../../common/element/Input/clientInput";
import InductionDialog from "./Dialog/InductionDialog";
import ReactPaginate from "react-paginate";
import InductionList from "./InductionsList";
import { notify } from "react-notify-toast";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CreateCompanyInductionForClient } from "../../../../../actions/client-portal/CreateCompanyInductionForClient";
import { useDispatch, useSelector } from "react-redux";
import Badge from "../../../../../common/element/Badge";
import {
  AddInductionModuleData,
  DeleteInductionModuleDataByIndex,
  ResetInductionModuleData,
} from "../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { RestInductuionDetailForm } from "../../../../../redux/classes/client-portal/inductionDetailsForm";
import { Alert } from "@material-tailwind/react";
import { GetAllInductionsData } from "../../../../../actions/client-portal/GetAllInductionOfSpecificCompanyApi";
import { debounce } from "../../../../../common/snippet/Debouncing";
import { ResetEditInduction } from "../../../../../redux/classes/client-portal/EeditInduction";
import { EditInduction } from "../../../../../redux/classes/client-portal/EeditInduction";
import { UpdateCompanyInductionStatus } from "../../../../../actions/client-portal/UpdateCompanyInductionStatusApi";
// import { GetClientUserPermByIdForClient } from "../../../../../actions/client-portal/AllUsersApi";
import EmailIcon from "../../../../../assets/icons-svg/email.svg";
import { InductuionDetailForm } from "../../../../../redux/classes/client-portal/inductionDetailsForm";
import Email from "../Email/Email";
import { TailSpin } from "react-loader-spinner";
import { UpdateInductionStatus } from "../../../../../redux/classes/client-portal/updateInductionSlice";
import { GetCompanyInductionAndModulesbyId } from "../../../../../actions/client-portal/GetCompanyInductionAndModulesbyIdApi";
import { refetchAllInductionsData } from "../../../../../redux/classes/client-portal/refetchAllInductions";
import { DeleteCompanyInductionModuleById } from "../../../../../actions/client-portal/DeleteCompanyInductionModuleById";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
const Inductions = (props) => {
  
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const GettAllFunctions = useSelector((state) => state);
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const inductionDetailData = GettAllFunctions?.inductionDetailData;
  const dispatch = useDispatch();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [BtnDisable, setBtnDisable] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [validateFrom, setvalidateFrom] = useState(false);
  const [apiCalls, setApiCalls] = useState(true);
  const [veiwInduction, setveiwInduction] = useState(false);
  const [deleteModuleData, setdeleteModuleData] = useState({});
  const [finalSaveData, setfinalSaveData] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [deleteDiolog, setdeleteDiolog] = useState(false);
  const [activeBtn, setActiveBtn] = useState("");
  const [exitCheck, setExitCheck] = useState(false);
  const [upDateState, setupDateState] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [forceRender, setForceRender] = useState(false);
  const permissionDataUpdate = useSelector(
    (state) => state?.permissions?.permissionData
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Inductions",
          url: "/client/inductions",
          home: "/client/dashboard",
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem(
        "permission_data",
        JSON.stringify(permissionDataUpdate)
      );
      setForceRender((prevForceRender) => !prevForceRender);
      if (permissionDataUpdate?.induction_view == false) {
        // navigate('/client/dashboard');
        navigate("/client/noaccess");
      }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }

  const inductionPermission =
    permission_data?.induction_write == true ? false : true;

  let function_assignment_id;
  let user_id;
  let organisation_id;
  let trading_name;
  let login_user;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
    organisation_id = storedData.organisation_id;
    trading_name = storedData?.trading_name;
    login_user = `${storedData?.userName?.first_name || ""} ${
      storedData?.userName?.last_name || ""
    }`;
  }
  const [companyInductionId, setcompanyInductionId] = useState(null);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [AllData, setAllData] = useState([]);
  const [updateData, SetupdateData] = useState(false);
  const [formSatus, SetformSatus] = useState("inductionDetails");
  const [dialogUnsaveOpen, setUnsaveOpen] = useState(false);
  const [company_induction_id, setcompany_induction_id] = useState("");

  const [dialogEmailOpen, setEmailOpen] = useState(false);
  // to open email dialog
  const openEmailDialog = () => {
    setEmailOpen(true);
  };
  // to close email dialog
  const onEmailClose = (e) => {
    if (!buttonDisabled) {
      setEmailOpen(false);
    }
  };

  // api call for permissions
  // useEffect(() => {
  //   const apiData = {
  //     user_id: user_id,
  //     function_assignment_id: function_assignment_id,
  //   };
  //   dispatch(GetClientUserPermByIdForClient(apiData)).then((response) => {
  //     if (response?.success === true) {
  //       // const arrayData = response?.data;
  //       localStorage.setItem("permission_data", JSON.stringify(response?.data));
  //     } else if (response?.status === 401) {
  //       handleUnauthorizedAccess();
  //     }
  //   });
  // }, []);

  const openDialog = () => {
    dispatch(ResetEditInduction());
    dispatch(ResetInductionModuleData());
    setIsOpen(true);
    setBtnDisable(false);
    dispatch(RestInductuionDetailForm());
    setveiwInduction(false);
  };
  useEffect(() => {
    dispatch(EditInduction(false));
  }, []);

  useEffect(() => {
    if (
      GettAllFunctions?.editInduction !== null &&
      GettAllFunctions?.editInduction
    ) {
      SetformSatus("module");
      dispatch(EditInduction(true));
      setIsOpen(true);
      setBtnDisable(false);
      // dispatch(ResetEditInduction())
    }
  }, [GettAllFunctions, GettAllFunctions?.editInduction]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Dispatch your action when the page is about to be unloaded (hard refresh)
      dispatch(EditInduction(false));
      dispatch(ResetEditInduction());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);
  // FilTER DATA SHOW
  const [getFilterData, SetFilterData] = useState({
    function_assignment_id: function_assignment_id,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });
  // All ReduxFunction
  const fetchInductionData = useCallback(() => {
    setLoadData(false);
    dispatch(GetAllInductionsData(getFilterData))
      .then((response) => {
        if (response?.success) {
          setLoadData(true);
          setAllData(response?.data);
          setTotalPages(
            Math.ceil(response?.data?.count / getFilterData?.limit)
          );
          dispatch(refetchAllInductionsData(false));
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
          setLoadData(true);
        }
      })
      .catch((error) => {
        setLoadData(true);
        console.log("error", error);
      });
  }, [dispatch, getFilterData]);

  useEffect(() => {
    fetchInductionData();
  }, [
    getFilterData,
    updateData,
    dialogIsOpen,
    GettAllFunctions?.refetchAllInductions,
  ]);

  // useEffect(() => {
  //   dispatch(GetAllInductionsData(getFilterData)).then((response) => {
  //     if (response?.success) {
  //       setAllData(response?.data);
  //       dispatch(refetchAllInductionsData(false));
  //     } else if (response?.status === 401) {
  //       handleUnauthorizedAccess();
  //     }
  //   });
  // }, [
  //   getFilterData,
  //   updateData,
  //   dialogIsOpen,
  //   GettAllFunctions?.refetchAllInductions,
  // ]);

  const handleSort = (column) => {
    if (column === getFilterData?.sort) {
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getFilterData?.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getFilterData?.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };

  const handlePageChange = ({ selected }) => {
    SetFilterData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
  };

  // Search Data
  const handleSearch = (e) => {
    SetFilterData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
  };

  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  const onDialogClose = (e) => {
    if (formSatus === "module") {
      setUnsaveOpen(true);
      setvalidateFrom(false);
      setIsOpen(true);
      if (!buttonDisabled) {
        setBtnDisable(false);
      }
    } else {
      dispatch(ResetInductionModuleData());
      setvalidateFrom(false);
      SetformSatus("inductionDetails");
      // dispatch(RestInductuionDetailForm());
      setIsOpen(false);
    }

    //  props?.getOnDialogClose(onDialogClose);
  };

  const openAddDialog = () => {
    if (isActive) {
      setExitCheck(true);
    }
    dispatch(UpdateInductionStatus(true));
    setveiwInduction(false);
    if (formSatus == "inductionDetails") {
      if (inductionDetailData?.company_ind_name !== "") {
        if (!isActive) {
          // first click
          setButtonDisabled(true);

          const newObject = {
            ...inductionDetailData,
            organisation_id: organisation_id ? organisation_id : "",
            trading_name: trading_name,
            login_user: login_user,
            abn_nzbn: storedData?.abn_nzbn,
          };
          dispatch(CreateCompanyInductionForClient(newObject,ipAddress))
            .then((response) => {
              if (response?.success) {
                setButtonDisabled(false);
                ///disabled code here for false
                setcompany_induction_id(response?.data?.company_induction_id);
                const updatedInductionData = {
                  ...inductionDetailData,
                  company_induction_id: response?.data?.company_induction_id,
                };
                dispatch(InductuionDetailForm(updatedInductionData));
                setcompanyInductionId(response?.data?.company_induction_id);
                SetformSatus("module");
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {})
            .finally(() => {
              setButtonDisabled(false);
            });
        }

        // setAddOpen(true)
        // setIsOpen(false)
      } else {
        setvalidateFrom(true);
      }
    } else {
      setBtnDisable(true);
      setfinalSaveData(true);
      setupDateState(!upDateState);
    }
  };

  const closePannelDisable = () => {
    setBtnDisable(false);
  };

  const closePannel = (disableBTN) => {
    if (disableBTN == "disableBTN") {
      setBtnDisable(false);
    } else {
      setAddOpen(true);
    }
  };

  const onAddDialogClose = (e) => {
    setBtnDisable(false);
    SetformSatus("inductionDetails");
    dispatch(ResetInductionModuleData());
    setIsOpen(false);
    setAddOpen(false);
    setfinalSaveData(false);
    SetupdateData(!updateData);
    dispatch(ResetEditInduction());
    // props?.getOnDialogClose(onAddDialogClose);
  };

  const onSaveDialog = (e) => {
    setExitCheck(true);
    setfinalSaveData(true);
    setUnsaveOpen(false);
    if (dialogAddOpen) {
      SetformSatus("inductionDetails");
      setTimeout(() => {
        SetupdateData(!updateData);
        dispatch(ResetInductionModuleData());
        setIsOpen(false);
        setAddOpen(false);
        setfinalSaveData(false);

        dispatch(ResetEditInduction());
      }, 500);
    }
  };

  const openUnsaveDialog = () => {
    if (formSatus === "module") {
      setUnsaveOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const onUnsaveDialogClose = (e) => {
    setUnsaveOpen(false);
    setExitCheck(false);
  };

  const closeFullForm = () => {
    setExitCheck(false);
    setUnsaveOpen(false);
    SetformSatus("inductionDetails");
    setTimeout(() => {
      SetupdateData(!updateData);
      dispatch(ResetInductionModuleData());
      setIsOpen(false);
      setAddOpen(false);
      setfinalSaveData(false);
      setUnsaveOpen(false);
      dispatch(ResetEditInduction());
    }, 500);
  };

  const getSpecificCompanyModule = (company_induction_id) => {
    // SetcheckEditdata(true);
    dispatch(ResetEditInduction());
    dispatch(ResetInductionModuleData());

    dispatch(GetCompanyInductionAndModulesbyId(company_induction_id)).then(
      (response) => {
        if (response?.success) {
          dispatch(
            InductuionDetailForm({
              function_assignment_id: response?.data?.function_assignment_id,
              company_ind_name: response?.data?.company_ind_name,
              company_ind_desc: response?.data?.company_ind_desc,
              company_induction_id: response?.data?.company_induction_id,
              company_ind_status: response?.data?.company_ind_status,
              company_ind_valid_days: response?.data?.company_ind_valid_days,
              company_ind_publish_date:
                response?.data?.company_ind_publish_date,
            })
          );

          response?.data?.company_ind_modules?.map((item) => {
            dispatch(AddInductionModuleData(item));
          });
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      }
    );
    setIsOpen(true);
  };

  const onAddDialogView = () => {
    getSpecificCompanyModule(
      company_induction_id == ""
        ? GettAllFunctions.inductionDetailData?.company_induction_id
        : company_induction_id
    );
    setveiwInduction(true);
    SetformSatus("inductionDetails");
    setIsOpen(false);
    setAddOpen(false);
    setfinalSaveData(false);
    // SetupdateData(!updateData);
  };

  useEffect(() => {
    if (isActive) {
      ActivateInduction(activeBtn);
    }
  }, [dialogAddOpen]);
  const ActivateInduction = (btn) => {
    setBtnDisable(true);
    dispatch(UpdateInductionStatus(true));
    setActiveBtn(btn);
    setisActive(true);
    let status;

    if (inductionDetailData?.company_ind_status == "active") {
      status = "draft";
    } else {
      status = "active";
    }
    if (btn == "archiveBtn") {
      status = "archived";
    }
    if (!dialogAddOpen) {
      openAddDialog();
    } else {
      dispatch(
        UpdateCompanyInductionStatus(
          {
            company_ind_status: status,
            company_induction_id: inductionDetailData?.company_induction_id
              ? inductionDetailData?.company_induction_id
              : companyInductionId,
            organisation_id: organisation_id ? organisation_id : "",
            trading_name: trading_name,
            // login_user: login_user,
            induction_name: inductionDetailData?.company_ind_name,
          },
          ipAddress
        )
      )
        .then((response) => {
          if (response?.success) {
            SetformSatus("inductionDetails");
            setfinalSaveData(false);
            setisActive(false);
            // setIsOpen(false);
            // setAddOpen(false);
            // setExitCheck(false);
            SetupdateData(!updateData);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          notify.show(error, "error");
        });
    }
  };

  // Delete Module Dialog box
  const onDeleteDialogClose = () => {
    setdeleteDiolog(false);
  };
  const openDeletePopup = (ModuleData, indexData) => {
    setdeleteDiolog(true);
    setdeleteModuleData({ indexData: indexData, ModuleData: ModuleData });
  };

  const deleteModuleConfirmation = () => {
    let indexData = deleteModuleData?.indexData;
    if (deleteModuleData?.ModuleData.module_id) {
      dispatch(
        DeleteCompanyInductionModuleById(deleteModuleData?.ModuleData)
      ).then((response) => {
        if (response?.success) {
          dispatch(DeleteInductionModuleDataByIndex({ indexData }));
          setdeleteDiolog(false);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    } else {
      dispatch(DeleteInductionModuleDataByIndex({ indexData }));
      setdeleteDiolog(false);
    }
  };

  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetFilterData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1,
      }));
      setTotalPages(
        Math.ceil(
          AllData?.count / newLimit?.limit
            ? newLimit?.limit
            : newLimit
            ? newLimit
            : 10
        )
      );
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetFilterData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div>
          <Heading>Inductions</Heading>
          <p className="text-gray-600">
            Craft induction modules to ensure contractors align your company's
            guidelines
          </p>
        </div>
        <BtnLight
          disabled={inductionPermission}
          onClick={() => openEmailDialog()}
          className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
        >
          Email <img src={EmailIcon} alt="" className="ml-2" />
        </BtnLight>
      </div>

      <div className="flex justify-between mb-5 mt-7 px-3">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img
                src="/assets/icons-svg/search-lg.svg"
                alt=""
                className="h-5"
              />
            </div>
            <ClientInput
              type="text"
              className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              inputchangehandler={inputchangehandler}
              name={"search"}
            />
          </div>
        </div>

        <div className="flex gap-2">
          {/* <BtnLight className="w-auto h-9 flex items-center justify-center text-xs px-3.5 gap-1">
            Columns
            <svg
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 2.5v15m5-15v15m-6-15h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.092 1.093C17.5 4.4 17.5 5.1 17.5 6.5v7c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092c-.535.273-1.235.273-2.635.273h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 15.6 2.5 14.9 2.5 13.5v-7c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C4.4 2.5 5.1 2.5 6.5 2.5z"
                stroke="#344054"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnLight> */}

          <BtnPrimary
            disabled={inductionPermission}
            onClick={() => openDialog()}
            className="text-xs h-9 flex items-center justify-center px-3 gap-1"
          >
            Add Induction
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.712 4.167v11.666M4.878 10h11.667"
                stroke="#fff"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnPrimary>
        </div>
      </div>
      <InductionList
        loadData={loadData}
        fetchInductionData={fetchInductionData}
        veiwInduction={veiwInduction}
        data={AllData}
        inductionPermission={inductionPermission}
        handleSort={handleSort}
        getFilterData={getFilterData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {
        <div
          className="flex justify-between mt-3"
          style={{
            alignItems: "Right",
            listStyle: "none",
            padding: 0,
          }}
        >
          <Pagination
            totalCount={AllData?.count}
            pageNumber={getFilterData?.page}
            displayPage={AllData?.rows?.length ? AllData?.rows?.length : 0}
            UpdatePageLimit={updatePageLimit}
            UpdatePage={updatePage}
            // allfilters={getFilterData}
          />
          {AllData?.rows?.length > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={getFilterData?.page - 1}
            />
          )}
        </div>
      }
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {formSatus === "inductionDetails"
                ? "Add an Induction Module"
                : inductionDetailData?.company_ind_name}{" "}
            </h5>
            {formSatus === "inductionDetails" ? (
              <p className="text-gray-600">
                Craft a unique induction module to onboard your team
              </p>
            ) : (
              <Badge
                className="py-0.5 px-2 rounded-lg"
                content={
                  inductionDetailData?.company_ind_status !== undefined
                    ? inductionDetailData?.company_ind_status
                    : "Draft"
                }
                innerClass="bg-blue-50 text-blue-800 "
              />
            )}
          </div>
        </div>
        <InductionDialog
          inductionPermission={inductionPermission}
          inductionDetailData={inductionDetailData}
          companyInductionId={companyInductionId}
          validateFrom={validateFrom}
          formSatus={formSatus}
          saveData={finalSaveData}
          closePannel={closePannel}
          closePannelDisable={closePannelDisable}
          upDateState={upDateState}
          exitCheck={exitCheck}
          openDeletePopup={openDeletePopup}
        />
        <div className="flex dailog-footer mt-0 justify-end">
          <BtnLight
            className="mr-1.5 w-auto px-4"
            variant="plain"
            disabled={BtnDisable}
            onClick={openUnsaveDialog}
          >
            Cancel
          </BtnLight>
          {formSatus !== "inductionDetails" &&
            inductionDetailData?.company_ind_status === "active" && (
              <BtnPrimary
                disabled={BtnDisable}
                onClick={() => ActivateInduction("archiveBtn")}
                className="ml-1.5 w-auto px-4 flex gap-2 items-center"
              >
                Archive induction
              </BtnPrimary>
            )}
          {formSatus !== "inductionDetails" && (
            <BtnPrimary
              onClick={() => ActivateInduction()}
              disabled={
                BtnDisable
                  ? BtnDisable
                  : GettAllFunctions?.InductionModuleData?.length
                  ? !GettAllFunctions?.InductionModuleData?.every((module) =>
                      module?.module_ques_data?.every(
                        (item) =>
                          item?.ques_ans_data?.every(
                            (ans) => ans.answer !== ""
                          ) &&
                          item?.ques_ans_data?.some(
                            (ans) => ans.is_correct_answer
                          )
                      )
                    )
                  : true
              }
              className="ml-1.5 w-auto px-4 flex gap-2 items-center"
            >
              {inductionDetailData?.company_ind_status === "active"
                ? "Set induction to Draft"
                : "Activate induction"}
            </BtnPrimary>
          )}
          <BtnPrimary
            type="submit"
            disabled={BtnDisable}
            onClick={() => openAddDialog()}
            className="ml-1.5 w-auto px-4 flex gap-2 items-center"
          >
            {formSatus === "inductionDetails" ? "Next" : "Save"}
            {formSatus === "inductionDetails" ? (
              <img src="/assets/icons-svg/arrow-right.svg" alt="" />
            ) : (
              ""
            )}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Induction added successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 flex-col gap-3">
          <BtnPrimary
            onClick={onAddDialogView}
            className="w-auto px-4 flex gap-2 items-center justify-center"
          >
            View Induction
          </BtnPrimary>
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogUnsaveOpen}
        onClose={onUnsaveDialogClose}
        onRequestClose={onUnsaveDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-0.5">
            You have unsaved updates
          </p>
          <p className=" text-gray-600 text-center">Save before leaving?</p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-3">
          <BtnLight className="flex-1" onClick={() => closeFullForm()}>
            Exit
          </BtnLight>
          <BtnPrimary
            onClick={onSaveDialog}
            className="flex-1 flex items-center justify-center"
          >
            Save
          </BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogEmailOpen}
        onClose={onEmailClose}
        onRequestClose={onEmailClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <Email
          onEmailClose={onEmailClose}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
        />
      </Dialog>

      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete module?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={() => setdeleteDiolog(false)}>
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            onClick={() => deleteModuleConfirmation()}
          >
            Delete
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Inductions;
