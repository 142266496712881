import React, { useEffect, useState } from "react";
import Tooltip from "../../../../../../../common/element/Tooltip";
import Table from "../../../../../../../common/element/Table";
import { useSelector } from "react-redux";
import Button from "../../../../../../../common/element/Buttons";
import Tag from "../../../../../../../common/element/Tag";
import rightIcon from "../../../../../../../assets/icons-svg/chevron-right.svg";
const CompanyInduction = (props) => {
  const { GetSpecificWorkerIndData } = useSelector((state) => state);
  const [inductionData, setInductionData] = useState([]);
  const [toggleState, setToggleState] = useState(
    Array(inductionData?.length).fill(false)
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const { Tr, Th, Td, THead, TBody } = Table;

  useEffect(() => {
    setInductionData(
      GetSpecificWorkerIndData?.data?.data?.rows
        ? GetSpecificWorkerIndData?.data?.data?.rows
        : []
    );
  }, [GetSpecificWorkerIndData]);

  const openInDialog = (item, company) => {
    props.handleView(item, company);
  };

  // Function to toggle the main rows
  const toggleClick = (index) => {
    const newToggleStates = [...toggleState];
    newToggleStates[index] = !newToggleStates[index];
    setToggleState(newToggleStates);
    // Close all child rows when closing a parent row
    // setExpandedChildRows({});

    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = [...prevExpandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      return newExpandedRows;
    });
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <div className="p-2 mb-6">
          <div className="overflow-hidden border rounded-lg border-gray-200">
            {/* <Table>
              <THead>
                <Tr>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      onClick={() => props.handleSort("company_ind_name")}
                    >
                      Induction
                      <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "company_ind_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Th>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      onClick={() => props.handleSort("client_name")}
                    >
                      Client name
                      <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "client_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Th>
                  <Th>Induction Completed</Th>

                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      onClick={() => props.handleSort("no_of_module")}
                    >
                      No. of Modules
                      <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "no_of_module" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Th>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      onClick={() => props.handleSort("date_completed")}
                    >
                      Date completed
                      <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "date_completed" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Th>

                  <Th className="w-44">
                    <span className="flex items-center">
                      Status
                      <Tooltip title="Top start" placement="top-start">
                        <span className="pl-1">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#bcclap1iaa)">
                              <path
                                d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                stroke="#98A2B3"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="bcclap1iaa">
                                <path fill="#fff" d="M0 0h16v16H0z" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </Tooltip>
                    </span>
                  </Th>
                </Tr>
              </THead>
              <TBody>
                {inductionData.length &&
                  inductionData?.map((data, index) =>
                    data?.clientAssign?.clientCompInd?.map((item, index) => {
                      let status;
                      if (item?.workerCompInd?.is_comp_ind_completed) {
                        status = "Completed";
                      } else if (
                        item?.workerCompInd?.is_comp_ind_completed == false
                      ) {
                        status = "Pending";
                      } else {
                        status = "Awaiting action";
                      }
                      return (
                        <Tr key={index}>
                          <Td>
                            {" "}
                            <Button
                              onClick={() => {
                                openInDialog(item, "company");
                              }}
                            >
                              {item?.company_ind_name}
                            </Button>
                          </Td>
                          <Td>
                          </Td>
                          <Td>-</Td>
                          <Td>-</Td>
                          <Td>-</Td>
                          <Td>
                            <Td>
                              {status == "Awaiting action" && (
                                <Tag className=" bg-gray-100 text-gray-700">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                      stroke="#475467"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  {status}
                                </Tag>
                              )}
                              {status == "Completed" && (
                                <Tag className="bg-success-100 text-success-700">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m10 3.691-5.5 5.5-2.5-2.5"
                                      stroke="#039855"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Completed
                                </Tag>
                              )}
                              {status == "Pending" && (
                                <Tag className="bg-blue-100 text-blue-800">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_1592_20677)">
                                      <path
                                        d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                        stroke="#5786CC"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1592_20677">
                                        <rect
                                          width="12"
                                          height="12"
                                          fill="white"
                                          transform="translate(0 0.691406)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Pending
                                </Tag>
                              )}
                            </Td>
                          </Td>
                        </Tr>
                      );
                    })
                  )}
              </TBody>
            </Table> */}
            <Table>
              <THead>
                <Tr>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      onClick={() => props.handleSort("trading_name")}
                    >
                      Client Name
                      <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "trading_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Th>

                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      // onClick={() => props.handleSort("company_ind_name")}
                    >
                      Induction(s)
                      {/* <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "company_ind_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg> */}
                    </Button>
                  </Th>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      // onClick={() => props.handleSort("company_ind_name")}
                    >
                      Module(s)
                      {/* <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "company_ind_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg> */}
                    </Button>
                  </Th>
                  <Th>
                    <Button
                      className="flex items-center gap-1"
                      // onClick={() => props.handleSort("company_ind_name")}
                    >
                      Date completed
                      {/* <svg
                        className={`transform ${
                          props.getAllinvitedData.sort !== "" &&
                          props.getAllinvitedData.sort == "company_ind_name" &&
                          props.getAllinvitedData.order === "asc"
                            ? "rotate-180"
                            : ""
                        }`}
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                          stroke="#475467"
                          strokeWidth="1.333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg> */}
                    </Button>
                  </Th>
                  <Th>Status</Th>
                </Tr>
              </THead>
              <TBody>
                {!props?.loader &&
                  inductionData?.map((items, index) => {
                    let clientCompInd =
                      items?.clientAssign?.clientCompInd || [];
                    let progressbar = 0;
                    let length = clientCompInd?.length;
                    let getCompletedInduction = 100 / parseInt(length, 10);
                    let completedLength =
                      clientCompInd?.length &&
                      clientCompInd.filter(
                        (item) =>
                          item?.workerCompInd?.is_comp_ind_completed == true
                      ).length;

                    let totalModuleCount = 0;

                    if (clientCompInd?.length) {
                      totalModuleCount = clientCompInd
                        .filter((item) => item?.companyIndModuleCount)
                        .reduce(
                          (total, item) =>
                            parseInt(total, 10) +
                            parseInt(item.companyIndModuleCount, 10),
                          0
                        );
                    }

                    progressbar = getCompletedInduction * completedLength;
                    return (
                      <React.Fragment>
                        <Tr key={index}>
                          <Td>
                            <div className="flex items-center">
                              {items?.clientAssign?.clientCompInd?.length >
                                1 && (
                                <Button
                                  onClick={() => toggleClick(index)}
                                  className={`bg-gray-50 rounded-full h-6 w-6 p-0.5 mr-2 ${
                                    toggleState[index] ? "rotate-90" : ""
                                  }`}
                                >
                                  <img src={rightIcon} alt="" />
                                </Button>
                              )}
                              <Button
                                onClick={() => {
                                  items?.clientAssign?.clientCompInd?.length ==
                                    1 &&
                                    openInDialog(
                                      items?.clientAssign?.clientCompInd[0],
                                      "company"
                                    );
                                }}
                              >
                                {items?.trading_name}
                              </Button>
                            </div>
                          </Td>
                          <Td>
                            {items?.clientAssign?.clientCompInd?.length > 1
                              ? items?.clientAssign?.clientCompInd?.length +
                                " " +
                                "Inductions"
                              : items?.clientAssign?.clientCompInd?.[0]
                                  ?.company_ind_name}
                          </Td>
                          <Td>{totalModuleCount}</Td>
                          <Td>
                            {items?.worker_individual?.phone
                              ? items?.worker_individual?.phone
                              : ""}
                          </Td>
                          <Td className={"flex items-center gap-1	"}>
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                              <div
                                className="bg-blue-600 h-2.5 rounded-full"
                                style={{ width: `${progressbar?.toFixed(2)}%` }}
                              ></div>
                            </div>
                            {`${progressbar?.toFixed(2)}%`}
                          </Td>
                        </Tr>
                        {expandedRows[index] &&
                          items?.clientAssign?.clientCompInd.map(
                            (innerItem, index) => {
                              // Status
                              let status;
                              if (
                                innerItem?.workerCompInd?.is_comp_ind_completed
                              ) {
                                status = "Completed";
                              } else if (
                                innerItem?.workerCompInd
                                  ?.is_comp_ind_completed == false
                              ) {
                                status = "Pending";
                              } else {
                                status = "Awaiting action";
                              }
                              return (
                                <Tr key={index}>
                                  <Td>
                                    <div className="flex items-center">
                                      <span class="h-6 w-6 mr-2 gray-line-set relative"></span>
                                    </div>
                                  </Td>
                                  <Td>
                                    <Button
                                      onClick={() => {
                                        openInDialog(innerItem, "company");
                                      }}
                                    >
                                      {innerItem?.company_ind_name}
                                    </Button>
                                  </Td>
                                  <Td>{innerItem.companyIndModuleCount}</Td>
                                  <Td></Td>
                                  <Td>
                                    {status == "Awaiting action" && (
                                      <Tag className=" bg-gray-100 text-gray-700">
                                        <svg
                                          width="12"
                                          height="13"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                            stroke="#475467"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        {status}
                                      </Tag>
                                    )}
                                    {status == "Completed" && (
                                      <Tag className="bg-success-100 text-success-700">
                                        <svg
                                          width="12"
                                          height="13"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="m10 3.691-5.5 5.5-2.5-2.5"
                                            stroke="#039855"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        Completed
                                      </Tag>
                                    )}
                                    {status == "Pending" && (
                                      <Tag className="bg-blue-100 text-blue-800">
                                        <svg
                                          width="12"
                                          height="13"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g clip-path="url(#clip0_1592_20677)">
                                            <path
                                              d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                              stroke="#5786CC"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_1592_20677">
                                              <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                                transform="translate(0 0.691406)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                        Pending
                                      </Tag>
                                    )}
                                  </Td>
                                  <Td></Td>
                                </Tr>
                              );
                            }
                          )}
                      </React.Fragment>
                    );
                  })}
              </TBody>
            </Table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CompanyInduction;
