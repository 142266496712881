import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllAssignedWorkerToClient = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/GetAllAssignedWorkerToClient`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
