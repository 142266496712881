import axios from "axios";
import * as actionType from "../../constant/actionType";
import {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
} from "../../redux/classes/client-portal/clientCsvSlice";
export const InviteProvidersCsvView = (CSVData) => async (dispatch) => {
  /*** Add CSV Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in CSVData) {
      if (key === "provider_invite_csv") {
        formdata.append("provider_invite_csv", CSVData.provider_invite_csv);
      } else {
        formdata.append(key, CSVData[key]);
      }
    }
    dispatch(fetchData());
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/InviteProvidersCsvView`,
    });
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataError(error.message));
  }
};
