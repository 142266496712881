import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
// import { notify } from "react-notify-toast";
import Dialog from "../../../../../../common/element/Dialog";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Label from "../../../../../../common/element/Label";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import * as userActions from "../../../../../../actions/index";
import { useParams, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import emailIcon from "../../../../../../assets/icons-svg/email.svg";
import Validations from "../../../../../../common/404/Validations";
import Upload from "../../../../../../common/element/Upload";
import trashIcon from "../../../../../../assets/icons-svg/trash.svg";
import PdfFile from "../../../../../../assets/icons-svg/upload-pdf.svg";
import Select from "../../../../../../common/element/Select";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { useSelector } from "react-redux";

const EditTemplate = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  // const { Tr, Th, Td, THead, TBody } = Table
  const [dialogIsOpen, setIsOpen] = useState(true);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [content, setContent] = useState("");
  const [templateSec, setTemplateSec] = useState("");
  const [documentError, setdocumentError] = useState("");
  const [sectionOption, setSectionOption] = useState([
    { label: "Admin Client", value: "admin_client" },
    { label: "Admin Provider", value: "admin_provider" },
    { label: "Admin Worker", value: "admin_worker" },
  ]);
  const [attachment, setAttachment] = useState({
    files: [], // Initialize 'files' as an empty array
  });
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [nameError, setNameError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const onDialogClose = () => {
    if (!isSaving) {
      setIsOpen(false);
      dismissError();
      props?.setOpenPopup(!dialogIsOpen);
    }
  };
  const onDialogOk = () => {
    setIsOpen(false);
  };
  const dismissError = () => {
    setMessageError("");
    setSubjectError("");
    setNameError("");
  };
  const handleChange = (value) => {
    setContent(value);
    setMessageError("");
  };
  const GetEmailTemplateById = () => {
    props?.GetEmailTemplateById(props?.email_id).then((response) => {
      if (response?.success === true) {
        const res_data = response?.data;
        const fileData = [];

        res_data?.email_temp_attach.forEach((data) => {
          const file = data?.email_temp_doc;
          const attachment_id = data?.email_temp_attachment_id;
          const segments = file.split("/");

          // Get the last segment, which should be the file name
          const fileNameWithQueryParams = segments[segments.length - 1];

          // Decode the percent-encoded characters
          const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

          fileData.push({
            email_temp_attachment_id: attachment_id,
            file: file,
            fileName: decodedFileName,
          });
        });
        setTemplateName(res_data?.template_name);
        setTemplateSubject(res_data?.template_subject);
        setContent(res_data?.template_content);
        setUploadedFiles((prevAttachment) => ({
          ...prevAttachment,
          files: fileData,
        }));
        setAttachment((prevAttachment) => ({
          ...prevAttachment,
          files: fileData,
        }));
        setTemplateSec(res_data?.template_type);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  };

  useEffect(() => {
    GetEmailTemplateById();
  }, []);

  const inputChangeHandler = (e, identifier) => {
    if (identifier === "templateName") {
      setTemplateName(e.target.value);
    }
    if (identifier === "templateSubject") {
      setTemplateSubject(e.target.value);
    }
  };
  // function to remove attached file
  const removeFile = (item) => {
    if (item?.email_temp_attachment_id) {
      const payload = {
        email_temp_attachment_id: item?.email_temp_attachment_id,
        email_temp_doc: item?.file,
      };

      props?.onDeleteEmailTempAttachmentById(payload).then((response) => {
        if (response?.success === true) {
          GetEmailTemplateById();
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
    } else {
      setAttachment((prevAttachment) => ({
        ...prevAttachment,
        files: prevAttachment.files.filter(
          (files) => files.file !== item?.file
        ),
      }));
    }
  };

  // to calculate the size of attached files
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment?.files?.forEach((item) => {
      totalSize += item?.size;
    });
    return totalSize;
  };

  const submithandler = (e) => {
    e.preventDefault();
    const tempNameValidation = Validations({
      value: templateName,
      Validations: { required: true },
    });
    const tempSubValidation = Validations({
      value: templateSubject,
      Validations: { required: true },
    });
    const tempContentValidation = Validations({
      value: content == "<p><br></p>" || "" ? "" : content,
      Validations: { required: true },
    });
    if (!tempNameValidation.valid) {
      setNameError(tempNameValidation.error_msg);
    }
    if (!tempSubValidation.valid) {
      setSubjectError(tempSubValidation.error_msg);
    }
    if (!tempContentValidation.valid) {
      setMessageError(tempContentValidation.error_msg);
    }

    const totalAttachmentSize = calculateTotalAttachmentSize();
    if (totalAttachmentSize > 25 * 1024 * 1024) {
      return;
    }

    const files = attachment.files;
    if (
      tempNameValidation.valid === true &&
      tempSubValidation.valid === true &&
      documentError == "" &&
      tempContentValidation.valid === true
    ) {
      setIsSaving(true);
      const payload = {
        email_template_id: props?.email_id,
        template_name: templateName,
        template_subject: templateSubject,
        template_content: content,
        template_type: templateSec,
        email_temp_doc: files, // Array to hold file objects
      };
      setTemplateName("");
      props
        ?.onUpdateEmailTemplate(payload, ipAddress)
        .then((response) => {
          if (response?.success === true) {
            onDialogOk();
            setNameError("");
            setSubjectError("");
            setMessageError("");
            props?.GetAllEmailTemplatesByType();
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          } else {
            setIsSaving(false);
          }
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }
  };

  // const handleFileChange = (files) => {
  //   setAttachment({ files });
  // };
  const handleFileChange = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true;
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        } else {
          totalSize += fileSize;
          return true;
        }
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });
      // setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      } else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError(
            "Total file size exceeds the maximum limit of 25MB."
          );
          return;
        } else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };
  // const handleFileRemove = (files) => {
  //   // Handle the file remove logic here
  //   setAttachment({ files });
  // };
  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      // setFilesUploaded(false);
    }

    if (files.length === 0) {
      // setFilesUploaded(false)
    }
  };
  const handleReactSelectChange = (selectedOption) => {
    setTemplateSec(selectedOption.value); // Save the selected option to state
  };

  return (
    <React.Fragment>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={emailIcon} alt=""></img>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Edit email template
            </h5>
            <p className="text-gray-600">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <form>
          <div className="p-6">
            <div className="flex gap-3">
              <div className="mb-4 flex flex-col flex-1">
                <Label className="font-medium text-xs mb-2">
                  Template name*
                </Label>
                <input
                  className="h-9 input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={templateName ? templateName : ""}
                  onChange={(e) => {
                    inputChangeHandler(e, "templateName");
                    setNameError("");
                  }}
                />
                {nameError && (
                  <div className="text-red-500 text-sm mt-1">{nameError}</div>
                )}
              </div>
              <div className="mb-4 flex flex-col  flex-1">
                <Label className="font-medium text-xs mb-2">
                  Which section of admin portal?
                </Label>
                <Select
                  className="h-9 hide-seacrh-icon h-full-wrap"
                  type="text"
                  value={sectionOption.filter(function (option) {
                    return option.value === templateSec;
                  })}
                  options={sectionOption}
                  onChange={handleReactSelectChange}
                />
              </div>
            </div>
            <div className="mb-4 flex flex-col">
              <Label className="font-medium text-xs mb-1.5">Subject*</Label>
              <input
                className="h-9 input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={templateSubject}
                onChange={(e) => {
                  inputChangeHandler(e, "templateSubject");
                  setSubjectError("");
                }}
              />
              {subjectError && (
                <div className="text-red-500 text-sm mt-1">{subjectError}</div>
              )}
            </div>

            <div className="flex flex-col">
              <div>
                <Label className="font-medium text-xs mb-1.5">Message*</Label>
                <RichTextEditor
                  onChange={(e) => handleChange(e)}
                  value={content}
                />
                {messageError && (
                  <div className="text-red-500 text-sm mt-1">
                    {messageError}
                  </div>
                )}
              </div>

              <div className="mb-5">
                <Label className="text-xs font-medium block mb-1 mt-3">
                  Attachment (optional)
                </Label>
                <Upload
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center cursor-pointer"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true} // Allow multiple file uploads
                  maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
                >
                  <span className="text-blue-700 font-semibold ">
                    Click to upload
                  </span>{" "}
                  <span className="font-medium text-gray-600">
                    or drag and drop
                  </span>
                  <p className="text-gray-600">
                    PDF, JPG, JPEG, PNG, GIF (MAX. 25MB)
                  </p>
                </Upload>
                {documentError && (
                  <p className="text-xs text-red-600 mt-1">{documentError}</p>
                )}
              </div>

              {uploadedFiles.files &&
                uploadedFiles.files.map((item, index) => (
                  <div
                    key={index}
                    className="upload-file flex items-center justify-between mt-2"
                  >
                    <div className="upload-file-left flex items-center">
                      <div className="upload-file-thumbnail">
                        <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                          <img src={PdfFile} alt="" />
                        </span>
                      </div>
                      <a
                        href={item?.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="upload-file-name font-medium text-xs text-gray-700">
                          {item?.fileName}
                        </span>
                      </a>
                    </div>
                    <span
                      className="upload-file-remove w-5"
                      onClick={() => removeFile(item)}
                    >
                      <span className="">
                        <img src={trashIcon} alt="" />
                      </span>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </form>
        <div className="flex dailog-footer mt-0 justify-end gap-4">
          <BtnLight
            className="w-auto px-3"
            variant="plain"
            onClick={onDialogClose}
            width={900}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            className="w-auto px-3"
            variant="solid"
            onClick={submithandler}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save template"}
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.CreateEmailTemplate.loader,
  };
}
const mapDispatchToProps = {
  onUpdateEmailTemplate: userActions.UpdateEmailTemplate,
  GetEmailTemplateById: userActions.GetEmailTemplateById,
  onDeleteEmailTempAttachmentById: userActions.DeleteEmailTempAttachmentById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplate);
