import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ViewAllClients from "../pages/Client/viewAllClients";
import SpecificClients from "../pages/Client/SpecificClient";
import Notes from "../pages/Client/SpecificClient/Notes";
import ClientSettings from "../pages/Client/SpecificClient/Settings";
import SpecificSite from "../pages/Client/SpecificSite/index";
import Dashboard from "../pages/Client/dashboard";
// import Invoice from "../pages/Client/Invoice";
import Invoices from "../pages/Client/Invoices";
// import NewInvoice from "../pages/Client/Invoice/NewInvoice";
import RaiseSpecificClientInvoice from "../pages/Client/SpecificClientTabs/Invoices/RiseInvoice";
import Reports from "../pages/Client/Reports";
import ClientOverview from "../pages/Client/Reports/ClientsOverview";
import ClientsInductions from "../pages/Client/Reports/ClientsInductions";
import ClientsComplianceChecklist from "../pages/Client/Reports/ClientsComplianceChecklist";
import ClientsInvoices from "../pages/Client/Reports/ClientsInvoices";
import ClientsSites from "../pages/Client/Reports/ClientsSites";
import ProviderUnderAClient from "../pages/Client/Reports/ProviderUnderAClient";
import RaiseInvoice from "../pages/Client/Invoices/RiseInvoice";
function useLocalStoragePath() {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  return location.pathname;
}
export const AdminPortalClientRoutes = () => {
  const currentPath = useLocalStoragePath();
  return (
    <React.Fragment>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="view-all-clients" element={<ViewAllClients />} />
        <Route
          path="view-all-clients/specific-client/:id/*"
          element={<SpecificClients />}
        />
        <Route
          path="view-all-clients/specific-client/:id/notes"
          element={<Notes />}
        />
        <Route
          path="view-all-clients/specific-client/:id/settings"
          element={<ClientSettings />}
        />
        <Route
          path="view-all-clients/specific-client/:id/specific-site/:siteId"
          element={<SpecificSite />}
        />
        
        <Route path="invoices" element={<Invoices />} />
        <Route path="invoices/raise-invoice" element={<RaiseInvoice />} />
        <Route
          path="view-all-clients/specific-client/:id/raise-invoice"
          element={<RaiseSpecificClientInvoice />}
        />
        {/* <Route path="invoice/new-invoice" element={<NewInvoice />} /> */}
        <Route path="reports" element={<Reports />} />
        <Route path="reports/clients-overview" element={<ClientOverview />} />
        <Route
          path="reports/compliance-checklist"
          element={<ClientsComplianceChecklist />}
        />
        <Route
          path="reports/clients-inductions"
          element={<ClientsInductions />}
        />
        <Route path="reports/clients-invoices" element={<ClientsInvoices />} />
        <Route path="reports/clients-sites" element={<ClientsSites />} />
        <Route
          path="reports/provider-under-client"
          element={<ProviderUnderAClient />}
        />
      </Routes>
    </React.Fragment>
  );
};
