// import axios from "axios";
import axios from "axios";
import * as actionType from "../../constant/actionType";

export const DeleteCompanyInductionModuleById =
  (ApiData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "delete",
        data: ApiData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/DeleteCompanyInductionModuleById`,
      });
      return response.data;
    } catch (error) {}
  };
