import React, { useState, useEffect, useCallback } from "react";
import BtnPrimaryLight from "../../../../../../common/element/Buttons/primaryLightBtn";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import { Link, useParams } from "react-router-dom";
import { debounce } from "../../../../../../common/snippet/Debouncing";
// import Segment from "../../../../../common/element/Segment";
import Heading from "../../../../../../common/element/Heading";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import InvoiceList from "./InvoiceList";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../../../../../common/pagination";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import searchIcon from "../../../../../../assets/icons-svg/search-lg.svg";
import { connect, useDispatch } from "react-redux";
import RiseInvoice from "./RiseInvoice";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { GetAllInvoiceOfClients } from "../../../../../../actions/Admin-Client/invoices/GetAllInvoiceOfClients";
import Label from "../../../../../../common/element/Label";
import Select from "../../../../../../common/element/Select";
import { components } from "react-select";
import { HiCheck } from "react-icons/hi";
import { GetAllClientList } from "../../../../../../actions/admin-provider/AllInviteContractorApi";
import { useSelector } from "react-redux";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
const Invoices = () => {
  const { id } = useParams();
  const orgId = decryptId(id);
  const { allFunctions } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [removeCheck, setRemoveCheck] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [countData, setCountData] = useState(0);
  const [filterpage, setfilterpage] = useState(1);
  const [searchTerm, setsearchTerm] = useState("");
  const [pdfDownloadSuccessDialog, setPdfDownloadSuccessDialog] =
    useState(false);
  const [loadData, setLoadData] = useState(false);
  const [filterlimit, setfilterlimit] = useState(10);
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("created_date");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedClint, setSelectedClint] = useState("");

  const [totalPages, setTotalPages] = useState(1);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
  const [allInvoicesData, setAllInvoicesData] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const { specificClientDetails } = useSelector((state) => state);
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },
        {
          label: specificClientDetails?.trading_name,
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(
            id
          )}`,
        },
        {
          label: "Invoices",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, [specificClientDetails]);

  const onGetAllInvoiceData = useCallback(async () => {
    let payload = {
      page: filterpage,
      search: searchTerm,
      limit: filterlimit,
      client_org_id: orgId,
      order: filterorder,
      sort: filtersort,
    };
    dispatch(GetAllInvoiceOfClients(payload)).then((response) => {
      if (response.success) {
        setAllInvoicesData(response.data?.rows);
        setCountData(response.data?.count);
        setTotalPages(Math.ceil(response.data?.count / filterlimit));
        setLoadData(true);
      } else {
        setLoadData(true);
      }
    });
    // setAllInvoicesData(getData);
    // setCountData(getData.length);
    // setTotalPages(Math.ceil(getData.length / filterlimit));

    // try {
    //     const payload = {
    //         search: searchTerm,
    //         page: filterpage,
    //         limit: filterlimit,
    //         sort: filtersort,
    //         order: filterorder,
    //         viewType: viewType
    //     }
    //     const response = await dispatch(GetAllAdminInvoices(payload));
    //     if (response?.success) {
    //         const arrayData = response?.data.rows;
    //         setAllInvoicesData(arrayData);
    //         setCountData(response?.data?.count)
    //         setTotalPages(Math.ceil(response.data.count / filterlimit));

    //     } else if (response?.status === 401) {
    //         handleUnauthorizedAccess();
    //     }
    // } catch (error) {
    //     console.error('Error fetching global permissions:', error);
    // }
  }, [
    dispatch,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    selectedClint,
  ]);

  useEffect(() => {
    onGetAllInvoiceData();
  }, [
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    selectedClint,
  ]);

  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
    setRemoveCheck(true);
  };

  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
  };

  const { Control } = components;

  const countryOptions = [
    { value: "", label: "All" },
    { value: "PAID", label: "Paid", imgPath: "" },
    { value: "UPCOMING", label: "Upcoming", imgPath: "/img/countries/cn.png" },
    { value: "OVERDUE", label: "Overdue", imgPath: "/img/countries/jp.png" },
    { value: "DRAFT", label: "Draft", imgPath: "/img/countries/jp.png" },
  ];

  const colourOptions = [
    { value: "all", label: "All" },
    { value: "paid", label: "Paid" },
    { value: "upcoming", label: "Upcoming" },
    { value: "overdue", label: "Overdue" },
  ];

  const CustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0];
    return (
      <Control {...props}>
        {selected && (
          <span
            className=" rtl:mr-4"
            shape="circle"
            size={18}
            src={selected.imgPath}
          />
        )}
        {children}
      </Control>
    );
  };

  const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
    return (
      <div
        className={`flex items-center justify-between p-2 ${
          isSelected ? "bg-blue-50 rounded-lg" : "hover:bg-blue-50 rounded-lg"
        }`}
        {...innerProps}
      >
        <div className="flex items-center">
          <span shape="circle" size={20} src={data.imgPath} />
          <span className="ml-2 rtl:mr-2">{label}</span>
        </div>
        {isSelected && <HiCheck className="text-blue-500 text-xl" />}
      </div>
    );
  };

  const downloadSingleInvoice = async (item) => {
    try {
      if (!item?.xero_invoice_pdf || !item?.xero_invoice_number) {
        throw new Error("Invalid invoice data: Missing URL or invoice number");
      }

      const response = await fetch(item.xero_invoice_pdf);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const url = window.URL.createObjectURL(blob); // Create an object URL for the Blob

      const link = document.createElement("a");
      link.href = url;
      link.download = `${item.xero_invoice_number}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error(
        `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
        error.message
      );
    }
  };

  const downloadSelectedInvoices = async () => {
    for (const [index, item] of allInvoicesData.entries()) {
      await new Promise((resolve) => {
        setTimeout(() => {
          downloadSingleInvoice(item);
          resolve();
        }, index * 300); // Introducing a delay of 300ms between downloads
      });
    }
    // After all downloads are attempted, show success dialog and reset checked items
    setPdfDownloadSuccessDialog(true);
  };

  const closeDownloadSuccessPdf = () => {
    setPdfDownloadSuccessDialog(false);
  };

  useEffect(() => {
    const payload = {
      function_id: allFunctions?.allFunction?.filter(
        (item) => item.function_name == "client"
      )[0].function_id,
      search: searchClient,
    };

    if (searchClient.length >= 3) {
      dispatch(GetAllClientList(payload)).then((response) => {
        if (response.success) {
          let filterData = response.data.map((item) => {
            return {
              label: item.trading_name,
              value: item.organisation_id,
            };
          });
          setClientOptions(filterData);
        }
      });
    }
  }, [searchClient]);

  const handleRecipientSearchChange = useCallback(
    debounce((newInputValue) => {
      setSearchClient(newInputValue);
    }, 500),
    []
  );

  const handleSelectRecipientChange = (selectedOption) => {
    setSelectedClint(selectedOption);
  };

  return (
    <React.Fragment>
      <div className="flex justify-between flex-wrap gap-5 pb-4 items-center">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={searchIcon} alt="" className="h-5" />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
            />
          </div>
        </div>
        <Link to="raise-invoice">
          <BtnPrimary className="flex w-40 items-center justify-center gap-2 text-12">
            Raise an invoice
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.712 4.167v11.666M4.878 10h11.667"
                stroke="#fff"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnPrimary>
        </Link>
      </div>
      {/* <Filter /> */}
      <InvoiceList
        removeCheck={removeCheck}
        setRemoveCheck={setRemoveCheck}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        allInvoicesData={allInvoicesData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterpage}
            displayPage={allInvoicesData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}

      <Dialog
        isOpen={pdfDownloadSuccessDialog}
        onClose={closeDownloadSuccessPdf}
        onRequestClose={closeDownloadSuccessPdf}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            All PDFs downloaded successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeDownloadSuccessPdf()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Invoices;
