import axios from "axios";
import * as actionType from "../../constant/actionType";
import { useSelector } from 'react-redux';
export const CreateCompanyInductionForClientForAdmin =
  (APIData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "post",
        data: APIData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/CreateCompanyInductionForClient`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const CreateCompanyInductionModuleApiForAdmin =
  (ApiData) => async (dispatch) => {
    try {
      let formdata = new FormData();
      for (let key in ApiData) {
        if (key === "company_ind_file") {
          formdata.append("company_ind_file", ApiData.company_ind_file);
        } else {
          if (key === "module_ques_data" || key === "pdf_page_range") {
            formdata.append(key, JSON.stringify(ApiData[key]));
          } else {
            if (key === "content_info_type") {
              if (ApiData.content_info_type !== "") {
                formdata.append(key, ApiData[key]);
              }
            } else {
              formdata.append(key, ApiData[key]);
            }
          }
        }
      }

      const response = await axios({
        method: "post",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/CreateCompanyInductionModule`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetAllInductionOfSpecificCompanyForAdmin =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetAllInductionOfSpecificCompany`,
        params: getAllData,
      });
      return response.data;
    } catch (error) {}
  };

export const GetCompanyInductionAndModulesbyIdForAdmin =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetCompanyInductionAndModulesbyId`,
        params: { company_induction_id: getAllData },
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

  

export const GetCompanyInductionModuleByIdForAdmin =
  (getData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetCompanyInductionModuleById`,
        params: { module_id: getData.module_id },
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

  

export const UpdateCompanyInductionForAdmin = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (
        key !== "company_ind_publish_date" &&
        key !== "function_assignment_id"
      ) {
        // For other properties, append the value directly
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateCompanyInduction`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const UpdateCompanyInductionModuleForAdmin =
  (ApiData) => async (dispatch) => {
    try {
      // dispatch(fetchData());
      let formdata = new FormData();
      for (let key in ApiData) {
        if (key === "company_ind_file") {
          formdata.append("company_ind_file", ApiData.company_ind_file);
        } else {
          if (key === "module_ques_data" || key === "pdf_page_range") {
            // Assuming you want to stringify the array as JSON
            formdata.append(key, JSON.stringify(ApiData[key]));
          } else {
            // For other properties, append the value directly
            if (key === "content_info_type") {
              if (
                ApiData.content_info_type !== "" &&
                ApiData.content_info_type !== null
              ) {
                formdata.append(key, ApiData[key]);
              }
            } else {
              formdata.append(key, ApiData[key]);
            }
          }
        }
      }

      const response = await axios({
        method: "put",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/UpdateCompanyInductionModule`,
      });
      // dispatch(fetchDataSuccess(response.data));
      return response.data;
    } catch (error) {
      return error.message;
      // dispatch(fetchDataError(error.message));
    }
  };

  export const UpdateCompanyInductionStatusForAdmin = (ApiData, ipAddress) => async (dispatch) => {
    try {
      const response = await axios({
        method: "put",
        data: ApiData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          userip: ipAddress, // Include IP address here
        },
        url: `${actionType.environment_url}/api/admin/client/UpdateCompanyInductionStatus`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
  


  export const ExportInductionModulePdfRange = (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: 'put',
        data: ApiData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/client/ExportInductionModulePdfRange`,
      });
      return response.data
    } catch (error) {
      return error.message
    }
  }


  export const DeleteCompanyIndModuleFileById = (data) => async (dispatch) => {
    // Final CSV Data
    try {
      let formdata = new FormData();
      for (let key in data) {
          formdata.append(key, data[key]);
      }
      const response = await axios({
        method: "delete",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleFileById`,
      });
      return response.data;
    } catch (error) {}
  };

  export const DeleteCompanyIndModuleQuesAnsByid = (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "delete",
        data: {module_question_id:ApiData} ,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleQuesAnsByid`,
      });
      return response.data;
    } catch (error) {}
  };

  
export const DeleteCompanyIndModuleAnsByid = (ApiData) => async (dispatch) => {

  // Final CSV Data
  try {
    const response = await axios({
      method: "delete",
      data: { module_answer_id: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleAnsByid`,
    });
    return response.data;
  } catch (error) {}
};

export const DeleteCompanyInduction = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: {company_induction_id:ApiData} ,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyInduction`,
    });
    return response.data;
  } catch (error) {}
};

export const DeleteCompanyInductionModuleById = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData ,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyInductionModuleById`,
    });
    return response.data;
  } catch (error) {}
};


export const UpdateModuleSortingOrder = (data) => async (dispatch) => {
  const dataa = {moduleOrderContents : data}
  try {
    let formdata = new FormData();
    for (let key in dataa) {
        formdata.append(key, JSON.stringify(dataa[key]));
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateModuleSortingOrder`,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateModuleQuesSortingOrder = (data) => async (dispatch) => {
  const dataa = {moduleQuesOrderContents : data}
  try {
    let formdata = new FormData();
    for (let key in dataa) {
        formdata.append(key, JSON.stringify(dataa[key]));
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateModuleQuesSortingOrder`,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateModuleAnsSortingOrder = (data) => async (dispatch) => {
  const dataa = {moduleAnsOrderContents : data}
  try {
    let formdata = new FormData();
    for (let key in dataa) {
        formdata.append(key, JSON.stringify(dataa[key]));
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateModuleAnsSortingOrder`,
    });
    return response.data;
  } catch (error) {}
};
