import React, { useEffect, useRef, useState, useCallback } from "react";
import Table from "../../../../../../common/element/Table";
import Tooltip from "../../../../../../common/element/Tooltip";
import Tag from "../../../../../../common/element/Tag";
import Button from "../../../../../../common/element/Buttons";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Tabs from "../../../../../../common/element/Tabs";
import { notify } from "react-notify-toast";
import { GetWorkerrDocById } from "../../../../../../actions/admin-worker/AdminWorkerAPI";
import { GetAllDocumentsOfSpecificContractorData } from "../../../../../../actions/admin-provider/GetContractorDocById";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import { UpdateProviderDoc } from "../../../../../../actions/admin-provider/GetUpdateContractorDoc";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import moment from "moment";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import { GetDocumentHistoryById } from "../../../../../../actions/admin-provider/GetDocumentHistoryById";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import rightIcon from "../../../../../../assets/icons-svg/chevron-right.svg";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import { DeleteProviderDoc } from "../../../../../../actions/admin-provider/DeleteContractorDoc";
import Upload from "../../../../../../common/element/Upload";
import DatePicker from "../../../../../../common/element/DatePicker";
import { GetAllDocType } from "../../../../../../actions/admin-worker/getAllDocumentTypeList";
import {
  UpdateWorkerDoc,
  GetWorkerDocHistoryById,
} from "../../../../../../actions/admin-worker/AdminWorkerAPI";
import { PostDoctype } from "../../../../../../actions/admin-worker/postDocTypeData";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Select from "../../../../../../common/element/Select";
import { DeleteWorkerDoc } from "../../../../../../actions/admin-worker/DeleteWorkerDoc";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import { GetSignedUrlByKey } from "../../../../../../actions/public/GetSignedUrlByKey";
const { Tr, Th, Td, THead, TBody } = Table;
const { TabNav, TabList, TabContent } = Tabs;
const DocumentsList = (props) => {
  const { id } = useParams();
  const orgId = decryptId(id);

  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const GettAllFunctions = useSelector((state) => state);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const dateGap = 0;
  const dispatch = useDispatch();
  const [getData, SetGetData] = useState([]);
  const [updateData, SetupdateData] = useState([]);
  const [allDocHistorty, setAllDocHistory] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error_expiry_date, setError_expiry_date] = useState("");
  const [IndividualData, SetgetIndividual] = useState();
  const [checkError, SetCheckError] = useState([]);
  const [updateError, SetUpdateError] = useState(false);
  const [specificData, SetspecificData] = useState([]);
  const [specificDataUpdate, SetspecificDataUpdate] = useState({
    document_id: specificData.document_id ? specificData.document_id : "",
    column_names: [""],
  });
  const [clientOrgData, SetClientOrgData] = useState([]);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [updateSuccessOpen, setUpdateSuccess] = useState(false);
  const [checkEdit, SetCheckEdit] = useState(false);
  const [insuraceDialogIsOpen, setIsInsuraceOpen] = useState(false);
  const [formDialogIsOpen, setIsFormOpen] = useState(false);
  const [deleteDiolog, setDeleteDiolog] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [pdfFile, setPdfFile] = useState("");
  const [checkPdfFile, setCheckPdfFile] = useState("");
  const [checkAllData, setcheckAllData] = useState();
  const [toggleState, setToggleState] = useState(
    Array(getData?.length).fill(false)
  );
  const [zoom, setZoom] = useState(100);
  const [dragging, setDragging] = useState(false);
  let fileExtension;
  const [startPosition, setStartPosition] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [docTypeOptions, setdocTypeOptions] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [addDocForm, setaddDocForm] = useState("");
  const [checkReqiuredField, SetcheckReqiuredField] = useState(false);
  const [addDocError, setAddDocError] = useState(false);
  const [sizeError, SetsizeError] = useState("");
  const [wordError, SetwordError] = useState("");
  const [docType, setDocType] = React.useState("");
  const [specificDocData, setSpecificDocData] = React.useState("");
  let error = "This field is required!";
  const [refetch, Setrefetch] = useState(false);
  const { workerRedirectSpecificData } = useSelector((state) => state);

  const [expiryDateChk, setExpiryDateChk] = useState(false);
  const [documentFormData, SetDocumentFormData] = useState({
    doc_name: "",
    individual_id: workerRedirectSpecificData?.individual_id
      ? workerRedirectSpecificData?.individual_id
      : "",
    // function_assignment_id: function_assignment_id,
    worker_id: orgId,
  });
  // Get Data From Redux-store

  useEffect(() => {
    if (props?.allDocumnetDataList) {
      SetGetData(props?.allDocumnetDataList);
    }
  }, [props?.allDocumnetDataList]);

  const openDeleteDailog = (e) => {
    setDeleteDiolog(true);
    setDeleteData(e);
  };
  const fetchDocumentTypes = useCallback(() => {
    // if (dialogChoose) {
    dispatch(GetAllDocType({ recipient_type: "worker" }))
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data;
          const updatedArrayData = arrayData.map((item) => ({
            ...item,
            value: item.doc_type_name.toLowerCase().replace(/ /g, "_"),
            label: `${item.doc_type_name} - (${item.recipient_type})`,
            name: "document_type_id",
          }));
          setdocTypeOptions(updatedArrayData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.error("Failed to fetch document types:", error);
      });
    // }
  }, [dispatch]);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);
  const openDialog = (e) => {
    // dispatch(reSetData())
    let getIndivisualData = {
      document_id: e.document_id,
      // document_type_id: e.document_type_id,
      // function_assignment_id: props.function_assignment_id,
    };
    setIsOpen(true);
    dispatch(GetWorkerrDocById(getIndivisualData))
      .then((response) => {
        if (response?.success) {
          const data = response.data;
          setcheckAllData(data?.docData?.document_type);
          SetspecificDataUpdate({
            document_id: data?.docData?.document_id,
            column_names: [""],
          });
          SetspecificData({
            document_id: data?.docData?.document_id,
            document_type: data?.docData?.document_type?.document_type_id,
            doc_type_name: data?.docData?.document_type?.doc_type_name,
            doc_name: data?.docData?.doc_name,
            document_desc: data?.docData?.expiry_date_notes,
            expiry_date: data?.docData?.expiry_date,
            customField: data?.docData?.doctype_fields_data,
            // number_of_workers: data?.doc_insurance?.number_of_workers,
            insured_amount: data?.docData?.amount_insured,
            policy_no: data?.docData?.policy_no,
            // trading_name: data?.swm_doc_type?.swm_clients_org,
            issuing_authority: data?.docData?.issuer,
            created_date: data?.docData?.created_date,
            // issue_date: data?.docData?.issue_date,
            // is_swms_doc: data?.is_swms_doc,
            doc_file: data?.docData?.doc_file,
          });
          // SetClientOrgData(data?.clientsData)
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    SetgetIndividual(e);
  };
  const minDate = dayjs(new Date())
    .subtract(dateGap, "day")
    .startOf("day")
    .toDate();

  const onCloseUpdateSucess = () => {
    setUpdateSuccess(false);
  };
  const handleEditClick = (e) => {
    SetDocumentFormData(e);
    setEditDialogIsOpen(true); // Open the edit dialog
    SetDocumentFormData({
      doc_name: "",
      // organisation_id: id,
      // function_assignment_id: function_assignment_id,
      individual_id: workerRedirectSpecificData?.individual_id
        ? workerRedirectSpecificData?.individual_id
        : "",
      // function_assignment_id: function_assignment_id,
      worker_id: orgId,
      document_type_id: e.document_type_id,
    });
    setaddDocForm(e.doc_type_name);
    setDocType(e.doc_type_name);
    let FilterData = docTypeOptions?.filter(
      (item) => item.document_type_id == e.document_type_id
    );

    setSpecificDocData(FilterData?.[0]);
    if (FilterData?.[0]?.allow_expiry == true) {
      setExpiryDateChk(true);
    }
    for (let filterData in FilterData?.[0]) {
      if (
        FilterData?.[0][filterData] == true ||
        filterData == "custom_fields"
      ) {
        let name =
          filterData == "allow_expiry"
            ? "expiry_date"
            : filterData == "req_expiry_date_notes"
            ? "expiry_date_notes"
            : filterData == "req_issuer"
            ? "issuer"
            : filterData == "req_policy_no"
            ? "policy_no"
            : filterData == "req_amount_insured"
            ? "amount_insured"
            : filterData == "custom_fields"
            ? "doctype_fields_data"
            : "";
        if (name == "doctype_fields_data") {
          let obj = {};
          if (FilterData?.[0]?.[filterData]?.length) {
            for (let i = 0; i < FilterData[0][filterData].length; i++) {
              obj[FilterData[0][filterData][i]] = "";
            }
          } else {
            console.error(
              "FilterData or its nested properties are null or undefined."
            );
          }
          SetDocumentFormData((prevalue) => {
            return {
              ...prevalue,
              [name]: obj,
            };
          });
        } else {
          SetDocumentFormData((prevalue) => {
            if (name == "amount_insured") {
              return {
                ...prevalue,
                [name]: 0,
              };
            } else {
              return {
                ...prevalue,
                [name]: "",
              };
            }
          });
        }
      }
    }

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // Set the document data to the state

    let getIndivisualData = {
      document_id: e.document_id,
      // document_type_id: e.document_type_id,
      // function_assignment_id: props.function_assignment_id,
    };
    // setIsOpen(true);
    dispatch(GetWorkerrDocById(getIndivisualData))
      .then((response) => {
        if (response?.success) {
          const data = response.data;
          setcheckAllData(data?.docData?.document_type);
          SetspecificDataUpdate({
            document_id: data?.docData?.document_id,
            column_names: [""],
          });
          SetspecificData({
            document_id: data?.docData?.document_id,
            document_type: data?.docData?.document_type?.document_type_id,
            doc_type_name: data?.docData?.document_type?.doc_type_name,
            doc_name: data?.docData?.doc_name,
            document_desc: data?.docData?.expiry_date_notes,
            expiry_date: data?.docData?.expiry_date,
            customField: data?.docData?.doctype_fields_data,
            // number_of_workers: data?.doc_insurance?.number_of_workers,
            insured_amount: data?.docData?.amount_insured,
            policy_no: data?.docData?.policy_no,
            // trading_name: data?.swm_doc_type?.swm_clients_org,
            issuing_authority: data?.docData?.issuer,
            created_date: data?.docData?.created_date,
            // issue_date: data?.docData?.issue_date,
            // is_swms_doc: data?.is_swms_doc,
            doc_file: data?.docData?.doc_file,
          });
          // SetClientOrgData(data?.clientsData)
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    SetgetIndividual(e);
  };
  const onDialogCloseEdit = () => {
    setEditDialogIsOpen(false);
  };
  // Handle form
  const handleForm = (event, expireDate, custom_field, action = "add") => {
    SetDocumentFormData((prevValue) => {
      if (custom_field === "doctype_fields_data") {
        return {
          ...prevValue,
          doctype_fields_data: {
            ...prevValue.doctype_fields_data,
            [expireDate]: event.target.value,
          },
        };
      }

      if (expireDate === "expiry_date" || expireDate === "issue_date") {
        return {
          ...prevValue,
          [expireDate]: event,
        };
      }

      if (event?.name === "document_type_id") {
        return {
          ...prevValue,
          [event.name]: event.document_type_id,
        };
      }

      if (event.name === "is_doctype_swms") {
        return {
          ...prevValue,
        };
      }

      // Handle file removal
      if (action === "remove") {
        SetsizeError("");
        setAddDocError(false);
        return {
          ...prevValue,
          doc_file: [], // Clear the file list
        };
      }

      // Handle file upload
      if (Array.isArray(event) || event.length === 0) {
        if (event.length === 0) {
          SetsizeError("");
          setAddDocError(false);
          return {
            ...prevValue,
            doc_file: event,
          };
        }

        const validExtensions = /\.(pdf|jpg|jpeg)$/i;
        if (validExtensions.test(event[0]?.name)) {
          if (event[0]?.size <= 2 * 1024 * 1024) {
            // 2MB limit
            SetsizeError("");
            setAddDocError(false);
            return {
              ...prevValue,
              doc_file: event,
            };
          } else {
            SetsizeError("File size exceeds the maximum limit of 2MB.");
            setAddDocError(true);
            return {
              ...prevValue,
              doc_file: [], // Clear the file list
            };
          }
        } else {
          SetsizeError("Only PDF, JPG, and JPEG files will be accepted.");
          setAddDocError(true);
          return {
            ...prevValue,
            doc_file: [], // Clear the file list
          };
        }
      }

      if (event.target.name === "document_desc") {
        const currentValue = event.target.value;
        const words = currentValue.split(/\s+/);

        if (words.length <= 250) {
          SetwordError("");
          return {
            ...prevValue,
            [event.target.name]: currentValue,
          };
        } else {
          SetwordError("Additional information must not exceed 250 words.");
          const limitedInput = words.slice(0, 250).join(" ");
          return {
            ...prevValue,
            [event.target.name]: limitedInput,
          };
        }
      }

      return {
        ...prevValue,
        [event.target.name]: event.target.value,
      };
    });
  };
  const HandleChange = (e) => {
    SetDocumentFormData({
      doc_name: "",
      // organisation_id: id,
      // function_assignment_id: function_assignment_id,
      individual_id: "",
      // function_assignment_id: function_assignment_id,
      worker_id: orgId,
      document_type_id: e.document_type_id,
    });
    setaddDocForm(e.value);
    setDocType(e.value);
    let FilterData = docTypeOptions?.filter(
      (item) => item.document_type_id == e.document_type_id
    );
    setSpecificDocData(FilterData?.[0]);
    if (FilterData?.[0]?.allow_expiry == true) {
      setExpiryDateChk(true);
    }
    for (let filterData in FilterData?.[0]) {
      if (
        FilterData?.[0][filterData] == true ||
        filterData == "custom_fields"
      ) {
        let name =
          filterData == "allow_expiry"
            ? "expiry_date"
            : filterData == "req_expiry_date_notes"
            ? "expiry_date_notes"
            : filterData == "req_issuer"
            ? "issuer"
            : filterData == "req_policy_no"
            ? "policy_no"
            : filterData == "req_amount_insured"
            ? "amount_insured"
            : filterData == "custom_fields"
            ? "doctype_fields_data"
            : "";
        if (name == "doctype_fields_data") {
          let obj = {};
          if (FilterData?.[0]?.[filterData]?.length) {
            for (let i = 0; i < FilterData[0][filterData].length; i++) {
              obj[FilterData[0][filterData][i]] = "";
            }
          } else {
            console.error(
              "FilterData or its nested properties are null or undefined."
            );
          }
          SetDocumentFormData((prevalue) => {
            return {
              ...prevalue,
              [name]: obj,
            };
          });
        } else {
          SetDocumentFormData((prevalue) => {
            if (name == "amount_insured") {
              return {
                ...prevalue,
                [name]: 0,
              };
            } else {
              return {
                ...prevalue,
                [name]: "",
              };
            }
          });
        }
      }
    }
  };
  // const handleUpdate = () => {
  //   SetcheckReqiuredField(true);
  //   let isFormDataValid = true;
  //   if ((documentFormData?.expiry_date || expiryDateChk == false) && documentFormData.doc_name) {
  //     isFormDataValid = true;
  //   } else {
  //     isFormDataValid = false;
  //   }

  //   if (documentFormData?.doc_file == "" || documentFormData?.doc_file == undefined || (Array.isArray(documentFormData?.doc_file) && documentFormData.doc_file.length === 0)) {
  //     setAddDocError(true)
  //   }
  //   else {
  //     setAddDocError(false)
  //   }
  //   if (isFormDataValid &&
  //     (documentFormData?.doc_file != "" && documentFormData?.doc_file != undefined && (Array.isArray(documentFormData?.doc_file) && documentFormData.doc_file.length != 0))) {
  //     setButtonDisabled(true)
  //     dispatch(PostDoctype(documentFormData))
  //       .then((response) => {
  //         if (response?.success) {
  //           setButtonDisabled(false)
  //           setUpdateSuccess(true);
  //           setEditDialogIsOpen(false)
  //           SetDocumentFormData([]);
  //           SetcheckReqiuredField(false);
  //         } else if (response.status === 401) {
  //           handleUnauthorizedAccess();
  //         }
  //       })
  //       .catch((error) => {
  //         setButtonDisabled(false)
  //       }).finally(() => {
  //         setButtonDisabled(false)
  //       })
  //   }
  // };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "image.jpg";
    link.click();
  };
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();
    printWindow.document.write(`<img src="${pdfFile}" alt="Image" />`);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };
  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };
  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };
  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  fileExtension = pdfFile?.split(".").pop().toLowerCase();
  const onDialogClose = (e) => {
    setIsOpen(false);
    SetspecificData([]);
    setButtonDisabled(false);
    setAllDocHistory([]);
    SetsizeError("");
    setError_expiry_date("");
    SetCheckEdit(false);
  };

  const insuraceDialogClose = (e) => {
    setIsInsuraceOpen(false);
  };

  const formDialogClose = (e) => {
    setIsFormOpen(false);
  };

  const handleTabClick = (event) => {
    if (event.target.textContent === "History") {
      if (allDocHistorty?.length == 0) {
        dispatch(
          GetWorkerDocHistoryById({ document_id: specificData?.document_id })
        )
          .then((response) => {
            if (response?.success) {
              const data = response.data;
              setAllDocHistory(data);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      setAllDocHistory([]);
    }
  };

  // Documentb Edit function
  const handleEditForm = (e, customField) => {
    if (customField == "expiry_date") {
      setError_expiry_date("");
    }
    if (customField == "emptyDoc_file") {
      SetupdateData(e[0]);
    } else {
      SetupdateData(e.target.value);
    }
    SetspecificDataUpdate((prevalue) => {
      if (customField == "customField") {
        const doctype_fields_data = {
          ...prevalue.doctype_fields_data, // If it already exists in prevalue
          [e.target.name]: e.target.value, // Assuming 'name' is the key where you want to store the value
          // If you have different keys, replace 'name' with your desired key
        };
        return {
          ...prevalue,
          doctype_fields_data: doctype_fields_data,
        };
      } else if (customField == "doc_file") {
        if (e.target.files[0]?.size <= 2 * 1024 * 1024) {
          // 2MB limit
          SetsizeError("");
          return {
            ...prevalue,
            ["doc_file"]: e.target.files[0],
          };
        } else {
          SetsizeError("File size exceeds the maximum limit of 2MB.");
          return {
            ...prevalue,
            ["doc_file"]: [],
          };
        }
      } else if (customField == "emptyDoc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e[0],
        };
      } else {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      }
    });
    SetspecificData((prevalue) => {
      if (customField == "customField") {
        return {
          ...prevalue,
          customField: {
            ...prevalue.customField,
            [e.target.name]: e.target.value,
          },
        };
      } else if (customField == "doc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e.target.files[0],
        };
      } else if (customField == "emptyDoc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e[0],
        };
      } else {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      }
    });
  };

  // Update data in a state
  useEffect(() => {
    let customColumnName = "";
    if (specificDataUpdate?.document_id) {
      if (specificDataUpdate?.doctype_fields_data) {
        customColumnName = Object.keys(specificDataUpdate?.doctype_fields_data);
      }

      let columnName;
      if (customColumnName) {
        columnName = {
          ...specificDataUpdate,
          [customColumnName]: customColumnName,
        };
      } else {
        columnName = specificDataUpdate;
      }
      const columnData = Object.keys(columnName).filter(
        (key) =>
          key !== "document_id" &&
          key !== "column_names" &&
          key !== "doctype_fields_data"
      );
      SetspecificDataUpdate((prevData) => ({
        ...prevData,
        column_names: columnData,
      }));
    }
  }, [updateData]);

  // Document updatData function
  let UpdateDocData;
  if (specificDataUpdate?.doctype_fields_data) {
    UpdateDocData = {
      ...specificDataUpdate,
      ["doctype_fields_data"]: JSON.stringify(
        specificDataUpdate.doctype_fields_data
      ),
    };
  } else {
    UpdateDocData = specificDataUpdate;
  }
  // Function to check for empty columns
  const checkEmptyColumns = (data) => {
    const emptyColumns = [];

    // Iterate over column names and check if each column is empty
    //  data[column]?.trim() === ""
    data.column_names.forEach((column) => {
      if (!data[column]) {
        emptyColumns.push(column);
      }
    });
    return emptyColumns;
  };

  const updatData = () => {
    // Usage
    // SetUpdateError((prevalue)=>!prevalue)
    let checkAllColumn = true;
    const emptyColumns = checkEmptyColumns(UpdateDocData);
    SetCheckError(emptyColumns);
    if (emptyColumns.length > 0) {
      checkAllColumn = false;
    } else {
      checkAllColumn = true;
    }

    let check_expiry_date = true;
    let check_sizeError = true;
    if (sizeError === "" || UpdateDocData?.doc_file?.size == undefined) {
      check_sizeError = true;
    } else {
      check_sizeError = false;
    }
    if (UpdateDocData.expiry_date == "") {
      check_expiry_date = false;
      setError_expiry_date("This filed is required!");
    } else {
      check_expiry_date = true;
    }

    if (check_sizeError) {
      if (UpdateDocData !== undefined && checkAllColumn && check_expiry_date) {
        SetsizeError("");
        setButtonDisabled(true);
        dispatch(UpdateWorkerDoc(UpdateDocData))
          .then((response) => {
            if (response.success) {
              SetCheckEdit(false);
              setButtonDisabled(false);
              setUpdateSuccess(true);
              props?.GetAllDocsOfWorkers();
              SetspecificDataUpdate({
                document_id: specificData?.document_id,
                column_names: [""],
              });
              props.refetch(true);
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
              setButtonDisabled(false);
            }
          })
          .catch((error) => {
            setButtonDisabled(false);
          });
      }
    } else {
      SetsizeError("File size exceeds the maximum limit of 2MB.");
    }
  };

  const onDeleteDialogClose = () => {
    setDeleteDiolog(false);
  };

  // const openDeleteHandle = (item, worker_doc_approval_id, innerTab) => {
  //   let singleItem = item.contr_doc;
  //   if (innerTab) {
  //     setDeleteData({ worker_doc_approval_id: worker_doc_approval_id });
  //   } else {
  //     if (singleItem.is_swms_doc) {
  //       setDeleteData({
  //         doc_type: "swms",
  //         document_id: singleItem.document_id,
  //         document_swm_type_id:
  //           singleItem.swm_doc_type.document_swm_type_id,
  //         swm_type_id: singleItem?.swm_doc_type?.swm_type?.swm_type_id,
  //         worker_doc_approval_id: "",
  //       });
  //     } else {
  //       setDeleteData({
  //         doc_type: singleItem.document_type.document_type,
  //         document_id: singleItem.document_id,
  //         worker_doc_approval_id: "",
  //       });
  //     }
  //   }

  //   setDeleteDiolog(true);
  // };

  const deleteModuleConfirmation = () => {
    dispatch(DeleteWorkerDoc(deleteData)).then((response) => {
      if (response.success) {
        setDeleteDiolog(false);
        props.refetch(!props.refetchData);
      }
    });
  };
  let docName;
  if (specificData?.doc_file) {
    if (specificData?.doc_file.name) {
      docName = specificData?.doc_file.name;
    } else {
      docName = specificData?.doc_file.split("-");
      const partAfterThirdHyphen = docName.slice(4).join("-");
      // Decode the URL component to get the actual data
      docName = decodeURIComponent(partAfterThirdHyphen);
    }
  } else {
    docName = "";
  }

  const fileInputRef = useRef(null);

  const UploadDoc = () => {
    // Trigger click event on file input
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (specificData?.doc_file?.name) {
      setCheckPdfFile(specificData?.doc_file?.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        setPdfFile(base64String);
      };
      reader.readAsDataURL(specificData?.doc_file);
    } else {
      if (specificData?.doc_file) {
        setCheckPdfFile(specificData?.doc_file);
        setPdfFile(specificData?.doc_file);
      }
    }
  }, [specificData?.doc_file]);

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className={"w-6/12"}>
                <span
                  className="flex items-center gap-1 "
                  onClick={() => props.handleSort("doc_name")}
                >
                  Document name
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.sort !== "" &&
                      props.getAllinvitedData.sort === "doc_name" &&
                      props.getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-60">Type</Th>
              <Th className="w-80">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("expiry_date")}
                >
                  Expiry date
                  <svg
                    className={`transform ${
                      props.getAllinvitedData?.sort === "expiry_date" &&
                      props.getAllinvitedData?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-28"> </Th>
            </Tr>
          </THead>

          <TBody>
            {getData?.map((items, index) => {
              return (
                <React.Fragment>
                  <Tr key={index}>
                    <Td>
                      <Button
                        onClick={() => openDialog(items)}
                        className={"bg-gray-50 rounded-full h-6 p-0.5 mr-2"}
                      >
                        {items?.doc_name}
                      </Button>
                    </Td>
                    <Td>{items?.doc_type_name}</Td>

                    <Td>
                      {items.expiry_date !== null
                        ? moment(items.expiry_date).format("DD/MM/YYYY")
                        : "-"}
                    </Td>
                    <Td className="flex gap-4">
                      <Button onClick={() => openDeleteDailog(items)}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                      <Button
                        // onClick={() => handleEditClick(items)}
                        onClick={() => openDialog(items)}
                      >
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                            stroke="#667085"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </Td>
                  </Tr>
                </React.Fragment>
              );
            })}
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/4">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList
                className="bg-gray-500 rounded-lg p-1"
                onClick={(val) => handleTabClick(val)}
              >
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="h-cal-full ">
                <Scrollbar>
                  <div className="">
                    <TabContent value="tab1">
                      <div className="mb-4">
                        <div className="font-bold text-white text-xl mb-1 flex justify-between">
                          <span></span>
                          {/* {specificData?.doc_name} */}
                          <div className="mb-4 mt-4">
                            {checkEdit ? (
                              <React.Fragment>
                                <ClientInput
                                  name={"doc_name"}
                                  inputchangehandler={handleEditForm}
                                  value={specificData?.doc_name}
                                  className="mt-2 w-full h-10 select-options"
                                />
                                {checkError.includes("doc_name") && (
                                  <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                    {error}
                                  </p>
                                )}
                              </React.Fragment>
                            ) : (
                              <p className="text-white font-semibold">
                                {specificData?.doc_name}
                              </p>
                            )}
                          </div>
                          <Button
                            onClick={() => SetCheckEdit(true)}
                            className="ml-auto"
                          >
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </div>
                        {specificData && (
                          <label className="text-gray-100">
                            {moment(specificData?.created_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            at{" "}
                            {moment(specificData?.created_date).format("HH:mm")}
                          </label>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Document type
                        </label>
                        <p className="text-white font-semibold">
                          {specificData?.doc_type_name}
                        </p>
                      </div>

                      {checkAllData?.req_issuer && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Issuing authority
                          </label>
                          {checkEdit ? (
                            <React.Fragment>
                              <ClientInput
                                name={"issuing_authority"}
                                inputchangehandler={handleEditForm}
                                value={specificData?.issuing_authority}
                                className="mt-2 w-full h-10 select-options"
                              />
                              {checkError.includes("issuing_authority") && (
                                <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                  {error}
                                </p>
                              )}
                            </React.Fragment>
                          ) : (
                            <p className="text-white font-semibold">
                              {specificData?.issuing_authority}
                            </p>
                          )}
                        </div>
                      )}

                      {/* <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Issue date
                        </label>
                        {checkEdit ? (
                          <ClientInput
                            type="date"
                            name={"expiry_date"}
                            inputchangehandler={handleEditForm}
                            value={moment(specificData?.issue_date).format(
                              "DD/MM/YYYY"
                            )}
                            className="mt-2 w-full h-10 select-options"
                          />
                        ) : (
                          <p className="text-white font-semibold">
                            {moment(specificData?.expiry_date).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                        )}
                      </div> */}

                      {/* <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Clients
                        </label>
                        <p className="text-white font-semibold">
                          {clientOrgData
                            ?.map((innerData) => innerData?.trading_name)
                            .join(", ")}
                        </p>
                      </div> */}
                      {checkAllData?.req_policy_no && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Policy number
                          </label>
                          {checkEdit ? (
                            <React.Fragment>
                              <ClientInput
                                name={"policy_no"}
                                inputchangehandler={handleEditForm}
                                value={specificData?.policy_no}
                                className="mt-2 w-full h-10 select-options"
                              />
                              {checkError.includes("policy_no") && (
                                <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                  {error}
                                </p>
                              )}
                            </React.Fragment>
                          ) : (
                            <p className="text-white font-semibold">
                              {specificData?.policy_no}
                            </p>
                          )}
                        </div>
                      )}
                      {checkAllData?.req_amount_insured && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Amount insured
                          </label>
                          {checkEdit ? (
                            <React.Fragment>
                              <ClientInput
                                type="number"
                                name={"insured_amount"}
                                inputchangehandler={handleEditForm}
                                value={specificData?.insured_amount}
                                className="mt-2 w-full h-10 select-options"
                              />
                              {checkError.includes("insured_amount") && (
                                <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                  {error}
                                </p>
                              )}
                            </React.Fragment>
                          ) : (
                            <p className="text-white font-semibold">
                              ${specificData?.insured_amount}
                            </p>
                          )}
                        </div>
                      )}
                      {checkAllData?.allow_expiry && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Expiry date
                          </label>
                          {checkEdit ? (
                            <React.Fragment>
                              <ClientInput
                                name={"expiry_date"}
                                type="date"
                                inputchangehandler={(e) => {
                                  handleEditForm(e, "expiry_date");
                                }}
                                value={moment(specificData?.expiry_date).format(
                                  "YYYY-MM-DD"
                                )}
                                // value={moment(specificData?.expiry_date).format("YYYY-MM-DD")}
                                className="mt-2 w-full h-10 select-options"
                              />
                              {error_expiry_date && (
                                <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                  {error_expiry_date}
                                </p>
                              )}
                            </React.Fragment>
                          ) : (
                            <p className="text-white font-semibold">
                              {moment(specificData?.expiry_date).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          )}
                        </div>
                      )}
                      {checkAllData?.req_expiry_date_notes && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Additional info
                          </label>
                          {checkEdit ? (
                            <React.Fragment>
                              <ClientInput
                                name={"document_desc"}
                                inputchangehandler={handleEditForm}
                                value={specificData?.document_desc}
                                className="mt-2 w-full h-10 select-options"
                              />
                              {checkError.includes("document_desc") && (
                                <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                  {error}
                                </p>
                              )}
                            </React.Fragment>
                          ) : (
                            <p className="text-white font-semibold">
                              {specificData?.document_desc}
                            </p>
                          )}
                        </div>
                      )}
                      {specificData?.customField &&
                        Object.keys(specificData?.customField)?.map(
                          (item, index) => {
                            return (
                              <div className="mb-4" key={index}>
                                <label className="text-gray-100 text-12 font-medium">
                                  {item}
                                </label>
                                {checkEdit ? (
                                  <React.Fragment>
                                    <ClientInput
                                      name={item} // Use 'item' as the name, not a hardcoded string
                                      inputchangehandler={(e) => {
                                        handleEditForm(e, "customField");
                                      }}
                                      value={specificData?.customField[item]} // Use square brackets to access value
                                      className="mt-2 w-full h-10 select-options"
                                    />
                                    {checkError.includes(item) && (
                                      <p className="text-xs text-red-600 mb-4 mt-0 pt-5">
                                        {error}
                                      </p>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <p className="text-white font-semibold">
                                    {specificData?.customField[item]}
                                  </p>
                                )}
                              </div>
                            );
                          }
                        )}
                      {/* <hr className="border-t border-gray-400 my-5" /> */}
                      {/* <div className="font-bold text-white text-xl mb-4 ">
                        Validation
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium mb-2 block">
                          Message
                        </label>
                        <textarea
                          id=""
                          name=""
                          className="w-full"
                          placeholder="Enter a message..."
                        ></textarea>
                        <p className="text-gray-100 text-12">
                          Provide a message about the document, if necessary.
                        </p>
                      </div> */}

                      {checkEdit && (
                        <React.Fragment>
                          <div className="mb-5">
                            <label className="font-medium text-white">
                              Document
                            </label>
                            <div>
                              {/* {sizeError && (
                            <div className="text-red-500 text-sm mt-1">
                              {sizeError}
                            </div>
                          )}
                          {checkReqiuredField &&
                            documentFormData.doc_file == "" && (
                              <div className="text-red-500 text-sm mt-1 ">
                                {error}
                              </div>
                            )} */}
                              <div className="flex items-center justify-between">
                                {!specificData?.doc_file ? (
                                  <>
                                    <Upload
                                      uploadLimit={1}
                                      onChange={(e) =>
                                        handleEditForm(e, "emptyDoc_file")
                                      }
                                      accept={".jpg, .jpeg, .png, .pdf,"}
                                      draggable
                                      className="w-full bg-white mt-2"
                                      // onFileRemove={handleForm}
                                    >
                                      <div className="text-center">
                                        <div className="flex items-center justify-center flex-col">
                                          <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                                                stroke="#475467"
                                                strokeWidth="1.667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span className="pt-4">
                                            <span className="text-blue-700 font-semibold mr-1">
                                              Click to upload
                                            </span>{" "}
                                            <span className="text-xs text-gray-600">
                                              {" "}
                                              or drag and drop (Max 2MB. Only
                                              PDF accepted)
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </Upload>
                                    {/* {sizeError && (
                                      <div className="text-red-500 text-sm mb-3 ">{sizeError}</div>
                                    )} */}
                                  </>
                                ) : (
                                  <React.Fragment>
                                    <div className="p-4 rounded-xl border border-gray-300 bg-white w-full flex items-center justify-between">
                                      <div className="flex  gap-4 items-center">
                                        <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                                          <img
                                            src="/assets/icons-svg/upload-pdf.svg"
                                            alt=""
                                          />
                                        </span>
                                        <div className="flex flex-col items-center">
                                          <span className="text-gray-700 text-xs font-medium">
                                            {docName}
                                          </span>
                                        </div>
                                      </div>
                                      {/* <button>
                                        <svg
                                          width="20"
                                          height="19"
                                          viewBox="0 0 24 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                            stroke="#344054"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button> */}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                          {sizeError && (
                            <div className="text-red-500 text-sm mt-1 ">
                              {sizeError}
                            </div>
                          )}
                          {checkEdit && (
                            <div className="flex justify-end gap-2">
                              {/* <Button
                                onClick={() => {
                                  SetCheckEdit(false);
                                }}
                                className="text-error-700 font-semibold bg-white h-10 w-20 rounded-lg"
                              >
                                Cancel
                              </Button> */}
                              {/* <Button
                                onClick={() => {
                                  updatData();
                                }}
                                className="text-success-700 font-semibold bg-success-50 h-10 w-20 rounded-lg"
                              >
                                Update
                              </Button> */}
                            </div>
                          )}
                          <div className="flex justify-end gap-2">
                            <Button
                              disabled={buttonDisabled}
                              onClick={() => {
                                buttonDisabled == false && SetCheckEdit(false);
                                SetsizeError("");
                              }}
                              className="text-error-700 font-semibold bg-white h-10 w-20 rounded-lg"
                            >
                              Cancel
                            </Button>
                            <input
                              type="file"
                              accept=".pdf, .jpg, .jpeg"
                              ref={fileInputRef}
                              className="hidden"
                              onChange={(e) => handleEditForm(e, "doc_file")}
                            />
                            <Button
                              disabled={buttonDisabled}
                              className="text-blue-700 font-semibold bg-blue-100 h-10 w-40 rounded-lg"
                              onClick={UploadDoc}
                            >
                              Reupload
                            </Button>
                            <Button
                              disabled={buttonDisabled}
                              className="text-white font-semibold bg-blue-700 h-10 w-40 rounded-lg"
                              onClick={() => {
                                updatData();
                              }}
                            >
                              {buttonDisabled ? (
                                <ThreeDotLoader />
                              ) : (
                                "Save Document"
                              )}
                            </Button>
                          </div>
                        </React.Fragment>
                      )}
                    </TabContent>
                    <TabContent value="tab2">
                      {allDocHistorty?.map((item, index) =>
                        item.desc_html.map((innerItem, index) => {
                          let documetnName;
                          if (item?.new_value.doc_file) {
                            documetnName = item?.new_value?.doc_file.split("-");
                            const partAfterThirdHyphen = documetnName
                              .slice(4)
                              .join("-");
                            // Decode the URL component to get the actual data
                            documetnName =
                              decodeURIComponent(partAfterThirdHyphen);
                          } else {
                            documetnName = "";
                          }
                          let parser = new DOMParser();
                          let doc = parser.parseFromString(
                            innerItem,
                            "text/html"
                          );
                          return (
                            <React.Fragment>
                              {item?.column_names?.includes("doc_file") ? (
                                <div className="mb-4  pb-4 mt-4">
                                  <label className="text-gray-100">
                                    {moment(item?.updated_date).format(
                                      "DD MMMM YYYY [at] HH:mm"
                                    )}
                                  </label>
                                  <p className="font-semibold text-white mt-2">
                                    {doc.body.textContent}
                                  </p>
                                  <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                    <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                                          stroke="#5786CC"
                                          strokeWidth="1.333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <div className="ml-3">
                                      <span className="font-medium text-gray-700 text-xs">
                                        {documetnName}
                                      </span>
                                      <p className="text-gray-600 text-xs">
                                        {(
                                          item?.new_value?.file_size / 1024
                                        ).toFixed(2)}{" "}
                                        KB
                                      </p>
                                    </div>
                                    <Button className="ml-auto">
                                      <a
                                        href={item?.new_value.doc_file}
                                        download
                                      >
                                        <svg
                                          width="20"
                                          height="21"
                                          viewBox="0 0 20 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                                            stroke="#475467"
                                            strokeWidth="1.667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </a>
                                    </Button>
                                  </div>
                                </div>
                              ) : item?.column_names?.includes("message") ? (
                                <div className="mb-4  pb-4">
                                  <label className="text-gray-100">
                                    {moment(item?.updated_date).format(
                                      "DD MMMM YYYY [at] HH:mm"
                                    )}
                                  </label>
                                  <p className="font-semibold text-white mt-2">
                                    {doc.body.textContent}
                                  </p>
                                  {item.new_value.message && (
                                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                      {/* <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                      </span> */}
                                      <div className="ml-3">
                                        <span className="font-medium text-gray-700 text-xs">
                                          {item.new_value.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className="mb-4 border-b border-gray-500 pb-4 mt-4"
                                >
                                  <label className="text-gray-100">
                                    {moment(item?.updated_date).format(
                                      "DD MMMM YYYY [at] HH:mm"
                                    )}
                                  </label>
                                  <p className="font-semibold text-white mt-2">
                                    {doc.body.textContent}
                                  </p>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                      {/* <div className="mb-4 border-b border-gray-500 pb-4">
                        <label className="text-gray-100">
                          26 April 2023 at 15:23
                        </label>
                        <p className="font-semibold text-white mt-2">
                          [Worker Name] has edited Amount insured
                        </p>
                        <div className=" p-4 rounded-lg bg-white font-medium mt-2">
                          Hi. The document was rejected because the insurance
                          amount does not meet Client’s requirements. Please
                          re-upload.
                        </div>
                      </div>
                      <div className="mb-4  pb-4">
                        <label className="text-gray-100">
                          26 April 2023 at 15:23
                        </label>
                        <p className="font-semibold text-white mt-2">
                          [Worker Name] has edited Amount insured
                        </p>
                        <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                          <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                                stroke="#5786CC"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <div className="ml-3">
                            <span className="font-medium text-gray-700 text-xs">
                              document name.[format]
                            </span>
                            <p className="text-gray-600 text-xs">200 KB</p>
                          </div>
                          <Button className="ml-auto">
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                                stroke="#475467"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </div>
                      </div> */}
                    </TabContent>
                  </div>
                </Scrollbar>
              </div>
            </Tabs>
          </div>
          {/* <div className="right-block-wrap h-full w-4/5"> */}
          {/* <div className="doc-header flex items-center justify-center">
              <div className="flex justify-content-center">
                <Button className="bg-white h-10 w-11 text-xl bl-rds">-</Button>
                <span className="font-semibold text-12 text-gray-700 bg-white w-16 flex items-center justify-center text-center h-10 border-r border-l">
                  100%
                </span>
                <Button className="bg-white h-10 w-11 text-xl br-rds">+</Button>
              </div>
              <div className="flex gap-6 rightSidebtn">
                <Button className="flex text-white gap-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#zvmr0yey3a)">
                      <path
                        d="m10.835 18.333-2.5-2.5m0 0 2.5-2.5m-2.5 2.5H12.5a5.833 5.833 0 0 0 2.5-11.105m-10 10.544a5.834 5.834 0 0 1 2.5-11.105h4.167m0 0-2.5-2.5m2.5 2.5-2.5 2.5"
                        stroke="#fff"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="zvmr0yey3a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.588 15.303 9.41 16.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L9.41 4.697m-2.328 8.22 5.834-5.834"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div> */}
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-view pdf_view_scroll">
              {checkPdfFile?.split(".")?.pop()?.split("?")[0] == "pdf" ||
              checkPdfFile?.doc_file?.type?.split("/")?.pop()?.toLowerCase() ==
                "pdf" ? (
                specificData &&
                specificData?.doc_file &&
                pdfFile && (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={pdfFile}
                      plugins={[defaultLayoutPluginInstance]}
                      theme="dark"
                    />
                  </Worker>
                )
              ) : (
                <React.Fragment>
                  <div className="iFrame-Header image_viewer">
                    <div className="text-white text-xs">
                      {/* {moduleData?.[indexData]?.company_ind_file?.name} */}
                    </div>
                    <div className="btn-group">
                      <button
                        onClick={() => handleZoomIn()}
                        className="bg-white"
                      >
                        -
                      </button>
                      <button
                        onClick={() => handleZoomOut()}
                        className="bg-white"
                      >
                        +
                      </button>
                    </div>
                    <div className="flex gap-3">
                      <button onClick={() => handleDownload()}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button onClick={() => handlePrint()}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    className="image-container"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                    <img
                      style={{
                        scale: `${zoom}% `,
                        transform: `translate(${position.x}px, ${position.y}px)`,
                      }}
                      src={pdfFile}
                      alt="Image"
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </Dialog>

      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete document?
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={() => setDeleteDiolog(false)}>
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            onClick={() => deleteModuleConfirmation()}
            // onClick={() => setDeleteDiolog(false)}
          >
            Delete
          </BtnPrimary>
        </div>
      </Dialog>

      {/* Edit Document Dialog Box */}
      <Dialog
        isOpen={editDialogIsOpen}
        onClose={onDialogCloseEdit}
        onRequestClose={onDialogCloseEdit}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Edit Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Select document*
            </label>
            <div className="w-3/4 pl-2">
              <Select
                className="w-full h-10 select-options"
                placeholder="Select Validation Criteria"
                // options={docTypeOptions}
                value={docTypeOptions?.filter((item) => item?.value == docType)}
                // onChange={handleForm}
                readOnly={true}
              ></Select>
              {/* {checkReqiuredField &&
                documentFormData?.is_doctype_swms == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
            </div>
          </div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Document name*
            </label>
            <div className="w-3/4 pl-2">
              <ClientInput
                name={"doc_name"}
                inputchangehandler={(e) => handleForm(e, "doc_name")}
                className="w-full h-10 select-options"
              />
              {checkReqiuredField && documentFormData.doc_name == "" && (
                <div className="text-red-500 text-sm mt-1 ">{error}</div>
              )}
            </div>
          </div>
          {/* Expire Date */}
          {specificDocData?.allow_expiry && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Expiry date*
              </label>
              <div className="w-3/4 pl-2 flex">
                <div className="w-full relative">
                  <span className="adon_icon absolute left-4 top-3">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#0ckeddmh9a)">
                        <path
                          d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z"
                          stroke="#344054"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="0ckeddmh9a">
                          <path
                            fill="#fff"
                            transform="translate(0 .958)"
                            d="M0 0h20v20H0z"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <DatePicker
                    onChange={(e) => handleForm(e, "expiry_date")}
                    placeholder="Select expire date"
                    minDate={minDate}
                  />
                  {checkReqiuredField && documentFormData.expiry_date == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Amout Insured */}
          {specificDocData?.req_amount_insured && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Amount insured
              </label>
              <div className="w-3/4 pl-2 flex">
                <div className="w-full relative">
                  <span className="adon_icon absolute left-2 top-10px">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13.789a3.333 3.333 0 0 0 3.333 3.333h3.334a3.333 3.333 0 0 0 0-6.667H8.333a3.333 3.333 0 1 1 0-6.666h3.334A3.333 3.333 0 0 1 15 7.122m-5-5V18.79"
                        stroke="#667085"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <div className="w-full">
                    <ClientInput
                      type={"number"}
                      placeholder="Enter value here"
                      name={"amount_insured"}
                      inputchangehandler={(e) =>
                        handleForm(e, "amount_insured")
                      }
                      className="w-full h-10 select-options pl-8"
                    />
                    {checkReqiuredField &&
                      documentFormData.insured_amount == "" && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {error}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Policy number */}
          {specificDocData?.req_policy_no && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Policy number
              </label>
              <div className="w-3/4 pl-2">
                <ClientInput
                  name={"policy_no"}
                  inputchangehandler={(e) => handleForm(e, "policy_no")}
                  className="w-full h-10 select-options"
                />
                {/* {checkReqiuredField && documentFormData.policy_no == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
              </div>
            </div>
          )}

          {/* Issuing authority */}
          {specificDocData?.req_issuer && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Issuing authority
              </label>
              <div className="w-3/4 pl-2">
                <ClientInput
                  name={"issuer"}
                  inputchangehandler={(e) => handleForm(e, "issuer")}
                  className="w-full h-10 select-options"
                />
                {checkReqiuredField &&
                  documentFormData.issuing_authority == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )}
              </div>
            </div>
          )}

          {/* Custom Field */}
          {specificDocData?.custom_fields?.map((item, index) => {
            return (
              <div className="flex mb-5" key={index}>
                <label className="font-medium text-gray-700 w-1/4">
                  {item}
                </label>
                <div className="w-3/4 pl-2">
                  <ClientInput
                    name={item}
                    inputchangehandler={(e) =>
                      handleForm(e, item, "doctype_fields_data")
                    }
                    className="w-full h-10 select-options"
                  />
                  {/* {checkReqiuredField &&
                  documentFormData.issuing_authority == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )} */}
                </div>
              </div>
            );
          })}

          {/* Additional info (optional) */}
          {specificDocData?.req_expiry_date_notes && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Additional info
              </label>
              <div className="w-3/4 pl-2">
                <textarea
                  name={"expiry_date_notes"}
                  onChange={(e) => handleForm(e, "expiry_date_notes")}
                  rows={4}
                  cols={40}
                  placeholder="Provide a brief description to help Inductees..."
                  className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline h-10"
                />
                {wordError && (
                  <div className="text-red-500 text-sm mt-1 ">{wordError}</div>
                )}
              </div>
            </div>
          )}
          <div className="flex mb-5 border-t mt-5 pt-5">
            <label className="font-medium text-gray-700 w-1/4">Upload</label>
            <div className="w-3/4 pl-2">
              <Upload
                uploadLimit={1}
                onChange={(e) => handleForm(e, null, null, "add")}
                accept=".pdf, .jpg, .jpeg"
                draggable
                className="w-full"
                onFileRemove={(e) => handleForm(e, null, null, "remove")}
              >
                <div className="text-center">
                  <div className="flex items-center justify-center flex-col">
                    <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                          stroke="#475467"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                    <span className="pt-4">
                      <span className="text-blue-700 font-semibold mr-1">
                        Click to upload
                      </span>{" "}
                      <span className="text-xs text-gray-600">
                        {" "}
                        (Max 2MB. Only PDF and image files will be accepted)
                      </span>
                    </span>
                  </div>
                </div>
              </Upload>
              {(sizeError ||
                (addDocError &&
                  (documentFormData?.doc_file === "" ||
                    documentFormData?.doc_file === undefined ||
                    (Array.isArray(documentFormData?.doc_file) &&
                      documentFormData.doc_file.length === 0)))) && (
                <div className="text-red-500 text-sm mt-1">
                  {sizeError || error}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            disabled={buttonDisabled}
            className="mr-1.5"
            variant="plain"
            onClick={onDialogCloseEdit}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            //  onClick={() => handleUpdate()}
            className="ml-1.5"
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Update"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={updateSuccessOpen}
        onClose={onCloseUpdateSucess}
        onRequestClose={onCloseUpdateSucess}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Document updated successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onCloseUpdateSucess}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default DocumentsList;
