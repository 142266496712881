import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetUserOrganisationsList = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/provider/GetUserOrganisationsList`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};
