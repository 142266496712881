import React, { useState, useCallback, useEffect, useRef } from "react";
import Heading from "../../../../common/element/Heading";
import Segment from "../../../../common/element/Segment";
import Table from "../../../../common/element/Table";
import Badge from "../../../../common/element/Badge";
import Tag from "../../../../common/element/Tag";
import Scrollbar from "../../../../common/element/ScrollBar";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import ConnectClient from "../../../providerPortal/pages/Registration/ConnectClient";
import Dialog from "../../../../common/element/Dialog";
import Progress from "../../../../common/element/Progress/Progress";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { UpdateCheckExistUser } from "../../../../redux/classes/provider-portal/checkExistUser";
import moment from "moment";
import { useParams, Link, useNavigate } from "react-router-dom";
import SearchImg from "../../../../assets/icons-svg/search-lg.svg";
import ClientInput from "../../../../common/element/Input/clientInput";
import useCallbackRef from "../../../../common/element/hooks/useCallbackRef";
import Tooltip from "../../../../common/element/Tooltip";
import { debounce } from "../../../../common/snippet/Debouncing";
// import {
//   MarkTaskAsRead
// } from "../../../../actions/Admin";
import { MarkTaskAsRead } from "../../../../actions/provider-portal/MarkTaskAsRead";
import { UpdateTaskArchiveStatus } from "../../../../actions/provider-portal/UpdateTaskArchiveStatus";
import { GetAllDeshboardTasks } from "../../../../actions/provider-portal/GetAllDeshboardTasks";
import { GetClientDashboardDueInvoicesByDays } from "../../../../actions/provider-portal/invoices/GetClientDashboardDueInvoicesByDays";
const Dashboard = () => {
  const navigate = useNavigate();
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const { ProviderExistingUser } = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { ProviderContactData } = useSelector((state) => state);
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([{ label: "Dashboard", url: "/provider/dashboard" }])
    );
  }, []);
  const { Tr, Th, Td, THead, TBody } = Table;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [invoiceData, SetInvoiceData] = useState(false);

  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  const conserve_authtoken = localStorage.getItem("conserve_authtoken");
  const userId = loginData?.userName?.user_id || loginData?.data?.user_id;
  const userRole = "provider";
  const organisation_id = loginData?.organisation_id;
  const [totalTastCount, setTotalTaskCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const scrollContainerRef = useRef(null);
  const [scrollThreshold, setScrollThreshold] = useState(23);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["Inbox"]);

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
    },
    [setSingleSegmentValue]
  );

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  const startOfWeek = moment().startOf("week");
  const startOfMonth = moment().startOf("month");
  const startOfYear = moment().startOf("year");
  const groupedNotifications = notificationList?.reduce(
    (acc, notification) => {
      const createdDate = moment(notification?.created_date);
      if (createdDate.isSame(today, "d")) {
        acc.today.push(notification);
      } else if (createdDate.isSame(yesterday, "d")) {
        acc.yesterday.push(notification);
      } else if (createdDate.isSameOrAfter(startOfWeek)) {
        acc.thisWeek.push(notification);
      } else if (createdDate.isSameOrAfter(startOfMonth)) {
        acc.thisMonth.push(notification);
      } else if (createdDate.isSameOrAfter(startOfYear)) {
        acc.thisYear.push(notification);
      } else {
        acc.earlier.push(notification);
      }
      return acc;
    },
    {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      thisYear: [],
      earlier: [],
    }
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (
        scrollContainer &&
        scrollContainer.scrollTop >= scrollThreshold &&
        !isFetching &&
        notificationList?.length < totalCount
      ) {
        setLimit((prevLimit) => prevLimit + 10);
        setScrollThreshold((prevThreshold) => prevThreshold + 150);
      }
    };
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching, scrollThreshold, totalCount, notificationList]);
  const GetTasksNotifications = useCallback(() => {
    // setIsFetching(true);
    const payload = {
      limit: limit,
      role_name: userRole,
      user_id: userId,
      is_archived: singleSegmentValue[0] == "Inbox" ? false : true,
      organisation_id: organisation_id,
      search: searchTerm,
    };
    dispatch(GetAllDeshboardTasks(payload)).then((res) => {
      // setIsFetching(false);
      if (res?.success === true) {
        const arrayData = res?.data.rows;
        setNotificationList(arrayData);
        setTotalTaskCount(res?.data?.unreadCount);
        setTotalCount(res?.data?.count);
      }
    });
  }, [dispatch, singleSegmentValue, searchTerm, limit]);
  useEffect(() => {
    GetTasksNotifications();
  }, [singleSegmentValue, searchTerm, limit]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };
  const inputchangehandler = useCallbackRef(debounce(handleSearch), []);

  const archiveTask = (notify) => {
    const payload = {
      role_name: userRole,
      notification_id: notify.notification_id,
      is_archived: true,
      user_id: userId,
    };
    dispatch(UpdateTaskArchiveStatus(payload)).then((res) => {
      if (res?.success === true) {
        GetTasksNotifications();
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  const onMarkAsRead = (notifyId) => {
    const payload = {
      role_name: userRole,
      notification_id: notifyId,
      user_id: userId,
      task_read: true,
    };
    dispatch(MarkTaskAsRead(payload)).then((res) => {
      if (res?.success === true) {
        setTotalTaskCount(totalTastCount - 1); // Fixed typo: 'totalTastCount' -> 'totalTaskCount'
        setNotificationList((prevList) => {
          return prevList.map((item) => {
            if (item?.notification_id === notifyId) {
              return { ...item, task_read: true };
            }
            return item;
          });
        });
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  const { checkExistUser } = useSelector((state) => state);
  // checkExistUser.checkAcceptUser

  useEffect(() => {
    if (checkExistUser.checkAcceptUser) {
      setDialogIsOpen(true);
    }
  }, [checkExistUser]);

  const onDialogClose = () => {
    if (checkExistUser.checkAcceptUser) {
      dispatch(
        UpdateCheckExistUser({
          checkUser: false,
          path: "",
          login: false,
          checkAcceptUser: false,
        })
      );
    }
    setDialogIsOpen(false);
  };

  useEffect(() => {
    dispatch(
      GetClientDashboardDueInvoicesByDays({
        provider_org_id: storedData?.organisation_id,
      })
    ).then((response) => {
      if (response.success) {
        SetInvoiceData(response.data);
      }
    });
  }, []);
  return (
    <React.Fragment>
      <Heading className="mb-4">Dashboard</Heading>
      <div className="grid grid-container gap-8">
        <div className="flex gap-8 flex-col">
          <div className="bg-white py-6 border border-gray-200 rounded-xl dark-shadow">
            <div className="px-6">
              <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
                <div className="flex items-center">
                  <span className="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13 11H17.8C18.9201 11 19.4802 11 19.908 11.218C20.2843 11.4097 20.5903 11.7157 20.782 12.092C21 12.5198 21 13.0799 21 14.2V21M13 21V6.2C13 5.0799 13 4.51984 12.782 4.09202C12.5903 3.71569 12.2843 3.40973 11.908 3.21799C11.4802 3 10.9201 3 9.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.0799 3 6.2V21M22 21H2M6.5 7H9.5M6.5 11H9.5M6.5 15H9.5"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <p></p>
                  <div className="flex flex-col">
                    <span className="text-gray-900 text-base font-semibold">
                      Connection with Clients
                    </span>
                    <p className="text-gray-600">
                      Complete onboarding with clients
                    </p>
                  </div>
                </div>
                <BtnLight className="w-20 h-9">View All</BtnLight>
              </div>
            </div>
            <div className="h-56">
              <Scrollbar>
                <div className="px-6">
                  <div className="flex w-full border-b border-gray-200 mb-5 pb-5">
                    <span className="w-2/6">Client name</span>
                    <div className="w-4/6">
                      <Progress percent={60} />
                    </div>
                  </div>
                  <div className="flex w-full border-b border-gray-200 mb-5 pb-5">
                    <span className="w-2/6">Client name</span>
                    <div className="w-4/6">
                      <Progress percent={40} />
                    </div>
                  </div>
                  <div className="flex w-full border-b border-gray-200 mb-5 pb-5">
                    <span className="w-2/6">Client name</span>
                    <div className="w-4/6">
                      <Progress percent={90} />
                    </div>
                  </div>
                  <div className="flex w-full border-b border-gray-200 mb-5 pb-5">
                    <span className="w-2/6">Client name</span>
                    <div className="w-4/6">
                      <Progress percent={20} />
                    </div>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </div>

          <div className="bg-white py-6 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 px-6 mb-3">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 15L8 17L12.5 12.5M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Task{" "}
                <Tag className="text-blue-800 bg-blue-100 ml-2">
                  {totalTastCount}
                </Tag>
              </span>
              {/* <BtnLight className="w-20 h-9">View All</BtnLight> */}
            </div>
            <div className="flex justify-between mb-3 px-4">
              <Segment
                className="docType-btn"
                value={singleSegmentValue[0] == "Inbox" ? "Inbox" : "Archive"}
                onChange={(val) => onSingleSelectionSegmentChange(val)}
              >
                <Segment.Item value="Inbox">Inbox</Segment.Item>
                <Segment.Item value="Archive">Archive</Segment.Item>
              </Segment>
              <div className="w-80 h-10">
                <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5">
                    <img src={SearchImg} alt="" className="h-5" />
                  </div>
                  <ClientInput
                    type="text"
                    className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    inputchangehandler={inputchangehandler}
                    name={"search"}
                  />
                </div>
              </div>
            </div>
            <div
              ref={scrollContainerRef}
              className="notification-list-container overflow-y-auto h-[400px]"
            >
              <Table className="min-w-full">
                <THead>
                  <Tr>
                    <Th className="pl-6 w-1/2	">Notification</Th>
                    <Th>Timestamp</Th>
                    <Th className="w-84">Company</Th>
                    {singleSegmentValue[0] == "Inbox" && (
                      <Th className="">Action</Th>
                    )}
                  </Tr>
                </THead>
                <tbody className={`overflow-y-auto notification-list `}>
                  {loading ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        Loading...
                      </td>
                    </tr>
                  ) : notificationList.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        No Data Available!
                      </td>
                    </tr>
                  ) : (
                    <>
                      {groupedNotifications.today.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              Today
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.today.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}

                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {" "}
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.yesterday.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              Yesterday
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.yesterday.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisWeek.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This week
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisWeek.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisMonth.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This month
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisMonth.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisYear.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This year
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisYear.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
                {isFetching && (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-600"></div>
                  </div>
                )}
              </Table>
            </div>
          </div>
        </div>
        <div className="flex gap-8 flex-col">
          <div className="bg-white p-6 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M18.818 4.84647L18.818 4.84648C19.2151 4.91279 19.5063 5.2563 19.5063 5.65874V18.6823C19.5063 19.1371 19.1378 19.5058 18.6828 19.5058H12.8945C12.4399 19.5058 12.0711 19.1367 12.0711 18.6823C12.0711 18.2278 12.4399 17.8587 12.8945 17.8587H17.8595L17.8594 6.35648L10.8239 5.18397V18.6823C10.8239 19.1367 10.4552 19.5058 10.0004 19.5058C9.54578 19.5058 9.17711 19.1367 9.17711 18.6823V4.21176C9.17711 3.96961 9.28347 3.73994 9.46818 3.58353C9.65279 3.42699 9.89717 3.3597 10.1357 3.39948C10.1357 3.39948 10.1357 3.39948 10.1357 3.39948L18.818 4.84647Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M12.0716 15.0647C12.0716 14.6098 12.4401 14.2413 12.895 14.2413H15.7891C16.2442 14.2413 16.6127 14.6098 16.6127 15.0647V18.6824C16.6127 19.1373 16.2442 19.5059 15.7891 19.5059H12.895C12.44 19.5059 12.0716 19.1373 12.0716 18.6824V15.0647ZM13.7186 17.8589H14.9657V15.8883H13.7186V17.8589Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M3.38895 15.0647C3.38895 14.6098 3.75758 14.2413 4.21251 14.2413H7.10664C7.56159 14.2413 7.93008 14.6098 7.93008 15.0647V18.6824C7.93008 19.1373 7.56162 19.5059 7.10664 19.5059H4.21251C3.75756 19.5059 3.38895 19.1373 3.38895 18.6824V15.0647ZM5.0361 17.8589H6.28309V15.8883H5.0361V17.8589Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M1.45411 0.505331L1.45421 0.505348L10.1365 1.95258C10.5335 2.01877 10.8246 2.36218 10.8246 2.76485V18.6824C10.8246 19.1372 10.4563 19.5058 10.0012 19.5058H4.21291C3.75828 19.5058 3.38959 19.1367 3.38959 18.6824C3.38959 18.2281 3.75828 17.8589 4.21291 17.8589H9.17783L9.1779 3.46259L2.14234 2.28983V18.6824C2.14234 19.1367 1.77365 19.5059 1.31903 19.5059C0.864286 19.5059 0.495591 19.1367 0.495591 18.6824V1.31762C0.495591 1.07549 0.602156 0.846099 0.786666 0.68964C0.971354 0.532789 1.21573 0.465851 1.45411 0.505331Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M14.9657 11.0854C14.9657 10.8305 15.1725 10.6236 15.4273 10.6236H16.1509C16.4059 10.6236 16.6127 10.8304 16.6127 11.0854V11.809C16.6127 12.0638 16.4059 12.2708 16.1509 12.2708H15.4273C15.1725 12.2708 14.9657 12.0638 14.9657 11.809V11.0854Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M12.0711 11.0854C12.0711 10.8305 12.278 10.6236 12.5329 10.6236H13.2564C13.5113 10.6236 13.7182 10.8304 13.7182 11.0854V11.809C13.7182 12.0638 13.5114 12.2708 13.2564 12.2708H12.5329C12.2779 12.2708 12.0711 12.0638 12.0711 11.809V11.0854Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M12.0711 8.19138C12.0711 7.93656 12.2779 7.72959 12.5329 7.72959H13.2564C13.5114 7.72959 13.7182 7.93654 13.7182 8.19138V8.91494C13.7182 9.16977 13.5114 9.37673 13.2564 9.37673H12.5329C12.278 9.37673 12.0711 9.16978 12.0711 8.91494V8.19138Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M14.9657 8.19138C14.9657 7.93658 15.1724 7.72959 15.4273 7.72959H16.1509C16.4059 7.72959 16.6127 7.93654 16.6127 8.19138V8.91494C16.6127 9.16977 16.4059 9.37673 16.1509 9.37673H15.4273C15.1725 9.37673 14.9657 9.16976 14.9657 8.91494V8.19138Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M6.28306 11.0854C6.28306 10.8305 6.48987 10.6236 6.74485 10.6236H7.46841C7.72328 10.6236 7.93008 10.8305 7.93008 11.0854V11.809C7.93008 12.0638 7.72331 12.2708 7.46841 12.2708H6.74485C6.48986 12.2708 6.28306 12.0638 6.28306 11.809V11.0854Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M3.3884 11.0854C3.3884 10.8305 3.59521 10.6236 3.8502 10.6236H4.57375C4.82858 10.6236 5.03555 10.8304 5.03555 11.0854V11.809C5.03555 12.0638 4.82861 12.2708 4.57375 12.2708H3.8502C3.59521 12.2708 3.3884 12.0638 3.3884 11.809V11.0854Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M3.3884 8.19138C3.3884 7.93656 3.59519 7.72959 3.8502 7.72959H4.57375C4.8286 7.72959 5.03555 7.93652 5.03555 8.19138V8.91494C5.03555 9.16979 4.82861 9.37673 4.57375 9.37673H3.8502C3.59521 9.37673 3.3884 9.16978 3.3884 8.91494V8.19138Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M6.28306 8.19138C6.28306 7.93656 6.48985 7.72959 6.74485 7.72959H7.46841C7.7233 7.72959 7.93008 7.93657 7.93008 8.19138V8.91494C7.93008 9.16974 7.72331 9.37673 7.46841 9.37673H6.74485C6.48986 9.37673 6.28306 9.16978 6.28306 8.91494V8.19138Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M6.28306 5.29734C6.28306 5.04252 6.48985 4.83555 6.74485 4.83555H7.46841C7.7233 4.83555 7.93008 5.04252 7.93008 5.29734V6.0209C7.93008 6.27584 7.72329 6.48269 7.46841 6.48269H6.74485C6.48989 6.48269 6.28306 6.27588 6.28306 6.0209V5.29734Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                    <path
                      d="M3.3884 5.29734C3.3884 5.04252 3.59519 4.83555 3.8502 4.83555H4.57375C4.8286 4.83555 5.03555 5.04248 5.03555 5.29734V6.0209C5.03555 6.27588 4.82859 6.48269 4.57375 6.48269H3.8502C3.59523 6.48269 3.3884 6.27588 3.3884 6.0209V5.29734Z"
                      fill="#101828"
                      stroke="#101828"
                      stroke-width="0.2"
                    />
                  </svg>
                </span>
                Total Providers
              </span>
              <BtnLight className="w-20 h-9">View All</BtnLight>
            </div>
          </div>
          <div className="bg-white py-6 border border-gray-200 rounded-xl dark-shadow">
            <div className="px-6">
              <div className="card-header flex border-b border-gray-200 pb-5 items-center mb-5">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <div className="flex flex-col">
                  <span className="text-gray-900 text-base font-semibold">
                    Workers Inductions
                  </span>
                  <p className="text-gray-600">Pending completion</p>
                </div>
              </div>
            </div>
            <div className="h-64">
              <Scrollbar>
                <div className="px-6">
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <TBody>
                        <Tr>
                          <Td>Worker name</Td>
                          <Td>
                            Induction name{" "}
                            <span className="block text-gray-600">
                              [Client name]
                            </span>
                          </Td>
                          <Td className="w-28">
                            <Tag className="bg-gray-100 text-gray-700">
                              {" "}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <path
                                    d="M6 6.5C6.27614 6.5 6.5 6.27614 6.5 6C6.5 5.72386 6.27614 5.5 6 5.5C5.72386 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.72386 6.5 6 6.5Z"
                                    stroke="#667085"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.5 6.5C9.77614 6.5 10 6.27614 10 6C10 5.72386 9.77614 5.5 9.5 5.5C9.22386 5.5 9 5.72386 9 6C9 6.27614 9.22386 6.5 9.5 6.5Z"
                                    stroke="#667085"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M2.5 6.5C2.77614 6.5 3 6.27614 3 6C3 5.72386 2.77614 5.5 2.5 5.5C2.22386 5.5 2 5.72386 2 6C2 6.27614 2.22386 6.5 2.5 6.5Z"
                                    stroke="#667085"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              Awaiting action
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Worker name</Td>
                          <Td>
                            Induction name{" "}
                            <span className="block text-gray-600">
                              [Client name]
                            </span>
                          </Td>
                          <Td className="w-28">
                            <Tag className="bg-blue-50 text-blue-800">
                              {" "}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5886_93335)">
                                    <path
                                      d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665"
                                      stroke="#0E4E9A"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5886_93335">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Pending
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Worker name</Td>
                          <Td>
                            Induction name{" "}
                            <span className="block text-gray-600">
                              [Client name]
                            </span>
                          </Td>
                          <Td className="w-28">
                            <Tag className="bg-blue-50 text-blue-800">
                              {" "}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5886_93335)">
                                    <path
                                      d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665"
                                      stroke="#0E4E9A"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5886_93335">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Pending
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Worker name</Td>
                          <Td>
                            Induction name{" "}
                            <span className="block text-gray-600">
                              [Client name]
                            </span>
                          </Td>
                          <Td className="w-28">
                            <Tag className="bg-blue-50 text-blue-800">
                              {" "}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5886_93335)">
                                    <path
                                      d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665"
                                      stroke="#0E4E9A"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5886_93335">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Pending
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Worker name</Td>
                          <Td>
                            Induction name{" "}
                            <span className="block text-gray-600">
                              [Client name]
                            </span>
                          </Td>
                          <Td className="w-28">
                            <Tag className="bg-blue-50 text-blue-800">
                              {" "}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5886_93335)">
                                    <path
                                      d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665"
                                      stroke="#0E4E9A"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5886_93335">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Pending
                            </Tag>
                          </Td>
                        </Tr>
                      </TBody>
                    </Table>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </div>

          <div className="bg-white py-6 border border-gray-200 rounded-xl dark-shadow">
            <div className="px-6">
              <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
                <div className="flex items-center">
                  <span className="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M9.35156 14.6667C9.35156 15.9553 10.3962 17 11.6849 17H13.8516C15.2323 17 16.3516 15.8807 16.3516 14.5C16.3516 13.1193 15.2323 12 13.8516 12H11.8516C10.4709 12 9.35156 10.8807 9.35156 9.5C9.35156 8.11929 10.4709 7 11.8516 7H14.0182C15.3069 7 16.3516 8.04467 16.3516 9.33333M12.8516 5.5V7M12.8516 17V18.5M22.8516 12C22.8516 17.5228 18.3744 22 12.8516 22C7.32871 22 2.85156 17.5228 2.85156 12C2.85156 6.47715 7.32871 2 12.8516 2C18.3744 2 22.8516 6.47715 22.8516 12Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <p></p>
                  <div className="flex flex-col">
                    <span className="text-gray-900 text-base font-semibold">
                      Billing
                    </span>
                    <p className="text-gray-600">Due in the next 30 days </p>
                  </div>
                </div>
                <BtnLight
                  className="w-20"
                  onClick={() => navigate("/provider/invoices")}
                >
                  View All
                </BtnLight>
              </div>
            </div>

            <div className="h-64">
              <Scrollbar>
                <div className="px-6">
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <TBody>
                        {invoiceData?.length &&
                          invoiceData?.map((item, index) => {
                            let status;
                            if (item.xero_invoice_status === "AUTHORISED") {
                              const today = new Date();
                              today.setHours(0, 0, 0, 0); // Set time to 00:00:00
                              const invoiceSendDate = new Date(
                                item.invoice_send_date
                              );
                              invoiceSendDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                              const upcomingCreatedDate = new Date(
                                item.upcoming_created_date
                              );
                              upcomingCreatedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                              const dueDate = new Date(item.due_date);
                              dueDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
                              // Check if today's date is between the two dates

                              if (
                                item.upcoming_created_date &&
                                // today >= upcomingCreatedDate &&
                                today < invoiceSendDate
                              ) {
                                status = "UPCOMING";
                              } else if (
                                today >= invoiceSendDate &&
                                today <= dueDate
                              ) {
                                status = "DUE";
                              }
                            }
                            return (
                              <Tr>
                                <Td>{item?.xero_invoice_number}</Td>
                                <Td>
                                  {(status == "UPCOMING" ||
                                    status == "DUE") && (
                                    <Tag className="bg-warning-50 text-warning-700">
                                      <span>
                                        <svg
                                          width="12"
                                          height="12"
                                          viewBox="0 0 12 12"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g clip-path="url(#clip0_18999_70113)">
                                            <path
                                              d="M11.35 5.75L10.3503 6.75L9.34998 5.75M10.4725 6.5C10.4907 6.33583 10.5 6.169 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C7.41363 10.5 8.67502 9.84817 9.5 8.82867M6 3.5V6L7.5 7"
                                              stroke="#F79009"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_18999_70113">
                                              <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </span>{" "}
                                      {status}
                                    </Tag>
                                  )}
                                </Td>
                                <Td>
                                  {item?.invoice_send_date
                                    ? moment(item?.invoice_send_date).format(
                                        "YYYY/MM/DD"
                                      )
                                    : "-"}
                                </Td>
                              </Tr>
                            );
                          })}
                        {(invoiceData?.length == 0 ||
                          invoiceData?.length == undefined) && (
                          <Td colSpan="2" className="text-center p-4">
                            No Data Available!
                          </Td>
                        )}
                      </TBody>
                    </Table>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/building.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Connect with [Client name]
            </h5>
            <p className="text-gray-600 ">
              Ensure a successful connection with the client by completing these
              steps
            </p>
          </div>
        </div>
        <ConnectClient
          ProviderExistingUser={ProviderExistingUser}
          existingUser={true}
          GetProviderChecklistByClient={{
            provider_org_id: loginData?.organisation_id,
            client_org_id:
              ProviderContactData?.provInviteClient?.org_data?.organisation_id,
            token: conserve_authtoken,
          }}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default Dashboard;
