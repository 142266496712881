import React, { useState, useEffect, useCallback } from "react";
import Heading from "../../../../../common/element/Heading";
// import PortalLayout from "../../../../../common/layout/PortalLayout";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import EmailIcon from "../../../../../assets/icons-svg/email.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SitesList from "./SitesList";
import AddSite from "./Dialog/AddSite";
import Dialog from "../../../../../common/element/Dialog";
import SettingIcon from "../../../../../assets/icons-svg/settings02.svg";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import PlusIcon from "../../../../../assets/icons-svg/plus.svg";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSiteList } from "../../../../../actions/client-portal/AllSiteApi";
import { GetClientUserPermByIdForClient } from "../../../../../actions/client-portal/AllUsersApi";
import ReactPaginate from "react-paginate";
import Email from "../Email/Email";
import { debounce } from "../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";

const Sites = () => {
  // const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [apiCalls, setApiCalls] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const permissionDataUpdate = useSelector(
    (state) => state?.permissions?.permissionData
  );
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem(
        "permission_data",
        JSON.stringify(permissionDataUpdate)
      );
      setForceRender((prevForceRender) => !prevForceRender);
      if (permissionDataUpdate?.sites_view == false) {
        // navigate('/client/dashboard');
        navigate("/client/noaccess");
      }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }

  const sitePermission = permission_data?.sites_write == true ? false : true;

  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData !== null) {
    function_assignment_id = storedData.function_assignment_id;
    user_id = storedData?.userName?.user_id;
  }
  const [countData, setCountData] = useState(0);
  const [filterState, setFilterState] = useState({
    function_assignment_id: function_assignment_id,
    sort: "site_name",
    order: "desc",
    page: 1,
    limit: 10,
    search: "",
  });
  const [totalPages, setTotalPages] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [allSiteData, setAllSiteData] = useState("");
  const [dialogIsOpen, setIsOpen] = useState(false);

  // state to open/ close email dialog
  const [dialogEmailOpen, setEmailOpen] = useState(false);
  // to open email dialog
  const openEmailDialog = () => {
    setEmailOpen(true);
  };
  // to close email dialog
  const onEmailClose = (e) => {
    if (!buttonDisabled) {
      setEmailOpen(false);
    }
  };

  // api call for permissions
  // useEffect(() => {
  //   const apiData = {
  //     user_id: user_id,
  //     function_assignment_id: function_assignment_id,
  //   };
  //   dispatch(GetClientUserPermByIdForClient(apiData)).then((response) => {
  //     if (response?.success === true) {
  //       // const arrayData = response?.data;
  //       localStorage.setItem("permission_data", JSON.stringify(response?.data));
  //     } else if (response?.status === 401) {
  //       handleUnauthorizedAccess();
  //     }
  //   });
  // }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Sites", url: "/client/sites", home: "/client/dashboard" },
      ])
    );
  }, []);

  const handleSearchChange = (e) => {
    setFilterState({
      ...filterState,
      search: e?.target?.value.trim(),
      page: 1, // Reset page when searching
    });
    setApiCalls(false);
  };
  const inputchangeSearch = useCallback(debounce(handleSearchChange), []);
  const handlePageChange = ({ selected }) => {
    setApiCalls(false);
    setFilterState({
      ...filterState,
      page: selected + 1,
    });
  };

  const handleSort = (column) => {
    if (column === filterState?.sort) {
      // Toggle the sorting order if the same column is clicked
      setFilterState({
        ...filterState,
        order: filterState.order === "asc" ? "desc" : "asc",
      });
      setApiCalls(false);
    } else {
      setFilterState({
        ...filterState,
        sort: column,
        order: "asc", // Set the default order when changing the sorting column
        page: 1, // Reset page when changing the sorting column
      });
      setApiCalls(false);
    }
  };
  const updatePageLimit = (newLimit) => {
    setFilterState((prevState) => ({
      ...prevState,
      limit: newLimit?.limit,
    }));
    setApiCalls(false);
  };

  const updatePage = (newPage) => {
    setFilterState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
    setApiCalls(false);
  };
  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    !isSaving ? setIsOpen(false) : setIsOpen(true);
  };

  const onDialogOk = (e) => {
    setIsOpen(false);
  };

  // const GetAllSitesOfClient = useCallback(async () => {
  //   dispatch(
  //     userActions.GetAllSitesOfClient(
  //       filterState
  //     )
  //   ).then((response) => {
  //     if (response?.success === true) {
  //       const arrayData = response?.data?.rows;
  //       let siteData = [];
  //       for (let key in arrayData) {
  //         siteData.push({
  //           siteName: arrayData[key]?.site_name,
  //           siteId: arrayData[key]?.site_id,
  //           siteState: arrayData[key]?.state_name,
  //           stateId: arrayData[key]?.state_id,
  //           siteAddress: arrayData[key]?.site_address,
  //           siteStatus: arrayData[key]?.is_site_active,
  //         });
  //       }
  //       setAllSiteData(siteData);
  //       setTotalPages(Math.ceil(response?.data?.count / filterState.limit));
  //     } else if (response?.status === 401) {
  //     } else {
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [
  //   dispatch,
  //   filterState
  // ]);

  const updateDataFromApi = (arrayData) => {
    let siteData = [];
    for (let key in arrayData) {
      siteData.push({
        siteName: arrayData[key]?.site_name ? arrayData[key]?.site_name : "",
        siteId: arrayData[key]?.site_id ? arrayData[key]?.site_id : "",
        siteState: arrayData[key]?.state_name ? arrayData[key]?.state_name : "",
        stateId: arrayData[key]?.state_id ? arrayData[key]?.state_id : "",
        siteAddress: arrayData[key]?.site_address
          ? arrayData[key]?.site_address
          : "",
        siteStatus: arrayData[key]?.is_site_active
          ? arrayData[key]?.is_site_active
          : "",
      });
    }
    setAllSiteData(siteData);
  };

  // useEffect(() => {
  //   dispatch(GetAllSiteList(filterState))
  //     .then((response) => {
  //       if (response?.success) {
  //         const arrayData = response?.data?.rows;
  //         setCountData(response?.data?.count)
  //         updateDataFromApi(arrayData);
  //         setTotalPages(Math.ceil(response?.data?.count / filterState.limit));
  //         // setAllData(response?.data);
  //       } else if (response?.status === 401) {
  //         handleUnauthorizedAccess();
  //       }
  //     }).catch((err) => {
  //     })
  // }, [filterState,]);
  const GetAllSiteListData = useCallback(() => {
    dispatch(GetAllSiteList(filterState))
      .then((response) => {
        if (response?.success) {
          setLoadData(true);
          const arrayData = response?.data?.rows;
          setCountData(response?.data?.count);
          updateDataFromApi(arrayData);
          setTotalPages(Math.ceil(response?.data?.count / filterState.limit));
          // setAllData(response?.data);
        } else if (response?.status === 401) {
          setLoadData(true);
          handleUnauthorizedAccess();
        }
      })
      .catch((err) => {
        setLoadData(true);
        console.log(err, "error");
      });
  }, [dispatch, filterState]);

  useEffect(() => {
    if (!apiCalls) {
      GetAllSiteListData();
      setApiCalls(true);
    }
  }, [filterState, apiCalls, totalPages]);

  return (
    <React.Fragment>
      {/* <PortalLayout> */}
      <div className="flex justify-between mb-5">
        <div>
          <Heading>Sites</Heading>
          <p className="text-gray-600">
            Add, manage and tailor work sites to fit the unique needs of each
            site
          </p>
        </div>
        <BtnLight
          disabled={sitePermission}
          onClick={() => openEmailDialog()}
          className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
        >
          Email <img src={EmailIcon} alt="" className="ml-2" />
        </BtnLight>
      </div>
      <div className="flex justify-between mb-5 mt-7">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={searchIcon} alt="" className="h-5" />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
            />

            {/* <div className="absolute inset-y-0 right-3.5 flex items-center pl-4 pointer-events-none">
                  <img src={SettingIcon} alt="" />
                </div> */}
          </div>
        </div>
        <div className="flex gap-2 reset-dropdown">
          <BtnPrimary
            onClick={() => openDialog()}
            variant="solid"
            disabled={sitePermission}
            className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
            type="button"
          >
            Add Site
            <span className="ml-3">
              <img src={PlusIcon} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>
      <SitesList
        loadData={loadData}
        GetAllSiteListData={GetAllSiteListData}
        allSiteData={allSiteData}
        handleSort={handleSort}
        sitePermission={sitePermission}
        updateDataFromApi={updateDataFromApi}
        filterState={filterState}
        setApiCalls={setApiCalls}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData}
            pageNumber={filterState?.page}
            displayPage={allSiteData?.length}
            UpdatePageLimit={updatePageLimit}
            allfilters={filterState}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              // pageLinkClassName={'page-link'}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterState.page - 1}
            />
          )}
        </div>
      )}
      {/* </PortalLayout> */}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-3 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M8.12602 14.5007C8.57006 16.2259 10.1362 17.5007 12 17.5007C13.8638 17.5007 15.4299 16.2259 15.874 14.5007M11.0177 3.26473L4.23539 8.53986C3.78202 8.89248 3.55534 9.06879 3.39203 9.28959C3.24737 9.48518 3.1396 9.70552 3.07403 9.93978C3 10.2043 3 10.4914 3 11.0658V18.3007C3 19.4208 3 19.9809 3.21799 20.4087C3.40973 20.785 3.71569 21.091 4.09202 21.2827C4.51984 21.5007 5.07989 21.5007 6.2 21.5007H17.8C18.9201 21.5007 19.4802 21.5007 19.908 21.2827C20.2843 21.091 20.5903 20.785 20.782 20.4087C21 19.9809 21 19.4208 21 18.3007V11.0658C21 10.4914 21 10.2043 20.926 9.93978C20.8604 9.70552 20.7526 9.48518 20.608 9.28959C20.4447 9.06879 20.218 8.89248 19.7646 8.53986L12.9823 3.26473C12.631 2.99148 12.4553 2.85485 12.2613 2.80233C12.0902 2.75599 11.9098 2.75599 11.7387 2.80233C11.5447 2.85485 11.369 2.99148 11.0177 3.26473Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a Site
            </h5>
            <p className="text-gray-600 ">
              Create a new Site under the Client's profile to manage Inductions,
              Inductees, Visitor Log and more.{" "}
            </p>
          </div>
        </div>
        <AddSite
          setApiCalls={setApiCalls}
          onDialogClose={onDialogClose}
          updateDataFromApi={updateDataFromApi}
          filterState={filterState}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      </Dialog>
      <Dialog
        isOpen={dialogEmailOpen}
        onClose={onEmailClose}
        onRequestClose={onEmailClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <Email
          onEmailClose={onEmailClose}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default Sites;
