import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../../../../common/element/Table";
import Badge from "../../../../../../common/element/Badge";
import Select from "../../../../../../common/element/Select";
import Button from "../../../../../../common/element/Buttons";
import Dialog from "../../../../../../common/element/Dialog";
import Label from "../../../../../../common/element/Label";
import Input from "../../../../../../common/element/Input";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import Upload from "../../../../../../common/element/Upload";
import * as userActions from "../../../../../../actions/index";
import { ReinviteProvider } from "../../../../../../actions/client-portal/AllContractorApi";
import { InviteSpecificProvider } from "../../../../../../actions/client-portal/AllContractorApi";
import { GetAllGlobalProvidersForClient } from "../../../../../../actions/client-portal/AllContractorApi";
// import AddContractor from "../YourContractor/index";
import { notify } from "react-notify-toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Validations from "../../../../../../common/404/Validations";
import { Pagination } from "../../../../../../common/pagination";
import Tooltip from "../../../../../../common/element/Tooltip";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import preventInvalidChars from "../../../../../../utils/preventInvalidChars";
const { Tr, Th, Td, THead, TBody } = Table;

const GlobalSearch = (props) => {
  const dispatch = useDispatch();
  let function_assignment_id;
  let contractor_permissions;
  let user_id;
  let trading_name;

  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    contractor_permissions = permissionData.provider_write;
  }
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
    // function_id = storedData.functionId
    trading_name = storedData.trading_name;
  }
  const [apiCalls, setApiCalls] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [ReinvitedialogIsOpen, setReinvitedialogIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [statesOne, setStatesOne] = useState();
  const [countries, setCountries] = useState();

  const [totalPages, setTotalPages] = useState(1);
  const [invite_attach, setInvite_attach] = useState([]);
  //state to save data to invite specific provider
  const [contractorData, SetcontractorData] = useState({
    individual_id: "",
    provider_f_a_id: "",
    contractor_name: "",
    provider_abn_nzbn: "",
    provider_email: "",
    invite_message: "",
    // function_assignment_id:function_assignment_id,
    trading_name: "",
    invite_attach: { files: [] },
  });
  //state to save list of contractors from all provider api
  const [allContractorData, setAllContractorData] = useState();
  const GetFunction = useSelector((state) => state);
  const Allfunction = useSelector((state) => state?.allFunctions?.allFunction);
  let function_id;
  // useEffect(() => {
  if (Allfunction) {
    // functionID if function name is provider
    const functionID = Allfunction.find(
      (item) => item?.function_name === "provider"
    )?.function_id;
    function_id = functionID;
  }
  // }, [Allfunction]);
  let contactTypeId = GetFunction.AllContactTypes.filter(
    (item) => item?.contact_type === "provider primary"
  );
  // state to send params for search & paginate in all provider api
  const [countData, setCountData] = useState(0);

  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    contact_type_id: contactTypeId[0]?.contact_type_id,
    page: 1,
    limit: 10,
    sort: "",
    order: "desc",
    client_fa_id: function_assignment_id,
    function_id: function_id,
    trading_name: "",
    meta_value_one: "",
    abn_nzbn: "",
    country_id: "",
    state_id: "",
    suburb: "",
    zipcode: "",
  });

  const openDialog = (item) => {
    setIsOpen(true);
    SetcontractorData({
      ...contractorData,
      individual_id: item?.individual_id,
      provider_f_a_id: item?.function_assignment_id,
      contractor_name: item?.trading_name,
      provider_abn_nzbn: item?.abn_nzbn,
      provider_email: item?.email,
      first_name: item?.first_name,
      last_name: item?.last_name,
      email: item?.email,
      invite_message: "",
      provider_type_name: item?.provider_type_name
        ? item?.provider_type_name
        : "  ",
    });
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const onReinviteDialogClose = (e) => {
    setReinvitedialogIsOpen(false);
  };

  const openAddDialog = () => {
    setAddOpen(true);
    setIsOpen(false);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
  };

  const handleSeachChange = (e, field) => {
    let value = e?.target?.value.trim(); // Remove leading and trailing spaces
    SetgetAllinvitedData({ ...getAllinvitedData, [field]: value });
  };

  const inputchangehandler = useCallback(debounce(handleSeachChange), []);
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(countData / getAllinvitedData?.limit));
  };

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      // Toggle the sorting order if the same column is clicked
      SetgetAllinvitedData({
        ...getAllinvitedData,
        order: getAllinvitedData.order === "asc" ? "desc" : "asc",
      });
    } else {
      SetgetAllinvitedData({
        ...getAllinvitedData,
        sort: column,
        order: "asc", // Set the default order when changing the sorting column
        page: 1, // Reset page when changing the sorting column
      });
    }
  };
  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1,
      }));
      setTotalPages(
        Math.ceil(
          countData / newLimit?.limit
            ? newLimit?.limit
            : newLimit
            ? newLimit
            : 10
        )
      );
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };

  const GetAllStatesbyCountryId = useCallback(
    async (country_id) => {
      if (country_id) {
        dispatch(userActions.GetAllStatesbyCountryId(country_id)).then(
          (response) => {
            if (response?.success === true) {
              const arrayData = response?.data;
              let state_data = [];
              for (let key in arrayData) {
                state_data.push({
                  label: arrayData[key].state_name,
                  value: arrayData[key].state_id,
                });
              }
              setStatesOne(state_data);
            } else if (response?.status === 401) {
              // handleUnauthorizedAccess()
            } else {
            }
          }
        );
      }
    },
    [dispatch]
  );

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            country_id: arrayData[key].country_id,
          });
        }
        setCountries(country_data);
      } else if (response?.status === 401) {
        // handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (getAllinvitedData.country_id) {
      GetAllStatesbyCountryId(getAllinvitedData.country_id);
    }
  }, [getAllinvitedData.country_id]);
  useEffect(() => {
    GetAllCountries();
  }, []);

  const handleCountryChange = (selectedOption, identifier) => {
    SetgetAllinvitedData((prevFormData) => ({
      ...prevFormData,
      country_id: selectedOption.value,
    }));
  };

  const handleStateChange = (selectedOption, identifier) => {
    SetgetAllinvitedData((prevFormData) => ({
      ...prevFormData,
      state_id: selectedOption.value,
    }));
  };

  const fetchData = useCallback(() => {
    dispatch(GetAllGlobalProvidersForClient(getAllinvitedData)).then(
      (response) => {
        if (response?.success) {
          const arrayData = response?.data?.rows;
          setCountData(response?.data?.count);
          setAllContractorData(arrayData);
          setTotalPages(
            Math.ceil(response?.data?.count / getAllinvitedData?.limit)
          );
        } else if (response?.status === 401) {
          // handleUnauthorizedAccess()
        }
      }
    );
  }, [dispatch, getAllinvitedData]);

  useEffect(() => {
    fetchData();
  }, [getAllinvitedData]);

  const SubmitForm = () => {
    const payload = {
      individual_id: contractorData.individual_id
        ? contractorData.individual_id
        : "",
      provider_fa_id: contractorData.provider_f_a_id
        ? contractorData.provider_f_a_id
        : "",
      first_name: contractorData?.first_name,
      last_name: contractorData?.last_name,
      email: contractorData?.email,
      provider_abn_nzbn: contractorData.provider_abn_nzbn,
      invite_message: contractorData.invite_message,
      trading_name: trading_name,
      client_fa_id: function_assignment_id,
      provider_type_id: "",
      invite_attach:
        invite_attach && invite_attach.files ? invite_attach : { files: [] }, // Ensure invite_attach has a files property
    };

    dispatch(InviteSpecificProvider(payload))
      .then((response) => {
        if (response?.success) {
          openAddDialog(); // If the response indicates success, open the Add dialog
        } else {
          // If the response indicates an error, log the error message
          console.error(
            "Error inviting provider: ",
            response?.message || "Unknown error"
          );
        }
      })
      .catch((error) => {
        // If there's an error during the invite operation, log it
        console.error("Error during invite operation: ", error);
      });
  };

  // to reInvite user
  const ReInviteUser = (e) => {
    const payload = {
      invite_provider_id: e.invite_provider_id,
      trading_name: e.trading_name,
      first_name: e.first_name,
      last_name: e.last_name,
    };
    setButtonDisabled(true);
    dispatch(ReinviteProvider(payload))
      .then((response) => {
        if (response?.success) {
          setButtonDisabled(false);
          setReinvitedialogIsOpen(true);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {})
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <React.Fragment>
      <div className="bg-gray-50 rounded-lg p-5  flex flex-col gap-4 mb-5">
        <div className="flex flex-wrap gap-3">
          <div className="flex-1">
            <label className="block mb-1">Provider name</label>
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18 17.5L15.0834 14.5833M17.1667 9.58333C17.1667 13.4954 13.9954 16.6667 10.0833 16.6667C6.17132 16.6667 3 13.4954 3 9.58333C3 5.67132 6.17132 2.5 10.0833 2.5C13.9954 2.5 17.1667 5.67132 17.1667 9.58333Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="trading_name"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                // value={getAllinvitedData.trading_name}
                onChange={(e) => inputchangehandler(e, "trading_name")}
              />
            </div>
          </div>

          <div className="flex-1">
            <label className="block mb-1">Services</label>
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18 17.5L15.0834 14.5833M17.1667 9.58333C17.1667 13.4954 13.9954 16.6667 10.0833 16.6667C6.17132 16.6667 3 13.4954 3 9.58333C3 5.67132 6.17132 2.5 10.0833 2.5C13.9954 2.5 17.1667 5.67132 17.1667 9.58333Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="meta_value_one"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                // value={getAllinvitedData.meta_value_one}
                onChange={(e) => inputchangehandler(e, "meta_value_one")}
              />
            </div>
          </div>
          <div className="flex-1">
            <label className="block mb-1">ABN/NZBN</label>
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18 17.5L15.0834 14.5833M17.1667 9.58333C17.1667 13.4954 13.9954 16.6667 10.0833 16.6667C6.17132 16.6667 3 13.4954 3 9.58333C3 5.67132 6.17132 2.5 10.0833 2.5C13.9954 2.5 17.1667 5.67132 17.1667 9.58333Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="number"
                id="abn_nzbn"
                maxLength="14"
                minLength="11"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                // value={getAllinvitedData.abn_nzbn}
                onChange={(e) => inputchangehandler(e, "abn_nzbn")}
                onKeyDown={preventInvalidChars}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-3">
          <div className="flex-1">
            <div className="flex flex-col select-wrap-block">
              <label className="block mb-1">Country</label>
              <Select
                id="country_id"
                className="w-full h-9 select-options"
                placeholder="Search"
                options={countries}
                value={countries?.filter(function (option) {
                  return option.value === getAllinvitedData.country_id;
                })}
                onChange={(e) => handleCountryChange(e, "country_id")}
              ></Select>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex flex-col select-wrap-block">
              <label className="block mb-1">State</label>
              <Select
                id="state_id"
                className="w-full h-9 select-options"
                placeholder="Search"
                options={statesOne}
                value={statesOne?.filter(function (option) {
                  return option.value === getAllinvitedData.state_id;
                })}
                onChange={(e) => handleStateChange(e, "state_id")}
              ></Select>
            </div>
          </div>

          <div className="flex-1">
            <label className="block mb-1">Suburb</label>
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18 17.5L15.0834 14.5833M17.1667 9.58333C17.1667 13.4954 13.9954 16.6667 10.0833 16.6667C6.17132 16.6667 3 13.4954 3 9.58333C3 5.67132 6.17132 2.5 10.0833 2.5C13.9954 2.5 17.1667 5.67132 17.1667 9.58333Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="suburb"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                // value={getAllinvitedData.suburb}
                onChange={(e) => inputchangehandler(e, "suburb")}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-col">
              <label className="block mb-1">Postcode</label>
              <input
                type="text"
                id="zipcode"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
                // value={getAllinvitedData.zipcode}
                onChange={(e) => inputchangehandler(e, "zipcode")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-96">
                <span
                  onClick={() => handleSort("trading_name")}
                  className="flex items-center gap-1"
                >
                  Provider
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort === "trading_name" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-60">Provider type</Th>
              <Th className="w-60">Services</Th>
              <Th className="w-48">ABN/NZBN</Th>
              <Th className="w-48">Suburb</Th>
              <Th className="w-48">State</Th>
              <Th className="w-48">Country</Th>

              <Th className="w-48">
                {/* <span  onClick={() => handleSort("phone")} className="flex items-center gap-1"> */}
                Contact no.
                {/* <svg
                   className={`transform ${getAllinvitedData.sort === "phone" &&
                   getAllinvitedData.order === "asc"
                   ? "rotate-180"
                   : ""
                   }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span> */}
              </Th>
              {/* <Th className="w-48">Status</Th> */}
              <Th>Invite</Th>
            </Tr>
          </THead>
          <TBody>
            {allContractorData &&
              allContractorData?.map((item) => (
                <Tr>
                  <Td>
                    {props?.providerPermission ? (
                      <span>
                        {item?.trading_name ? item?.trading_name : "-"}
                      </span>
                    ) : (
                      // <Link to="specific-provider-name">
                      <>{item?.trading_name ? item?.trading_name : "-"}</>
                      // </Link>
                    )}
                  </Td>
                  <Td>
                    {item?.provider_type_name ? item?.provider_type_name : "-"}
                  </Td>
                  <Td>{item?.meta_value_one ? item?.meta_value_one : "-"}</Td>
                  <Td>{item?.abn_nzbn ? item?.abn_nzbn : "-"}</Td>
                  <Td>{item?.suburb ? item?.suburb : "-"}</Td>
                  <Td>{item?.state_name ? item?.state_name : "-"}</Td>
                  <Td>{item?.country_name ? item?.country_name : "-"}</Td>
                  <Td>{item?.phone ? item?.phone : "-"}</Td>
                  {/* <Td>
                <Badge
                  className="py-0.5 px-2 rounded-lg"
                  content={"Pending"}
                  innerClass="bg-gray-100 text-gray-700 "
                />
              </Td> */}
                  <Td>
                    {(item?.f_a_relation_id == "" ||
                      item?.f_a_relation_id == "null") &&
                      (item?.invite_provider_id == "" ||
                        item?.invite_provider_id == "null") && (
                        <Button
                          disabled={props?.providerPermission}
                          onClick={() => openDialog(item)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21m17 0v-6m-3 3h6M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0z"
                              stroke="#344054"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Button>
                      )}
                    {item?.invite_provider_id &&
                      item?.invite_status == "Invited" && (
                        <div className="flex items-center justify-end gap-4">
                          <Tooltip title="Reinvite " placement="top">
                            <button
                              disabled={buttonDisabled}
                              onClick={() => ReInviteUser(item)}
                            >
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                                  stroke="#475467"
                                  stroke-width="1.667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </button>
                          </Tooltip>
                        </div>
                      )}
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>
      <div className="flex justify-between mt-3">
        <Pagination
          totalCount={countData}
          pageNumber={getAllinvitedData.page}
          displayPage={allContractorData?.length}
          // UpdatePageLimit={SetgetAllinvitedData}
          // allfilters={getAllinvitedData}
          UpdatePageLimit={updatePageLimit}
          UpdatePage={updatePage}
        />
        {countData > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={getAllinvitedData.page - 1}
          />
        )}
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M19.6658 21.3745C20.4709 21.3745 21.1214 20.7225 21.1214 19.9191V16.0467C21.1214 15.2434 20.4709 14.5914 19.6658 14.5914H13.8557C13.0506 14.5914 12.4 15.2434 12.4 16.0467V19.9191C12.4 20.7225 13.0506 21.3745 13.8557 21.3745H19.6658ZM19.6658 20.0128H13.8557C13.804 20.0128 13.762 19.9707 13.762 19.9191V16.0467C13.762 15.9951 13.804 15.9531 13.8557 15.9531H19.6658C19.7174 15.9531 19.7594 15.9951 19.7594 16.0467V19.9191C19.7594 19.9707 19.7174 20.0128 19.6658 20.0128Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M19.7593 16.0458C19.7593 16.5251 19.3703 16.9141 18.891 16.9141H14.6303C14.151 16.9141 13.762 16.5251 13.762 16.0458C13.762 15.6699 13.4569 15.3648 13.081 15.3648C12.7051 15.3648 12.4 15.6699 12.4 16.0458C12.4 17.2769 13.3992 18.2761 14.6303 18.2761H18.891C20.1221 18.2761 21.1213 17.2769 21.1213 16.0458C21.1213 15.6699 20.8162 15.3648 20.4403 15.3648C20.0644 15.3648 19.7593 15.6699 19.7593 16.0458Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.31 15.3891C18.6307 15.3891 18.891 15.1288 18.891 14.8081V14.0335C18.891 13.2852 18.2852 12.6779 17.5354 12.6779H15.986C15.2362 12.6779 14.6304 13.2852 14.6304 14.0335V14.8081C14.6304 15.1288 14.8907 15.3891 15.2114 15.3891H18.31ZM17.729 14.3728H15.7924V14.0335C15.7924 13.9266 15.8792 13.8398 15.986 13.8398H17.5354C17.6422 13.8398 17.729 13.9266 17.729 14.0335V14.3728Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M11.28 14.5663C9.40316 14.5979 5.83487 15.2345 4.06036 19.0572C3.84841 19.5141 3.88479 20.2369 5.23027 20.2759C6.57575 20.3149 9.106 20.2921 10.666 20.2759"
                stroke="#0E4E9A"
                stroke-width="1.95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="12.0698"
                cy="8.40674"
                r="3.75986"
                stroke="#0E4E9A"
                stroke-width="1.95"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one.</p>
          </div>
        </div>
        {/* <AddContractor/> */}
        <div className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Provider type*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                // options={searchProviderOptions}
                value={contractorData?.provider_type_name}
                readOnly
                // onChange={(selectedOption) =>
                //   selectDocType(selectedOption)
                // }
              ></Select>
              {/* {!selectedDocumentType && formError.selectDoc && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formError.selectDoc}
                </p>
              )} */}
            </div>
          </div>

          <div className="flex mb-5 flex-1 gap-4">
            <div className=" w-3/6">
              <Label className="text-xs font-medium block mb-1">
                Email address*
              </Label>
              <Input
                className="h-9 w-full"
                placeholder="email"
                readOnly={true}
                // inputchangehandler={inputchangehandler}
                name={"provider_abn_nzbn"}
                value={contractorData.provider_email}
              />
            </div>

            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">ABN/NZBN</Label>
              <Input
                className="h-9 w-full"
                placeholder="abn/nzbn"
                // inputchangehandler={inputchangehandler}
                readOnly={true}
                name={"provider_abn_nzbn"}
                value={contractorData.provider_abn_nzbn}
              />
            </div>
          </div>
          <div className="flex mb-5 flex-1 gap-4">
            <div className=" w-3/6">
              <Label className="text-xs font-medium block mb-1">
                First name*
              </Label>
              <Input
                className="h-9 w-full"
                // placeholder="email"
                readOnly={true}
                // inputchangehandler={inputchangehandler}
                name="first_name"
                value={contractorData?.first_name}
              />
            </div>

            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">
                Last name*
              </Label>
              <Input
                className="h-9 w-full"
                // placeholder="abn/nzbn"
                // inputchangehandler={inputchangehandler}
                readOnly={true}
                name="last_name"
                value={contractorData?.last_name}
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Assign Compliance Checklist
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                // options={searchProviderOptions}
                // value={selectedDocumentType}

                // onChange={(selectedOption) =>
                //   selectDocType(selectedOption)
                // }
              ></Select>
              {/* {!selectedDocumentType && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.selectDoc}
                      </p>
                    )} */}
            </div>
          </div>
          <div className="mb-5">
            <Label className="text-xs font-medium block mb-1">
              Attachment (optional)
            </Label>
            <Upload
              onChange={(e) => setInvite_attach({ files: e })}
              onFileRemove={(e) => {
                setInvite_attach({ files: e });
              }}
              multiple={true} // Allow multiple file uploads
              maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
              className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
            >
              <span className="text-blue-700 font-semibold">
                Click to upload
              </span>{" "}
              <span className="font-medium text-gray-600">
                or drag and drop
              </span>
              <p className="text-gray-600">(max.25mb)</p>
            </Upload>
          </div>
          <div className="mb-5 message-box-height">
            <Label className="text-xs font-medium block mb-1">
              Message (optional)
            </Label>
            <RichTextEditor
              onChange={(value) => {
                SetcontractorData((prevalue) => {
                  return {
                    ...prevalue,
                    ["invite_message"]: value,
                  };
                });
              }}
              name="invite_message"
              value={contractorData.invite_message}
            />
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => SubmitForm()} className="ml-1.5">
            Invite Provider
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      {/*--------- Invitaion Sent Confirmation -------- */}
      <Dialog
        isOpen={ReinvitedialogIsOpen}
        onClose={onReinviteDialogClose}
        onRequestClose={onReinviteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <SmallButton onClick={() => onReinviteDialogClose()}>
            close
          </SmallButton>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default GlobalSearch;
