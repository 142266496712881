import axios from "axios";
import * as actionType from "../../constant/actionType";
import {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
} from "../../redux/classes/provider-portal/GetAllContactsOfContractorPortalSlice";
export const GetAllContactsOfProvider =
  (apiData, singleSegmentValue) => async (dispatch) => {
    /*** API Call Action ***/
    //provider portal contact data get
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let apiPath =
      singleSegmentValue[0] == "all"
        ? "GetAllContactsOfProvider"
        : "GetProviderInternalOrClientContacts";
    try {
      dispatch(fetchData());
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/provider/${apiPath}`,
        params: apiData,
      });
      dispatch(fetchDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchDataError(error.message));
    }
  };
