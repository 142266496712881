import React, { useState, useCallback, useEffect } from "react";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../../common/element/Select/Select";
import { GetAllDocType } from "../../../../../../actions/admin-worker/getAllDocumentTypeList";
import { GetAllDocsOfWorker } from "../../../../../../actions/admin-worker/AdminWorkerAPI";
import { PostDoctype } from "../../../../../../actions/admin-worker/postDocTypeData";
import Upload from "../../../../../../common/element/Upload";
import DocumentsList from "./DocumentsList";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Validations from "../../../../../../common/404/Validations";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import DatePicker from "../../../../../../common/element/DatePicker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
const Providers = (props) => {
  const { id } = useParams();
  const orgId = decryptId(id);

  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { workerRedirectSpecificData } = useSelector((state) => state);
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Worker",
          url: "/admin/worker/view-all-worker",
          home: "/admin/worker/dashboard",
        },
        {
          label: `${workerRedirectSpecificData?.first_name || ""} ${
            workerRedirectSpecificData?.last_name || ""
          }`,
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
        {
          label: "Documents",
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, []);
  const dateGap = 0;
  const { SpecificProviderData } = useSelector((state) => state);
  const { SpecificWorkerData } = useSelector((state) => state);
  let function_assignment_id = SpecificProviderData?.provider_fa_id;
  let error = "This field is required!";
  const [totalPages, setTotalPages] = useState(1);
  const [loadData, setLoadData] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [sizeError, SetsizeError] = useState("");
  const [wordError, SetwordError] = useState("");
  const [refetch, Setrefetch] = useState(false);
  const [apiCalls, setApiCalls] = useState(true);
  const [countData, setCountData] = useState(0);
  const [expiryDateChk, setExpiryDateChk] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [docTypeError, setDocTypeError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // const [singleSegmentValue, setSingleSegmentValue] = useState(["all"]);
  const [addDocForm, setaddDocForm] = useState("");
  const [checkReqiuredField, SetcheckReqiuredField] = useState(false);
  const [addDocError, setAddDocError] = useState(false);
  const [allDocumnetDataList, setAllDocumnetDataList] = useState([]);
  const [documentFormData, SetDocumentFormData] = useState({
    doc_name: "",
    individual_id: workerRedirectSpecificData?.individual_id
      ? workerRedirectSpecificData?.individual_id
      : "",
    // function_assignment_id: function_assignment_id,
    worker_id: orgId,
  });
  const [personName, setPersonName] = React.useState([]);
  const [docType, setDocType] = React.useState("");
  const [specificDocData, setSpecificDocData] = React.useState("");
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    page: 1,
    limit: 10,
    sort: "doc_name",
    order: "desc",
    search: "",
    individual_id: workerRedirectSpecificData?.individual_id
      ? workerRedirectSpecificData?.individual_id
      : "",
  });

  const GetAllDocsOfWorkers = useCallback(() => {
    setLoadData(false);
    dispatch(GetAllDocsOfWorker(getAllinvitedData))
      .then((response) => {
        if (response?.success) {
          setLoadData(true);
          const arrayData = response?.data;
          setCountData(response?.data?.count);
          setAllDocumnetDataList(arrayData?.rows);
          setTotalPages(
            Math.ceil(response?.data?.count / getAllinvitedData.limit)
          );
        } else if (response.status === 401) {
          setLoadData(true);
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        setLoadData(true);
        console.error("Failed to fetch document types:", error);
      });
  }, [dispatch, getAllinvitedData]);

  useEffect(() => {
    GetAllDocsOfWorkers();
  }, [getAllinvitedData, refetch]);

  const GetFunction = useSelector((state) => state);

  const handleSort = (column) => {
    if (column === getAllinvitedData?.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    }
  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };
  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);
  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1,
      }));
      setTotalPages(
        Math.ceil(
          countData / newLimit?.limit
            ? newLimit?.limit
            : newLimit
            ? newLimit
            : 10
        )
      );
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };
  const [docTypeOptions, setdocTypeOptions] = useState([]);
  // Add Document
  const HandleChange = (e) => {
    SetDocumentFormData({
      doc_name: "",
      // organisation_id: id,
      // function_assignment_id: function_assignment_id,
      individual_id: workerRedirectSpecificData?.individual_id
        ? workerRedirectSpecificData?.individual_id
        : "",
      // function_assignment_id: function_assignment_id,
      worker_id: orgId,
      document_type_id: e.document_type_id,
    });
    setaddDocForm(e.value);
    setDocType(e.value);
    let FilterData = docTypeOptions?.filter(
      (item) => item.document_type_id == e.document_type_id
    );
    setSpecificDocData(FilterData?.[0]);
    if (FilterData?.[0]?.allow_expiry == true) {
      setExpiryDateChk(true);
    }

    setDocTypeError(false);
    // allow_expiry
    // custom_fields
    // req_expiry_date_notes
    // req_issuer
    // req_policy_no
    // req_amount_insured

    // doc_name:document name (document type name)
    // policy_no:policy-1258455
    // expiry_date:2023-12-12
    // amount_insured:2400
    // expiry_date_notes:
    // doctype_fields_data:{"salra":"dssd"}

    for (let filterData in FilterData?.[0]) {
      if (
        FilterData?.[0][filterData] == true ||
        filterData == "custom_fields"
      ) {
        let name =
          filterData == "allow_expiry"
            ? "expiry_date"
            : filterData == "req_expiry_date_notes"
            ? "expiry_date_notes"
            : filterData == "req_issuer"
            ? "issuer"
            : filterData == "req_policy_no"
            ? "policy_no"
            : filterData == "req_amount_insured"
            ? "amount_insured"
            : filterData == "custom_fields"
            ? "doctype_fields_data"
            : "";
        if (name == "doctype_fields_data") {
          let obj = {};
          if (FilterData?.[0]?.[filterData]?.length) {
            for (let i = 0; i < FilterData[0][filterData].length; i++) {
              obj[FilterData[0][filterData][i]] = "";
            }
          } else {
            console.error(
              "FilterData or its nested properties are null or undefined."
            );
          }
          SetDocumentFormData((prevalue) => {
            return {
              ...prevalue,
              [name]: obj,
            };
          });
        } else {
          SetDocumentFormData((prevalue) => {
            if (name == "amount_insured") {
              return {
                ...prevalue,
                [name]: 0,
              };
            } else {
              return {
                ...prevalue,
                [name]: "",
              };
            }
          });
        }
      }
    }
  };

  // Handle form
  const handleForm = (event, expireDate, custom_field, action = "add") => {
    SetDocumentFormData((prevValue) => {
      if (custom_field === "doctype_fields_data") {
        return {
          ...prevValue,
          doctype_fields_data: {
            ...prevValue.doctype_fields_data,
            [expireDate]: event.target.value,
          },
        };
      }

      if (expireDate === "expiry_date" || expireDate === "issue_date") {
        return {
          ...prevValue,
          [expireDate]: event,
        };
      }

      if (event?.name === "document_type_id") {
        return {
          ...prevValue,
          [event.name]: event.document_type_id,
        };
      }

      if (event.name === "is_doctype_swms") {
        return {
          ...prevValue,
        };
      }

      // Handle file removal
      if (action === "remove") {
        SetsizeError("");
        setAddDocError(false);
        return {
          ...prevValue,
          doc_file: [], // Clear the file list
        };
      }

      // Handle file upload
      if (Array.isArray(event) || event.length === 0) {
        if (event.length === 0) {
          SetsizeError("");
          setAddDocError(false);
          return {
            ...prevValue,
            doc_file: event,
          };
        }

        const validExtensions = /\.(pdf|jpg|jpeg)$/i;
        if (validExtensions.test(event[0]?.name)) {
          if (event[0]?.size <= 2 * 1024 * 1024) {
            // 2MB limit
            SetsizeError("");
            setAddDocError(false);
            return {
              ...prevValue,
              doc_file: event,
            };
          } else {
            SetsizeError("File size exceeds the maximum limit of 2MB.");
            setAddDocError(true);
            return {
              ...prevValue,
              doc_file: [], // Clear the file list
            };
          }
        } else {
          SetsizeError("Only PDF, JPG, and JPEG files will be accepted.");
          setAddDocError(true);
          return {
            ...prevValue,
            doc_file: [], // Clear the file list
          };
        }
      }

      if (event.target.name === "document_desc") {
        const currentValue = event.target.value;
        const words = currentValue.split(/\s+/);

        if (words.length <= 250) {
          SetwordError("");
          return {
            ...prevValue,
            [event.target.name]: currentValue,
          };
        } else {
          SetwordError("Additional information must not exceed 250 words.");
          const limitedInput = words.slice(0, 250).join(" ");
          return {
            ...prevValue,
            [event.target.name]: limitedInput,
          };
        }
      }

      return {
        ...prevValue,
        [event.target.name]: event.target.value,
      };
    });
  };

  const fetchDocumentTypes = useCallback(() => {
    if (dialogChoose) {
      dispatch(GetAllDocType({ recipient_type: "worker" }))
        .then((response) => {
          if (response?.success) {
            const arrayData = response?.data;
            const updatedArrayData = arrayData.map((item) => ({
              ...item,
              value: item.doc_type_name.toLowerCase().replace(/ /g, "_"),
              label: `${item.doc_type_name} - (${item.recipient_type})`,
              name: "document_type_id",
            }));
            setdocTypeOptions(updatedArrayData);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          console.error("Failed to fetch document types:", error);
        });
    }
  }, [dialogChoose, dispatch]);

  useEffect(() => {
    fetchDocumentTypes();
  }, [dialogChoose]);

  // useEffect(() => {
  //   const documentNames =
  //     Getallfubnctions?.GetAllDocumentTypeList?.data?.data.map((item) => ({
  //       value: item?.doc_type_name.toLowerCase().replace(/ /g, "_"),
  //       label: item?.doc_type_name + " - (" + item?.recipient_type + ")",
  //       document_type_id: item?.document_type_id,
  //       name: "document_type_id",
  //     }));
  //   setdocTypeOptions(documentNames);
  // }, [Getallfubnctions]);
  // Update Main Tabs Function
  // const onSingleSelectionSegmentChange = useCallback(
  //   (val) => {
  //     if (val[0] === "all") {
  //       SetgetAllinvitedData((prevalue) => {
  //         return {
  //           ...prevalue,
  //           sort: "created_date",
  //           order: "desc",
  //           doc_type: "all",
  //         };
  //       });
  //     } else if (val[0] === "insurance") {
  //       SetgetAllinvitedData((prevalue) => {
  //         return {
  //           ...prevalue,
  //           sort: "created_date",
  //           order: "desc",
  //           doc_type: "insurance",
  //         };
  //       });
  //     } else if (val[0] === "swms") {
  //       SetgetAllinvitedData((prevalue) => {
  //         return {
  //           ...prevalue,
  //           sort: "created_date",
  //           order: "desc",
  //           doc_type: "swms",
  //         };
  //       });
  //     } else {
  //       SetgetAllinvitedData((prevalue) => {
  //         return {
  //           ...prevalue,
  //           sort: "created_date",
  //           order: "desc",
  //           doc_type: "other",
  //         };
  //       });
  //     }
  //     setSingleSegmentValue(val);
  //     SetgetAllinvitedData((prevalue) => {
  //       return {
  //         ...prevalue,
  //         ["is_doctype_swms"]: val[0] == "swms" ? true : false,
  //       };
  //     });
  //     SetgetAllinvitedData((prevalue) => {
  //       return {
  //         ...prevalue,
  //         ["doc_type"]: val[0],
  //         // val[0] == "swms" || val[0] == "View All" ? "" : val[0],
  //       };
  //     });
  //   },
  //   [setSingleSegmentValue]
  // );
  const searchOptions = [
    { value: "insurance", label: "Insurance" },
    { value: "swms", label: "SWMS" },
    { value: "other", label: "Other" },
  ];

  const openChoose = () => {
    setChoose(true);
  };
  const onChooseClose = (e) => {
    setChoose(false);
    setDocTypeError(false);
    setaddDocForm("");
    SetDocumentFormData({
      doc_name: "",
      // organisation_id: id,
      // function_assignment_id: function_assignment_id,
      individual_id: SpecificWorkerData?.individual_id,
      // function_assignment_id: function_assignment_id,
      worker_id: orgId,
    });
  };
  const openDialog = () => {
    SetcheckReqiuredField(false);
    const emailValidation = Validations({
      value: addDocForm,
      Validations: { required: true },
    });
    if (!emailValidation.valid) {
      setDocTypeError(emailValidation.error_msg);
    } else {
      setIsOpen(true);
      setPersonName([]);
      setChoose(false);
      setaddDocForm("");
      setDocTypeError("");
    }
  };
  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
      setAddDocError(false);
      setExpiryDateChk(false);
      onChooseClose();
      SetDocumentFormData([]);
      SetsizeError("");
      onAddDialogClose();
      setPersonName([]);
    }
  };
  const hanldeAddDocument = () => {
    SetcheckReqiuredField(true);
    let isFormDataValid = true;
    if (
      (documentFormData?.expiry_date || expiryDateChk == false) &&
      documentFormData.doc_name
    ) {
      isFormDataValid = true;
    } else {
      isFormDataValid = false;
    }

    if (
      documentFormData?.doc_file == "" ||
      documentFormData?.doc_file == undefined ||
      (Array.isArray(documentFormData?.doc_file) &&
        documentFormData.doc_file.length === 0)
    ) {
      setAddDocError(true);
    } else {
      setAddDocError(false);
    }
    if (
      isFormDataValid &&
      documentFormData?.doc_file != "" &&
      documentFormData?.doc_file != undefined &&
      Array.isArray(documentFormData?.doc_file) &&
      documentFormData.doc_file.length != 0
    ) {
      setButtonDisabled(true);

      dispatch(PostDoctype(documentFormData))
        .then((response) => {
          if (response?.success) {
            setButtonDisabled(false);
            setAddOpen(true);
            GetAllDocsOfWorkers();
            setIsOpen(false);
            SetDocumentFormData([]);
            SetcheckReqiuredField(false);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          setButtonDisabled(false);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    // props.getOnDialogClose(onAddDialogClose);
  };

  useEffect(() => {
    // Calculate the scroll height and set the textarea height accordingly
    const textarea = document.querySelector(".moduleDescTextarea");
    if (textarea) {
      textarea.style.minHeight = "38px";
      textarea.style.height = `${Math.max(textarea.scrollHeight, 38)}px`;
      textarea.style.maxHeight = "400px";
      if (textarea.textLength === 0) {
        textarea.style.height = "38px";
      }
    }
  }, [documentFormData]);

  // Min Date
  const minDate = dayjs(new Date())
    .subtract(dateGap, "day")
    .startOf("day")
    .toDate();
  return (
    <React.Fragment>
      {/* <div className="flex justify-between">
        <div className="flex flex-col">
          <Heading>Compliance Checklist</Heading>
          <p className="text-gray-600">
            Configure and manage criteria for contractors and workers to be
            fulfilled before initiating collaboration with your organisation
          </p>
        </div>
      </div> */}
      <div className="flex justify-between mb-5 mt-7">
        <div className="flex gap-2 validation-filter">
          {/* <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="all" className="h-9">
              <span>View All</span>
            </Segment.Item>
            <Segment.Item value="insurance" className="h-9">
              <span>Insurances</span>
            </Segment.Item>
            <Segment.Item value="swms" className="h-9">
              <span className="py-2">SWMS</span>
            </Segment.Item>
            <Segment.Item value="other" className="h-9">
              <span className="py-2">Other</span>
            </Segment.Item>
          </Segment> */}
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img
                  src="/assets/icons-svg/search-lg.svg"
                  alt=""
                  className="h-5"
                />
              </div>
              <ClientInput
                type="text"
                className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                inputchangehandler={inputchangehandler}
                name={"search"}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2 reset-dropdown">
          <BtnPrimary
            onClick={() => openChoose()}
            variant="solid"
            className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
            type="button"
          >
            Add Document
            <span className="ml-3">
              <img src="/assets/icons-svg/plus.svg" alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>
      <DocumentsList
        allDocumnetDataList={allDocumnetDataList}
        GetAllDocsOfWorkers={GetAllDocsOfWorkers}
        handleSort={handleSort}
        getAllinvitedData={getAllinvitedData}
        refetch={Setrefetch}
        refetchData={refetch}
        function_assignment_id={function_assignment_id}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {
        <div
          className="flex justify-between mt-3"
          style={{
            alignItems: "Right",
            listStyle: "none",
            padding: 0,
          }}
        >
          <Pagination
            totalCount={loadData && (countData || 0)}
            pageNumber={getAllinvitedData.page}
            displayPage={allDocumnetDataList?.length || 0}
            UpdatePageLimit={updatePageLimit}
            UpdatePage={updatePage}
            // UpdatePageLimit={SetgetAllinvitedData}
            // allfilters={getAllinvitedData}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={getAllinvitedData?.page - 1}
            />
          )}
        </div>
      }
      
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Document document type*
            </label>
            <div className="w-3/4 pl-2">
              <Select
                className="w-full h-10 select-options"
                placeholder="Select document"
                options={docTypeOptions}
                onChange={HandleChange}
              ></Select>
              {docTypeError && (
                <div className="text-red-500 text-sm mt-1 ">{docTypeError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => openDialog()} className="ml-1.5">
            Add
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Document
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Select document*
            </label>
            <div className="w-3/4 pl-2">
              <Select
                className="w-full h-10 select-options"
                placeholder="Select Validation Criteria"
                // options={docTypeOptions}
                value={docTypeOptions?.filter((item) => item?.value == docType)}
                // onChange={handleForm}
                readOnly={true}
              ></Select>
              {/* {checkReqiuredField &&
                documentFormData?.is_doctype_swms == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
            </div>
          </div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Document name*
            </label>
            <div className="w-3/4 pl-2">
              <ClientInput
                name={"doc_name"}
                inputchangehandler={(e) => handleForm(e, "doc_name")}
                className="w-full h-10 select-options"
              />
              {checkReqiuredField && documentFormData.doc_name == "" && (
                <div className="text-red-500 text-sm mt-1 ">{error}</div>
              )}
            </div>
          </div>
          {/* Expire Date */}
          {specificDocData?.allow_expiry && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Expiry date*
              </label>
              <div className="w-3/4 pl-2 flex">
                <div className="w-full relative">
                  <span className="adon_icon absolute left-4 top-3">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#0ckeddmh9a)">
                        <path
                          d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z"
                          stroke="#344054"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="0ckeddmh9a">
                          <path
                            fill="#fff"
                            transform="translate(0 .958)"
                            d="M0 0h20v20H0z"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <DatePicker
                    onChange={(e) => handleForm(e, "expiry_date")}
                    placeholder="Select expire date"
                    minDate={minDate}
                  />
                  {checkReqiuredField && documentFormData.expiry_date == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Amout Insured */}
          {specificDocData?.req_amount_insured && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Amount insured
              </label>
              <div className="w-3/4 pl-2 flex">
                <div className="w-full relative">
                  <span className="adon_icon absolute left-2 top-10px">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13.789a3.333 3.333 0 0 0 3.333 3.333h3.334a3.333 3.333 0 0 0 0-6.667H8.333a3.333 3.333 0 1 1 0-6.666h3.334A3.333 3.333 0 0 1 15 7.122m-5-5V18.79"
                        stroke="#667085"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <div className="w-full">
                    <ClientInput
                      type={"number"}
                      placeholder="Enter value here"
                      name={"amount_insured"}
                      inputchangehandler={(e) =>
                        handleForm(e, "amount_insured")
                      }
                      className="w-full h-10 select-options pl-8"
                    />
                    {checkReqiuredField &&
                      documentFormData.insured_amount == "" && (
                        <div className="text-red-500 text-sm mt-1 ">
                          {error}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Policy number */}
          {specificDocData?.req_policy_no && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Policy number
              </label>
              <div className="w-3/4 pl-2">
                <ClientInput
                  name={"policy_no"}
                  inputchangehandler={(e) => handleForm(e, "policy_no")}
                  className="w-full h-10 select-options"
                />
                {/* {checkReqiuredField && documentFormData.policy_no == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
              </div>
            </div>
          )}

          {/* Issuing authority */}
          {specificDocData?.req_issuer && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Issuing authority
              </label>
              <div className="w-3/4 pl-2">
                <ClientInput
                  name={"issuer"}
                  inputchangehandler={(e) => handleForm(e, "issuer")}
                  className="w-full h-10 select-options"
                />
                {checkReqiuredField &&
                  documentFormData.issuing_authority == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )}
              </div>
            </div>
          )}

          {/* Custom Field */}
          {specificDocData?.custom_fields?.map((item, index) => {
            return (
              <div className="flex mb-5" key={index}>
                <label className="font-medium text-gray-700 w-1/4">
                  {item}
                </label>
                <div className="w-3/4 pl-2">
                  <ClientInput
                    name={item}
                    inputchangehandler={(e) =>
                      handleForm(e, item, "doctype_fields_data")
                    }
                    className="w-full h-10 select-options"
                  />
                  {/* {checkReqiuredField &&
                  documentFormData.issuing_authority == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )} */}
                </div>
              </div>
            );
          })}

          {/* Additional info (optional) */}
          {specificDocData?.req_expiry_date_notes && (
            <div className="flex mb-5">
              <label className="font-medium text-gray-700 w-1/4">
                Additional info
              </label>
              <div className="w-3/4 pl-2">
                <textarea
                  name={"expiry_date_notes"}
                  onChange={(e) => handleForm(e, "expiry_date_notes")}
                  rows={4}
                  cols={40}
                  placeholder="Provide a brief description to help Inductees..."
                  className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline h-10"
                />
                {wordError && (
                  <div className="text-red-500 text-sm mt-1 ">{wordError}</div>
                )}
              </div>
            </div>
          )}
          <div className="flex mb-5 border-t mt-5 pt-5">
            <label className="font-medium text-gray-700 w-1/4">Upload*</label>
            <div className="w-3/4 pl-2">
              <Upload
                uploadLimit={1}
                onChange={(e) => handleForm(e, null, null, "add")}
                accept=".pdf, .jpg, .jpeg"
                draggable
                className="w-full"
                onFileRemove={(e) => handleForm(e, null, null, "remove")}
              >
                <div className="text-center">
                  <div className="flex items-center justify-center flex-col">
                    <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                          stroke="#475467"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                    <span className="pt-4">
                      <span className="text-blue-700 font-semibold mr-1">
                        Click to upload
                      </span>{" "}
                      <span className="text-xs text-gray-600">
                        {" "}
                        (Max 2MB. Only PDF and image files will be accepted)
                      </span>
                    </span>
                  </div>
                </div>
              </Upload>
              {(sizeError ||
                (addDocError &&
                  (documentFormData?.doc_file === "" ||
                    documentFormData?.doc_file === undefined ||
                    (Array.isArray(documentFormData?.doc_file) &&
                      documentFormData.doc_file.length === 0)))) && (
                <div className="text-red-500 text-sm mt-1">
                  {sizeError || error}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            disabled={buttonDisabled}
            className="mr-1.5"
            variant="plain"
            onClick={onDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            onClick={() => hanldeAddDocument()}
            className="ml-1.5"
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Add"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Thanks! Document added.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Providers;
