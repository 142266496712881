import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetClientUserDetailsById = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetClientUserDetailsById`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateClientUserProfile = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/UpdateClientUserProfile`,
    });
    return response.data;
  } catch (error) {}
};

export const GetSpecificClientDetailsById =
  (getAllData) => async (dispatch) => {
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetSpecificClientDetailsById`,
        params: getAllData,
      });
      return response.data;
    } catch (error) {}
  };

export const GetAlreadyExistingOrgCheck = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetAlreadyExistingOrgCheck`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateClientDetails = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/UpdateClientDetails`,
    });
    return response.data;
  } catch (error) {}
};

export const GetClientOtherDetailsById = (getAllData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetClientOtherDetailsById`,
      params: getAllData,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateComplianceChecklistReviewDate =
  (data) => async (dispatch) => {
    // Final CSV Data
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      let formdata = new FormData();
      for (let key in data) {
        formdata.append(key, data[key]);
      }
      const response = await axios({
        method: "put",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/UpdateComplianceChecklistReviewDate`,
      });
      return response.data;
    } catch (error) {}
  };

export const UpdateUserPassword = (data) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/UpdateUserPassword`,
    });
    return response.data;
  } catch (error) {}
};
