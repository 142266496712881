import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetAllIndividualListForInviteForClient =
  (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetAllIndividualListForInvite`,
        params: apiData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
export const RemoveOrgUser = (ApiData) => {
  return (dispatch) => {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    return axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/RemoveOrgUser`,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return;
      });
  };
};
export const UserInviteForClient = (data, ipAddress) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    //Client Post Api for add user and permission
    const response = await axios({
      method: "post",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/client/InviteOrgUser`,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateClientUserPermForClient =
  (data, ipAddress) => async (dispatch) => {
    // Final CSV Data
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      //Client Update Api for permission
      const response = await axios({
        method: "put",
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
          userip: ipAddress,
        },
        url: `${actionType.environment_url}/api/client/UpdateOrgUserPerm`,
      });
      return response.data;
    } catch (error) {}
  };

export const GetClientUserPermByIdForClient = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    //Client Get Api for permission by ID
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetOrgUserPermById`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetAllSpecificOrgUsers =
  (apiData, searchTerm) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/client/GetAllSpecificOrgUsers`,
        params: { organisation_id: apiData, search: searchTerm },
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetAllSpecificOrgInvites = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/GetAllSpecificOrgInvites`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const ReinviteOrgUser = (data, ipAddress) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "put",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
        userip: ipAddress,
      },
      url: `${actionType.environment_url}/api/client/ReinviteOrgUser`,
    });
    return response.data;
  } catch (error) {}
};

export const RemoveOrgUserInvitation = (ApiData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/RemoveOrgUserInvitation`,
    });
    return response.data;
  } catch (error) {}
};

export const RemoveClientUser = (ApiData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/RemoveClientUser`,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateSiteActiveStatus = (data, ipAddress) => async (dispatch) => {
  // Final CSV Data
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "put",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        userip: ipAddress,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/UpdateSiteActiveStatus`,
    });
    return response.data;
  } catch (error) {}
};
