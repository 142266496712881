import React, { useState, useEffect, useCallback } from "react";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { debounce } from "../../../../../common/snippet/Debouncing";
import Heading from "../../../../../common/element/Heading";
import InvoiceList from "./InvoiceList";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { useDispatch } from "react-redux";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import Dialog from "../../../../../common/element/Dialog";
// import Filter from "../../../Client/Invoice/Filter";
import Select from "../../../../../common/element/Select";
import { AdminGetAllProviderInvoices } from "../../../../../actions/admin-provider/invoices/AdminGetAllProviderInvoices";
import { useSelector } from "react-redux";
import { GetAllProviderOrgList } from "../../../../../actions/admin-provider/invoices/GetAllProviderOrgList";
import { GetSignedUrlByKey } from "../../../../../actions/public/GetSignedUrlByKey";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
const Invoices = () => {
  const { allFunctions } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loadData, setLoadData] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [filterpage, setfilterpage] = useState(1);
  const [searchTerm, setsearchTerm] = useState("");
  const [searchProvider, setSearchProvider] = useState("");
  const [filterlimit, setfilterlimit] = useState(10);
  const [status, setStatus] = useState("");
  const [providerFilter, setProviderFilter] = useState("");
  const [providerOptions, setProviderOptions] = useState([]);
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("created_date");
  const [pdfDownloadSuccessDialog, setPdfDownloadSuccessDialog] =
    useState(false);
  const [viewType, setViewType] = useState("");
  const [allInvoicesData, setAllInvoicesData] = useState([]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Invoices",
          url: "/admin/provider/invoices",
          home: "/admin/provider/dashboard",
        },
      ])
    );
  }, [dispatch]);

  const onGetAllInvoiceData = useCallback(async () => {
    // dispatch(AdminGetAllProviderInvoices()).then(()=>{

    // })
    // setAllInvoicesData(getData);
    // setCountData(getData.length);
    // setTotalPages(Math.ceil(getData.length / filterlimit));

    try {
      const payload = {
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        provider_org_id: providerFilter,
        statusType: status,
      };
      const response = await dispatch(AdminGetAllProviderInvoices(payload));
      if (response?.success) {
        const arrayData = response?.data;
        setAllInvoicesData(arrayData);
        // setCountData(response?.data?.count);
        // setTotalPages(Math.ceil(response.data.count / filterlimit));
        setLoadData(true);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
        setLoadData(true);
      }
    } catch (error) {
      setLoadData(true);
      console.error("Error fetching global permissions:", error);
    }
  }, [
    dispatch,
    viewType,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    status,
    providerFilter,
  ]);
  useEffect(() => {
    onGetAllInvoiceData();
  }, [
    viewType,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    status,
    providerFilter,
  ]);

  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  const updateUrl = (file) => {
    if (file?.xero_invoice_pdf) {
      dispatch(
        GetSignedUrlByKey({ url: file?.xero_invoice_pdf }, "admin/provider")
      ).then((response) => {
        if (response.success) {
          downloadSingleInvoice(response?.data, file);
        }
      });
    }
  };

  const downloadSingleInvoice = async (pdf, item) => {
    try {
      if (!pdf || !item?.xero_invoice_number) {
        throw new Error("Invalid invoice data: Missing URL or invoice number");
      }

      const response = await fetch(pdf);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const url = window.URL.createObjectURL(blob); // Create an object URL for the Blob

      const link = document.createElement("a");
      link.href = url;
      link.download = `${item.xero_invoice_number}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error(
        `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
        error.message
      );
    }
  };

  const downloadSelectedInvoices = async () => {
    for (const [index, item] of allInvoicesData.entries()) {
      await new Promise((resolve) => {
        setTimeout(() => {
          updateUrl(item);
          resolve();
        }, index * 300); // Introducing a delay of 300ms between downloads
      });
    }
    // After all downloads are attempted, show success dialog and reset checked items
    setPdfDownloadSuccessDialog(true);
  };

  const closeDownloadSuccessPdf = () => {
    setPdfDownloadSuccessDialog(false);
  };

  const statusOptions = [
    { value: "", label: "All" },
    { value: "PAID", label: "Paid" },
    { value: "UPCOMING", label: "Upcoming" },
    { value: "OVERDUE", label: "Overdue" },
    { value: "DRAFT", label: "Draft" },
  ];
  const HandleStatus = (e) => {
    setStatus(e?.value);
  };

  let functionType = allFunctions?.allFunction.filter(
    (item) => item?.function_name === "provider"
  );

  // Provider Filters
  const Handleprovider = (e) => {
    setProviderFilter(e?.value);
  };

  const handleSearchProviderChange = (e) => {
    setSearchProvider(e);
  };

  const handleSearchChange = useCallback(
    debounce(handleSearchProviderChange),
    []
  );

  const GetAllProviderList = () => {
    const payload = {
      function_id: functionType[0]?.function_id,
      search: searchProvider,
    };
    dispatch(GetAllProviderOrgList(payload)).then((response) => {
      if (response.success) {
        const filterData = response?.data?.map((item) => {
          return { label: item?.trading_name, value: item?.organisation_id };
        });
        setProviderOptions(filterData);
      }
    });
  };

  useEffect(() => {
    if (searchProvider.length) {
      GetAllProviderList();
    }
  }, [searchProvider]);

  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
        <Heading>Invoices</Heading>
        <div className="flex flex-wrap gap-3">
          <BtnPrimary
            disabled={
              allInvoicesData?.length == 0 || allInvoicesData == undefined
            }
            className="w-36 flex items-center justify-center text-xs"
            onClick={() => {
              downloadSelectedInvoices();
            }}
          >
            {/* <div> */}
            Download all
            <span className="ml-3">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                  stroke="#fff"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </BtnPrimary>
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-y-2.5">
        <div className="bg-gray-50 p-4 rounded-lg mb-5 w-full">
          <div className="flex gap-3">
            <div className="w-full">
              <label className="text-gray-700 font-medium text-xs mb-2 block">
                Search
              </label>
              <div className="input-wrapper">
                <div class="input-suffix-start left-3.5">
                  <img
                    src="/static/media/search-lg.b2976d5763145bb53f64eaa877fe41b4.svg"
                    alt=""
                    className="h-5"
                  />
                </div>
                <input
                  onChange={(e) => inputchangeSearch(e)}
                  type="text"
                  name="hs-table-search"
                  className="input  pl-9 w-full  input-shadow h-34 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="select-wrap-block w-full">
              <label className="text-gray-700 font-medium text-xs mb-2 block">
                Status
              </label>
              <Select
                onChange={HandleStatus}
                options={statusOptions}
                className="select-option"
              />
            </div>
            <div className="select-wrap-block w-full">
              <label className="text-gray-700 font-medium text-xs mb-2 block">
                Provider
              </label>
              <Select
                isSearchable={true}
                placeholder="All"
                onChange={Handleprovider}
                handleSearchChange={handleSearchChange}
                options={providerOptions}
                className="select-option"
              ></Select>
            </div>
          </div>
        </div>
      </div>
      {/* <Filter /> */}
      <InvoiceList
        handleSort={handleSort}
        filtersort={filtersort}
        setViewType={setViewType}
        viewType={viewType}
        filterorder={filterorder}
        allInvoicesData={allInvoicesData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && allInvoicesData?.length == 0 && (
        <div className="h-full w-full text-center mt-2">No Data Available!</div>
      )}

      <Dialog
        isOpen={pdfDownloadSuccessDialog}
        onClose={closeDownloadSuccessPdf}
        onRequestClose={closeDownloadSuccessPdf}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            All PDFs downloaded successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeDownloadSuccessPdf()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Invoices;
