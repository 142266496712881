import axios from "axios";
import * as actionType from "../../constant/actionType";
import {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
} from "../../redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";
export const GetWorkerDocHistoryById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    dispatch(fetchData());
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/admin/client/GetDocumentHistoryById`,
      params: apiData,
    });
    dispatch(fetchDataSuccess(response.data));
    return { success: true, data: response.data };
  } catch (error) {
    dispatch(fetchDataError(error));
    return { success: false, error };
  }
};

export const UpdateWorkerDocStatus = (ApiData) => async (dispatch) => {
  try {
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "patch",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,

        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateWorkerDocStatus`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
