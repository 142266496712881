import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../../../../common/element/Heading";
import Segment from "../../../../../common/element/Segment";
import Table from "../../../../../common/element/Table";
import SearchImg from "../../../../../assets/icons-svg/search-lg.svg";
import Badge from "../../../../../common/element/Badge";
import ClientInput from "../../../../../common/element/Input/clientInput";
import moment from "moment";
import useCallbackRef from "../../../../../common/element/hooks/useCallbackRef";
import Tooltip from "../../../../../common/element/Tooltip";
import { debounce } from "../../../../../common/snippet/Debouncing";
import {
  GetAllDeshboardTasks,
  UpdateTaskArchiveStatus,
  MarkTaskAsRead,
  GetNotificationTasksForAdminPortal,
} from "../../../../../actions/Admin";
import { useParams, Link } from "react-router-dom";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import Tag from "../../../../../common/element/Tag";
import Scrollbar from "../../../../../common/element/ScrollBar";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { decryptId } from "../../../../../common/encryptDecrypt/encryptdecrypt";
import { GetWorkerMainDashboardDocsStatusCount } from "../../../../../actions/admin-worker/GetWorkerMainDashboardDocsStatusCount";

const Dashboard = () => {
  const { id } = useParams();
  // const orgId = decryptId(id);

  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { Tr, Th, Td, THead, TBody } = Table;

  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  const user_id = loginData?.userName?.user_id || loginData?.data?.user_id;
  const userRole = loginData?.data?.role?.role_name;
  const [totalTastCount, setTotalTaskCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [workerCountData, SetWorkerCountData] = useState("");

  const scrollContainerRef = useRef(null);
  const [scrollThreshold, setScrollThreshold] = useState(23);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["Inbox"]);
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Dashboard",
          url: "/admin/provider/dashboard",
          home: "/admin/provider/dashboard",
        },
      ])
    );
  }, []);

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
    },
    [setSingleSegmentValue]
  );

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  const startOfWeek = moment().startOf("week");
  const startOfMonth = moment().startOf("month");
  const startOfYear = moment().startOf("year");
  const groupedNotifications = notificationList?.reduce(
    (acc, notification) => {
      const createdDate = moment(notification?.created_date);

      if (createdDate.isSame(today, "d")) {
        acc.today.push(notification);
      } else if (createdDate.isSame(yesterday, "d")) {
        acc.yesterday.push(notification);
      } else if (createdDate.isSameOrAfter(startOfWeek)) {
        acc.thisWeek.push(notification);
      } else if (createdDate.isSameOrAfter(startOfMonth)) {
        acc.thisMonth.push(notification);
      } else if (createdDate.isSameOrAfter(startOfYear)) {
        acc.thisYear.push(notification);
      } else {
        acc.earlier.push(notification);
      }
      return acc;
    },
    {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      thisYear: [],
      earlier: [],
    }
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;

      if (
        scrollContainer &&
        scrollContainer.scrollTop >= scrollThreshold &&
        !isFetching &&
        notificationList?.length < totalCount
      ) {
        setLimit((prevLimit) => prevLimit + 10);
        setScrollThreshold((prevThreshold) => prevThreshold + 150);
      }
    };
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching, scrollThreshold, totalCount, notificationList]);
  const OnGetNotificationTasksForAdminPortal = useCallback(() => {
    // setIsFetching(true);
    const payload = {
      dashboardType: "worker",
      limit: limit,
      role_name: userRole,
      user_id: user_id,
      is_archived: singleSegmentValue[0] == "Inbox" ? false : true,
      organisation_id: "",
      search: searchTerm,
    };
    dispatch(GetNotificationTasksForAdminPortal(payload)).then((res) => {
      // setIsFetching(false);
      if (res?.success === true) {
        const arrayData = res?.data.rows;
        setNotificationList(arrayData);
        setTotalTaskCount(res?.data?.unreadCount);
        setTotalCount(res?.data?.count);
      }
    });
  }, [dispatch, singleSegmentValue, searchTerm, limit]);
  useEffect(() => {
    OnGetNotificationTasksForAdminPortal();
  }, [singleSegmentValue, searchTerm, limit]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };
  const inputchangehandler = useCallbackRef(debounce(handleSearch), []);

  const archiveTask = (notify) => {
    const payload = {
      role_name: userRole,
      notification_id: notify.notification_id,
      is_archived: true,
      user_id: user_id,
      dashboardType: "worker",
    };
    dispatch(UpdateTaskArchiveStatus(payload)).then((res) => {
      if (res?.success === true) {
        OnGetNotificationTasksForAdminPortal();
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  const onMarkAsRead = (notifyId) => {
    const payload = {
      role_name: userRole,
      notification_id: notifyId,
      user_id: user_id,
      task_read: true,
      dashboardType: "worker",
    };
    dispatch(MarkTaskAsRead(payload)).then((res) => {
      if (res?.success === true) {
        setTotalTaskCount(totalTastCount - 1); // Fixed typo: 'totalTastCount' -> 'totalTaskCount'
        setNotificationList((prevList) => {
          return prevList.map((item) => {
            if (item?.notification_id === notifyId) {
              return { ...item, task_read: true };
            }
            return item;
          });
        });
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  useEffect(() => {
    dispatch(GetWorkerMainDashboardDocsStatusCount()).then((response) => {
      if (response.success) {
        SetWorkerCountData(response?.data);
      }
    });
  }, []);
  return (
    <React.Fragment>
      <Heading className="mb-4">Dashboard</Heading>

      <div className="grid grid-container gap-8">
        <div className="flex gap-8 flex-col">
          <div className="bg-white py-5 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 px-5 mb-3">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 15L8 17L12.5 12.5M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Task{" "}
                <Tag className="text-blue-800 bg-blue-100 ml-2">
                  {totalTastCount}
                </Tag>
              </span>
              {/* <BtnLight className="w-20 h-9">View All</BtnLight> */}
            </div>
            <div className="flex justify-between mb-3 px-4">
              <Segment
                className="docType-btn"
                value={singleSegmentValue[0] == "Inbox" ? "Inbox" : "Archive"}
                onChange={(val) => onSingleSelectionSegmentChange(val)}
              >
                <Segment.Item value="Inbox">Inbox</Segment.Item>
                <Segment.Item value="Archive">Archive</Segment.Item>
              </Segment>
              <div className="w-80 h-10">
                <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5">
                    <img src={SearchImg} alt="" className="h-5" />
                  </div>
                  <ClientInput
                    type="text"
                    className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    inputchangehandler={inputchangehandler}
                    name={"search"}
                  />
                </div>
              </div>
            </div>
            <div
              ref={scrollContainerRef}
              className="notification-list-container overflow-y-auto h-[400px]"
            >
              <Table className="min-w-full">
                <THead>
                  <Tr>
                    <Th className="pl-6 w-1/2	">Notification</Th>
                    <Th>Timestamp</Th>
                    <Th className="w-84">Company</Th>
                    {singleSegmentValue[0] == "Inbox" && (
                      <Th className="">Action</Th>
                    )}
                  </Tr>
                </THead>
                <tbody className={`overflow-y-auto notification-list `}>
                  {loading ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        Loading...
                      </td>
                    </tr>
                  ) : notificationList.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        No Data Available!
                      </td>
                    </tr>
                  ) : (
                    <>
                      {groupedNotifications.today.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              Today
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.today.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}

                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {" "}
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.yesterday.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              Yesterday
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.yesterday.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisWeek.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This week
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisWeek.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisMonth.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This month
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisMonth.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisYear.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This year
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisYear.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
                {isFetching && (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-600"></div>
                  </div>
                )}
              </Table>
            </div>
          </div>
        </div>
        <div className="flex gap-8 flex-col">
          <div className="bg-white p-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M14.625 2.26953V6.40007C14.625 6.96012 14.625 7.24015 14.734 7.45406C14.8299 7.64222 14.9828 7.7952 15.171 7.89108C15.3849 8.00007 15.6649 8.00007 16.225 8.00007H20.3555M9.625 16L11.625 18L16.125 13.5M14.625 2H9.425C7.74484 2 6.90476 2 6.26303 2.32698C5.69854 2.6146 5.2396 3.07354 4.95198 3.63803C4.625 4.27976 4.625 5.11984 4.625 6.8V17.2C4.625 18.8802 4.625 19.7202 4.95198 20.362C5.2396 20.9265 5.69854 21.3854 6.26303 21.673C6.90476 22 7.74484 22 9.425 22H15.825C17.5052 22 18.3452 22 18.987 21.673C19.5515 21.3854 20.0104 20.9265 20.298 20.362C20.625 19.7202 20.625 18.8802 20.625 17.2V8L14.625 2Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Worker documents
              </span>
              <BtnLight className="w-20 h-9">View All</BtnLight>
            </div>
            <div className="card-body pt-5">
              <div className="flex gap-3">
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-blue-100 text-blue-800">
                    Awaiting review
                  </Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    {workerCountData?.count_awaiting_review || 0}
                  </span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-error-50 text-error-700">
                    Action required
                  </Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    {workerCountData?.count_action_required || 0}
                  </span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-success-50 text-success-700">Approved</Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    {workerCountData?.count_approved || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
