import axios from "axios";
import * as actionType from "../../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAdminSpecificContractorSlice";
export const GetWorkerProfileById =
  (apiData, workerPortal) => async (dispatch) => {
    /*** API Call Action ***/
    try {
const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

      //   dispatch(fetchData())
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
          
          organisationid: storedData?.organisation_id,
        },
        url: `${actionType.environment_url}/api/provider/GetWorkerProfileById`,
        params: apiData,
      });
      return response.data;
      //   dispatch(fetchDataSuccess(response.data))
    } catch (error) {
      return error.message;
      //   dispatch(fetchDataError(error.message))
    }
  };
