// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const LoginUpdate = createSlice({
  name:"LoginUpdate",
  initialState:false,
  reducers:{
    LoginUpdatefunc(state,action){
      return action.payload;
    },
  }
}) 
export default LoginUpdate.reducer;
export const {LoginUpdatefunc}=LoginUpdate.actions;