import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import { notify } from "react-notify-toast";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import EditContactForm from "./EditContact";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../common/element/Table";
import Tooltip from "../../../../../common/element/Tooltip";
import {
  GetClientContactById,
  DeleteClientContact,
  GetAllClientContacts,
} from "../../../../../actions/client-portal/AllContactsApi";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetUserOrganisationsList } from "../../../../../actions/client-portal/GetUserOrganisationsList";
const ContactsList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [treashIsOpen, setTrashIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;
  const [contactData, setContactData] = useState(false);
  const [individualOrgId, setIndividualOrgId] = useState("");
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  let organisation_id;
  let function_assignment_id;
  if (storedData !== null) {
    organisation_id = storedData?.organisation_id;
    function_assignment_id = storedData?.function_assignment_id;
  }

  const openDialog = (individual_id) => {
    setIsOpen(true);
    const individualId = {
      individual_id: individual_id,
      organisation_id: organisation_id,
    };
    dispatch(GetClientContactById(individualId)).then((response) => {
      if (response?.success) {
        const arrayData = response?.data;
        setContactData(arrayData);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  };
  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
    }
  };
  const openAlert = (item) => {
    setButtonDisabled(false);
    setIndividualOrgId(item?.individual_org_id);
    setTrashIsOpen(true);
  };
  const onAlertClose = (e) => {
    if (!buttonDisabled) {
      setTrashIsOpen(false);
    }
  };
  const onDialogOk = (e) => {
    const organisation_Id = { organisation_id: organisation_id };
    const individualId = { individual_org_id: individualOrgId };
    const payload = {
      individual_org_id: individualOrgId,
      function_assignment_id: function_assignment_id
        ? function_assignment_id
        : "",
    };
    setButtonDisabled(true);
    dispatch(DeleteClientContact(payload))
      .then((response) => {
        if (response?.success) {
          dispatch(GetAllClientContacts(organisation_Id))
            .then((response) => {
              if (response?.success) {
                const arrayData = response?.data.rows;
                props?.setContactsData(arrayData);
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              setButtonDisabled(false);
            })
            .finally(() => {
              onAlertClose();
              setButtonDisabled(false);
            });
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {})
      .finally(() => {
        onAlertClose();
        setButtonDisabled(false);
      });
    // setTrashIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("first_name")}
                >
                  Contact name
                  <svg
                    className={`transform ${
                      props?.filterData.sort === "first_name" &&
                      props?.filterData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("job_title")}
                >
                  Job Title
                  <svg
                    className={`transform ${
                      props?.filterData.sort === "job_title" &&
                      props?.filterData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-96">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("email")}
                >
                  Email address
                  <svg
                    className={`transform ${
                      props?.filterData.sort === "email" &&
                      props?.filterData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-44">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("phone")}
                >
                  Phone number
                  <svg
                    className={`transform ${
                      props?.filterData.sort === "phone" &&
                      props?.filterData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-44">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("is_client_site_contact")}
                >
                  Site contact
                  <svg
                    className={`transform ${
                      props?.filterData.sort === "is_client_site_contact" &&
                      props?.filterData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-36"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.loadData &&
              props?.contactsData &&
              props?.contactsData.map((item) => (
                <Tr key={item?.individual_id}>
                  <Td>{item?.first_name + " " + item?.last_name}</Td>
                  <Td>{item?.job_title ? item?.job_title : "-"}</Td>
                  <Td>{item?.email ? item?.email : "-"}</Td>
                  <Td>{item?.phone ? item?.phone : "-"}</Td>
                  <Td>
                    {item?.is_client_site_contact === true ? "Yes" : "No"}
                  </Td>
                  <Td>
                    <span className="flex items-center justify-end gap-4">
                      <Tooltip title="Delete" placement="top">
                        <button
                          disabled={props?.contactsPermission}
                          onClick={() => openAlert(item)}
                        >
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                              stroke="#475467"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                      <Tooltip title="Edit" placement="top">
                        <button
                          disabled={props?.contactsPermission}
                          onClick={() => openDialog(item?.individual_id)}
                        >
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                              stroke="#475467"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </span>
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/user-edit.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Edit Contact
            </h5>
            <p className="text-gray-600 ">
              Modify and update the information for this contact
            </p>
          </div>
        </div>
        <EditContactForm
          fetchClientContacts={props?.fetchClientContacts}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          individual_id={props?.contactsData.individual_id}
          contactData={contactData}
          function_assignment_id={function_assignment_id}
          onDialogClose={onDialogClose}
          setContactsData={props?.setContactsData}
        />
      </Dialog>
      <Dialog
        isOpen={treashIsOpen}
        onClose={onAlertClose}
        onRequestClose={onDialogClose}
        style={{
          marginTop: 250,
        }}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to delete this Contact?
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight
            disabled={buttonDisabled}
            className="mr-1.5"
            onClick={onAlertClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            className="ml-1.5"
            onClick={onDialogOk}
          >
            Confirm
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ContactsList;
