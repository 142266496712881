import axios from "axios";
import * as actionType from "../../constant/actionType";
export const UpdateModuleQuesSortingOrder = (data) => async (dispatch) => {
  const dataa = { moduleQuesOrderContents: data };
  try {
    let formdata = new FormData();
    for (let key in dataa) {
      formdata.append(key, JSON.stringify(dataa[key]));
    }
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        
        organisationid: storedData?.organisation_id,
      },
      url: `${actionType.environment_url}/api/client/UpdateModuleQuesSortingOrder`,
    });
    return response.data;
  } catch (error) {}
};
