import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
// import {notify} from 'react-notify-toast';
import * as userActions from "../../../../../../actions/index";
import ReactPaginate from "react-paginate";
import Table from "../../../../../../common/element/Table";
import Tooltip from "../../../../../../common/element/Tooltip";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
const InviteContrators = (props) => {
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { Tr, Th, Td, THead, TBody } = Table;
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Function to open the "Remove Invitation" dialog
  const openDialog = () => {
    setIsOpen(true);
  };
  // Function to close the "Remove Invitation" confirmation dialog
  const onDialogClose = () => {
    setIsOpen(false);
  };

  // to reInvite user
  const ReInviteUser = (e) => {
    setButtonDisabled(true);
    const payload = {
      invite_provider_id: e.inviteContractorId,
      trading_name: props?.tradingName,
      first_name: e.providerName.split(" ")[0],
      last_name: e.providerName.split(" ")[1],
    };

    props
      .onReinviteContractor(payload,ipAddress)
      .then((response) => {
        if (response.success === true) {
          openDialog();
          setButtonDisabled(false);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
          setButtonDisabled(false);
        } else {
          setButtonDisabled(false);
        }
      })
      .catch((error) => {
        setButtonDisabled(false);
      });
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              {/* <Th className="w-49">
                <span
                  className="flex items-center gap-1">
                  ID
                  <svg

                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th> */}
              <Th className="w-49">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("first_name")}
                >
                  Provider Name
                  <svg
                    className={`transform ${props.filtersort === "first_name" &&
                      props.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-49">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("provider_type_name")}
                >
                  Provider type
                  <svg
                    className={`transform ${props.filtersort === "provider_type_name" &&
                      props.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-49">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("meta_value_one")}
                >
                  Services
                  <svg
                    className={`transform ${props.filtersort === "meta_value_one" &&
                      props.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-48">
                {/* <span className="flex items-center gap-1">ABN/NZBN</span> */}
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("provider_abn_nzbn")}
                >
                  ABN/NZBN
                  <svg
                    className={`transform ${props.filtersort === "provider_abn_nzbn" &&
                      props.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">State</span>
              </Th>
              <Th
                scope="col"
                className="flex gap-2 bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
              >
                Status
                <Tooltip
                  title={
                    <>
                      Invited:
                      <br />
                      Compliant:
                      <br />
                      Non-compliant:
                      <br />
                      Non-compliant ($) :{" "}
                    </>
                  }
                >
                  <button
                    variant="plain"
                    shape="circle"
                    size="sm"
                    className="text-blue-700 font-semibold hover:text-gray-600"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_13733_32643)">
                        <path
                          d="M6.06001 5.99992C6.21675 5.55436 6.52611 5.17866 6.93331 4.93934C7.34052 4.70002 7.81927 4.61254 8.28479 4.69239C8.75032 4.77224 9.17255 5.01427 9.47673 5.3756C9.7809 5.73694 9.94738 6.19427 9.94668 6.66659C9.94668 7.99992 7.94668 8.66659 7.94668 8.66659M8.00001 11.3333H8.00668M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00001 14.6666C4.31811 14.6666 1.33334 11.6818 1.33334 7.99992C1.33334 4.31802 4.31811 1.33325 8.00001 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13733_32643">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </Tooltip>
              </Th>

              <Th className="w-28"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.contractorData &&
              props?.contractorData?.map((item) => (
                <Tr key={item?.inviteContractorId}>
                  {/* <Td>{0}</Td> */}
                  <Td>{item?.providerName ? item?.providerName : "-"}</Td>
                  <Td>{item?.ProviderType ? item?.ProviderType : "-"}</Td>
                  <Td>{item?.serviceName ? item?.serviceName : "-"}</Td>
                  <Td>{item?.abnNzbn ? item?.abnNzbn : "-"}</Td>
                  <Td>{item?.state_name ? item?.state_name : "-"}</Td>
                  <Td>
                    <span
                      className={`badge py-0.5 px-2 rounded-lg font-medium ${item?.inviteStatus == "Rejected"
                        ? "bg-error-100 text-error-700"
                        : "bg-gray-100 text-gray-700"
                        }`}
                    >
                      {item?.inviteStatus ? item?.inviteStatus : "-"}
                    </span>
                  </Td>
                  {/* <button class=" undefined" type="button"><div class="tag badge-wrapper bg-error-100 text-error-700">Action required</div></button> */}
                  <Td>
                    <div className="flex items-center justify-end gap-4">
                      <Tooltip title="Reinvite " placement="top">
                        <button
                          disabled={buttonDisabled}
                          onClick={() => ReInviteUser(item)}
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                              stroke="#475467"
                              stroke-width="1.667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>
      <div className="flex justify-between mt-3">
        <Pagination
          totalCount={props?.countData}
          pageNumber={props?.filterpage}
          displayPage={props?.contractorData?.length}
          UpdatePageLimit={props?.setfilterlimit}
          UpdatePage={props?.setfilterpage}
        />

        {props?.countData > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={props.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={props.handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={props.filterpage - 1}
          />
        )}
      </div>
      {/*--------- Invitaion Sent Confirmation -------- */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <SmallButton onClick={() => onDialogClose()}>close</SmallButton>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onReinviteContractor: userActions.ReinviteProvider,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteContrators);
